import {
  CREATED,
  CREATE_FAILED,
  DELETED,
  DELETE_FAILED,
  UPDATED,
  UPDATE_FAILED
} from "../constants/glovebox"
import { getJSON, postFile, getFile, putFile, deleteJSON } from "../../shared_component/utils/fetch"
import { setLoading, setNotLoading } from "./user"
const GLOVEBOX_URL = "/api/v4/glovebox_items"

//action

export const ALL_VEHICLE_GLOVEBOX_DATA = "ALL_VEHICLE_GLOVEBOX_DATA"
function loadAllVehicleGloveboxData(data) {
  return { type: ALL_VEHICLE_GLOVEBOX_DATA, payload: data }
}

export const AVAILABLE_GLOVEBOX_UPLOADED = "AVAILABLE_GLOVEBOX_UPLOADED"
function availableGloveboxUploaded(data) {
  return { type: AVAILABLE_GLOVEBOX_UPLOADED, payload: data }
}

export const AVAILABLE_GLOVEBOX_UPDATED = "AVAILABLE_GLOVEBOX_UPDATED"
function availableGloveboxUpdated(data) {
  return { type: AVAILABLE_GLOVEBOX_UPDATED, payload: data }
}

export const AVAILABLE_GLOVEBOX_LOADED = "AVAILABLE_GLOVEBOX_LOADED"
function availableGloveboxLoaded(data) {
  return { type: AVAILABLE_GLOVEBOX_LOADED, payload: data }
}
export const AVAILABLE_GLOVEBOX_DELETED = "AVAILABLE_GLOVEBOX_DELETED"

function availableGloveboxDeleted(data) {
  return { type: AVAILABLE_GLOVEBOX_DELETED, payload: data }
}

export const LOAD_ALL_STATES = "LOAD_ALL_STATES"
export function loadAllStates(data) {
  return { type: LOAD_ALL_STATES, payload: data }
}

export function createGloveboxData(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        payload: payload.formData
      }

      let response = await postFile(
        `${GLOVEBOX_URL}/bulk_create?auth_token=${authentication_token}`,
        request.payload
      )
      if (response.result) {
        await dispatch(availableGloveboxUploaded(response.result))
        await dispatch(loadGloveboxData(payload))
        await dispatch(
          loadAllGloveboxData({
            vehicleIds: payload.vehicleIds,
            callback: payload.callback
          })
        )
        payload.callback && payload.callback("success", { message: CREATED })
      } else {
        payload.callback && payload.callback("failure", { message: CREATE_FAILED })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function updateGloveboxData(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        payload: payload.formData
      }

      let response = await putFile(
        `${GLOVEBOX_URL}/bulk_update?auth_token=${authentication_token}`,
        request.payload
      )

      if (response.result) {
        await dispatch(availableGloveboxUpdated(response.result))
        await dispatch(loadGloveboxData(payload))
        await dispatch(
          loadAllGloveboxData({
            vehicleIds: payload.vehicleIds,
            callback: payload.callback
          })
        )
        payload.callback && payload.callback("success", { message: UPDATED })
      } else {
        payload.callback && payload.callback("failure", { message: UPDATE_FAILED })
      }

      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function loadGloveboxData(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token },
        payload: { vehicle_id: payload.vehicleId }
      }

      let response = await getJSON(`${GLOVEBOX_URL}`, request.payload, request.headers)

      if (response) {
        const { result } = response
        if (payload.deleteDocument) {
          payload.clearDocument(payload.documentType)
        } else {
          payload.updateValue && payload.updateValue(result.glovebox_items)
        }
        dispatch(availableGloveboxLoaded(result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function loadAllGloveboxData(payload) {
  var allVehicleGloveboxData = []
  return async function (dispatch, getState) {
    await dispatch(setLoading())

    let user = getState().user || {}
    let { authentication_token } = user
    let urlList = []
    if (authentication_token) {
      payload.vehicleIds.map((vehicleId) => {
        let request = {
          headers: { Authorization: authentication_token },
          payload: { vehicle_id: vehicleId }
        }
        urlList.push({
          url: `${GLOVEBOX_URL}`,
          params: request.payload,
          headers: request.headers
        })
      })
    }

    let fetches = urlList.map((url) =>
      fetch(`${GLOVEBOX_URL}?vehicle_id=${url.params.vehicle_id}`, {
        method: "GET",
        headers: Object.assign({ Accept: "application/json" }, url.headers)
      }).then((res) => res.json())
    )

    Promise.allSettled(fetches)
      .then((results) => {
        results.forEach((result, num) => {
          if (result.status == "fulfilled") {
            allVehicleGloveboxData.push(result.value.glovebox_items)
          } else if (result.status == "rejected") {
            payload.callback && payload.callback("failure", { message: result.reason })
          }
        })

        dispatch(loadAllVehicleGloveboxData(allVehicleGloveboxData))
        return allVehicleGloveboxData
      })
      .catch((err) => {
        alert(err)
      })
  }
}
export function deleteGloveboxData(payload) {
  return async function (dispatch, getState) {
    dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let url = `${GLOVEBOX_URL}/${payload.id}`
      let response = await deleteJSON(url, {}, { Authorization: authentication_token })

      if (response.result) {
        dispatch(availableGloveboxDeleted(payload.id))
        dispatch(loadGloveboxData({ ...payload, deleteDocument: true }))
        dispatch(
          loadAllGloveboxData({
            vehicleIds: payload.vehicleIds,
            callback: payload.callback
          })
        )
        payload.callback && payload.callback("success", { message: DELETED })
      } else {
        payload.callback && payload.callback("failure", { message: DELETE_FAILED })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function loadUserStates(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token, country } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(`/api/v4/states?country=${country}`, {}, request.headers)

      if (response.result) {
        dispatch(loadAllStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}
