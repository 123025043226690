import React from "react"
import { Image, Modal } from "semantic-ui-react"
import DialogCrossLogo from "../../../images/rewards/cross.svg"
import ReactSVG from "react-svg"
import TireTreadDepth from "../../../images/tire-tread-depth.svg"
import TireTreadDepthMethod from "../../../images/tire-tread-depth-method.svg"
import { useTranslation } from "react-i18next"
import { DANGER_DEPTH, GOOD_DEPTH, TIRE_DEPTH_STATUS, WARNING_DEPTH } from "../../constants/tires"

const HowTrackTireDepth = ({ handleTreadDepthDialog, openDialog }) => {
  const { t } = useTranslation("treadDepthTracker")

  const TIRE_DEPTH = [
    {
      label: GOOD_DEPTH,
      className: "tire_green",
      condition: TIRE_DEPTH_STATUS.good
    },
    {
      label: WARNING_DEPTH,
      className: "tire_warning",
      condition: TIRE_DEPTH_STATUS.replaceSoon
    },
    {
      label: DANGER_DEPTH,
      className: "tire_dangered",
      condition: TIRE_DEPTH_STATUS.replaceNow
    }
  ]

  return (
    <Modal
      onClose={() => handleTreadDepthDialog()}
      onOpen={() => handleTreadDepthDialog()}
      open={openDialog}
      className="tread_depth__dialog"
    >
      <Modal.Content>
        <div className="tread_depth__header">
          <p className="trea_depth__headerTitle">{t("tiresTreadDepthTitle")}</p>
          <ReactSVG
            onClick={() => handleTreadDepthDialog()}
            src={DialogCrossLogo}
            className="tread-depth-cross"
          />
        </div>
        <p className="tread_depth__how_check">{t("howToCheckTreadDepth")}</p>

        <div className="tread_depth__indicatorContainer">
          {TIRE_DEPTH.map((tireDepth) => (
            <div key={tireDepth.label} className="tread_depth__indicator">
              <p className="tire_label">{tireDepth.label}</p>
              <div className="tire_condition">
                <div className={`tire_condition__color ${tireDepth.className}`}></div>
                <p className="tire_condition__label">{tireDepth.condition}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="tire_tread_img__container">
          <Image src={TireTreadDepth} className="tire_tread_depth__image" />
        </div>

        <p className="penny_tire__test">{t("pennyTireTestTitle")}</p>
        <p className="penny_tire__test_ways">{t("pennyTireTestExplanation")}</p>

        <Image src={TireTreadDepthMethod} className="tire_tread_depth__image" />
      </Modal.Content>
    </Modal>
  )
}

export default HowTrackTireDepth
