import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { ShimmerServiceCard } from "./ShimmerServiceCard"
import { updateCart } from "../../../v1/actions/user"
import { getSecondLevelCategories } from "../../../v1/actions/services"
import CategoryCard from "./CategoryCard"
import ReactSVG from "react-svg"
import CategoryWiseServices from "./CategoryWiseServices"
import { NoServiceFound } from "./NoServiceFound"

const SecondLevelCategories = (props) => {
  const [secondLevelCategories, setSecondLevelCategories] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const { getSecondLevelCategories, cart } = props

  const fetchAvailableCategories = async () => {
    setLoading(true)

    const serviceCategories =
      (await getSecondLevelCategories({
        first_level: props.firstLevelCategory
      })) || null

    if (serviceCategories !== null) {
      setSecondLevelCategories(serviceCategories)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAvailableCategories()
  }, [])

  const handleCategoryCardClick = (category) => {
    setSelectedCategory(category) // Set the selected category in the state
  }

  if (props.firstLevelCategory && selectedCategory) {
    return (
      <CategoryWiseServices
        firstLevelCategory={props.firstLevelCategory}
        secondLevelCategory={selectedCategory}
        handleCategoryCardClick={handleCategoryCardClick}
      />
    )
  }

  return (
    <div className="second-level-services-container">
      <div className="add-services_header">
        <ReactSVG
          src="images/go_back_icon.svg"
          onClick={() => props.handleCategoryCardClick(null)}
          style={{ cursor: "pointer" }}
        />
        <div className="category-header-section">
          <p className="meta-heading">CATEGORY</p>
          <p className="category-heading">{props.firstLevelCategory}</p>
        </div>
      </div>
      <div className="second-level-services">
        {isLoading && <ShimmerServiceCard count={15} />}
        {!isLoading && secondLevelCategories.length > 0
          ? secondLevelCategories
              .slice()
              .sort()
              .map((os, index) => {
                return (
                  <CategoryCard
                    key={index}
                    isCheckbox={true}
                    index={index}
                    service={os}
                    handleCategoryCardClick={handleCategoryCardClick}
                  />
                )
              })
          : !isLoading && <NoServiceFound serviceName={`${props.firstLevelCategory}`} />}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps, { getSecondLevelCategories, updateCart })(
  SecondLevelCategories
)
