import React, { useState } from "react"

import { connect } from "react-redux"

import _ from "lodash"
import { withTranslation } from "react-i18next"

import emptyIcon from "../../../images/shops/empty_shop_logo.svg"

import { Image } from "semantic-ui-react"
import { SHOPLIST } from "../../../../constants/shops"
import FeaturedLabel from "../../dashboard/components/FeaturedLabel"

const ShopMapItem = (props) => {
  const { shop, setShopSelected, setViewType } = props

  const onMarkerHover = (shop) => {
    setShopSelected(shop)
    setViewType(SHOPLIST)
  }

  const onClosePopup = () => {
    setShopSelected(undefined)
  }

  const onSelectShop = (shop) => {
    setShopSelected(shop)
  }

  const isHighlighted = props.cart && props.cart.shop && props.cart.shop.id == shop.id

  let logo = shop.logo_url ? (
    <img style={{ width: "38px" }} src={shop.logo_url} />
  ) : (
    <Image style={{ width: "38px" }} src={emptyIcon} />
  )
  const sponseredShopClassStyles = shop.shop_featured
    ? "sponsered-shop-border"
    : "other-shop-border"
  return (
    <>
      {window.innerWidth > 720 && (
        <div className="shopMapItem" onClick={() => onMarkerHover(shop)}>
          <FeaturedLabel shop={shop} listView={true} showCheckIcon={false} showTooltip={true} />
          <div
            className={
              isHighlighted
                ? `container shopItemHighlighted ${sponseredShopClassStyles}`
                : `container ${sponseredShopClassStyles}`
            }
          >
            <div className="shopLogoContainer">{logo}</div>
            <div className="shopName shopNameHeader">
              <span className="mapShopTitle">{shop.name.toUpperCase()}</span>
              <span className="mapShopDistance">
                {shop.distance_config.display_value ? shop.distance_config.display_value : ""}
              </span>
            </div>
          </div>
        </div>
      )}
      {window.innerWidth <= 720 && (
        <div className="shopMapItem" onClick={() => onSelectShop(shop)}>
          <FeaturedLabel shop={shop} listView={true} showCheckIcon={false} showTooltip={true} />
          <div
            className={isHighlighted ? "container shopItemHighlighted" : "container"}
            style={
              shop.shop_featured
                ? { borderRadius: "0px 5px 5px 5px", border: "1px solid #282828" }
                : { borderRadius: "5px" }
            }
          >
            <div className="shopLogoContainer">{logo}</div>
            <div className="shopName shopNameHeader">
              <span className="mapShopTitle">{shop.name.toUpperCase()}</span>
              <span className="mapShopDistance">
                {shop.distance_config.display_value ? shop.distance_config.display_value : ""}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  return {
    user: user,
    cart
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(ShopMapItem))
