import React, { useState } from "react"
import { Popup, Accordion, Icon, Divider, Grid, Checkbox, Image } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import FilterIconImage from "../../../images/shops/filter.svg"
import FilterSelectedIconImage from "../../../images/shops/filter-selected.svg"
import { RATING_TAG } from "../../../../constants/shops"
import SpacingComponent from "./SpacingComponent"

const ShopMapFilter = (props) => {
  const {
    addorRemoveFilterItem,
    clearAllFilters,
    ratingFiltersApplied,
    typeFiltersApplied,
    isFilterOpen,
    setIsFilterOpen
  } = props

  const handleFilterIconClick = () => {
    setIsFilterOpen()
  }

  const handleClearAllFilter = () => {
    clearAllFilters()
  }

  return (
    <div style={{ display: "flex" }}>
      <Popup
        basic
        trigger={<FilterIcon isOpen={isFilterOpen} onClick={handleFilterIconClick} />}
        content={
          <div style={{ paddingLeft: "8px" }}>
            <FilterOptions
              onClearAllClick={handleClearAllFilter}
              addorRemoveFilterItem={(value, type) => {
                addorRemoveFilterItem(value, type)
                setIsFilterOpen(false)
              }}
              ratingFiltersApplied={ratingFiltersApplied}
              typeFiltersApplied={typeFiltersApplied}
            />
          </div>
        }
        on="click"
        position="bottom right"
        style={{ left: "38px", zIndex: 9999 }}
        open={isFilterOpen}
      />
    </div>
  )
}

const FilterIcon = (props) => {
  const { t } = useTranslation("dashboardMapComponent")
  return (
    <div
      className={
        props.isOpen ? "mapHeaderComponentSelected mapHeaderComponent" : "mapHeaderComponent"
      }
      onClick={() => props.onClick()}
    >
      <Image src={props.isOpen ? FilterSelectedIconImage : FilterIconImage} />
      <span>{t("filter")}</span>
    </div>
  )
}

const FilterOptions = (props) => {
  const { t } = useTranslation("dashboardMapComponent")
  const [isStarFilterOpen, setIsStarFilterOpen] = useState(true)
  const [isTpeOfServiceFilterOpen, setIsTpeOfServiceFilterOpen] = useState(true)

  return (
    <Accordion vertical>
      <Grid.Row
        style={{
          width: "250px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <span className="filterTitle">{t("filterBy")}:</span>
        <span className="filterClearText" onClick={props.onClearAllClick}>
          {t("clearFilter")}
        </span>
      </Grid.Row>

      <Accordion.Title
        active={isTpeOfServiceFilterOpen}
        onClick={() => setIsTpeOfServiceFilterOpen(!isTpeOfServiceFilterOpen)}
      >
        <Grid.Row
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <span className="filterOptionsHeader">{t("typeOfService")}</span>

          <div style={{ width: "20px" }} />
          <Icon name={isTpeOfServiceFilterOpen ? "chevron up" : "chevron down"} />
        </Grid.Row>
      </Accordion.Title>

      <Accordion.Content
        active={isTpeOfServiceFilterOpen}
        children={
          <TypeOfService
            filterSelected={props.typeFiltersApplied}
            addorRemoveFilterItem={props.addorRemoveFilterItem}
          />
        }
      />

      <Divider className="dividerStyles" />

      <Accordion.Title
        fluid
        active={isStarFilterOpen}
        onClick={() => setIsStarFilterOpen(!isStarFilterOpen)}
      >
        <Grid.Row
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <span className="filterOptionsHeader">{t("starRating")}</span>
          <div style={{ width: "20px" }} />
          <Icon name={isStarFilterOpen ? "chevron up" : "chevron down"} />
        </Grid.Row>
      </Accordion.Title>
      <Accordion.Content
        children={
          <StarRating
            filterSelected={props.ratingFiltersApplied}
            addorRemoveFilterItem={props.addorRemoveFilterItem}
          />
        }
        active={isStarFilterOpen}
      />
    </Accordion>
  )
}

const FilterItem = (props) => {
  const { isSelected, onClick, label, value } = props
  return (
    <div className="filterComponent">
      <Checkbox value={value} checked={isSelected} onChange={() => onClick(value)} />
      <span className="checkBoxLabel">{label}</span>
    </div>
  )
}

const StarRating = (props) => {
  const { filterSelected, addorRemoveFilterItem } = props
  const addorRemoveItem = (value) => {
    addorRemoveFilterItem(value, RATING_TAG)
  }

  return (
    <Grid.Column stretched>
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 5}
        onClick={(value) => addorRemoveItem(value)}
        label="5 Stars"
        value={5}
      />
      <SpacingComponent />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 4}
        onClick={(value) => addorRemoveItem(value)}
        label="4+ Stars"
        value={4}
      />
      <SpacingComponent />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 3}
        onClick={(value) => addorRemoveItem(value)}
        label="3+ Stars"
        value={3}
      />
      <SpacingComponent />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 2}
        onClick={(value) => addorRemoveItem(value)}
        label="2+ Stars"
        value={2}
      />
    </Grid.Column>
  )
}

const TypeOfService = (props) => {
  const { filterSelected, addorRemoveFilterItem } = props

  const addorRemoveItem = (value) => {
    addorRemoveFilterItem(value, "type")
  }

  return (
    <Grid.Column stretched>
      <FilterItem
        isSelected={filterSelected && filterSelected.includes("walk_in")}
        onClick={(value) => addorRemoveItem(value)}
        label="Walk in"
        value={"walk_in"}
      />
      <SpacingComponent />
      <FilterItem
        isSelected={filterSelected && filterSelected.includes("appointment")}
        onClick={(value) => addorRemoveItem(value)}
        label="Appointment"
        value={"appointment"}
      />
    </Grid.Column>
  )
}

export default ShopMapFilter
