import { HIDE_CHROME, SHOW_CHROME } from "../actions/framework"

export default function Framework(state = {}, action = {}) {
  switch (action.type) {
    case HIDE_CHROME:
      return { ...state, hideChrome: true }
    case SHOW_CHROME:
      return { ...state, hideChrome: false }
    default:
      return state
  }
}
