export const FINANCIAL_PRODUCT_KEY = "H0M5BNMHWYAZP0FY"
export const USER_CANCEL_URL = "https://api.caradvise.com/v4/orders"
export const USER_CONFIRMATION_URL = "https://api.caradvise.com/v4/orders"
export const USER_CONFIRMATION_URL_ACTION = "POST"
export const ITEM_IMAGE_URL =
  "https://caradvise.com/wp-content/uploads/2020/10/WhiteLogoCarAdvise.png"
export const ITEM_URL = "https://www.caradvise.com"

function getMerchantDetails() {
  return {
    user_cancel_url: USER_CANCEL_URL,
    user_confirmation_url: USER_CONFIRMATION_URL,
    user_confirmation_url_action: USER_CONFIRMATION_URL_ACTION
  }
}

function getCheckoutConfig() {
  return { financial_product_key: FINANCIAL_PRODUCT_KEY }
}

function getCheckoutMetadata() {
  return { mode: "modal" }
}

function getShippingDetails(user, order) {
  // shipping details
  let shippingDetails = { name: {}, address: {}, email: "", phone_number: "" }
  shippingDetails["name"]["first"] = user.firstName
  shippingDetails["name"]["last"] = user.lastName
  shippingDetails["address"]["line1"] = "PO BOX 405"
  shippingDetails["address"]["line2"] = ""
  shippingDetails["address"]["city"] = "Westmont"
  shippingDetails["address"]["state"] = "IL"
  shippingDetails["address"]["zipcode"] = "60559"
  shippingDetails["email"] = user.email
  shippingDetails["phone_number"] = ""
  return shippingDetails
}

function getItemsDetails(order) {
  let itemsDetails = order.order_services
    .filter((orderService) => {
      return orderService.status == "1" || orderService.status == "2" || orderService.status == "0"
    })
    .map((orderService) => ({
      display_name: orderService.name,
      sku: orderService.reference_id || "000",
      unit_price: (orderService.price_estimate.shop_price_estimate || 1) * 100,
      qty: 1,
      item_image_url: ITEM_IMAGE_URL,
      item_url: ITEM_URL
    }))
  return itemsDetails
}

function getDiscountDetails(order) {
  return {
    total_coupon_discount: {
      discount_amount: Math.round(order.total_coupon_discount * 100) || "0.00",
      discount_display_name: "Total Coupon Discount"
    },
    total_shop_discount: {
      discount_amount: Math.round(order.total_shop_discount * 100) || "0.00",
      discount_display_name: "Total Shop Discount"
    },
    caradvise_discount: {
      discount_amount: Math.round(order.caradvise_discount * 100) || "0.00",
      discount_display_name: "Caradvise Discount"
    },
    promotional_discount: {
      discount_amount: Math.round(order.promotional_discount * 100) || "0.00",
      discount_display_name: "Promotional Discount"
    },
    membership_discount: {
      discount_amount: Math.round(order.membership_discount * 100) || "0.00",
      discount_display_name: "Membership Discount"
    }
  }
}

export function prepareCheckoutObj(user, order) {
  let checkoutObj = {}
  checkoutObj["config"] = getCheckoutConfig()
  checkoutObj["merchant"] = getMerchantDetails()
  checkoutObj["metadata"] = getCheckoutMetadata()
  checkoutObj["shipping"] = getShippingDetails(user, order)
  checkoutObj["items"] = getItemsDetails(order)
  checkoutObj["currency"] = order.currency.toUpperCase()
  checkoutObj["discounts"] = getDiscountDetails(order)
  checkoutObj["tax_amount"] = getTaxDetails(order)
  checkoutObj["total"] = Math.round((order.estimated_total - (order.previous_payments || 0)) * 100)
  checkoutObj["shipping_amount"] = "0"
  return checkoutObj
}

export function getTaxDetails(order) {
  let override_total_pre_tax = order.estimated_total_pre_tax
  let taxTotal = Math.round((order.estimated_total - override_total_pre_tax) * 100)
  return taxTotal
}

export function prepareTireCheckoutObj(user, order, totalAmountDue, shopDetails) {
  let checkoutObj = {}
  checkoutObj["config"] = getCheckoutConfig()
  checkoutObj["merchant"] = getMerchantDetails()
  checkoutObj["metadata"] = getCheckoutMetadata()
  checkoutObj["shipping"] = getTireShippingDetails(user, shopDetails)
  checkoutObj["items"] = getTireItemsDetails(order)
  checkoutObj["currency"] = "USD"
  checkoutObj["discounts"] = getDiscountDetails(order)
  checkoutObj["tax_amount"] = getTireTaxDetails(order)
  checkoutObj["total"] = Math.round(totalAmountDue * 100)
  checkoutObj["shipping_amount"] = "0"
  return checkoutObj
}

function getTireShippingDetails(user, shopDetails) {
  let shippingDetails = { name: {}, address: {}, email: "", phone_number: "" }
  shippingDetails["name"]["first"] = user.firstName
  shippingDetails["name"]["last"] = user.lastName
  shippingDetails["address"]["line1"] = shopDetails.address_line1
  shippingDetails["address"]["line2"] = ""
  shippingDetails["address"]["city"] = shopDetails.city
  shippingDetails["address"]["state"] = shopDetails.state
  shippingDetails["address"]["zipcode"] = shopDetails.zip
  shippingDetails["email"] = user.email
  shippingDetails["phone_number"] = "" //user.cellPhone
  return shippingDetails
}

function getTireItemsDetails(order) {
  const itemsDetails = [
    {
      display_name: "Tire Connect New Tires",
      sku: "tire_connect_new_tires",
      unit_price: order && order.total_tire_price * 100,
      qty: 1,
      item_image_url: ITEM_IMAGE_URL,
      item_url: ITEM_URL
    },
    {
      display_name: "Tire Connect Installation",
      sku: "tire_connect_nstallation",
      unit_price: order && order.installation_fee * 100,
      qty: 1,
      item_image_url: ITEM_IMAGE_URL,
      item_url: ITEM_URL
    },
    {
      display_name: "Tire Delivery Fee",
      sku: "tire_delivery_fee",
      unit_price: order && order.items[0].order_total.delivery_fee * 100,
      qty: 1,
      item_image_url: ITEM_IMAGE_URL,
      item_url: ITEM_URL
    }
  ]
  return itemsDetails
}

export function getTireTaxDetails(order) {
  let taxTotal = Math.round(
    order && (order.total_tire_price * order.tax + order.transaction_fee) * 100
  )
  return taxTotal
}
