import React, { Component } from "react"
import { slide as Menu } from "react-burger-menu"
import { connect } from "react-redux"
import store from "../../shared_component/utils/configureStore"
import { logoutUser } from "../../v1/actions/user"
import { Link } from "react-router-dom"
import { hidePartnerOffers } from "../../v1/helpers/affiliationHelpers"
import { isSigningUp } from "../../v1/helpers/pathHelpers"
import { withTranslation } from "react-i18next"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { faUser, faSignOut } from "@fortawesome/pro-regular-svg-icons"
import Badge from "../../v1/components/Badge"
import { ON, setupSplitFlags } from "../../v1/components/Config/SplitClient"
import { getSubdomain } from "../../v1/components/Config/StylesheetInjector"
import MenuBarIcon from "../../images/menu-bar-buttons.svg"
import { Image } from "semantic-ui-react"
import DashbaordIcon from "../../images/sidemenu_icon/dashboard.svg"
import ApprovalsIcon from "../../images/sidemenu_icon/approval.svg"
import MaintenanceScheduleIcon from "../../images/sidemenu_icon/maintenance_schedule.svg"
import MyMembershipIcon from "../../images/sidemenu_icon/my-membership.svg"
import MyGarageIcon from "../../images/sidemenu_icon/my-garage.svg"
import MaintenanceHistoryIcon from "../../images/sidemenu_icon/maintenance-history.svg"
import InviteFriendsIcon from "../../images/sidemenu_icon/invite-friends.svg"
import FAQIcon from "../../images/sidemenu_icon/faq.svg"
import GloveboxIcon from "../../images/sidemenu_icon/glovebox.svg"
import carAdvise360BlackLogo from "../../images/car360-black.svg"
import RewardLogo from "../../images/rewards/reward-navigation.svg"
import TotalMiles from "./Rewards/TotalMiles"
import { APP_NAME } from "../../shared_component/constants/common"

class SideMenu_v2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRewardsFeature: window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature
    }
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ app_name: APP_NAME })
  }

  close(name) {
    if (name !== "Dashboard")
      trackEvent("click-navigation-drawer", {
        itemName: name
      })
    try {
      document.getElementsByClassName("bm-cross-button")[0].children[1].click()
    } catch (e) {}
  }

  menuItems() {
    const { isGeneric, t } = this.props

    let items = [
      [
        "dashboard",
        t("dashboardMenu"),
        DashbaordIcon,
        {
          isImage: true,
          width: "18px",
          height: "24px"
        }
      ],
      [
        "approvals",
        t("approvalsMenu"),
        ApprovalsIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ],

      [
        "maintenanceSchedule",
        t("maintenanceScheduleMenu"),
        MaintenanceScheduleIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ],
      [
        "membership",
        t("membershipCardMenu"),
        MyMembershipIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ],
      [
        this.state.isGloveBoxFeatureFlag === ON && "gloveboxComponent",
        t("gloveboxMenu"),
        GloveboxIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ],
      [
        "managevehicles",
        t("myGarageMenu"),
        MyGarageIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ],
      [
        this.props.activeVehicleId ? `vehicles/${this.props.activeVehicleId}/history` : null,
        t("maintenanceHistoryMenu"),
        MaintenanceHistoryIcon,
        {
          isImage: true,
          width: "24px",
          height: "24px"
        }
      ]
    ]

    if (this.state.isRewardsFeature) {
      items.push([
        "rewards",
        t("rewards"),
        RewardLogo,
        {
          isImage: true,
          height: 27,
          width: 22,
          style: { marginTop: "1px", marginLeft: 0 }
        }
      ])
    }

    if (!hidePartnerOffers())
      items.push([
        "offers",
        t("partnerOffersMenu"),
        carAdvise360BlackLogo,
        {
          isImage: true,
          width: 24,
          height: 24
        }
      ])
    items.push([
      "invite-friends",
      t("inviteFriendsMenu"),
      InviteFriendsIcon,
      {
        isImage: true,
        width: "24px",
        height: "24px"
      }
    ])
    if (!isGeneric)
      items.unshift([
        "MyAccount",
        t("myAccountMenu"),
        faUser,
        {
          isImage: false,
          style: { marginLeft: "4px" }
        }
      ])

    return items
  }

  render() {
    const { isLoggedIn, isGeneric, t } = this.props
    const { isRewardsFeature } = this.state
    if (/checkout/.test(window.location.href) || !isLoggedIn || (isGeneric && isSigningUp()))
      return null
    return (
      <div className="side-menu_v2">
        <Menu customBurgerIcon={<Image src={MenuBarIcon} />} disableAutoFocus>
          <div className="bm-menu-wrap">
            <div className="side-menu-bm-menu">
              {isRewardsFeature && <TotalMiles />}
              {this.menuItems().map(
                (arr, index) =>
                  arr[0] && (
                    <a
                      id={arr[0]}
                      key={arr[0]}
                      className="side-bar-menu-item"
                      href={`#/${arr[0]}`}
                      onClick={this.close.bind(this, arr[1])}
                    >
                      <div className="side-menu-bm-menu-item">
                        <Badge count={null} icon={arr[2]} customImage={arr[3]} />
                        {arr[1]}
                      </div>
                    </a>
                  )
              )}
            </div>
            <div
              className="side-menu-bm-menu-item"
              onClick={() => {
                trackEvent("click-navigation-drawer", {
                  itemName: "FAQs"
                })
              }}
            >
              <Badge
                count={null}
                icon={FAQIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "5px 0px" }
                }}
              />
              <a className="side-bar-menu-item" href="https://caradvise.com/faq/" target="_blank">
                {t("faqPageMenu")}
              </a>
            </div>
            <div className="side-menu-bm-menu-item">
              <Badge
                count={null}
                icon={faSignOut}
                customImage={{
                  isImage: false,
                  style: { margin: "10px 6px" }
                }}
              />
              <Link
                to="/signin"
                className="side-bar-menu-item"
                onClick={() => store.dispatch(logoutUser())}
              >
                {t("signoutMenu")}
              </Link>
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}

  return {
    isLoggedIn: user.id,
    activeVehicleId: user.activeVehicleId,
    isGeneric: user.generic,
    user
  }
}

export default connect(mapStateToProps, { logoutUser })(withTranslation("sideMenu")(SideMenu_v2))
