import React, { Component } from "react"
import store from "../../../shared_component/utils/configureStore"
import { updateSelectedMembershipUpgradeClauses } from "../../actions/membership"
import ModalDialog from "../ModalDialog"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { withTranslation } from "react-i18next"

class MembershipClauseAgreementSection extends Component {
  handleDispatch = () => {
    store.dispatch(updateSelectedMembershipUpgradeClauses(this.state))
  }

  state = {
    acceptedTermsAndConditions: false,
    acceptedPassengerVehicleClause: false
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.checked
    const name = target.name
    const { updateSelectedMembership } = this.props

    if (updateSelectedMembership) {
      updateSelectedMembership({ [name]: value })
    } else {
      this.setState({ [name]: value }, this.handleDispatch)
    }
  }

  render() {
    const { selectedMembershipUpgrade } = this.props
    const { t } = this.props
    return (
      <ul className="membership-clause-agreement-section">
        {selectedMembershipUpgrade && (
          <React.Fragment>
            <div className="checkbox-item">
              <input
                type="checkbox"
                name={"acceptedTermsAndConditions"}
                onClick={(e) => this.handleInputChange(e)}
              />
              {`${t("passengerVehicleCheckboxText")} `}
              <ModalDialog
                headerTitle={t("passengerVehicleModalHeader")}
                contentClassName={"affiliation-modal-content"}
                chromeless={true}
                icon={faQuestionCircle}
              >
                <div class="passenger-vehicle-info">{t("passengerVehicleModalDescription")}</div>
              </ModalDialog>
            </div>
            <div className="checkbox-item">
              <input
                type="checkbox"
                name={"acceptedPassengerVehicleClause"}
                onClick={(e) => this.handleInputChange(e)}
              />
              {t("IAgreeToLbl")}
              <a
                href="https://caradvise.com/terms/"
                className="button-href"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("termsAndConditionLbl")}
              </a>
            </div>
            <div className="checkbox-item membership-upgrade-clause-fine-print">
              {t("roadsideAssistanceDescription1", {
                eventsCount: selectedMembershipUpgrade.max_num_yearly_roadside_events
              })}
            </div>
            <div
              style={{ paddingTop: 0, marginTop: 0 }}
              className="checkbox-item membership-upgrade-clause-fine-print"
            >
              {t("roadsideAssistanceDescription2")}
            </div>
          </React.Fragment>
        )}
      </ul>
    )
  }
}

export default withTranslation("membershipClauseAgreementSection")(MembershipClauseAgreementSection)
