import React, { useState, useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { DEFAULT_IMG_URL } from "../constants/carMileage"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Button, Input } from "semantic-ui-react"
import { fetchInsuranceProvider } from "../actions/insurance"
import { getUserDetails, updateActiveVehicle, updateUserDetails } from "../actions/user"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import ProviderIframe from "./ProviderIframe"
import CustomDropdown from "../../shared_component/elements/CustomDropdown"
import { fetchVehicleName, selectOptions } from "../helpers/vehicleHelpers"
import { updateVehicleData } from "../actions/vehicles"
import { routerPaths } from "../constants/paths"
import InsuranceProviders from "./InsuranceProviders"
import { isEmpty } from "lodash"
import { ANCHOR_BLANK } from "../../shared_component/constants/common"

const ChooseCoverage = ({
  user,
  t,
  vehicles,
  activeVehicle,
  updateActiveVehicle,
  updateUserDetails,
  updateVehicleData,
  fetchInsuranceProvider,
  isLeftNavOpen
}) => {
  const [zebra, setZebra] = useState(false)
  const [clearcover, setClearcover] = useState(false)
  const [zebraUrl, setZebraUrl] = useState()
  const [clearcoverDisabled, setClearcoverDisabled] = useState(false)
  const [partnerFlag, setPartnerFlag] = useState()
  const [hasClicked, setIsClicked] = useState(false)
  const [vehicleMileage, setVehicleMileage] = useState("")
  const [isApiLoading, setIsApiLoading] = useState(false)
  const [mileageFieldError, setMileageFieldError] = useState(false)
  const [insuranceProvider, setInsuranceProvider] = useState([])

  useEffect(() => {
    getFeatureFlagResult()
    getInsuranceProvider()
  }, [])

  useEffect(() => {
    setVehicleMileage(activeVehicle && activeVehicle.miles)
    setMileageFieldError(!(activeVehicle && activeVehicle.miles))
    setClearcoverDisabled(!(activeVehicle && activeVehicle.vin))
  }, [activeVehicle])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const stringComparision = (str1, str2) => {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase()
  }

  const getInsuranceProvider = async (vehicle) => {
    setIsApiLoading(true)

    try {
      const data = {
        zip: user.zip,
        vin: (!isEmpty(vehicle) && vehicle.vin) || activeVehicle.vin,
        mileage:
          !isEmpty(vehicle) && !isNaN(vehicle.miles)
            ? vehicle.miles
            : vehicleMileage || (activeVehicle && activeVehicle.miles),
        firstName: user.firstName,
        lastName: user.lastName
      }
      let responseData = await fetchInsuranceProvider(data)
      const transformData =
        Object.entries(responseData.result) &&
        Object.entries(responseData.result).map(([provider, providerData]) => {
          return {
            quote: providerData.return_url || providerData.url || providerData.location,
            logo: providerData.logo,
            name: providerData.name,
            trackEvent: stringComparision(providerData.name, t("rootInsurance"))
              ? "insurance-plan-root-insurance-clicked"
              : stringComparision(providerData.name, t("zebra"))
              ? "insurance-plan-zebra-clicked"
              : "insurance-plan-clearcover-clicked",
            benefits: providerData.benefits,
            onClick: stringComparision(providerData.name, t("rootInsurance"))
              ? redirectRoot
              : stringComparision(providerData.name, t("zebra"))
              ? handleZebra
              : handleClearcover,
            isReviewsAvailable: stringComparision(providerData.name, t("rootInsurance"))
          }
        })
      setInsuranceProvider(transformData)
    } catch (e) {
      console.log(t("errorInrediRectClearcover"), e)
    } finally {
      setIsApiLoading(false)
    }
  }

  const redirectRoot = (provider) => {
    trackEvent(provider.trackEvent, {}, true, false, false)
    window.open(provider.quote, ANCHOR_BLANK)
    setZebra(false)
  }

  const handleZebra = async (provider) => {
    setClearcover(false)
    setZebra((prev) => !prev)
    setZebraUrl(provider.quote)
    trackEvent(provider.trackEvent, {}, true, false, false)
    setIsClicked(true)
  }

  const handleClearcover = (provider) => {
    trackEvent(provider.trackEvent, {}, true, false, false)
    setClearcover(!clearcover)
    setZebra(false)
    window.open(provider.quote, ANCHOR_BLANK)
  }

  const setActiveVehicle = async (vehicle) => {
    try {
      setVehicleMileage(0)
      await updateActiveVehicle(vehicle.value.id)
      await getInsuranceProvider(vehicle.value)
    } catch (error) {
      console.log(t("errorInSetActiveVehicle"), error)
    }
  }

  const handleMileage = (e) => {
    setVehicleMileage(e.target.value)
    setMileageFieldError(!e.target.value)
  }

  const handleNextBtnClick = async (e) => {
    setIsApiLoading(true)
    const data = {
      vehicle_id: activeVehicle.id,
      zip: user.zip,
      mileage: vehicleMileage
    }
    try {
      const responseData = await updateUserDetails(data)

      if (responseData && responseData.result.active_vehicle) {
        if (+activeVehicle.miles !== +vehicleMileage) {
          await updateVehicleData({
            vin: responseData.result.active_vehicle.vin,
            vehicleId: responseData.result.active_vehicle.id,
            miles: responseData.result.active_vehicle.miles,
            milesPerMonth: responseData.result.active_vehicle.miles_per_month,
            licensePlateNumber: responseData.result.active_vehicle.license_plate_number,
            licensePlateState: responseData.result.active_vehicle.license_plate_state
          })

          await getInsuranceProvider()
        }
      }
    } catch (error) {
      console.log(t("errorInHandleNextBtnClick"), error)
    } finally {
      setIsApiLoading(false)
    }
  }

  const handleGoBack = () => {
    setIsClicked(false)
    setZebra(false)
    setClearcover(false)
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          {!hasClicked && (
            <div className="choose-coverage-section">
              <div className="choose-coverage">
                <div className="step-title">
                  <Link to={routerPaths.offers}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                  <h3>{t("selectVehiclePlan")}</h3>
                </div>
                {+activeVehicle.miles !== +vehicleMileage && (
                  <p className="confirm__mileage">{t("confirmVehicleMileage")}</p>
                )}
                <div className="vehicles">
                  <div className="vehicle_list">
                    <p className="vehicle_label">{t("yourVehicle")}</p>
                    <CustomDropdown
                      options={selectOptions(vehicles)}
                      isVehicleDropdown={true}
                      defaultOption={{
                        text: fetchVehicleName(activeVehicle),
                        image: activeVehicle.dashboard_mobile_url
                          ? activeVehicle.dashboard_mobile_url
                          : DEFAULT_IMG_URL,
                        key: activeVehicle.id,
                        value: activeVehicle
                      }}
                      imageClass="default-vehicle-image"
                      onOptionChange={(vehicle) => setActiveVehicle(vehicle)}
                    />
                  </div>
                  <div className="vehicle_mileage">
                    <p className="vehicle_label">{t("currentVehicleMileage")}</p>
                    <div
                      className={`vehicle_mileage__input ${mileageFieldError && "mileage_error"}`}
                    >
                      <Input
                        value={vehicleMileage}
                        onChange={handleMileage}
                        className="vehicle_input"
                        type="number"
                      />
                      <Button
                        disabled={
                          !vehicleMileage ||
                          +activeVehicle.miles === +vehicleMileage ||
                          isApiLoading
                        }
                        onClick={handleNextBtnClick}
                        size="small"
                        className="mileage_save"
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="form-parent">
                  <div className={`left-child ${isLeftNavOpen && "insurace-left-child"}`}>
                    {isApiLoading
                      ? [...new Array(3)].map(() => <div className="provider_shimmer"></div>)
                      : insuranceProvider &&
                        insuranceProvider.length > 0 &&
                        insuranceProvider.map((provider) => (
                          <InsuranceProviders
                            provider={provider}
                            benefits={provider.benefits}
                            image={provider.logo}
                            onClick={(providerData) => {
                              provider.onClick(providerData)
                              getInsuranceProvider()
                            }}
                            providerName={provider.name}
                            isReviewsAvailable={provider.isReviewsAvailable}
                            isButtonDisabled={
                              stringComparision(provider.name, t("clearcover")) &&
                              clearcoverDisabled
                            }
                            dynamicFooterUI={
                              stringComparision(provider.name, t("clearcover")) &&
                              clearcoverDisabled ? (
                                <p className="clearcover__note">
                                  {t("VIN_unavailable")}&nbsp;
                                  <Link to={routerPaths.managevehicles} className="garage_text">
                                    {t("Go_to_garage")}
                                  </Link>
                                  &nbsp;
                                  {t("update_to_proceed_further")}
                                </p>
                              ) : null
                            }
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {hasClicked && (
        <ProviderIframe
          iframeURL={zebra && zebraUrl}
          handleGoBack={handleGoBack}
          providerName={zebra && t("zebra")}
        />
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user

  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  const activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    user: user,
    vehicles: user.vehicles,
    activeVehicle: activeVehicle,
    isLeftNavOpen: state.leftnav.navOpen
  }
}

export default connect(mapStateToProps, {
  getUserDetails,
  updateActiveVehicle,
  updateUserDetails,
  updateVehicleData,
  fetchInsuranceProvider
})(withTranslation("chooseCoverage")(ChooseCoverage))
