import ReactGA from "react-ga"

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

export const GAEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  })
}

export const GAEventCategories = {
  DASHBOARD: "DASHBOARD",
  CART: "CART",
  MAINTENANCE_SCHEDULE: "MAINTENANCE_SCHEDULE",
  MAINTENANCE_HISTORY: "MAINTENANCE_HISTORY",
  MY_GARAGE: "MY_GARAGE",
  PARTNER_OFFERS: "PARTNER_OFFERS",
  SELECT_COMMON_MAINTENANCE: "SELECT_COMMON_MAINTENANCE",
  SELECT_SHOP: "SELECT_SHOP",
  SEARCH_SERVICES: "SEARCH_SERVICES",
  MY_ACCOUNT: "MY_ACCOUNT",
  APPROVALS: "APPROVALS",
  FTUE: "FTUE",
  USER: "USER",
  REWARDS: "REWARDS"
}
