import React from "react"
import RemoveFilterLogo from "../../../../images/remove-filter.svg"
import ReactSVG from "react-svg"
import {
  TIRE_FILTER_CHECKBOX,
  TIRE_FILTER_RANGE,
  TIRE_FILTER_SORTING,
  TIRE_SORTING_FILTER_OPTIONS
} from "../../../constants/tires"
import { transformString } from "../../../../shared_component/utils/TireFilterHelpers"

const sortingOptionsMap = TIRE_SORTING_FILTER_OPTIONS.reduce((acc, option) => {
  acc[option.value] = option.key
  return acc
}, {})

const TireFilterChip = ({
  filterType,
  filterLabel,
  handleClearFilter,
  filterCategory,
  filterValue
}) => {
  const getFilterLabelAndValue = ({ filterType, filterLabel }) => {
    return (
      <p>
        {transformString(filterType)}: {filterLabel}
      </p>
    )
  }

  const renderContent = () => {
    switch (filterCategory) {
      case TIRE_FILTER_CHECKBOX:
        return getFilterLabelAndValue({ filterType, filterLabel })
      case TIRE_FILTER_SORTING:
        return getFilterLabelAndValue({ filterType, filterLabel: sortingOptionsMap[filterValue] })
      case TIRE_FILTER_RANGE:
        return getFilterLabelAndValue({
          filterType,
          filterLabel: `$${filterValue.start_price} - $${filterValue.end_price}`
        })
      default:
        return null
    }
  }

  return (
    <div className="applied__filter" key={`${filterType}-${filterCategory}-${filterLabel}`}>
      {renderContent()}
      <ReactSVG
        className="remove__filter-logo"
        src={RemoveFilterLogo}
        onClick={() => handleClearFilter(filterType, filterLabel, filterCategory)}
      />
    </div>
  )
}

export default TireFilterChip
