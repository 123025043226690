import { postJSON, getJSON } from "../../shared_component/utils/fetch"

export const LOAD_USER_STATES = "LOAD_USER_STATES"
export function loadUserStates(data) {
  return { type: LOAD_USER_STATES, payload: data }
}

export const USER_NOT_LOADING = "USER_NOT_LOADING"
export function setUserNotLoading() {
  return { type: USER_NOT_LOADING }
}

export function setNotLoading() {
  return async function (dispatch) {
    await dispatch(setUserNotLoading())
    return () => {}
  }
}

export function getAxiomAPI(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/vsc/axiom`,
        { vehicle_id: activeVehicleId },
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getCuvrdPlans(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await postJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/vsc/cuvrd`,
        payload.data,
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getCuvrdPlanParts(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(payload.apiURL)

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}
