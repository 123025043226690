import React, { PureComponent } from "react"
import { connect } from "react-redux"
import ModalDialog from "../ModalDialog"
import { elements } from "caradvise_shared_components"
import Button from "../Button.js"
import { updateInfo } from "../../actions/user"
import { withTranslation } from "react-i18next"

const { BaseModalDialog } = elements

class LanguageSelectModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      language: props.user.language || "en"
    }
  }

  handleChange = async (event) => {
    this.setState({
      language: event.target.value
    })
  }

  callUpdate = async () => {
    const { language } = this.state
    const { user, t } = this.props
    if (user.language !== language) {
      const response = await this.props.updateInfo({
        language: language,
        displayNotification: true
      })
    }
    this.props.closeModal()
  }

  render() {
    const { setRef, user, isOpen, closeModal, t } = this.props
    return (
      <BaseModalDialog isOpen={isOpen} toggleModal={closeModal}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h3>{t("welcomeTitle")}</h3>
          <p style={{ textAlign: "center" }}>{t("preferredLanguageDesc")}</p>
        </div>
        <select style={{ height: "40px" }} onChange={this.handleChange} value={this.state.language}>
          <option value={"en"}>{t("englishLbl")}</option>
          <option value={"fr"}>{t("frenchLbl")}</option>
        </select>
        <Button
          text={t("updateLbl")}
          onClick={this.callUpdate}
          colorStyle="orange"
          style={{ paddingTop: 5, paddingBottom: 5, marginTop: 15, marginBottom: 15 }}
        />
      </BaseModalDialog>
    )
  }
}

function mapStateToProps(state) {
  return {
    language: state.language || "en"
  }
}

export default connect(mapStateToProps, { updateInfo })(
  withTranslation("languageSelectModal")(LanguageSelectModal)
)
