import React, { useEffect, useState } from "react"

import { Checkbox, Divider, Icon, Image } from "semantic-ui-react"
import RecommendedServiceModal from "../../../../components/MaintenanceSchedules/RecommendedServiceModal"
import { EVENT_LISTENERS, MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"
import { numberFormatToCurrency } from "../../../../../shared_component/utils/currency"
import { formattedPrice, shopPrice } from "../../../../../v2/components/Cart/CartServiceRow"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import store from "../../../../../shared_component/utils/configureStore"

const Services = (props) => {
  const {
    service,
    selectedServices,
    completed,
    showMarkCompletedCheckBox = true,
    isLast = false,
    addorRemoveServices,
    onMarkasCompletedCheckboxClick,
    markAllCompleted,
    serviceToBeMarkedAsCompleted,
    activeVehicle,
    isAdded = false,
    order
  } = props
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener(EVENT_LISTENERS.RESIZE, updateWindowWidth)
    return () => {
      window.removeEventListener(EVENT_LISTENERS.RESIZE, updateWindowWidth)
    }
  }, [])
  const { t } = useTranslation("common")
  const { user } = store.getState()
  let activevehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  const vehicleTypeId = activeVehicle.vehicle_type_id
  const [open, setOpen] = React.useState(false)
  let isChecked = false
  const [show, setShow] = React.useState(true)
  React.useEffect(() => {
    if (!isChecked) {
      setShow(true)
    }
  }, [isChecked])
  if (markAllCompleted) {
    const serviceIndex = serviceToBeMarkedAsCompleted.findIndex(
      (s) => s.service_id === service.service_id
    )
    isChecked = serviceIndex == -1 ? false : true
  } else {
    const serviceIndex = selectedServices.findIndex((s) => s.service_id === service.service_id)
    isChecked = serviceIndex == -1 ? false : true
  }
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = (data) => {
    setOpen(false)
  }

  const onSave = (data) => {
    if (data) {
      addorRemoveServices(service, data)
    } else {
      addorRemoveServices(service)
      isChecked = false
    }
    closeModal()
  }
  return (
    <>
      <div className="manufacturer-title">
        {markAllCompleted && (
          <div style={{ flexDirection: "row" }}>
            <Icon.Group size="large" className="back-icon">
              <Icon
                name={completed ? "check circle" : isChecked ? "check circle" : "circle outline"}
                disabled={completed}
                onClick={() => !completed && onMarkasCompletedCheckboxClick(service)}
              />
            </Icon.Group>
            <span className="secondary-font">{service.service_name}</span>
          </div>
        )}
        {!markAllCompleted && <span className="secondary-font">{service.service_name}</span>}
        {completed && (
          <Image src="../../../../../images/completedIcon.png" className="completed-image" />
        )}
        {!completed && (
          <div className="maintenance-schedule-price-section">
            {windowWidth > 500 && (
              <div className="service-price-info-section">
                <div className="service-price-info">
                  {!isAdded && (!service.price_estimate || !service.high_price_estimate) ? (
                    order && order.shop && order.shop.cdk_dealer ? (
                      <p className="price-text">{t("seeDealerPriceLbl")}</p>
                    ) : vehicleTypeId && vehicleTypeId === MD_HD_VEHICLE_TYPE_ID ? (
                      <p className="price-text">{t("mdHdpricedAtShopLbl")}</p>
                    ) : service.high_price_estimate &&
                      service.low_price_estimate &&
                      service.high_price_estimate.shop_price_estimate !=
                        service.low_price_estimate.shop_price_estimate ? (
                      <p className="price-range">
                        {" "}
                        {`${t("fromLabel")} ${numberFormatToCurrency(
                          service.low_price_estimate.shop_price_estimate
                        )} - ${numberFormatToCurrency(
                          service.high_price_estimate.shop_price_estimate
                        )}`}
                      </p>
                    ) : service.high_price_estimate ? (
                      `${numberFormatToCurrency(service.high_price_estimate.shop_price_estimate)}`
                    ) : service.price_range_from && service.price_range_to ? (
                      <p className="price-range">
                        {" "}
                        {`${t("fromLabel")} ${numberFormatToCurrency(
                          service.price_range_from
                        )} - ${numberFormatToCurrency(service.price_range_to)}`}
                      </p>
                    ) : isEmpty(activeVehicle) ? (
                      <p className="price-text">{t("addTheVehicleLabel")}</p>
                    ) : order && isEmpty(order.shop) ? (
                      <p className="price-text">{t("selectStoreLabel")}</p>
                    ) : (
                      <p className="price-text">{t("orderServicePriceRow:discountPriceAtShop")}</p>
                    )
                  ) : null}
                  {isAdded &&
                    formattedPrice(
                      shopPrice(service.price_estimate, service),
                      t,
                      user,
                      order.shop,
                      service
                    )}
                </div>
              </div>
            )}
            {markAllCompleted == false && !completed && (
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  // e.preventDefault()
                  if (service.positions && service.positions.length > 1) {
                    openModal()
                  }

                  if (!service.positions || service.positions.length < 1) {
                    addorRemoveServices(service)
                  }
                }}
                onClick={() => {
                  if (isChecked && service.positions && service.positions.length > 1) {
                    addorRemoveServices(service)
                    isChecked = true
                    setShow(false)
                  }
                  if (!isChecked && service.positions && service.positions.length > 1) {
                    // addorRemoveServices(service)
                    isChecked = true
                    setShow(true)
                  }
                }}
              />
            )}
          </div>
        )}
        {show && service.positions && service.positions.length > 1 && (
          <RecommendedServiceModal
            serviceOptions={service.positions}
            isOpenModel={open}
            serviceName={service.service_name}
            closeModal={closeModal}
            openModal={openModal}
            onSave={onSave}
            activeVehicle={activeVehicle}
          />
        )}
      </div>
      {!isLast && <Divider />}
    </>
  )
}

export default Services
