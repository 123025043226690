import React, { useState } from "react"
import DownArrowLogo from "../../../images/down-arrow.png"
import { Image } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { getShopAddressWithCountry } from "../../helpers/shopHelpers"

const TreadDepthTireDetails = ({ vehicle }) => {
  const { t } = useTranslation("treadDepthTracker")

  const [exapndTireDetails, setExpandTireDetails] = useState(false)

  const handleExpandTireDetails = () => {
    if (vehicle.tread_depth_check_date) {
      setExpandTireDetails((prev) => !prev)
    }
  }

  return (
    <div className="tire_details">
      <div className="tire_details__header">
        <p
          className={`tire_details__text ${
            vehicle.tread_depth_check_date ? "" : "container_disabled"
          } `}
        >
          {t("tireDetails")}
        </p>
        <Image
          onClick={handleExpandTireDetails}
          className={`accordin_arrow ${exapndTireDetails && "rotate_arrow"}`}
          src={DownArrowLogo}
        />
      </div>
      {exapndTireDetails && (
        <>
          <p className="last_tread_depth_checked">
            {t("lastDepthChecked")} <span>{vehicle.tread_depth_check_date || t("common:NA")}</span>
          </p>
          <p className="recorded_at_shop">{t("recordedShop")}</p>
          <p className="recorded_shop_name">
            {(vehicle.shop_details && vehicle.shop_details.name) || t("common:NA")}
          </p>
          <p className="recorded_shop__address">
            {(vehicle && vehicle.shop_details && getShopAddressWithCountry(vehicle.shop_details)) ||
              t("common:NA")}
          </p>
        </>
      )}
    </div>
  )
}

export default TreadDepthTireDetails
