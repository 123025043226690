import { Image, Input, Popup } from "semantic-ui-react"
import CrossIcon from "../../images/btn-delete.png"
import Visa from "../../images/Visa.svg"
import Maestro from "../../images/Maestro.svg"
import Mastercard from "../../images/Mastercard.svg"
import Cvv_info from "../../images/Cvv_Info.png"
import LockIcon from "../../images/LockIcon.svg"
import React, { useEffect, useState } from "react"
import store from "../../shared_component/utils/configureStore"
import Notifications from "./Notifications"
import braintree from "braintree-web"
import { ADD_NEW_BRAINTREE_CARD_URL } from "../constants/tires"
import { connect } from "react-redux"
import {
  createPaymentMethod,
  getBraintreePaymentToken,
  getPaymentCards,
  removePaymentCard,
  setDefaultPaymentCard
} from "../actions/paymentMethods"
import { withTranslation } from "react-i18next"
import { AddNewCardShimmer } from "./TirePayment"
import zipcodes from "zipcodes"
import { PAYMENT_METHODS } from "../constants/application"

function CreditCardForm({
  t,
  handleClose,
  user,
  createPaymentMethod,
  popup = true,
  setDefaultPaymentCard,
  handleDefaultCard
}) {
  const [cardNumber, setCardNumber] = useState()
  const [nameOnCard, setNameOnCard] = useState()
  const [cvv, setCvv] = useState()
  const [expirationDate, setExpirationDate] = useState()
  const [isPrimaryCard, setIsPrimaryCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showCvvInfo, setShowCvvInfo] = useState(false)
  const [isAddCardBtnDisabled, setIsAddCardBtnDisabled] = useState(true)
  const [cardNumberClone, setCardNumberClone] = useState("")
  const [zipCode, setZipCode] = useState()
  const [isExpiryDateChanged, setIsExpiryDateChanged] = useState(false)
  const [showExpiryErrorMsg, setShowExpiryErrorMsg] = useState(false)

  const handleCardNumberBlur = () => {
    if (cardNumber && cardNumber.length === 19) {
      setCardNumberClone(cardNumber)
      const maskedCardNumber = "•".repeat(12) + cardNumber.replace(/\s/g, "").slice(12) // Remove spaces before replacing
      const finalFormattedCardNumber = maskedCardNumber.replace(/(•{4})/g, "$1 ").trim() // Add spaces after every 4 digits
      setCardNumber(finalFormattedCardNumber)
    }
  }

  const handleCardNumberFocus = () => {
    if (cardNumber) {
      setCardNumber(cardNumberClone)
    }
  }

  const handleCardNumberChange = (event) => {
    const inputCardNumber = event.target.value
    const regex = /^[0-9\s]*$/

    if (regex.test(inputCardNumber)) {
      const formattedCardNumber = inputCardNumber
        .replace(/\s/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim()

      const isValid = formattedCardNumber.replace(/\s/g, "").length <= 16

      if (isValid) {
        setCardNumber(formattedCardNumber)
      }
    } else {
      setCardNumber(cardNumber || "")
    }
  }

  const handleCvvChange = (e) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === "" || re.test(e.target.value)) {
      setCvv(e.target.value)
    } else {
      setCvv(cvv ? cvv : "")
    }
  }

  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value.toUpperCase())
  }

  const handleNameOnCard = (e) => {
    const regex = /^[A-Za-z\s]*$/
    const value = e.target.value

    if (regex.test(value)) {
      setNameOnCard(value)
    } else {
      setNameOnCard(nameOnCard || "")
    }
  }

  const openCvvInfo = () => {
    setShowCvvInfo(true)
  }

  const closeCvvInfo = () => {
    setShowCvvInfo(false)
  }

  const handleIsPrimaryBtnClick = () => {
    setIsPrimaryCard(!isPrimaryCard)
  }

  const handleSetDefault = async (card) => {
    if (card.isdefault) return false
    setIsLoading(true)
    const response = await setDefaultPaymentCard(user, card.token)
    if (response && response.result) {
      handleDefaultCard && handleDefaultCard(response.result)
      store.dispatch(
        Notifications.success({
          title: t("Default_card_updated"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("Default_card_update_failed"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    setIsLoading(false)
  }

  const handleExpirationDateChange = (event) => {
    const input = event.target.value
      .replace(/^([1-9]\/|[2-9])$/g, "0$1/")
      .replace(/^(0[1-9]|1[0-2])$/g, "$1/")
      .replace(/^([0-1])([3-9])$/g, "0$1/$2")
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
      .replace(/^([0]+)\/|[0]+$/g, "0")
      .replace(/[^\d\/]|^[\/]*$/g, "")

    const formattedInput = input.replace(/[^\d]/g, "")

    const isValid = /^\d{0,2}\/?\d{0,2}$/.test(formattedInput)

    if (isValid) {
      let updatedInput = formattedInput
      if (formattedInput.length > 2) {
        updatedInput = `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`
      }
      setExpirationDate(updatedInput)

      if (updatedInput.length === 5) {
        validateExpiryDate(updatedInput)
        setIsExpiryDateChanged(!isExpiryDateChanged)
      }
    }
  }

  const validateExpiryDate = (dateToCheck) => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear() % 100
    const currentMonth = currentDate.getMonth() + 1

    const [inputMonth, inputYear] = dateToCheck.split("/").map(Number)

    if (
      inputMonth <= 12 &&
      (inputYear > currentYear || (inputYear === currentYear && inputMonth >= currentMonth))
    ) {
      setShowExpiryErrorMsg(false)
      return true
    } else {
      setShowExpiryErrorMsg(true)
      return false
    }
  }

  const validateValidZip = (value) => {
    const result = zipcodes.lookup(value)
    return result !== undefined
  }

  const addNewCard = async () => {
    if (isAddCardBtnDisabled) {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("Enter_all_details"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      setIsLoading(true)

      let trimmedCardNumber = cardNumberClone.replace(/\s/g, "")

      braintree.client.create(
        {
          authorization: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY
        },
        function (err, client) {
          let creditcardObj = {
            number: trimmedCardNumber,
            expirationDate: expirationDate,
            cvv: cvv,
            cardholderName: nameOnCard,
            billingAddress: {
              postalCode: zipCode || (user && user.zip)
            }
          }

          client.request(
            {
              endpoint: ADD_NEW_BRAINTREE_CARD_URL,
              method: "post",
              data: {
                creditCard: creditcardObj
              }
            },
            async function (err, response) {
              if (response) {
                const res = await createPaymentMethod(
                  user,
                  response.creditCards[0].nonce,
                  PAYMENT_METHODS.CREDIT_CARD
                )
                if (res) {
                  if (isPrimaryCard) {
                    await handleSetDefault(res.result[0])
                  }
                  setCardNumber("")
                  setCardNumberClone("")

                  if (popup) {
                    handleClose()
                  }
                }
              } else if (err) {
                store.dispatch(
                  Notifications.error({
                    title: t("Error"),
                    message:
                      err &&
                      err.details &&
                      err.details.originalError &&
                      err.details.originalError.error &&
                      err.details.originalError.error.message,
                    position: "tr",
                    autoDismiss: 5,
                    action: {
                      label: "Dismiss"
                    }
                  })
                )
              }
              setIsLoading(false)
            }
          )
        }
      )
    }
  }

  const renderForm = () => (
    <div className="form-parent">
      {isLoading ? (
        <div className="loading-component-center">
          <AddNewCardShimmer />
        </div>
      ) : (
        <form>
          <div className="input-parent">
            <label style={{ marginTop: "0" }}>
              {t("Card_Number")}
              <div className="rightDiv">
                <Image src={Visa} />
                <Image src={Maestro} />
                <Image src={Mastercard} />
              </div>
            </label>
            <Input
              type="text"
              id="cardNumber"
              value={cardNumber}
              icon="credit card"
              iconPosition="left"
              placeholder="_ _ _ _-_ _ _ _-_ _ _ _-_ _ _ _"
              onChange={handleCardNumberChange}
              onBlur={handleCardNumberBlur}
              onFocus={handleCardNumberFocus}
              onPaste={(e) => {
                e.preventDefault()
                return false
              }}
              onCopy={(e) => {
                e.preventDefault()
                return false
              }}
            />
          </div>
          <div className="security-inputs">
            <div className="input-parent">
              <label>{t("Name_on_card")}</label>
              <Input
                type="text"
                placeholder={t("Name")}
                value={nameOnCard}
                onChange={handleNameOnCard}
                onPaste={(e) => {
                  e.preventDefault()
                  return false
                }}
                onCopy={(e) => {
                  e.preventDefault()
                  return false
                }}
              />
            </div>
            <div className="input-parent">
              <label>{t("Security_Code")}</label>
              <Input
                type="password"
                placeholder={t("digits_code")}
                maxLength="4"
                autoComplete="new-password"
                value={cvv}
                onChange={handleCvvChange}
                onPaste={(e) => {
                  e.preventDefault()
                  return false
                }}
                onCopy={(e) => {
                  e.preventDefault()
                  return false
                }}
              />
              <Popup
                className="cvv-popup"
                on="click"
                pinned
                open={showCvvInfo}
                trigger={
                  <p className="require-text" onClick={openCvvInfo}>
                    {t("What_is_this")}
                  </p>
                }
              >
                <div className="cvv-input-info">
                  <Image src={Cvv_info} />
                  <span>{t("Cvv_info")}</span>
                  <Image src={CrossIcon} className="cross-icon" onClick={closeCvvInfo} />
                </div>
              </Popup>
            </div>
          </div>
          <div className="security-inputs-zip">
            <div className="input-parent">
              <label>{t("Expiration_Date")}</label>
              <Input
                type="text"
                placeholder="MM/YY"
                onChange={handleExpirationDateChange}
                maxLength="5"
                value={expirationDate}
                onPaste={(e) => {
                  e.preventDefault()
                  return false
                }}
                onCopy={(e) => {
                  e.preventDefault()
                  return false
                }}
              />
            </div>
            <div className="input-parent">
              <label>{t("Zip_Code")}</label>
              <Input
                type="text"
                placeholder="Zip Code"
                onChange={handleZipCodeChange}
                maxLength="6"
                value={zipCode}
              />
            </div>
          </div>
          <div className="security-inputs-zip">
            <div className="input-parent ui checkbox" onClick={handleIsPrimaryBtnClick}>
              <input type="checkbox" checked={isPrimaryCard} />
              <label>{t("Make_primary_card")}</label>
            </div>
            <div className="input-parent" onClick={addNewCard}>
              <button
                className={`${!isAddCardBtnDisabled ? "active-btn" : ""}`}
                disabled={isAddCardBtnDisabled}
              >
                {t("Add_card")}
              </button>
            </div>
          </div>
          {popup && (
            <span className="payment-protected-info">
              <img src={LockIcon} alt="secure-lock" />
              {t("Payment_info_protected")}
            </span>
          )}
        </form>
      )}
    </div>
  )

  useEffect(() => {
    if (showExpiryErrorMsg) {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("Enter_expiry_date_from_future"),
          position: "tr",
          autoDismiss: 3,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }, [showExpiryErrorMsg, isExpiryDateChanged])

  useEffect(() => {
    if (
      !cardNumber ||
      cardNumber === "" ||
      !nameOnCard ||
      nameOnCard === "" ||
      !cvv ||
      cvv === "" ||
      !expirationDate ||
      expirationDate === "" ||
      expirationDate.length < 5 ||
      !validateExpiryDate(expirationDate) ||
      !zipCode ||
      zipCode === "" ||
      zipCode.length < 5 ||
      !validateValidZip(zipCode)
    ) {
      setIsAddCardBtnDisabled(true)
    } else {
      setIsAddCardBtnDisabled(false)
    }
  }, [cardNumber, nameOnCard, cvv, expirationDate, zipCode])

  return popup ? (
    <div
      className="add-new-modal-backdrop"
      onClick={() => {
        handleClose()
      }}
    >
      <div
        className={`add-new-modal-content ${isLoading ? "add-new-modal-loading" : ""}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="card-modal-header">
          <h2>{t("Add_new_card")}</h2>
          <Image src={CrossIcon} className="cross-icon" onClick={handleClose} />
        </div>
        <hr />
        {renderForm()}
      </div>
    </div>
  ) : (
    renderForm()
  )
}

function mapStateToProps(state, props) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps, {
  getBraintreePaymentToken,
  createPaymentMethod,
  removePaymentCard,
  setDefaultPaymentCard,
  getPaymentCards
})(withTranslation("tirePayment")(CreditCardForm))
