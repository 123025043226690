import React, { useState, useEffect } from "react"
import ReactMapGL, { Marker } from "react-map-gl"
import { Card, Divider } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapPin } from "@fortawesome/pro-light-svg-icons"
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
const ICON_WIDTH = 18
const ICON_HEIGHT = 32
function MapCard(props) {
  //we will be passing props to this component that will contain location:{  longitude: -122.45,latitude: 37.78,} and address, hours, contact number
  const [viewport, setViewport] = useState({
    // width: '100%',
    // height: '100%',
    longitude: -122.45,
    latitude: 37.78,
    zoom: 10,
    pitch: 0,
    bearing: 0
  })

  return (
    <Card raised className="map-card-card">
      <Card.Content className="map-card-div">
        <Card.Header className="map-card-store">STORE DETAILS</Card.Header>
        <Card.Description className="map-card-map">
          <ReactMapGL
            {...viewport}
            width="100%"
            height="100%"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            onViewportChange={(viewport) => setViewport(viewport)}
          >
            <Marker
              latitude={37.7577} //props.location.latitude
              longitude={-122.4376} //props.location.longitude
              offsetLeft={-(ICON_WIDTH / 2)}
              offsetTop={-ICON_HEIGHT}
              className="onTop"
            >
              <FontAwesomeIcon
                icon={faMapPin}
                fixedWidth
                className="caradvise-electric-blue"
                style={{
                  cursor: "pointer",
                  width: `${ICON_WIDTH}px`,
                  height: `${ICON_HEIGHT}px`
                }}
                //   onClick={() => ()}
              />
            </Marker>
          </ReactMapGL>
        </Card.Description>

        <Card.Header className="map-card-address-header">Address</Card.Header>
        {/* props.address */}
        <Card.Description className="map-card-address-description">
          Estadous Unidos
        </Card.Description>
        <Divider style={{ width: "100%", color: "black" }} />
        <Card.Header className="map-card-hours-header">Hours</Card.Header>

        {/* props.hour-details */}
        <Card.Description className="map-card-hours-description">
          Mon-Fri 08:00-17:00
        </Card.Description>

        <Card.Description className="map-card-hours-description">Sat 08:00-12:00</Card.Description>
        <Card.Description className="map-card-hours-description">Sun Closed</Card.Description>
        <Divider style={{ width: "100%", color: "black" }} />
        <Card.Header className="map-card-contact-header">Contact number</Card.Header>
        {/* props.contact */}
        <Card.Description className="map-card-contact-description">1234567890</Card.Description>
      </Card.Content>
    </Card>
  )
}
function mapStateToProps(state, props) {
  return {
    state: state
  }
}
export default connect(mapStateToProps, {})(withTranslation("mapCard")(MapCard))
