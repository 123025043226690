import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import store from "../../../shared_component/utils/configureStore"
import { Grid } from "semantic-ui-react"
import Button from "../../components/Button.js"
import MembershipUpgradeContent from "./MembershipUpgradeContent"
import MembershipCongratsContent from "./MembershipCongratsContent"
import Notifications from "../Notifications"
import { createMembership, getMembershipPlans } from "../../actions/membership"
import {
  nextMembershipUpgrades,
  usesMembership,
  isPremium,
  planFor,
  includesRoadside
} from "../../helpers/membershipHelpers"
import MembershipTerms from "./MembershipTerms"
import { PERSONAL_INFO_SECTION, PAYMENT_SECTION } from "../../accountMobile"
import MiniLoader from "../MiniLoader"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { getPaymentMethods } from "../../actions/paymentMethods.js"

const { BaseModalDialog } = elements

const UPGRADE = "upgrade"
const PAYMENT = "payment"
const USER_INFO = "userInfo"
const CONGRATS = "congrats"

class MembershipUpgradeModal extends React.Component {
  state = {
    isSubmitting: false,
    activeContent: UPGRADE,
    selectedMembership: null,
    customMessage: null
  }

  async componentDidMount() {
    const { user, getMembershipPlans } = this.props
    await this.props.getPaymentMethods(user)
    if (usesMembership(user)) {
      getMembershipPlans()
      this.setInitialMembership()
    }
  }

  componentDidUpdate() {
    this.setInitialMembership()
  }

  setInitialMembership = () => {
    const { nextUpgrades } = this.props
    const { selectedMembership } = this.state
    if (nextUpgrades.length > 0 && !selectedMembership) {
      const plan = nextUpgrades.find((u) => isPremium(u)) || nextUpgrades[0]
      this.setState({ selectedMembership: plan })
    }
  }

  attemptCreateMembership = (payload) => {
    const { paymentMethods, createMembership, user, t, currentMembershipPlan } = this.props
    const { firstName, lastName, cellPhone, zip } = user
    const { selectedMembership } = this.state

    const missingPayment = !paymentMethods || paymentMethods.length < 1
    const paymentMethodRequired =
      !selectedMembership.is_free_trial || !selectedMembership.trial_requires_opt_in
    const missingPersonalInfo =
      includesRoadside(selectedMembership) && (!firstName || !lastName || !cellPhone || !zip)

    if (missingPayment && paymentMethodRequired && missingPersonalInfo) {
      this.setState({
        activeContent: USER_INFO,
        customMessage: t("enterPaymentMethodAndAccountInfoMessage")
      })
    } else if (missingPayment && paymentMethodRequired) {
      this.setState({ activeContent: PAYMENT })
    } else if (missingPersonalInfo) {
      this.setState({ activeContent: USER_INFO })
    } else {
      this.setState({ isSubmitting: true }, async () => {
        const response = await createMembership({
          plan: selectedMembership,
          callback: this.afterRequest
        })
        if (response().result) {
          await identifyEvent({ traits: { selectedPlan: response().result.plan_name } })
          trackEvent("plan-type-chosen", {
            selectedPlan: response().result.plan_name,
            previousPlan: (currentMembershipPlan && currentMembershipPlan.name) || null,
            duringOnboarding: "No"
          })
        }
      })
    }
  }

  afterRequest = (status, data) => {
    const { t } = this.props
    this.setState({ isSubmitting: false }, () => {
      if (status === "success") {
        this.setState({ activeContent: CONGRATS })
      } else {
        store.dispatch(
          Notifications.error({
            title: t("errorNotificationTitle"),
            message: `${data.message || t("errorNotificationMessage")}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }

  onSelectMembership = (plan) => {
    this.setState({ selectedMembership: plan })
  }

  navigateToMyAccount = (section) => {
    const { closeModal, history, location } = this.props

    if (section == PAYMENT_SECTION) {
      history.push({
        pathname: "/payment_info",
        state: { section: section }
      })
    } else if (section == PERSONAL_INFO_SECTION) {
      history.push({
        pathname: "/personal_info",
        state: { section: section }
      })
    }
    closeModal()
  }

  contentConfig = () => {
    const { closeModal } = this.props
    const { selectedMembership } = this.state
    const { t } = this.props

    return {
      [UPGRADE]: {
        headerTitle: t("headerTitle"),
        buttonText: t("upgradeMeToBtn", { membershipName: selectedMembership.name }),
        onButtonClick: this.attemptCreateMembership
      },
      [PAYMENT]: {
        headerTitle: t("upgradeToText", { membershipName: selectedMembership.name }),
        buttonText: t("addPaymentMethodBtn"),
        onButtonClick: () => this.navigateToMyAccount(PAYMENT_SECTION)
      },
      [USER_INFO]: {
        headerTitle: t("upgradeToText", { membershipName: selectedMembership.name }),
        buttonText: t("updateInfoBtn"),
        onButtonClick: () => this.navigateToMyAccount(PERSONAL_INFO_SECTION)
      },
      [CONGRATS]: {
        headerTitle: t("congratulationTitle"),
        buttonText: t("gotItBtn"),
        onButtonClick: closeModal
      }
    }[this.state.activeContent]
  }

  disableButton = () => {
    const { selectedMembership } = this.state
    const roadsideIncomplete =
      includesRoadside(selectedMembership) &&
      (!selectedMembership.acceptedPassengerVehicleClause ||
        !selectedMembership.acceptedTermsAndConditions)

    return this.state.isSubmitting || roadsideIncomplete
  }

  updateSelectedMembership = (payload) => {
    const { selectedMembership } = this.state
    this.setState({ selectedMembership: { ...selectedMembership, ...payload } })
  }

  render() {
    const {
      closeModal,
      user,
      nextUpgrades,
      variableContentType,
      preMembershipDiscountSubtotal,
      altButtonText,
      altButtonOnClick,
      showUpgradeBenefits,
      currentMembershipPlan
    } = this.props
    const { activeContent, isSubmitting, selectedMembership, customMessage } = this.state
    if (!selectedMembership) return null

    const config = this.contentConfig()
    const { t } = this.props

    return (
      <BaseModalDialog
        headerTitle={config.headerTitle}
        isOpen
        toggleModal={closeModal}
        overflow={activeContent === UPGRADE}
      >
        {activeContent === UPGRADE && (
          <React.Fragment>
            <MembershipUpgradeContent
              variableContentType={variableContentType}
              nextUpgrades={nextUpgrades}
              preMembershipDiscountSubtotal={preMembershipDiscountSubtotal}
              onSelectMembership={this.onSelectMembership}
              selectedMembership={selectedMembership}
              updateSelectedMembership={this.updateSelectedMembership}
              currentMembership={user.membership || {}}
            />
            <MembershipTerms />
          </React.Fragment>
        )}

        {activeContent === PAYMENT && (
          <span style={{ padding: "30px 0" }}>
            {`${t("missingInfoMessage", { selectedMembership: selectedMembership.name })} `}
            {customMessage || t("paymentMethodMissingMessage")}
          </span>
        )}

        {activeContent === USER_INFO && (
          <span style={{ padding: "30px 0" }}>
            {`${t("missingInfoMessage", { selectedMembership: selectedMembership.name })} `}
            {customMessage || t("userInfoMissingMessage")}
          </span>
        )}

        {activeContent === CONGRATS && (
          <MembershipCongratsContent
            plan={currentMembershipPlan}
            showUpgradeBenefits={showUpgradeBenefits}
          />
        )}

        <Grid centered columns={8}>
          <Grid.Row>
            <Button
              disabled={activeContent === UPGRADE && this.disableButton()}
              style={{ margin: "0.3em", height: "0.9em" }}
              colorStyle="orange"
              styleText={{ color: "white", fontSize: "0.8em" }}
              text={isSubmitting ? <MiniLoader /> : config.buttonText}
              onClick={config.onButtonClick}
            />
            {activeContent === UPGRADE && (
              <Button
                style={{ margin: "0.3em", height: "0.9em" }}
                colorStyle="grey"
                styleText={{ color: "white", fontSize: "0.8em" }}
                text={altButtonText || t("cancelBtn")}
                onClick={altButtonOnClick || closeModal}
              />
            )}
          </Grid.Row>
        </Grid>
      </BaseModalDialog>
    )
  }
}

MembershipUpgradeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variableContentType: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  preMembershipDiscountSubtotal: PropTypes.number
}

function mapStateToProps(state, ownProps) {
  let { user, membershipData, paymentMethods } = state
  const { availableMembershipPlans } = membershipData

  return {
    paymentMethods: paymentMethods.braintreePaymentMethods,
    user: user || {},
    availableMembershipPlans: availableMembershipPlans || [],
    currentMembershipPlan: planFor(user, availableMembershipPlans),
    nextUpgrades: nextMembershipUpgrades(user, availableMembershipPlans).reverse(),
    createMembership,
    getMembershipPlans
  }
}

export default withRouter(
  connect(mapStateToProps, { createMembership, getMembershipPlans, getPaymentMethods })(
    withTranslation("membershipUpgradeModal")(MembershipUpgradeModal)
  )
)
