import React, { Component } from "react"
import CheckoutIndex from "./CheckoutIndex"
import { REVIEW_PAGE } from "../../../shared_component/constants/common"

class ReviewComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: (this.props.location.state && this.props.location.state.showMapListView) || false
    }
  }
  render() {
    const { history, match } = this.props
    const { showMap } = this.state
    return (
      <CheckoutIndex
        pageContent={REVIEW_PAGE}
        history={history}
        showMap={showMap}
        paramsId={match.params.id}
      />
    )
  }
}
export default ReviewComponent
