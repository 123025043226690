import React, { Component } from "react"
import { Button } from "semantic-ui-react"

class PaymentMethodDefaultIcon extends Component {
  render() {
    return <Button icon="check" className="defaultSelectedPayment" />
  }
} // PaymentMethodDefaultIcon

export default PaymentMethodDefaultIcon
