import React from "react"
import { Provider } from "react-redux"
import store, { getPersistor } from "./shared_component/utils/configureStore"
import { PersistGate } from "redux-persist/integration/react"
import Router from "./Router"
import LoadingComponent from "./v1/components/LoadingComponent"
import { I18nextProvider } from "react-i18next"
import i18n from "./shared_component/utils/i18n"
import { isCarPerks } from "./v1/helpers/carPerksHelpers"
import ZendeskChatWidget from "./v1/components/ZendeskChatWidget"
import SentryInjector from "./v1/components/Config/SentryInjector"
import StylesheetInjector from "./v1/components/Config/StylesheetInjector"

export default class App extends React.Component {
  async componentDidMount() {
    SentryInjector()
    StylesheetInjector()
    if (isCarPerks() && !window.location.hash) {
      window.location.href = `${process.env.REACT_APP_ROOT_URL}#/carperks${window.location.search}`
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props
    const previousLocation = prevProps.location.pathname

    if (pathname !== previousLocation) {
      window.Appcues.page()
    }
  }

  render() {
    // TODO: figure out why we're using PersistGate- what problem it solves.
    // The app seems to sometimes have trouble properly refreshing data, and this may be the cause.
    // Should we keep or remove this?
    return (
      <Provider store={store}>
        <PersistGate loading={<LoadingComponent />} persistor={getPersistor()}>
          <I18nextProvider i18n={i18n}>
            <ZendeskChatWidget />
            <Router />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    )
  }
}
