import React, { Component, useEffect, useState } from "react"

import { connect } from "react-redux"

import { withTranslation } from "react-i18next"

import { logoForShop } from "../../../../../shared_component/utils/shopLogos"

import { Image } from "semantic-ui-react"

const style = {
  "flex-direction": "row"
}

export const CARD_MARGIN = 5
const NO_SHOP = "NO_SHOP"

class ShopListItems extends Component {
  constructor(props) {
    super(props)

    this.carousel = React.createRef()
  }

  onPreSelect = (shop) => {
    this.props.onPreSelect(shop === this.props.selectedShop ? NO_SHOP : shop)
  }

  render() {
    const { shops } = this.props
    return shops && shops.length > 0 ? (
      <div className="shop_list_floating_items">
        {shops.map((shop, index) => {
          const src = logoForShop(shop)

          return (
            <div className="shop_list_items">
              <div className="services-dashboard-icon-container">
                <Image src={src} className="service-dashboard-icons" size="mini" />
              </div>
              <div className="services-dashboard-grid-item-title">
                <p className="shop_name">
                  {shop.name}
                  <br />
                  <p className="miles">{shop.distance_config.display_value}</p>
                </p>
              </div>
            </div>
          )
        })}
      </div>
    ) : (
      <div />
    )
  }
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps, {})(withTranslation(["ShopListItems"])(ShopListItems))
