import { debounce, get } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { getTrackBackground, Range } from "react-range"
import { END_PRICE, START_PRICE, TIRE_FILTER_PRICE, TIRE_FILTER_RANGE } from "../../constants/tires"
import { useTranslation } from "react-i18next"

const TireFilterRange = ({ max = 0, min = 0, handleSelectedFilter, tireSelectedFilters }) => {
  const { t } = useTranslation("tireFilters")

  const initialMin = get(
    tireSelectedFilters,
    `${TIRE_FILTER_PRICE}.${TIRE_FILTER_RANGE}.${START_PRICE}`,
    min
  )
  const initialMax = get(
    tireSelectedFilters,
    `${TIRE_FILTER_PRICE}.${TIRE_FILTER_RANGE}.${END_PRICE}`,
    max
  )

  const [value, setValue] = useState([initialMin, initialMax])

  useEffect(() => {
    const updatedMin = get(
      tireSelectedFilters,
      `${TIRE_FILTER_PRICE}.${TIRE_FILTER_RANGE}.${START_PRICE}`,
      min
    )
    const updatedMax = get(
      tireSelectedFilters,
      `${TIRE_FILTER_PRICE}.${TIRE_FILTER_RANGE}.${END_PRICE}`,
      max
    )
    setValue([updatedMin, updatedMax])
  }, [tireSelectedFilters, min, max])

  const debouncedUpdateFilterState = useCallback(
    debounce((newRange) => {
      handleSelectedFilter(
        {
          min: newRange[0],
          max: newRange[1]
        },
        TIRE_FILTER_PRICE,
        TIRE_FILTER_RANGE
      )
    }, 100),
    []
  )

  const handleChange = (value) => {
    setValue(value)
  }

  return (
    <div className="tire__slider-container">
      <p className="price__value">
        {t("price")}: ${value[0]}-${value[1]}
      </p>
      <Range
        draggableTrack
        values={value}
        step={1}
        min={min}
        max={min === max ? min + 1 : max}
        disabled={min === max}
        onChange={(values) => {
          handleChange(values)
          debouncedUpdateFilterState(values)
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "8px",
              width: "100%",
              background: getTrackBackground({
                values: value,
                colors: ["#ddd", "#444444", "#ddd"],
                min: min,
                max: max
              }),
              borderRadius: "5px"
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px"
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              border: "1px solid #BDBDBD"
            }}
          />
        )}
      />
    </div>
  )
}

export default TireFilterRange
