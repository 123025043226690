import React from "react"
import { connect } from "react-redux"
import TireConnect from "./TireConnect"

const TIRE_INTEGRATION_NAME = "TireConnect"

const Tires = (props) => {
  if (props.cart.shop.tire_integration === TIRE_INTEGRATION_NAME) {
    return <TireConnect {...props} />
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  const { activeVehicleId, active_carts, vehicles } = user
  let cart = {}
  let vehicle = {}

  if (activeVehicleId) {
    const activeVehicle = vehicles.find((vehicle) => vehicle.id === activeVehicleId)
    if (activeVehicle) vehicle = activeVehicle

    if (active_carts) {
      const activeCart = active_carts.find((cart) => cart.vehicle_id === activeVehicleId)
      if (activeCart) cart = activeCart
    }
  }

  return { user, cart, vehicle }
}

export default connect(mapStateToProps)(Tires)
