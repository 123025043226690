import "core-js/fn/array/index"
import React from "react"
import { routerPaths } from "./v1/constants/paths"
import { Route, HashRouter, Switch, withRouter, Redirect } from "react-router-dom"
import Loadable from "react-loadable"
import Landing from "./v1/components/Landing"
import MaintenanceScheduleHistoryDetails from "./v1/CarAdvise2/screens/maintenanceSchedule/MaintenanceScheduleHistoryDetails"
import DashHeader_v2 from "./v2/components/header.js"
import RegFooter from "./v1/components/regFooter.js"
import LeftNav from "./v1/components/leftNav.js"
import MobileNav from "./v1/components/MobileNav.js"
import AccountDetails from "./v1/registration/accountDetails"
import MembershipSelect from "./v1/registration/MembershipSelect"
import CCDetails from "./v1/registration/ccDetails"
import MembershipFlowCCDetails from "./v1/registration/MembershipFlowCCDetails.js"
import Offers from "./v1/offers"
import Video from "./v1/video"
import Settings from "./v1/settings"
import SignIn from "./v1/registration/signIn"
import OauthSignin from "./v1/registration/oauthSignin"
import Password from "./v1/registration/password"
import ResetPassword from "./v1/registration/resetpassword"
import ApprovalDetail from "./v1/checkout/approvalDetail"
import Vin from "./v1/registration/vin"
import Warranty from "./v1/registration/warranty"
import Diagnosis from "./v1/Diagnosis"
import Recall from "./v1/registration/recall"
import MoreInfo from "./v1/moreInfo"
import PriceList from "./v1/pricelist"
import Notifications from "./v1/notifications"
import NotificationContainer from "./v1/components/NotificationContainer"
import InitialDataLoader from "./v1/components/InitialDataLoader"
import VehicleDetails from "./v1/registration/vehicleDetails"
import MyGarageVehicleDetails from "./v1/components/MyGarageVehicleDetails"
import GoogleTagManager from "./v1/components/GoogleTagManager"
import SideMenu from "./v1/sideMenu"
import LoadingMask, { Loader } from "./v1/components/LoadingMask"
import LoadingComponent from "./v1/components/LoadingComponent"
import UberLandingRedirectPage from "./v1/components/Uber/UberLandingRedirectPage"
import MyAccount from "./v1/MyAccount"
import ApprovalsFollowup from "./v1/components/Approvals/ApprovalsFollowup"
import Tires from "./v1/components/Tires"
import Appcues from "./v1/components/Config/Appcues"
import { AFFILIATION_PARAM } from "./shared_component/utils/urlUtils"
import { isCarPerks } from "./v1/helpers/carPerksHelpers"
// import PurchaseCaradviseCash from "./v1/components/PurchaseCaradviseCash/PurchaseCaradviseCash"
import MembershipPanel from "./v1/components/Membership/MembershipPanel"
import ConfirmDetails from "./v1/components/ConfirmDetails"
import WarrantyPlan from "./v1/components/WarrantyPlan"
import PlanDetails from "./v1/components/PlanDetails"
import CarMileage from "./v1/components/CarMileage"
import ChooseCoverage from "./v1/components/ChooseCoverage"
import AutoGlass from "./v1/components/AutoGlass"
import TopTires from "./v1/components/TopTires"
import TireDetails from "./v1/components/TireDetails"
import ConfirmTire from "./v1/components/ConfirmTire"
import ConfirmInstallation from "./v1/components/ConfirmInstallation"
import ConfirmInstallationDate from "./v1/components/ConfirmInstallationDate"
import TireSize from "./v1/components/TireSize"
import MaintenanceDetails from "./v1/components/MaintenanceSchedules/MaintenanceDetails"
import Booking from "./v1/components/Booking"
import RedirectWarning from "./v1/components/RedirectWarning"
import BookService from "./v1/CarAdvise2/screens/dashboard/bookService"
import BookingConfirmation from "./v1/components/BookingConfirmation"
// import CollisionRepair from "./v1/components/CollisionRepair"
// import DownloadAppModal from "./v1/components/DownloadAppModal"
import LeftNav_v2 from "./v2/components/LeftNav_v2"
import SideMenu_v2 from "./v2/components/SideMenu_v2"
import CartIcon from "./v2/components/Cart/CartIcon"
import AddServicesIndex from "./v2/components/Services/AddServicesIndex"
import CheckoutIndex from "./v2/components/Checkout/CheckoutIndex"
import CartSummary from "./v2/components/Cart/CartSummary/CartSummary"
import ReviewOrderIndex from "./v2/components/ReviewOrder/ReviewOrderIndex"
import EarnMilesIndex from "./v2/components/Rewards/EarnMiles/EarnMilesIndex"
import SponspredPromotionIndex from "./v2/components/Rewards/SponsoredPromotions/SponspredPromotionIndex"
import RewardsIndex from "./v2/components/Rewards/RewardsIndex"
import RewardsHistoryPage from "./v2/components/RewardsHistory/RewardsHistoryPage"
import MagicLinkIndex from "./shared_component/components/MagicLink/MagicLinkIndex"
import { Loader2 } from "./shared_component/components/Loader2.js"
import NewLoadingComponent from "./v1/components/NewLoadingComponent"
import ContentLoader from "./shared_component/components/ContentLoader"
import ScheduleComponent from "./v2/components/Checkout/ScheduleComponent.js"
import PaymentComponent from "./v2/components/Checkout/PaymentComponent.js"
import ReviewComponent from "./v2/components/Checkout/ReviewComponent.js"

const render = () => (loaded, props) => {
  let Element = loaded.default
  return (
    <LoadingMask>
      <Element {...props} />
    </LoadingMask>
  )
}

const ApprovalsNew = Loadable({
  loader: () => import("./v1/components/Approvals/ApprovalsNew"),
  render: render(),
  loading: ContentLoader
})
const Dashboard = Loadable({
  loader: () => import("./v2/components/Dashboard"),
  render: render(),
  loading: Loader2
})
const DownloadDeepLinkModal = Loadable({
  loader: () => import("./v1/components/download"),
  render: render(),
  loading: LoadingComponent
})
const GloveboxComponent = Loadable({
  loader: () => import("./v1/glovebox/GloveboxComponent"),
  render: render(),
  loading: ContentLoader
})
const ServiceDetail = Loadable({
  loader: () => import("./v1/registration/serviceDetail"),
  render: render(),
  loading: Loader2
})
const RegSuggested = Loadable({
  loader: () => import("./v1/registration/suggestedMaintenance"),
  render: render(),
  loading: ContentLoader
})
const ManageVehicles = Loadable({
  loader: () => import("./v1/vehicles/manageVehicles"),
  render: render(),
  loading: ContentLoader
})
const MaintenanceHistory = Loadable({
  loader: () => import("./v1/components/MaintenanceHistory/MaintenanceHistory"),
  render: render(),
  loading: ContentLoader
})
const InviteFriends = Loadable({
  loader: () => import("./v1/components/InviteFriends/InviteFriends"),
  render: render(),
  loading: ContentLoader
})

const ShopAppointmentTimePicker = Loadable({
  loader: () => import("./v1/components/ShopAppointmentTimePicker"),
  render: render(),
  loading: Loader2
})

const UpdatePasswordScreen = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/PasswordScreen"),
  render: render(),
  loading: NewLoadingComponent
})

const PaymentInformationScreen = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/PaymentScreen"),
  render: render(),
  loading: NewLoadingComponent
})

const PersonalInformationScreen = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/PersonalInfoScreen"),
  render: render(),
  loading: NewLoadingComponent
})
const ManageAlertsScreen = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/ManageAlerts"),
  render: render(),
  loading: NewLoadingComponent
})
const MyProfileHOC = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/MyProfileHoc"),
  render: render(),
  loading: NewLoadingComponent
})
const AccountTierScreen = Loadable({
  loader: () => import("./v1/CarAdvise2/screens/account/AccountTier"),
  render: render(),
  loading: NewLoadingComponent
})

const LoadableRescheduleScreen = Loadable({
  loader: () => import("./v2/components/Schedule/RescheduleIndex"),
  render: render(),
  loading: Loader2
})

class Router_v2 extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path={routerPaths.signUp1}
          render={(props) => (
            <LoadingMask disableLoggedInCheck={true}>
              <AccountDetails isSignUp={true} {...props} />
            </LoadingMask>
          )}
        />
        <Route
          path="/carperks/signup"
          render={(props) => {
            const { location } = props
            const params = new URLSearchParams(location.search)
            const affiliationReference = params.get(AFFILIATION_PARAM)
            const signupStep = params.get("step")

            const isStep1 = !signupStep || signupStep === "1"
            const isStep2 = signupStep === "2"
            const isStep3 = signupStep === "3"

            return (
              <LoadingMask affiliation={affiliationReference} disableLoggedInCheck={false}>
                <AccountDetails
                  affiliation={affiliationReference}
                  carperks
                  isSignUp={true}
                  {...props}
                />
              </LoadingMask>
            )
          }}
        />

        <Route
          path="/carperks"
          render={(props) => {
            return <Redirect to={{ pathname: "/carperks/signup", search: props.location.search }} />
          }}
        />
        <Route
          path="/invite/:referralCode"
          render={(props) => <AccountDetails isSignUp={true} {...props} />}
        />
        <Route
          path={routerPaths.selectMembership}
          render={(props) => (
            <LoadingMask disableLoggedInCheck={false}>
              <MembershipSelect {...props} />
            </LoadingMask>
          )}
        />
        <Route
          path={routerPaths.signIn}
          render={(props) => (
            <LoadingMask>
              <SignIn {...props} />
            </LoadingMask>
          )}
        />
        <Route path={routerPaths.magicLink} render={(props) => <MagicLinkIndex {...props} />} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <>
            <SideMenu_v2 />
            <LeftNav_v2 />
          </>
          <div className="container" style={{ flex: "1.0" }}>
            <DashHeader_v2 isCarAdviseNavigation={this.props.isCarAdviseNavigation} />
            {!/cart-summary|review|reschedule-appointment|checkout|approvals/.test(
              window.location.href
            ) && <CartIcon />}
            <div className="mainContainer dashScreen" style={{ paddingTop: "0px !important" }}>
              <MobileNav />
              <div className="content_v2">
                <GoogleTagManager />
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <Landing carperks={isCarPerks()} {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  exact
                  path="/uber"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={true}>
                      <UberLandingRedirectPage {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/service_signin"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={true}>
                      <OauthSignin {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/collect_cc"
                  render={(props) => (
                    <LoadingMask>
                      <CCDetails isTextAdvise={true} {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.download}
                  render={(props) => <DownloadDeepLinkModal {...props} />}
                />
                <Route
                  path="/no_vehicle_vin"
                  render={(props) => (
                    <LoadingMask>
                      <Vin isSignUp={false} isMissingVehicle={true} {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/addvehiclebyvin"
                  render={(props) => (
                    <LoadingMask>
                      <Vin isSignUp={false} {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.review}
                  render={(props) => <ReviewOrderIndex {...props} />}
                />
                <Route
                  path="/maintenanceschedule"
                  render={(props) => <RegSuggested {...props} />}
                />
                <Route
                  path="/maintenanceservicehistory"
                  render={(props) => <MaintenanceDetails {...props} />}
                />
                <Route path="/servicedetail" render={(props) => <ServiceDetail {...props} />} />
                <Route path="/managevehicles" render={(props) => <ManageVehicles {...props} />} />
                <Route
                  path="/addvehicle"
                  render={(props) => (
                    <LoadingMask>
                      <MyGarageVehicleDetails isAddVehicle {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/accountdetails"
                  render={(props) => (
                    <LoadingMask>
                      <AccountDetails {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/membership/:vehicleId?"
                  render={(props) => (
                    <LoadingMask>
                      <MembershipPanel {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.dashboard}
                  render={(props) => (
                    <LoadingMask>
                      <Dashboard {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/gloveboxComponent"
                  render={(props) => (
                    <LoadingMask>
                      <GloveboxComponent {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/myAccount"
                  render={(props) => (
                    <LoadingMask>
                      <MyProfileHOC {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/vehicles/:id/history"
                  render={(props) => (
                    <LoadingMask>
                      <MaintenanceHistory {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/diagnosis"
                  render={(props) => (
                    <LoadingMask>
                      <Diagnosis {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/questions/:parent_question_id"
                  render={(props) => (
                    <LoadingMask>
                      <Diagnosis {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/categories/:first_level"
                  render={(props) => (
                    <LoadingMask>
                      <Diagnosis {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/settings"
                  render={(props) => (
                    <LoadingMask>
                      <Settings {...props} />
                    </LoadingMask>
                  )}
                />
                <Route path="/approvals" render={(props) => <ApprovalsNew {...props} />} />
                <Route
                  path="/reschedule"
                  render={(props) => <ShopAppointmentTimePicker {...props} />}
                />
                <Route
                  path={routerPaths.rescheduleAppointment}
                  render={(props) => <LoadableRescheduleScreen {...props} />}
                />
                <Route
                  path="/singlependingapproval/:pendingapprovalid/:id"
                  render={(props) => (
                    <LoadingMask>
                      <ApprovalDetail {...props} />
                    </LoadingMask>
                  )}
                />
                <Route path="/approvals_confirmation" component={ApprovalsFollowup} />
                <Route
                  path="/vin"
                  render={(props) => (
                    <LoadingMask>
                      <Vin {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/password"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={true}>
                      <Password {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/offers"
                  render={(props) => (
                    <LoadingMask>
                      <Offers {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/video"
                  render={(props) => (
                    <LoadingMask>
                      <Video {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/insurance-car-mileage"
                  render={(props) => (
                    <LoadingMask>
                      <CarMileage {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/choose-coverage"
                  render={(props) => (
                    <LoadingMask>
                      <ChooseCoverage {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/customers/password"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={true}>
                      <ResetPassword {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/warranty"
                  render={(props) => (
                    <LoadingMask>
                      <Warranty {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/recalls/:id"
                  render={(props) => (
                    <LoadingMask>
                      <Recall {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/more-info"
                  render={(props) => (
                    <LoadingMask>
                      <MoreInfo {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/pricelist"
                  render={(props) => (
                    <LoadingMask>
                      <PriceList {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/notifications"
                  render={(props) => (
                    <LoadingMask>
                      <Notifications {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.suggestedMaintenanceDetails}
                  render={(props) => (
                    <LoadingMask>
                      <MaintenanceScheduleHistoryDetails {...props} />
                    </LoadingMask>
                  )}
                />

                <Route
                  path={routerPaths.inviteFriends}
                  render={(props) => <InviteFriends {...props} />}
                />
                <Route
                  path="/ConfirmDetails"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <ConfirmDetails {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/WarrantyPlan"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <WarrantyPlan {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/redirect"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <RedirectWarning {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/plan-details"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <PlanDetails {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/offer/auto-glass"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <AutoGlass {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.updatePassword}
                  render={(props) => (
                    <LoadingMask>
                      <UpdatePasswordScreen {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.paymentInformation}
                  render={(props) => (
                    <LoadingMask>
                      <PaymentInformationScreen {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.personalInfo}
                  render={(props) => (
                    <LoadingMask>
                      <PersonalInformationScreen {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.preferences}
                  render={(props) => (
                    <LoadingMask>
                      <ManageAlertsScreen {...props} />
                    </LoadingMask>
                  )}
                />

                <Route
                  path={routerPaths.accountTier}
                  render={(props) => (
                    <LoadingMask>
                      <AccountTierScreen {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/top-tires"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <TopTires {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/tire-details"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <TireDetails {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/confirm-tire"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <ConfirmTire {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/confirm-installation"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <ConfirmInstallation {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/confirm-installation-date"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <ConfirmInstallationDate {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/tire-size"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <TireSize {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/booking"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <Booking {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path="/booking-confirmation/:id"
                  render={(props) => (
                    <LoadingMask disableLoggedInCheck={false}>
                      <BookingConfirmation {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.addServices}
                  render={(props) => (
                    <LoadingMask>
                      <AddServicesIndex {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.cartSummary}
                  render={(props) => (
                    <LoadingMask>
                      <CartSummary {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.checkoutSchedule}
                  render={(props) => (
                    <LoadingMask>
                      <ScheduleComponent {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.checkoutPayment}
                  render={(props) => (
                    <LoadingMask>
                      <PaymentComponent {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.checkoutReview}
                  render={(props) => (
                    <LoadingMask>
                      <ReviewComponent {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.rewards}
                  render={(props) => (
                    <LoadingMask>
                      <RewardsIndex {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.rewardsHistory}
                  render={(props) => (
                    <LoadingMask>
                      <RewardsHistoryPage {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.earnMiles}
                  render={(props) => (
                    <LoadingMask>
                      <EarnMilesIndex {...props} />
                    </LoadingMask>
                  )}
                />
                <Route
                  path={routerPaths.sponsoredPromotions}
                  render={(props) => (
                    <LoadingMask>
                      <SponspredPromotionIndex {...props} />
                    </LoadingMask>
                  )}
                />
              </div>
            </div>
            {!/checkout/.test(window.location.href) && <RegFooter />}
          </div>
        </div>
      </Switch>
    )
  }
}

export default Router_v2
