import React, { Component, Fragment } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Settings from "./settings"
import PaymentList from "./components/PaymentList"
import PaymentMethodListHeader from "./components/PaymentMethods/PaymentMethodListHeader"
import { Segment, Divider, Accordion, Icon } from "semantic-ui-react"
import Button from "./components/Button.js"
import { usesMembership } from "./helpers/membershipHelpers"
import PropTypes from "prop-types"
import MembershipSection from "./components/MembershipSection"
import { withTranslation } from "react-i18next"

export const PERSONAL_INFO_SECTION = "personalInfo"
export const PASSWORD_SECTION = "password"
export const PAYMENT_SECTION = "payment"
export const MEMBERSHIP_SECTION = "membership"

class AccountMobile extends Component {
  constructor(props) {
    super(props)

    const state = (props.location && props.location.state) || {}
    const section = state.section
    const activeSections = section ? [section] : []

    this.state = {
      activeSections: activeSections
    }
  }

  getSections = () => {
    const { hasPaymentMethod, user, t } = this.props

    let sections = [
      {
        title: t("personalInfoTitle"),
        content: <Settings renderPersonalInfo user={user} />,
        section: PERSONAL_INFO_SECTION
      },
      {
        title: t("updatePasswordTitle"),
        content: <Settings renderUpdatePassword user={user} />,
        section: PASSWORD_SECTION
      }
    ]
    const paymentMethodSection = {
      title: t("paymentInfoTitle"),
      content: (
        <Fragment>
          <PaymentMethodListHeader hasPaymentMethod={hasPaymentMethod} />
          <PaymentList />
        </Fragment>
      ),
      section: PAYMENT_SECTION
    }
    const membershipSection = {
      title: t("accountTierTitle"),
      content: <MembershipSection style={{ textAlign: "left" }} />,
      section: MEMBERSHIP_SECTION
    }

    if (!user.generic) sections.push(paymentMethodSection)
    if (usesMembership(user)) sections.push(membershipSection)

    return sections
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeSections } = this.state
    const newSections = activeSections

    // implementation so we can have more than one account sections open at the same time
    const currentIndexPosition = activeSections.indexOf(index)
    if (currentIndexPosition > -1) {
      newSections.splice(currentIndexPosition, 1)
    } else {
      newSections.push(index)
    }
    this.setState({ activeSections: newSections })
  }

  contentSection = (section, title, content) => {
    const { activeSections } = this.state

    return (
      <Segment style={{ padding: "5px" }}>
        <Accordion.Title
          active={activeSections.includes(section)}
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            color: "#666"
          }}
          index={section}
          onClick={this.handleClick}
        >
          <span className="accountMobileSectionHeaders">{title}</span>
          <Icon name="dropdown" />
        </Accordion.Title>
        <Accordion.Content
          active={activeSections.includes(section)}
          style={{ marginTop: 0 }}
          content={
            <Fragment>
              <Divider style={{ marginTop: 0 }} />
              <div style={{ marginBottom: "15px" }}>{content}</div>
            </Fragment>
          }
        />
      </Segment>
    )
  }

  render() {
    return (
      <div style={{ marginRight: "24px", marginLeft: "10px" }}>
        <Accordion>
          {this.getSections().map((s) => this.contentSection(s.section, s.title, s.content))}
        </Accordion>
      </div>
    )
  }
}

AccountMobile.propTypes = {
  user: PropTypes.object.isRequired,
  hasPaymentMethod: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const { membershipData } = state
  let user = state.user || {}
  const { availableMembershipPlans } = membershipData

  return {
    user: user,
    hasPaymentMethod: user.has_cc_info_saved
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation("accountMobile")(AccountMobile)))
