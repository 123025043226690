import React from "react"
import { connect } from "react-redux"
import { postJSON } from "../../../../../shared_component/utils/fetch"
import { Dropdown } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { useGeolocated } from "react-geolocated"
import Geocode from "react-geocode"
import { updateActiveZip } from "../../../actions/serviceSearch"
import PopUpComponent from "./PopUpComponent"
import { DEFAULT_ZIP, KEY } from "../../../constants/serviceSearch"
import IconLocation from "../../../images/caradvise2/IconLocation.png"
const SEARCH_SERVICES_URL = "/api/v4/services/search"
Geocode.setLanguage("en")

Geocode.setLocationType("ROOFTOP")

Geocode.setApiKey(KEY)

export const ServiceSearch = (props) => {
  const { user, t, updateActiveZip, activeZip } = props
  const [servicesResult, setServicesResult] = React.useState([])
  const [zipCode, setZipCode] = React.useState("")
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    // userDecisionTimeout: 5000,
    watchPosition: true
  })

  React.useEffect(() => {
    if (!isGeolocationAvailable || !isGeolocationEnabled) {
      updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
      setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
    } else if (coords) {
      // Get the current position
      //from position to zip
      let address
      if (coords.latitude && coords.latitude) {
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            address =
              response.results[0].address_components[
                response.results[0].address_components.length - 1
              ].long_name
            updateActiveZip(address)

            setZipCode(address)
          },
          (error) => {
            updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
            setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
          }
        )
      } else {
        updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
        setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
      }
    } else {
      setZipCode("-")
    }
  }, [
    coords,
    coords && coords.latitude,
    coords && coords.longitude,
    isGeolocationAvailable,
    isGeolocationEnabled
  ])

  const searchServices = async (query) => {
    setServicesResult([])

    if (query && query.length < 3) {
      setServicesResult([])
    } else {
      try {
        const response = await postJSON(
          SEARCH_SERVICES_URL,
          {
            query: {
              type: "terms",
              where: query
            },
            vehicle_id: user.activeVehicleId
          },
          {
            Authorization: user.authentication_token
          }
        )

        if (response.result && response.result.length > 0) {
          const result = []
          response.result.map((service) => {
            result.push({
              ...service,
              key: service.id,
              text: service.name,
              value: service.id
            })
          })
          setServicesResult(result)
        } else {
          setServicesResult([])
        }
      } catch (e) {
        console.log(e, "error")
      }
    }
  }

  const handleChange = (e, { value }) => {}

  return (
    <div className="searchBox" style={{ margin: "0rem auto" }}>
      <Dropdown
        button
        fluid
        floating
        labeled
        onChange={handleChange}
        onSearchChange={(_, data) => searchServices(data.searchQuery)}
        options={servicesResult}
        placeholder={t("placeholder")}
        icon={"search"}
        search={(options) => options} // This overwrites the internal search function of the Dropdown Component
        fullTextSearch={false}
        filterRemoteData={false}
        noResultsMessage={t("errorMessage")}
        selection
        className="icon dashBoard2_0__searchContainer_Input"
        closeOnChange={true}
      />
      {/* <div className="Location">
        <div>
          <img src={IconLocation} alt="Icon Location" />
        </div>
        <div>{activeZip ? activeZip : zipCode}</div>
        <PopUpComponent />
      </div> */}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return {
    user: user,
    activeZip: state.zipSearch.activeZip
  }
}

export default connect(mapStateToProps, { updateActiveZip })(
  withTranslation("dashboardServiceSearch")(ServiceSearch)
)
