import React, { useEffect, useState } from "react"
import { Button, Card, CardContent, CardHeader, Grid } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingComponent from "./LoadingComponent"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import moment from "moment"
import { formatPlanTitle } from "../../shared_component/utils/formatPlanTitle"
import { Image } from "semantic-ui-react"
import { getSplitIOForFeatures } from "../actions/user"
import carchexLogo from "../../images/carchex-logo-offer.png"
import { getAxiomAPI, getCuvrdPlans, getForeverPlans } from "../actions/vsc"
import { getLogo } from "../actions/user"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { Redirect } from "react-router-dom"
import CurvdCard from "./Cuvrd/CuvrdCard"
import CuvrdModel from "./Cuvrd/CuvrdModel"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"

function WarrantyPlan({
  location,
  t,
  user,
  getSplitIOForFeatures,
  getAxiomAPI,
  getCuvrdPlans,
  getLogo,
  activeVehicle
}) {
  const { activeVehicleId } = user
  const userData = location.state && location.state.userData
  const [url, setUrl] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [splitResponse, setSplitResponse] = useState()
  const [image, setImage] = useState(null)
  const [partnerFlag, setPartnerFlag] = useState()
  const [resultCount, setResultCount] = useState(0)
  const [openCuvrdDialog, setOpenCuvrdDialog] = useState(false)
  const [cuvrdPartsUrl, setCuvrdPartsUrl] = useState("")
  const [cuvrdPlans, setCuvrdPlans] = useState({})
  const [cuvrdSelectedPlans, setCuvrdSelectedPlans] = useState({})
  const formatting_options = {
    currency: "USD",
    minimumFractionDigits: 2
  }
  const carchex_url = "https://www.carchex.com/auto-warranty/caradvise/?paid=100559&amp;subpaid=app"

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const handleCuvrdPlan = async () => {
    setIsLoading(true)
    const data = {
      mileage: activeVehicle.miles,
      zip_code: user.zip,
      vehicle_id: activeVehicle.id
    }
    const cuvrdResponse = await getCuvrdPlans({
      data: data,
      callback: (err) => {}
    })
    if (cuvrdResponse.error) {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: `${cuvrdResponse.error}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      setIsLoading(false)
    } else {
      setCuvrdPartsUrl(cuvrdResponse.result.covered_parts_url)
      setCuvrdPlans(cuvrdResponse.result)
      cuvrdResponse &&
        cuvrdResponse.result &&
        cuvrdResponse.result.offers &&
        setResultCount((count) => count + cuvrdResponse.result.offers.length)
      setIsLoading(false)
    }
  }

  const getSplitIODetails = async () => {
    const response = await getSplitIOForFeatures("vsc")
    setSplitResponse(response.result)
    if (response.result.axiom) {
      axiomApi()
    }
    if (response.result.curvd) {
      handleCuvrdPlan()
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getSplitIODetails()
  }, [])

  const axiomApi = async () => {
    const response = await getAxiomAPI()
    if (response && response.result.url) {
      setUrl(response.result.url)
      setResultCount((count) => count + 1)
    }
  }

  const logoApi = async () => {
    const response = await getLogo()
    setImage(response && response.result)
  }

  const handleCuvrdDialog = (plan) => {
    setOpenCuvrdDialog((prev) => !prev)
    plan && setCuvrdSelectedPlans(plan)
  }

  useEffect(() => {
    logoApi()
  }, [])

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to="/dashboard" />
      ) : (
        <div className="warranty-section">
          <div className="warranty-plan">
            <h3>
              <Link to="/ConfirmDetails">
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              {t("Step_2")}: {t("Select_warranty_plan")}
            </h3>
            <div className="plan-card">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <>
                  <div className="app">
                    <div className="left-div">
                      <h3>
                        {t("Protection_Plan_Results")} ({resultCount})
                      </h3>
                      <div className="card-row-axiom card-row">
                        {cuvrdPlans && cuvrdPlans.offers && cuvrdPlans.offers.length > 0
                          ? cuvrdPlans.offers.map((plan, index) => {
                              return (
                                <Grid.Column key={plan.productRateIdHash} className="col-md-2">
                                  <CurvdCard
                                    handleCuvrdDialog={handleCuvrdDialog}
                                    cuvrdDetail={cuvrdPlans}
                                    plan={plan}
                                    activeVehicleId={activeVehicleId}
                                  />
                                </Grid.Column>
                              )
                            })
                          : null}
                        {splitResponse && splitResponse.axiom && url ? (
                          <div className="col-md-2">
                            <Card className="axiom-card">
                              <CardHeader>
                                <h5>{t("Get_free_quote")}</h5>
                                <div className="img-parent">
                                  <Image src={image && image.axiom} />
                                </div>
                              </CardHeader>
                              <div className="quote">{t("Axiom_description")}</div>
                              <CardContent>
                                <Link
                                  to={{
                                    pathname: "/redirect",
                                    state: {
                                      url: url
                                    }
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      trackEvent(
                                        "vehicle-protection-plan-vzzbee-clicked",
                                        {},
                                        true,
                                        false,
                                        false
                                      )
                                    }
                                    className="quote-btn"
                                  >
                                    {t("Get_Quote")}
                                  </Button>
                                </Link>
                              </CardContent>
                            </Card>
                          </div>
                        ) : null}
                        {/* Commented as if required in future */}
                        {/* <div className="col-md-2">
                          <Card className="axiom-card">
                            <CardHeader className="carchex-header">
                              <div className="img-parent carchex_img">
                                <Image src={carchexLogo} />
                              </div>
                            </CardHeader>
                            <div className="quote">{t("carchex_Description")}</div>
                            <CardContent>
                              <Link
                                to={{
                                  pathname: "/redirect",
                                  state: {
                                    url: carchex_url
                                  }
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    trackEvent(
                                      "vehicle-protection-plan-carchex-clicked",
                                      {},
                                      true,
                                      false,
                                      false
                                    )
                                  }
                                  className="quote-btn"
                                >
                                  {t("View_Offer")}
                                </Button>
                              </Link>
                            </CardContent>
                          </Card>
                        </div> */}
                      </div>
                    </div>
                    <div className="purchase-plan right-div">
                      <div className="purchase-plan-head">
                        <Card className="purchase-card">
                          <CardHeader>
                            <span>{t("Purchase_plan")}</span>
                          </CardHeader>
                          <div className="card-desc">
                            {t("Snag_coverage_plan")}{" "}
                            <span className="desc-bold">{t("CarAdvise_cash")}</span>
                            *
                            <br />
                            <br /> {t("Available_in_account")}
                          </div>
                        </Card>
                      </div>
                      <div className="col-md-2 purchase-plan-desc">
                        <Card className="purchase-card">
                          <CardHeader className="purchase-card-header">
                            <span>💡 {t("How_does_this_work")}</span>
                          </CardHeader>
                          <div className="quote">{t("How_it_works_desc")}</div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {openCuvrdDialog && (
        <CuvrdModel
          cuvrdPartsUrl={cuvrdPartsUrl}
          openCuvrdDialog={openCuvrdDialog}
          handleCuvrdDialog={handleCuvrdDialog}
          selectedPlan={cuvrdSelectedPlans}
        />
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let activeVehicle =
    (user.vehicles || []).find((vehicle) => vehicle.id === user.activeVehicleId) || {}

  return {
    user: user,
    activeVehicle: activeVehicle
  }
}

export default connect(mapStateToProps, {
  getSplitIOForFeatures,
  getAxiomAPI,
  getLogo,
  getCuvrdPlans
})(withTranslation("warrantyPlan")(WarrantyPlan))
