import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import store from "../../../shared_component/utils/configureStore"
import { updateCart } from "../../actions/user"
import { Link } from "react-router-dom"
export default function RemoveShopX() {
  return (
    <span
      style={{ cursor: "pointer" }}
      // onClick={() => store.dispatch(updateCart([{ event: "set_shop", shop_id: null }], null, true))}
    >
      <Link
        to="/selectShop"
        onClick={() =>
          store.dispatch(
            updateCart(
              [
                { event: "set_shop", shop_id: null },
                { event: "set_walk_in", appointment_datetime: null },
                { event: "set_time", appointment_datetime: null }
              ],
              null,
              null,
              true
            )
          )
        }
      >
        <FontAwesomeIcon icon={faTimes} style={{ float: "right" }} />
      </Link>
      {/* <FontAwesomeIcon icon={faTimes} style={{ float: "right" }} /> */}
    </span>
  )
}
