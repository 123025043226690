import {
  PREPOPULATED_SERVICES_REQUESTED,
  LOAD_SELECTED_SERVICE,
  CLEAR_SELECTED,
  RECOMMENDED_SERVICE_DATA,
  RECOMMENDED_SERVICES_LOADING,
  RECOMMENDED_SERVICES_NOT_LOADING
} from "../actions/services"

const initialState = {}

export default function serviceReducer(state = initialState, action) {
  switch (action.type) {
    case RECOMMENDED_SERVICES_LOADING:
      return Object.assign({}, state, { recommendedServicesLoading: true })

    case RECOMMENDED_SERVICES_NOT_LOADING:
      return Object.assign({}, state, { recommendedServicesLoading: false })

    case LOAD_SELECTED_SERVICE:
      return Object.assign({}, state, { selectedService: action.selectedService })

    case CLEAR_SELECTED:
      return Object.assign({}, state, { selectedService: {}, selectedCategory: null })

    case RECOMMENDED_SERVICE_DATA:
      return Object.assign({}, state, {
        recommendedServices: action.recommendedServices,
        recommendedServicesLoading: false
      })

    case PREPOPULATED_SERVICES_REQUESTED:
      return {
        ...state,
        prepopulatedServices: action.payload.prepopulatedServices
      }

    default:
      return state
  }
}
