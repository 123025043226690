import React from "react"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function LoadingComponent(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.flexDirection,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <br />
      <br />
      <FontAwesomeIcon icon={faSpinner} size={"2x"} spin />
    </div>
  )
}

LoadingComponent.defaultProps = {
  flexDirection: "column"
}
