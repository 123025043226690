import React from "react"
import SponserPromotionCard from "../SponserPromotionCard"
import GoBackLogo from "../../../../images/go_back_icon.svg"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import { SPONSER_PROMOTION_LIST } from "../../../../shared_component/constants/rewards"

const SponspredPromotionIndex = ({ t }) => {
  return (
    <div className="sponsered-promotion-container">
      <div className="sponsered-promotion-header">
        <ReactSVG src={GoBackLogo} onClick={() => window.history.back()} className="go-back-logo" />
        <p className="sponsered-promotion-label">{t("sponsoredPromotion")}</p>
      </div>
      <div className="sponsered-promotion">
        {SPONSER_PROMOTION_LIST &&
          SPONSER_PROMOTION_LIST.length > 0 &&
          SPONSER_PROMOTION_LIST.map((sponsers) => (
            <SponserPromotionCard key={sponsers.id} sponsers={sponsers} />
          ))}
      </div>
    </div>
  )
}

export default withTranslation("rewards")(SponspredPromotionIndex)
