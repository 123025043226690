import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import BookingMembership from "./BookingMembership"
import InstallLocation from "./InstallLocation"
import BookedService from "./BookedService"
import BookingCalander from "../../../images/booking-calander.svg"
import TireImage from "../../../images/tire-image.svg"
import TirePurchaseImage from "../../../images/tire-purchase-logo.svg"
import Exlamation from "../../../images/exlamation.svg"
import moment from "moment"
import AddToCalendar from "react-add-to-calendar"
import { HOUR, HOURS } from "../../constants/tires"
import { DATE_TIME_FORMAT, SUPPORT_CARADVISE_NUMBER } from "../../constants/application"
import { formatPhoneNumber } from "../../helpers/stringHelpers"
import { ShimmerThumbnail } from "react-shimmer-effects"

const BookingDetails = ({ t, installDetails, isLoading }) => {
  const [event, setEvent] = useState()

  useEffect(() => {
    installDetails.id && buildEvent()
  }, [])

  const buildEvent = () => {
    const shop = installDetails.shop
    const start = moment(installDetails.appointment_datetime)
    const end = start.clone().add(1, HOUR)
    const duration = end.diff(start, HOURS)
    let eventFormed = {
      title: t("serviceAtText", { shopName: shop.name }),
      startDatetime: start.format(DATE_TIME_FORMAT.YYYYMMDDTHHmmss),
      endDatetime: end.format(DATE_TIME_FORMAT.YYYYMMDDTHHmmss),
      timezone: shop.timezone,
      duration: `${duration}`,
      description: `${shop.name} ${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`,
      location: `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`
    }
    setEvent(eventFormed)
  }

  return (
    <div className="booking-details">
      {isLoading ? (
        <ShimmerThumbnail className="custom-shimmer" height={25} rounded />
      ) : (
        <p className="booking-label">{t("bookingDetails")}</p>
      )}
      <div className="order-status">
        <div className="tire-image">
          {isLoading ? (
            <ShimmerThumbnail height={110} width={150} rounded />
          ) : (
            <Image src={TireImage} />
          )}
          {!isLoading && <Image className="tire-purchase-logo" src={TirePurchaseImage} />}
        </div>
        <div className="order-status-details">
          {isLoading ? (
            <ShimmerThumbnail height={20} rounded />
          ) : (
            <p className="order-status-header">
              {t("appointmentAndTirePurchase")} <span>{t("confirmed")}</span>
            </p>
          )}
          {isLoading ? (
            <ShimmerThumbnail height={20} rounded />
          ) : (
            <p className="order-id">
              {t("orderId")} {installDetails && installDetails.id}
            </p>
          )}
        </div>
      </div>
      {isLoading ? (
        <ShimmerThumbnail className="shimmer-custom-mergin" height={110} rounded />
      ) : (
        <BookingMembership />
      )}
      <InstallLocation
        isLoading={isLoading}
        installDetails={
          installDetails && installDetails.children ? installDetails.children[0] : installDetails
        }
      />
      {isLoading ? (
        <ShimmerThumbnail className="shimmer-custom-mergin" height={50} rounded />
      ) : (
        <BookedService isLoading={isLoading} installDetails={installDetails} />
      )}
      <hr />
      {isLoading ? (
        <ShimmerThumbnail className="shimmer-custom-mergin" height={50} rounded />
      ) : (
        <div className="cancellation">
          <div className="cancellation-price">
            <p className="cancellation-policy">{t("Total")}</p>
            <p className="cancellation-policy">
              {installDetails && installDetails.total} {t("usd")}
            </p>
          </div>
          <p className="non-refundable-policy">
            {" "}
            {t("needHelp")} {t("callUsAt")} {formatPhoneNumber(SUPPORT_CARADVISE_NUMBER)},{" "}
            {t("willCancel")}
          </p>
          <p className="non-refundable-policy"> {t("cancellationAvailable")}</p>
          <hr />
        </div>
      )}
      <>
        <div className="calander">
          {isLoading ? (
            <ShimmerThumbnail height={20} rounded />
          ) : (
            <div className="calendar-btn">
              <div>
                <Image src={BookingCalander} />
              </div>
              <AddToCalendar event={event && event} />
            </div>
          )}
        </div>
      </>
    </div>
  )
}

export default withTranslation("bookingDetails")(BookingDetails)
