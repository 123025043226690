import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "semantic-ui-react"
import { getServiceNameForMap } from "../../../v1/helpers/serviceHelper"
import { formattedPrice, shopPrice } from "../Cart/CartServiceRow"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { CancellationPolicy } from "./CancellationPolicy"
import { constants } from "caradvise_shared_components"
import CancelAppointmentModal from "../../../shared_component/Modals/CancelAppointmentModal"
import EstimateEarnMiles from "./EstimateEarnMiles"
const {
  orders: { CANCELLED, APPROVAL, AWAITING_APPROVAL, COMPLETED, PRICE_REVIEW }
} = constants

export const ServicesSummary = ({ cart, user, estimateEarnMiles }) => {
  const { t } = useTranslation("reviewOrder")
  const [openModal, setOpenModal] = useState(false)

  const totalPriceText = (cart) => {
    if (cart.any_order_service_lacks_price) {
      return t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(cart.estimated_total)
    }
  }

  const cancelAppointmentModal = () => {
    setOpenModal(true)
  }

  const close = () => setOpenModal(false)

  const serviceCount = cart && cart.order_services.length
  const excludedStatuses = [COMPLETED, APPROVAL, AWAITING_APPROVAL, CANCELLED, PRICE_REVIEW]
  const isTireOrder = cart && cart.is_tire_order

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  return (
    <>
      <div className="service-summary-details-container">
        <div className="service-summary-header-section">
          <div className="service-header">
            {t("servicesLabel")} {`(${serviceCount})`}
          </div>
          {/* <Image src={LeftIcon} className="service-icon" /> */}
        </div>
        <div className="service-summary-content-section">
          <ul className="service-list">
            {cart &&
              cart.order_services.map((os, index) => (
                <li className="service-list-item" key={index}>
                  <div className="service-name">
                    <span className="bullet-point">&#8226;</span>
                    {getServiceNameForMap(os)}
                  </div>
                  <div className="service-price">
                    {formattedPrice(
                      shopPrice(os.price_estimate, os),
                      t,
                      user,
                      cart.shop,
                      os,
                      isTireOrder
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="horizontal-divider" />
        <div className="estimated-total-section">
          <div className="est-section">
            <span className="est-header">{t("estTotalsLabel")}</span>
          </div>
          <div className="est-total">
            <div>{totalPriceText(cart)}</div>
          </div>
        </div>
        {estimateEarnMiles && user && user.total_shop_orders === 1 && isRewardsFeature && (
          <EstimateEarnMiles className="custom-earn-miles" estimateEarnMiles={estimateEarnMiles} />
        )}
        <CancellationPolicy />
        <div className="horizontal-divider" />
        {cart.status !== undefined && !excludedStatuses.includes(cart.status) && (
          <div className="review-cancel-btn" onClick={() => cancelAppointmentModal()}>
            {t("cancelLink:cancelApptLabel")}
          </div>
        )}
      </div>
      {openModal && (
        <CancelAppointmentModal open={openModal} close={close} order={cart} user={user} />
      )}
    </>
  )
}
