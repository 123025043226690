import React from "react"
import RatingIcon from "../../../images/Rating_Icon.png"
import WarrantyIcon from "../../../images/warranty-logo.svg"
import TireIcon from "../../../images/tire-confirm-img.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { formatPriceNumber } from "../../../shared_component/utils/helpers"
import TireMilesWarranty from "./TireMilesWarranty"

const TireDetailConfirmScreen = ({ tireImage, tireSize, tireName, milesRating, t }) => {
  const onImageError = (e) => {
    e.target.src = TireIcon
  }

  return (
    <div className="tire-detail-container">
      <div className="tire-image">
        <Image
          className="tire-logo"
          src={tireImage ? tireImage : TireIcon}
          onError={onImageError}
        />
      </div>
      <div className="tire-detail">
        <p className="tire-name">{tireName}</p>
        <p className="tire-info">
          {t("detail")} <span>{tireSize}</span>
        </p>
        {/* As per discussion with Ankur as of now there is not rating so make it comment */}
        {/* <p className="tire-rating">
          <span>
            <Image className="rating-icon" src={RatingIcon} />
          </span>{" "}
          4.5 (290)
        </p> */}
        <div className="mileage-info">
          {milesRating > 0 && <TireMilesWarranty milesRating={milesRating} />}
        </div>
      </div>
      <div className="mileage-warranty">
        {milesRating > 0 && <TireMilesWarranty milesRating={milesRating} />}
      </div>
    </div>
  )
}

export default withTranslation("confirmOrderDialog")(TireDetailConfirmScreen)
