import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import { handleTireStatusData } from "../../../helpers/vehicleHelpers"
import TireTreadDepthStatus from "../TireTreadDepthStatus"
import UnlockMilesInfoLogo from "../../../../images/rewards/unlock-miles-info.svg"
import HowTrackTireDepth from "../HowTrackTireDepth"
import { getShopAddressWithCountry } from "../../../helpers/shopHelpers"
import { isDesktop, isMobile } from "react-device-detect"
import TreadDepthTireDetails from "../TreadDepthTireDetails"
import { isEmpty } from "lodash"
import { TIRE_DEPTH_STATUS } from "../../../constants/tires"
import DownArrowLogo from "../../../../images/down-arrow.png"
import { Image } from "semantic-ui-react"

const TireModuleTreadDepth = ({ vehicle }) => {
  const { t } = useTranslation("treadDepthTracker")
  const [vehicleTireStatus, setVehicleTireStatus] = useState([])
  const [openTreadDepthDialog, setOpenTreadDepthDialog] = useState(false)
  const [statusWithCount, setStatusWithCount] = useState({})
  const [isExpanded, setIsExpanded] = useState(false)

  const handleVehicleTireStatus = useCallback(() => {
    const { tire_tread_fl, tire_tread_fr, tire_tread_rl, tire_tread_rr } = vehicle

    const { tireStatusData, statusWithCount } = handleTireStatusData(
      tire_tread_fl,
      tire_tread_fr,
      tire_tread_rl,
      tire_tread_rr
    )

    setVehicleTireStatus(tireStatusData)
    setStatusWithCount(statusWithCount)
  }, [vehicle])

  const handleTreadDepthDialog = useCallback(() => {
    setOpenTreadDepthDialog((prev) => !prev)
  }, [])

  const handleIsExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev)
  }, [])

  useEffect(() => {
    handleVehicleTireStatus()
  }, [handleVehicleTireStatus])

  const statusItems = useMemo(() => {
    return Object.entries(statusWithCount).map(([status, value]) => {
      const statusClass =
        status === TIRE_DEPTH_STATUS.replaceNow
          ? "replace-now"
          : status === TIRE_DEPTH_STATUS.replaceSoon
          ? "replace-soon"
          : ""

      return statusClass ? (
        <div className={`depth__status ${statusClass}`} key={status}>
          <p className="depth__data">{value}</p>
          <p className="depth__status-label">{status}</p>
        </div>
      ) : null
    })
  }, [statusWithCount])

  return (
    <div className="tire__module__treaDdepth">
      <div className="tread__header">
        <p className="tread__lable">
          {t("treadDepthTrackerLabel")}
          {!isDesktop && (
            <ReactSVG
              wrapper="span"
              onClick={handleTreadDepthDialog}
              src={UnlockMilesInfoLogo}
              className="tread__info-mobile"
            />
          )}
        </p>
        {isDesktop && (
          <ReactSVG
            onClick={handleTreadDepthDialog}
            src={UnlockMilesInfoLogo}
            className="tread__info"
          />
        )}
        {!isDesktop && (
          <Image
            onClick={handleIsExpanded}
            className={`down__arrow ${isExpanded ? "rotate_arrow" : ""}`}
            src={DownArrowLogo}
          />
        )}
      </div>

      {isMobile && !isEmpty(statusWithCount) && (
        <div className="depth__status-box">{statusItems}</div>
      )}

      {!isDesktop && isExpanded && (
        <div className="marginY__medium">
          <div className="depth__status">
            <TireTreadDepthStatus tireStatus={vehicleTireStatus} />
          </div>
          <TreadDepthTireDetails vehicle={vehicle} />
        </div>
      )}

      {isDesktop && (
        <div className="tread__depth-details">
          <div className="depth__status">
            <TireTreadDepthStatus tireStatus={vehicleTireStatus} />
          </div>
          <div className="depth__detail-container">
            <div>
              <p className="detail__header">{t("tireInfo")}</p>
              <p className="depth__reading">
                {t("lastDepthChecked")}{" "}
                <span>{vehicle.tread_depth_check_date || t("common:NA")}</span>
              </p>
            </div>
            <div>
              <p className="recorded__shop-label">{t("recordedShop")}</p>
              <p className="recorded__shop-name">
                {(vehicle.shop_details && vehicle.shop_details.name) || t("common:NA")}
              </p>
              <p className="recorded__shop-address">
                {(vehicle &&
                  vehicle.shop_details &&
                  getShopAddressWithCountry(vehicle.shop_details)) ||
                  t("common:NA")}
              </p>
            </div>
          </div>
        </div>
      )}

      <HowTrackTireDepth
        openDialog={openTreadDepthDialog}
        handleTreadDepthDialog={handleTreadDepthDialog}
      />
    </div>
  )
}

export default TireModuleTreadDepth
