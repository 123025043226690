import React from "react"
import Logo from "../../../../images/CarAdvise-Logo.svg"

const CompanyLogoHeader = (props) => {
  return (
    <div className="responsive-small-signup">
      <div className="headerSignUpLogo">
        <img src={Logo} />
      </div>
    </div>
  )
}

export default CompanyLogoHeader
