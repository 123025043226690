import React, { Component } from "react"
import PropTypes from "prop-types"

import PaymentMethodButton from "./PaymentMethodButton"
import PaymentMethodDefaultIcon from "./PaymentMethodDefaultIcon"
import PaymentMethodDetails from "./PaymentMethodDetails"
import PaymentMethodIcon from "./PaymentMethodIcon"
import Notifications from "../../components/Notifications"
import store from "../../../shared_component/utils/configureStore"
import { withTranslation } from "react-i18next"

class PaymentMethod extends Component {
  containerClassName = () => {
    let isDefault = this.props.paymentMethod.isdefault
    let className = "nextPaymentMethodRow"
    if (this.props.isAffirmSelectedDefault) {
      className += " grayBorder"
    } else if (isDefault) {
      className += " greenBorder"
    } else {
      className += " grayBorder"
    }

    return className
  }

  handleOnClick = (event) => {
    const { onSetDefault, paymentMethod } = this.props

    if (onSetDefault) {
      event = {}
      event.source = this
      event.value = paymentMethod
      onSetDefault(event)
    }
  }

  handleOnDeleteClick = (event) => {
    const { onDelete, paymentMethod, hasPaidMembership } = this.props
    const { t } = this.props

    if (onDelete && this.props.allowedFinalRemove) {
      event = {}
      event.source = this
      event.value = paymentMethod
      onDelete(event)
    } else {
      let message = hasPaidMembership ? t("deleteErrorMessageAlt") : t("deleteErrorMessage")
      store.dispatch(
        Notifications.error({
          title: t("errorNotificationTitle"),
          message: message,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }

  renderDefaultColumn = () => {
    const isDefault = this.props.paymentMethod.isdefault
    const { t } = this.props
    if (isDefault && !this.props.isAffirmSelectedDefault) {
      return <PaymentMethodDefaultIcon />
    } else {
      return (
        <div style={{ display: "flex" }}>
          <PaymentMethodButton
            label={t("setDefaultLbl")}
            onClick={this.handleOnClick}
            icon="check"
          />
          <PaymentMethodButton
            label={t("removeLbl")}
            icon="trash alternate"
            onClick={this.handleOnDeleteClick}
          />
        </div>
      )
    }
  }

  render() {
    const { paymentMethod } = this.props
    const { t } = this.props

    return (
      <div className={this.containerClassName()} onClick={this.handleOnClick}>
        <div className="paymentDetailsSection">
          <PaymentMethodIcon paymentMethod={paymentMethod} />

          <PaymentMethodDetails paymentMethod={paymentMethod} />
        </div>
        {this.renderDefaultColumn()}
      </div>
    )
  }
} // PaymentMethod

PaymentMethod.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  hasPaidMembership: PropTypes.bool
}

export default withTranslation("paymentMethod")(PaymentMethod)
