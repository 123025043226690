import React, { useEffect } from "react"
import { Icon } from "semantic-ui-react"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import Button from "../Button"

export default function RecommendedServiceModal({
  closeModal,
  onSave,
  serviceOptions,
  isOpenModel,
  serviceName,
  activeVehicle
}) {
  const { t } = useTranslation("recommendedServiceModal")

  const newServiceOptions = serviceOptions.map((item) => {
    return {
      name: item,
      checked: false
    }
  })

  const [service, setService] = React.useState(newServiceOptions)

  const [selectedServices, setSelectedServices] = React.useState([])

  useEffect(() => {
    if (activeVehicle && activeVehicle.oil_type_name) {
      const optionName = activeVehicle.oil_type_name
      const updatedItems = service.map((item) =>
        item.name === optionName ? { ...item, checked: true } : item
      )
      const selectedItem = updatedItems.filter((item) => item.name === optionName)
      setService(updatedItems)
      setSelectedServices(selectedItem)
    }
  }, [])

  const onChanges = (ev) => {
    const a = service.map((item) => {
      return {
        ...item,

        checked: item.name === ev.target.value && !item.checked ? true : false
      }
    })

    setService(a)
    const services = a.filter((element) => {
      if (element.checked) {
        return element.name
      }
    })

    setSelectedServices(services)
  }

  React.useEffect(() => {}, [service])

  return (
    <>
      <Modal
        className="customStyles"
        isOpen={isOpenModel}
        overlayClassName="Overlay"
        onRequestClose={() => closeModal(service)}
        // style={customStyles}
      >
        <div className="modelContainer">
          <div className="service-modal-section">
            <div className="modelCheckbox">
              <h3 className="primary-font">
                {t("recommended")} {serviceName} {t("forYourCar")}
                <Icon
                  name="times"
                  className="modal-cross-button"
                  onClick={() => closeModal(service)}
                  size="small"
                />
              </h3>
              <p className="secondary-font">{t("pleaseSelect")}</p>
              {serviceOptions &&
                service.map((item, i) => {
                  return (
                    <div className="modelCheckboxContainer">
                      <input
                        type="radio"
                        id={item[i]}
                        name="name"
                        checked={item.checked}
                        className="modal-custom-radio"
                        value={item.name}
                        onClick={(ev) => {
                          // ev.preventDefault()
                          onChanges(ev)
                        }}
                      />

                      <p className="tertiary-font">{item.name}</p>
                      {item.name === (activeVehicle && activeVehicle.oil_type_name) ? (
                        <div
                          style={{
                            backgroundColor: "#E3EAE6",
                            marginLeft: "7px",
                            width: "max-content",
                            height: "auto",
                            textAlign: "center",
                            borderRadius: "15px",
                            padding: "3px 10px"
                          }}
                        >
                          <p className="tertiary-font" style={{ margin: "0rem", padding: "0rem" }}>
                            {t("best")}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <Button
              disabled={!selectedServices.length ? true : false}
              text={t("Save")}
              fluid
              style={{ minWidth: "93%", display: "flex", margin: "0rem" }}
              colorStyle="orange"
              onClick={() => onSave(selectedServices.length ? selectedServices[0].name : undefined)}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

const customStyles = {
  overlay: {
    backdropFilter: "blur(0px)",
    backgroundColor: "red !important",
    opacity: 0.98
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "30%",
    height: "396px",
    display: "flex",
    bottom: "auto",
    backgroundColor: "ffffff",
    marginRight: "-40%",
    border: "2px solid rgba(0, 0, 0, 0.05)",
    margin: "24px",
    transform: "translate(-50%, -50%)"
  }
}
