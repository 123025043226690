import React, { Component } from "react"
import { connect } from "react-redux"
import spinnerCheckmark from "../../images/spinner_pulsing_checkmark.gif"
import { withTranslation } from "react-i18next"

class ApprovalDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.redirectTo = this.redirectTo.bind(this)
  }

  renderLoadingView() {
    const { t } = this.props
    return (
      <div className="spinnerContainer">
        <div>
          <p className="spinnerText">
            <img src="./images/Caradvise_Logo_Grey.svg" alt="" />
          </p>
          <p className="spinnerText">{t("loadingText")}</p>
        </div>
      </div>
    )
  }

  renderOil() {
    const { t } = this.props
    if (this.props.service.name === "Oil Change") {
      return (
        <div>
          <div className="serviceInfoHd">{t("recommendedOilText")}</div>
          <div className="serviceInfoText">
            {"\n"} {this.props.activeVehicle.oil_type_name}
          </div>
        </div>
      )
    }
  }

  renderComments() {
    const { t } = this.props
    if (this.props.service.comments) {
      return (
        <div>
          <div className="serviceInfoHd">{t("shopNotesText")}</div>
          <div className="serviceInfoText">
            {"\n"} {this.props.service.comments}
          </div>
        </div>
      )
    }
  }
  redirectTo() {
    if (this.props.pendingApprovalId) {
      var parameter2 = this.props.pendingApprovalId // some thing like this you can set value for 2nd Param.
      window.location.href = "#pendingapproval/" + parameter2
    } else {
      window.location.href = "#approvals"
    }
  }

  render() {
    let labor_time
    let labor_time_units
    const { service, t } = this.props
    if (this.state.showSpinner || !this.props.service) return this.renderLoadingView()

    let cost = Number(service.override_total) ? (
      <div className="approvalServicePrice">${Number(service.override_total).toFixed(2)}</div>
    ) : null

    let oneLiner = null
    if (service && service.service_definition && service.service_definition.one_liner) {
      oneLiner = (
        <div className="serviceOptionsHd">
          {"\n"} {service.service_definition.one_liner}
        </div>
      )
    }
    let laborest = (
      <div>
        <div className="serviceEstimates">
          <strong>{t("timeEstimateText")}</strong> {labor_time} {labor_time_units}
        </div>
      </div>
    )

    let whatisit = null
    if (service && service.service_definition && service.service_definition.what_is_this) {
      whatisit = (
        <div>
          <div className="serviceInfoHd">{t("whatIsItText")}</div>
          <div className="serviceInfoText">
            {"\n"} {service.service_definition.what_is_this}
          </div>
        </div>
      )
    }

    let whydothis = null
    if (service && service.service_definition && service.service_definition.why_do_this) {
      whydothis = (
        <div>
          <div className="serviceInfoHd">{t("whyDoThisText")}</div>
          <div className="serviceInfoText">
            {"\n"} {service.service_definition.why_do_this}
          </div>
        </div>
      )
    }

    let whatifdecline = null
    if (service && service.service_definition && service.service_definition.what_if_decline) {
      whatifdecline = (
        <div>
          <div className="serviceInfoHd">{t("whatIfDeclineText")}</div>
          <div className="serviceInfoText">
            {"\n"} {service.service_definition.what_if_decline}
          </div>
        </div>
      )
    }

    let factorstoconsider = null
    if (service && service.service_definition && service.service_definition.factors_to_consider) {
      factorstoconsider = (
        <div>
          <div className="serviceInfoHd">{t("factorsToConsiderText")}</div>
          <div className="serviceInfoText">
            {"\n"} {service.service_definition.factors_to_consider}
          </div>
        </div>
      )
    }

    return (
      <div className="dashLeftContainer">
        <div className="dashLeft" style={{ padding: "20px", textAlign: "center" }}>
          <p className="introText">{t("serviceDetailText")}</p>
          <div className="selectMaintenanceContainer">
            <div className="serviceListRow">
              <div className="serviceListName">{service.name}</div>
              {cost}
            </div>
            {oneLiner}
          </div>

          {this.renderOil()}
          <div className="serviceInfoContainer">
            {laborest}
            {whatisit}
            {whydothis}
            {whatifdecline}
            {factorstoconsider}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let id = ownProps.match.params.id
  let pendingApprovalId = ownProps.match.params.pendingapprovalid
  let service
  let user = state.user
  let activeVehicleIndex = 0
  if (Object.keys(user).length !== 0) {
    if (user.activeVehicleId) {
      activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
    }
    let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

    service = pendingApprovalId ? state.approved.pending[pendingApprovalId][id] : state.approved[id]

    let origin = state.origin.origin
    return {
      origin,
      isLoggedIn: !!user.authentication_token,
      authentication_token: user.authentication_token,
      miles: user.vehicles[0].miles,
      oilType: user.vehicles[0].oil_type_name,
      activeVehicle,
      service,
      pendingApprovalId
    }
  } else {
    return {}
  }
}

export default connect(mapStateToProps)(withTranslation("approvalDetail")(ApprovalDetail))
