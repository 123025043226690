import { SET_ORDER_LOADING, UNSET_ORDER_LOADING } from "../actions/orders"

const initialState = {
  loading: false
}

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_LOADING:
      return { ...state, loading: true }
      break
    case UNSET_ORDER_LOADING:
      return { ...state, loading: false }
      break
    default:
      return state
  }
}
