import { postJSON, getJSON } from "../../shared_component/utils/fetch"

export const LOAD_USER_STATES = "LOAD_USER_STATES"
export function loadUserStates(data) {
  return { type: LOAD_USER_STATES, payload: data }
}

export const USER_NOT_LOADING = "USER_NOT_LOADING"
export function setUserNotLoading() {
  return { type: USER_NOT_LOADING }
}

export function setNotLoading() {
  return async function (dispatch) {
    await dispatch(setUserNotLoading())
    return () => {}
  }
}

export function getClearCoverURL(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let data = {
        firstname: payload.firstName,
        lastname: payload.lastName,
        zip: payload.zip,
        vin: payload.vin,
        mileage: payload.mileage
      }

      let response = await postJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/insurance/clearcover`,
        data,
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getZebraURL(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let data = {
        mileage: payload.mileage,
        vehicle_id: payload.vehicle_id
      }

      let response = await postJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/insurance/zebra`,
        data,
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function fetchInsuranceProvider(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let data = {
        firstname: payload.firstName,
        lastname: payload.lastName,
        zip: payload.zip,
        vin: payload.vin,
        mileage: payload.mileage,
        type: "insurance"
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/providers/integration`,
        data,
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}
