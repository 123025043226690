import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import defaultTire from "../../images/DefaultTire.png"
import defaultCar from "../../images/placeholder_car.svg"
import { Link, Redirect } from "react-router-dom"
import { Card, CardHeader, Image, Confirm, Dropdown, Button } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { saveSelectedTire, saveTireDetails } from "../actions/tires"
import LoadingShimmer from "./LoadingShimmer"
import ClearScreen from "./ClearScreen"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { routerPaths } from "../constants/paths"
import CheckIcon from "../../images/CheckIcon.svg"
import ReactSVG from "react-svg"
import DefaultLogo from "../../images/NoLogoAvailable.png"
import { formatParagraph } from "../helpers/stringHelpers"
import { Chip } from "../../shared_component/elements/Chip"
import SheildIcon from "../../images/warranty-logo.svg"
import { formatPriceNumber } from "../../shared_component/utils/helpers"
import { NO, YES } from "../constants/order"
import { numberFormatToCurrency } from "../../shared_component/utils/currency"
import { DEFAULT_TIRE_QUENTY } from "../constants/tires"
import RemoveIcon from "../../images/delete_icon_vector.svg"

function ConfirmTire({
  t,
  activeVehicle,
  savedTires,
  saveTireDetails,
  user,
  saveSelectedTire,
  history
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [tireArray, setTireArray] = useState([])
  const [subTotal, setSubTotal] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [selectedTire, setSelectedTire] = useState()
  const [partnerFlag, setPartnerFlag] = useState()
  const objectCollection = [
    { id: 1, label: "1" },
    { id: 2, label: "2" },
    { id: 3, label: "3" },
    { id: 4, label: "4" }
  ]

  const dataprovider = objectCollection.map((object) => {
    return { key: object.id, value: object, text: object.label }
  })

  useEffect(() => {
    getTireDetails()
  }, [savedTires])

  const getTireDetails = async () => {
    setIsLoading(true)
    if (savedTires && savedTires.length > 0) {
      savedTires.map((tire) => {
        setTireArray((items) => [...items, tire.tireDetails])
      })
      calculateSum()
    }
  }

  useEffect(() => {
    if (tireArray.length === savedTires.length) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [tireArray, savedTires])

  const calculateSum = () => {
    if (savedTires.length > 0) {
      var sum = 0
      savedTires.forEach((item) => {
        sum += item.quantity * (item.price ? item.price : 0)
      })
      setSubTotal(sum)
    }
  }

  const changeHandler = (event, data, tire) => {
    setTireArray([])
    const tempArr = savedTires.filter((item) => item.tireId !== tire.id)
    const finalArr = [
      ...tempArr,
      { tireId: tire.id, tireDetails: tire, quantity: data.value.id, price: tire.price }
    ]
    saveTireDetails(finalArr)
  }

  const handleDeleteTire = () => {
    setTireArray([])
    const finalArr = savedTires.filter((item) => item.tireId !== selectedTire.id)
    saveTireDetails(finalArr)
    closeModal()
    history.push({
      pathname: routerPaths.topTires
    })
  }

  const openDeleteModal = (tire) => {
    setSelectedTire(tire)
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const onImageError = (e) => {
    e.target.src = defaultTire
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <>
          <div className="confirm-tire-sec">
            {savedTires && savedTires.length > 0 ? (
              <div className="confirm-tire">
                <div className="confirm-heading">
                  <Link to={routerPaths.topTires}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                  <h3>{t("Back")}</h3>
                </div>
                <div className="tires">
                  {isLoading ? (
                    <div className="loader-style">
                      <LoadingShimmer />
                    </div>
                  ) : (
                    <div className="card-parent">
                      <div className="tires-card-parent">
                        <div className="all-card">
                          {tireArray && tireArray.length > 0
                            ? tireArray.map((item) => {
                                return (
                                  <div className="tire-size-card">
                                    <div className="tire-image-container">
                                      <Image
                                        src={item && item.image ? item.image : defaultTire}
                                        onError={onImageError}
                                        className="tire-size-image"
                                      />
                                      <div className="tire-original-description-section">
                                        <ReactSVG src={CheckIcon} />
                                        {t("topTires:originalLabel")}
                                      </div>
                                    </div>
                                    <div className="tire-size-details-container">
                                      <div className="tire-size-details-section">
                                        <div className="tire-size-company-details">
                                          <Image
                                            src={item.brand_logo || item.image || DefaultLogo}
                                            className="tire-image-brand-logo"
                                          />
                                          <div className="tire-brand-miles-details">
                                            {item.mileage_rating ? (
                                              <Chip
                                                icon={SheildIcon}
                                                label={`${formatPriceNumber(
                                                  item.mileage_rating
                                                )} ${t("confirmOrderDialog:miles")} ${t(
                                                  "confirmOrderDialog:warranty"
                                                )}`}
                                                backgroundColor={"#F8F8F8"}
                                              />
                                            ) : null}
                                            {item.season ? (
                                              <Chip
                                                label={formatParagraph(
                                                  `${t("topTires:seasonLabel")}: *${item.season}*`,
                                                  600
                                                )}
                                                backgroundColor={"#FEF6F4"}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="tire-size-details">
                                          <div className="tire-name">
                                            {item && item.brand + " " + item.model}
                                          </div>
                                          <div className="tire-size-details-section">
                                            <span className="tire-size-detail">
                                              {" "}
                                              {item && item.size}
                                            </span>
                                            <Link
                                              to={routerPaths.tireDetails}
                                              className="details_link"
                                            >
                                              <span onClick={() => saveSelectedTire(item)}>
                                                {t("topTires:seeDetailsLabel")}
                                              </span>
                                            </Link>
                                          </div>
                                          <div className="tire-size-config-section">
                                            <div className="tire-size-config">
                                              {t("topTires:runFlatLabel")}{" "}
                                              <strong>{item.run_flat ? YES : NO}</strong>
                                            </div>
                                            <div className="tire-size-config">
                                              {t("topTires:loadRangeLabel")}{" "}
                                              <strong>
                                                {item.load_range ? item.load_range : "-"}
                                              </strong>
                                            </div>
                                            <div className="tire-size-config">
                                              {t("topTires:speedRatingLabel")}{" "}
                                              <strong>
                                                {item.speed_rating ? item.speed_rating : "-"}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="tire-size-cart-section">
                                        <div className="tire-cart-summary">
                                          <div className="tire-price-section">
                                            <div className="tire-price">
                                              {item && numberFormatToCurrency(item.price)}
                                            </div>
                                            <div className="tire-price-summary">
                                              {savedTires &&
                                              savedTires.filter((item) => item.tireId === item.id)
                                                .length > 0
                                                ? `${numberFormatToCurrency(
                                                    savedTires.filter(
                                                      (item) => item.tireId === item.id
                                                    )[0].quantity * item.price
                                                  )} ${t("topTires:setForLabel")} ${
                                                    savedTires.filter(
                                                      (item) => item.tireId === item.id
                                                    )[0].quantity
                                                  }`
                                                : `${numberFormatToCurrency(
                                                    DEFAULT_TIRE_QUENTY * item.price
                                                  )} ${t(
                                                    "topTires:setForLabel"
                                                  )} ${DEFAULT_TIRE_QUENTY}`}
                                            </div>
                                          </div>
                                          <div>
                                            <Dropdown
                                              options={dataprovider}
                                              onChange={(event, data) =>
                                                changeHandler(event, data, item)
                                              }
                                              className="tire-qty-dropdown"
                                              placeholder={
                                                savedTires.filter(
                                                  (savedItem) => savedItem.tireId === item.id
                                                ).length > 0
                                                  ? savedTires.filter(
                                                      (savedItem) => savedItem.tireId === item.id
                                                    )[0].quantity
                                                  : 1
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="add-button">
                                          <div
                                            className={"shop-btn-selected"}
                                            onClick={() => openDeleteModal(item)}
                                          >
                                            <ReactSVG
                                              src={RemoveIcon}
                                              className="shop-button-icon"
                                            />
                                            {t("topTires:removeLabel")}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            : null}
                        </div>
                        <Card className="tire-card confirm-card">
                          <CardHeader>
                            <div className="tire-name-parent">
                              <div className="step">{t("STEP_I")}</div>
                              <div className="confirm-title">{t("Confirm_your_tires")}</div>
                            </div>
                          </CardHeader>
                          <hr />
                          <div className="subtotal-parent">
                            <div className="subtotal">{t("Subtotal")}</div>
                            <div className="value">${Number(subTotal).toFixed(2)}</div>
                          </div>
                          <div className="total-parent">
                            <div className="total">{t("TOTAL")}</div>
                            <div className="total-value">${Number(subTotal).toFixed(2)}</div>
                          </div>
                          <div className="btn-parent">
                            <Link to={routerPaths.confirmInstallation} className="next-btn">
                              {t("Next")}: {t("Choose_installer")}
                            </Link>
                          </div>
                        </Card>
                      </div>
                    </div>
                  )}
                  {/* commented for next development */}
                  {/* <div className="car-img">
                    <h4 className="car-name">
                      {activeVehicle && activeVehicle.make + " " + activeVehicle.model}
                    </h4>
                    <p className="car-year">{activeVehicle && activeVehicle.year}</p>
                    <div className="img-parent">
                      <span className="img-strip">
                        <Image
                          className="hide-bg"
                          src={
                            activeVehicle && activeVehicle.dashboard_url
                              ? activeVehicle.dashboard_url
                              : defaultCar
                          }
                        />
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <ClearScreen />
            )}
          </div>
          <Confirm
            open={openModal}
            onCancel={closeModal}
            onConfirm={handleDeleteTire}
            content={t("Are_you_sure")}
            className="delete-modal"
          />
        </>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let savedTires = state.saveBuyTireDetails.tireArray
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    user: user,
    activeVehicle: activeVehicle,
    savedTires: savedTires
  }
}

export default connect(mapStateToProps, { saveTireDetails, saveSelectedTire })(
  withTranslation("confirmTire")(ConfirmTire)
)
