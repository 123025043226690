import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../components/Notifications"

export const showAlert = (props) => {
  const { message, title } = props
  store.dispatch(
    Notifications.success({
      title: title,
      message: message,
      position: "tr",
      autoDismiss: 5,
      action: {
        label: "Dismiss"
      }
    })
  )
}
