import React from "react"
import { constants } from "caradvise_shared_components"
import AppointmentSelectorModal from "../Modal/AppointmentSelectorModal"
import CancelLink from "./CancelLink"
import { Link } from "react-router-dom"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import MiniLoader from "../MiniLoader"
import { useTranslation } from "react-i18next"

const {
  orders: {
    INITIATED_BY_USER,
    CANCELLED,
    APPROVAL,
    AWAITING_APPROVAL,
    PAID,
    ACTIVE,
    COMPLETED,
    WALK_IN,
    SEEKING_QUOTE,
    PRICE_REVIEW
  }
} = constants

function WalkIn({ orderLikeObject, user, changeDateLink, orderLoading }) {
  const { t } = useTranslation("timeInformation")
  const { appointment_date_pretty, status } = orderLikeObject
  const excludedStatuses = [COMPLETED, APPROVAL, AWAITING_APPROVAL, CANCELLED, PRICE_REVIEW]

  return (
    <div>
      <p>
        <small>
          <strong>{t("dateAndTimeLbl")}</strong>
        </small>
      </p>
      <hr />
      <p>
        <span>{appointment_date_pretty}</span>
        <br />
        <span>{t("walkInShopAvailablityText")}</span>
      </p>

      <div className="cancel-change-section">
        {status == null && changeDateLink}
        {orderLikeObject.status !== undefined &&
          !excludedStatuses.includes(orderLikeObject.status)(
            <CancelLink order={orderLikeObject} user={user} />
          )}
      </div>
    </div>
  )
}

function Normal({ orderLikeObject, user, changeDateLink, orderLoading }) {
  const { t } = useTranslation("timeInformation")
  const { appointment_date_pretty, appointment_time_pretty, status } = orderLikeObject
  return (
    <div>
      <p className="secondary-font">
        <small>
          <strong>{t("dateAndTimeLbl")}</strong>
        </small>
      </p>
      <hr />
      {status === WALK_IN ? (
        <p>
          <span>{t("walkInOrderNotConfirmedText")}</span>
        </p>
      ) : (
        <p>
          <span>{appointment_date_pretty}</span>
          <br />
          <span>
            {appointment_time_pretty.includes("3:00 AM")
              ? t("walkInShopAvailablityText")
              : appointment_time_pretty}
          </span>
          <br />
        </p>
      )}

      {orderLoading ? (
        <div>
          <MiniLoader inverted={false} />
          {t("updatingOrderLoadingText")}
        </div>
      ) : (
        <div className="cancel-change-section">
          {(orderLikeObject.status == INITIATED_BY_USER || orderLikeObject.status == "active") && (
            <p className="cart-change-appt-cta">
              <AppointmentSelectorModal user={user} orderLikeObject={orderLikeObject} />
            </p>
          )}
          {orderLikeObject.status == null && changeDateLink}
          {orderLikeObject.status !== undefined &&
            orderLikeObject.status != COMPLETED &&
            orderLikeObject.status != APPROVAL &&
            orderLikeObject.status != AWAITING_APPROVAL &&
            orderLikeObject.status != CANCELLED &&
            orderLikeObject.status != PRICE_REVIEW && (
              <CancelLink order={orderLikeObject} user={user} />
            )}
        </div>
      )}
    </div>
  )
}

export default function TimeInformation(props) {
  const { t } = useTranslation("timeInformation")
  const changeDateLink = (
    <p className="cart-change-appt-cta">
      <Link
        onClick={() => {
          // trackEvent(EVENT_NAME.changeDateTimeClicked, {
          //   ...getAdditionalProps({ shouldIncludeBookingTime: false }),
          //   existingDate: props.orderLikeObject.appointment_date_pretty,
          //   existingTime: props.orderLikeObject.appointment_time_pretty,
          //   vehicleName: getVehicleName(getActiveVehicle()),
          //   orderId: props.orderLikeObject.id
          // })
          GAEvent(GAEventCategories.DASHBOARD, "change-date", "Change Date")
        }}
        to="/schedule"
      >
        {t("changeDateTimeLbl")}
      </Link>
    </p>
  )

  if (props.orderLikeObject.is_walk_in && !props.orderLikeObject.status) {
    return <WalkIn {...props} changeDateLink={changeDateLink} />
  } else if (props.orderLikeObject.appointment_datetime) {
    return <Normal {...props} changeDateLink={changeDateLink} />
  } else return null
}
