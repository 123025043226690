import {
  AVAILABLE_MEMBERSHIP_PLANS_LOADED,
  MEMBERSHIP_UPGRADE_SELECTED,
  SELECTED_MEMBERSHIP_UPGRADE_CLAUSES_UPDATED,
  USER_MEMBERSHIP_CREATED,
  SELECTED_MEMBERSHIP_UPGRADE_CLEARED,
  APPLE_WALLET_KEY_URL,
  GOOGLE_WALLET_KEY_URL
} from "../actions/membership"

const initialState = {
  availableMembershipPlans: []
}

export default function membershipReducer(state = initialState, action) {
  switch (action.type) {
    case AVAILABLE_MEMBERSHIP_PLANS_LOADED:
      return Object.assign({}, state, { availableMembershipPlans: action.payload })
      break
    case MEMBERSHIP_UPGRADE_SELECTED:
      return Object.assign({}, state, {
        selectedMembershipUpgrade: action.payload,
        acceptedTermsAndConditions: false,
        acceptedPassengerVehicleClause: false
      })
      break
    case SELECTED_MEMBERSHIP_UPGRADE_CLAUSES_UPDATED:
      const newState = Object.assign({}, state, {
        selectedMembershipUpgrade: {
          ...state.selectedMembershipUpgrade,
          ...action.payload
        }
      })
      return newState
      break
    case SELECTED_MEMBERSHIP_UPGRADE_CLEARED:
      return Object.assign({}, state, {
        selectedMembershipUpgrade: action.payload,
        acceptedTermsAndConditions: false,
        acceptedPassengerVehicleClause: false
      })
      break
    case APPLE_WALLET_KEY_URL:
      return Object.assign({}, state, { appleWalletKeyUrl: action.payload })
      break
    case GOOGLE_WALLET_KEY_URL:
      return Object.assign({}, state, { googleWalletKeyUrl: action.payload })
      break
    default:
      return state
  }
}
