import React from "react"
import { Image } from "semantic-ui-react"
import StoreImage from "../../../images/storeImage.svg"
import { withTranslation } from "react-i18next"
import { DATE_TIME_FORMAT } from "../../constants/application"
import moment from "moment"
import { ShimmerThumbnail } from "react-shimmer-effects"

const InstallLocation = ({ t, installDetails, isLoading }) => {
  return (
    <div className="install-location">
      <p className="label">
        {isLoading ? (
          <ShimmerThumbnail className="shimmer-custom-mergin" height={10} rounded />
        ) : (
          t("installLocation")
        )}
      </p>
      <div className="location-info">
        {isLoading ? (
          <ShimmerThumbnail className="shimmer-custom-mergin" height={100} width={100} rounded />
        ) : (
          <Image
            className="imageClass"
            src={
              (installDetails && installDetails.shop && installDetails.shop.logo_url) || StoreImage
            }
          />
        )}
        <div>
          {isLoading ? (
            <ShimmerThumbnail className="shimmer-custom-mergin" height={10} rounded />
          ) : (
            <p className="store-name">
              {installDetails && installDetails.shop && installDetails.shop.name}
            </p>
          )}
          {isLoading ? (
            <ShimmerThumbnail className="shimmer-custom-mergin" height={10} rounded />
          ) : (
            installDetails &&
            installDetails.shop && (
              <p className="store-location">
                {installDetails.shop.address_line1 +
                  ", " +
                  installDetails.shop.city +
                  ", " +
                  installDetails.shop.state +
                  " " +
                  installDetails.shop.zip}
              </p>
            )
          )}
          {isLoading ? (
            <ShimmerThumbnail className="shimmer-custom-mergin" height={10} rounded />
          ) : (
            <p className="check-in">
              {t("checkIn")} :{" "}
              <span className="install-date">
                {installDetails &&
                  moment(installDetails.appointment_datetime)
                    .parseZone()
                    .format(DATE_TIME_FORMAT.LLL)}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTranslation("installLocation")(InstallLocation)
