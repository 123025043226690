import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Modal from "react-modal"
import { Image } from "semantic-ui-react"
import { cancelOrder } from "../../v1/actions/orders"
import { trackEvent } from "../utils/segmentAnalytics"
import {
  getBookingAppointmentDateTime,
  getBookingAppointmentDay
} from "../../v1/helpers/segmentHelper"
import { isWalkIn } from "../../v1/helpers/orderHelpers"
import { getShopAddress, getShopName } from "../../v1/helpers/shopHelpers"
import { getVehicleName } from "../../v1/helpers/vehicleHelpers"
import { getCurrencyCode, numberFormatToCurrency } from "../utils/currency"
import { getServiceNameForMap } from "../../v1/helpers/serviceHelper"
import i18n from "../utils/i18n"
import CloseIcon from "../../images/close-icon.svg"
import Button from "../../v1/components/Button"
import MiniLoader from "../../v1/components/MiniLoader"
import { orderCancelledEvents } from "../utils/googleAnalyticsHelpers"
import { me } from "../../v1/actions/user"

class CancelAppointmentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false
    }
  }
  cancelAppointment = async (e) => {
    e.stopPropagation()
    this.setState({ isSubmitting: true })
    const { cancelOrder, history, user, order, activeVehicle } = this.props
    if (!user.activeVehicleId || !user.authentication_token) return

    const response = await cancelOrder(order.id)
    if (response.success === true) {
      this.props.me()
      await orderCancelledEvents({ response, activeVehicle })
      trackEvent("order-cancelled", {
        bookingAppointmentDay:
          response.result &&
          response.result.appointment_datetime &&
          getBookingAppointmentDay(response.result.appointment_datetime),
        bookingAppointmentDate:
          response.result &&
          response.result.appointment_datetime &&
          getBookingAppointmentDateTime(response.result.appointment_datetime),
        bookingAppointmentTime:
          response.result && isWalkIn(response.result)
            ? "Walk In"
            : response.result.appointment_time_pretty,
        orderServices:
          response.result &&
          response.result.order_services &&
          response.result.order_services.map(getServiceNameForMap),
        shopId: response.result && response.result.shop && parseInt(response.result.shop.id),
        orderId: response.result && parseInt(response.result.id),
        walkIn: response.result && isWalkIn(response.result) ? "Yes" : "No",
        shopName: getShopName({ shop: order.shop, company: order.company }),
        shopAddress: getShopAddress(order.shop),
        vehicleName: getVehicleName(order.vehicle),
        yourTotal: order.estimated_total ? numberFormatToCurrency(order.estimated_total) : 0,
        promoCode: order.promotions && order.promotions.length > 0 ? order.promotions[0].name : "",
        revenue: order.estimated_total,
        currency: getCurrencyCode(i18n.language),
        vehicleId: response.result && parseInt(response.result.vehicle_id),
        email: this.props && this.props.user && this.props.user.email
      })
      this.props.close()
      history.push("/dashboard")
    }
    this.setState({ isSubmitting: false })
  }

  render() {
    const { open, close, t, order } = this.props
    const { isSubmitting } = this.state
    return (
      <Modal
        isOpen={open}
        onAfterOpen={this.afterOpenModal}
        // onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        className="cancel-modal-container"
      >
        <div className="modal-header-close">
          <Image
            src={CloseIcon}
            onClick={(e) => {
              e.stopPropagation()
              close()
            }}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="modal-dialog-content">
          <div className="cancel-content">
            <p className="header-text">{t("areYouSureLabel")}</p>
            <p className="sub-text">{t("canRescheduleApptLabel")}</p>
          </div>
          <div className="modal-action-content">
            <Button
              fluid
              text={isSubmitting ? <MiniLoader /> : t("cancelApptLabel")}
              colorStyle="orange"
              onClick={(e) => this.cancelAppointment(e)}
              appendClose={true}
            />
            <Button
              fluid
              text={t("keepMyAppointmentLabel")}
              colorStyle="grey"
              onClick={(e) => {
                e.stopPropagation()
                close()
              }}
              appendClose={true}
            />
          </div>
        </div>
      </Modal>
    )
  }
}
function mapStateToProps(state) {
  const user = state.user || {}
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  let activeOrder =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)

  return {
    order: activeOrder,
    user,
    activeVehicle
  }
}

export default withRouter(
  connect(mapStateToProps, { cancelOrder, me })(
    withTranslation("cancelLink")(CancelAppointmentModal)
  )
)
