import { postJSON } from "../../shared_component/utils/fetch"
import Notifications from "../components/Notifications"
import i18n from "../../shared_component/utils/i18n"
const CREATE_RATING_URL = "/api/v4/shop_order_ratings"

export const SHOP_ORDER_RATING_COMPLETED = "SHOP_ORDER_RATING_COMPLETED"

export function createRating(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}

    let url = CREATE_RATING_URL
    let params = {
      shop_order_rating: {
        shop_order_id: payload.shopOrderId,
        shop_order_rating_tag_ids: payload.shopOrderRatingTagIds,
        score: payload.score,
        customer_explanation: payload.customerExplanation,
        dismissed: payload.dismissed
      }
    }
    let response = await postJSON(url, params, { Authorization: user.authentication_token })

    if (response.result) {
      dispatch({ type: SHOP_ORDER_RATING_COMPLETED, shopOrderId: payload.shopOrderId })
      return true
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )

      return false
    }
  }
}
