import React, { PureComponent } from "react"
import AppstoreIcon from "../../../images/icons/appstore-icon.svg"
import PlaystoreIcon from "../../../images/icons/google-play-icon.svg"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"

export class SignUpAppBanner extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { banner, visible } = this.props

    const rowStyle = { marginTop: "1em" }
    const appImageStyle = { width: "100%", height: "100%" }
    if (visible) {
      return (
        <>
          {!window.location.href.includes("instacart-ca") && (
            <div>
              <strong className="banner-heading" style={{ color: "#fff", textAlign: "center" }}>
                Life is better on our Mobile App!
                <br />
                DOWNLOAD for Android and Apple App today and
                <br />
                ditch your desktop for our refreshed Mobile App.
              </strong>
              <div style={rowStyle}>
                <div style={{ display: "inline-flex", marginLeft: "18%" }}>
                  <a
                    href="https://apps.apple.com/us/app/caradvise/id1118606915"
                    style={{ width: "34%", marginRight: "2%" }}
                    onClick={() =>
                      trackEvent("download-app-clicked", {
                        appleOrAndroid: "Apple",
                        source: "Sign Up",
                        beforeOrAfterSignup: "Before Sign Up"
                      })
                    }
                  >
                    <img style={appImageStyle} src={AppstoreIcon} />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.caradvise.caradvise"
                    style={{ width: "40%" }}
                    onClick={() =>
                      trackEvent("download-app-clicked", {
                        appleOrAndroid: "Android",
                        source: "Sign Up",
                        beforeOrAfterSignup: "Before Sign Up"
                      })
                    }
                  >
                    <img style={appImageStyle} src={PlaystoreIcon} />
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )
    }
  }
}

SignUpAppBanner.defaultProps = {
  visible: true
}

export default SignUpAppBanner
