import React from "react"
import { connect } from "react-redux"
import Button from "../components/Button"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

function Recall({ recalls }) {
  if (!recalls) return null

  const { t } = useTranslation("recall")
  let id = parseInt(window.location.href.match(/recalls\/(\d)+/)[1], 10)
  let recall = recalls.filter((r) => r.id === id)[0]
  let day = null
  let monthIndex = null
  let year = null
  if (recall) {
    let date = new Date(recall.report_received_date)
    day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })
    monthIndex = date.getMonth().toLocaleString(undefined, { minimumIntegerDigits: 2 })
    year = date.getFullYear()
  }

  return (
    <div className="dashLeft recalls" style={{ flexDirection: "column" }}>
      <p className="introText">{t("recallDetailsLbl")}</p>
      {recall && (
        <div className="recall-detail">
          <div>
            {year}-{monthIndex}-{day}
          </div>
          <div>
            {t("campaignNumberLbl")} {recall.payload.NHTSACampaignNumber}
          </div>
          <div>{recall.payload.Component}</div>
          <div>{t("summaryLbl")}</div>
          <div>{recall.payload.Summary}</div>
          <div>{t("consequenceLbl")}</div>
          <div>{recall.payload.Consequence}</div>
          <div>{t("remedyLbl")}</div>
          <div>{recall.payload.Remedy}</div>
          <div>{t("notesLbl")}</div>
          <div>{recall.payload.Notes}</div>
          <Button
            text={t("scheduleServiceText")}
            colorStyle="orange"
            iconRight={faAngleRight}
            linkTo={"/selectCommonMaintenance"}
          />
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let vehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    recalls: vehicle.recalls
  }
}

export default connect(mapStateToProps, {})(Recall)
