import React from "react"
import { Image } from "semantic-ui-react"

export const Chip = ({ icon = "", label, backgroundColor }) => {
  return (
    <div className="chip-container" style={{ backgroundColor: backgroundColor || "#F8F8F8" }}>
      {icon && <Image src={icon} className="chip-icon" />}
      <div className="chip-content">{label}</div>
    </div>
  )
}
