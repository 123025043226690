import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import GotoLogo from "../../../images/rewards/gotoRewardHistory.svg"
import ReactSVG from "react-svg"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getEarnedMiles } from "../../../v1/actions/rewards"
import { useEffect } from "react"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"
import {
  DEFAULT_PAD_START_VALUE,
  GET_FIRST_NON_ZERO_NUMBER
} from "../../../shared_component/constants/rewards"

const MilesEarnedCard = ({ t, history, getEarnedMiles, user, earnedMiles }) => {
  const isFirstTimeUser = false

  const handleEarnedRewards = async () => {
    try {
      const rewardsRes = await getEarnedMiles()

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    } catch (error) {
      console.log(t("errorInMilesEarnedCard"), error)
    }
  }

  useEffect(() => {
    handleEarnedRewards()
  }, [])

  const firstNonZeroIndex = earnedMiles ? earnedMiles.search(GET_FIRST_NON_ZERO_NUMBER) : -1

  return (
    <div
      className={`miles-earned-container ${
        user.generic && window.location.href.includes(routerPaths.rewards) ? "disable-color" : ""
      }`}
      onClick={() => {
        if (!isFirstTimeUser && !user.generic) {
          const earnedMilesObj = {
            earnedMiles: +earnedMiles
          }
          trackFirebaseAnalytics({
            event: gaJSON.total_miles_earned_cliked,
            params: {
              earned_miles: earnedMilesObj.earnedMiles
            }
          })
          identifyEvent({
            traits: earnedMilesObj,
            userId: user.id
          })
          trackEvent("total-miles-earned-clicked", earnedMilesObj)
          routeChange(history, routerPaths.rewardsHistory)
        }
      }}
    >
      <div>
        <p className="total-miles-earned">{t("totalMilesEarned")}</p>
        {earnedMiles &&
          earnedMiles.split("").map((char, index) => {
            // Determine if the character is part of the leading zeros
            let isLeadingZero =
              char === DEFAULT_PAD_START_VALUE &&
              (firstNonZeroIndex === -1 || index < firstNonZeroIndex)

            // Determine the className based on the character and user type
            let className = "miles-earned"
            if (isLeadingZero) {
              className += isFirstTimeUser ? " disable-color" : ""
            } else {
              className += isFirstTimeUser ? " earn-miles-color disable-color" : " earn-miles-color"
            }

            return (
              <span key={index} className={className}>
                {char}
              </span>
            )
          })}
        <p className="available-time">{t("availableAtAllTimes")}</p>
      </div>
      {!isFirstTimeUser && <ReactSVG className="miles-earn-logo" src={GotoLogo} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    earnedMiles: state.rewards.earnedMile
      ? numberFormater(state.rewards.earnedMile).toString().padStart(9, DEFAULT_PAD_START_VALUE)
      : DEFAULT_PAD_START_VALUE.padStart(7, DEFAULT_PAD_START_VALUE)
  }
}

export default connect(mapStateToProps, {
  getEarnedMiles
})(withRouter(withTranslation("milesEarnCard")(MilesEarnedCard)))
