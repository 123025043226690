import React, { useEffect, useState } from "react"
import ReactSVG from "react-svg"

export const SearchComponent = ({ searchQuery, setSearchQuery }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchQuery(value)
  }

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [windowWidth])

  const handleIconHover = () => {
    if (searchQuery.trim() !== "") {
      document.querySelector(".search").classList.add("focused")
    }
  }

  return (
    <div className="search-container">
      <div className="wrapper">
        <div
          className={`search ${searchQuery.trim() !== "" || windowWidth <= 500 ? "focused" : ""}`}
        >
          <span onMouseEnter={handleIconHover}>
            <ReactSVG src="images/search_icon.svg" />
          </span>
          <input
            type="text"
            name="focus"
            required
            className="search-box"
            placeholder="Search services..."
            value={searchQuery}
            onChange={handleInputChange}
          />
          {searchQuery.trim() !== "" && (
            <p className="close-icon" onClick={() => setSearchQuery("")}>
              <ReactSVG src="images/close_icon_vector.svg" />
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
