import React, { Component } from "react"
import { slide as Menu } from "react-burger-menu"
import { connect } from "react-redux"
import store from "../shared_component/utils/configureStore"
import { logoutUser } from "./actions/user"
import { Link } from "react-router-dom"
import { hidePartnerOffers } from "./helpers/affiliationHelpers"
import { isSigningUp } from "./helpers/pathHelpers"
import { withTranslation } from "react-i18next"
import moment from "moment"
import {
  EVENT_NAME,
  getAdditionalProps,
  identifyEvent,
  trackEvent
} from "../shared_component/utils/segmentAnalytics"
import {
  faCog,
  faCalendar,
  faCreditCard,
  faCar,
  faIdCard,
  faHome,
  faWrench,
  faEnvelopeOpenDollar,
  faGift,
  faCheckCircle,
  faQuestionCircle,
  faFileAlt,
  faUser,
  faSignOut
} from "@fortawesome/pro-regular-svg-icons"
import Badge from "./components/Badge"
import { ON, setupSplitFlags } from "./components/Config/SplitClient"
import { getSubdomain } from "./components/Config/StylesheetInjector"
import { APP_NAME } from "../shared_component/constants/common"
import RewardWhiteLogo from "../images/rewards/reward-navigation-white.svg"

class SideMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRewardsFeature: window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature
    }
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ app_name: APP_NAME })
  }

  close(name) {
    if (name !== "Dashboard")
      trackEvent("click-navigation-drawer", {
        itemName: name
      })
    try {
      document.getElementsByClassName("bm-cross-button")[0].children[1].click()
    } catch (e) {}
  }

  menuItems() {
    const { isGeneric, t } = this.props

    let items = [
      ["dashboard", t("dashboardMenu"), faHome],
      ["approvals", t("approvalsMenu"), faCheckCircle],
      ["maintenanceSchedule", t("maintenanceScheduleMenu"), faCalendar],
      ["membership", t("membershipCardMenu"), faIdCard],
      [
        this.state.isGloveBoxFeatureFlag === ON && "gloveboxComponent",
        t("gloveboxMenu"),
        faFileAlt
      ],
      ["managevehicles", t("myGarageMenu"), faCar],
      [
        this.props.activeVehicleId ? `vehicles/${this.props.activeVehicleId}/history` : null,
        t("maintenanceHistoryMenu"),
        faWrench
      ]
    ]
    if (this.state.isRewardsFeature) {
      items.push([
        "rewards",
        t("rewards"),
        RewardWhiteLogo,
        { isImage: true, height: 27, width: 20, style: { marginTop: "5px", marginLeft: "-2px" } }
      ])
    }
    if (!hidePartnerOffers()) items.push(["offers", t("partnerOffersMenu"), faGift])
    items.push(["invite-friends", t("inviteFriendsMenu"), faEnvelopeOpenDollar])
    if (!isGeneric) items.unshift(["MyAccount", t("myAccountMenu"), faUser])

    return items
  }

  render() {
    const { isLoggedIn, isGeneric, t } = this.props
    const { isRewardsFeature } = this.state
    if (!isLoggedIn || (isGeneric && isSigningUp())) return null
    return (
      <div>
        <div className="bm-burger-button">
          <span>
            <span className="bm-burger-bars"></span>
            <span className="bm-burger-bars"></span>
            <span className="bm-burger-bars"></span>
          </span>
        </div>

        <Menu disableAutoFocus>
          <div className="bm-menu-wrap">
            <div className="side-menu-bm-menu">
              {this.menuItems().map(
                (arr, index) =>
                  arr[0] &&
                  index < 8 && (
                    <div key={index} className="side-menu-bm-menu-item">
                      <Badge count={null} icon={arr[2]} customImage={arr[3]} />
                      <a
                        id={arr[0]}
                        key={arr[0]}
                        className="side-bar-menu-item"
                        href={`#/${arr[0]}`}
                        onClick={this.close.bind(this, arr[1])}
                      >
                        {arr[1]}
                      </a>
                    </div>
                  )
              )}
            </div>

            <div style={{ paddingTop: "18%" }}>
              {this.menuItems().map(
                (arr, index) =>
                  arr[0] &&
                  index > 7 && (
                    <div key={index} className="side-menu-bm-menu-item">
                      <Badge count={null} icon={arr[2]} customImage={arr[3]} />
                      <a
                        id={arr[0]}
                        key={arr[0]}
                        className="side-bar-menu-item"
                        href={`#/${arr[0]}`}
                        onClick={this.close.bind(this, arr[1])}
                      >
                        {arr[1]}
                      </a>
                    </div>
                  )
              )}

              <div
                className="side-menu-bm-menu-item"
                onClick={() => {
                  trackEvent("click-navigation-drawer", {
                    itemName: "FAQs"
                  })
                }}
              >
                <Badge count={null} icon={faQuestionCircle} />
                <a className="side-bar-menu-item" href="https://caradvise.com/faq/" target="_blank">
                  {t("faqPageMenu")}
                </a>
              </div>
              <div className="side-menu-bm-menu-item">
                <Badge count={null} icon={faSignOut} />
                <Link
                  to="/signin"
                  className="side-bar-menu-item"
                  onClick={() => store.dispatch(logoutUser())}
                >
                  {t("signoutMenu")}
                </Link>
              </div>
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}

  return {
    isLoggedIn: user.id,
    activeVehicleId: user.activeVehicleId,
    isGeneric: user.generic,
    user
  }
}

export default connect(mapStateToProps, { logoutUser })(withTranslation("sideMenu")(SideMenu))
