import React, { useState, useRef, useLayoutEffect, useEffect } from "react"
import ReactSVG from "react-svg"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { Popup } from "semantic-ui-react"
import { constants } from "caradvise_shared_components"
import { INSTACART } from "../../../v1/constants/affiliations"
import ModalDialog from "../../../v1/components/ModalDialog"
import { connect } from "react-redux"
const {
  tooltips: { TAX_AND_FEES, TAX_AND_FEES_FR }
} = constants

const SavingsAccordion = (props) => {
  const { user, cart, t, isDefaultOpen, isServiceComponentScrolled, handleFooterToggle } = props
  const {
    total_savings,
    membership_discount,
    estimated_total,
    estimated_total_pre_tax,
    transaction_fee,
    promotional_discount,
    promotions,
    total_rewards_credit,
    combined_sales_tax_from_service,
    prepay_discount,
    estimated_retail_total_pre_tax,
    redeem_discount,
    any_order_service_lacks_price
  } = cart

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const [isExpanded, setIsExpanded] = useState(isDefaultOpen)
  const accordionRef = useRef(null)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
    handleFooterToggle && handleFooterToggle(!isExpanded)
  }

  useEffect(() => {
    if (isServiceComponentScrolled && isServiceComponentScrolled > 0 && isExpanded) {
      setIsExpanded(false)
      handleFooterToggle && handleFooterToggle(false)
    }
  }, [isServiceComponentScrolled])

  const is_instacart_affiliation = (user) => {
    try {
      if (user.affiliation_name.toLocaleLowerCase().includes(INSTACART)) return true
      else return false
    } catch (e) {
      if (window.location.origin.toLocaleLowerCase().includes(INSTACART)) return true
      else return false
    }
  }

  useLayoutEffect(() => {
    if (isExpanded && accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [isExpanded])
  return (
    <div className={`savings-accordion ${isExpanded ? "expanded" : ""}`} ref={accordionRef}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="savings-label">
          <ReactSVG src="images/CA_CheckIcon.svg" svgStyle={{ width: "23px", height: "23px" }} />
          <span>{t("totalLabel")}</span>
        </div>
        <div className="savings-amount-section">
          <div className="savings-amount">
            <div>💰</div>
            <div>{numberFormatToCurrency(total_savings)}</div>
          </div>
          <ReactSVG src={isExpanded ? "images/up_arrow.svg" : "images/down_arrow.svg"} />
        </div>
      </div>
      {isExpanded && (
        <div className="accordion-content">
          {/* <SavingsExpandedContent
            text={t("estimatedRetailPriceLabel")}
            price={estimated_retail_total_pre_tax}
          /> */}
          <SavingsExpandedContent
            text={t("carAdvisePriceLabel")}
            price={
              any_order_service_lacks_price
                ? "N/A"
                : numberFormatToCurrency(estimated_total_pre_tax - transaction_fee)
            }
          />
          {total_rewards_credit > 0 && (
            <SavingsExpandedContent
              text={t("carAdviseCashLabel")}
              price={numberFormatToCurrency(total_rewards_credit)}
            />
          )}
          {prepay_discount > 0 && (
            <SavingsExpandedContent
              text={t("prepayDiscountLabel")}
              price={numberFormatToCurrency(prepay_discount)}
            />
          )}
          {membership_discount > 0 && (
            <SavingsExpandedContent
              text={t("memberSavingsLabel")}
              price={numberFormatToCurrency(membership_discount)}
            />
          )}
          {promotional_discount > 0 && (
            <SavingsExpandedContent
              text={
                is_instacart_affiliation(user)
                  ? `${t("instacartDiscountLbl")} `
                  : `${t("promotionalSavingsLabel")}`
              }
              price={numberFormatToCurrency(promotional_discount)}
              info={(promotions && promotions.length > 0 && promotions[0].description) || ""}
            />
          )}
          {isRewardsFeature && !any_order_service_lacks_price && !!+redeem_discount && (
            <SavingsExpandedContent
              textClassName="price_label"
              text={t("milesRedeemed")}
              price={numberFormatToCurrency(redeem_discount)}
            />
          )}
          {combined_sales_tax_from_service > 0 && transaction_fee === 0 && (
            <SavingsExpandedContent
              text={t("estimatedTaxLabel")}
              price={numberFormatToCurrency(estimated_total - estimated_total_pre_tax)}
            />
          )}
          <TotalSavingsButton text={t("totalLabel")} price={total_savings} />
        </div>
      )}
    </div>
  )
}

export const SavingsExpandedContent = ({ text, price, info = "", textClassName = "" }) => {
  return (
    <div className="savings-expanded-content-wrapper">
      <div className="savings-expanded-content">
        <div className="savings-content-header">
          <div className={`savings-content-text ${textClassName}`}>{text}</div>
          {info.length > 0 ? (
            <ModalDialog
              linkText={<ReactSVG src="images/info_icon_vector.svg" />}
              className="primary-button-color"
            >
              {info}
            </ModalDialog>
          ) : (
            <div />
          )}
        </div>
        <div className="savings-content-header-values">
          <div className="header-values">
            <div className="savings-content-values">{price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TotalSavingsButton = ({ text, price }) => {
  return (
    <div className="button-wrapper-parent">
      <div className="button-wrapper">
        <div className="total-savings-parent">
          <b className="total-savings-label">{text}</b>
          <div className="total-savings-value">
            <b className="price-text">{numberFormatToCurrency(price)}</b>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    rewards: state.rewards || {}
  }
}

export default connect(mapStateToProps, null)(SavingsAccordion)
