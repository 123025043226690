import store from "../../shared_component/utils/configureStore"

export function selectLanguage() {
  let user = store && store.getState().user
  if (window.location.origin.includes("instacart-ca")) {
    var browserLanguage = navigator.language || navigator.userLanguage
    return browserLanguage.includes("fr") ||
      (user && user.language !== undefined ? user.language.includes("fr") : false)
      ? "fr-CA"
      : "en"
  } else {
    return "en"
  }
}
