import { getJSON } from "../../shared_component/utils/fetch"

const TIRE_ORDER_URL = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/orders/`

export function getTireShopOrderDetails(user, orderId) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: `${TIRE_ORDER_URL}/${orderId}`,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }

      const response = await getJSON(request.url, request.body, request.headers)

      return response
    }
  }
}
