import React, { Fragment, useState } from "react"

const ConditionalWrapper = ({ initialWrapper, condition, wrapper, children }) =>
  condition ? wrapper(children) : initialWrapper(children)

export default function TabBarWithRender({ tabsList, isFancyTabs = true }) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="tabs">
      <div className="fancy-labels-wrapper">
        <ConditionalWrapper
          initialWrapper={(children) => <Fragment>{children}</Fragment>}
          wrapper={(children) => <div className="labels">{children}</div>}
          condition={isFancyTabs}
        >
          {tabsList.map((el, i) => (
            <div
              className={"tab-label" + (activeTab === i ? " active-tab-label" : "")}
              active={activeTab === i}
              onClick={() => setActiveTab(i)}
              key={i}
            >
              {el.label}
            </div>
          ))}
        </ConditionalWrapper>
      </div>
      <div>
        {tabsList.map((tab, i) => (
          <div className={activeTab === i ? " active-tab-content" : ""} key={i}>
            {activeTab === i && tab.content()}
          </div>
        ))}
      </div>
    </div>
  )
}
