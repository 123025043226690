import React, { PureComponent } from "react"
import { List, Popup } from "semantic-ui-react"
import { BASIC, PREMIUM, ELITE, isBasic, isPremium } from "../../../v1/helpers/membershipHelpers"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import CheckIcon from "../../../images/membershippanel/checkIcon.svg"
import CrossIcon from "../../../images/membershippanel/check-cross-icon.svg"
import ReactSVG from "react-svg"
import { formatParagraph } from "../../../v1/helpers/stringHelpers"

class MembershipBenefitsList extends PureComponent {
  benefits = () => {
    const { plan, t, currentMembershipPlan } = this.props
    if (!plan) return []

    const planDiscount = plan.percent_discount || 0
    const previousDiscount = currentMembershipPlan.percent_discount || 0
    const discountDiff = planDiscount - previousDiscount
    const discount =
      discountDiff <= 0 ? null : (
        <span>
          {formatParagraph(
            t("discountLabel", { discountDiff: parseInt(discountDiff) }),
            700,
            undefined,
            "#616161"
          )}
        </span>
      )

    const advice = <span>{formatParagraph(t("ASECertifiedLabel"), 700, undefined, "#616161")}</span>
    const vehicles = (
      <span>
        {formatParagraph(
          t("vehiclesBenefitLabel", { vehiclesCount: plan.max_num_vehicles }),
          700,
          undefined,
          "#616161"
        )}
      </span>
    )
    let config =
      {
        [BASIC]: [discount, advice, vehicles],
        [PREMIUM]: [discount, advice, vehicles],
        [ELITE]: [discount, advice, vehicles]
      }[plan.name] || []

    return config.filter((attr) => attr != null)
  }

  render() {
    const { plan, t } = this.props
    const eventCountYearly = plan.max_num_yearly_roadside_events
      ? plan.max_num_yearly_roadside_events
      : 0
    const isBasic = plan && plan.name === BASIC
    return (
      <List>
        {this.benefits().map((benefit, i) => {
          return (
            <List.Item
              key={`MembershipBenefitsList-${plan.name}-${i}`}
              className="upgrade-featured-benefit"
            >
              <Image src={CheckIcon} className="upgrade-modal-checkmark" />
              {benefit}
            </List.Item>
          )
        })}
        <List.Item className="upgrade-featured-benefit">
          <Image src={isBasic ? CrossIcon : CheckIcon} className="upgrade-modal-checkmark" />
          <span>{formatParagraph(t("tireBenefitsLabel"), 700, undefined, "#616161")}</span>
        </List.Item>
        <List.Item className="upgrade-featured-benefit">
          <Image
            src={plan.includes_roadside ? CheckIcon : CrossIcon}
            className="upgrade-modal-checkmark"
          />
          <span className="road-side-assistant-text">
            {t("roadsideAssistanceLabel")}
            {plan.includes_roadside && (
              <Popup
                on="hover"
                className="road-side-assistance-popup"
                position="top center"
                trigger={
                  <ReactSVG className="road-side-tooltip" src="images/info_icon_vector.svg" />
                }
              >
                <div className="road-side-assistance-label">
                  <span className="road-side-assistance-header">
                    {t("roadsideAssistanceLabel")}
                  </span>
                  <span>{t("coverageForTwingText")}</span>
                  <span>{t("limitedTotext", { eventsCount: eventCountYearly })}</span>
                </div>
              </Popup>
            )}
          </span>
        </List.Item>
      </List>
    )
  }
}

export default withTranslation("membershipBenefitsList")(MembershipBenefitsList)
