import React from "react"
import { TIRE_DEPTH_STATUS } from "../../constants/tires"

const TireCondition = ({ status, depth }) => {
  const tireStatusClassName =
    status === TIRE_DEPTH_STATUS.good
      ? "tire_good"
      : status === TIRE_DEPTH_STATUS.replaceSoon
      ? "tire_replace__soon"
      : status === TIRE_DEPTH_STATUS.replaceNow
      ? "tire_replace__now"
      : ""

  const tireDepthClassName =
    status === TIRE_DEPTH_STATUS.good
      ? "good_depth"
      : status === TIRE_DEPTH_STATUS.replaceSoon
      ? "replace_soon__depth"
      : status === TIRE_DEPTH_STATUS.replaceNow
      ? "replace_now__depth"
      : ""

  return (
    <div className="tire_condition__container">
      {status && <p className={`tire_status ${tireStatusClassName}`}>{status}</p>}
      <p className={`tire_depth ${tireDepthClassName}`}>{depth}</p>
    </div>
  )
}

export default TireCondition
