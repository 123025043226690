import React from "react"
import { connect } from "react-redux"
import store from "../../../shared_component/utils/configureStore"
import { Grid, Form } from "semantic-ui-react"
import { elements } from "caradvise_shared_components"
import { createCustomersPromotion, applyCustomersPromotion } from "../../actions/promotion"
import Notifications from "../Notifications"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import { withTranslation } from "react-i18next"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"

const { Button } = elements

class AvailablePromotionsContent extends React.Component {
  state = {
    code: ""
  }

  onSubmitCode = (e) => {
    e.preventDefault()
    const { code } = this.state
    if ([null, undefined, ""].includes(code)) return

    this.props.createCustomersPromotion({
      code: this.state.code,
      cartId: this.props.cartId,
      callback: this.afterRequest
    })
  }

  onApply = (e, promotionId) => {
    e.preventDefault()
    GAEvent(GAEventCategories.CART, "apply-promo", "Apply Promotion To Cart")
    trackEvent("click-apply-promo-code")
    this.props.applyCustomersPromotion({
      cartId: this.props.cartId,
      promotionId: promotionId,
      callback: this.afterRequest
    })
  }

  afterRequest = (status, data) => {
    let baseAttrs = { position: "tr", autoDismiss: 5, action: { label: "Dismiss" } }
    const { t } = this.props
    if (status === "success") {
      store.dispatch(
        Notifications.success({
          ...baseAttrs,
          title: t("successTitle"),
          message: t("promotionAppliedMessage")
        })
      )
      this.props.closeModal()
    } else {
      store.dispatch(
        Notifications.error({
          ...baseAttrs,
          title: t("errorTitle"),
          message: `${data.message || t("errorMessage")}`
        })
      )
    }
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  render() {
    const { goToUsedPromos, appliedPromotionId, t } = this.props
    const promos = this.props.promos || []
    const { code } = this.state

    return (
      <React.Fragment>
        {promos.length === 0 && (
          <div style={{ padding: "100px 0" }}>{t("noAvailablePromotionsMessage")}</div>
        )}

        <div style={{ margin: "40px 0" }}>
          {promos.map((promo, i) => {
            return (
              <Grid.Row columns="equal" key={`available-promotion-${i}`}>
                <Grid.Column
                  floated="left"
                  textAlign="left"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ textAlign: "left", paddingRight: "10px" }}>
                    {`${appliedPromotionId === promo.id ? "*" : ""}${promo.name}`}
                  </span>
                </Grid.Column>
                <Grid.Column floated="right">
                  <small>
                    <Button
                      onClick={(e) => this.onApply(e, promo.id)}
                      text={t("applyToCartText")}
                      colorStyle={"orange"}
                      width={"150px"}
                      style={{ padding: 0 }}
                    />
                  </small>
                </Grid.Column>
              </Grid.Row>
            )
          })}
          <Grid.Row columns="equal">
            <Grid.Column floated="left" textAlign="left">
              <Form.Input
                value={code}
                onChange={this.onCodeChange}
                style={{ margin: "0 10px 0 0" }}
                placeholder={t("enterPromoCodePlaceholder")}
              />
            </Grid.Column>
            <Grid.Column floated="right">
              <small>
                <Button
                  onClick={this.onSubmitCode}
                  text={t("applyToCartText")}
                  colorStyle={"orange"}
                  width={"150px"}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                />
              </small>
            </Grid.Column>
          </Grid.Row>
        </div>

        <span className="cursor-pointer primary-link" onClick={goToUsedPromos}>
          {t("viewUsedPromotionsLbl")}
        </span>
      </React.Fragment>
    )
  }
}

export default connect(
  () => {
    return {}
  },
  { createCustomersPromotion, applyCustomersPromotion }
)(withTranslation("availablePromotionsContent")(AvailablePromotionsContent))
