import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../../v1/actions/user"
import { CartServices } from "../Cart/CartSummary/CartSummary"
import CartSidePanelFooter from "../Cart/CartSidePanelFooter"
import ApplyPromotion from "./ApplyPromotion"
import NoAddedServices from "../Services/NoAddedServices"
import OrderSummaryFooter from "./OrderSummaryFooter"
import SelectedShopCard from "../Schedule/Shop/SelectedShopCard"
import EarnMilesPerOrder from "../Rewards/EarnMilesPerOrder"
import AddNote from "./AddNoteSection"
import { AFFIRM, PAYPAL, PAYPAL_ACCOUNT } from "../../../v1/constants/tires"
import { MD_HD_VEHICLE_TYPE_ID, PAYMENT_METHODS } from "../../../v1/constants/application"
import { Image } from "semantic-ui-react"
import { obfuscateEmail } from "../../../shared_component/utils/stringHelpers"
import { Loader2 } from "../../../shared_component/components/Loader2"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"
import { BRAINTREE } from "caradvise_shared_components/lib/constants/payment_providers"

const OrderSummaryIndex = (props) => {
  const {
    cart,
    user,
    updateCart,
    handleShopChange,
    isCart,
    t,
    setCustomerComments,
    customerComments,
    isNoteExpanded,
    handlePaymentChange,
    isCartUpdating,
    activeVehicle,
    isFirstOrder,
    anyOrderServiceLackPrice,
    paymentMethodSelected,
    isLastStep,
    defaultPaymentDetails
  } = props
  const cartQuantity = cart && cart.order_services && cart.order_services.length
  let paymentType = cart.paymentType || props.paymentType
  let paymentDetails = cart.paymentDetails || props.paymentDetails

  if (isLastStep && (!paymentType || !paymentDetails)) {
    paymentMethodSelected(BRAINTREE, defaultPaymentDetails)
  }

  let logo = paymentDetails
    ? paymentDetails.type === PAYMENT_METHODS.CREDIT_CARD
      ? paymentDetails.card_type
      : [PAYMENT_METHODS.PAYPAL, PAYPAL_ACCOUNT, PAYPAL].includes(paymentDetails.type)
      ? PAYMENT_METHODS.PAYPAL
      : AFFIRM
    : null
  const isSupportsHDMD = cart && cart.shop && cart.shop.support_medium_and_heavy_duty_vehicles
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  if (isCartUpdating) return <Loader2 allowText={true} />
  return (
    <div className="order-summary-index-container">
      <div className="order-summary-container">
        <div className="order-summary-header">{t("orderSummaryLabel")}</div>
        {cartQuantity > 0 ? (
          <CartServices
            cart={cart}
            cartQuantity={cartQuantity}
            t={t}
            user={user}
            updateCart={updateCart}
            isCart={isCart}
          />
        ) : (
          <div className="no-services-section">
            <NoAddedServices closeSidebar={() => {}} />
          </div>
        )}
        {cart && cart.shop && (
          <>
            <div className="order-summary-headers">{t("appointmentDetailsLabel")}</div>
            {activeVehicle
              ? !isSupportsHDMD &&
                activeVehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID && (
                  <div>
                    <b className="caradvise-red">{t("supportsMDHDMessage")}</b>
                  </div>
                )
              : null}
            <SelectedShopCard cart={cart} shop={cart.shop} handleShopChange={handleShopChange} />
          </>
        )}
        <div className="horizontal-divider" />
        <AddNote
          setCustomerComments={setCustomerComments}
          customerComments={customerComments}
          isExpanded={isNoteExpanded}
        />
        <CartSidePanelFooter
          user={user}
          cart={cart}
          t={t}
          shop={cart.shop}
          sidePanelClose={() => {}}
          buttonText={t("goCheckoutLabel")}
          showButton={false}
          isDefaultOpen={true}
        />
        {!anyOrderServiceLackPrice && <ApplyPromotion cart={cart} />}
        <div className="horizontal-divider"></div>
        <OrderSummaryFooter />
        {isRewardsFeature && isFirstOrder && <EarnMilesPerOrder closeSidebar={() => {}} />}
        {cart && paymentType && (
          <div className="selected-payment-type-section">
            <div className="payment-type-section">
              {logo && (
                <Image
                  className="payment-logo"
                  src={`images/paymentLogo/${logo.replace(/\s+/g, "")}.svg`}
                />
              )}
              {cart && paymentDetails ? (
                [PAYMENT_METHODS.PAYPAL, PAYPAL_ACCOUNT, PAYPAL].includes(paymentDetails.type) ? (
                  <p>{obfuscateEmail(paymentDetails.email || paymentDetails.details.email)}</p>
                ) : paymentDetails.type === PAYMENT_METHODS.CREDIT_CARD ? (
                  <>
                    <span className="dot-input">{paymentDetails.last_4}</span>
                    <span>{paymentDetails.last_4}</span>
                  </>
                ) : (
                  ""
                )
              ) : null}
            </div>
            <span
              className="change-link"
              onClick={() => {
                if (handlePaymentChange && handlePaymentChange) {
                  handlePaymentChange()
                }
              }}
            >
              {t("reviewOrder:changeLabelReviewScreen")}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let cart
  let isCart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  let defaultPaymentDetails

  if (state.paymentMethods && state.paymentMethods.braintreePaymentMethods) {
    defaultPaymentDetails = state.paymentMethods.braintreePaymentMethods.find(function (payment) {
      return payment.isdefault
    })
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    isFirstOrder: state.user.total_shop_orders === 0,
    anyOrderServiceLackPrice: cart && cart.any_order_service_lacks_price,
    defaultPaymentDetails
  }
}
export default connect(mapStateToProps, { updateCart })(
  withTranslation("cartPanel")(OrderSummaryIndex)
)
