import { initFirebase, firebaseAnalytics } from "../config/firebaseConfig"
import gaJSON from "../config/googleAnalytics.json"
import { getDeviceProperties } from "../../v1/helpers/userHelper"
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics"
import { LOADING, PURCHASE, SCREEN_VIEW, WALK_IN_TIME_STRING } from "../constants/common"
import { getShopAddress } from "../../v1/helpers/shopHelpers"
import { numberFormatToCurrency } from "./currency"
import { NO, YES } from "../../v1/constants/order"
import moment from "moment"
import { capitalCase } from "./stringHelpers"
import { DATE_TIME_FORMAT } from "../../v1/constants/application"
import { getBookingAppointmentDay } from "../../v1/helpers/segmentHelper"
import { getServiceNameForMap } from "../../v1/helpers/serviceHelper"
import { isEmpty } from "lodash"
import store from "./configureStore"

const analytics = getAnalytics()

const trackFirebaseAnalytics = ({ event, params = {} }) => {
  const user = store.getState().user
  const affiliationName =
    user.affiliation && user.affiliation.parent_id
      ? user.affiliation.parent_name
      : user.affiliation && user.affiliation.name
  const additionalProps = getDeviceProperties()
  params = {
    [gaJSON.page_location]: window.location.hash,
    version: window.splitClientFlagCache.isCarAdviseNavigation,
    affiliation: affiliationName && affiliationName,
    // Add additional parameters as needed for your analytics events
    ...params,
    ...additionalProps
  }
  try {
    logEvent(analytics, event, params)
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const setFirebaseAnalyticsUserId = (userId) => {
  try {
    setUserId(analytics, userId.toString())
    setUserProperties(analytics, {
      version: window.splitClientFlagCache.isCarAdviseNavigation
    })
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const logFirebaseAnalyticsScreenName = (screenName) => {
  if (screenName !== LOADING) {
    try {
      logEvent(analytics, SCREEN_VIEW, {
        firebase_screen: screenName,
        firebase_screen_class: screenName,
        version: window.splitClientFlagCache.isCarAdviseNavigation
      })
    } catch (e) {
      console.error("Google Analytics Error- ", e)
    }
  }
}

const logPurchase = (currency, value) => {
  try {
    logEvent(analytics, PURCHASE, {
      currency: currency,
      value: value
    })
  } catch (e) {
    console.error("Google Analytics Error- ", e)
  }
}

const initializeFirebaseAnalytics = () => {
  // Perform any necessary initialization or cleanup related to analytics
  // For example, initializing Firebase analytics on mount or performing setup
}

const getShopData = (shop) => {
  return {
    [gaJSON.shop_id]: shop && shop.id,
    [gaJSON.shop_name]: shop && shop.name,
    [gaJSON.shop_rating]: shop && shop.average_rating,
    [gaJSON.shop_address]: shop && getShopAddress(shop),
    [gaJSON.shop_city]: shop && shop.city
  }
}
const getVehicleData = (activeVehicle) => {
  if (!isEmpty(activeVehicle)) {
    return {
      [gaJSON.vehicle_id]: activeVehicle && activeVehicle.id,
      [gaJSON.vehicle_name]: activeVehicle
        ? `${activeVehicle.year || ""} ${activeVehicle.make.toUpperCase() || ""} ${
            activeVehicle.model.toUpperCase() || ""
          }`
        : null
    }
  }
}
const getOrderService = (response) => {
  return {
    [gaJSON.revenue]: response.estimated_total,
    [gaJSON.booking_total]: numberFormatToCurrency(response.estimated_total),
    [gaJSON.currency]: response.currency && response.currency.toUpperCase(),
    [gaJSON.number_of_services]: response.order_services && response.order_services.length,
    [gaJSON.order_services]:
      response.order_services &&
      response.order_services.map((os) => (!os.position ? os.name : `${os.name} - ${os.position}`)),
    [gaJSON.cost]:
      response.order_services &&
      response.order_services
        .map((service) =>
          service.price_estimate !== null &&
          service.price_estimate !== undefined &&
          service.price_estimate.shop_price_estimate !== null
            ? service.price_estimate.shop_price_estimate
            : service.price_estimate.shop_price_estimate
            ? service.override_total
            : 0
        )
        .join(",")
  }
}

const getBookingData = (response) => {
  return {
    [gaJSON.booking_appointment_date]:
      response && response.appointment_datetime
        ? new Date(response.appointment_datetime).toISOString()
        : null,
    [gaJSON.walk_in]: response.is_walk_in
      ? YES
      : response.appointment_date_pretty === WALK_IN_TIME_STRING
      ? YES
      : NO,
    [gaJSON.booking_appointment_time]: response.is_walk_in
      ? WALK_IN_TIME_STRING
      : response.appointment_time_pretty
  }
}

const getAppointmentData = (response) => {
  return {
    [gaJSON.booking_appointment_day]: moment(
      response.result.appointment_datetime,
      DATE_TIME_FORMAT.YYYY_MM_DD
    ).format(DATE_TIME_FORMAT.DDDD),
    [gaJSON.payment_type]:
      response.result.payment_type && capitalCase(response.result.payment_type),
    [gaJSON.promo_code]:
      response.result.promotions && response.result.promotions.length > 0
        ? response.result.promotions[0].name
        : "",
    [gaJSON.order_services]:
      response.result &&
      response.result.order_services &&
      response.result.order_services.map(getServiceNameForMap),
    [gaJSON.booking_appointment_day]:
      response.result &&
      response.result.appointment_datetime &&
      getBookingAppointmentDay(response.result.appointment_datetime)
  }
}

const serviceSelectedEvents = ({ serviceDefinition, position, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.service_selected,
    params: {
      [gaJSON.service_name]: serviceDefinition.name,
      [gaJSON.service_type]: !position
        ? serviceDefinition.name
        : `${serviceDefinition.name} - ${position}`,
      [gaJSON.from_where]: window.location.hash,
      [gaJSON.vehicle_name]: activeVehicle
        ? `${activeVehicle.year || ""} ${activeVehicle.make.toUpperCase() || ""} ${
            activeVehicle.model.toUpperCase() || ""
          }`
        : null
    }
  })
}

const shopSelectedEvents = ({ response, shop, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.shop_selected,
    params: {
      [gaJSON.cart_id]: response && parseInt(response.id),
      ...getShopData(response),
      ...getVehicleData(activeVehicle),
      ...getOrderService(response)
    }
  })
}

const appointmentDateTimeSelectedEvents = ({ response, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.appointment_date_time_selected,
    params: {
      [gaJSON.cart_id]: response && parseInt(response.id),
      ...getShopData(response),
      ...getVehicleData(activeVehicle),
      ...getOrderService(response),
      ...getBookingData(response)
    }
  })
}

const orderBookedEvents = ({ response, orderId, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.order_booked,
    params: {
      [gaJSON.order_id]: parseInt(orderId),
      ...getShopData(response),
      ...getVehicleData(activeVehicle),
      ...getBookingData(response),
      ...getAppointmentData(response),
      [gaJSON.revenue]: response.result.total,
      [gaJSON.booking_total]: response.result.total
        ? numberFormatToCurrency(response.result.total)
        : 0,
      [gaJSON.currency]: response.result.currency.toUpperCase(),
      [gaJSON.number_of_services]:
        response.result.order_services && response.result.order_services.length,
      [gaJSON.cost]: response.result.order_services
        .map((service) => (service.total ? service.total : 0))
        .join(",")
    }
  })
}

const orderRescheduleEvents = ({ response, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.order_reschedule,
    params: {
      [gaJSON.order_id]: response.result && parseInt(response.result.id),
      ...getShopData(response),
      ...getVehicleData(activeVehicle),
      ...getOrderService(response),
      ...getBookingData(response),
      ...getAppointmentData(response)
    }
  })
}

const orderCancelledEvents = ({ response, activeVehicle }) => {
  trackFirebaseAnalytics({
    event: gaJSON.order_cancelled,
    params: {
      [gaJSON.order_id]: response.result && parseInt(response.result.id),
      ...getShopData(response),
      ...getVehicleData(activeVehicle),
      ...getBookingData(response),
      ...getAppointmentData(response),
      [gaJSON.revenue]: response.result.total,
      [gaJSON.booking_total]: response.result.total
        ? numberFormatToCurrency(response.result.total)
        : 0,
      [gaJSON.currency]: response.result.currency.toUpperCase(),
      [gaJSON.number_of_services]:
        response.result.order_services && response.result.order_services.length,
      [gaJSON.cost]: response.result.order_services
        .map((service) => (service.total ? service.total : 0))
        .join(",")
    }
  })
}

const getMembershipPlan = (response) => {
  return {
    [gaJSON.membership_plan_id]: response && response.membership_plan_id,
    [gaJSON.membership_plan_name]: response && response.plan_name
  }
}

const subscriptionPurchasedEvents = ({ response }) => {
  trackFirebaseAnalytics({
    event: gaJSON.subscription_purchased,
    params: {
      ...getMembershipPlan(response)
    }
  })
}

export {
  trackFirebaseAnalytics,
  setFirebaseAnalyticsUserId,
  logFirebaseAnalyticsScreenName,
  initializeFirebaseAnalytics,
  gaJSON,
  logPurchase,
  serviceSelectedEvents,
  shopSelectedEvents,
  appointmentDateTimeSelectedEvents,
  orderBookedEvents,
  orderRescheduleEvents,
  orderCancelledEvents,
  subscriptionPurchasedEvents
}
