import React from "react"
import { Link } from "react-router-dom"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation } from "react-i18next"
import { formatPlanTitle } from "../../shared_component/utils/formatPlanTitle"
import { Button, Card, CardHeader } from "semantic-ui-react"

function PlanDetails({ location, t }) {
  const planDetails = location.state && location.state.plan
  const foreverUrl = location.state && location.state.foreverUrl

  return (
    <div className="plan-details-section">
      <div className="plan-details">
        <h2>
          <Link to="/WarrantyPlan">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          {t("Forevercar")}: {formatPlanTitle(planDetails && planDetails.plan_level)}
        </h2>
        <div className="card-parent">
          <Card className="card">
            <CardHeader className="header">
              <div className="title">{formatPlanTitle(planDetails && planDetails.plan_level)}</div>
              <ul>
                <li>
                  <span className="name">{t("Coverage_Score")}:</span>
                  <span className="value">{planDetails && planDetails.coverage_score}</span>
                </li>
                <li>
                  <span className="name">{t("Deductible")}:</span>
                  <span className="value">${planDetails && planDetails.deductible}</span>
                </li>
              </ul>
              <a href={foreverUrl} target="_blank">
                <Button className="quote-btn">{t("Get_Quote")}</Button>
              </a>
            </CardHeader>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default withTranslation("planDetails")(PlanDetails)
