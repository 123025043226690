import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import ReactSVG from "react-svg"
import { connect } from "react-redux"
import { Image } from "semantic-ui-react"
import NoteIcon from "../../../images/note-icon.svg"

function AddNote(props) {
  const [isExpanded, setIsExpanded] = useState(props.isExpanded)
  const { setCustomerComments, customerComments } = props
  const { t } = props
  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  const onCommentChange = (e) => {
    setCustomerComments(e.target.value)
  }
  return (
    <div className={`add-note-accordion-section ${isExpanded ? "expanded" : ""}`}>
      <div className="add-note-header" onClick={toggleAccordion}>
        <div className="add-note-label">
          <Image src={NoteIcon} />
          <span>{t("addNoteLabel")}</span>
          <span className="add-note-sub-label">{t("optionalLabel")}</span>
        </div>
        <div className="add-note-amount-section">
          <ReactSVG src={isExpanded ? "images/up_arrow.svg" : "images/down_arrow.svg"} />
        </div>
      </div>
      {isExpanded && (
        <div className="accordion-content">
          <div className="add-note-sub-label">
            <span>{t("addNoteDescription")}</span>
          </div>
          <textarea
            id="myTextarea"
            value={customerComments}
            onChange={onCommentChange}
            placeholder={t("addNotesPlaceholder")}
            rows="4"
          />
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(withRouter(withTranslation("common")(AddNote)))
