import React, { Component, useEffect, useState } from "react"

import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapPin } from "@fortawesome/pro-light-svg-icons"
import Geosuggest from "react-geosuggest"
import { withTranslation } from "react-i18next"
import { logoForShop } from "../../../../../shared_component/utils/shopLogos"
import { getShopAddress, withPricing } from "../../../../helpers/shopHelpers"
import ShopListItems from "./ShopListItems"

import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl"
import { Dropdown, Grid, GridColumn, GridRow } from "semantic-ui-react"
import { faLocationArrow, faMapMarkerAlt, faMarker } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons"
import { faMapMarkerPlus } from "@fortawesome/pro-regular-svg-icons"
import { ORANGE } from "../../../constant/color"
import { ProgressHUDMap } from "../../components/ProgressHUD"

const ICON_WIDTH = 18
const ICON_HEIGHT = 32

const navStyle = {
  position: "absolute",
  top: 200,
  right: 0,
  padding: "10px"
}

const MapComponent = (props) => {
  const { shops, onClickMarker, selectedShop, onSearchLocationSelect, t } = props

  const user = props.user
  const height = Math.max(window.innerHeight * 0.5, 300)
  const firstShop = (props.shops && props.shops[0]) || {}
  const [viewport, setViewport] = useState({
    latitude: firstShop.latitude || 41.8786772,
    longitude: firstShop.longitude || -87.6451561,
    width: "100%",
    height: `${height}px`,
    zoom: 9
  })

  React.useEffect(() => {
    if (shops && shops.length > 0) {
      setViewport({
        latitude: shops[0].latitude,
        longitude: shops[0].longitude,
        width: "100%",
        height: `${height}px`,
        zoom: 9
      })
    }
  }, [shops])

  const [shopSelected, setShopSelected] = useState(selectedShop)

  const onMarkerClick = (shop) => {
    setShopSelected(shop)
  }

  const updateViewport = (viewPort) => {
    setViewport(viewPort)
  }

  const onClosePopup = () => {
    setShopSelected({})
  }

  const onSelectLocationResult = (suggest) => {
    if (suggest) {
      onSearchLocationSelect(suggest)
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <div className="dashboard_map_container">
        <ShopListItems shops={shops} />
      </div>
      <MapGL
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={updateViewport}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <React.Fragment>
          {shops &&
            shops.length > 0 &&
            shops.map((shop, index) => {
              const { latitude, longitude } = shop
              const src = logoForShop(shop)
              let logo = src !== undefined ? <img style={{ width: "40px" }} src={src} /> : null
              const isSelectedShop = shopSelected && shop.id === shopSelected.id
              return (
                <React.Fragment>
                  <Marker
                    key={`marker-${index}`}
                    latitude={latitude}
                    longitude={longitude}
                    offsetLeft={-(ICON_WIDTH / 2)}
                    offsetTop={-ICON_HEIGHT}
                    className={`${isSelectedShop ? "onTop" : ""}`}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      fixedWidth
                      style={{
                        color: ORANGE,
                        cursor: "pointer",
                        width: `${ICON_WIDTH}px`,
                        height: `${ICON_HEIGHT}px`
                      }}
                      onClick={() => onMarkerClick(shop)}
                    />

                    {/* <FontAwesomeIcon
                      icon={faMapPin}
                      fixedWidth
                      className={isSelectedShop ? "caradvise-electric-blue" : "caradvise-orange"}
                      style={{
                        cursor: "pointer",
                        width: `${ICON_WIDTH}px`,
                        height: `${ICON_HEIGHT}px`
                      }}
                      onClick={() => onClickMarker(shop)}
                    /> */}
                  </Marker>
                  {/* <Marker key={`marker-${index}`} latitude={latitude} longitude={longitude}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      fixedWidth
                      style={{
                        color: "#0390ff",
                        cursor: "pointer",
                        width: `${ICON_WIDTH}px`,
                        height: `${ICON_HEIGHT}px`
                      }}
                      onClick={() => onMarkerClick(shop)}
                    />
                  </Marker> */}
                  {isSelectedShop && (
                    <Popup
                      className="onTop"
                      latitude={shopSelected.latitude}
                      longitude={shopSelected.longitude}
                      closeOnClick={false}
                      closeButton={true}
                      onClose={() => onClosePopup()}
                      anchor="bottom"
                    >
                      <div className="services-dashboard-icon-container">{logo}</div>
                      <div className="services-dashboard-grid-item-title">
                        <p className="shop_name">
                          {shopSelected.company.name}
                          <br />
                          <p>{shopSelected.address}</p>
                          <p className="miles">{shopSelected.distance_config.display_value}</p>
                        </p>
                      </div>
                    </Popup>
                  )}
                </React.Fragment>
              )
            })}
        </React.Fragment>
        <div className="geo_container">
          <Geosuggest
            onSuggestSelect={onSelectLocationResult}
            placeholder={t("placeholder")}
            types={["geocode"]}
            country={user.country}
            ignoreTab={true}
            className="geosuggest-container"
            suggestsClassName="search-suggests-list"
            suggestItemClassName="search-suggest-item"
            inputClassName={`searchFldShop smaller-width`}
            suggestItemActiveClassName="search-suggest-item-active"
            suggestsHiddenClassName="search-suggests-list-hidden"
          />
        </div>

        <div className="nav" style={navStyle}>
          <NavigationControl onViewportChange={updateViewport} />
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flex: 1,
            height: "100%",
            margin: "0rem auto",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {props.loading && <ProgressHUDMap />}
        </div>
      </MapGL>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(MapComponent))
