import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { numberFormatToCurrency } from "../../../../../shared_component/utils/currency"

const AppointmentCard = (props) => {
  const { t } = props

  return (
    <div className="dashboardAppointmentContainer">
      <div className="servicesContainer">
        {props.order &&
          props.order.order_services &&
          props.order.order_services.length >= 1 &&
          props.order.order_services.map((s) => {
            return (
              <div style={{ flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <span className="title" style={{ flex: 1 }}>
                    {s.name}
                  </span>
                  {s.override_total && (
                    <span className="title">{numberFormatToCurrency(s.override_total)}</span>
                  )}
                </div>
              </div>
            )
          })}

        <span className="subtitle">
          {props.order.appointment_date_pretty} @ {props.order.appointment_time_pretty}
        </span>
        <br />
        {props.order && props.order.shop && (
          <span className="subtitle">
            {props.order.shop.address_line1} {props.order.shop.city}, {props.order.shop.state}{" "}
            {props.order.shop.zip}
          </span>
        )}
        <span className="subtitle"></span>
      </div>
      <div className="containerFooter">
        <span style={{ flex: 1 }}></span>
        <span>{t("manageBooking")}</span>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  let order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)

  return {
    user,
    activeVehicle,
    order
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardAppointment")(AppointmentCard))
