import React, { Component } from "react"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { isUsaa } from "../helpers/affiliationHelpers"

class RegFooter extends Component {
  render() {
    const { t } = this.props
    return (
      <div className="footer">
        <p>
          {t("termsAndConditions1")}
          <span className="underline">
            <strong>
              <a href="https://caradvise.com/privacy" target="_blank" className="disclaimer">
                {t("termsAndConditions2")}
              </a>
            </strong>
          </span>
          {t("termsAndConditions3")}
          <span className="underline">
            <strong>
              <a href="https://caradvise.com/terms" target="_blank" className="disclaimer">
                {t("termsAndConditions4")}
              </a>
            </strong>
          </span>
        </p>
        &copy; {`${moment().year()} ${t("footerText")}`}
        <span> {process.env.REACT_APP_COMMIT_SHA}</span>
        {isUsaa() && (
          <React.Fragment>
            <p className="underline">
              <strong>
                <a
                  href="https://www.usaa.com/inet/wc/shopping_and_discounts_main"
                  target="_blank"
                  className="disclaimer"
                >
                  {t("returnToUsaa")}
                </a>
              </strong>
            </p>
            <p className="disclaimer" style={{ padding: "15px", textAlign: "left" }}>
              <strong>{t("caradviseDisclosuresText")}</strong>
              <br />
              <br />
              {t("allServicesBookedDesc")}{" "}
              <a href="https://caradvise.com/terms/" target="_blank" className="disclaimer">
                {t("termsOfUseText")}
              </a>
              {t("anyInfoYouProvideDesc")}
              <a href="https://caradvise.com/privacy/" target="_blank" className="disclaimer">
                {t("privacyPolicyText")}
              </a>
              .
              <br />
              <br />
              {t("savingsVaryDesc")}
              <br />
              <br />
              {t("finalPricesForServicesDesc")}
              <br />
              <br />
              {t("priceGuaranteeText")}
              <br />
              {t("guaranteedPriceDesc")}
              <br />
              <br />
              {t("happinessGuaranteedText")}
              <br />
              {t("notSatisfiedWithServiceDesc")}
              <br />
              <br />
              <strong>{t("usaaDisclosuresText")}</strong>
              <br />
              <br />
              {t("usaaMeanDesc")}
              <br />
              <br />
              {t("useOfTermsDesc")}
              <br />
              <br />
              {t("allianceServicesDesc")}
              <br />
              <br />
              {t("propertyOfOwnerDesc")}
              <br />
              <br />
              {t("usaaDidNumber")}
              <br />
              <br />
            </p>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withTranslation("regFooter")(RegFooter)
