import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { updateActiveZip } from "../../../actions/serviceSearch"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation } from "react-i18next"
import { faPen } from "@fortawesome/pro-regular-svg-icons"

function PopUpComponent({ user, activeZip, updateActiveZip, t }) {
  const [open, setOpen] = React.useState(false)
  const [zip, setZip] = React.useState(null)

  const handleChange = (event) => {
    setZip(event.target.value)
  }
  React.useEffect(() => {}, [zip])

  return (
    <Modal
      closeIcon
      open={open}
      trigger={<FontAwesomeIcon icon={faPen} style={{ marginLeft: "10px", marginTop: "3px" }} />}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="zip_modal"
    >
      <Modal.Content>
        <div>
          <p className="zip_modal_text">
            Choose your location to score the sweetest deals in your area
          </p>
          <input
            className="zip_modal_input"
            type="text"
            name="zip"
            placeholder={t("needZipTitle")}
            onChange={handleChange}
          ></input>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="zip_modal_apply"
          onClick={() => {
            updateActiveZip(null)
            setOpen(false)

            updateActiveZip(zip)
          }}
        >
          <span style={{ color: "white" }}>{t("buttonLabel")}</span>
        </Button>
      </Modal.Actions>
      <Modal.Actions>
        <Button
          className="zip_modal_find"
          onClick={() => {
            updateActiveZip(null)
            setOpen(false)

            updateActiveZip(zip)
          }}
        >
          <span style={{ color: "red" }}>{t("findMyLocation")}</span>
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function mapStateToProps(state, props) {
  return {
    user: state.user,
    activeZip: state && state.popUpComponent && state.popUpComponent.activeZip
  }
}

export default connect(mapStateToProps, { updateActiveZip })(
  withTranslation("gatherZipModal")(PopUpComponent)
)
