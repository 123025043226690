import React, { useState } from "react"
import { Image } from "semantic-ui-react"
import RemoveServiceFromCart from "./RemoveServiceFromCart"
import MiniLoader from "../../../v1/components/MiniLoader"

const TireSearch = ({
  handleClick,
  tireLogo,
  serviceHeader,
  service,
  serviceSubLine,
  tireNote,
  tireNoteLogo,
  brandLogo,
  className,
  isTireInCart,
  t,
  isCartService,
  remove,
  isPresentInCart
}) => {
  const [isClickedOnRemove, setIsClickedOnRemove] = useState(false)

  const handleRemove = async (e) => {
    e.stopPropagation()
    setIsClickedOnRemove(true)
    await remove()
    setIsClickedOnRemove(false)
  }

  return (
    <div
      className={`new-tire-section ${className}`}
      // onClick={() => !isCartService && handleClick()}
      onClick={() => !isPresentInCart && handleClick()}
    >
      <div className="new-tire-header">
        <div>
          <div className="new-tire-margin"></div>
          <div className="server-label">{serviceHeader}</div>
        </div>
        {isTireInCart ? (
          <MiniLoader inverted={false} />
        ) : (
          isCartService &&
          !isPresentInCart && (
            <div
              className="add-to-cart"
              // onClick={() => !isTireInCart && !isPresentInCart && handleClick()}
            >
              {t("addToCart")}
            </div>
          )
        )}
        {isCartService && isPresentInCart && (
          <RemoveServiceFromCart isLoading={isClickedOnRemove} t={t} handleRemove={handleRemove} />
        )}
      </div>
      <div className="new-tire-details">
        <Image className="tire-image" src={tireLogo} />
        <div className="new-tire">
          <div className="new-tire-description">
            {service}
            {brandLogo && <Image src={brandLogo} />}
          </div>
          <p className="tire-deal">{serviceSubLine}</p>
          <div className="tire-shipping">
            {tireNoteLogo && <Image src={tireNoteLogo} />}
            <p className="tire-note">{tireNote}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TireSearch
