import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Popup, Accordion, Icon, Checkbox } from "semantic-ui-react"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"
import SpacingComponent from "./SpacingComponent"
import { DISTANCE, PRICE } from "../../../../../shared_component/constants/checkout"

const ShopSortingComponent = (props) => {
  const { sortingType, setSortingType, vehicleTypeId, cart, isSortOpen, setIsSortOpen } = props

  const { t } = useTranslation("dashboardMapComponent")

  const handleFilterIconClick = () => {
    setIsSortOpen(!isSortOpen)
  }

  return (
    <div style={{ display: "flex" }}>
      <Popup
        basic
        disabled={
          (vehicleTypeId && vehicleTypeId == MD_HD_VEHICLE_TYPE_ID) ||
          ((cart && cart.order_services) || []).length === 0
        }
        trigger={
          <FilterIcon
            sortingType={sortingType}
            isOpen={isSortOpen}
            onClick={handleFilterIconClick}
            t={t}
          />
        }
        content={
          <FilterOptions
            sortingType={sortingType}
            setSortingType={(value) => {
              setIsSortOpen(false)
              setSortingType(value)
            }}
          />
        }
        on="click"
        position="bottom right"
        style={{ left: "38px", zIndex: 9999 }}
        open={isSortOpen}
      />
    </div>
  )
}

const FilterIcon = (props) => {
  const SortIcon = () => {
    return (
      <div className="sort_icon">
        <Icon name="chevron up" size="tiny" />
        <Icon name="chevron down" size="tiny" />
      </div>
    )
  }

  return (
    <div className="mapHeaderComponent" onClick={() => props.onClick()}>
      <span style={{ fontFamily: "lato" }}>
        {props.t("sortBy")}: <span>{props.sortingType == PRICE ? "Price" : "Distance"}</span>
      </span>
      <SortIcon />
    </div>
  )
}

const FilterOptions = (props) => {
  const { sortingType, setSortingType } = props
  return (
    <Accordion vertical>
      <div style={{ width: "120px" }}>
        <FilterItem
          isSelected={sortingType && sortingType == PRICE}
          onClick={(value) => setSortingType(value)}
          label="Price"
          value={PRICE}
        />
        <SpacingComponent />
        <FilterItem
          isSelected={sortingType && sortingType == DISTANCE}
          onClick={(value) => setSortingType(value)}
          label="Distance"
          value={DISTANCE}
        />
      </div>
    </Accordion>
  )
}

const FilterItem = (props) => {
  const { isSelected, onClick, label, value } = props
  return (
    <div className="filterComponent">
      <Checkbox value={value} checked={isSelected} onChange={() => onClick(value)} />
      <span className="checkBoxLabel">{label}</span>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null

  return {
    user: user,
    cart,
    vehicleTypeId
  }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardMapComponent")(ShopSortingComponent)
)
