import React, { Component } from "react"
import DefaultShopLogo from "../../../../images/default_shop_logo.svg"
import { Button, Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import ShopSearch from "./ShopSearch"
import moment from "moment"
import { DATE_TIME_FORMAT } from "../../../../v1/constants/application"
import { ACTIVE_VIEWS } from "../../../../shared_component/constants/checkout"
import { WALK_IN_ACTIVE, WALK_IN_TIME_STRING } from "../../../../shared_component/constants/common"
import { connect } from "react-redux"
import { constants } from "caradvise_shared_components"
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

class SelectedShopCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: false || props.showMap
    }
  }

  componentDidUpdate(prevProps) {
    const { shop } = this.props
    if (prevProps.shop && shop) {
      if (prevProps.shop.id !== shop.id) {
        this.setState({ showMap: false })
      }
    }
  }

  showDateTime = (cart) => {
    const { t } = this.props
    return (
      <>
        <span>
          {cart.appointment_datetime &&
            moment(cart.appointment_datetime)
              .parseZone()
              .format(DATE_TIME_FORMAT.APPOINTMENT_DATETIME_DATE_PREVIEW)}
        </span>
        <span>
          {cart.appointment_time_pretty.includes(WALK_IN_TIME_STRING)
            ? " - "
            : cart.appointment_time_pretty && " @ "}
        </span>
        {cart.appointment_time_pretty.includes(WALK_IN_TIME_STRING) ? (
          t("timeInformation:walkInShopAvailablityText")
        ) : (
          <span>
            {cart.appointment_datetime &&
              moment(cart.appointment_datetime)
                .parseZone()
                .format(DATE_TIME_FORMAT.APPOINTMENT_DATETIME_TIME_PREVIEW)}
          </span>
        )}
      </>
    )
  }
  renderView = () => {
    const {
      cart,
      shop,
      handleShopChange,
      t,
      isReschedule = false,
      order,
      isSchedule = false
    } = this.props
    const includesStatuses = [ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE]
    const showModifyAppointment = order && cart && includesStatuses.includes(order.status)
    return (
      <div className={`selected-shop-container ${isSchedule ? "selected-shop" : ""}`}>
        <div className="selected-shop-name-section">
          <div className="shop_name-section">
            <Image
              className="shop_logo"
              src={shop && shop.logo_url ? shop.logo_url : DefaultShopLogo}
            />

            <div className="shop_name">{shop.name}</div>
          </div>
          {!isReschedule && (
            <Button
              basic
              onClick={() => {
                if (handleShopChange && handleShopChange) {
                  handleShopChange()
                } else this.setState({ showMap: true })
              }}
            >
              {!showModifyAppointment && (
                <span className="change_link-button">{t("changeLabel")}</span>
              )}
            </Button>
          )}
        </div>

        <div className="selected-shop-address">
          {shop.address_line1}, {shop.city}, {shop.state} {shop.zip}
        </div>
        {cart && !isReschedule && !isSchedule && (
          <div className="selected-shop-date-time">{this.showDateTime(cart)}</div>
        )}
      </div>
    )
  }

  render() {
    return (
      <>{!this.state.showMap ? this.renderView() : <ShopSearch activeView={ACTIVE_VIEWS.LIST} />}</>
    )
  }
}
function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)

  return {
    user: user || {},
    order
  }
}

export default connect(mapStateToProps)(
  withTranslation(["schedule", "common", "timeInformation"])(SelectedShopCard)
)
