import React from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"

export const NoServiceFound = ({ serviceName = "", searchQuery = "" }) => {
  const { t } = useTranslation("common")
  return (
    <div className="no-service-found-container">
      <ReactSVG src="images/no_service_icon.svg" />
      <div className="text">
        {serviceName !== "" ? (
          <p>
            {t("noServiceText")} {serviceName}
          </p>
        ) : (
          searchQuery !== 0 && (
            <p>
              {t("noRecordsText")} {searchQuery}
            </p>
          )
        )}
      </div>
    </div>
  )
}
