const ADD_ORIGIN = "ADD_ORIGIN"

const initialState = {}

export default function originReducer(state = initialState, action) {
  //const nextState = Object.assign({}, state);
  switch (action.type) {
    case ADD_ORIGIN:
      return Object.assign({}, state, { origin: action.origin })

    default:
      return state
  }
}
