import React, { Component } from "react"
import { Grid, Button, Card, Divider, Image } from "semantic-ui-react"
import { EVENT_NAME, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import welcome1 from "../../../images/wlcomeScreens/welcome1.png"
import welcome2 from "../../../images/wlcomeScreens/welcome2.png"
import welcome3 from "../../../images/wlcomeScreens/welcome3.png"
import mobilewelcome1 from "../../../images/wlcomeScreens/mobile-welcome1.png"
import mobilewelcome2 from "../../../images/wlcomeScreens/mobile-welcome2.png"
import mobilewelcome3 from "../../../images/wlcomeScreens/mobile-welcome3.png"
import { getCookie } from "../../../shared_component/utils/urlUtils"
import {
  ONBOARDING_WELCOME_CARDS_VERSION,
  ONBOARDING_WELCOME_CARDS_FLAG,
  ONBOARDING_WELCOME_CARDS_VERSION_NUMBER
} from "../../constants/application"
import { getEventNameByKey } from "../../helpers/segmentHelper"

class WelcomeCard extends Component {
  timeout = 600
  nextButton = () => {
    const { t, pageNumber, onNext } = this.props
    return (
      <Button
        onClick={() => {
          setTimeout(() => {
            trackEvent("welcome-screen", {
              screenNumber: pageNumber,
              buttonName: "next",
              action: "click"
            })
          }, this.timeout * pageNumber)
          onNext()
        }}
      >
        {t("next")}
      </Button>
    )
  }

  skipButton = () => {
    const { t, pageNumber, onSkip } = this.props
    return (
      <a
        onClick={() => {
          setTimeout(() => {
            trackEvent("welcome-screen", {
              screenNumber: pageNumber,
              buttonName: "skip",
              action: "click"
            })
          }, this.timeout * pageNumber)
          onSkip()
        }}
      >
        {t("skip")}
      </a>
    )
  }

  render() {
    const {
      t,
      title,
      message1,
      message2,
      image,
      pageNumber,
      showSkip,
      totalPages,
      mobileImage
    } = this.props
    return (
      <div className="welcome-container">
        <div className="backdropDesign" />
        <Grid>
          <Grid.Row only="computer">
            <div className="welcome-web-container-modal">
              {showSkip && <div className="web-skip">{this.skipButton()}</div>}
              <div className="web-container">
                <div className="web-container-left">
                  <div>
                    <p className="web-title">{title}</p>
                    <p style={{ textAlign: "initial", marginBlockStart: "0em" }}>
                      <span className="web-message1">{message1}</span>
                      <span className="web-message2">{message2}</span>
                    </p>
                  </div>
                </div>
                <div className="web-container-right">
                  <div>
                    <Image src={image} className="web-image" />
                  </div>
                </div>
              </div>
              <div className="web-container-below">
                <div className="web-dots">
                  <span className="web-page-nav"> {pageNumber + t("of") + totalPages} </span>
                </div>
                <div className="flex-web-right">{this.nextButton()}</div>
              </div>
            </div>
          </Grid.Row>
          <Grid.Row only="tablet">
            <div className="welcome-tab-container-modal">
              {showSkip && <div className="tab-skip">{this.skipButton()}</div>}
              <div className="tab-container">
                <div className="tab-container-left">
                  <div>
                    <p className="tab-title">{title}</p>
                    <p style={{ textAlign: "initial", margin: "0em" }}>
                      <span className="tab-message1">{message1}</span>
                      <span className="tab-message2">{message2}</span>
                    </p>
                  </div>
                </div>
                <div className="tab-container-right">
                  <div>
                    <Image src={image} className="tab-image" />
                  </div>
                </div>
              </div>
              <div className="tab-container-below">
                <div className="tab-dots">
                  <span className="tab-page-nav"> {pageNumber + t("of") + totalPages} </span>
                </div>
                <div className="flex-tab-right">{this.nextButton()}</div>
              </div>
            </div>
          </Grid.Row>
          <Grid.Row only="mobile">
            <div className="mobile-welcome-container-modal">
              <div
                style={{
                  backgroundImage: `url(${mobileImage})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "initial",
                  width: "100%",
                  height: "70%"
                }}
                className="mobile-image"
              >
                {showSkip && <div className="mobile-skip">{this.skipButton()}</div>}
              </div>
              <div></div>
              <div className="mobile-info-section">
                <p className="mobile-title">{title}</p>
                <p style={{ textAlign: "initial" }}>
                  <span className="mobile-message1">{message1}</span>
                  <span className="mobile-message2">{message2}</span>
                </p>
              </div>
              <div className="flex-container">
                <div className="flex-left">
                  {[...Array(totalPages)].map((e, i) => (
                    <span className={pageNumber - 1 === i ? "dot active" : "dot"}></span>
                  ))}
                </div>
                <div className="flex-right">{this.nextButton()}</div>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

class WelcomeCards extends Component {
  constructor(props) {
    super(props)
    const { t } = props
    this.state = {
      screenNo: 0,
      ONBOARDING_SCREEN_ARRAY: [
        {
          id: 1,
          image: welcome1,
          mobileImage: mobilewelcome1,
          title: t("welcomeScreen1Tiltellbl"),
          message1: t("welcomeScreen1Lbl1"),
          message2: t("welcomeScreen1Lbl2"),
          showSkip: true
        },
        {
          id: 2,
          image: welcome2,
          mobileImage: mobilewelcome2,
          title: t("welcomeScreen2Tiltellbl"),
          message1: t("welcomeScreen2Lbl1"),
          message2: t("welcomeScreen2Lbl2"),
          showSkip: true
        },
        {
          id: 3,
          image: welcome3,
          mobileImage: mobilewelcome3,
          title: t("welcomeScreen3Tiltellbl"),
          message1: t("welcomeScreen3Lbl1"),
          message2: t("welcomeScreen3Lbl2"),
          showSkip: false
        }
      ]
    }
  }
  next = () => {
    let screenNo = this.state.screenNo + 1
    if (screenNo === this.state.ONBOARDING_SCREEN_ARRAY.length) {
      document.cookie = `${ONBOARDING_WELCOME_CARDS_FLAG}=${true}`
      document.cookie = `${ONBOARDING_WELCOME_CARDS_VERSION}=${ONBOARDING_WELCOME_CARDS_VERSION_NUMBER}`
    }
    this.setState({
      screenNo: screenNo
    })
  }
  skip = () => {
    this.setState({
      screenNo: this.state.ONBOARDING_SCREEN_ARRAY.length + 1
    })
    document.cookie = `${ONBOARDING_WELCOME_CARDS_FLAG}=${true}`
    document.cookie = `${ONBOARDING_WELCOME_CARDS_VERSION}=${ONBOARDING_WELCOME_CARDS_VERSION_NUMBER}`
  }

  render() {
    const { t } = this.props
    const { ONBOARDING_SCREEN_ARRAY, screenNo } = this.state
    const welcomeScreenFlag = getCookie(ONBOARDING_WELCOME_CARDS_FLAG)
    const welcomeCardsVersion = getCookie(ONBOARDING_WELCOME_CARDS_VERSION)
    return (
      screenNo < ONBOARDING_SCREEN_ARRAY.length &&
      (!welcomeScreenFlag || welcomeCardsVersion < ONBOARDING_WELCOME_CARDS_VERSION_NUMBER) && (
        <WelcomeCard
          image={ONBOARDING_SCREEN_ARRAY[screenNo].image}
          mobileImage={ONBOARDING_SCREEN_ARRAY[screenNo].mobileImage}
          title={ONBOARDING_SCREEN_ARRAY[screenNo].title}
          message1={ONBOARDING_SCREEN_ARRAY[screenNo].message1}
          message2={ONBOARDING_SCREEN_ARRAY[screenNo].message2}
          showSkip={ONBOARDING_SCREEN_ARRAY[screenNo].showSkip}
          pageNumber={screenNo + 1}
          totalPages={ONBOARDING_SCREEN_ARRAY.length}
          onNext={this.next}
          onSkip={this.skip}
          t={t}
        />
      )
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}

  return {
    user: user,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading,
    isGeneric: user.generic,
    firstName: user.firstName,
    lastName: user.lastName
  }
}

export default connect(mapStateToProps)(withTranslation("welcomeCards")(WelcomeCards))
