import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { navigationPaths } from "../../../v1/constants/paths"
import { FILTERS } from "../../../shared_component/constants/checkout"
import { Link, Redirect, withRouter } from "react-router-dom"

function NoServicesContent(props) {
  const { t } = useTranslation("browseServices")
  return (
    <div className="no-service-container">
      <div className="no-service-header">{t("searchForLabel")}</div>
      <ServiceChips history={props.history} />
    </div>
  )
}

const ServiceChips = (props) => {
  const [filters, setFilters] = useState([
    { name: FILTERS.ALL, state: 3 },
    { name: FILTERS.COMMON_SERVICES, state: 1 },
    { name: FILTERS.MAINTENANCE_SERVICES, state: 2 }
  ])
  return (
    <div className="no-service-filter-cards">
      {filters &&
        filters.map((item, index) => (
          <Link key={index} to={{ pathname: navigationPaths.addServices(), state: item.state }}>
            <div className={`button-chip-container`} key={index}>
              {item.name}
            </div>
          </Link>
        ))}
    </div>
  )
}

export default withRouter(NoServicesContent)
