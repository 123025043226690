import React, { PureComponent } from "react"
import { connect } from "react-redux"
import store from "../../shared_component/utils/configureStore"
import { logoutUser, getUserDetails } from "../actions/user"
import Badge from "./Badge"
import { withRouter, Link } from "react-router-dom"
import { isSigningUp, isPartnerOfferScreen } from "../helpers/pathHelpers"
import { Menu, Dropdown, Responsive, Header, Image } from "semantic-ui-react"
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons"
import {
  faShoppingCart as faCartSolid,
  faCalendarCheck as faCalSolid
} from "@fortawesome/pro-solid-svg-icons"
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons"
import { nextMembershipUpgrades } from "../helpers/membershipHelpers"
import MembershipUpgradeModal from "../components/Membership/MembershipUpgradeModal"
import CartInfoPopup from "../components/CartInfoPopup"
import { withTranslation } from "react-i18next"
import { Row, Col } from "react-grid-system"
import { EVENT_NAME, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { getAdditionalProps } from "../helpers/segmentHelper"
import User from "../../images/person.png"
import downArrow from "../../images/Vector.png"
import CustomHeaderComponent from "../CarAdvise2/screens/components/CustomHeaderComponent"
import { DEFAULT_IMG_URL } from "../constants/confirmDetails"
import { isEmpty } from "lodash"
export const CART_ICON_ID = "toggle-cart"

class DashHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef(null)
    this.state = {
      open: false,
      membershipModalOpen: false
    }
    this.toggleCart = this.toggleCart.bind(this)
    this.onRouteChanged = this.onRouteChanged.bind(this)
  }

  toggleCart(event, fromState) {
    if (fromState === undefined) fromState = this.state.cartOpen
    let newState = !fromState
    const types = [".dashLeftContainer", ".content", ".dashLeft", ".container", ".generic-panel"]
    if (newState) {
      let divs
      types.map((type) => {
        divs = document.querySelectorAll(type)
        const start = type === ".container" ? 1 : 0
        for (let i = start; i < divs.length; i++) {
          divs[i].classList.add("shopping-cart-open")
        }
      })
      document.querySelectorAll(".dashRight .cart-panel")[0].classList.add("visible")
    } else {
      let divs
      types.map((type) => {
        divs = document.querySelectorAll(type)
        const start = type === ".container" ? 1 : 0
        for (let i = 0; i < divs.length; i++) {
          divs[i].classList.remove("shopping-cart-open")
        }
      })
      divs = document.querySelectorAll(".cart-panel")
      for (let i = 0; i < divs.length; i++) {
        divs[i].classList.remove("visible")
      }
    }

    this.setState({ cartOpen: newState })
  }

  componentWillUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    this.toggleCart(null, true)
  }

  toggleMembershipUpgradeModal = () => {
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  routeChange(path) {
    this.props.history.push(path)
  }

  render() {
    let {
      cart,
      activeOrder,
      isLoggedIn,
      isGeneric,
      nextUpgrades,
      accountTierName,
      t,
      firstName,
      lastName,
      email,
      hideChrome,
      user,
      activeVehicle
    } = this.props
    const { cartOpen, membershipModalOpen, userData, vehicleData, open } = this.state
    const hasFirstName = firstName != null
    const hasLasttName = lastName != null
    const hasFirstAndLastName = hasFirstName && hasLasttName
    const currentUserName = hasFirstAndLastName ? firstName + " " + lastName : ""
    const vehicleDetails =
      !isEmpty(activeVehicle) &&
      activeVehicle.year + " " + activeVehicle.make + " " + activeVehicle.model

    if (hideChrome) return null

    let count =
      !activeOrder && cart && cart.order_services && cart.order_services.length > 0
        ? cart.order_services.length
        : null
    if (activeOrder) count = "!"

    const icon = activeOrder
      ? cartOpen
        ? faCalSolid
        : faCalendarCheck
      : cartOpen
      ? faCartSolid
      : faShoppingCart

    return (
      <div>
        <Responsive minWidth={900}>
          <div className="site-header" style={{ position: "sticky!important" }}>
            <div className="borderless massive ui header-width">
              {isLoggedIn && !isGeneric && (
                <Row className="header-parent">
                  <div className="headerLogo" />
                  <Menu.Item>
                    <div className="partnerLogo" />
                  </Menu.Item>
                  {isLoggedIn && !isGeneric && (
                    <Menu.Menu position="right">
                      <div className="user">
                        <div
                          className="user-details"
                          onClick={() => this.setState({ open: !open })}
                        >
                          <img src={User} />
                          <div className="user-name">{currentUserName}</div>
                          <img src={downArrow} />
                        </div>
                        {accountTierName ? (
                          <span className="tier-banner">
                            {accountTierName} {t("accountLbl")}
                          </span>
                        ) : (
                          <span className="tier-banner">
                            {t("membershipCard:basic")} {t("accountLbl")}
                          </span>
                        )}
                        {nextUpgrades.length > 0 && (
                          <span
                            className="upgradeModalLink"
                            onClick={this.toggleMembershipUpgradeModal}
                          >
                            {t("upgradeAccountLbl")}
                          </span>
                        )}
                        {membershipModalOpen && (
                          <MembershipUpgradeModal
                            isOpen={membershipModalOpen}
                            closeModal={this.toggleMembershipUpgradeModal}
                            variableContentType="settingsSection"
                            showUpgradeBenefits={true}
                          />
                        )}
                      </div>
                      <div
                        className={`profile_modal ${open ? "active" : "inactive"}`}
                        onClick={() => this.setState({ open: !open })}
                      ></div>
                      <div
                        className={`profile-dropdown ${open ? "active" : "inactive"}`}
                        ref={this.myRef}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingLeft: "16px"
                          }}
                        ></div>
                        <Dropdown.Item onClick={() => this.routeChange("/myaccount")}>
                          <Link
                            className={`profile-link ${open ? "active" : "inactive"}`}
                            to="/myaccount"
                            onClick={() => {
                              trackEvent("my-account-clicked", { ...getAdditionalProps() })
                              this.setState({ open: !open })
                            }}
                          >
                            {t("myAccountLbl")}
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            this.routeChange("/membership")
                            this.setState({ open: !open })
                          }}
                        >
                          <Link
                            className={`profile-link ${open ? "active" : "inactive"}`}
                            to="/membership"
                          >
                            {t("membershipCardLbl")}
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => store.dispatch(logoutUser())}>
                          <Link
                            className={`profile-link ${open ? "active" : "inactive"}`}
                            to="/signin"
                            onClick={() => {
                              store.dispatch(logoutUser())
                              this.setState({ open: !open })
                            }}
                          >
                            {t("signoutLbl")}
                          </Link>
                        </Dropdown.Item>
                      </div>
                    </Menu.Menu>
                  )}
                </Row>
              )}
              {isLoggedIn && !isGeneric && (
                <Row className="vehicle" style={{ width: "auto !important" }}>
                  <div className="vehicle-details">
                    <Image
                      src={
                        !isEmpty(activeVehicle) && activeVehicle.dashboard_mobile_url
                          ? activeVehicle.dashboard_mobile_url
                          : DEFAULT_IMG_URL
                      }
                      className="header-img"
                    />
                    {vehicleDetails ? <span className="secondary-font">{vehicleDetails}</span> : ""}
                  </div>
                </Row>
              )}
            </div>
            {isLoggedIn && !(isGeneric && isSigningUp()) && (
              <div
                className={`shopping-cart-icon ${cartOpen ? "open" : "closed"}`}
                onClick={this.toggleCart}
                id={CART_ICON_ID}
              >
                <div>
                  <Badge icon={icon} count={count} size="1x" />
                </div>
              </div>
            )}
          </div>
        </Responsive>
        <Responsive maxWidth={539}>
          <div className="site-header">
            <div
              className="headerLeft"
              style={!firstName ? { justifyContent: "center", padding: 0 } : {}}
            >
              <CustomHeaderComponent page={window.location.hash} />
            </div>
            {isLoggedIn && (
              <div className="headerRightContainer">
                <div className="headerRight">
                  <div className="headerUser">
                    {firstName} {lastName}
                    <br />
                    <Link to="/signin" onClick={() => store.dispatch(logoutUser())}>
                      {t("signoutLbl")}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {isLoggedIn && !(isGeneric && isSigningUp()) && !isPartnerOfferScreen() && (
              <div
                className={`shopping-cart-icon ${cartOpen ? "open" : "closed"}`}
                onClick={this.toggleCart}
                id={CART_ICON_ID}
              >
                <div>
                  <Badge icon={icon} count={count} size="1x" />
                  <CartInfoPopup
                    count={count}
                    trigger={<div style={{ pointerEvents: "none" }}></div>}
                  />
                </div>
              </div>
            )}
          </div>
        </Responsive>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  const { membershipData, paymentMethods } = state
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let activeOrder =
    user.active_orders && user.active_orders.find((s) => s.vehicle_id == user.activeVehicleId)
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    user: user,
    hideChrome: state.framework && state.framework.hideChrome,
    cart: cart,
    location: props.location,
    activeOrder,
    isLoggedIn: !!user.authentication_token,
    isGeneric: user.generic,
    accountTierName: user.membership && user.membership.plan_name,
    hasPaymentMethod: user.has_cc_info_saved,
    nextUpgrades: nextMembershipUpgrades(user, membershipData.availableMembershipPlans),
    paymentMethods: paymentMethods.braintreePaymentMethods,
    activeVehicle: activeVehicle
  }
}

export default withRouter(
  connect(mapStateToProps, { logoutUser, getUserDetails })(withTranslation("header")(DashHeader))
)
