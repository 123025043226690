import React, { Component, useEffect, useRef, useState } from "react"

import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapPin } from "@fortawesome/pro-light-svg-icons"
import Geosuggest from "react-geosuggest"
import { withTranslation } from "react-i18next"
import { logoForShop } from "../../../../../shared_component/utils/shopLogos"
import { getShopAddress, withPricing } from "../../../../helpers/shopHelpers"
import ShopListItems from "./ShopListItems"
import Button from "../../../../components/Button"
import ArrowRight from "../../../images/shops/arrow-right.svg"
import ArrowLeft from "../../../images/shops/arrow-left.svg"
import MarkerBG from "../../../images/shops/marker.svg"
import MarkerSelectedBG from "../../../images/shops/marker-highlited.svg"
import EmptyShopLogo from "../../../images/shops/empty_shop_logo.svg"
import RecenterIcon from "../../../images/shops/recenter.svg"
import MyLocationImg from "../../../../../images/map/location.png"
import SponsoredShopLogo from "../../../../../images/dashboard/check-icon.svg"

import MapGL, { Marker, NavigationControl, Popup } from "react-map-gl"
import { Divider, Icon, Image } from "semantic-ui-react"
import ShopItemComponent from "./ShopItemComponent"
import ShopMapItem from "../../Map/components/ShopMapItem"
import MapControlContainer from "../../Map/components/MapControlContainer"
import { ProgressHUDMap } from "../../components/ProgressHUD"
import { suggestGeoSuggestClick } from "../../../helpers/geoLocationHelper"
import { MARKER, SHOPLIST } from "../../../../constants/shops"
import FeaturedLabel from "./FeaturedLabel"

const ICON_WIDTH = 60
const ICON_HEIGHT = 60
const SCROLL_OFFSET = 180

const navStyle = {
  position: "absolute",
  top: 100,
  right: 0,
  padding: "10px"
}

const MapWidget = (props) => {
  const {
    shops,
    selectedShop,
    t,
    user,
    updateViewport,
    viewPort,
    setShopSelected,
    shopSelected,
    setShowMapExpanded,
    showMapExpanded,
    onSelectShopClick,
    onSuggestSelect,
    hideFilters,
    recenterMap,
    setViewType,
    view,
    position,
    setPosition,
    showShopTiles = true,
    searchLocation = undefined
  } = props

  const scrollRef = useRef(null)

  const maxWidth = window.innerWidth * 0.7

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset
  }

  const onMarkerHover = (shop) => {
    recenterMap(shop.latitude, shop.longitude)
    setShopSelected(shop)
    setViewType(MARKER)
  }

  const onClosePopup = () => {
    setShopSelected(undefined)
  }

  return (
    <MapGL
      {...viewPort}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={updateViewport}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      attributionControl={false}
      style={{ borderRadius: "20px" }}
    >
      <React.Fragment>
        {searchLocation && searchLocation.latitude && searchLocation.longitude && (
          <Marker latitude={searchLocation.latitude} longitude={searchLocation.longitude}>
            <Image
              style={{ maxWidth: "30px" }}
              src={MyLocationImg}
              className="current-position-image"
            />
          </Marker>
        )}
        {shops &&
          shops.length > 0 &&
          shops.map((shop, index) => {
            const { latitude, longitude } = shop

            const isSelectedShop = shopSelected && shop.id === shopSelected.id
            const selectedShopMarkerBG = shop.shop_featured
              ? "sponsored-shop-selected-mapMarkerBG"
              : "mapMarkerSelectedBG"
            return (
              <React.Fragment key={`marker-${index}-fragment`}>
                <Marker
                  key={`marker-${index}`}
                  latitude={latitude}
                  longitude={longitude}
                  offsetLeft={-ICON_WIDTH / 2}
                  offsetTop={-ICON_HEIGHT}
                  className={`${isSelectedShop ? "onTop" : ""}`}
                >
                  <div className="map-marker-bg-image">
                    <Image
                      onClick={() => onMarkerHover(shop)}
                      src={
                        shop.shop_featured
                          ? SponsoredShopLogo
                          : shop.logo_url
                          ? shop.logo_url
                          : EmptyShopLogo
                      }
                      className={` 
                        marker-shop-logo markerLogoStyling
                        ${isSelectedShop ? selectedShopMarkerBG : "mapMarkerBG"}
                        ${shop.shop_featured ? "sponsored-shop-mapMarkerBG" : ""}
                      `}
                    />
                  </div>
                </Marker>

                {isSelectedShop && window.innerWidth > 720 && (
                  <>
                    {view === MARKER && (
                      <Popup
                        className="onTop"
                        latitude={shopSelected.latitude}
                        longitude={shopSelected.longitude}
                        closeOnClick={false}
                        closeButton={false}
                        anchor="top"
                        style={{ padding: "10px 10px 6px" }}
                        maxWidth={maxWidth}
                        onClose={() => onClosePopup()}
                      >
                        <FeaturedLabel
                          shop={shopSelected}
                          showCheckIcon={false}
                          showTooltip={true}
                        />
                        <ShopItemComponent
                          shop={shopSelected}
                          onClose={() => onClosePopup()}
                          isPopUpComponent={true}
                          onSelectShopClick={onSelectShopClick}
                        />
                      </Popup>
                    )}
                    {view === SHOPLIST && (
                      <div
                        className={`shopMapItemWebContainer ${
                          shopSelected.shop_featured ? "featured" : ""
                        }`}
                      >
                        <FeaturedLabel
                          shop={shopSelected}
                          showCheckIcon={false}
                          showTooltip={true}
                        />
                        <ShopItemComponent
                          shop={shopSelected}
                          isPopUpComponent={true}
                          onSelectShopClick={() => {
                            onSelectShopClick(shopSelected)
                          }}
                          setShopSelected={setShopSelected}
                          onClose={() => onClosePopup()}
                        />
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            )
          })}
      </React.Fragment>
      <div className="geo_container">
        <Geosuggest
          onSuggestSelect={(suggest) => suggestGeoSuggestClick(suggest, onSuggestSelect)}
          placeholder="Search on Map"
          types={["geocode"]}
          country={user.country}
          ignoreTab={true}
          className="geosuggestContainerNew"
          suggestsClassName="search-suggests-list"
          suggestItemClassName="search-suggest-item"
          inputClassName={`searchFldShop smaller-width`}
          suggestItemActiveClassName="search-suggest-item-active"
          suggestsHiddenClassName="search-suggests-list-hidden"
          fixtures={[{ label: t("use-current-location"), custom: true }]}
        />
      </div>

      <div className="nav" style={navStyle}>
        <MapControlContainer
          recenterClick={() => recenterMap()}
          expandCompressClick={() => {
            hideFilters()
            setShowMapExpanded(!showMapExpanded)
          }}
          isExpanded={showMapExpanded}
        />
        {!showMapExpanded && <NavigationControl onViewportChange={updateViewport} />}
      </div>

      {showShopTiles && shops && shops.length > 0 && !showMapExpanded && (
        <div className="shopListHorizontalContainer">
          <div className="iconContainer" onClick={() => scroll(-SCROLL_OFFSET)}>
            <Image src={ArrowLeft} height="20px" width="20px" />
          </div>
          <div className="shopListContainer" ref={scrollRef}>
            {shops.map((shop, index) => (
              <ShopMapItem
                isSelected={selectedShop && shop.id == selectedShop.id}
                key={index}
                shop={shop}
                setShopSelected={setShopSelected}
                onSelectShopClick={onSelectShopClick}
                recenterMap={recenterMap}
                setViewType={setViewType}
              />
            ))}
          </div>
          <div
            className={`iconContainer ${shops.length > 3 ? "right-iconContainer" : ""}`}
            onClick={() => scroll(SCROLL_OFFSET)}
          >
            <Image src={ArrowRight} height="20px" width="20px" />
          </div>
        </div>
      )}
      {props.loading && (
        <div
          style={{
            position: "relative",
            display: "flex",
            flex: 1,
            height: "100%",
            margin: "0rem auto",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {props.loading && <ProgressHUDMap />}
        </div>
      )}
      {shopSelected && Object.keys(shopSelected).length !== 0 && window.innerWidth <= 720 && (
        <div
          className="shopMapItemMobileContainer mapWidget"
          style={
            shopSelected.shop_featured
              ? { borderRadius: "0px 10px 10px 10px", border: "1px solid #282828" }
              : { borderRadius: "10px" }
          }
        >
          <FeaturedLabel shop={shopSelected} showCheckIcon={false} showTooltip={true} />
          <ShopItemComponent
            shop={shopSelected}
            isPopUpComponent={true}
            onSelectShopClick={() => {
              onSelectShopClick(shopSelected)
            }}
            setShopSelected={setShopSelected}
            isMobileViewExpanded={true}
            onClose={() => onClosePopup()}
          />
        </div>
      )}
    </MapGL>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(MapWidget))
