import React, { PureComponent } from "react"
import MembershipUpgradeCard from "./MembershipUpgradeCard"
import { withTranslation } from "react-i18next"

class MembershipUpgradeContent extends PureComponent {
  render = () => {
    const {
      onSelectMembership,
      selectedMembership,
      availableMembershipPlans,
      currentMembershipPlan,
      isDashboardModal,
      isAffiliation
    } = this.props
    const nextUpgrades =
      isAffiliation || isDashboardModal
        ? availableMembershipPlans || []
        : this.props.nextUpgrades || []
    if (selectedMembership == {}) return null

    return (
      <div className="membership-upgrade-content">
        <div className="membership-upgrade-cards">
          {nextUpgrades.map((plan) => {
            return (
              <MembershipUpgradeCard
                style={nextUpgrades.length > 1 ? { width: "49%" } : {}}
                key={`MembershipUpgradeContent-${plan.name}`}
                plan={plan}
                onSelectMembership={onSelectMembership}
                isSelected={plan.name === selectedMembership.name}
                currentMembershipPlan={currentMembershipPlan}
                isAffiliation={isAffiliation}
                isDashboardModal={isDashboardModal}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default withTranslation("membershipUpgradeContent")(MembershipUpgradeContent)
