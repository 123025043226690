import React, { useState, useEffect } from "react"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import InviteFriendsLogo from "../../../images/rewards/invite-friends.svg"
import CopyCodeLogo from "../../../images/rewards/copy-code.svg"
import WhiteCopyCodeLogo from "../../../images/rewards/white-copy-icon.svg"
import { Portal } from "semantic-ui-react"
import { COPY, REFERAL_CODE, TEXT_AREA } from "../../../shared_component/constants/rewards"

const InviteFriendsRewards = ({ t, user }) => {
  const [isCopied, setIsCopied] = useState(false)
  const [openPortal, setOpenPortal] = useState(false)

  const copyToClipboard = () => {
    if (user.generic) {
      return
    }
    const textArea = document.createElement(TEXT_AREA)
    textArea.value = REFERAL_CODE
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand(COPY)
    document.body.removeChild(textArea)
    setIsCopied(true)
    handlePortal()
  }

  const handlePortal = () => {
    setOpenPortal((prev) => !prev)
  }

  useEffect(() => {
    if (openPortal) {
      setTimeout(() => {
        handlePortal()
      }, 5000)
    }
  }, [openPortal])

  return (
    <div className="invite-friends-rewards">
      <div className={`invite-friends-rewards-box ${user.generic ? "disable-color" : ""}`}>
        <div className="invite-friends-container">
          <p className="invite-frnds-heading">
            {t("inviteFriendsUnlock")} <span className="free-miles">{t("freeMiles")}</span>{" "}
            {t("forYourVehicle")}
          </p>
          <p className="invite-frnds-sub-heading">{t("friendsInvite")}</p>
          <div className="invite-code">
            {t("code")}: {REFERAL_CODE}{" "}
            <span className="copy-logo" onClick={copyToClipboard}>
              <ReactSVG src={CopyCodeLogo} />
            </span>
          </div>
        </div>
        <div className="invite-friends-logo">
          <ReactSVG src={InviteFriendsLogo} className="invite-friends-logo" />
        </div>
      </div>
      <Portal onClose={handlePortal} open={openPortal}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            backgroundColor: "#3c3c3c",
            color: "white",
            left: "50%",
            position: "fixed",
            top: "85%",
            zIndex: 1000,
            padding: "8px",
            borderRadius: "5px",
            transform: "translateX(-50%)"
          }}
        >
          <ReactSVG src={WhiteCopyCodeLogo} wrapper="span" />
          <p
            style={{
              margin: 0
            }}
          >
            {REFERAL_CODE}
          </p>
        </div>
      </Portal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default withTranslation("inviteFriendsRewards")(InviteFriendsRewards)
