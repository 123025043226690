import React from "react"
import ReactSVG from "react-svg"
import { formatParagraph } from "../../v1/helpers/stringHelpers"

const Step = ({ status, title, description, customIcon, showCustomIcon }) => (
  <div className={`v-step ${status}`}>
    <div className="v-stepper">
      <div className={showCustomIcon ? "custom-icon" : "circle"}>
        {showCustomIcon ? <ReactSVG src={customIcon} /> : title}
      </div>
      <div className="line"></div>
    </div>

    <div className="v-content">{formatParagraph(description)}</div>
  </div>
)

const VerticalStepper = ({ steps }) => (
  <div className="v-container">
    {steps.map((step, index) => (
      <Step
        key={index}
        status={step.status}
        title={step.title}
        description={step.description}
        customIcon={step.customIcon}
        showCustomIcon={step.showCustomIcon}
      />
    ))}
  </div>
)

export default VerticalStepper
