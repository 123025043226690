import React, { Component } from "react"
import PropTypes from "prop-types"
import { WALKIN } from "../constants/order"
import { format } from "date-fns"
import { DATE_TIME_FORMAT } from "../constants/application"

class Item extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick(evt) {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.value)
    }
  }

  areDatesEqual(dateString1, dateString2) {
    if (dateString1 && dateString2) {
      const date1 = new Date(dateString1)
      const date2 = new Date(dateString2)
      const formattedDate1 = format(date1, DATE_TIME_FORMAT.yyyy_MM_dd)
      const formattedDate2 = format(date2, DATE_TIME_FORMAT.yyyy_MM_dd)

      return formattedDate1 === formattedDate2
    }
  }

  render() {
    const { cart, selectorFlag } = this.props
    const isSameDate = this.areDatesEqual(this.props.value, cart && cart.appointment_date_pretty)
    const isSelect = cart && cart.appointment_time_pretty == this.props.label
    const isSelected = isSelect && isSameDate && selectorFlag
    if (this.props.selected) {
      this.props.dateTimeSelected && this.props.dateTimeSelected(this.props.value)
      return <div className="item-selector-item selected">{this.props.label}</div>
    } else {
      return (
        <div
          className={`item-selector-item ${isSelected && isSelected ? "selected" : ""}`}
          onClick={this.onClick}
        >
          {this.props.label}
        </div>
      )
    }
  }
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
}

class ItemSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_value: null,
      selectedItem: false,
      selectorFlag: true
    }
    this.onSelect = this.onSelect.bind(this)
    this.valueField = this.props.valueField || "value"
    this.labelField = this.props.labelField || "label"
  }

  async onSelect(value) {
    await this.setState({ selected_value: value })
    await this.setState({ selectorFlag: false })
    if (this.props.onSelect) {
      this.props.onSelect(value)
    }
  }
  async componentDidMount() {
    const { items, value } = this.props
    if (items) {
      if (items.length === 1 && items[0].formatted_time === WALKIN) {
        this.setState({ selectedItem: true })
      }
    }
  }
  render() {
    const { items, value, dateTimeSelected, cart } = this.props
    const { selectorFlag } = this.state
    if (items === undefined || items === null || items.length == 0) {
      return <div className="error-label">{this.props.emptyMessage}</div>
    }
    return (
      <div className="item-selector">
        {items.map((item) => {
          const selected = item[this.valueField] === value || this.state.selectedItem
          return (
            <Item
              key={item[this.labelField]}
              onSelect={this.onSelect}
              label={item[this.labelField]}
              value={item[this.valueField]}
              selected={selected}
              dateTimeSelected={dateTimeSelected}
              cart={cart}
              selectorFlag={selectorFlag}
            />
          )
        })}
      </div>
    )
  }
}

ItemSelector.propTypes = {
  items: PropTypes.array,
  value: PropTypes.object,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  emptyMessage: PropTypes.string,
  onSelect: PropTypes.func
}

export default ItemSelector
