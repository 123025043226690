import React, { useRef, useState } from "react"

import { connect } from "react-redux"

import Geosuggest from "react-geosuggest"
import { withTranslation } from "react-i18next"

import ArrowRight from "../../../images/shops/arrow-right.svg"

import EmptyShopLogo from "../../../images/shops/empty_shop_logo.svg"
import ArrowLeft from "../../../images/shops/arrow-left.svg"
import MyLocationImg from "../../../../../images/map/location.png"
import SponsoredShopLogo from "../../../../../images/dashboard/check-icon.svg"

import MapGL, { Marker, Popup } from "react-map-gl"
import { Divider, Icon, Image } from "semantic-ui-react"
import ShopItemComponent from "./ShopItemComponent"
import ShopMapItem from "../../Map/components/ShopMapItem"
import ShopMapFilterMobile from "../../Map/components/ShopMapFilterMobile"
import ShopSortingComponentMobile from "../../Map/components/ShopSortingComponentMobile"

import SortingFilterMobileView from "../../Map/components/SortingFilterMobileView"
import FilterViewMobileView from "../../Map/components/FilterViewMobileView"
import { useTranslation } from "react-i18next"
import DisplayMessage from "../../../../components/ShopSearch/DisplayMessage"
import MapControlContainer from "../../Map/components/MapControlContainer"
import { ProgressHUDMap } from "../../components/ProgressHUD"
import { suggestGeoSuggestClick } from "../../../helpers/geoLocationHelper"
import FeaturedLabel from "./FeaturedLabel"

const ICON_WIDTH = 60
const ICON_HEIGHT = 60
const SCROLL_OFFSET = 180

const navStyle = {
  position: "absolute",
  top: "20%",
  right: "3%",
  padding: "10px"
}

const MapWidgetMobile = (props) => {
  const { t } = useTranslation("dashboardMapComponent")
  const width = "100%"
  const [shopSelected, setShopSelected] = useState({})
  const [viewPort, setViewport] = useState({
    latitude: props.viewPort.latitude,
    longitude: props.viewPort.longitude,
    width: "100vw",
    height: "105vh",
    zoom: 9
  })
  const updateViewport = (viewPort) => {
    setViewport(viewPort)
  }

  const [shop, setShop] = useState({})

  const recenterMap = (lat, lng) => {
    if (lat) {
      const view = {
        latitude: lat,
        longitude: lng,
        width: "100vw",
        height: "105vh",
        zoom: 9
      }
      setViewport(view)
    } else {
      setViewport(initialViewport)
    }
  }

  const selectShop = (shop) => {
    setShopSelected(shop)
  }
  const {
    shops,

    selectedShop,
    setSortingType,
    sortingType,
    ratingFiltersApplied,
    typeFiltersApplied,
    addorRemoveFilterItem,
    clearAllFilters,

    setIsSortOpen,
    setIsFilterOpen,
    isSortOpen,

    isFilterOpen,
    user,

    onSuggestSelect,

    setShowMapExpanded,
    showMapExpanded,
    onSelectShopClick,
    vehicleTypeId,
    handleApplyFilterClick,
    position,
    setPosition,
    searchLocation = undefined
  } = props

  const height = Math.max(window.innerHeight * 0.5, 300)
  const scrollRef = useRef(null)

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset
  }

  const [newView, setNewView] = React.useState()
  React.useEffect(() => {
    setNewView({
      ...props.viewPort,
      height: "105vh",
      width: "100vw"
    })
  }, [])

  const [initialViewport, setInitialViewport] = useState({
    latitude: props.viewPort.latitude,
    longitude: props.viewPort.longitude,
    height: "105vh",
    width: "100vw",
    zoom: 9
  })

  React.useEffect(() => {
    if (shops && shops.length > 0) {
      setInitialViewport({
        latitude: shops[0].latitude,
        longitude: shops[0].longitude,
        height: "105vh",
        width: "100vw",
        zoom: 9
      })
      setViewport({
        latitude: shops[0].latitude,
        longitude: shops[0].longitude,
        height: "105vh",
        width: "100vw",
        zoom: 9
      })
    } else if (searchLocation) {
      setInitialViewport({
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude,
        height: "105vh",
        width: "100vw",
        zoom: 9
      })
      setViewport({
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude,
        height: "105vh",
        width: "100vw",
        zoom: 9
      })
    }
  }, [shops])

  const [shopHovered, setShopHovered] = React.useState(undefined)

  const onMarkerHover = (shop) => {
    if (isFilterOpen) {
      setIsFilterOpen(false)
    }
    if (isSortOpen) {
      setIsSortOpen(false)
    }
    if (shopHovered && shop && shopHovered.id == shop.id) {
      setShopHovered(undefined)
    } else {
      setShopHovered(shop)
    }
  }
  const onMarkerHoverRemove = () => {
    setShopSelected()
  }

  const onClosePopup = () => {
    setShopSelected({})
  }

  return (
    <div className="mapMobileFullScreen">
      <MapGL
        {...viewPort}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={updateViewport}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        attributionControl={false}
      >
        <React.Fragment>
          {searchLocation && searchLocation.latitude && searchLocation.longitude && (
            <Marker latitude={searchLocation.latitude} longitude={searchLocation.longitude}>
              <Image
                style={{ maxWidth: "30px" }}
                src={MyLocationImg}
                className="current-position-image"
              />
            </Marker>
          )}
          {shops &&
            shops.length > 0 &&
            shops.map((shop, index) => {
              const { latitude, longitude } = shop

              const isSelectedShop = shopHovered && shop.id === shopHovered.id
              const selectedShopMarkerBG = shop.shop_featured
                ? "sponsored-shop-selected-mapMarkerBG"
                : "mapMarkerSelectedBG"
              return (
                <React.Fragment key={`marker-${index}-fragment`}>
                  <Marker
                    key={`marker-${index}`}
                    latitude={latitude}
                    longitude={longitude}
                    offsetLeft={-(ICON_WIDTH / 2)}
                    offsetTop={-ICON_HEIGHT}
                    className={`${isSelectedShop ? "onTop" : ""}`}
                  >
                    <div className="map-marker-bg-image" onClick={() => onMarkerHover(shop)}>
                      <Image
                        onClick={() => {
                          if (shop) {
                            setShopHovered(undefined)
                          } else {
                            onMarkerHover(shop)
                          }
                        }}
                        src={
                          shop.shop_featured
                            ? SponsoredShopLogo
                            : shop.logo_url
                            ? shop.logo_url
                            : EmptyShopLogo
                        }
                        className={` 
                          marker-shop-logo markerLogoStyling
                          ${isSelectedShop ? selectedShopMarkerBG : "mapMarkerBG"}
                          ${shop.shop_featured ? "sponsored-shop-mapMarkerBG" : ""}
                        `}
                      />
                    </div>
                  </Marker>
                </React.Fragment>
              )
            })}
        </React.Fragment>

        {window.innerWidth < 914 ? (
          <div>
            <div className="headerTitleBar">
              <div
                onClick={() => {
                  setShowMapExpanded(!showMapExpanded)
                  setShopHovered(undefined)
                  let newWidth = 0
                  if (window.innerWidth > 425 && window.innerWidth < 768) {
                    newWidth = window.innerWidth - 120
                  }
                  if (window.innerWidth >= 768 && window.innerWidth < 810) {
                    newWidth = window.innerWidth - 180
                  }
                  if (window.innerWidth >= 820 && window.innerWidth < 1000) {
                    newWidth = window.innerWidth - 230
                  }
                  if (window.innerWidth > 1000 && window.innerWidth < 1300) {
                    newWidth = window.innerWidth / 2 - 40
                  }
                  if (window.innerWidth > 1300 && window.innerWidth < 1400) {
                    newWidth = window.innerWidth / 2 - 80
                  }
                  if (window.innerWidth > 1400) {
                    newWidth = window.innerWidth / 2 - 300
                  }
                  if (window.innerWidth <= 425) {
                    newWidth = window.innerWidth - 60
                  }
                  updateViewport({
                    ...viewPort,
                    height:
                      window.innerWidth > 768
                        ? window.innerHeight / 2 + 200
                        : window.innerHeight / 2 + 200,
                    width: newWidth
                  })
                }}
              >
                <Icon name="arrow left black" size="20px" />
              </div>
              <div className="mapScreenTitle">{t("searchInMap")}</div>
              <div
                style={{ marginRight: "18px", fontSize: "16px", color: "red" }}
                onClick={() => {
                  setShopHovered(undefined)
                  setShowMapExpanded(!showMapExpanded)
                  let newWidth = 0
                  if (window.innerWidth > 425 && window.innerWidth < 768) {
                    newWidth = window.innerWidth - 120
                  }
                  if (window.innerWidth >= 768 && window.innerWidth < 810) {
                    newWidth = window.innerWidth - 180
                  }
                  if (window.innerWidth >= 820 && window.innerWidth < 1000) {
                    newWidth = window.innerWidth - 230
                  }
                  if (window.innerWidth > 1000 && window.innerWidth < 1300) {
                    newWidth = window.innerWidth / 2 - 40
                  }
                  if (window.innerWidth > 1300 && window.innerWidth < 1400) {
                    newWidth = window.innerWidth / 2 - 80
                  }
                  if (window.innerWidth > 1400) {
                    newWidth = window.innerWidth / 2 - 300
                  }
                  if (window.innerWidth <= 425) {
                    newWidth = window.innerWidth - 60
                  }
                  updateViewport({
                    ...viewPort,
                    height:
                      window.innerWidth > 768
                        ? window.innerHeight / 2 + 200
                        : window.innerHeight / 2 + 200,
                    width: newWidth
                  })
                }}
              >
                Cancel
              </div>
            </div>
            <div className="displayMessageMobileMapViewContainer">
              <Geosuggest
                onSuggestSelect={(suggest) => suggestGeoSuggestClick(suggest, onSuggestSelect)}
                placeholder="Search on Map"
                types={["geocode"]}
                country={user.country}
                ignoreTab={true}
                className="geosuggestContainerExpandedMobile"
                suggestsClassName="search-suggests-list"
                suggestItemClassName="search-suggest-item"
                inputClassName={`searchFldShop smaller-width`}
                suggestItemActiveClassName="search-suggest-item-active"
                suggestsHiddenClassName="search-suggests-list-hidden"
                fixtures={[{ label: t("use-current-location"), custom: true }]}
              />
              <DisplayMessage
                message={props.t("common:mdHdShopsLabel")}
                vehicleTypeId={vehicleTypeId}
              />
            </div>
          </div>
        ) : null}

        <div className="nav" style={navStyle}>
          <MapControlContainer
            recenterClick={() => recenterMap(shops[0].latitude, shops[0].longitude)}
            expandCompressClick={() => {
              setShowMapExpanded(!showMapExpanded)
              setShopHovered(undefined)
              let newWidth = 0
              if (window.innerWidth > 425 && window.innerWidth < 768) {
                newWidth = window.innerWidth - 120
              }
              if (window.innerWidth >= 768 && window.innerWidth < 810) {
                newWidth = window.innerWidth - 180
              }
              if (window.innerWidth >= 820 && window.innerWidth < 1000) {
                newWidth = window.innerWidth - 230
              }
              if (window.innerWidth > 1000 && window.innerWidth < 1300) {
                newWidth = window.innerWidth / 2 - 40
              }
              if (window.innerWidth > 1300 && window.innerWidth < 1400) {
                newWidth = window.innerWidth / 2 - 80
              }
              if (window.innerWidth > 1400) {
                newWidth = window.innerWidth / 2 - 300
              }
              if (window.innerWidth <= 425) {
                newWidth = window.innerWidth - 60
              }
              updateViewport({
                ...viewPort,
                height:
                  window.innerWidth > 768
                    ? window.innerHeight / 2 + 200
                    : window.innerHeight / 2 + 200,
                width: newWidth
              })
            }}
            isExpanded={showMapExpanded}
            currentPositionClick={(pos) => setPosition(pos)}
          />
        </div>
        {props.loading && (
          <div
            style={{
              position: "relative",
              display: "flex",
              flex: 1,
              height: "100%",
              margin: "0rem auto",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div style={{ position: "absolute", top: "40%" }}>
              {props.loading && <ProgressHUDMap />}
            </div>
          </div>
        )}
        {showMapExpanded && window.innerWidth < 913 && (
          <div className="mapMobileComponents">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ShopSortingComponentMobile
                setSortingType={(value) => setSortingType(value)}
                sortingType={sortingType}
                setIsSortOpen={() => {
                  setIsSortOpen(!isSortOpen)
                  setShopHovered(undefined)
                }}
                isSortOpen={isSortOpen}
              />
              <ShopMapFilterMobile
                ratingFiltersApplied={ratingFiltersApplied}
                typeFiltersApplied={typeFiltersApplied}
                addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
                clearAllFilters={clearAllFilters}
                setIsFilterOpen={() => {
                  setShopHovered(undefined)
                  setIsFilterOpen(!isFilterOpen)
                }}
                isFilterOpen={isFilterOpen}
              />
            </div>
          </div>
        )}
        {shops && shops.length > 0 && !showMapExpanded && (
          <div className="shopListHorizontalContainer">
            <div className="iconContainer" onClick={() => scroll(-SCROLL_OFFSET)}>
              <Image src={ArrowLeft} height="20px" width="20px" />
            </div>
            <div className="shopListContainer" ref={scrollRef}>
              {shops.map((shop, index) => (
                <ShopMapItem
                  isSelected={selectedShop && shop.id == selectedShop.id}
                  key={index}
                  shop={shop}
                  setShopSelected={setShopSelected}
                  onSelectShopClick={onSelectShopClick}
                />
              ))}
            </div>
            <div className="iconContainer" onClick={() => scroll(SCROLL_OFFSET)}>
              <Image src={ArrowRight} height="20px" width="20px" />
            </div>
          </div>
        )}

        {isSortOpen && (
          <SortingFilterMobileView
            sortingType={sortingType}
            setIsSortOpen={setIsSortOpen}
            setSortingType={setSortingType}
          />
        )}
        {isFilterOpen && (
          <FilterViewMobileView
            setIsFilterOpen={setIsFilterOpen}
            clearAllFilters={clearAllFilters}
            ratingFiltersApplied={ratingFiltersApplied}
            typeFiltersApplied={typeFiltersApplied}
            addorRemoveFilterItem={addorRemoveFilterItem}
            handleApplyFilterClick={handleApplyFilterClick}
          />
        )}

        {shopHovered && Object.keys(shopHovered).length !== 0 && (
          <div className="shopMapItemMobileContainer">
            <FeaturedLabel shop={shopHovered} showCheckIcon={false} showTooltip={true} />
            <ShopItemComponent
              shop={shopHovered}
              isPopUpComponent={true}
              onSelectShopClick={() => {
                onSelectShopClick(shopHovered)
                setShowMapExpanded(false)
              }}
              isMobileViewExpanded={true}
              setShopSelected={setShopHovered}
            />
          </div>
        )}
      </MapGL>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(MapWidgetMobile))
