import React, { Component } from "react"
import AddToCalendarHOC from "react-add-to-calendar-hoc"
import moment from "moment"
import PropTypes from "prop-types"
import Button from "../Button"
import { withTranslation } from "react-i18next"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"

const dropdownStyles = {
  padding: "10px",
  borderTop: "none",
  backgroundColor: "#FFF",
  margin: "0 auto"
}

const Dropdown = ({ children }) => {
  return <div style={dropdownStyles}>{children}</div>
}

const AddToCalendar = AddToCalendarHOC(Button, Dropdown)

class CalendarDropdown extends Component {
  constructor(props) {
    super(props)

    this.buildEvent = this.buildEvent.bind(this)
  }

  static propTypes = {
    order: PropTypes.object.isRequired,
    buttonProps: PropTypes.object,
    linkProps: PropTypes.object
  }

  static defaultProps = {
    buttonProps: {
      colorStyle: "orange",
      style: { marginTop: "10px", marginLeft: "0", height: "100%", width: "150px" },
      styleText: { fontSize: "10pt" }
    },
    linkProps: {
      style: {
        textDecoration: "none",
        display: "block",
        textAlign: "center",
        padding: "6px"
      }
    }
  }

  buildEvent() {
    const { order, t } = this.props
    const shop = order.shop
    const start = moment(order.appointment_datetime)
    const end = start.clone().add(1, "hour")
    const duration = end.diff(start, "hours")
    return {
      title: t("serviceAtText", { shopName: shop.name }),
      startDatetime: start.format("YYYYMMDDTHHmmss"),
      endDatetime: end.format("YYYYMMDDTHHmmss"),
      timezone: shop.time_zone,
      duration: `${duration}`,
      description: `${shop.name} ${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`,
      location: `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`
    }
  }

  render() {
    const { order, t, user } = this.props
    const defaultButtonProps = {
      text: (
        <div
          onClick={() => {
            trackEvent("add-to-calendar-clicked", {
              ...(user.active_orders &&
                user.active_orders.length > 0 &&
                order &&
                order.id > 0 && { orderId: parseInt(order.id) })
            })
          }}
        >
          {t("addToCalendarText")}
        </div>
      ),
      colorStyle: "orange",
      style: { marginTop: "10px", marginLeft: "0", height: "100%", width: "150px" },
      styleText: { fontSize: "10pt" }
    }
    if (order.shop && order.appointment_datetime)
      return (
        <AddToCalendar
          event={this.buildEvent()}
          buttonProps={{ ...defaultButtonProps, ...this.props.buttonProps }}
          linkProps={this.props.linkProps}
        />
      )
    else return null
  }
}

export default withTranslation("calendarDropdown")(CalendarDropdown)
