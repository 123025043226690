import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Button, Input } from "semantic-ui-react"
import zipcodes from "zipcodes"
import { updateInfo } from "../../v1/actions/user"
import {
  COUNTRY_CODE,
  EMAIL_REGEX,
  POSTAL_CODE_REGEX,
  ZIP_CODE_REGEX
} from "../../shared_component/utils/stringHelpers"
import _ from "lodash"
import store from "../../shared_component/utils/configureStore"
import Notifications from "../../v1/components/Notifications"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { getSubdomain } from "../../v1/components/Config/StylesheetInjector"
class ContactInformation extends Component {
  constructor(props) {
    super(props)

    const { firstName, lastName, cellPhone, email, zip, language } = props
    this.state = {
      isSubmitting: false,
      fields: {
        firstName: {
          name: "First Name",
          value: firstName,
          invalid: false,
          validators: ["_isPresent"]
        },
        lastName: {
          name: "Last Name",
          value: lastName,
          invalid: false,
          validators: ["_isPresent"]
        },
        cellPhone: {
          name: "Mobile Phone",
          value: cellPhone,
          invalid: false,
          validators: ["_isPresent", "_isValidCellPhone"]
        },
        email: {
          name: "Email",
          value: email,
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        language: { name: "Language", value: language, invalid: false, validators: ["_isPresent"] },
        newPassword: {
          name: "New Password",
          value: null,
          invalid: false,
          validators: ["_isPresent"]
        }
      }
    }
  }

  _onFieldChange = (key, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _onNumberChange = (key, value) => {
    const numericRegex = /^\d*$/

    const isOnlyNumeric = numericRegex.test(value)

    if (isOnlyNumeric) {
      this.setState({
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      })
    }
  }

  _setAndValidateField = (key, value) => {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _isPresent = (value) => {
    return !!value
  }

  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  _isZipCodeValid(value) {
    if (_.includes(getSubdomain(), "-ca")) {
      return POSTAL_CODE_REGEX.test(value)
    } else return ZIP_CODE_REGEX.test(value)
  }

  _validateFields = (callback) => {
    if (!this._validateEmail() || !this._validateCellPhone()) return
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey)
        this.showError(`${t("settings:errorMessage")} ${t(`settings:${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  _validateEmail = () => {
    const { fields } = this.state
    const email = fields.email.value
    const { t } = this.props

    if (!this._isEmailValid(email)) {
      this.showError(t("settings:invalidEmailMessage"))
      return false
    }
    return true
  }

  _validateZipCode = () => {
    const { fields } = this.state
    const zipCode = fields.zip.value
    const { t } = this.props

    if (!this._isZipCodeValid(zipCode)) {
      this.showError(t("settings:invalidZipCodeMessage"))
      return false
    }
    return true
  }

  _validatePasswordsMatch = () => {
    const { fields } = this.state
    const newPassword = fields.newPassword.value
    const confirmNewPassword = fields.confirmNewPassword.value
    const { t } = this.props

    if (newPassword !== confirmNewPassword) {
      this.showError(t("settings:passwordNotMatchedMessage"))
      return false
    }

    return true
  }

  _isPasswordMatched = (value) => {
    return value === this.state.fields.newPassword.value
  }

  _isValidCellPhone = (value) => {
    return value && value.length == 10 ? true : false
  }

  _validateCellPhone = () => {
    const { fields } = this.state
    const { t } = this.props
    const cellPhone = fields.cellPhone.value
    if (!this._isValidCellPhone(cellPhone)) {
      this.showError(t("settings:invalidCellPhoneMessage"))
      return false
    }
    return true
  }

  _isValidZip = (value) => {
    if (value.length !== 5) {
      return false
    } else {
      const result = zipcodes.lookup(value)
      return result !== undefined
    }
  }

  saveDetails = () => {
    const { t } = this.props
    const { fields } = this.state
    const { firstName, lastName, email, cellPhone, zip, newPassword, language } = fields
    this._validateFields(async () => {
      const response = await this.props.updateInfo({
        firstName: firstName.value,
        lastName: lastName.value,
        cellPhone: cellPhone.value,
        email: email.value,
        language: language.value,
        password: newPassword.value,
        displayErrorNotification: true
      })
      if (response === true) {
        await identifyEvent({
          traits: {
            phone: COUNTRY_CODE.concat(fields.cellPhone.value),
            firstNameWithSpace: fields.firstName.value,
            lastNameWithSpace: fields.lastName.value,
            email: fields.email.value
          }
        })
        trackEvent("profile-updated", {
          firstNameWithSpace: fields.firstName.value,
          lastNameWithSpace: fields.lastName.value,
          phone: COUNTRY_CODE.concat(fields.cellPhone.value),
          email: fields.email.value
        })
        this.showAlert(t("settings:infoUpdatedAlertMessage"))
      }
    })
  }

  render() {
    const { t } = this.props
    const { isSubmitting, fields } = this.state
    const {
      firstName,
      lastName,
      email,
      cellPhone,
      zip,
      newPassword,
      confirmNewPassword,
      language
    } = fields
    return (
      <div className="contact-information">
        <h2 className="heading">{t("Contact_Information")}</h2>
        <div className="information-inputs">
          <div className="input-parent">
            <label>{t("First_Name")}</label>
            <Input
              placeholder={t("Write_your_name")}
              type="text"
              value={firstName.value}
              onChange={(value) => this._onFieldChange("firstName", value.target.value)}
            />
          </div>
          <div className="input-parent">
            <label>{t("Last_Name")}</label>
            <Input
              placeholder={t("Write_your_last_name")}
              type="text"
              value={lastName.value}
              onChange={(value) => this._onFieldChange("lastName", value.target.value)}
            />
          </div>
          <div className="input-parent">
            <label>{t("Mobile_Number")}</label>
            <Input
              placeholder="Ex. (304) 123-1234"
              value={cellPhone.value}
              onChange={(value) =>
                this._onNumberChange("cellPhone", value.target.value.replace(/[^0-9]/g, ""))
              }
            />
          </div>
          <div className="input-parent">
            <label>{t("Email")}</label>
            <Input
              placeholder="johndoe@gmail.com"
              type="text"
              value={email.value}
              onChange={(value) => this._onFieldChange("email", value.target.value)}
            />
          </div>
          <div className="input-parent">
            <label>{t("Password")}</label>
            <Input
              placeholder={t("Set_password")}
              type="password"
              autoComplete="new-password"
              onChange={(value) => this._onFieldChange("newPassword", value.target.value)}
            />
          </div>
          <div className="input-parent btn-padding">
            <Button
              className={`create-account-btn ${
                !(
                  firstName.value &&
                  lastName.value &&
                  cellPhone.value &&
                  email.value &&
                  newPassword.value
                )
                  ? "disabled"
                  : "success"
              }`}
              onClick={() => this.saveDetails()}
              disabled={
                !(
                  firstName.value &&
                  lastName.value &&
                  cellPhone.value &&
                  email.value &&
                  newPassword.value
                )
              }
            >
              {t("Create_an_account")}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let vehicle = undefined
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  vehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    loading: user.loading,
    isLoggedOut: !user.authentication_token,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    cellPhone: user.cellPhone || "",
    error: user.error,
    vehicleNumber: vehicle.vehicleNumber,
    zip: user.zip || "",
    language: user.language || "en",
    email: user.generic ? "" : user.email
  }
}

export default connect(mapStateToProps, { updateInfo })(
  withTranslation("booking")(ContactInformation)
)
