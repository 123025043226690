import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import DefaultShop from "../../images/DefaultShop.png"
import { Link, Redirect } from "react-router-dom"
import { Card, CardContent, CardHeader, Image, Input, Label } from "semantic-ui-react"
import {
  getShopsForTire,
  getTireDetails,
  saveSelectedShop,
  saveTireDetails
} from "../actions/tires"
import { withTranslation } from "react-i18next"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { connect } from "react-redux"
import LoadingShimmer from "./LoadingShimmer"
import RatingIcon from "../../images/Rating_Icon.png"
import { toCurrencyString } from "../../shared_component/utils/currency"
import ZipCodeIcon from "../../images/Zip-code-icon.png"
import { isCanada } from "../helpers/translationHelpers"
import CorrectZip from "../../images/Correct_zip.png"
import IncorrectZip from "../../images/Incorrect_zip.png"
import { routerPaths } from "../constants/paths"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { ENTER_KEY, GUEST_USER_DEFAULT_ZIPCODE, GUEST_ZIPCODE } from "../constants/tires"

function ConfirmInstallation({
  t,
  user,
  savedTires,
  getShopsForTire,
  saveSelectedShop,
  selectedShop
}) {
  const guestZipcode = localStorage.getItem(GUEST_ZIPCODE)

  const [partnerFlag, setPartnerFlag] = useState()
  const [shopList, setShopList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedShopId, setSelectedShopId] = useState()
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [errorZip, setErrorZip] = useState(false)
  const [zipCode, setZipCode] = useState(() => {
    return guestZipcode !== "undefined" && guestZipcode !== null
      ? guestZipcode
      : user && user.generic
      ? GUEST_USER_DEFAULT_ZIPCODE
      : user.zip
  })

  const [showImg, setShowImg] = useState(
    localStorage.getItem(GUEST_ZIPCODE) !== "undefined" &&
      localStorage.getItem(GUEST_ZIPCODE) !== null
  )

  const totalQty = savedTires.reduce((prev, next) => prev + next.quantity, 0)
  const total = 0 + savedTires.reduce((total, item) => total + item.quantity * item.price, 0)

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  useEffect(() => {
    getListOfShops()
  }, [])

  const getListOfShops = async (isChangeBtnClicked) => {
    setIsLoading(true)
    let APIPayload = {
      supplier: savedTires.length > 0 && savedTires[0].tireDetails.supplier
    }

    if (zipCode) {
      APIPayload = { ...APIPayload, zip: zipCode }
    }
    const response = await getShopsForTire(APIPayload)
    if (response && response.error) {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      if (
        response &&
        response.result &&
        response.result.shops &&
        response.result.shops.length > 0
      ) {
        setShopList(response.result.shops)
        setErrorZip(false)

        const isFound = response.result.shops.some((shop) => {
          if (shop.id === selectedShop.id) {
            return true
          }
          return false
        })

        setSelectedShopId(
          selectedShop && isFound && selectedShop.id ? selectedShop.id : response.result.shops[0].id
        )
        saveSelectedShop(
          selectedShop && isFound && selectedShop.id ? selectedShop : response.result.shops[0]
        )
        setIsBtnDisabled(false)
      } else {
        setErrorZip(true)
        setShopList([])
      }
    }
    setIsLoading(response && response.result ? false : true)
  }

  const handleShopChange = (shop) => {
    setSelectedShopId(shop.id)
    saveSelectedShop(shop)
  }

  const handleZipCode = (e) => {
    setShowImg(false)
    setZipCode(e.target.value)
    if (e.key === ENTER_KEY) {
      getListOfShopsOnZipChange()
    }
  }

  const validateZipCode = () => {
    if (zipCode === 0 || zipCode === "" || zipCode.length != 5) {
      return false
    } else if (!isCanada(user.country) && zipCode.match(/^[0-9]+$/) == null) {
      setErrorZip(true)
      return false
    } else {
      return true
    }
  }

  const getListOfShopsOnZipChange = () => {
    const isValidZip = validateZipCode()
    if (isValidZip) {
      localStorage.setItem(GUEST_ZIPCODE, zipCode)
      setShowImg(true)
      getListOfShops(true)
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("InvalidZipCode"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <div className="confirm-installation-sec">
          <div className="confirm-installation">
            <div className="confirm-heading">
              <Link to={routerPaths.confirmTire}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h3>{t("Back")}</h3>
            </div>
            <div className="tires">
              <div className="card-parent">
                <div className="tires-card-parent">
                  <Card className="tire-card confirm-card">
                    <CardHeader>
                      <div className="tire-name-parent">
                        <div className="step">{t("STEP_2")}</div>
                        <div className="confirm-title">{t("Confirm_installation")}</div>
                      </div>
                    </CardHeader>
                    <hr />
                    <div className="set-tires-parent">
                      <div className="set-tire">
                        {totalQty} {totalQty > 1 ? t("tires") : t("tire")}
                      </div>
                      <div className="value">
                        $
                        {savedTires
                          .reduce((total, item) => total + item.quantity * item.price, 0)
                          .toFixed(2)}
                      </div>
                    </div>
                    <div className="subtotal-parent">
                      <div className="subtotal">{t("Installation")}</div>
                      <div className="value">
                        {toCurrencyString(
                          shopList && shopList.length > 0
                            ? shopList[0].tire_installation_price * totalQty
                            : 0
                        )}
                      </div>
                    </div>
                    <div className="total-parent">
                      <div className="total">{t("TOTAL")}</div>
                      <div className="total-value">
                        {toCurrencyString(
                          shopList && shopList.length > 0
                            ? total + shopList[0].tire_installation_price * totalQty
                            : 0
                        )}
                      </div>
                    </div>
                    <div className="btn-parent">
                      <Link
                        to={isBtnDisabled ? "#" : routerPaths.confirmInstallationDate}
                        className={`next-btn ${isBtnDisabled ? "disable-btn" : "active-btn"}`}
                      >
                        {t("Next")}: {t("Select_dates")}
                      </Link>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="shop-section">
                <div className="zip-parent">
                  <div className="zip-content">
                    <div className="zip-header">
                      <Image src={ZipCodeIcon} />
                      {t("Use_zip_code")}
                    </div>
                    <div className="zip-container">
                      <Input
                        maxLength={isCanada(user.country) ? 7 : 5}
                        type="text"
                        icon={{ name: "map marker alternate" }}
                        iconPosition="left"
                        label={{
                          basic: true,
                          content: t("Change"),
                          onClick: getListOfShopsOnZipChange
                        }}
                        labelPosition="right"
                        onChange={handleZipCode}
                        defaultValue={zipCode && zipCode}
                        onKeyUp={handleZipCode}
                        className="input-zip"
                      />
                      {showImg ? (
                        <Image
                          src={errorZip ? IncorrectZip : CorrectZip}
                          className="zip-validator-img"
                        />
                      ) : null}
                    </div>
                  </div>
                  {errorZip ? <span className="error-zip">{t("Error_zip")}</span> : null}
                </div>
                <div className="shop-card-parent">
                  {!isLoading ? (
                    shopList && shopList.length > 0 ? (
                      shopList.map((shop) => {
                        return (
                          <div className="firestone-parent">
                            <Card className="firestone-card">
                              <CardHeader>
                                <div className="img-parent">
                                  <Image src={shop.logo_url ? shop.logo_url : DefaultShop} />
                                </div>
                                <div className="tire-name-parent">
                                  <div className="tire-name">{shop.shop_name}</div>
                                  <div className="tire-details">
                                    {shop.address_line1 +
                                      ", " +
                                      shop.city +
                                      ", " +
                                      shop.state +
                                      " " +
                                      shop.zip}
                                    <br />
                                  </div>
                                  {shop.average_rating && shop.average_rating !== "0.0" ? (
                                    <div className="shop-rating">
                                      <img src={RatingIcon} />
                                      {shop.average_rating}
                                    </div>
                                  ) : null}
                                </div>
                              </CardHeader>
                              <CardContent>
                                <div className="installation-fee-parent">
                                  <div className="installation">{t("Installation_fees")}</div>
                                  <div className="value-parent">
                                    <div className="value">
                                      {toCurrencyString(shop.tire_installation_price * totalQty)}
                                    </div>
                                    <div className="subvalue">
                                      {toCurrencyString(shop.tire_installation_price)}{" "}
                                      {t("per_tire")}
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                              <div className="book-parent" onClick={() => handleShopChange(shop)}>
                                <Label>
                                  <input type="radio" checked={selectedShopId === shop.id} />
                                  {t("Book_this_shop")}
                                </Label>
                              </div>
                            </Card>
                          </div>
                        )
                      })
                    ) : (
                      <div className="no-shops">
                        <span>{t("No_results")}</span>
                        <p>{t("No_installers_available")}</p>
                      </div>
                    )
                  ) : (
                    <LoadingShimmer />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
function mapStateToProps(state) {
  let user = state.user
  let savedTires = state.saveBuyTireDetails.tireArray
  let selectedShop = state.saveBuyTireDetails.selectedShop
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    user: user,
    activeVehicle: activeVehicle,
    savedTires: savedTires,
    selectedShop: selectedShop
  }
}

export default connect(mapStateToProps, {
  getTireDetails,
  saveTireDetails,
  getShopsForTire,
  saveSelectedShop
})(withTranslation("confirmInstallation")(ConfirmInstallation))
