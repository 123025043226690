import { routerPaths } from "./paths"

export const FACTORY_TIRE_SIZE = "FactoryTireSize"
export const CUSTOM_TIRE_SIZE = "CustomTireSize"
export const CHARACTERISTICS = "Characteristics"
export const FEATURES = "Features"
export const BENEFITS = "Benefits"
export const DATE_FORMAT = "ll"
export const ZERO_VALUE = "0.0"
export const VISA = "Visa"
export const MASTERCARD = "Mastercard"
export const TIRES = "Tires"
export const WALK_IN = "Walk in"
export const BRAINTREE = "braintree"
export const AFFIRM = "affirm"
export const PAYPAL = "paypal"
export const ADD_NEW_BRAINTREE_CARD_URL = "payment_methods/credit_cards"
export const TIRE_CONNECT = "TireConnect"
export const PAYPAL_ACCOUNT = "PayPalAccount"
export const FR_LANG = "fr_FR"
export const FR = "fr"
export const EN = "en"
export const USD = "USD"
export const TIRE_DELIVERY_FEE = "Tire Delivery Fee"
export const TIRE_CONNECT_NEW_TIRES = "CarAdvise New Tire(s)"
export const TIRE_CONNECT_INSTALLATION = "Tire Installation"
export const BASIC = "Basic"
export const PREMIUM = "Premium"
export const HOUR = "hour"
export const HOURS = "hours"
export const TIRE_SERVICES = [TIRE_DELIVERY_FEE, TIRE_CONNECT_INSTALLATION, TIRE_CONNECT_NEW_TIRES]
export const CONTACT_NUMBER = "(844) 923-8473"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const MOBILE_PHONE_REGEX = /^\d{10}$/
export const ZIPCODE_REGEX_US = /^\d{5}$/
export const ZIPCODE_REGEX_CANADA = /^\d{7}$/
export const IS_ALPHANUMERIC = /^\d*$/

export const VIN = "VIN"
export const PLATE = "PLATE"
export const GUEST_ZIPCODE = "guestZipcode"
export const SUCCESS = "success"
export const ENTER_KEY = "Enter"
export const CA_STATE = "-ca"

export const GUEST_USER_DEFAULT_ZIPCODE = 60612

export const INSTALLATION_POPUP = [
  {
    id: "a",
    label: "Tire Install / Swap Tires"
  },
  {
    id: "b",
    label: "Wheel Balance"
  },
  {
    id: "c",
    label: "Tire Servicing"
  },
  {
    id: "d",
    label: "Tire Mounting"
  },
  {
    id: "e",
    label: "TPMS Service Valve Kit"
  },
  {
    id: "f",
    label: "Valve Replacement"
  }
]

export const CART_HIDE_PATHS = [
  routerPaths.tireSize,
  routerPaths.topTires,
  routerPaths.confirmTire,
  routerPaths.confirmInstallation,
  routerPaths.confirmInstallationDate,
  routerPaths.booking,
  routerPaths.bookingConfirmation,
  routerPaths.tireDetails
]

export const DEFAULT_TIRE_QUENTY = 4
export const THOUSANDS_CODE = "k"
export const MILLIONS_CODE = "M"

export const TIRE_DEPTH_STATUS = {
  good: "Good",
  replaceSoon: "Replace soon",
  replaceNow: "Replace now"
}

export const REPLACE_NOW_DEPTH = 2
export const REPLACE_SOON_MIN_DEPTH = 3
export const REPLACE_SOON_MAX_DEPTH = 5

export const DEFULT_TIRE_DEPTH = '0/0"'
export const DEFULT_TIRE_INCH = '32"'
export const DASHBOARD = "DASHBOARD"

export const GOOD_DEPTH = "6/32’’ or deeper"
export const WARNING_DEPTH = "3/32’’"
export const DANGER_DEPTH = "2/32’’ or less"

export const TIRE_FILTER_CHECKBOX = "checkbox"
export const TIRE_FILTER_RANGE = "range"
export const TIRE_FILTER_SORTING = "sorting"
export const TIRE_FILTER_PRICE = "price"
export const START_PRICE = "start_price"
export const END_PRICE = "end_price"
export const PRICE_SORT = "price_sort"

export const TIRE_SORTING_FILTER_OPTIONS = [
  { key: "Low to high", value: "asc", text: "Price: Low to high" },
  { key: "High to low", value: "desc", text: "Price: High to low" }
]

export const FILTER_NAME_FORMAT = /[-_]/
