import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Grid, Header, Segment, Divider, Responsive } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { routerPaths, navigationPaths } from "../constants/paths"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

export const DASHBOARD = "dashboard"
export const SERVICES = "services"
export const SHOP = "shop"
export const SCHEDULE = "schedule"
export const CHECKOUT = "checkout"

class BreadcrumbNav extends Component {
  navConfig = (nav) => {
    const { t, cartId } = this.props

    return (
      {
        [DASHBOARD]: { path: routerPaths.dashboard, label: t("breadcrumbNav:dashboardLbl") },
        [SERVICES]: { path: routerPaths.services, label: t("breadcrumbNav:selectServicesLbl") },
        [SHOP]: { path: routerPaths.shop, label: t("breadcrumbNav:selectShopLbl") },
        [SCHEDULE]: { path: routerPaths.schedule, label: t("breadcrumbNav:selectDateTimeLbl") },
        [CHECKOUT]: {
          path: navigationPaths.checkout(cartId),
          label: t("breadcrumbNav:checkoutLbl")
        }
      }[nav] || {}
    )
  }

  backButton = () => {
    const { path, label } = this.navConfig(this.props.back)

    return (
      <Grid.Column>
        {label && (
          <Link to={path} className="progressBack">
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "5px" }} />
            {label}
          </Link>
        )}
      </Grid.Column>
    )
  }

  forwardButton = () => {
    const { forward, forwardDisabled } = this.props
    const { path, label } = this.navConfig(forward)

    if (!path || !label) return <Grid.Column />

    return (
      <Grid.Column style={{ textAlign: "right" }}>
        {forwardDisabled && (
          <React.Fragment>
            {this.props.t("nextLbl")} <span>{label}</span>
          </React.Fragment>
        )}
        {!forwardDisabled && (
          <Link to={path} className="progressForward">
            {this.props.t("nextLbl")}{" "}
            <span
              className="underline"
              onClick={() => {
                let params = {
                  zip: this.props.user.zip,
                  services_requested: JSON.stringify(
                    this.props.addedServices.map((sd) => {
                      return { position: sd.position, service_definition_id: sd.id }
                    })
                  ),
                  vehicle_id: this.props.user.activeVehicleId
                }
                this.props.makeShopApiCall(params)
              }}
            >
              {label}
            </span>
          </Link>
        )}
      </Grid.Column>
    )
  }

  renderDesktopLayout = () => {
    return (
      <div>
        <Grid columns="equal">
          {this.backButton()}

          <Grid.Column floated="right">
            <div className="progressCurrent">{this.props.currentName}</div>
          </Grid.Column>

          {this.forwardButton()}
        </Grid>
        <Divider hidden />
      </div>
    )
  }

  renderMobileLayout = () => {
    return (
      <div>
        <Grid columns="equal">
          <Grid.Row>
            {this.backButton()}
            {this.forwardButton()}
          </Grid.Row>
        </Grid>

        <Grid columns="equal">
          <Grid.Column centered style={{ paddingTop: "0px", marginBottom: "8px" }}>
            <div className="progressCurrent">{this.props.currentName}</div>
          </Grid.Column>
        </Grid>
      </div>
    )
  }

  render() {
    return (
      // Noticed text-wrap change occurring at this point. 540 does not map
      // to normal breakpoints, but works here.
      <div className="progressContainer">
        <Responsive minWidth={540}>{this.renderDesktopLayout()}</Responsive>
        <Responsive maxWidth={539}>{this.renderMobileLayout()}</Responsive>
      </div>
    )
  }
}

export default connect((state) => {
  let pev = state.shop.pev
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let addedServices = cart ? cart.order_services : []

  return { user: state.user, pev, addedServices }
}, {})(withTranslation(["common", "breadcrumbNav"])(BreadcrumbNav))
