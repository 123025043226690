import React, { Component } from "react"
import { connect } from "react-redux"
import Loadable from "react-loadable"
import Button from "./components/Button"
import { postJSON } from "../shared_component/utils/fetch"
import { updateCart, setLoading, setNotLoading } from "./actions/user"
import { Loader } from "./components/LoadingMask"
import { withTranslation } from "react-i18next"
import { showChatWidget } from "./helpers/userHelper"
import { routerPaths } from "./constants/paths"
import { V1, setupSplitFlags } from "./components/Config/SplitClient"
import { getSubdomain } from "./components/Config/StylesheetInjector"

const QUESTIONS_URL = "/api/v4/questions/search.json"

function ProblemStatement({ problems, onClick }) {
  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <p>
        <b>Based on your information, here are some potential problems:</b>
      </p>
      <ul>
        {problems.map((p, i) => (
          <li key={i}>{p.name}</li>
        ))}
      </ul>
      <p>
        We highly recommend you get:{" "}
        {[...new Set(problems.map((r) => r.inspection.name))].join(", ")}.
      </p>
      <Button text="Add Inspection" colorStyle="orange" onClick={onClick} />
    </div>
  )
}

class Diagnosis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: [],
      problems: [],
      isLoading: false
    }

    this.getServices()
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.getServices()
      }
    }
  }

  async getServices() {
    const { t } = this.props
    this.props.setLoading(t("gettingDiagnosisDataLoadingMessage"))

    let { first_level, parent_question_id, authentication_token } = this.props
    const data = {
      first_level,
      parent_question_id
    }

    let responseData = await postJSON(QUESTIONS_URL, data, { Authorization: authentication_token })

    this.setState({
      questions: responseData.result.questions,
      problems: responseData.result.problems,
      isLoading: true
    })
    this.props.setNotLoading()
  }

  async addInspections() {
    this.props.setLoading()
    await this.props.updateCart(
      this.state.problems.map((p) => {
        return {
          service_definition_id: p.inspection.id,
          event: "added"
        }
      })
    )
    this.props.setNotLoading()
    if (this.state.isCarAdviseNavigation && this.state.isCarAdviseNavigation === V1)
      window.location = `/#${routerPaths.services}`
    else window.location = `/#${routerPaths.addServices}`
  }

  render() {
    const { t } = this.props
    const List = Loadable({
      loader: () => import("./components/List"),
      loading: Loader
    })
    return (
      <div className="dashLeftContainer">
        <div className="dashLeft" style={{ padding: "20px" }}>
          <a href="#" id="launch-chat" className="btn-needHelp" onClick={showChatWidget}>
            {t("needHelpLbl")}
          </a>
          <p className="introText">{t("problemDiagnosisLbl")}</p>

          {(this.state.problems || []).length === 0 && (
            <div className="selectMaintenanceContainer">
              <List
                items={this.state.questions.map((q) => {
                  q.linkTo = `/questions/${q.id}`
                  return q
                })}
              />
            </div>
          )}

          {(this.state.problems || []).length > 0 && (
            <ProblemStatement
              onClick={this.addInspections.bind(this)}
              problems={this.state.problems}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let user = state.user || {}
  let { first_level, parent_question_id } = ownProps.match.params
  return {
    authentication_token: user.authentication_token,
    first_level,
    parent_question_id
  }
}
export default connect(mapStateToProps, { updateCart, setLoading, setNotLoading })(
  withTranslation("diagnosis")(Diagnosis)
)
