import store from "../../shared_component/utils/configureStore"
import { getActiveVehicle } from "./vehicleHelpers"
export function preMembershipDiscountSubtotal(cart) {
  return cart && parseFloat(cart.estimated_total_pre_tax) + parseFloat(cart.membership_discount)
}

export const getActiveCart = () => {
  let currentActiveCart
  const state = store.getState()
  const activeVehicle = getActiveVehicle()
  const activeCarts =
    state.user && state.user.active_carts && state.user.active_carts.length
      ? state.user.active_carts
      : []
  currentActiveCart =
    activeVehicle && activeCarts.find(({ vehicle_id }) => vehicle_id === activeVehicle.id)
  return currentActiveCart || null
}
