import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import ShopListViewItemComponent from "./ShopListViewItemComponent"

import MapHeaderComponent from "../../Map/components/MapHeaderComponent"
import DisplayMessage from "../../../../components/ShopSearch/DisplayMessage"
import { getActiveVehicle } from "../../../../helpers/vehicleHelpers"

import { Icon } from "semantic-ui-react"
import MapWidgetExpanded from "./MapWidgetExpanded"
import Loading from "react-loading-overlay"
const HEIGHT = window.innerWidth < 1400 ? `520px` : "620px"
const height = Math.max(window.innerHeight * 0.65, 300)
class MapPopUpComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFilterOpen: false,
      isSortOpen: false,
      viewPort: {
        latitude: props.viewPort.latitude,
        longitude: props.viewPort.longitude,
        width: "-webkit-fill-available",
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      },
      shopSelected: {}
    }
  }

  setFilterOpen = (value) => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
      isSortOpen: false,
      shopSelected: undefined
    })
  }
  updateViewport = (viewPort) => {
    this.setState({
      viewPort: viewPort
    })
  }
  setSortOpen = (value) => {
    this.setState({
      isSortOpen: !this.state.isSortOpen,
      isFilterOpen: false,
      shopSelected: undefined
    })
  }
  selectShop = (shop) => {
    this.setState({ shopSelected: shop, isFilterOpen: false, isSortOpen: false })
  }

  onSuggestOptionClick = (suggest) => {
    if (suggest) {
      this.props.onSuggestSelect(suggest)
    }
  }

  render() {
    const {
      shops,
      setShopSelected,
      setShowMapExpanded,
      showMapExpanded,
      setSortingType,
      sortingType,
      ratingFiltersApplied,
      typeFiltersApplied,
      addorRemoveFilterItem,
      clearAllFilters,
      zip,
      hideFilters,
      onSelectShopClick,
      isCheckout = false,
      position,
      setPosition,
      searchLocation,
      isShopSelected = false
    } = this.props

    let vehicleTypeId
    if (this.props.user) {
      const activeVehicle = getActiveVehicle()
      vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
    }

    return (
      <div className="map-expanded">
        <div className="backdropDesign">
          <div className="map-expanded-container">
            {window.innerWidth > 914 ? (
              <div
                style={{
                  position: "absolute",
                  right: "-13px",
                  top: "-13px",
                  boxShadow: "rgb(203 201 201) 1px 2px 9px",
                  padding: "6px",
                  textAlign: "center",
                  borderRadius: "100px",
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff"
                }}
                onClick={() => {
                  setShowMapExpanded(false)
                  this.selectShop({})
                  let newWidth = 0

                  if (window.innerWidth > 1300 && window.innerWidth < 1400) {
                    newWidth = window.innerWidth / 2 - 80
                  }
                  if (window.innerWidth > 1400) {
                    newWidth = window.innerWidth / 2 - 300
                  }
                }}
              >
                <Icon
                  name="x icon"
                  style={{ display: "flex", justifyContent: "center", marginLeft: "5px" }}
                  color="grey"
                />
              </div>
            ) : null}
            {window.innerWidth > 914 ? (
              <MapHeaderComponent
                ratingFiltersApplied={ratingFiltersApplied}
                typeFiltersApplied={typeFiltersApplied}
                addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
                clearAllFilters={clearAllFilters}
                setSortingType={(value) => setSortingType(value)}
                sortingType={sortingType}
                zip={shops && shops.length > 0 ? shops[0].zip : zip}
                setIsFilterOpen={() => this.setFilterOpen(!this.state.isSortOpen)}
                setIsSortOpen={() => this.setSortOpen(!this.state.isFilterOpen)}
                isSortOpen={this.state.isSortOpen}
                isFilterOpen={this.state.isFilterOpen}
                isCheckout={isCheckout}
              />
            ) : null}

            <div className="expandedContainer">
              <div className="shopList">
                <DisplayMessage
                  message={this.props.t("common:mdHdShopsLabel")}
                  vehicleTypeId={vehicleTypeId}
                />
                {window.innerWidth > 914 && shops ? (
                  <Loading active={isShopSelected} spinner={true} text={""}>
                    <ShopListViewItemComponent
                      shops={shops}
                      showMapExpanded={showMapExpanded}
                      onClose={() => {
                        setShopSelected(false)
                      }}
                      onSelectShopClick={onSelectShopClick}
                    />
                  </Loading>
                ) : null}
              </div>

              <div className="map" style={{ borderRadius: "8px" }}>
                <MapWidgetExpanded
                  updateViewport={(viewport) => this.updateViewport(viewport)}
                  viewPort={this.state.viewPort}
                  setShopSelected={(shop) => this.selectShop(shop)}
                  shopSelected={this.state.shopSelected}
                  shops={shops}
                  setShowMapExpanded={(val) => {
                    setShowMapExpanded(val)
                    this.selectShop({})
                  }}
                  loading={this.props.loading}
                  showMapExpanded={showMapExpanded}
                  hideFilters={hideFilters}
                  ratingFiltersApplied={ratingFiltersApplied}
                  typeFiltersApplied={typeFiltersApplied}
                  addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
                  clearAllFilters={clearAllFilters}
                  setSortingType={(value) => setSortingType(value)}
                  sortingType={sortingType}
                  zip={shops && shops.length > 0 ? shops[0].zip : zip}
                  setIsFilterOpen={() => this.setFilterOpen(!this.state.isSortOpen)}
                  setIsSortOpen={() => this.setSortOpen(!this.state.isFilterOpen)}
                  isSortOpen={this.state.isSortOpen}
                  isFilterOpen={this.state.isFilterOpen}
                  onSuggestSelect={this.onSuggestOptionClick}
                  onSelectShopClick={(shop) => {
                    onSelectShopClick(shop)
                  }}
                  position={position}
                  setPosition={setPosition}
                  searchLocation={searchLocation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user }
}

export default connect(mapStateToProps)(withTranslation("dashboardShopList")(MapPopUpComponent))
