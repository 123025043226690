import range from "lodash/range"
import store from "../../shared_component/utils/configureStore"
import has from "lodash/has"
import i18n from "../../shared_component/utils/i18n"
import { DEFAULT_IMG_URL } from "../constants/confirmDetails"
import {
  DEFULT_TIRE_DEPTH,
  DEFULT_TIRE_INCH,
  REPLACE_NOW_DEPTH,
  REPLACE_SOON_MAX_DEPTH,
  REPLACE_SOON_MIN_DEPTH,
  TIRE_DEPTH_STATUS
} from "../constants/tires"
import { floor } from "lodash"

const EARLIEST_AVAILABLE_YEAR = 1970
export function addVehicleYearRange() {
  return range(new Date().getFullYear() + 1, EARLIEST_AVAILABLE_YEAR - 1, -1)
}

export const getActiveVehicle = () => {
  const state = store.getState()
  const activeVehicleId = (state.user && state.user.activeVehicleId) || -1
  const vehicles = (state.user && state.user.vehicles) || []
  return (vehicles && vehicles.find((vehicle) => vehicle.id === activeVehicleId)) || null
}

export const getVehicleName = (vehicle = {}) => {
  let vehicleName
  if (vehicle && typeof vehicle === "object") {
    if (vehicle.year) {
      vehicleName = vehicle.year
    }
    if (vehicle.make) {
      vehicleName += " " + vehicle.make
    }
    if (vehicle.model) {
      vehicleName += " " + vehicle.model
    }
  }
  return vehicleName || null
}

export const getTransformedMaintenanceScheduleResponse = (maintenanceScheduleResponse) => {
  let maintenanceSchedule = []
  if (has(maintenanceScheduleResponse, "result.maintenance.services[0]")) {
    {
      maintenanceSchedule = maintenanceScheduleResponse.result.maintenance.services.filter(
        (service) => !!service.services.length
      )
    }
  }
  return maintenanceSchedule
}

export function selectOptions(vehicles, options = {}) {
  let vehicleOptions = []
  vehicleOptions = options.includeBlank
    ? [
        {
          value: null,
          text: i18n.t("vehicleDetails:vehicleDropdownText")
        }
      ]
    : []
  vehicles.forEach((vehicle) => {
    vehicleOptions.push({
      key: vehicle.id,
      text: fetchVehicleName(vehicle),
      image: vehicle.dashboard_mobile_url ? vehicle.dashboard_mobile_url : DEFAULT_IMG_URL,
      value: vehicle,
      isDefaultImage: !vehicle.dashboard_mobile_url
    })
  })

  return vehicleOptions
}

export const fetchVehicleName = (vehicle = {}) => {
  let vehicleName
  if (vehicle && typeof vehicle === "object") {
    if (vehicle.year) {
      vehicleName = vehicle.year
    }
    if (vehicle.make) {
      vehicleName += " " + vehicle.make
    }
    if (vehicle.model) {
      vehicleName += " " + vehicle.model
    }
  }
  return vehicleName || i18n.t("vehicleDetails:unknownLabel")
}

export const handleTireStatus = (tireTreadDepth) => {
  if (tireTreadDepth <= REPLACE_NOW_DEPTH) {
    return TIRE_DEPTH_STATUS.replaceNow
  } else if (tireTreadDepth >= REPLACE_SOON_MIN_DEPTH && tireTreadDepth <= REPLACE_SOON_MAX_DEPTH) {
    return TIRE_DEPTH_STATUS.replaceSoon
  } else {
    return TIRE_DEPTH_STATUS.good
  }
}

const getStatusCounts = (tireDepths) => {
  return tireDepths.reduce((acc, item) => {
    const status = item.status
    acc[status] = (acc[status] || 0) + 1
    return acc
  }, {})
}

export const handleTireStatusData = (
  tire_tread_fl,
  tire_tread_fr,
  tire_tread_rl,
  tire_tread_rr
) => {
  const tireStatusData = [
    { tire_tread: tire_tread_fl },
    { tire_tread: tire_tread_fr },
    { tire_tread: tire_tread_rl },
    { tire_tread: tire_tread_rr }
  ].map((tire) => ({
    status: tire.tire_tread ? handleTireStatus(tire.tire_tread) : "",
    depth: tire.tire_tread ? `${floor(tire.tire_tread)}/${DEFULT_TIRE_INCH}` : DEFULT_TIRE_DEPTH,
    actualDepth: !!tire.tire_tread
  }))

  const buyNewTiresStatus = tireStatusData.filter(
    (tire) => tire.status && tire.status !== TIRE_DEPTH_STATUS.good
  )

  const tireWithGoodStatus = tireStatusData.filter(
    (tire) => tire.status && tire.status === TIRE_DEPTH_STATUS.good
  )

  const allTireDepthAvailable = tireStatusData.filter((tire) => tire.allTireDepthAvailable)

  const buyNewTiresCount = buyNewTiresStatus && buyNewTiresStatus.length

  const shouldRenderContainer =
    tireStatusData.some((tire) => tire.actualDepth) &&
    !tireStatusData.every((tire) => tire.status === TIRE_DEPTH_STATUS.good)

  return {
    tireStatusData,
    buyNewTiresCount,
    allTireHasGoodStatus: tireStatusData.length === tireWithGoodStatus && tireWithGoodStatus.length,
    allTireDepthAvailable: allTireDepthAvailable.length === tireStatusData && tireStatusData.length,
    shouldRenderContainer,
    statusWithCount: getStatusCounts(tireStatusData)
  }
}
