import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import Button from "../Button"
import { prepareCheckoutObj } from "../../helpers/affirmHelper.js"
import { updatePaymentType } from "../../actions/orders"
import LoadingComponent from "../LoadingComponent"
import { elements } from "caradvise_shared_components"
import { Image, Message } from "semantic-ui-react"
import AffirmLogo from "../../../images/affirm.svg"

const { ModalDialog } = elements

class Affirm extends Component {
  constructor(props) {
    super(props)
    this.onFailedCheckout = this.onFailedCheckout.bind(this)
    this.onSuccessCheckout = this.onSuccessCheckout.bind(this)
    this.state = {
      checkoutLoading: false
    }
    this.warningDialog = React.createRef()
  }

  componentDidMount() {
    window.affirm && window.affirm.ui && window.affirm.ui.refresh()
  }

  componentDidUpdate() {
    window.affirm && window.affirm.ui && window.affirm.ui.refresh()
  }

  handleModalClose = async (e) => {
    this.setState({
      checkoutLoading: true
    })

    let { order } = this.props || []
    let { user } = this.props || []
    let checkoutObj = prepareCheckoutObj(user, order)
    window.affirm.checkout(checkoutObj)

    await window.affirm.checkout.open({
      onFail: (a) => this.onFailedCheckout(a),
      onSuccess: (a) => this.onSuccessCheckout(a)
    })
    if (e) e.preventDefault()
  }

  renderWarningDialog = (ref) => {
    const { t } = this.props

    return (
      <ModalDialog ref={ref} warningClassName="warning" afterCloseModal={this.handleModalClose}>
        <Message warning>
          <Message.Header>{t("paymentList:alertLabel")}</Message.Header>
          <span style={{ display: "flex", textAlign: "justify" }}>
            {t("paymentList:affirmAlertLabel")}
          </span>
        </Message>
      </ModalDialog>
    )
  }

  payUsingAffirm = (warningDialog) => {
    warningDialog.openModal()
  }

  onFailedCheckout(obj) {
    this.setState({ checkoutLoading: false })
    window.affirm.ui.refresh()
  }

  onSuccessCheckout(obj) {
    const paymentType = "affirm"
    let affirmCheckoutToken = obj["checkout_token"]
    this.setState({ checkoutLoading: true }, () => {
      this.postApprovals(paymentType, affirmCheckoutToken).then(() => {
        this.setState({ checkoutLoading: false })
        window.affirm.ui.refresh()
      })
      // window.affirm.ui.refresh()
    })
  }

  postApprovals = async (paymentType, affirmCheckoutToken) => {
    const response = await this.props.updatePaymentType(
      this.props.order,
      paymentType,
      affirmCheckoutToken,
      "awaiting_approval"
    )
  }

  handleAffirmSelect = (event) => {
    const { handleAffirmSelect } = this.props
    handleAffirmSelect(event)
    if (event) event.preventDefault()
  }

  render() {
    let { order, cart, currentPage, isAffirmSelectedDefault, handleAffirmSelect, user } = this.props
    const affirm_user_ids = process.env.REACT_APP_SELECTED_AFFIRM_USER_IDS
    const affirm_user_emails = process.env.REACT_APP_SELECTED_AFFIRM_USER_EMAILS
    let orderPrice, orderCurrency, order_previous_payments, remaining_payment

    if (order) {
      orderPrice = order.estimated_total
      orderCurrency = order.currency
      order_previous_payments = order.previous_payments
    } else if (cart) {
      orderPrice = cart.estimated_total
      orderCurrency = cart.currency
      order_previous_payments = 0.0
    }

    remaining_payment = orderPrice - order_previous_payments

    if ((remaining_payment != 0.0 && remaining_payment < 50) || orderCurrency != "usd") {
      return <div></div>
    }

    if (
      affirm_user_ids.split(",").some((item) => parseInt(item) == user.id) ||
      affirm_user_emails.split(",").some((item) => item == user.email) ||
      (affirm_user_ids == "*" && affirm_user_emails == "*")
    ) {
      return typeof order !== "undefined" ? (
        <>
          <AffirmPaymentOption
            checkoutLoading={this.state.checkoutLoading}
            isAffirmSelectedDefault={isAffirmSelectedDefault}
            order={order}
            onClick={() => this.payUsingAffirm(this.warningDialog)}
            remaining_payment={remaining_payment}
          />
          {this.renderWarningDialog((dialog) => {
            this.warningDialog = dialog
          })}
        </>
      ) : (
        <AffirmPaymentOption
          checkoutLoading={this.state.checkoutLoading}
          isAffirmSelectedDefault={isAffirmSelectedDefault}
          order={cart}
          onClick={handleAffirmSelect}
          remaining_payment={remaining_payment}
        />
      )
    } else {
      return <div></div>
    }
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, { updatePaymentType })(withTranslation("affirm")(Affirm))

function AffirmPaymentOption(props) {
  let { order, onClick, checkoutLoading, isAffirmSelectedDefault, remaining_payment } = props
  let className = isAffirmSelectedDefault
    ? "nextPaymentMethodRow greenBorder"
    : "nextPaymentMethodRow grayBorder"
  return (
    <div
      style={
        order.status == "approval" || remaining_payment == 0.0
          ? { pointerEvents: "none", opacity: "0.4" }
          : {}
      }
    >
      <div id="prices" className={className} style={{ textAlign: "center" }}>
        {checkoutLoading ? (
          <LoadingComponent />
        ) : (
          <div onClick={onClick} style={{ width: "100%" }}>
            <div className="affirm-payment-container">
              <Image src={AffirmLogo} />
              {"Monthly payments"}
            </div>
            <p
              className="affirm-as-low-as"
              data-page_type="cart"
              data-amount={order.estimated_total * 100}
              style={{ background: "#4A4AF30D", marginBlockStart: 0, padding: "5px" }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function AffirmPaymentButton(props) {
  const { showPaymentButton } = props
  return (
    <div>
      <div id="prices" className="nextPaymentMethodRow grayBorder" style={{ textAlign: "center" }}>
        {showPaymentButton ? (
          <div>
            <div className="radio-button" style={{ float: "left", padding: "25px" }}>
              <input id="chkTest" type="radio" name="testRadio" />
            </div>
            <a
              className="affirm-site-modal"
              data-page-type="banner"
              aria-label='<img src="https://cdn-assets.affirm.com/images/banners/234x60.png"> - Affirm Financing (opens in modal)'
              style={{ cursor: "pointer" }}
            >
              <img
                src="https://cdn-assets.affirm.com/images/banners/234x60.png"
                style={{ width: "275px", height: "70px", paddingLeft: "25px" }}
              />
            </a>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  )
}
