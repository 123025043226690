import { LOAD_SHOP_LIST_VIEW } from "../constants/reduxActions"

const initialState = {
  showMapFlag: false
}

export default function shopListReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SHOP_LIST_VIEW:
      return { ...state, showMapFlag: action.showMap }
    default:
      return state
  }
}
