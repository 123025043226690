import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../components/Button"
import { Redirect } from "react-router"
import { signIn, setLoading, setNotLoading } from "../actions/user"
import PaymentList from "../components/PaymentList"
import "url-search-params-polyfill"
import { withTranslation } from "react-i18next"

class CCDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this._onClickPass = this._onClickPass.bind(this)
  }

  async _onClickPass() {
    await this.setState({ gotoNext: true })
  }

  componentDidUpdate() {
    if (this.props.hasCC) setTimeout(() => this.setState({ gotoNext: true }), 3000)
  }

  render() {
    if (this.state.gotoNext) {
      return <Redirect to="/dashboard" />
    }
    const { t } = this.props
    return (
      <div className="container" style={{ width: "100%" }}>
        <div className="contentCenter">
          <div className="progressBar">
            <div className="progress">
              <strong>{t("step1Lbl")}</strong>
              <br />
              {t("accountDetailsLbl")}
            </div>
            <div className="progress">
              <strong>{t("step2Lbl")}</strong>
              <br />
              {t("vehicleDetailsLbl")}
            </div>
            <div className="progress progressOn">
              <strong>{t("step3Lbl")}</strong>
              <br />
              {t("paymentDetailsLbl")}
            </div>
          </div>
          <p className="introText">{t("wannaSaveTimeDesc")}</p>

          <PaymentList />

          {!this.props.hasCC && (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Button text={t("doItLaterText")} colorStyle="grey" onClick={this._onClickPass} />
            </div>
          )}
          <p className="disclaimer">
            {t("footer", { thisOrEither: this.props.isTextAdvise ? "this" : "either" })}
            <br />
            <a href="http://caradvise.com/privacy/" target="_blank">
              {t("privacyPolicyLbl")}
            </a>{" "}
            {t("andLbl")}{" "}
            <a href="http://caradvise.com/terms/" target="_blank">
              {t("termsOfUseLbl")}
            </a>
            .
          </p>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    hasCC: state.user && state.user.has_cc_info_saved
  }
}

export default connect(mapStateToProps, { signIn, setLoading, setNotLoading })(
  withTranslation("ccDetails")(CCDetails)
)
