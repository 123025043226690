import React from "react"
import BenifitLog from "../../images/benefit_logo.svg"
import ReactSVG from "react-svg"

const ProviderBenifits = ({ benifit }) => {
  return (
    <div className="provider_benifits">
      <ReactSVG className="benifit_logo" src={BenifitLog} />
      <p className="provider_benifit__text">{benifit}</p>
    </div>
  )
}

export default ProviderBenifits
