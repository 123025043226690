const LOAD_APPROVED_SERVICE = "LOAD_APPROVED_SERVICE"
const LOAD_PENDING_APPROVED_SERVICE = "LOAD_PENDING_APPROVED_SERVICE"

export default function approvedReducer(state = {}, action) {
  const nextState = Object.assign({}, state)
  switch (action.type) {
    case LOAD_APPROVED_SERVICE:
      if (action.approvedService.id) {
        nextState[action.approvedService.id] = action.approvedService
      } else {
        nextState[action.approvedService[0].id] = action.approvedService
      }
      break

    case LOAD_PENDING_APPROVED_SERVICE:
      nextState["pending"] = {}
      nextState["pending"][action.id] = {}
      if (action.pending.id) {
        nextState["pending"][action.id][action.pending.id] = action.pending
      } else {
        nextState[action.pending[0].id] = action.pending
      }
      break

    default:
      return state
  }
  return nextState
}
