import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import MembershipCardFrontFormat from "../../../v1/components/Membership/MembershipCardFrontFormat"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { withRouter } from "react-router-dom"
import { Wallets } from "../../../shared_component/components/Wallets"
import { getAppleWalletUrl, getGoogleWalletUrl } from "../../../v1/actions/membership"

const MembershipCardSummary = ({
  user,
  vehicles,
  t,
  appleWalletUrl,
  googleWalletUrl,
  history,
  getGoogleWalletUrl,
  getAppleWalletUrl
}) => {
  const planName = user && user.membership.plan_name
  const membershipMap = {
    Basic: t("membershipPanel:basic"),
    Premium: t("membershipPanel:premium")
  }

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.authentication_token) {
        try {
          await getAppleWalletUrl()
          await getGoogleWalletUrl()
        } catch (error) {
          console.error("Error fetching wallet URLs:", error)
        }
      }
    }

    fetchData()
  }, [])

  const membershipType = membershipMap[planName] || t("membershipPanel:elite")

  const backgroundColorMap = {
    [t("membershipPanel:basic")]: "#FFFFFF",
    [t("membershipPanel:premium")]: "#F1F3F9",
    [t("membershipPanel:elite")]: "#2E2E2E"
  }

  const colorMap = {
    [t("membershipPanel:basic")]: "#343434",
    [t("membershipPanel:premium")]: "#1D1D1D",
    [t("membershipPanel:elite")]: "#FFFFFF"
  }

  return (
    <div className="membership-summary-container">
      <div className="membership-summary-header-section">
        <div className="primary-header-section">
          <p className="label">{t("membership")}</p>
          <p className="see-more-link" onClick={() => routeChange(history, routerPaths.membership)}>
            {t("seeMoreLink")}
          </p>
        </div>
        <p className="secondary-header">{t("showMembershipCard")}</p>
      </div>
      <MembershipCardFrontFormat
        user={user}
        plan={membershipMap[planName] || t("membershipPanel:elite")}
        vehicles={vehicles}
        bg={backgroundColorMap[membershipType] || "#2E2E2E"}
        clr={colorMap[membershipType] || "#FFFFFF"}
      />
      <Wallets
        googleWalletUrl={googleWalletUrl}
        appleWalletUrl={appleWalletUrl}
        planName={membershipMap[planName]}
      />
    </div>
  )
}

function mapStateToProps(state) {
  const user = state.user || {}
  const vehicles = user.vehicles || []
  const vehicle = vehicles.find((v) => v.id === user.activeVehicleId) || vehicles[0]
  const name = [user.firstName, user.lastName].filter((n) => n != null).join(" ")
  const availableMembershipPlans =
    state.membershipData && state.membershipData.availableMembershipPlans
  const isLoggedIn = !!user.authentication_token
  const googleWalletUrl = state.membershipData && state.membershipData.googleWalletKeyUrl
  const appleWalletUrl = state.membershipData && state.membershipData.appleWalletKeyUrl

  return {
    name,
    vehicle,
    user,
    availableMembershipPlans,
    isLoggedIn,
    googleWalletUrl,
    appleWalletUrl
  }
}

export default connect(mapStateToProps, { getGoogleWalletUrl, getAppleWalletUrl })(
  withRouter(withTranslation(["bookingMembership", "membershipPanel"])(MembershipCardSummary))
)
