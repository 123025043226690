import React from "react"
import { Grid } from "semantic-ui-react"
import { useTranslation } from "react-i18next"

export default function LoadingServices() {
  const { t } = useTranslation("loadingServices")
  return (
    <Grid verticalAlign="middle" padded="vertically" stackable className="search-item">
      <Grid.Column textAlign="left">{t("searcingLbl")}</Grid.Column>
    </Grid>
  )
}
