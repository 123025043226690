import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { selectShop, setMakeShopsApiCallData } from "../../../../v1/actions/shop"
import { getShopAddress, getShopName, withPricing } from "../../../../v1/helpers/shopHelpers"
import { getJSON } from "../../../../shared_component/utils/fetch"
import { withRouter } from "react-router-dom"
import { isCanada } from "../../../../v1/helpers/translationHelpers"
import { parseInt, partition } from "lodash"
import { sortByAttr } from "../../../../shared_component/utils/arrayHelpers"
import { trackEvent } from "../../../../shared_component/utils/segmentAnalytics"
import store from "../../../../shared_component/utils/configureStore"
import { clearPromotionParam } from "../../../../v1/actions/promotion"
import {
  MAP_VIEW,
  LIST_VIEW,
  RATING_TAG,
  DISTANCE,
  PRICE
} from "../../../../shared_component/constants/checkout"
import ShopList2 from "../../../../v1/CarAdvise2/screens/dashboard/components/ShopList2"
import { setLoading, setNotLoading, updateCart } from "../../../../v1/actions/user"
import i18n from "../../../../shared_component/utils/i18n"
import { shopSelectedEvents } from "../../../../shared_component/utils/googleAnalyticsHelpers"
import { openShopListView } from "../../../../v1/actions/checkout"
import { CART_SIDE_PANEL_OPEN } from "../../../../v1/constants/maintenanceSchedules"

const FIND_SHOPS_URL = "/api/v4/shops/select_shops"

function getRegion(shop) {
  return {
    latitude: shop.latitude,
    longitude: shop.longitude,
    latitudeDelta: 0.1,
    longitudeDelta: 0.1
  }
}

class ShopSearch extends Component {
  constructor(props) {
    super(props)
    const { user } = props
    this.state = {
      zip: "",
      shops: [],
      selectedShop: null,
      selectedCompany: null,
      geolocationFinished: true,
      prevShopSearchParams: {},
      shopsLoaded: false,

      // default latitude/longitude is for Toronto or Chicago
      region: {
        latitude: isCanada(user.country) ? 43.6390608 : 41.8786673,
        longitude: isCanada(user.country) ? -79.4199158 : 87.6428121,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421
      },

      searchLocation: null,

      selectedView: LIST_VIEW,
      sortAttr: DISTANCE,

      loading: false,
      notificationModalOpen: false,
      showNewMap: false,
      ratingFiltersApplied: [],
      typeFiltersApplied: [],
      isShopSelected: false,
      selectedShopResponse: {},
      showCart: false
    }
    this.fetchShops.bind(this)
    this._handleSuggestSelect = this._handleSuggestSelect.bind(this)
  }

  async componentDidMount() {
    this.fetchInitialData()
  }

  handleSideCartPanel = (flag) => {
    this.setState({ showCart: flag })
  }

  componentDidUpdate(prevProps) {
    const { addedServices, user } = this.props
    if (prevProps !== this.props) {
      if (
        prevProps.user.zip !== user.zip ||
        prevProps.addedServices.length !== addedServices.length
      ) {
        this.fetchInitialData()
      }
    }
  }

  handleShopSelect = async (shop) => {
    this.setState({ selectedShop: shop, isShopSelected: true })
    const { showCart } = this.state
    const { promoCode, cart, activeVehicle, t, openShopListView, updateCart } = this.props
    const hasNoServices = (cart.order_services || []).length === 0

    let opts = {
      successCallback: async (response) => {
        await shopSelectedEvents({ response, shop, activeVehicle })
        await trackEvent("click-service-center", {
          shopAddress: getShopAddress(response.shop),
          shopCity: response.shop && response.shop.city,
          shopName: getShopName({
            shop: response.shop,
            company: response.shop && response.shop.company
          }),
          shopId: response.shop && parseInt(response.shop.id),
          eventSourceName: "Checkout_2.0"
        })
        store.dispatch(clearPromotionParam())
      }
    }
    if (promoCode) opts.promoCode = promoCode

    const response = await updateCart(
      [{ event: "set_shop", shop_id: shop.id }],
      undefined,
      undefined,
      true,
      opts
    )

    if (response && response().result) {
      this.setState({ selectedShopResponse: response().result })
      this.setState({ isShopSelected: false })
      await openShopListView(false)
      if (!hasNoServices && !showCart) {
        const events = new CustomEvent(CART_SIDE_PANEL_OPEN, { detail: true })
        document.dispatchEvent(events)
      }
    }
    if (response().error) {
      this.setState({ isShopSelected: false })
    }
  }

  searchShops = async (params) => {
    if (!(params.latitude && params.longitude) && !params.zip) params.zip = this.props.user.zip
    this.setState({ shopsLoaded: true })
    const response = await getJSON(FIND_SHOPS_URL, params, {
      Authorization: this.props.user.authentication_token
    })

    if (response.result && !response.result.errors) {
      this.props.setMakeShopsApiCallData(response.result)
      const shops = withPricing(this.props.shops || [], this.props.t)
      this.setState({
        shops,
        region: shops[0] ? getRegion(shops[0]) : this.state.region,
        selectedShop: null,
        prevShopSearchParams: params
      })
    } else {
    }
  }

  fetchInitialData() {
    const { user } = this.props
    const queryParams = new URLSearchParams(this.props.location.search)

    if (!user.authentication_token) return

    if (user.zip) {
      this.fetchShops({ zip: user.zip }, false)
    } else if (queryParams.get("lat") && queryParams.get("lng")) {
      this.fetchShopsFromParams(queryParams)
    } else {
      this.fetchShopsFromUsersLocation()
    }
  }

  fetchShopsFromParams(queryParams) {
    const latitude = queryParams.get("lat")
    const longitude = queryParams.get("lng")

    this.setState(
      {
        searchLocation: {
          latitude,
          longitude
        }
      },
      () => {
        this.fetchShops({ latitude, longitude }, false)
      }
    )
  }

  async fetchShopsFromUsersLocation() {
    this.setState({ geolocationFinished: false })
    if (navigator && navigator.permissions && navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (data) => {
              const { latitude, longitude } = data.coords
              this.fetchShops({ latitude, longitude }, false)
            },
            (error) => {
              this.getDefaultShops()
            }
          )
        } else {
          this.getDefaultShops()
        }
      })
    } else {
      this.getDefaultShops()
    }
  }

  getDefaultShops() {
    const { region } = this.state
    const { latitude, longitude } = region
    this.fetchShops({ latitude, longitude }, false)
  }

  clearAllFilters = () => {
    const { latitude, longitude, zip } = this.state.prevShopSearchParams
    this.setState({
      ratingFiltersApplied: [],
      typeFiltersApplied: []
    })
    this.fetchShops({ latitude, longitude, zip, clearFilters: true })
  }
  async fetchShops(
    {
      latitude,
      longitude,
      zip,
      rating = undefined,
      appointmentType = undefined,
      clearFilters = false
    },
    fullReload = true
  ) {
    if (this.state.loading) {
      return
    }
    const { t } = this.props
    this.setState({ geolocationFinished: true })
    fullReload && this.props.setLoading(t("findingShopsLoadingMessage"))

    this.setState({ loading: true })

    let params = {
      services_requested: this.servicesParam()
    }

    if (this.props.user.activeVehicleId) {
      params.vehicle_id = this.props.user.activeVehicleId
    }
    if (clearFilters) {
      params.rating = "[]"
      params.booking_type = "[]"
    } else {
      if (rating != undefined) {
        params.rating = JSON.stringify(rating)
      } else if (this.state.ratingFiltersApplied && this.state.ratingFiltersApplied.length > 0) {
        params.rating = JSON.stringify(this.state.ratingFiltersApplied)
      }
      if (appointmentType != undefined) {
        params.booking_type = JSON.stringify(appointmentType)
      } else if (this.state.typeFiltersApplied && this.state.typeFiltersApplied.length > 0) {
        params.booking_type = JSON.stringify(this.state.typeFiltersApplied)
      }
    }
    const { promoCode } = this.props
    if (promoCode) params["promo_code"] = promoCode

    if (zip) {
      params.zip = zip
    } else if (latitude && longitude) {
      // Set lat & long in params
      params.latitude = latitude
      params.longitude = longitude
    } else {
      fullReload && this.props.setNotLoading()
      return
    }
    await this.searchShops(params)

    fullReload && this.props.setNotLoading()
    this.setState({ loading: false })
  }

  servicesParam() {
    const services = this.props.addedServices.map((sd) => {
      return { position: sd.position, service_definition_id: sd.id }
    })

    return JSON.stringify(services)
  }

  _handleSuggestSelect(suggest) {
    if (suggest) {
      this.setState(
        {
          searchLocation: {
            name: suggest.label,
            latitude: suggest.location.lat,
            longitude: suggest.location.lng
          }
        },
        () => {
          this.fetchShops(this.state.searchLocation, false)
        }
      )
    }
  }

  filteredSortedShops = () => {
    const { shops, isFixedPrice, selectedCompany, sortAttr } = this.state
    let filteredShops
    if (selectedCompany && selectedCompany.independent) {
      filteredShops = shops.filter(
        (shop) => shop.company.independent && shop.company.paid === selectedCompany.paid
      )
    } else if (selectedCompany) {
      filteredShops = shops.filter((shop) => shop.company_id === selectedCompany.id)
    } else {
      filteredShops = shops
    }

    if (sortAttr === PRICE) {
      let partitioned = partition(filteredShops, (s) => s["shopPriceEstimate"] != null)
      let withPrices = partitioned[0]
      let nullPrices = partitioned[1]

      let sorted = sortByAttr(withPrices, "shopPriceEstimate")
      sorted = sorted.concat(sortByAttr(nullPrices, DISTANCE))

      return sorted
    } else {
      return sortByAttr(filteredShops, DISTANCE)
    }
  }

  handleShopPreSelect = (shop) => {
    this.setState({ selectedShop: shop })
  }

  addorRemoveFilterItem(value, filterType) {
    const { latitude, longitude, zip } = this.state.prevShopSearchParams

    const selectedType =
      filterType == RATING_TAG
        ? this.state.ratingFiltersApplied.slice()
        : this.state.typeFiltersApplied.slice()
    if (filterType == RATING_TAG) {
      const index = selectedType.findIndex((i) => i == value)
      if (index > -1) {
        selectedType.splice(0, selectedType.length)
      } else {
        selectedType.splice(0, selectedType.length)
        if (value == 2) {
          selectedType.push(2, 3, 4, 5)
        } else if (value == 3) {
          selectedType.push(3, 4, 5)
        } else if (value == 4) {
          selectedType.push(4, 5)
        } else if (value == 5) {
          selectedType.push(5)
        }
      }
    } else {
      const index = selectedType.findIndex((i) => i == value)
      if (index > -1) {
        selectedType.splice(index, 1)
      } else {
        selectedType.push(value)
      }
    }
    if (filterType == RATING_TAG) {
      this.setState({
        ratingFiltersApplied: selectedType
      })
      this.fetchShops({ latitude, longitude, zip, rating: selectedType })
    } else {
      this.setState({
        typeFiltersApplied: selectedType
      })
      this.fetchShops({ latitude, longitude, zip, appointmentType: selectedType })
    }
  }

  handleApplyFilterClick = (rating, bookingType, clearFilters = false) => {
    const { latitude, longitude, zip } = this.state.prevShopSearchParams
    this.setState({
      ratingFiltersApplied: rating,
      typeFiltersApplied: bookingType
    })
    this.fetchShops({
      latitude,
      longitude,
      zip,
      rating: rating,
      appointmentType: bookingType,
      clearFilters: clearFilters
    })
  }

  render() {
    const { user, activeView, count, showShopTiles } = this.props
    const { loading, isShopSelected, searchLocation, selectedShopResponse } = this.state
    const { ratingFiltersApplied, typeFiltersApplied, sortAttr } = this.state
    return (
      <div className="search-shop-container">
        <ShopList2
          handleShopSelect={this.handleShopSelect}
          shops={this.filteredSortedShops()}
          addorRemoveFilterItem={(value, type) => this.addorRemoveFilterItem(value, type)}
          clearAllFilters={this.clearAllFilters}
          ratingFiltersApplied={ratingFiltersApplied}
          typeFiltersApplied={typeFiltersApplied}
          sortingType={sortAttr}
          loading={loading}
          updateSortingType={(value) => {
            this.setState({ sortAttr: value, selectedShop: null })
          }}
          onSuggestSelect={this._handleSuggestSelect}
          zip={user.zip}
          handleApplyFilterClick={this.handleApplyFilterClick}
          activeView={activeView}
          isCheckout={true}
          isShopSelected={isShopSelected}
          count={count}
          showShopTiles={showShopTiles}
          searchLocation={searchLocation}
          selectedShopResponse={selectedShopResponse}
          handleSideCartPanel={this.handleSideCartPanel}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user
  let shops = state.shop.shops || []
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let addedServices = cart ? cart.order_services : []
  let modalNotifications = state.modalNotifications
  const promotionData = state.promotionData

  return {
    addedServices,
    anyOrderServiceLacksPrice: cart && cart.any_order_service_lacks_price,
    promoCode: promotionData.promoCode,
    user,
    cart: cart || {},
    shops,
    modalNotifications,
    activeVehicle
  }
}

export default connect(mapStateToProps, {
  setMakeShopsApiCallData,
  selectShop,
  setLoading,
  setNotLoading,
  openShopListView,
  updateCart
})(withRouter(withTranslation(["selectShop", "common"])(ShopSearch)))
