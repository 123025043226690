import React, { PureComponent } from "react"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import MembershipUpgradeCard from "./MembershipUpgradeCard"
import MembershipClauseAgreementSection from "./MembershipClauseAgreementSection"
import { includesRoadside } from "../../helpers/membershipHelpers"
import { withTranslation } from "react-i18next"

class MembershipUpgradeContent extends PureComponent {
  vehicleSection = () => {
    return <p>{this.props.t("vehicleSectionText")}</p>
  }

  checkoutSection = () => {
    const { preMembershipDiscountSubtotal, selectedMembership, currentMembership } = this.props
    const discount = selectedMembership && selectedMembership.percent_discount

    if (!discount || !preMembershipDiscountSubtotal || preMembershipDiscountSubtotal === 0)
      return this.settingsSection()

    const currentDiscount = currentMembership.percent_discount || 0

    const selectedSavings = (parseFloat(preMembershipDiscountSubtotal) * parseFloat(discount)) / 100
    const currentSavings =
      (parseFloat(preMembershipDiscountSubtotal) * parseFloat(currentDiscount)) / 100

    const savings = selectedSavings - currentSavings
    if (!savings || savings <= 0) return this.settingsSection()

    return (
      <div>
        <p>
          {`Save an additional `}
          <span className="premium-upgrade-price">{numberFormatToCurrency(savings)}</span>
          {` on this order by upgrading your membership!`}
        </p>
      </div>
    )
  }

  settingsSection = () => {
    return (
      <div style={{ margin: "7px 0" }}>
        <div>{this.props.t("subHeaderText")}</div>
      </div>
    )
  }

  defaultSection = () => {
    return <p>{this.props.t("defaultSectionText")}</p>
  }

  variableContent = () => {
    const { variableContentType } = this.props

    return (
      {
        vehicleSection: this.vehicleSection(),
        checkoutSection: this.checkoutSection(),
        settingsSection: this.settingsSection()
      }[variableContentType] || this.defaultSection()
    )
  }

  render = () => {
    const {
      variableContentType,
      onSelectMembership,
      selectedMembership,
      updateSelectedMembership
    } = this.props
    const nextUpgrades = this.props.nextUpgrades || []
    if (selectedMembership == {}) return null

    return (
      <div className="membership-upgrade-content">
        {variableContentType && this.variableContent()}

        <div className="membership-upgrade-cards">
          {nextUpgrades.map((plan) => {
            return (
              <MembershipUpgradeCard
                style={nextUpgrades.length > 1 ? { width: "49%" } : {}}
                key={`MembershipUpgradeContent-${plan.name}`}
                plan={plan}
                onSelectMembership={onSelectMembership}
                isSelected={plan.name === selectedMembership.name}
              />
            )
          })}
        </div>

        {includesRoadside(selectedMembership) && (
          <div className="membership-upgrade-clauses">
            <MembershipClauseAgreementSection
              updateSelectedMembership={updateSelectedMembership}
              selectedMembershipUpgrade={selectedMembership}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation("membershipUpgradeContent")(MembershipUpgradeContent)
