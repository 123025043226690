import React, { Component, PureComponent } from "react"
import OrderServicePriceRow from "./OrderServicePriceRow"
import ModalDialog from "../ModalDialog"
import PropTypes from "prop-types"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { helpers, constants } from "caradvise_shared_components"
import ReactTooltip from "react-tooltip"
import { withTranslation } from "react-i18next"
import SavingsRibbon from "../ShopSearch/SavingsRibbon"
import { pricingDataForOrder } from "../../helpers/shopHelpers"
import { updateCart } from "../../actions/user"
import { connect } from "react-redux"
import MiniLoader from "../MiniLoader"
import store from "../../../shared_component/utils/configureStore"
import moment from "moment"
import { INSTACART } from "../../constants/affiliations"
import SplitClient from "../../components/Config/SplitClient"
import ReactSVG from "react-svg"
import PrepayCheckbox from "../Cart/PrepayCheckbox"

const {
  orders: { ACTIVE, INITIATED_BY_USER }
} = constants
const {
  tooltips: { TAX_AND_FEES, TAX_AND_FEES_FR }
} = constants

export class OrderTopLevelPricing extends PureComponent {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    order: PropTypes.object.isRequired,
    shop: PropTypes.object,
    isFixedPrice: PropTypes.bool
  }

  renderPromotionInfo = () => {
    const { order, shop } = this.props
    return (
      <ModalDialog
        headerTitle={order.promotions && order.promotions[0].name}
        icon={faQuestionCircle}
      >
        {order.promotions[0].description}
      </ModalDialog>
    )
  }

  totalPriceText = (order, shop) => {
    if (order.any_order_service_lacks_price) {
      return this.props.t("TBDLbl")
    } else {
      return numberFormatToCurrency(order.estimated_total)
    }
  }

  is_instacart_affiliation = (user) => {
    try {
      if (user.affiliation_name.toLocaleLowerCase().includes(INSTACART)) return true
      else return false
    } catch (e) {
      if (window.location.origin.toLocaleLowerCase().includes(INSTACART)) return true
      else return false
    }
  }

  render() {
    const { shop, isFixedPrice, t, user } = this.props
    const order = this.props.order || {}

    return (
      <React.Fragment>
        {order.total_rewards_credit > 0 && (
          <tr className="primary-color secondary-font" key={`opd1-${order.id}`}>
            <td>{`${t("carAdviseCashLbl")}`}</td>
            <td style={{ textAlign: "right" }}>
              ({numberFormatToCurrency(order.total_rewards_credit)})
            </td>
          </tr>
        )}
        {order.prepay_discount > 0 && (
          <tr
            className="primary-color prepay-discount-amount secondary-font"
            key={`opd1-${order.id}`}
          >
            <td>5% Pay Ahead Discount</td>
            <td style={{ textAlign: "right" }}>
              ({numberFormatToCurrency(order.prepay_discount)})
            </td>
          </tr>
        )}
        {order.promotional_discount > 0 && (
          <tr className="primary-color secondary-font" key={`opd2-${order.id}`}>
            <td>
              {this.is_instacart_affiliation(user)
                ? `${t("instacartDiscountLbl")} `
                : `${t("promotionalDiscountLbl")} `}
              {this.renderPromotionInfo()}
            </td>
            <td style={{ textAlign: "right" }}>
              ({numberFormatToCurrency(order.promotional_discount)})
            </td>
          </tr>
        )}
        {order.caradvise_discount > 0 && (
          <tr className="primary-color secondary-font" key={`ocd1-${order.id}`}>
            <td>{t("caradviseDiscountLbl")}</td>
            <td style={{ textAlign: "right" }}>
              ({numberFormatToCurrency(order.caradvise_discount)})
            </td>
          </tr>
        )}
        {order.membership_discount > 0 && (
          <tr className="primary-color secondary-font" key={`ocd1-${order.id}`}>
            <td>{t("membershipDiscountLbl")}</td>
            <td style={{ textAlign: "right" }}>
              ({numberFormatToCurrency(order.membership_discount)})
            </td>
          </tr>
        )}
        {order.estimated_total_pre_tax > 0 && [
          <tr key={`oprt3-${order.id}`}>
            <td className="secondary-font">{t("subtotalLbl")}</td>
            <td className="secondary-color" style={{ textAlign: "right" }}>
              {numberFormatToCurrency(order.estimated_total_pre_tax - order.transaction_fee)}
            </td>
          </tr>
        ]}
        {order.transaction_fee > 0 && [
          <tr key={`otf-${order.id}`}>
            <td>
              <span className="secondary-font">
                {t("taxesAndFeesLbl")}
                <FontAwesomeIcon
                  data-tip={user && user.language.includes("fr") ? TAX_AND_FEES_FR : TAX_AND_FEES}
                  data-event="click focus"
                  style={{ cursor: "pointer" }}
                  icon={faQuestionCircle}
                />
              </span>
              <ReactTooltip
                place="top"
                type="light"
                effect="float"
                border
                borderColor="black"
                globalEventOff="click"
                className="tooltip"
              />
            </td>
            <td className="secondary-color" style={{ textAlign: "right" }}>
              {numberFormatToCurrency(
                order.estimated_total - order.estimated_total_pre_tax + order.transaction_fee
              )}
            </td>
          </tr>
        ]}
        {order.combined_sales_tax_from_service > 0 &&
          order.transaction_fee === 0 && [
            <tr key={`ost3-${order.id}`}>
              <td className="secondary-font">{t("estimatedTaxLbl")}</td>
              <td className="secondary-color" style={{ textAlign: "right" }}>
                {numberFormatToCurrency(order.estimated_total - order.estimated_total_pre_tax)}
              </td>
            </tr>
          ]}
      </React.Fragment>
    )
  }
}

export class OrderBottomLinePricing extends PureComponent {
  totalPriceText = (order, shop) => {
    if (order.any_order_service_lacks_price) {
      return this.props.t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(order.estimated_total)
    }
  }

  static propTypes = {
    order: PropTypes.object.isRequired,
    shop: PropTypes.object,
    isFixedPrice: PropTypes.bool
  }

  render() {
    const { shop = {}, isFixedPrice, order = {}, t, showSavingsRibbon } = this.props
    const estimatedPricingStatuses = [ACTIVE, INITIATED_BY_USER]
    const company = shop.company || order.company || (order.shop || {}).company || {}
    const pricingData = pricingDataForOrder(order, company, t)
    const savingsText =
      order && order.services_retail_savings
        ? order.services_retail_savings
        : order && order.total_retail_savings

    return (
      <React.Fragment>
        <tr style={{ fontWeight: "550" }} className="confident-blue" key={`oep-${order.id}`}>
          <td className="secondary-font">{t("estimatedTotalLbl")}</td>
          <td style={{ textAlign: "right" }}>{this.totalPriceText(order, shop)}</td>
        </tr>
        {order.total_savings != null &&
          parseFloat(order.total_savings) !== 0 &&
          estimatedPricingStatuses.includes(order.status) && (
            <tr className="optimistic-gold">
              <td
                className="secondary-font"
                style={{ "margin-top": "10px", fontWeight: "550", "letter-spacing": "0.5px" }}
              >
                {t("totalSavingsLbl")}
              </td>
              <td style={{ textAlign: "right" }}>{numberFormatToCurrency(order.total_savings)}</td>
            </tr>
          )}
        <tr style={{ textAlign: "right" }}>
          <td colspan="2">
            {showSavingsRibbon && shop && !shop.cdk_dealer && (
              <SavingsRibbon
                savingsText={savingsText}
                containerStyle={{ marginTop: "5px", marginRight: "-37px", paddingBottom: "10px" }}
                ribbonStyle={{ width: "60%" }}
              />
            )}
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

export function HRRow() {
  return (
    <tr>
      <td style={{ paddingRight: "0px" }}>
        <hr />
      </td>
      <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <hr />
      </td>
      <td style={{ paddingLeft: "0px" }}>
        <hr />
      </td>
    </tr>
  )
}
class ReviewTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prePayTreatment: ""
    }
  }

  async componentDidMount() {
    const user = this.props.user
    const prePayConsumerTreatment = await SplitClient.getTreatment("pre-pay-consumer", user)
    await this.setState({ prePayTreatment: prePayConsumerTreatment })
  }

  static propTypes = {
    order: PropTypes.object.isRequired,
    shop: PropTypes.object,
    user: PropTypes.object,
    removable: PropTypes.bool,
    addable: PropTypes.bool,
    displayPrices: PropTypes.bool.isRequired,
    isFixedPrice: PropTypes.bool,
    showSavingsRibbon: PropTypes.bool
  }

  static defaultProps = {
    displayPrices: true,
    showSavingsRibbon: true
  }

  prepayDataFromCheckboxToReview = (data) => {
    this.props.prepayDataFromReviewToSummary(data)
  }

  render() {
    const {
      order,
      removable,
      addable,
      isFixedPrice,
      displayPrices,
      shop,
      user,
      t,
      showSavingsRibbon
    } = this.props

    let { isCart } = this.props
    const is_prepay = this.state.prePayTreatment == "on"
    const confirm_url_match = window.location.hash == "#/carts/" + order.id + "/confirm"
    return (
      <div className="review-table">
        <table cellPadding="5" cellSpacing="0" style={{ marginLeft: "-5px", width: "100%" }}>
          <tbody>
            {(order.order_services || []).map((os, i) => {
              return (
                <OrderServicePriceRow
                  key={i}
                  displayPlus={addable}
                  displayPrices={displayPrices}
                  order={order}
                  displayMinus={removable}
                  pe={os.price_estimate}
                  os={os}
                  name={os.name}
                  shop={shop}
                />
              )
            })}
            {displayPrices && shop && !shop.cdk_dealer && (
              <React.Fragment>
                <HRRow />
                <OrderTopLevelPricing
                  order={order}
                  isFixedPrice={isFixedPrice}
                  shop={shop}
                  t={t}
                  user={user}
                />
                <HRRow />
                <OrderBottomLinePricing
                  order={order}
                  isFixedPrice={isFixedPrice}
                  shop={shop}
                  t={t}
                  showSavingsRibbon={showSavingsRibbon}
                />
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
function mapStateToProps(state) {
  const user = state.user || {}
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    isCart = false
  } else {
    isCart = true
  }

  return {
    isCart,
    activeVehicle
  }
}

export default connect(mapStateToProps, { updateCart })(
  withTranslation(["reviewTable", "common"])(ReviewTable)
)
