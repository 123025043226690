import React from "react"
import FullPageLoader from "./FullPageLoader"

export default function ContentLoader(props) {
  return (
    <div className="content-loading-container">
      <FullPageLoader />
    </div>
  )
}
