import React from "react"

const RewardUsedTile = ({ title, value }) => {
  return (
    <div className="reward-used-tile">
      <p className="reward-tile-title">{title}</p>
      <p className="reward-tile-value">{value}</p>
    </div>
  )
}

export default RewardUsedTile
