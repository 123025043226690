import { isMobile } from "react-device-detect"
import {
  END_PRICE,
  FILTER_NAME_FORMAT,
  PRICE_SORT,
  START_PRICE,
  TIRE_FILTER_CHECKBOX,
  TIRE_FILTER_RANGE,
  TIRE_FILTER_SORTING
} from "../../v1/constants/tires"

/**
 * Transforms a given string into a capitalized format.
 *
 * @param {string} input - The input string to transform.
 * @returns {string} - The transformed string.
 *
 * @example
 * // Returns 'Brand'
 * transformString('brand');
 *
 * @example
 * // Returns 'Hello World'
 * transformString('hello_world');
 */
export const transformString = (input) => {
  return input
    .split(FILTER_NAME_FORMAT) // Split the string by hyphens and underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" ") // Join the words with a space
}

/**
 * Converts selected filters to a query string for use in a URL.
 *
 * @param {Object} filters - An object representing selected filters.
 * @returns {string} - A URL string with the query parameters.
 *
 * Example of the filters object:
 * {
 *   speed_rating: { checkbox: ['V', 'H'] },
 *   load_index: { checkbox: [1, 2] },
 *   price: { range: { min: 100, max: 500 } },
 *   sort: { sorting: 'desc' }
 * }
 */
export const prepareFilterAPIUrl = (filters) => {
  const queryParams = []

  // Iterate over each filter type and its values
  for (const filterType in filters) {
    if (filters.hasOwnProperty(filterType)) {
      const filterValues = filters[filterType]

      if (filterValues.checkbox && filterValues.checkbox.length > 0) {
        // Add each checkbox value as a separate query parameter
        filterValues.checkbox.forEach((value) => {
          queryParams.push(`${encodeURIComponent(filterType)}[]=${encodeURIComponent(value)}`)
        })
      }

      if (filterValues.range) {
        if (filterValues.range.start_price !== undefined) {
          queryParams.push(`${START_PRICE}=${encodeURIComponent(filterValues.range.start_price)}`)
        }
        if (filterValues.range.end_price !== undefined) {
          queryParams.push(`${END_PRICE}=${encodeURIComponent(filterValues.range.end_price)}`)
        }
      }

      if (filterValues.sorting) {
        queryParams.push(`${PRICE_SORT}=${encodeURIComponent(filterValues.sorting)}`)
      }
    }
  }

  return queryParams.join("&")
}

/**
 * Manages tire filter state updates and triggers data fetching.
 *
 * This function updates the `tireSelectedFilters` state based on the selected filter's type and category,
 * and optionally triggers an API call to fetch filtered data. It supports checkbox, range, and sorting filters.
 *
 * @param {Object} params - Parameters for managing the tire filters.
 * @param {Object} params.selectedFilter - The filter data selected by the user.
 * @param {string} params.filterType - The type of filter being applied (e.g., 'speed_rating', 'load_index').
 * @param {string} params.filterCategory - The category of the filter (e.g., 'TIRE_FILTER_CHECKBOX', 'TIRE_FILTER_RANGE', 'TIRE_FILTER_SORTING').
 * @param {Function} params.setTireSelectedFilters - Function to update the state of selected filters.
 * @param {Function} params.fetchTireData - Function to fetch tire data based on the updated filters.
 *
 * @returns {Object} The updated filter state.
 */
export const manageTireFilters = ({
  selectedFilter,
  filterType,
  filterCategory,
  setTireSelectedFilters,
  fetchTireData
}) => {
  setTireSelectedFilters((prevFilters) => {
    const currentFilters = prevFilters[filterType] || []
    let updatedFilters

    switch (filterCategory) {
      case TIRE_FILTER_CHECKBOX:
        const checkboxFilters = currentFilters.checkbox || []
        if (selectedFilter.checked) {
          updatedFilters = {
            ...prevFilters,
            [filterType]: {
              ...currentFilters,
              checkbox: [...checkboxFilters, selectedFilter.label]
            }
          }
        } else {
          updatedFilters = {
            ...prevFilters,
            [filterType]: {
              ...currentFilters,
              checkbox: checkboxFilters.filter((label) => label !== selectedFilter.label)
            }
          }
        }
        break

      case TIRE_FILTER_RANGE:
        updatedFilters = {
          ...prevFilters,
          [filterType]: {
            ...currentFilters,
            range: {
              start_price: selectedFilter.min,
              end_price: selectedFilter.max
            }
          }
        }
        break

      case TIRE_FILTER_SORTING:
        updatedFilters = {
          ...prevFilters,
          [filterType]: {
            ...currentFilters,
            sorting: selectedFilter.value
          }
        }
        break

      default:
        updatedFilters = prevFilters
        break
    }

    // Trigger API call immediately if on desktop
    if (!isMobile) {
      fetchTireData(updatedFilters)
    }

    return updatedFilters
  })
}

/**
 * Checks if all filters are empty.
 * @param {Object} filters - The filters object to check.
 * @returns {boolean} - Returns true if all filters are empty, false otherwise.
 */
export const areTireFiltersEmpty = (filters) => {
  return !Object.values(filters).some(
    (filter) =>
      (filter.checkbox && filter.checkbox.length > 0) ||
      (filter.range && (filter.range.start_price || filter.range.end_price)) ||
      (filter.sorting && filter.sorting)
  )
}

export const shouldApplyFilterRendered = (selectedFilters) => {
  return Object.values(selectedFilters).some(
    (filter) =>
      (filter.checkbox && filter.checkbox.length > 0) ||
      (filter.range && (filter.range.start_price || filter.range.end_price))
    //  ||  (filter.sorting && filter.sorting) If want to consider sorting filter as an part of applied filter then uncomment this line
  )
}
