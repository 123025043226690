import { has, isNil, omit, omitBy } from "lodash"
import { isCurrentlyDue, isPastDue, isUpcoming } from "./serviceHelper"
import { getActiveVehicle, getVehicleName } from "./vehicleHelpers"
import i18n from "../../shared_component/utils/i18n"
import store from "../../shared_component/utils/configureStore"
import { browserName } from "react-device-detect"
import { getShopAddress, getShopName } from "./shopHelpers"
import { getActiveCart } from "./cartHelpers"
import { getActiveOrder, isWalkIn } from "./orderHelpers"
import { startCase } from "lodash"
import { getServiceNameForMap } from "./serviceHelper"
import { CARADVISE } from "../constants/affiliations"
import { getPaymentMethodName } from "./paymentHelpers"
import moment from "moment"
import { getCurrencyCode, numberFormatToCurrency } from "../../shared_component/utils/currency"
import { getCurrentUser } from "./userHelper"

export const getDateFormat = () => "MM-DD-YYYY"

export const formatDate = (date = "", format = "") => {
  return moment(date).format(format || getDateFormat())
}

export const getVehicleProps = (
  vehicle = {},
  additionalInfo = { shouldAddCarsAdded: true, shouldRemoveNullKeys: false, omitIsDefault: false }
) => {
  const activeVehicle = getActiveVehicle()
  let vehicleProps = {}

  if (additionalInfo.shouldAddCarsAdded) vehicleProps.carsAdded = 1

  if (typeof vehicle === "object" && vehicle !== null && vehicle !== undefined) {
    vehicleProps.vehicleYear = vehicle.year || null

    vehicleProps.vehicleModel = vehicle.model || null

    vehicleProps.vehicleSubModel = vehicle.sub_model || null

    vehicleProps.vehicleMake = vehicle.make || null

    vehicleProps.vin = vehicle.vin || null

    vehicleProps.vehicleEngine = vehicle.engine_description || null

    vehicleProps.vehicleImage = vehicle.dashboard_mobile_url || vehicle.dashboard_url || null

    vehicleProps.miles =
      parseInt(vehicle.odometer_config && vehicle.odometer_config.quantity) || null

    vehicleProps.estimatedCurrentMileage =
      (vehicle.predicted_current_odometer_config &&
        parseInt(vehicle.predicted_current_odometer_config.quantity)) ||
      null

    vehicleProps.lastMaintenanceDate =
      (vehicle.last_maintenance_date && formatDate(vehicle.last_maintenance_date)) || null

    vehicleProps.nextMaintenanceDate =
      (vehicle.next_maintenance_date && formatDate(vehicle.next_maintenance_date)) || null

    if (
      vehicle &&
      activeVehicle &&
      vehicle.id === activeVehicle.id &&
      vehicle.maintenanceSchedules &&
      vehicle.maintenanceSchedules.length > 0
    ) {
      vehicleProps = {
        ...vehicleProps,
        ...getMaintenanceScheduleProps(vehicle)
      }
    } else {
      vehicleProps.currentlyDueServices = null
      vehicleProps.pastDueServices = null
      vehicleProps.upcomingServices = null
    }

    vehicleProps.estimatedMilesPerMonth =
      (vehicle.odometer_per_month_config && parseInt(vehicle.odometer_per_month_config.quantity)) ||
      null

    vehicleProps.licensePlateNumber = vehicle.license_plate_number || null

    vehicleProps.licensePlateState = vehicle.license_plate_state || null

    vehicleProps.vehicleId = (vehicle.id && parseInt(vehicle.id)) || null

    if (!additionalInfo.omitIsDefault)
      vehicleProps.isDefault =
        activeVehicle && activeVehicle.id && vehicle.id === activeVehicle.id ? "Yes" : "No" || null

    vehicleProps.lastRecordedMileage =
      (vehicle.odometer_config && parseInt(vehicle.odometer_config.quantity)) || null

    vehicleProps.vehicleName = getVehicleName(vehicle) || null
  }

  // if we want to remove null or undefined keys
  if (additionalInfo.shouldRemoveNullKeys) {
    vehicleProps = omitBy(vehicleProps, isNil)
  }

  return vehicleProps
}

export const getMaintenanceScheduleProps = (vehicle) => {
  let maintenanceSchedule = {}

  const transformServices = (maintenanceSchedule) => {
    if (maintenanceSchedule.length > 0) {
      return maintenanceSchedule.map(({ services, interval }) => ({
        interval: interval ? `${interval.toLocaleString()}` : null,
        services: services.map((service) => ({
          name: service.name,
          description: service.details.one_liner
        }))
      }))
    }
    return null
  }

  if (vehicle && has(vehicle, "maintenanceSchedules[0]")) {
    maintenanceSchedule = {
      pastDueServices: transformServices(
        vehicle.maintenanceSchedules.filter(({ interval }) => isPastDue(interval, vehicle))
      ),
      currentlyDueServices: transformServices(
        vehicle.maintenanceSchedules.filter(({ interval }) => isCurrentlyDue(interval, vehicle))
      ),
      upcomingServices: transformServices(
        vehicle.maintenanceSchedules.filter(({ interval }) => isUpcoming(interval, vehicle))
      )
    }
  }
  return maintenanceSchedule
}

export const getEventNameByKey = (key) => {
  const state = store.getState()
  if (state && state.segment && state.segment.eventNames && state.segment.eventNames[key]) {
    return state.segment.eventNames[key]
  }
  return null
}

export const getDeviceProp = () => ({ device: `Web - ${browserName.toLowerCase()}` })

export const getBookingAppointmentDay = (appointmentDateTime) =>
  moment(appointmentDateTime, "YYYY-MM-DD HH:mm:ss").format("dddd")

export const getBookingAppointmentDateTime = (appointmentDateTime) =>
  moment(appointmentDateTime).subtract(2, "hours").toISOString()

export const getAdditionalProps = () => {
  const props = {}
  const activeCart = getActiveCart()
  const activeOrder = getActiveOrder()
  const activeVehicle = getActiveVehicle()
  const activeVehicleName = getVehicleName(activeVehicle)
  const activeCartOrOrder = activeCart
  const shopName = getShopName({
    shop: activeCartOrOrder ? activeCartOrOrder.shop : null,
    company:
      activeCart && activeCart.shop && activeCart.shop.company
        ? activeCart.shop.company
        : activeOrder && activeOrder.company
        ? activeOrder.company
        : null
  })
  if (activeCart) {
    if (shopName) props.shopName = shopName

    if (activeCart && activeCart.id) props.cartId = parseInt(activeCart.id)

    if (
      activeCartOrOrder &&
      activeCartOrOrder.order_services &&
      activeCartOrOrder.order_services.length > 0
    )
      props.orderServices = activeCartOrOrder.order_services.map(getServiceNameForMap)

    if (activeCartOrOrder && activeCartOrOrder.shop)
      props.shopAddress = getShopAddress(activeCartOrOrder.shop)

    if (activeVehicle && activeVehicle.id) props.vehicleId = parseInt(activeVehicle.id)

    if (activeVehicleName) {
      props.vehicleName = activeVehicleName
    }

    if (activeCartOrOrder && activeCartOrOrder.shop && activeCartOrOrder.shop.id)
      props.shopId = parseInt(activeCartOrOrder.shop.id)

    if (activeCartOrOrder && activeCartOrOrder.appointment_time_pretty)
      props.walkIn =
        (activeCartOrOrder && activeCartOrOrder.is_walk_in) || isWalkIn(activeCartOrOrder)
          ? "Yes"
          : "No"

    if (activeCartOrOrder && activeCartOrOrder.appointment_datetime) {
      props.bookingAppointmentDate = getBookingAppointmentDateTime(
        activeCartOrOrder.appointment_datetime
      )
    }

    if (activeCartOrOrder && activeCartOrOrder.appointment_datetime) {
      props.bookingAppointmentDay = getBookingAppointmentDay(activeCartOrOrder.appointment_datetime)
    }

    if (activeCartOrOrder && activeCartOrOrder.appointment_time_pretty)
      props.bookingAppointmentTime =
        (activeCartOrOrder && activeCartOrOrder.is_walk_in) || isWalkIn(activeCartOrOrder)
          ? "Walk In"
          : activeCartOrOrder.appointment_time_pretty

    if (getPaymentMethodName()) {
      props.paymentMode = getPaymentMethodName()
    }

    if (activeCart && activeCart.estimated_total) {
      props.revenue = activeCart.estimated_total
      props.currency = getCurrencyCode(i18n.language)
    }
    props.yourTotal =
      (activeCart.estimated_total && numberFormatToCurrency(activeCart.estimated_total)) || 0
  }

  return props
}

// this function is used to extract hostname(uber, instacart, caradvise etc) from users base_url object
const getSubDomainFromCustomerUrl = (customerBaseUrl = "") => {
  if (typeof customerBaseUrl === "string") {
    customerBaseUrl = customerBaseUrl.replace(/.+\/\/|www.|\..+/g, "")
  }
  if (customerBaseUrl) {
    customerBaseUrl = customerBaseUrl.replace(/[^a-zA-Z]/g, " ")
  }
  if (typeof customerBaseUrl === "string" && customerBaseUrl.toLowerCase() === "app") {
    customerBaseUrl = CARADVISE
  }
  return startCase(customerBaseUrl)
}

export const getSignInOrSignUpMethod = (type = "", customerBaseUrl = "", key = "") => {
  customerBaseUrl = getSubDomainFromCustomerUrl(customerBaseUrl)
  let isSSO = false
  type = type === "signin" ? "Signin" : "Signup"
  if (customerBaseUrl && customerBaseUrl.toLowerCase() === "uber" && key) {
    isSSO = true
  } else {
    isSSO = false
  }
  return isSSO ? `SSO ${type}` : `Email ${type}`
}

export const getUserType = (customerBaseUrl = "") => {
  return `${getSubDomainFromCustomerUrl(customerBaseUrl)} User`
}

export const removeGenericEmail = (payload = {}) => {
  const user = getCurrentUser()
  if (user.generic) {
    payload = omit(payload, ["email"])
  }
  return payload
}

export const getAdditionalPartnerOfferProps = () => {
  const props = {}
  const activeVehicle = getActiveVehicle()
  const activeVehicleName = getVehicleName(activeVehicle)

  if (activeVehicle) {
    props.vin = activeVehicle.vin
    props.miles = activeVehicle.miles
    props.vehicleName = activeVehicleName
    props.vehicleId = activeVehicle.id
  }

  return props
}
