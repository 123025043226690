import React, { Component } from "react"
import { connect } from "react-redux"
import { setupSplitFlags } from "../Config/SplitClient"
import CartButtons from "./CartButtons"
import CartHeader from "./CartHeader"
import ShopInformation from "./ShopInformation"
import TimeInformation from "./TimeInformation"
import Summary from "./Summary"
import { nextMembershipUpgrades, maxPercentDiscountDiff } from "../../helpers/membershipHelpers"
import { preMembershipDiscountSubtotal } from "../../helpers/cartHelpers"
import MembershipUpgradeModal from "../../components/Membership/MembershipUpgradeModal"
import { constants } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../actions/user"
import store from "../../../shared_component/utils/configureStore"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"

const {
  orders: { SEEKING_QUOTE }
} = constants

class CartPanel extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    path: window.location.hash,
    membershipModalOpen: false
  }

  componentDidMount() {
    // Used for flags transactionFee, isFixedPrice
    setupSplitFlags.bind(this)()
  }

  getOrderLikeObject() {
    const { order, cart } = this.props
    let orderLikeObject
    if (order) orderLikeObject = order
    else if (cart) orderLikeObject = cart
    return orderLikeObject
  }

  prepayDataFromSummaryToCartPanel = (data) => {
    store.dispatch(updateCart(data, this.props.activeVehicle.id, null, true))
    if (this.props.prepayDataFromCartPanelToOrder != null)
      this.props.prepayDataFromCartPanelToOrder(data)
  }

  showUpgradeOrComplete = () => {
    trackEvent("request-appointment-clicked")
    const { user, nextUpgrades } = this.props
    const percentDiscount = maxPercentDiscountDiff(user.membership, nextUpgrades)

    if (percentDiscount && percentDiscount > 0) {
      this.toggleMembershipUpgradeModal()
    } else {
      this.props.onComplete()
    }
  }

  toggleMembershipUpgradeModal = () => {
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  render() {
    const { confirmEnabled, user, cart, isCart, onComplete, orderLoading, t } = this.props
    const { transactionFee, isFixedPrice, membershipModalOpen, payAheadTreatment } = this.state
    const orderLikeObject = this.getOrderLikeObject()

    if (!orderLikeObject) return null

    const shopLikeObject = orderLikeObject.shop || orderLikeObject.shop_info
    const overriddenIsFixedPrice = orderLikeObject.is_fixed_price || isFixedPrice

    return (
      <div className="dashLeft cart-panel">
        <CartHeader
          orderLikeObject={orderLikeObject}
          shopLikeObject={shopLikeObject}
          isFixedPrice={overriddenIsFixedPrice}
          cart={cart}
          isCart={isCart}
          user={user}
        />
        <div className="cart-summary">
          <Summary
            orderLikeObject={orderLikeObject}
            shopLikeObject={shopLikeObject}
            isFixedPrice={overriddenIsFixedPrice}
            prepayDataFromSummaryToCartPanel={this.prepayDataFromSummaryToCartPanel}
            checkboxDisabled={this.props.checkboxDisabled}
            user={user}
            payAheadTreatment={payAheadTreatment}
          />
          <ShopInformation
            orderLikeObject={orderLikeObject}
            shopLikeObject={shopLikeObject}
            user={user}
          />
          {shopLikeObject && (
            <TimeInformation
              orderLikeObject={orderLikeObject}
              user={user}
              orderLoading={orderLoading}
            />
          )}
          <CartButtons
            confirmEnabled={confirmEnabled}
            onComplete={this.showUpgradeOrComplete}
            orderLikeObject={orderLikeObject}
            shopLikeObject={shopLikeObject}
            cancelAppointment={this.cancelAppointment}
            path={this.state.path}
          />

          {membershipModalOpen && ( // wrap with the attr here to reset the modal when re-opened
            <MembershipUpgradeModal
              isOpen={membershipModalOpen}
              closeModal={this.toggleMembershipUpgradeModal}
              variableContentType="checkoutSection"
              preMembershipDiscountSubtotal={preMembershipDiscountSubtotal(orderLikeObject)}
              altButtonText={t("noThanksContinueLbl")}
              altButtonOnClick={onComplete}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let { membershipData } = state
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    orderLoading: state.orders.loading,
    nextUpgrades: nextMembershipUpgrades(user, membershipData.availableMembershipPlans)
  }
}

export default connect(mapStateToProps, { updateCart })(withTranslation("cartPanel")(CartPanel))
