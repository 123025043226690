import React, { Component, PureComponent } from "react"
import { Image } from "semantic-ui-react"
import ModalDialog from "../ModalDialog"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../actions/user"
import payAheadActivate from "../../../images/pay-ahead-activate.svg"
import payLaterActivate from "../../../images/pay-later-activate.svg"
import payLaterDeactivate from "../../../images/pay-later-deactivate.svg"
import payAheadDeactivate from "../../../images/pay-ahead-deactivate.svg"
import ReactSVG from "react-svg"

export class PrepayCheckbox extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { prepay: this.props.prepay }
  }

  handleInputChange = (pay_ahead) => () => {
    const value = pay_ahead
    this.setState({ prepay: value })

    this.props.prepayDataFromCheckboxToReview(value)
    this.setState({ prepay: value }, () => {})
  }

  prePayLearnmore = () => {
    const { t } = this.props
    return (
      <ModalDialog
        customElement={
          <span className="primary-color" style={{ textDecoration: "underline" }}>
            Learn more
          </span>
        }
      >
        <span className="caradvise-electric-blue" style={{ paddingLeft: "5px" }}>
          <h2 className="primary-font confident-blue"> {t("prePayModalHeader")} </h2>
        </span>
        <span className="caradvise-electric-blue" style={{ paddingLeft: "5px" }}>
          <strong> {t("prePayModalSubHeader1")} </strong>
        </span>
        <p>{t("prePayTestLine1")}</p>
        <span className="caradvise-electric-blue" style={{ paddingLeft: "5px" }}>
          <strong> {t("prePayModalSubHeader2")} </strong>
        </span>
        <p>{t("prePayTestLine2")}</p>
        <span className="caradvise-electric-blue" style={{ paddingLeft: "5px" }}>
          <strong> {t("prePayModalSubHeader3")} </strong>
        </span>
        <p>{t("prePayTestLine3")}</p>
      </ModalDialog>
    )
  }

  render() {
    const { t, prepay, force_prepay, order, isCart } = this.props

    return (
      <React.Fragment>
        <div style={{ display: "inline-flex", paddingTop: "16px" }}>
          <div style={{ textAlign: "center" }}>
            <div className="paylater-payahead-text">Pay Later</div>
            <div
              className={prepay ? "prepay-deactivate-box" : "prepay-active-box"}
              onClick={this.handleInputChange(false)}
            >
              <div className="prepay-icons">
                <ReactSVG src={prepay ? payLaterDeactivate : payLaterActivate} />
                <div className={prepay ? "prepay-deactivate-text" : "prepay-active-text"}>
                  <div>AFTER</div> <div>APPROVING WORK</div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: "62px 15px" }}>
            <span className="divider-paylater-payahead">OR</span>
          </div>

          <div style={{ textAlign: "center" }}>
            <div className="paylater-payahead-text">Pay Ahead</div>
            <div
              className={prepay ? "prepay-active-box" : "prepay-deactivate-box"}
              onClick={this.handleInputChange(true)}
            >
              <div className="prepay-icons">
                <ReactSVG src={prepay ? payAheadActivate : payAheadDeactivate} />
                <div
                  className={prepay ? "prepay-active-text" : "prepay-deactivate-text"}
                  style={{ paddingTop: "3px" }}
                >
                  <div>GET 5% OFF & $5 </div> <div>CARADVISE CASH</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {prepay ? (
            <div className="prepay-dynamic-text">
              When you Pay Ahead, pay for services now and get 5% off, and $5 in CarAdvise Cash
              later.
            </div>
          ) : (
            <div className="prepay-dynamic-text">
              By <span style={{ fontWeight: "500" }}>Paying Later</span>, the transaction is
              processed after <span style={{ textDecoration: "underline" }}>ALL</span> service is
              completed.
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    isCart = false
  } else {
    isCart = true
  }

  return {
    isCart,
    activeVehicle
  }
}

export default connect(mapStateToProps, { updateCart })(
  withTranslation(["reviewTable", "common"])(PrepayCheckbox)
)
