export const USER_REWARDS = "USER_REWARDS"
export const USER_REDEEM_MILE = "USER_REDEEM_MILE"
export const REWARD_SPLIT_FLAG = "REWARD_SPLIT_FLAG"
export const RESET_REWARDS = "RESET_REWARDS"
export const SET_REWARDS_DATA = "SET_REWARDS_DATA"
export const SET_REWARDS_EARNED_MILES = "SET_REWARDS_EARNED_MILES"
export const SET_REWARDS_UNLOCK_MILE = "SET_REWARDS_UNLOCK_MILE"
export const SET_MILE_ON_FIRST_MAINTENANCE = "SET_MILE_ON_FIRST_MAINTENANCE"
export const RESET_MILES_REWARDS = "RESET_MILES_REWARDS"
export const SET_SERVICE_REMOVING_FLAG = "SET_SERVICE_REMOVING_FLAG"

export default function rewards(state = {}, action = {}) {
  switch (action.type) {
    case USER_REWARDS:
      return { ...state }
    case USER_REDEEM_MILE:
      return { ...state, ...action.payload }
    case REWARD_SPLIT_FLAG:
      return { ...state, isRewardsAvailable: window.splitClientFlagCache.isRewardsFeature }
    case RESET_REWARDS:
      state = {}
      return { ...state }
    case SET_REWARDS_DATA:
      return { ...state, userRewards: action.payload.user_rewards || [] }
    case SET_REWARDS_EARNED_MILES:
      const { earned_miles, burned_miles, pending_miles, miles_per_dollar } = action.payload
      return {
        ...state,
        earnedMile: earned_miles,
        burnedMiles: burned_miles,
        pendingMiles: pending_miles,
        milesPerDollar: miles_per_dollar
      }
    case SET_REWARDS_UNLOCK_MILE:
      const lite_vehicle = action.payload.lite_vehicle
      const { points_to_unlock } = lite_vehicle
      return { ...state, milesToUnlock: points_to_unlock }
    case SET_MILE_ON_FIRST_MAINTENANCE:
      return { ...state, estimateEarnMiles: action.payload.user_reward.miles }
    case RESET_MILES_REWARDS:
      state = { estimateEarnMiles: state.estimateEarnMiles }
      return { ...state }
    case SET_SERVICE_REMOVING_FLAG:
      return { ...state, isServiceRemoving: action.payload }
    default:
      return state
  }
}
