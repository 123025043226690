import React from "react"
import { Divider, Select } from "semantic-ui-react"
import TireFilterSection from "../TireFilterSection"
import { isEmpty, max, min } from "lodash"
import { useTranslation } from "react-i18next"
import { isMobile, isTablet } from "react-device-detect"
import TireFilterRange from "../TireFilterRange"
import {
  CONTACT_NUMBER,
  TIRE_FILTER_PRICE,
  TIRE_FILTER_SORTING,
  TIRE_SORTING_FILTER_OPTIONS
} from "../../../constants/tires"
import ReactSVG from "react-svg"
import FilterDownLogo from "../../../../images/filter-down.svg"
import InfoLogo from "../../../../images/info-strock.svg"

const TireFilterAndSearch360 = ({ filters, handleSelectedFilter, tireSelectedFilters, tires }) => {
  const { t } = useTranslation("tireFilters")

  const priceFilterOptions = filters[TIRE_FILTER_PRICE]

  const handleSelectChange = (event, { value }) => {
    handleSelectedFilter({ value }, TIRE_FILTER_PRICE, TIRE_FILTER_SORTING)
  }

  return (
    <div className="tire__filter">
      {!isMobile && !isTablet && <p className="tire__filter-header">{t("filterAndShort")}</p>}
      <div>
        <p className="tire__filter_sort-label">{t("sortBy")}</p>
        <Select
          className="tire__filter-select"
          placeholder={t("sortingPlaceholder")}
          options={TIRE_SORTING_FILTER_OPTIONS}
          onChange={handleSelectChange}
          value={
            tireSelectedFilters &&
            tireSelectedFilters[TIRE_FILTER_PRICE] &&
            tireSelectedFilters[TIRE_FILTER_PRICE][TIRE_FILTER_SORTING]
              ? tireSelectedFilters[TIRE_FILTER_PRICE][TIRE_FILTER_SORTING]
              : ""
          }
          disabled={isEmpty(tires)}
          icon={<ReactSVG wrapper="span" className="tire__filter-dropdown" src={FilterDownLogo} />}
        />
      </div>
      <Divider />

      {isEmpty(filters) ? (
        <div className="no__filter">
          <ReactSVG className="tire__info-logo" src={InfoLogo} />
          <p>{t("noFilterLabel")}</p>
        </div>
      ) : (
        <>
          <div>
            <p className="tire__filter_price-label">
              {t("priceRange")} <span>{t("perTire")}</span>
            </p>
            {!isEmpty(priceFilterOptions) && (
              <TireFilterRange
                max={max(priceFilterOptions)}
                handleSelectedFilter={handleSelectedFilter}
                min={min(priceFilterOptions)}
                tireSelectedFilters={tireSelectedFilters}
              />
            )}
          </div>
          <Divider />
          {!isEmpty(filters) &&
            Object.entries(filters).map(
              ([filterKey, filterData]) =>
                filterKey !== TIRE_FILTER_PRICE && (
                  <>
                    <div className="filter__padding" key={filterKey}>
                      <TireFilterSection
                        handleSelectedFilter={handleSelectedFilter}
                        filterName={filterKey}
                        filterData={filterData}
                        tireSelectedFilters={tireSelectedFilters}
                      />
                    </div>
                    <Divider className="margin__0" />
                  </>
                )
            )}
        </>
      )}

      <div className="have__any-questions">
        <p className="section__label">{t("haveAnyQuestions")}</p>
        <p className="section__sub-label">{t("canHelp")}</p>
        <a href={`tel:${CONTACT_NUMBER}`} className="contanct__number">
          {CONTACT_NUMBER}
        </a>
      </div>
    </div>
  )
}

export default TireFilterAndSearch360
