import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

const WelcomeCard = (props) => {
  const { user, t } = props
  return (
    <div className="dashboard_welcome_card">
      <p style={{ textAlign: "center" }}>
        {t("welcome")}{" "}
        {user && !user.generic
          ? `${!_.isEmpty(user.firstName) ? user.firstName.toUpperCase() : ""} ${
              !_.isEmpty(user.lastName) ? user.lastName.toUpperCase() : ""
            }`
          : ""}
      </p>
      <h1>{t("letsBook")}</h1>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user }
}

export default connect(mapStateToProps)(withTranslation("dashboardWelcomeCard")(WelcomeCard))
