import React from "react"
import CA_Logo from "../../images/CA-Logo.png"
import "../../css/component_specific/redirect-warning.scss"
import { Link } from "react-router-dom"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation } from "react-i18next"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"

function RedirectWarning({ location, t, history }) {
  const redirectURL = location && location.state && location.state.url

  const handleLinkClicked = () => {
    history.push("/WarrantyPlan")
  }

  return (
    <div className="quote-section">
      <div className="quote-description">
        <img src={CA_Logo} />
        <span>{t("Buckle_up")}</span>
        <p>{t("warning_desc")}</p>
        <a href={redirectURL} target="_blank" onClick={handleLinkClicked}>
          {redirectURL ? redirectURL : t("Please_select_protection_plan")}
        </a>
        <br />
        <br />
        <Link to="/WarrantyPlan" className="back-btn">
          <FontAwesomeIcon icon={faArrowLeft} />
          {"  "}
          {t("Go_back")}
        </Link>
      </div>
    </div>
  )
}

export default withTranslation("redirectWarning")(RedirectWarning)
