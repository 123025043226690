export const POPULAR_SERVICES = "Popular Services"
export const MAINTENANCE_SERVICES = "Maintenance Schedules"
export const BY_CATEGORY = "By Category"

export const RATING_TAG = "rating"

export const PAID_INDEPENDENT = "paidIndependent"
export const UNPAID_INDEPENDENT = "unpaidIndependent"

export const LIST_VIEW = "listView"
export const MAP_VIEW = "mapView"
export const DISTANCE = "distance"
export const PRICE = "price"

export const ACTIVE_VIEWS = Object.freeze({
  LIST: "list",
  MAP: "map"
})

export const FILTERS = Object.freeze({
  POPULAR_SERVICES: "Popular Services",
  MAINTENANCE_SERVICES: "Maintenance Schedules",
  BY_CATEGORY: "By Category",
  ALL: "All",
  COMMON_SERVICES: "Common Services"
})

export const INITIATED = "initiated"
export const UNDEFINED = "undefined"
