import { isEmpty } from "lodash"
import { getJSON, putJSON } from "../../../shared_component/utils/fetch"
import { setUserError } from "../../actions/user"

export const CARADVISE_RECOMMENDED_URL = "/api/v4/services/recommended/caradvise"

export const CARADVISE_RECOMMENDED_SERVICES = "CARADVISE_RECOMMENDED_SERVICES"
const MARK_SERVICES_COMPLETED_URL = "/api/v4/service_based_reminders"
const MAINTENANCE_SCHEDULES_SERVICES_PRICING = "/api/v6/service_pricings"
function caradviseRecommendedServices(data) {
  return { type: CARADVISE_RECOMMENDED_SERVICES, payload: data }
}

export function fetchCarAdviseRecemendedServices(data) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const url = CARADVISE_RECOMMENDED_URL

    try {
      const headers = { Authorization: authentication_token }
      const response = await getJSON(url, data, headers)
      if (response.result) {
        const services = response.result
        const service_ids = services.map(({ service_id, ...rest }) => service_id)
        const pricingResponse =
          data.vehicleId &&
          !isEmpty(service_ids) &&
          (await dispatch(
            fetchServicesPricing({ vehicleId: data.vehicleId, service_ids: service_ids })
          ))
        const map = new Map()
        services.forEach((service) => {
          map.set(service.service_id, service)
        })
        pricingResponse.result.forEach((service) => {
          if (map.has(service.id)) {
            const existingService = map.get(service.id)
            Object.assign(existingService, service)
          }
        })
        return response
      } else {
        const error = response.error || response.errors
        return error
      }
    } catch (e) {
      return e
    }
  }
}

export function markServicesCompletedAPI(data) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const url = `${MARK_SERVICES_COMPLETED_URL}/${data.vehicle_id}`
    const body = {
      ...data,
      customer_id: user.id
    }

    try {
      const headers = { Authorization: authentication_token }
      const response = await putJSON(url, body, headers)
      if (response.result) {
        return response
      } else {
        const error = response.error || response.errors
        return error
      }
    } catch (e) {
      return e
    }
  }
}

export function fetchServicesPricing(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const { vehicleId, service_ids } = payload
    let cart = (user.active_carts || []).find((c) => c.vehicle_id == user.activeVehicleId) || {}
    let shopId = cart && cart.shop ? cart.shop.id : null
    try {
      if (authentication_token && vehicleId) {
        const request = {
          url: MAINTENANCE_SCHEDULES_SERVICES_PRICING,
          headers: { Authorization: authentication_token },
          body: {
            vehicle_id: vehicleId,
            service_ids: JSON.stringify(service_ids),
            shop_id: shopId
          }
        }
        const response = await getJSON(request.url, request.body, request.headers)

        if (response.result) {
          return response
        } else {
          const error = response.error || response.errors
          return error
        }
      }
    } catch (e) {
      return e
    }
  }
}
