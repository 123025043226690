import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { PAYMENT_METHODS } from "../../constants/application"

class PaymentMethodIcon extends Component {
  render() {
    const { paymentMethod } = this.props

    const logo =
      paymentMethod.type === PAYMENT_METHODS.CREDIT_CARD
        ? paymentMethod.card_type
        : PAYMENT_METHODS.PAYPAL
    let ptClassName = "paymentIcon"
    let unknownIcon = ""
    const { card_type } = paymentMethod

    switch (logo) {
      case "American Express":
        ptClassName += " iconAmex"
        break
      case "Diners Club":
        ptClassName += " iconDinersClub"
        break
      case "Discover":
        ptClassName += " iconDiscover"
        break
      case "MasterCard":
        ptClassName += " iconMastercard"
        break
      case "Visa":
        ptClassName += " iconVisa"
        break
      case "pay_pal_account":
        ptClassName += " iconPayPal"
        break
      default:
        unknownIcon = <span className="iconUnknown">{card_type}</span>
    }

    return (
      <ReactSVG
        src={`images/paymentLogo/${logo.replace(/\s+/g, "")}.svg`}
        svgClassName={ptClassName}
      />
    )
  }
} // PaymentMethodIcon

PaymentMethodIcon.propTypes = {
  paymentMethod: PropTypes.object.isRequired
}

export default PaymentMethodIcon
