import React, { useState } from "react"

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import { filteredShops, getPricingInfo } from "../../../../helpers/shopHelpers"

import MapPopUpComponent from "./MapPopUpComponent"

import MapWidget from "./MapWidget"
import Loading from "react-loading-overlay"
import ShopListViewItemComponent from "./ShopListViewItemComponent"
import { getNewTiresServiceFromServices } from "../../../../helpers/orderServiceHelpers"
import MapWidgetMobile from "./MapWidgetMobile"
import Geosuggest from "react-geosuggest"
import { suggestGeoSuggestClick } from "../../../helpers/geoLocationHelper"
import ShopListShimmer from "./ShopListShimmer"
import { OPEN_EXPANDED_MAP_VIEW } from "../../../../constants/shops"
import { isEmpty } from "lodash"

const MapComponent = (props) => {
  const {
    onClickMarker,
    selectedShop,
    t,
    activeTab,
    handleShopSelect,
    cart,
    show,
    vehicleTypeId,
    setSortingType,
    sortingType,
    ratingFiltersApplied,
    typeFiltersApplied,
    addorRemoveFilterItem,
    clearAllFilters,
    onSuggestSelect,
    zip,
    hideFilters,
    setIsSortOpen,
    setIsFilterOpen,
    isSortOpen,
    onSearchLocationSelect,
    isFilterOpen,
    handleApplyFilterClick,
    setShopSelected,
    shopSelected = selectedShop,
    isCheckout = false,
    isShopSelected = false,
    setViewType,
    view,
    count,
    showShopTiles = true,
    searchLocation = undefined,
    selectedShopResponse,
    handleSideCartPanel
  } = props

  const user = props.user
  const height = Math.max(window.innerHeight * (showShopTiles ? 0.65 : 0.45), 345)
  const firstShop = (props.shops && props.shops[0]) || {}
  const [showMapExpanded, setShowMapExpanded] = useState(false)
  const width = "100%"
  const [zoomLevel, setZoomLevel] = useState(9)
  const [viewport, setViewport] = useState({
    latitude: firstShop.latitude || 41.8786772,
    longitude: firstShop.longitude || -87.6451561,
    width: width,
    height: `${height}px`,
    zoom: 9,
    borderRadius: "8px"
  })

  const [initialViewport, setInitialViewport] = useState({
    latitude: firstShop.latitude || 41.8786772,
    longitude: firstShop.longitude || -87.6451561,
    width: width,
    height: `${height}px`,
    zoom: 9,
    borderRadius: "8px"
  })
  const [position, setPosition] = useState({ latitude: null, longitude: null })
  const [shops, setNewShops] = useState(props.shops)
  const [isExpandedViewOpen, setIsExpandedViewOpen] = useState(false)

  const handlerEvent = async (res) => {
    await handleSideCartPanel(res.detail.hideCart)
    if (isExpandedViewOpen) return
    setIsExpandedViewOpen(true)
    try {
      if (res.detail.show) {
        setShowMapExpanded(true)
      } else {
        setShowMapExpanded(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsExpandedViewOpen(false)
    }
  }
  React.useEffect(() => {
    document.addEventListener(OPEN_EXPANDED_MAP_VIEW, handlerEvent)
    return () => {
      document.removeEventListener(OPEN_EXPANDED_MAP_VIEW, handlerEvent)
    }
  }, [])

  React.useEffect(() => {
    const shopList = filteredShops(props.shops)
    setNewShops(shopList.allShops)
  }, [props.shops])

  React.useEffect(() => {
    show(showMapExpanded)
  }, [showMapExpanded])

  React.useEffect(() => {
    if (!isEmpty(selectedShopResponse)) {
      setShowMapExpanded(false)
    }
  }, [selectedShopResponse])

  const onSelectShopClick = (shop) => {
    const pricingInfo = getPricingInfo(
      !!getNewTiresServiceFromServices(
        cart && cart.order_services && cart.order_services.length > 0 ? cart.order_services : []
      ),
      shop.supports_tire_selection,
      shop.shopPriceText,
      shop.shopPriceEstimate,
      shop,
      t,
      vehicleTypeId
    )
    handleShopSelect(shop, pricingInfo)
  }

  React.useEffect(() => {
    if (shops && shops.length > 0) {
      setInitialViewport({
        latitude: shops[0].latitude,
        longitude: shops[0].longitude,
        width: width,
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      })
      setViewport({
        latitude: shops[0].latitude,
        longitude: shops[0].longitude,
        width: width,
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      })
    } else if (searchLocation) {
      setInitialViewport({
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude,
        width: width,
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      })
      setViewport({
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude,
        width: width,
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      })
    }
  }, [shops])

  const updateViewport = (viewPort) => {
    setViewport(viewPort)
    setZoomLevel(viewPort.zoom > 10 ? 10 : viewPort.zoom)
  }
  const recenterMap = (lat, lng) => {
    if (lat) {
      const view = {
        latitude: lat - 0.06,
        longitude: lng,
        width: width,
        height: `${height}px`,
        zoom: zoomLevel,
        borderRadius: "8px"
      }
      setViewport(view)
    } else {
      setViewport(initialViewport)
    }
  }

  const onSuggestOptionClick = (suggest) => {
    onSuggestSelect(suggest)
  }

  return (
    <div
      className="map-component-container"
      style={{ position: "relative", height: `${height}px` }}
    >
      {showMapExpanded && window.innerWidth >= 914 && (
        <MapPopUpComponent
          onClose={() => setShowMapExpanded(false)}
          updateViewport={(viewport) => updateViewport(viewport)}
          viewPort={viewport}
          setShopSelected={(shop) => {
            setShopSelected(shop)
          }}
          loading={props.loading}
          shopSelected={shopSelected}
          shops={shops}
          setShowMapExpanded={(val) => {
            setShopSelected(undefined)
            setShowMapExpanded(val)
          }}
          showMapExpanded={showMapExpanded}
          setSortingType={(value) => setSortingType(value)}
          sortingType={sortingType}
          ratingFiltersApplied={ratingFiltersApplied}
          typeFiltersApplied={typeFiltersApplied}
          addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
          clearAllFilters={clearAllFilters}
          zip={shops && shops.length > 0 ? shops[0].zip : zip}
          onSuggestSelect={onSuggestOptionClick}
          hideFilters={hideFilters}
          setIsSortOpen={setIsSortOpen}
          setIsFilterOpen={setIsFilterOpen}
          isSortOpen={isSortOpen}
          onSelectShopClick={onSelectShopClick}
          isFilterOpen={isFilterOpen}
          isCheckout={isCheckout}
          position={position}
          setPosition={setPosition}
          searchLocation={searchLocation}
          isShopSelected={isShopSelected}
        />
      )}
      {showMapExpanded && window.innerWidth <= 914 && (
        <MapWidgetMobile // For mobile devices we are using This component for additional features and styles ch
          onClose={() => setShowMapExpanded(false)}
          onSelectLocationResult={onSearchLocationSelect}
          updateViewport={(viewport) => updateViewport(viewport)}
          viewPort={viewport}
          setShopSelected={(shop) => setShopSelected(shop)}
          shopSelected={shopSelected}
          shops={shops}
          setShowMapExpanded={(val) => {
            setShopSelected(undefined)
            setShowMapExpanded(val)
          }}
          loading={props.loading}
          showMapExpanded={showMapExpanded}
          hideFilters={hideFilters}
          setSortingType={(value) => setSortingType(value)}
          sortingType={sortingType}
          ratingFiltersApplied={ratingFiltersApplied}
          typeFiltersApplied={typeFiltersApplied}
          addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
          clearAllFilters={clearAllFilters}
          zip={shops && shops.length > 0 ? shops[0].zip : zip}
          onSuggestSelect={onSuggestOptionClick}
          setIsSortOpen={setIsSortOpen}
          setIsFilterOpen={setIsFilterOpen}
          isSortOpen={isSortOpen}
          isFilterOpen={isFilterOpen}
          onSelectShopClick={onSelectShopClick}
          vehicleTypeId={vehicleTypeId}
          handleApplyFilterClick={handleApplyFilterClick}
          position={position}
          setPosition={setPosition}
          searchLocation={searchLocation}
        />
      )}

      {activeTab == "map" ? (
        <div style={{ borderRadius: "8px", overflow: "hidden" }}>
          <MapWidget
            updateViewport={(viewport) => updateViewport(viewport)}
            viewPort={viewport}
            setShopSelected={(shop) => setShopSelected(shop)}
            shopSelected={shopSelected}
            shops={shops}
            setShowMapExpanded={(val) => {
              setShopSelected(undefined)
              setShowMapExpanded(val)
            }}
            setViewType={(view) => setViewType(view)}
            view={view}
            loading={props.loading}
            showMapExpanded={showMapExpanded}
            onSelectShopClick={onSelectShopClick}
            onSuggestSelect={onSuggestOptionClick}
            hideFilters={hideFilters}
            recenterMap={recenterMap}
            position={position}
            setPosition={setPosition}
            showShopTiles={showShopTiles}
            searchLocation={searchLocation}
          />
        </div>
      ) : (
        <div className="shopListViewContainer">
          <div className="geoSuggestListViewContainer">
            <Geosuggest
              onSuggestSelect={(suggest) => suggestGeoSuggestClick(suggest, onSuggestOptionClick)}
              placeholder={t("placeholder")}
              types={["geocode"]}
              country={user.country}
              ignoreTab={true}
              className="geosuggest-container"
              suggestsClassName="search-suggests-list"
              suggestItemClassName="search-suggest-item"
              inputClassName={`searchFldShop smaller-width`}
              suggestItemActiveClassName="search-suggest-item-active"
              suggestsHiddenClassName="search-suggests-list-hidden"
              fixtures={[{ label: t("use-current-location"), custom: true }]}
            />
          </div>
          <div className="shopListItemContainer">
            {props.loading || props.shopLoading ? (
              <ShopListShimmer count={count} />
            ) : (
              <Loading active={isShopSelected} spinner={true} text={""}>
                <ShopListViewItemComponent
                  shops={shops}
                  shopSelected={shopSelected}
                  onSelectShopClick={onSelectShopClick}
                  showMapExpanded={showMapExpanded}
                  onClose={() => {
                    setShopSelected(undefined)
                  }}
                />
              </Loading>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let vehicleTypeId
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  if (activeVehicle) {
    vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
  }

  return {
    user: user,
    vehicleTypeId,
    cart
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(MapComponent))
