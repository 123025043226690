const LOAD_BILLING_DATA = "LOAD_BILLING_DATA"

const initialState = {}

export default function billingData(state = initialState, action) {
  switch (action.type) {
    case LOAD_BILLING_DATA:
      return Object.assign({}, state, { billingData: action.billingData })
    default:
      return state
  }
}
