import React, { Component } from "react"
import { connect } from "react-redux"
import { updateCart } from "../../actions/user"
import { makeShopsApiCall, setPrevShopSearchParams } from "../../actions/shop"
import PropTypes from "prop-types"

import { getStyles } from "../Config/StylesheetInjector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { textifiedPrice } from "../../helpers/shopHelpers"
import { elements, helpers } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import { translatedPosition } from "../../helpers/translationHelpers"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { MD_HD_VEHICLE_TYPE_ID } from "../../constants/application"
import { TIRE_SERVICES } from "../../constants/tires"
import { getJSON } from "../../../shared_component/utils/fetch"

const {
  serviceHelpers: { savedOrDeletedStatus }
} = helpers
const { OrderServiceModalInfo } = elements
const styles = getStyles()

const BLANK_PRICE_TEXT = ""
const EXTRA_QUARTS_LIMIT = 5.0
export const PRICING_NOT_AVAILABLE_TEXT = "No CarAdvise Price"
export const PREPAID_TEXT = "Prepaid"

export class OrderServicePriceRow extends Component {
  constructor(props) {
    super(props)
  }
  static propTypes = {
    displayPrices: PropTypes.bool.isRequired
  }

  static defaultProps = {
    displayPrices: true
  }
  servicesParam() {
    const pe = this.props.pe || {}
    const os = this.props.os || {}
    const services = this.props.addedServices.filter((sd) => {
      if (sd.id !== os.service_id || os.id || pe.service_definition_id) {
        return { position: sd.position, service_definition_id: sd.id }
      }
      return JSON.stringify(services)
    })
  }

  async handleClick(event) {
    const pe = this.props.pe || {}
    const os = this.props.os || {}
    const order = this.props.order || {}
    const { updateCart } = this.props

    // Sometimes OS is actually a serialized service definition(as in case of a shopping cart)
    // other times is an old order service from a shop order (As in Maintenance History)
    // So prefe[r service_id before using id because both an order service and service def have an ID but the id
    // we seek here is the ID of the service def, which is service_id on an order service and id on a service definition.
    let params = {
      zip: this.props.user.zip,
      services_requested: JSON.stringify(
        this.props.addedServices.map((sd) => {
          return { position: sd.position, service_definition_id: sd.id }
        })
      ),
      vehicle_id: this.props.user.activeVehicleId
    }

    const response = await updateCart(
      [
        {
          service_definition_id: os.service_id || os.id || pe.service_definition_id,
          event: event,
          prepaid: os.prepaid,
          position: os.position
        }
      ],
      order.vehicle_id
    )
    this.props.setPrevShopSearchParams({
      service_definition_id: JSON.stringify(
        this.props.addedServices.map((sd) => {
          return { position: sd.position, service_definition_id: sd.id }
        })
      )
    })

    this.props.makeShopsApiCall(params)
    if (response().result && event === "added") {
      trackEvent("recommended-service-added", {
        serviceName:
          this.props.name + this.props.os && this.props.os.position
            ? " - " + this.props.os.position
            : ""
      })
    }
  }

  formattedPrice(price) {
    const { t, user, shop, os, order } = this.props
    const isTireService = TIRE_SERVICES.includes(os.name)
    let activeVehicle
    if (user)
      activeVehicle =
        user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
    if (order.is_tire_order) {
      return t("includeTirePriceLabel")
    } else if (shop && shop.cdk_dealer) {
      return t("seeDealerPriceLbl")
    } else if (Math.sign(price) == 1) {
      const os = this.props.os || {}
      return textifiedPrice({ price: price, t: this.props.t, prepaid: os.prepaid })
    } else if (isTireService && price === 0) {
      return t("Free")
    } else {
      if (shop && shop.is_cdk_dealer) {
        return t("seeDealerPriceLbl")
      } else if (
        activeVehicle &&
        activeVehicle[0] &&
        activeVehicle[0].vehicle_type_id === MD_HD_VEHICLE_TYPE_ID
      ) {
        return t("pricedAtShop")
      } else return t("discountPriceAtShop")
      // return this.props.t("common:priceAtShopLbl")
    }
  }

  showRejectedServices() {
    const { ignoreRejectedStatus, os } = this.props
    return savedOrDeletedStatus(os) && !ignoreRejectedStatus
  }

  shouldHidePrice() {
    const { shop } = this.props
    const order = this.props.order || {}
    return this.showRejectedServices()
  }

  shouldAsterisk() {
    if (this.props.pe && this.props.pe.base_vehicle_service) {
      const {
        pe: { oem_parts, base_vehicle_service: { base_vehicle_service_prices = [] } = {} } = {}
      } = this.props
      return base_vehicle_service_prices.length > 0 && oem_parts == 0
    }
  }
  componentDidMount() {
    this.handleClick.bind(this, "removed")
  }
  // shouldComponentUpdate(nextProps) {
  //   // Rendering the component only if
  //   // passed props value is changed
  //   if (nextProps.addedServices.length < this.props.addedServices.length) {
  //     this.handleClick.bind(this, "removed")
  //   } else {
  //     return false
  //   }
  // }
  additionalQuartsPrice() {
    const pe = this.props.pe || {}
    const { extra_quarts, extra_quarts_price } = pe

    if (extra_quarts) {
      return extra_quarts * extra_quarts_price
    }
  }

  shopPrice() {
    if (this.shouldHidePrice()) return BLANK_PRICE_TEXT

    const pe = this.props.pe || {}
    const { shop_price_estimate } = pe
    const os = this.props.os || {}
    const { override_total } = os
    const extraQuartsPrice = this.additionalQuartsPrice()
    let servicePrice

    if (override_total != undefined) {
      // check null or undefined
      servicePrice = Number(override_total)
    } else if (shop_price_estimate != undefined) {
      // check null or undefined
      servicePrice = Number(shop_price_estimate)
    } else {
      servicePrice = shop_price_estimate
    }

    if (extraQuartsPrice) servicePrice = servicePrice - extraQuartsPrice

    return servicePrice
  }

  displayMinus() {
    const os = this.props.os || {}
    return this.props.displayMinus && !os.prepaid
  }
  componentDidUpdate(prevProps) {
    if (this.props.order.order_services.length < prevProps.order.order_services.length) {
      return true
    } else {
      return true
    }
  }
  render() {
    const pe = this.props.pe || {}
    const os = this.props.os || {}
    const { position, oil_quarts_needed } = os
    const { name, displayPlus, displayPrices, t, shop } = this.props
    const shopPrice = this.shopPrice()
    const styleLineThrough = { textDecoration: "line-through" }
    const showRejected = this.showRejectedServices()
    const extraQuarts =
      (pe.extra_oil_config && pe.extra_oil_config.quantity) ||
      (os.extra_oil_config && os.extra_oil_config.quantity) ||
      0
    const extraQuartsPrice = this.additionalQuartsPrice() || 0
    const showExtraOilQuarts = extraQuarts > 0 && extraQuartsPrice > 0 && !showRejected

    return (
      <tr>
        <td className="osp-name secondary-font">
          <span className="osp-name-span">
            <span
              className="OrderServicePriceRow-name"
              style={showRejected ? styleLineThrough : {}}
            >
              {name}
              {position && (
                <small>{` (${translatedPosition({ position: position, t: t })})`}</small>
              )}
              {this.shouldAsterisk() && "*"}{" "}
            </span>
            <OrderServiceModalInfo
              service={os}
              pe={pe}
              styles={styles}
              icon={faQuestionCircle}
              className="OrderServicePriceRow-dialog"
              t={t}
            />
          </span>
          {showExtraOilQuarts && (
            <span className="OrderServicePriceRow-oil-quarts secondary-font">
              <br />
              <small>{`${t("additionalQuartsOfOilLbl")} (${extraQuarts})`}</small>
            </span>
          )}
        </td>
        {displayPrices && (
          <React.Fragment>
            <td style={{ textAlign: "right" }}>
              <span className="OrderServicePriceRow-shop-price secondary-font">
                {this.formattedPrice(shopPrice)}
              </span>
              {showExtraOilQuarts && shop && !shop.cdk_dealer && (
                <span>
                  <br />
                  <small>{this.formattedPrice(this.additionalQuartsPrice())}</small>
                </span>
              )}
            </td>
          </React.Fragment>
        )}

        {this.displayMinus() && (
          <td onClick={this.handleClick.bind(this, "removed")} style={{ cursor: "pointer" }}>
            <span className="primary-button-color gtm-cart-remove-service">
              {/* <FontAwesome name="minus-circle"/> */}
              <FontAwesomeIcon icon={faMinusCircle} />
            </span>
          </td>
        )}
        {this.displayMinus() && shop && !shop.cdk_dealer && (
          <td onClick={this.handleClick.bind(this, "added")} style={{ cursor: "pointer" }}>
            {/* <span className="primary-button-color gtm-cart-add-service">
              <FontAwesomeIcon icon={faPlusCircle} />
            </span> */}
          </td>
        )}
      </tr>
    )
  }
}

export default connect(
  (state) => {
    let pev = state.shop.pev
    let user = state.user
    let cart =
      user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    let addedServices = cart ? cart.order_services : []

    return { user: state.user, pev, addedServices }
  },
  { updateCart, makeShopsApiCall, setPrevShopSearchParams }
)(
  withTranslation(["orderServicePriceRow", "orderServiceModalInfo", "priceExplanation", "common"])(
    OrderServicePriceRow
  )
)
