import React from "react"
import { withRouter, NavLink } from "react-router-dom"
import { connect } from "react-redux"
import Badge from "./Badge"
import { isSigningUp } from "../helpers/pathHelpers"
import { faIdCard, faTachometer } from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import { IncomingMessage } from "http"
import { constants } from "caradvise_shared_components"
const {
  orders: { AWAITING_APPROVAL }
} = constants

function MobileNav({
  isLoggedIn,
  hasCC,
  hideChrome,
  pendingApprovalCount,
  hasApprovals,
  isGeneric,
  user
}) {
  if (!isLoggedIn || hideChrome || (isGeneric && isSigningUp())) return null

  return (
    <div className="mobile-nav safari_only">
      <div className="mobile-navlink-container">
        <NavLink activeClassName="active-mobile-navlink" to="/dashboard">
          <Badge className="fa-stack fa-badge-stack mobile" icon={faTachometer} exact />
        </NavLink>
      </div>
      <div className="mobile-navlink-container">
        <NavLink icon="check-circle-o" activeClassName="active-mobile-navlink" to="/approvals">
          <Badge
            className="fa-stack fa-badge-stack mobile"
            count={pendingApprovalCount}
            icon={faCheckCircle}
            exact
          />
        </NavLink>
      </div>
      <div className="mobile-navlink-container">
        <NavLink activeClassName="active-mobile-navlink" to="/membership">
          <Badge className="fa-stack fa-badge-stack mobile" icon={faIdCard} exact />
        </NavLink>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  if (!state.user || !state.user.active_orders) return null

  const activeVehicleId = state.user.activeVehicleId
  const shopOrders = state.user.active_orders
  const shopOrdersAwaitingApproval =
    shopOrders.filter(
      (shop_order) =>
        shop_order.vehicle_id == activeVehicleId && shop_order.status == AWAITING_APPROVAL
    ) || []
  let activeAwaitingApprovalOrderServices = [] // Placeholder
  if (shopOrdersAwaitingApproval.length > 0) {
    const orderServiceInitiatedStatus = 0 // NOTE Same as awaiting approval from admin perspective
    const orderServiceInitiatedByUserStatus = 1
    if ("order_services" in shopOrdersAwaitingApproval[0]) {
      activeAwaitingApprovalOrderServices = shopOrdersAwaitingApproval[0].order_services.filter(
        (orderService) =>
          orderService.status == orderServiceInitiatedStatus ||
          orderService.status == orderServiceInitiatedByUserStatus
      )
    }
  }
  const hasApprovals = activeAwaitingApprovalOrderServices.length > 0
  const orderServicesForApprovalCount = hasApprovals
    ? activeAwaitingApprovalOrderServices.length
    : null // NOTE null and not zero so no badge will load
  const user = state.user || {}

  return {
    isLoggedIn: state.user && state.user.id,
    hasCC: !state.user || !state.user.has_cc_info_saved,
    hideChrome: state.framework && state.framework.hideChrome,
    pendingApprovalCount: orderServicesForApprovalCount,
    hasApprovals: hasApprovals,
    isGeneric: user.generic,
    user: user
  }
}

export default connect(mapStateToProps)(withRouter(MobileNav))
