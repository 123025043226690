import React, { PureComponent } from "react"
import { connect } from "react-redux"
import ModalDialog from "../ModalDialog"
import ShopAppointmentPicker from "../ShopAppointmentPicker"
import { changeAppointment } from "../../actions/orders"
import { withTranslation } from "react-i18next"
import { orderRescheduleEvents } from "../../../shared_component/utils/googleAnalyticsHelpers"

class AppointmentSelectorModal extends PureComponent {
  state = {}

  dateTimeSelected = (date) => {
    if (date) {
      this.setState({ date: date.format("MM-DD-YYYY HH:mm") })
    }
  }

  onSubmit = async () => {
    const { changeAppointment, orderLikeObject, activeVehicle } = this.props
    const response = await changeAppointment(orderLikeObject, this.state.date)
    if (response.result) {
      await orderRescheduleEvents({ response, activeVehicle })
    }
  }

  render() {
    const { t } = this.props
    return (
      <ModalDialog
        linkText={
          <div
            onClick={() => {
              // trackEvent(EVENT_NAME.changeDateTimeClicked, {
              //   ...getAdditionalProps({ shouldIncludeBookingTime: false }),
              //   existingDate: this.props.orderLikeObject.appointment_date_pretty,
              //   existingTime: this.props.orderLikeObject.appointment_time_pretty,
              //   vehicleName: getVehicleName(getActiveVehicle()),
              //   orderId: this.props.orderLikeObject.id
              // })
            }}
          >
            {t("changeDateTimeLbl")}
          </div>
        }
        centerButtons={true}
        buttons={[
          {
            onClick: this.onSubmit,
            disabled: !this.state.date,
            text: t("updateAppointmentText"),
            colorStyle: "orange",
            appendClose: true,
            trackingClassName: "gtm-updateappointment-confirm"
          }
        ]}
      >
        <ShopAppointmentPicker
          user={this.props.user}
          orderLikeObject={this.props.orderLikeObject}
          onSelect={this.dateTimeSelected}
          disableWalkIns
        />
      </ModalDialog>
    )
  }
}
function mapStateToProps(state) {
  const user = state.user || {}
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    activeVehicle
  }
}

export default connect(mapStateToProps, { changeAppointment })(
  withTranslation("appointmentSelectorModal")(AppointmentSelectorModal)
)
