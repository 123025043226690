import React, { Component } from "react"
import store from "../../../shared_component/utils/configureStore"
import { updateSelectedMembershipUpgradeClauses } from "../../../v1/actions/membership"
import { withTranslation } from "react-i18next"

class MembershipAgreement extends Component {
  state = {
    acceptedTermsAndConditions: false
  }

  handleDispatch = () => {
    store.dispatch(updateSelectedMembershipUpgradeClauses(this.state))
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.checked
    const name = target.name
    const { updateSelectedMembership } = this.props

    if (updateSelectedMembership) {
      updateSelectedMembership({ [name]: value })
    } else {
      this.setState({ [name]: value }, this.handleDispatch)
    }
  }

  render() {
    const {
      t,
      selectedMembership,
      selectedAccountTier,
      nextUpgrades,
      isDashboardModal
    } = this.props
    const disableFlag = nextUpgrades.find((item) => item.id === selectedAccountTier.id)
    return (
      <div className="membership-term-and-condition-container">
        <div className="membership-term-and-condition-section">
          <input
            checked={selectedMembership.acceptedTermsAndConditions}
            type="checkbox"
            name={"acceptedTermsAndConditions"}
            onClick={(e) => this.handleInputChange(e)}
            disabled={!selectedAccountTier || (!isDashboardModal && !disableFlag)}
          />
          {t("IAgreeToLbl")}
          <a
            href="https://caradvise.com/terms/"
            className="button-href"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("termsAndConditionLbl")}
          </a>
        </div>
      </div>
    )
  }
}

export default withTranslation("membershipClauseAgreementSection")(MembershipAgreement)
