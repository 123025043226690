import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

class PaymentMethodDetails extends Component {
  renderTypeSpecificDetails() {
    const { paymentMethod } = this.props
    const { t } = this.props

    if (paymentMethod.type === "credit_card") {
      const { expiration_date, last_4 } = paymentMethod

      return (
        <div style={{ padding: "0px 10px" }}>
          {paymentMethod.cardholder_name && (
            <>
              <strong>{paymentMethod.cardholder_name}</strong>
              <br />
            </>
          )}
          {t("endingInLbl")} <strong>xxxx-{last_4}</strong>
          <br />
          {t("expLbl")} <strong>{expiration_date}</strong>
        </div>
      )
    } else if (paymentMethod.type === "pay_pal_account") {
      const { email } = paymentMethod
      return (
        <div className="paypal-info">
          <strong>{email}</strong>
          <br />
          {t("paypalLbl")}
        </div>
      )
    }
  }

  render() {
    return <div className="paymentInfo">{this.renderTypeSpecificDetails()}</div>
  }
} // PaymentMethodDetails

PaymentMethodDetails.propTypes = {
  paymentMethod: PropTypes.object.isRequired
}

export default withTranslation("paymentMethodDetails")(PaymentMethodDetails)
