import React, { Component } from "react"
import { styles } from "react-contexify/lib/utils/styles"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import Button from "../../../../components/Button"
import { BLACK, ORANGE } from "../../../constant/color"

class Footer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props
    return (
      <div className="dashBoard_supportCard_container">
        <div className="otherServicesDiv">
          <h3 className="other_services">{t("otherServices")}</h3>
        </div>
        <div className="dashBoard_supportCard_parent_container">
          <div className="dashBoard_supportCard_child_container">
            <div className="dashBoard_supportCard_child_header">
              <div>
                <h2 className="talkToTeach">{t("talkToTech")}</h2>
                <p className="certifiedMaintenance">{t("certifiedMaintenance")}</p>
              </div>
              <div>
                <Image
                  src={require("../../../images/caradvise2/head_phone.svg")}
                  alt="techSupport"
                />
              </div>
            </div>
            <div>
              <Button
                className="cta-button"
                key="btn-cta-buy"
                text={t("chatNow")}
                styleText={{ color: "white", fontWeight: "bold" }}
                style={{
                  backgroundColor: ORANGE,
                  borderWidth: "0px",
                  padding: "5px",
                  alignSelf: "start"
                }}
                linkTo={""}
              />
            </div>
          </div>
          <div className="dashBoard_supportCard_child_container2">
            <div className="dashBoard_tires_card_right">
              <div>
                <h2 className="changeTire">{t("changeTire")}</h2>
              </div>
              <div>
                <Button
                  className="cta-button"
                  key="btn-cta-buy"
                  text={t("buyNewTire")}
                  styleText={{ color: "white" }}
                  style={{
                    backgroundColor: ORANGE,
                    borderWidth: "0px",
                    padding: "5px"
                  }}
                  linkTo={""}
                />
              </div>
            </div>
            <div className="dashBoard_tires_card_left">
              <div className="blueTireDiv">
                <Image src={require("../../../images/caradvise2/big_tire.svg")} alt="tireBlue" />
              </div>
              <div>
                <Image src={require("../../../images/caradvise2/subtract.svg")} alt="subtract" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation("dashboardFooter")(Footer)
