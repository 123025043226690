import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import DefaultServiceLogo from "../../../images/default_wrench_icon.svg"
import LeftArrowIcon from "../../../images/left_arrow_icon.svg"

import { connect } from "react-redux"
import { ImageWithFallback } from "../../../shared_component/elements/ImageWithFallback"

const CategoryCard = ({ service, handleCategoryCardClick }) => {
  const isFunctionEmpty = handleCategoryCardClick.toString().trim().length === 0
  const { t } = useTranslation("common")
  const serviceLogoURL = `https://s3.amazonaws.com/assets.caradvise.com/service_categories/${service
    .replace(/\s+/g, "")
    .toLowerCase()}.svg`

  return (
    <div
      className={`category-card-parent`}
      onClick={() => !isFunctionEmpty && handleCategoryCardClick(service)}
    >
      <div className="category-info-section">
        {
          <div className="service-image-section">
            <ImageWithFallback
              defaultSrc={DefaultServiceLogo}
              src={serviceLogoURL}
              fallback={<div className="animate"></div>}
            />
          </div>
        }
        <div className="category-name-header-section">
          <div className="category-name-info-section">
            <p className="category-name">{service}</p>
          </div>
        </div>
      </div>
      {<Image src={LeftArrowIcon} />}
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.filter((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(CategoryCard)
