import React, { Component } from "react"

import GoogleAnalytics from "react-ga"
import store from "../../shared_component/utils/configureStore"

GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
const withGAPageviewTracking = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    const dimensionData = store.getState()
    const affiliationName = dimensionData.user.affiliation_name

    GoogleAnalytics.set({
      page,
      // Do NOT change these dimensions
      dimension1: affiliationName
    })
    GoogleAnalytics.pageview(page)
  }

  const HOC = class extends Component {
    constructor(props) {
      super(props)
    }

    componentDidMount() {
      const page = this.props.location.pathname
      const nonUniquePage = page.replace(new RegExp("/[0-9]+", "g"), "")
      trackPage(nonUniquePage)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return HOC
}

export default withGAPageviewTracking
