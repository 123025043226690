import React, { Component } from "react"
import cache from "../../../../../shared_component/utils/cache"
import { getJSON } from "../../../../../shared_component/utils/fetch"
import { getApiUrl, getUrlProtocol } from "../../../../../shared_component/utils/urlUtils"
import sortBy from "lodash/sortBy"
import uniqBy from "lodash/uniqBy"
import { connect } from "react-redux"
import { addNewVehicleByVin, addNewNoVehicleByPlate } from "../../../../actions/vehicles"
import { addNewVehicle, me, setLoading, setNotLoading, updateInfo } from "../../../../actions/user"
import { getMembershipPlans } from "../../../../actions/membership"
import { nextMembershipUpgrades } from "../../../../helpers/membershipHelpers"
import { addVehicleYearRange } from "../../../../helpers/vehicleHelpers"
import { Redirect } from "react-router"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Notifications from "../../../../components/Notifications"
import store from "../../../../../shared_component/utils/configureStore"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { routerPaths } from "../../../../constants/paths"
import { Loader } from "../../../../components/LoadingMask"
import { trackEvent } from "../../../../../shared_component/utils/segmentAnalytics"
import { Form, Grid, Image, Input, Button as SemanticButton } from "semantic-ui-react"

import CarCoverBlue from "../../../../../images/carCoverBlack.png"
import MiniLoader from "../../../../components/MiniLoader"
import NotificationContainer from "../../../../components/NotificationContainer"

import { getSubdomain } from "../../../../components/Config/StylesheetInjector"

import _ from "lodash"

import { fetchUserStates } from "../../../../actions/user"
import { GAEvent, GAEventCategories } from "../../../../tracking/GAEvent"
import { ON, setupSplitFlags } from "../../../../components/Config/SplitClient"

import TabBarWithRender from "../../../../registration/tabBarWithRender"
import { APP_NAME } from "../../../../../shared_component/constants/common"
import { Loader2 } from "../../../../../shared_component/components/Loader2"

const BY_YEAR_URL = "/api/v4/vehicles/years/:year/makes"
const BY_YEAR_AND_MAKE_URL = "/api/v4/vehicles/years/:year/makes/:make"
const BY_MODEL_AND_SUB_MODEL_URL =
  "/api/v4/vehicles/years/:year/makes/:make/models/:model/submodels/:submodel"
const BY_YMM = "/api/v4/vehicles/years/:year/makes/:make/models/:model"
const PREPOPULATE_URL = "/api/v4/shopping_carts/prepopulate"

class VehicleDetails extends Component {
  constructor(props) {
    super(props)
    const { t } = props
    this.state = {
      error: false,
      isStateLoading: false,
      value: "",
      isSubmitting: false,
      hide_years: true,
      years: addVehicleYearRange()
        .map((n) => n.toString())
        .map((value) => ({ value, label: value, key: value })),
      hide_makes: true,
      makes: cache.get("vehicleDetails-makes") || [],
      hide_models: true,
      models: cache.get("vehicleDetails-models") || [],
      hide_sub_models: true,
      sub_models: cache.get("vehicleDetails-sub_models") || [],
      hide_engines: true,
      engines: cache.get("vehicleDetails-engines") || [],
      userStates: [],
      fields: Object.assign(
        {
          year: { value: new Date().getFullYear(), invalid: false, validators: ["_isPresent"] },
          make: { value: null, invalid: false, validators: ["_isPresent"] },
          model: { value: null, invalid: false, validators: ["_isPresent"] },
          sub_model: { value: null, invalid: false, validators: ["_isPresent"] },
          engine: { value: null, invalid: false, validators: ["_isPresent"] },
          miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
        },
        cache.get("vehicleDetails-fields") || {}
      ),
      vinFields: Object.assign({
        vin: { value: "", invalid: false, validators: ["_isPresent"] },
        miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
      }),
      plateNumberFields: Object.assign({
        plateNumber: { value: "", invalid: false, validators: ["_isPresent"] },
        plateNumberState: { value: "", invalid: false, validators: ["_isPresent"] },
        miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
      })
    }
    this._fetchMakes = this._fetchMakes.bind(this)
    this._fetchModels = this._fetchModels.bind(this)
    this._onClickNext = this._onClickNext.bind(this)
    this._validateFields = this._validateFields.bind(this)
    this._handleInput = this._handleInput.bind(this)
    this._handleInputVIN = this._handleInputVIN.bind(this)
    this._validateVinFields = this._validateVinFields.bind(this)
    this._onSaveVIN = this._onSaveVIN.bind(this)
    this._handlePlateNumberInput = this._handlePlateNumberInput.bind(this)
    this._validatePlateNumberFields = this._validatePlateNumberFields.bind(this)
    this._onSavePlateNumber = this._onSavePlateNumber.bind(this)
  }

  async componentDidMount() {
    setupSplitFlags.bind(this)({ app_name: APP_NAME })
    const { user, updateInfo, history } = this.props
    this.props.fetchUserStates()
    this._fetchMakes()
    this.props.getMembershipPlans()
    if (user && user.id) {
      await updateInfo({
        send_through_signup_flow: false
      })
    }
    setTimeout(() => {
      trackEvent("no-vehicle-land")
    }, 500)
  }

  componentDidUpdate() {
    const { error, t } = this.props

    if (error && typeof error === "object" && error.length > 0) {
      error.forEach((error) =>
        store.dispatch(
          Notifications.error({
            title: t("errorText"),
            message: error,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      )
    } else if (error && typeof error === "string") {
      store.dispatch(
        Notifications.error({
          title: t("errorText"),
          message: error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }

  _handleInput(e) {
    this._onFieldChange("miles", e.target.value)
  }

  async _fetchModels() {
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    const { t } = this.props
    if (!year || !make) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    this.setState({ loadingModels: true })
    let response = await getJSON(
      BY_YEAR_AND_MAKE_URL.replace(":year", year).replace(":make", make_id)
    )
    this.setState({ loadingModels: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let models = response.result.map((vehicle) => ({
        key: vehicle.model_id,
        label: vehicle.model,
        value: vehicle.model
      }))
      let sub_models = response.result.map((vehicle) => ({
        key: vehicle.sub_model_id,
        label: vehicle.sub_model,
        value: vehicle.sub_model,
        model: vehicle.model
      }))

      models = uniqBy(models, "key")
      models = sortBy(models, ({ label }) => label.toLowerCase())
      sub_models = sortBy(sub_models, ({ label }) => label.toLowerCase())
      sub_models.unshift({
        key: "0",
        label: t("subModelPlaceholder"),
        value: t("subModelPlaceholder")
      })
      cache.set("vehicleDetails-models", models)
      cache.set("vehicleDetails-sub_models", sub_models)
      cache.remove("vehicleDetails-engines")

      this.setState(
        {
          models,
          sub_models,
          engines: [],
          fields: {
            ...this.state.fields,
            model: { ...this.state.fields.model, value: "", invalid: false },
            sub_model: { ...this.state.fields.sub_model, value: "", invalid: false },
            engine: { ...this.state.fields.engine, value: "", invalid: false }
          }
        },
        () => cache.set("vehicleDetails-fields", this.state.fields)
      )
    }
  }

  async _fetchMakes() {
    let year = this.state.fields.year.value
    const { t } = this.props
    if (!year) return

    this.setState({ loadingMakes: true })
    let response = await getJSON(BY_YEAR_URL.replace(":year", year))
    this.setState({ loadingMakes: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let makes = response.result.map(({ make_id, make }) => {
        return { key: make_id, label: make, value: make }
      })
      makes = sortBy(makes, ({ label }) => label.toLowerCase())
      cache.set("vehicleDetails-makes", makes)
      cache.remove("vehicleDetails-models")
      cache.remove("vehicleDetails-sub_models")
      cache.remove("vehicleDetails-engines")
      this.setState(
        {
          makes,
          models: [],
          sub_models: [],
          engines: [],
          fields: {
            ...this.state.fields,
            make: { ...this.state.fields.make, value: "", invalid: false },
            model: { ...this.state.fields.model, value: "", invalid: false },
            sub_model: { ...this.state.fields.sub_model, value: "", invalid: false },
            engine: { ...this.state.fields.engine, value: "", invalid: false }
          }
        },
        () => cache.set("vehicleDetails-fields", this.state.fields)
      )
    }
  }

  async _fetchEngines() {
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    let model = this.state.fields.model.value
    let sub_model = this.state.fields.sub_model.value
    const { t } = this.props
    if (!year || !make || !model || !sub_model) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    sub_model = this.state.sub_models.find((a) => a.value === sub_model && a.model === model) || {}
    let sub_model_id = sub_model.key
    if (!sub_model_id) return

    model = this.state.models.find(({ value }) => value === model) || {}
    let model_id = model.key
    if (!model_id) return

    this.setState({ loadingEngines: true })
    let response = await getJSON(
      BY_MODEL_AND_SUB_MODEL_URL.replace(":year", year)
        .replace(":make", make_id)
        .replace(":model", model_id)
        .replace(":submodel", sub_model_id)
    )
    this.setState({ loadingEngines: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let engines = response.result.map(({ id, description }) => {
        return { key: id, label: description, value: description }
      })

      engines = sortBy(engines, ({ label }) => label.toLowerCase())
      cache.set("vehicleDetails-engines", engines)

      this.setState(
        {
          engines,
          fields: {
            ...this.state.fields,
            engine: {
              ...this.state.fields.engine,
              value: engines[1] ? engines[1].value : "",
              engine_id: engines[1] ? engines[1].key : "",
              invalid: false
            }
          }
        },
        () => {
          cache.set("vehicleDetails-fields", this.state.fields)
        }
      )
    }
  }

  async _fetchVehicle() {
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    let model = this.state.fields.model.value
    if (!year || !make || !model) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    model = this.state.models.find(({ value }) => value === model) || {}
    let model_id = model.key
    if (!model_id) return

    let response = await getJSON(
      BY_YMM.replace(":year", year).replace(":make", make_id).replace(":model", model_id)
    )

    this.setState({
      fields: {
        ...this.state.fields,
        vehicleId: { value: response.result.base_vehicle_id }
      }
    })
  }

  _onClickNext(e) {
    e.preventDefault()
    this.setState({
      isSubmitting: true,
      vinFields: Object.assign({
        vin: { value: "", invalid: false, validators: ["_isPresent"] },
        miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
      }),
      plateNumberFields: Object.assign({
        plateNumber: { value: "", invalid: false, validators: ["_isPresent"] },
        plateNumberState: { value: "", invalid: false, validators: ["_isPresent"] },
        miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
      })
    })
    let fields = this.state.fields
    this._validateFields(async () => {
      const sanitizedMiles = fields.miles.value.replace(/\D/, "")
      let data = {
        odometer: sanitizedMiles,
        year: fields.year.value,
        make: fields.make.value
      }

      let models = cache.get("vehicleDetails-models") || []
      let model = models.find(({ value }) => value === fields.model.value) || {}
      data.model_id = model.key
      data.model = model.value

      let engines = cache.get("vehicleDetails-engines") || []
      let engine = engines.find(({ value }) => value === fields.engine.value) || {}
      data.vehicle_type_extension_engine_id = engine.key
      data.isNewUser = true
      GAEvent(GAEventCategories.MY_GARAGE, "add-vehicle", "Add Vehicle By Manual")
      let success = await this.props.addNewVehicle(data)
      if (success) {
        this.setState({ gotoNext: true })
      } else this.props.setNotLoading()
      this.setState({ isSubmitting: false })
    })
  }

  subModelFilter(arr) {
    const { t } = this.props
    let newarray = arr.filter((a) => a.model === this.state.fields.model.value)
    newarray.unshift({ key: "0", label: t("subModelPlaceholder"), value: t("subModelPlaceholder") })
    return newarray
  }

  subModelFilterManual(arr) {
    const { t } = this.props
    let newarray = arr.filter((a) => a.model === this.state.fields.model.value)
    return newarray
  }

  unshiftDropdownItems(arr, label) {
    let newarray = arr.filter((a) => a !== label)
    newarray.unshift({ key: "0", label: label, value: label })
    return newarray
  }

  _handleInputVIN(e) {
    this._onFieldVINChange(e.target.name, e.target.value)
  }

  _onFieldVINChange(key, value, onSelect) {
    this.setState(
      {
        vinFields: {
          ...this.state.vinFields,
          [key]: this._setAndValidateVINField(key, value)
        }
      },
      onSelect
    )
  }

  _onSaveVIN() {
    this._validateVinFields(() => {
      this.setState({
        fields: Object.assign(
          {
            year: { value: new Date().getFullYear(), invalid: false, validators: ["_isPresent"] },
            make: { value: null, invalid: false, validators: ["_isPresent"] },
            model: { value: null, invalid: false, validators: ["_isPresent"] },
            sub_model: { value: null, invalid: false, validators: ["_isPresent"] },
            engine: { value: null, invalid: false, validators: ["_isPresent"] },
            miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
          },
          cache.get("vehicleDetails-fields") || {}
        ),
        plateNumberFields: Object.assign({
          plateNumber: { value: "", invalid: false, validators: ["_isPresent"] },
          plateNumberState: { value: "", invalid: false, validators: ["_isPresent"] },
          miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
        })
      })
      this._verifyVIN()
    })
  }

  _validateVinFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.vinFields).forEach((key) => {
      let field = this.state.vinFields[key]
      fields[key] = field = this._setAndValidateVINField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (!firstInvalidKey && callback) callback()
      else {
        store.dispatch(
          Notifications.error({
            title: t("errorText"),
            message: `${t("errorPopupMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }

  _setAndValidateVINField(key, value) {
    let field = this.state.vinFields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  async _verifyVIN(callback) {
    this.props.setLoading(this.props.t("maintenanceHistoryLoadingMessage"))
    let success = await this.props.addNewVehicleByVin(
      this.state.vinFields.vin.value,
      this.state.vinFields.miles.value,
      true,
      true
    )
    this.props.setNotLoading()
    if (success) {
      GAEvent(GAEventCategories.MY_GARAGE, "add-vehicle", "Add Vehicle By Plate Number")
      this.setState({
        gotoNext: true,
        vinFields: Object.assign({
          vin: { value: "", invalid: false, validators: ["_isPresent"] },
          miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
        })
      })
    }
  }

  _onSavePlateNumber() {
    this._validatePlateNumberFields(() => {
      this.setState({
        fields: Object.assign(
          {
            year: { value: new Date().getFullYear(), invalid: false, validators: ["_isPresent"] },
            make: { value: null, invalid: false, validators: ["_isPresent"] },
            model: { value: null, invalid: false, validators: ["_isPresent"] },
            sub_model: { value: null, invalid: false, validators: ["_isPresent"] },
            engine: { value: null, invalid: false, validators: ["_isPresent"] },
            miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
          },
          cache.get("vehicleDetails-fields") || {}
        ),
        vinFields: Object.assign({
          vin: { value: "", invalid: false, validators: ["_isPresent"] },
          miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
        })
      })
      this._verifyPlateNumber()
    })
  }

  async _verifyPlateNumber(callback) {
    this.props.setLoading()
    let success = await this.props.addNewNoVehicleByPlate(
      this.state.plateNumberFields.plateNumber.value,
      this.state.plateNumberFields.plateNumberState.value,
      this.state.plateNumberFields.miles.value
    )
    this.props.setNotLoading()
    if (success) {
      GAEvent(GAEventCategories.MY_GARAGE, "add-vehicle", "Add Vehicle By Plate Number")
      this.setState({ gotoNext: true })
    }
  }

  _validatePlateNumberFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.plateNumberFields).forEach((key) => {
      let field = this.state.plateNumberFields[key]
      fields[key] = field = this._setAndValidatePlateNumberField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (!firstInvalidKey && callback) callback()
      else {
        store.dispatch(
          Notifications.error({
            title: t("errorText"),
            message: `${t("errorPopupMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }

  _handlePlateNumberInput(e) {
    this._onFieldPlateNumberChange(e.target.name, e.target.value)
  }

  _onFieldPlateNumberChange(key, value, onSelect) {
    this.setState(
      {
        plateNumberFields: {
          ...this.state.plateNumberFields,
          [key]: this._setAndValidatePlateNumberField(key, value)
        }
      },
      onSelect
    )
  }

  _setAndValidatePlateNumberField(key, value) {
    let field = this.state.plateNumberFields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  vehicleDetailsVIN = () => {
    const { isLoading, t } = this.props
    const { vinFields } = this.state
    const { vin, miles } = vinFields
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }}>
          <div style={{ height: "80px", overflow: "scroll" }}>
            <Form>
              <Form.Field required>
                <label className="no-vehicle-label">{t("vinNumberPlaceholder")}</label>
                <Input
                  data-qa="registration-vin-input-field"
                  refs="vin"
                  name="vin"
                  size="small"
                  placeholder={t("vinNumberPlaceholder")}
                  onKeyPress={(event) => {
                    if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  value={this.state.vinFields.vin.value}
                  onChange={this._handleInputVIN}
                />
              </Form.Field>
              <Form.Field required>
                <span className="required-field-miles">
                  <label className="required-field">{t("mileageLbl")}</label>
                  <small className="no-vehicle-miles-label">{t("milesSubLbl")}</small>
                </span>
                <Input
                  refs="miles"
                  size="small"
                  name="miles"
                  data-qa="registration-miles-input-field"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  placeholder={t("milePlaceholder")}
                  type="number"
                  value={this.state.vinFields.miles.value}
                  onChange={this._handleInputVIN}
                  min="0"
                />
              </Form.Field>
            </Form>
          </div>

          <SemanticButton
            fluid
            className="noVehicleManual-save-button"
            disabled={
              vin.value.length !== 0 && miles.value.length !== 0 && !isLoading ? false : true
            }
            content={isLoading ? <MiniLoader /> : "Add a new car"}
            onClick={this._onSaveVIN}
            style={{
              backgroundColor: "#E95832",
              color: "#ffffff",
              marginTop: "12px"
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  vehicleDetailsPlateNumber = () => {
    const { isLoading, t, userStates } = this.props
    const { plateNumberFields } = this.state
    const { plateNumber, miles } = plateNumberFields
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }}>
          <div style={{ height: "80px", overflow: "scroll" }}>
            <Form>
              <Form.Field required>
                <label className="no-vehicle-label">{t("plateNumberLbl")}</label>
                <Input
                  data-qa="registration-plate-number-input-field"
                  refs="plateNumber"
                  name="plateNumber"
                  size="small"
                  placeholder={t("plateNumberPlaceholder")}
                  onKeyPress={(event) => {
                    if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  value={plateNumber.value}
                  onChange={this._handlePlateNumberInput}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t("stateLbl")}</label>
                <Form.Dropdown
                  fluid
                  className="vehicle_add_dropdown"
                  placeholder={t("statePlaceholder")}
                  search
                  selection
                  onChange={(e, data) => {
                    let selectedValue = data.value
                    this._onFieldPlateNumberChange("plateNumberState", selectedValue)
                  }}
                  options={_.map(userStates, (item) => ({
                    key: item.id,
                    text: item.name,
                    value: item.label
                  }))}
                />
              </Form.Field>
              <Form.Field required>
                <span className="required-field-miles">
                  <label className="required-field">{t("mileageLbl")}</label>
                  <small className="no-vehicle-miles-label">{t("milesSubLbl")}</small>
                </span>
                <Input
                  refs="miles"
                  size="small"
                  name="miles"
                  data-qa="registration-miles-input-field"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  placeholder={t("milePlaceholder")}
                  type="number"
                  value={miles.value}
                  onChange={this._handlePlateNumberInput}
                  min="0"
                />
              </Form.Field>
            </Form>
          </div>
          <SemanticButton
            fluid
            className="noVehicle-save-button"
            // className="vehicle_add_container"

            disabled={
              plateNumber.value.length !== 0 && miles.value.length !== 0 && !isLoading
                ? false
                : true
            }
            content={isLoading ? <MiniLoader /> : "Add a new car"}
            onClick={this._onSavePlateNumber}
            style={{
              backgroundColor: "#E95832",
              color: "#ffffff",
              marginTop: "12px"
            }}
          />
          {(this.props.isMissingVehicle || this.props.isMissingVehicle === undefined) &&
            this.props.user.generic && (
              <p className="signIn-link">
                {t("alreadyHaveAccountLbl")}{" "}
                <span className="primary-link">
                  <Link to={{ pathname: "/signIn", params: { isFromNoVehicle: true } }}>
                    {t("clickHereLbl")}
                  </Link>
                </span>
              </p>
            )}
        </div>
      </React.Fragment>
    )
  }

  _renderSearch({
    key,
    value,
    items,
    isHidden,
    emptyMessage,
    isLoading,
    onSelect,
    placeholderText
  }) {
    let renderKey = this.state.fields[key]
    if (isLoading)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FontAwesomeIcon icon={faSpinner} size={"2x"} spin />
        </div>
      )
    if (items.length === 0) items = [{ key: "0", label: emptyMessage, value: renderKey.value }]
    return (
      <div>
        <Form.Dropdown
          placeholder={placeholderText}
          fluid
          search
          selection
          defaultValue={renderKey.value ? renderKey.value.toString() : renderKey.value}
          onChange={(e, data) => {
            let selectedValue = data.value
            this._onFieldChange(key, selectedValue, onSelect)
          }}
          options={_.map(items, (item) => ({
            key: item.key,
            text: item.label,
            value: item.value
          }))}
          className="vehicle_add_dropdown"
        />
      </div>
    )
  }

  vehicleDetailsManual = () => {
    const { isLoading, t } = this.props
    const { fields, makes, years, models, sub_models, engines } = this.state
    const { year, make, model, sub_model, miles, engine } = fields
    return (
      <React.Fragment>
        <div style={{ marginTop: "10px" }}>
          <div style={{ height: "80px", overflow: "scroll" }}>
            <Form>
              <Form.Field required>
                <label>{t("yearLbl")}</label>
                {this._renderSearch({
                  key: "year",
                  value: year.value,
                  items: years,
                  isHidden: this.state.hide_years,
                  emptyMessage: t("emptyYear"),
                  placeholderText: t("yearsPlaceholder"),
                  onSelect: () => this._fetchMakes()
                })}
              </Form.Field>
              <Form.Field required>
                <label>{t("makeLbl")}</label>
                {this._renderSearch({
                  key: "make",
                  value: make.value,
                  items: makes,
                  isHidden: this.state.hide_makes,
                  emptyMessage: t("emptyMake"),
                  placeholderText: t("makesPlaceholder"),
                  isLoading: this.state.loadingMakes,
                  onSelect: () => this._fetchModels()
                })}
              </Form.Field>
              <Form.Field required>
                <label>{t("modelLbl")}</label>
                {this._renderSearch({
                  key: "model",
                  value: model.value,
                  items: models,
                  isHidden: this.state.hide_models,
                  emptyMessage: t("emptyModel"),
                  placeholderText: t("modelsPlaceholder"),
                  isLoading: this.state.loadingModels,
                  onSelect: () =>
                    this.setState(
                      {
                        engines: [],
                        fields: {
                          ...this.state.fields,
                          sub_model: { ...sub_model, value: "", invalid: false },
                          engine: { ...engine, value: "", invalid: false }
                        }
                      },
                      () => {
                        cache.set("vehicleDetails-fields", fields)
                        this._fetchVehicle()
                      }
                    )
                })}
              </Form.Field>
              <Form.Field required>
                <label>{t("subModelLbl")}</label>
                {this._renderSearch({
                  key: "sub_model",
                  value: sub_model.value,
                  items: this.subModelFilterManual(sub_models),
                  isHidden: this.state.hide_sub_models,
                  emptyMessage: t("emptySubModel"),
                  placeholderText: t("subModelsPlaceholder"),
                  onSelect: () => {
                    this._fetchEngines()
                  }
                })}
              </Form.Field>
              <Form.Field required>
                <label>{t("engineLbl")}</label>
                {this._renderSearch({
                  key: "engine",
                  value: engine.value,
                  items: engines,
                  isHidden: this.state.hide_engines,
                  isLoading: this.state.loadingEngines,
                  emptyMessage: t("emptyEngine"),
                  placeholderText: t("enginesPlaceholder")
                })}
              </Form.Field>
              <Form.Field required>
                <span className="required-field-miles">
                  <label className="required-field">{t("milesPlaceholder")}</label>
                  <small className="no-vehicle-miles-label">{t("milesSubLbl")}</small>
                </span>
                <Input
                  refs="miles"
                  size="small"
                  name="miles"
                  data-qa="registration-miles-input-field"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  type="number"
                  value={miles.value}
                  onChange={this._handleInput}
                  placeholder={t("milePlaceholder")}
                  min="0"
                />
              </Form.Field>
            </Form>
          </div>
          <SemanticButton
            fluid
            disabled={
              year.value &&
              make.value &&
              model.value &&
              sub_model.value &&
              engine.value &&
              miles.value &&
              miles.value.length !== 0 &&
              !isLoading
                ? false
                : true
            }
            className="noVehicleManual-save-button"
            //   className="vehicle_add_button"
            content={isLoading ? <MiniLoader /> : "Add a new car"}
            onClick={this._onClickNext}
            style={{
              backgroundColor: "#E95832",
              color: "#ffffff",
              marginTop: "12px",
              paddingButton: "10px"
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  noVehicleMainContent = () => {
    const { user, t, isGeneric } = this.props

    return (
      <div>
        <NotificationContainer />
        <div style={{ display: "flex", flexDirection: "column", height: "100% !important" }}>
          <p className="vehicle_add_tiltle">Add Your First Car</p>
          <div className="vehicle_add_carcover">
            <Image src={CarCoverBlue} size="big" />
          </div>
          <p className=".vehicle_add_tag_line">And set up easy maintenance and repair today</p>
          <div className="vehicle_add_container">
            <TabBarWithRender
              tabsList={[
                {
                  label: "VIN",
                  content: () => <>{this.vehicleDetailsVIN()}</>
                },
                {
                  label: "Plates",
                  content: () => <>{this.vehicleDetailsPlateNumber()}</>
                },

                {
                  label: "Car Details",
                  content: () => <>{this.vehicleDetailsManual()}</>
                }
              ]}
            />
          </div>
          {/* </div> */}
        </div>
      </div>
    )
  }

  render() {
    const {
      user,
      t,
      availableMembershipPlans,
      prepopulatedServices,
      activeVehicleId,
      location,
      carperks,
      isLoggedIn,
      isGeneric
    } = this.props
    const next = nextMembershipUpgrades(user, availableMembershipPlans)

    if (carperks && (!isLoggedIn || isGeneric)) {
      const params = new URLSearchParams(location.search)
      params.set("step", "1")

      return (
        <Redirect
          to={{ pathname: location.pathname, hash: location.hash, search: params.toString() }}
        />
      )
    }

    if (this.state.gotoNext) {
      const { is_uber_user_and_no_vehicle } = this.props.user

      if (is_uber_user_and_no_vehicle != null && is_uber_user_and_no_vehicle == true) {
        window.location.replace(
          `${process.env.REACT_APP_API_URL}/api/v4/customers/auth/uber?add_services%5Bservices%5D%5B%5D%5Breference_id%5D=rideshare_visual_inspection`
        )
        return <Loader2 />
      }

      if (prepopulatedServices) {
        let url = `${getUrlProtocol()}${getApiUrl()}${PREPOPULATE_URL}`
        const params = `?${prepopulatedServices.join(
          "&"
        )}&vehicle_id=${activeVehicleId}&auth_token=${user.authentication_token}`
        window.location.replace(url + params)
        return
      }

      const { selectMembership, dashboard } = routerPaths
      const pathname =
        next && !/no_vehicle/.test(window.location.href) ? selectMembership : dashboard

      return <Redirect to={{ pathname: pathname, search: location.search }} />
    }
    return (
      <React.Fragment>
        <div>{this.noVehicleMainContent()}</div>
      </React.Fragment>
    )
  }

  _isPresent(value) {
    return !!value
  }

  _isPositive(value) {
    return parseInt(value) > 0
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _onFieldChange(key, value, onSelect) {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      },
      onSelect
    )
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props
    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (!firstInvalidKey && callback) callback()
      else {
        store.dispatch(
          Notifications.error({
            title: t("errorText"),
            message: `${t("errorPopupMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }
}

function mapStateToProps(state, ownProps) {
  const { membershipData, service } = state
  const user = state.user || {}
  //   const pathName = ownProps.match.path
  const states = state.user.states

  return {
    user: user,
    // originPath: pathName,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading,
    isGeneric: user.generic,
    firstName: user.firstName,
    lastName: user.lastName,
    activeVehicleId: user.activeVehicleId,
    prepopulatedServices: service.prepopulatedServices,
    availableMembershipPlans: membershipData.availableMembershipPlans || [],
    activeMembershipPlan: user.membership || {},
    error: user.error,
    userStates: states
  }
}

export default connect(mapStateToProps, {
  addNewVehicle,
  me,
  setLoading,
  setNotLoading,
  getMembershipPlans,
  updateInfo,
  addNewVehicleByVin,
  addNewNoVehicleByPlate,
  fetchUserStates
})(withTranslation("vehicleDetails")(VehicleDetails))
