import React, { Component } from "react"
import { connect } from "react-redux"
import { logoForShop } from "../../shared_component/utils/shopLogos"
import { deleteShop } from "../actions/shop"
import { selectTime } from "../actions/appointment"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { updateCart, me, setLoading, setNotLoading } from "../actions/user"
import CartPanel from "../components/Cart/CartPanel"
import ShopAppointmentPicker from "../components/ShopAppointmentPicker"
import GenericPanel from "../components/GenericPanel"
import { Link } from "react-router-dom"
import Button from "../components/Button"
import ModalDialog from "../components/ModalDialog"
import BreadcrumbNav from "../components/BreadcrumbNav"
import { SERVICES, SHOP, CHECKOUT } from "../components/BreadcrumbNav"
import { constants } from "caradvise_shared_components"
import CartButtons from "../components/Cart/CartButtons"
import { isEbayCart } from "../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import { routerPaths } from "../constants/paths"
import { Redirect } from "react-router"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { appointmentDateTimeSelectedEvents } from "../../shared_component/utils/googleAnalyticsHelpers"

export function WalkInWarningModal({ cartId, t }) {
  let buttons = [
    {
      text: t("walkInWarningModal:bookWalkInText"),
      colorStyle: "orange",
      trackingClassName: "gtm-cart-checkout",
      linkTo: `/carts/${cartId}/confirm`,
      appendClose: true,
      warning: true
    }
  ]

  return (
    <ModalDialog
      buttonText={t("walkInWarningModal:bookWalkInText")}
      buttons={buttons}
      colorStyle="orange"
      centerButtons
      headerTitle={t("walkInWarningModal:sameDayWalkInText")}
    >
      <p>{t("walkInWarningModal:sameDayWalkInDesc1")}</p>
      <p>{t("walkInWarningModal:sameDayWalkInDesc2")}</p>
    </ModalDialog>
  )
}

class Schedule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: null,
      updatingCart: false
    }

    this.dateTimeSelected = this.dateTimeSelected.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  async dateTimeSelected(date) {
    const { activeVehicle } = this.props
    let opts = {
      successCallback: async (response) => {
        await appointmentDateTimeSelectedEvents({ response, activeVehicle })
      }
    }
    if (date) {
      // this.setState({ updatingCart: true })

      // TODO: this updateCart api call returns the entire serialized cart, including price estimates.
      // Refactor to have the api return only the updated appointment time attrs.
      // To do this, #updateCart will need to update how it processes its data.
      const date_str = date.format("MM-DD-YYYY HH:mm")
      const event_type = date.format("HH:mm") == "03:00" ? "set_walk_in" : "set_time"
      await this.props.updateCart(
        [{ event: event_type, appointment_datetime: date_str }],
        this.props.activeVehicle.id,
        null,
        true,
        opts
      )
      await trackEvent("click-continue")
      this.setState({ date: date, updatingCart: false })
    }
  }

  renderSelectedShop() {
    const { t, shop } = this.props

    return (
      <div className="yourShop">
        <div className="yourShopLogo">
          <img src={logoForShop(shop)} className="logoShop" />
        </div>
        <div className="yourShopInfo">
          <strong>{shop.company && shop.company.name ? shop.company.name : shop.name}</strong>
          <br />
          {shop.address_line1} {shop.city}
        </div>
        {shop.warranty_info != null && shop.warranty_info.length > 0 ? (
          <div className="selectedShopWarranty">
            <Link to={`/warranty`}>{t("viewWarrantyDetailsLbl")} &gt;</Link>
          </div>
        ) : null}
      </div>
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss",
          callback: () => {
            window.location.href = "/#/"
          }
        }
      })
    )
  }

  render() {
    const { cart, shop, user, t } = this.props
    let { date, updatingCart } = this.state
    const { id, disable_shop_choice, order_services, appointment_datetime } = cart
    const hasNoServices = (order_services || []).length == 0
    const isEbay = isEbayCart(user) && shop

    if (!shop) return <Redirect to={routerPaths.shop} />

    return (
      <div className="dashContainer">
        <div className="dashLeftContainer">
          <GenericPanel>
            <BreadcrumbNav
              back={isEbay || disable_shop_choice ? "" : SHOP}
              forward={hasNoServices ? SERVICES : CHECKOUT}
              forwardDisabled={!appointment_datetime}
              currentName={t("selectDateTimeText")}
              cartId={id}
            />

            {shop ? this.renderSelectedShop() : null}

            <div>
              <ShopAppointmentPicker
                user={this.props.user}
                onSelect={this.dateTimeSelected}
                orderLikeObject={cart}
              />
            </div>

            <CartButtons
              shopLikeObject={shop}
              orderLikeObject={cart}
              path={"#/schedule"}
              confirmEnabled={!updatingCart}
            />
          </GenericPanel>
        </div>
        <div className="dashRight">
          <CartPanel />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  let origin = state.origin.origin
  let cart = (user.active_carts || []).find((c) => c.vehicle_id == user.activeVehicleId) || {}
  let addedServices = cart.order_services || []
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let shop = cart.shop || {}
  return {
    origin,
    isLoggedIn: !!user.authentication_token,
    user,
    shop,
    addedServices,
    activeVehicle,
    loading: user.loading,
    selectedTime: state.selectedTime,
    cart
  }
}

export default connect(mapStateToProps, {
  updateCart,
  deleteShop,
  selectTime,
  me,
  setLoading,
  setNotLoading
})(withTranslation("schedule")(Schedule))
