import React from "react"

export const FilterChip = ({ items, activeFilter, onFilterClick }) => {
  return (
    <div className="filter-chip-container">
      {items &&
        items.map((item) => (
          <div
            className={`button-chip-container ${activeFilter === item ? "active" : ""}`}
            key={item}
            onClick={() => onFilterClick(item)}
          >
            {item}
          </div>
        ))}
    </div>
  )
}
