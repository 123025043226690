import React, { useEffect, useState } from "react"
import MilesDownLogo from "../../../images/rewards/miles-down-arrow.svg"
import MilesPendingLogo from "../../../images/rewards/time-icon.svg"
import MilesCompleteLogo from "../../../images/rewards/miles-complete.svg"
import AccountLockLogo from "../../../images/rewards/create-account-lock.svg"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import ButtonWithIcon from "../../../shared_component/components/Button/ButtonWithIcon"
import { connect } from "react-redux"
import { gotoRewards } from "../../../shared_component/constants/rewards"
import { withRouter } from "react-router-dom"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const EarnMilesPerOrder = ({ t, user, history, estimateEarnMiles, closeSidebar }) => {
  const [isExapnded, setIsExpanded] = useState(false)

  const handleAccordian = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div className="earn-miles-per-order">
      <div className="earn-miles-box">
        <p className="earn-miles-header">
          {user.generic
            ? t("createAccountAndEarnWithCarAdvise")
            : `${t("willEarn")} ${numberFormater(estimateEarnMiles)} ${t("miles")}`}
        </p>
        <ReactSVG className="accordian-logo" onClick={handleAccordian} src={MilesDownLogo} />
      </div>
      {/* As of now we dont have complete order concept on rewards */}
      {/* <div className="miles-order-box">
        <div className="miles-order-header-box">
          <ReactSVG src={MilesPendingLogo} />
          <p className="miles-order-title">{t("completeOrder")}</p>
        </div>
        <p className="miles-order-value">5,000 {t("miles")}</p>
      </div> */}
      {isExapnded && (
        <>
          <div className="miles-order-box">
            <div className="miles-order-header-box">
              <ReactSVG src={MilesCompleteLogo} />
              <p className="miles-order-title">{t("bookFirstMaintenance")}</p>
            </div>
            <p className="miles-order-value">
              {estimateEarnMiles ? numberFormater(estimateEarnMiles) : 0} {t("miles")}
            </p>
          </div>

          <div className="miles-horizontal-divider"></div>

          <p className="earn-more-miles">
            {t("milesInstruciton1")} (
            <ReactSVG wrapper="span" src={MilesPendingLogo} />) {t("milesInstruciton2")} (
            <ReactSVG wrapper="span" src={MilesCompleteLogo} />) {t("milesInstruciton3")}
          </p>
          <p className="earn-more-miles">{t("earnMoreMiels")}</p>
          <p className="earn-more-miles">{t("mileUsedLable")}</p>
          {user.generic && (
            <ButtonWithIcon
              onClick={() => {
                gotoRewards(history)
                closeSidebar()
              }}
              logo={AccountLockLogo}
              btnLabel={t("createAccountAndEarn")}
            />
          )}
        </>
      )}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    estimateEarnMiles: state.rewards.estimateEarnMiles
  }
}

export default withRouter(
  connect(mapStateToProps, null)(withTranslation("earnMilesPerOrder")(EarnMilesPerOrder))
)
