import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import HowRewardsWorks from "./HowRewardsWorks"
import InviteFriendsRewards from "./InviteFriendsRewards"
import SponserPromotions from "./SponserPromotions"
import HowEarnMiles from "./HowEarnMiles"
import RewardMilesAndEarn from "./RewardMilesAndEarn"
import {
  COOKIE_TRUE,
  OPEN_REWARSD_WORKS_DIALOG,
  REWARDS
} from "../../../shared_component/constants/rewards"
import EarnRewardsSuccessContainer from "./EarnRewardsSuccessContainer"
import { routerPaths } from "../../../v1/constants/paths"
import { connect } from "react-redux"
import { getLocalStorage, setLocalStorage } from "../../../shared_component/utils/cookies"
import FeatureRewards from "./FeatureRewards"
import { logFirebaseAnalyticsScreenName } from "../../../shared_component/utils/googleAnalyticsHelpers"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"

const RewardsIndex = ({ t, history, user, estimateEarnMiles, isFirstOrder }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const shouldDialogOpen = getLocalStorage(OPEN_REWARSD_WORKS_DIALOG)
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  useEffect(() => {
    logFirebaseAnalyticsScreenName(REWARDS)
    identifyEvent({
      traits: {},
      userId: user.id
    })
    trackEvent("rewards-landing-page", {})

    if (shouldDialogOpen === COOKIE_TRUE) {
      setOpenDialog(true)
      setLocalStorage(OPEN_REWARSD_WORKS_DIALOG, false)
    }
  }, [shouldDialogOpen])

  if (isRewardsFeature !== undefined && !isRewardsFeature) {
    history.push({
      pathname: routerPaths.dashboard
    })
  }

  return (
    <div className="rewards-index-container">
      <div className="rewards-header-box">
        <p className="rewards-header">{t("rewardsProgram")}</p>
        <p className="rewards-how-works" onClick={() => setOpenDialog(true)}>
          {t("howItWorks")}
        </p>
      </div>

      <RewardMilesAndEarn />
      {/* Invite friends is not in scope of first release of reawards */}
      {/* <InviteFriendsRewards user={user} /> */}
      {isFirstOrder && <FeatureRewards user={user} estimateEarnMiles={estimateEarnMiles} />}
      {/* Sponsors are not in scope of first release of rewards */}
      {/* <SponserPromotions /> */}
      <HowEarnMiles user={user} />
      <HowRewardsWorks setOpenDialog={setOpenDialog} openDialog={openDialog} />
      <EarnRewardsSuccessContainer />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    estimateEarnMiles: state.rewards && state.rewards.estimateEarnMiles,
    isFirstOrder: state.user.total_shop_orders === 0
  }
}

export default connect(mapStateToProps, null)(withTranslation("rewardsIndex")(RewardsIndex))
