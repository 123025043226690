import React, { Component } from "react"
import cartIconLogo from "../../../images/cart_icon.svg"
import EditCartIcon from "../../../images/edit-cartIcon.svg"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import CartSidePanel from "./CartSidePanel"
import { Image } from "semantic-ui-react"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { withRouter } from "react-router-dom"
import { CART_HIDE_PATHS } from "../../../v1/constants/tires"
import { constants } from "caradvise_shared_components"
import { WALK_IN_ACTIVE } from "../../../shared_component/constants/common"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

class CartIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }
  handlerEvent = (res) => {
    if (res.detail) {
      this.setState({ visible: true })
    } else {
      this.setState({ visible: false })
    }
  }

  componentDidMount() {
    document.addEventListener("cartSidePanelOpen", this.handlerEvent)
  }

  componentWillUnmount() {
    document.removeEventListener("cartSidePanelOpen", this.handlerEvent)
  }

  close = () => {
    this.setState({ visible: false })
  }

  render() {
    const { cart, isLoggedIn, hideChrome, activeVehicleId, location, isCart, order } = this.props
    const { visible } = this.state
    const cartQuantity = cart && cart.order_services && cart.order_services.length
    const includesStatuses = [ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE]
    if (!isLoggedIn || hideChrome || !isCart || (order && !includesStatuses.includes(order.status)))
      return null
    const showModifyAppointment = order && cart && includesStatuses.includes(order.status)
    return (
      <>
        <div
          className="cart-icon-container"
          style={{ display: visible ? "none" : "" }}
          onClick={() => {
            this.setState({ visible: !visible })
            trackEvent("cart-view-clicked", {
              eventSourceName: "Checkout_2.0"
            })
          }}
        >
          <div className={CART_HIDE_PATHS.includes(location.pathname) ? "hide-icon" : ""}>
            <span className="notify-badge">{cartQuantity}</span>
            <Image
              src={showModifyAppointment ? EditCartIcon : cartIconLogo}
              className="cart-icon"
            />
          </div>
        </div>
        <CartSidePanel
          visible={visible}
          close={this.close}
          cart={cart}
          cartQuantity={cartQuantity}
        />
      </>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props, true)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user || {},
    activeVehicle,
    order,
    isLoggedIn: state.user && state.user.id,
    hideChrome: state.framework && state.framework.hideChrome,
    activeVehicleId:
      state.user.activeVehicleId ||
      (state.user &&
        state.user.vehicles &&
        state.user.vehicles.length > 0 &&
        state.user.vehicles[0].id)
  }
}
export default withRouter(connect(mapStateToProps)(withTranslation("cartPanel")(CartIcon)))
