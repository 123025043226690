import React, { useEffect, useState } from "react"
import { Radio } from "semantic-ui-react"
import ApplyRewards from "./ApplyRewards"
import {
  getEarnedMiles,
  getApplyMilesAndDiscount,
  applyRedeemMiles,
  resetMilesRewardsState,
  setServiceRemovingFlag
} from "../../../v1/actions/rewards"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { connect } from "react-redux"
import { updateCart } from "../../../v1/actions/user"
import { withTranslation } from "react-i18next"
import { getMilesFromDollar } from "../../../shared_component/utils/helpers"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { ORDER_APPROVE } from "../../../v1/constants/order"
import { convertCurrencyToNumber } from "../../../shared_component/constants/rewards"

const RewardMilesPayment = ({
  getEarnedMiles,
  getApplyMilesAndDiscount,
  t,
  activeVehicleId,
  cart,
  user,
  anyOrderServiceLackPrice,
  module,
  onApprovalChange,
  services,
  order,
  rewards
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [appliedMiles, setAppliedMiles] = useState(0)
  const [earnedMiles, setEarnedMiles] = useState(0)
  const [redeemMilesIntoUSD, setRedeemedMilesIntoUSD] = useState(0)
  const [wantToApplyMiles, setWantToApplyMiles] = useState(
    module === ORDER_APPROVE ? !!order.redeem_discount : !!cart.redeem_discount || false
  )
  const [milesPerDollar, setMilesPerDollar] = useState(0)
  const [isApiInProgress, setIsApiInProgress] = useState(false)

  const handleDue = () => {
    if (order) {
      const tax = Number(order.tax_amount) || 0
      const shop = Number(order.pre_tax_sub_total) || 0
      const previousPayments = Number(order.previous_payments) || 0
      const totalDue = shop + tax - previousPayments
      return Math.floor(+order.redeem_discount + totalDue)
    }
  }

  const getEstSubtotal = () => {
    return Math.floor(+cart.redeem_discount + cart.estimated_total_pre_tax - cart.transaction_fee)
  }

  const handleEarnedRewards = async () => {
    try {
      const rewardsRes = await getEarnedMiles()

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("error"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      } else {
        const pendingMiles =
          rewardsRes.result &&
          rewardsRes.result.pending_miles &&
          rewardsRes.result.pending_miles.toString()

        const miles =
          (module === ORDER_APPROVE ? handleDue() : getEstSubtotal()) *
          (rewardsRes.result && +rewardsRes.result.miles_per_dollar)

        const calculatedEarnedMiles = miles <= 0 ? 0 : miles

        setEarnedMiles(
          handleCartEstTotal(pendingMiles, rewardsRes.result.miles_per_dollar)
            ? pendingMiles
            : calculatedEarnedMiles > +pendingMiles || calculatedEarnedMiles === 0
            ? pendingMiles
            : calculatedEarnedMiles
        )

        const applyingMiles =
          getMilesFromDollar(
            rewardsRes.result && rewardsRes.result.miles_per_dollar,
            module === ORDER_APPROVE ? order && order.redeem_discount : cart && cart.redeem_discount
          ) || pendingMiles

        setAppliedMiles(
          handleCartEstTotal(applyingMiles, rewardsRes.result.miles_per_dollar)
            ? applyingMiles
            : calculatedEarnedMiles > +applyingMiles || calculatedEarnedMiles === 0
            ? applyingMiles
            : calculatedEarnedMiles
        )
      }
    } catch (error) {
      console.log(t("errorInHandledEarnedRewards"), error)
    }
  }

  const handleApplyMiles = async () => {
    try {
      const redeemMiles = await getApplyMilesAndDiscount({ miles: +appliedMiles })

      if (redeemMiles.error) {
        store.dispatch(
          Notifications.error({
            title: "Error",
            message: `${redeemMiles.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      } else {
        const newAppliedMiles = redeemMiles.result.amount
        setRedeemedMilesIntoUSD(newAppliedMiles)
        setMilesPerDollar(redeemMiles.result.miles_per_dollar)

        wantToApplyMiles && saveToStore(appliedMiles)
      }
    } catch (error) {
      console.log(t("errorInHandleApplyMiles"), error)
    }
  }

  const trackAppliedMilesGAEvent = (miles) => {
    const appliedMilesObj = {
      milesApplied: miles || appliedMiles
    }
    trackFirebaseAnalytics({
      event: gaJSON.miles_selected,
      params: {
        miles_applied: appliedMilesObj.milesApplied
      }
    })

    identifyEvent({
      traits: appliedMilesObj,
      userId: user.id
    })
    trackEvent("miles-applied", appliedMilesObj)
  }

  const handleCustomizeMiles = (miles) => {
    setAppliedMiles(miles)
    if (wantToApplyMiles) {
      trackAppliedMilesGAEvent(miles)
    }
  }

  const saveToStore = async (miles) => {
    setIsApiInProgress(true)
    try {
      if (module === ORDER_APPROVE) {
        !!handleDue() &&
          (await onApprovalChange(services, true, {
            rewards: true,
            miles: !!handleDue() ? miles || appliedMiles : 0
          }))
      } else {
        await store.dispatch(
          updateCart(undefined, activeVehicleId, undefined, undefined, {
            redeem_miles: miles || appliedMiles
          })
        )
      }
    } catch (err) {
      console.log(t("errorInsaveToStore"), err)
    } finally {
      setIsApiInProgress(false)
    }
  }

  const resetRewardsStateRedux = async () => {
    setIsApiInProgress(true)
    try {
      module !== ORDER_APPROVE && store.dispatch(resetMilesRewardsState())
      if (module === ORDER_APPROVE) {
        await onApprovalChange(services, true, {
          rewards: true,
          miles: 0
        })
      } else {
        await store.dispatch(
          updateCart(undefined, activeVehicleId, undefined, undefined, {
            redeem_miles: "0"
          })
        )
      }
    } catch (err) {
      console.log(t("errorInresetRewardsStateRedux"), err)
    } finally {
      setIsApiInProgress(false)
    }
  }

  useEffect(() => {
    handleEarnedRewards()
  }, [])

  useEffect(() => {
    !!+appliedMiles && handleApplyMiles()
  }, [appliedMiles])

  useEffect(() => {
    if (module !== ORDER_APPROVE && rewards && rewards.isServiceRemoving) {
      handleEarnedRewards()
      setWantToApplyMiles(false)
    }
  }, [rewards.isServiceRemoving])

  useEffect(() => {
    handleEarnedRewards()
  }, [cart.estimated_total])

  useEffect(() => {
    if (module !== ORDER_APPROVE && !anyOrderServiceLackPrice && appliedMiles && wantToApplyMiles) {
      store.dispatch(
        applyRedeemMiles({
          miles: appliedMiles
        })
      )
    } else if (module !== ORDER_APPROVE && anyOrderServiceLackPrice) {
      resetRewardsStateRedux()
    }
  }, [anyOrderServiceLackPrice, appliedMiles, wantToApplyMiles])

  const handleCartEstTotal = (redeemMiles, milesDollar) => {
    return module === ORDER_APPROVE
      ? handleDue() >=
          convertCurrencyToNumber((+redeemMiles || appliedMiles) / (+milesDollar || milesPerDollar))
      : getEstSubtotal() >=
          convertCurrencyToNumber((+redeemMiles || appliedMiles) / (+milesDollar || milesPerDollar))
  }

  const MILES_FROM_EST_TOTAL =
    (module === ORDER_APPROVE ? handleDue() : getEstSubtotal()) * milesPerDollar

  const isRewardsAllowed =
    (module === ORDER_APPROVE ? (handleDue() <= 0 ? true : !handleDue()) : getEstSubtotal() <= 0) ||
    isApiInProgress

  return (
    <div className="rewards-miles-payment-container">
      <div className="rewards-toggle">
        {wantToApplyMiles ? (
          <p className="rewards-applied-miles">
            {appliedMiles} {t("milesHaveApplied")}
          </p>
        ) : (
          <p className="rewards-used-miles">
            {t("redeemAll")} {appliedMiles} {t("miles")}
          </p>
        )}
        <Radio
          className="rewards-toogle-checkbox"
          checked={wantToApplyMiles}
          disabled={!+appliedMiles}
          onChange={(e, { checked }) => {
            if (isRewardsAllowed) {
              return
            }
            setWantToApplyMiles(checked)
            if (module !== ORDER_APPROVE) {
              store.dispatch(
                setServiceRemovingFlag({
                  flag: false
                })
              )
            }
            if (checked) {
              trackAppliedMilesGAEvent()
              saveToStore()
            } else {
              resetRewardsStateRedux()
            }
          }}
          toggle
        />
      </div>
      <p className="rewards-applied-miles">
        {" "}
        = {appliedMiles ? redeemMilesIntoUSD : 0.0} {t("orderTotal")}
      </p>
      <p
        className="rewards-customize-miles"
        onClick={() => {
          if (isRewardsAllowed) {
            return
          }
          if (+appliedMiles) {
            setOpenDialog(true)
            trackFirebaseAnalytics({
              event: gaJSON.customize_miles
            })
            identifyEvent({
              traits: {},
              userId: user.id
            })
            trackEvent("miles-customize-link-click", {})
          }
        }}
      >
        {t("customizeMiles")}
      </p>
      {openDialog && (
        <ApplyRewards
          handleCustomizeMiles={handleCustomizeMiles}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          earnedMiles={
            handleCartEstTotal()
              ? earnedMiles
              : MILES_FROM_EST_TOTAL > earnedMiles || MILES_FROM_EST_TOTAL === 0
              ? earnedMiles
              : MILES_FROM_EST_TOTAL
          }
          appliedMiles={
            handleCartEstTotal()
              ? appliedMiles - (appliedMiles % milesPerDollar)
              : MILES_FROM_EST_TOTAL > appliedMiles || MILES_FROM_EST_TOTAL === 0
              ? appliedMiles
              : MILES_FROM_EST_TOTAL
          }
          milesPerDollar={milesPerDollar}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const user = state.user || {}
  const rewards = state.rewards
  let isCart
  let cart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    rewards: rewards,
    activeVehicleId: user.activeVehicleId,
    isCart: isCart,
    cart: cart,
    user: user,
    anyOrderServiceLackPrice: cart && cart.any_order_service_lacks_price
  }
}

export default connect(mapStateToProps, { getEarnedMiles, getApplyMilesAndDiscount })(
  withTranslation("rewards")(RewardMilesPayment)
)
