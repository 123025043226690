import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Menu, Sidebar } from "semantic-ui-react"
import ReactSVG from "react-svg"
import { updateCart } from "../../../v1/actions/user"
import { fetchCarAdviseRecemendedServices } from "../../../v1/CarAdvise2/actions/maintenance"
import ServiceSection from "./CartServiceSection"
import SidePanelFooter from "./CartSidePanelFooter"
import { fetchOEMCurrentIntevals } from "../../../v1/actions/maintenanceSchedules"
import EarnMilesPerOrder from "../Rewards/EarnMilesPerOrder"
import dollorLabelLogo from "../../../images/dollor_label_vector.svg"
import { DemandBanner } from "../../../shared_component/components/Banners/DemandBanner"
import { constants } from "caradvise_shared_components"
import { WALK_IN_ACTIVE } from "../../../shared_component/constants/common"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

class CartSidePanel extends Component {
  state = {
    maintenanceScedulesServices: [],
    windowWidth: window.innerWidth,
    isServiceComponentScrolled: false,
    isFooterExpanded: false,
    isMiniCart: true
  }
  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth)
  }

  handleScrollChange = (scrolled) => {
    this.setState({ isServiceComponentScrolled: scrolled })
  }

  handleFooterToggle = (toggle) => {
    this.setState({ isFooterExpanded: toggle })
  }

  render() {
    const { windowWidth, isServiceComponentScrolled, isFooterExpanded } = this.state
    const {
      visible,
      close,
      cartQuantity,
      cart,
      t,
      user,
      updateCart,
      isCart,
      activeVehicle,
      isFirstOrder,
      order
    } = this.props
    const showDemmandBanner =
      cart &&
      cart.shop &&
      !cart.shop.cdk_dealer &&
      (cart.shop || (cart.total_savings && cart.total_savings > 0)) &&
      cart.order_services &&
      cart.order_services.length > 0

    const isRewardsFeature =
      window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

    const includesStatuses = [ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE]
    const showModifyAppointment = order && cart && includesStatuses.includes(order.status)

    return (
      <div className="cart-side-panel-container">
        <Sidebar
          as={Menu}
          animation="overlay"
          direction="right"
          vertical
          visible={visible}
          onHide={() => close()}
          className="side-panel-container"
        >
          <div className="side-panel_header">
            <p className="secondary-font headings-label">
              {showModifyAppointment
                ? t("activeOrderLbl", { orderId: order.id })
                : isCart
                ? t("myCartLabel")
                : t("approvalsFollowup:orderSummaryLbl")}
            </p>
            <ReactSVG
              src="images/close_icon_vector.svg"
              onClick={() => close()}
              className="icon-cursor-pointer"
            />
          </div>
          <ServiceSection
            onScrollChange={this.handleScrollChange}
            isFooterExpanded={isFooterExpanded}
            cart={cart}
            cartQuantity={cartQuantity}
            t={t}
            user={user}
            updateCart={updateCart}
            closeSidebar={close}
            shop={cart.shop}
            isCart={isCart}
            activeVehicle={activeVehicle}
            isFirstOrder={isFirstOrder}
            close={close}
            showModifyAppointment={showModifyAppointment}
            isMiniCart={this.state.isMiniCart}
          />

          <div className="cart-bottom">
            {!isFooterExpanded && showDemmandBanner && windowWidth <= 500 && (
              <DemandBanner iconUrl={dollorLabelLogo} titleText={t("header:alwaysBetterLabel")} />
            )}
            <SidePanelFooter
              user={user}
              cart={cart}
              t={t}
              shop={cart.shop}
              sidePanelClose={close}
              isCart={isCart}
              isDefaultOpen={!isCart}
              isServiceComponentScrolled={isServiceComponentScrolled}
              handleFooterToggle={this.handleFooterToggle}
              isActiveOrder={order}
            />
          </div>
        </Sidebar>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user || {},
    activeVehicle,
    order,
    isFirstOrder: state.user.total_shop_orders === 0
  }
}
export default connect(mapStateToProps, {
  updateCart,
  fetchCarAdviseRecemendedServices,
  fetchOEMCurrentIntevals
})(withTranslation("cartPanel")(CartSidePanel))
