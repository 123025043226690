import React from "react"

import { connect } from "react-redux"

import _ from "lodash"

import { withTranslation } from "react-i18next"

import StarIcon from "../../../images/shops/star_icon.svg"
import CarAdviseIcon from "../../../images/shops/caradvise_icon.svg"
import EmptyShopLogo from "../../../images/shops/empty_shop_logo.svg"
import closeIconImage from "../../../../../images/dashboard/close-icon.svg"

import { Icon, Image } from "semantic-ui-react"
import { getActiveVehicle } from "../../../../helpers/vehicleHelpers"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"
import { getNewTiresServiceFromServices } from "../../../helpers/orderServiceHelpers"
import SavingsRibbon from "../../../../components/ShopSearch/SavingsRibbon"
import { numberFormatToCurrency } from "../../../../../shared_component/utils/currency"
import ShopCheckmark from "../../../../../images/map/shop-selected.svg"

const ShopItemComponent = (props) => {
  const {
    shop,
    onSelectShopClick,
    isPopUpComponent = false,
    t,
    onClose,
    cart,
    showMapExpanded = false,
    isMobileViewExpanded = false,
    setShopSelected
  } = props

  const isShopSelected = cart && cart.shop && cart.shop.id == shop.id

  const showPrices = () => {
    if (shop.price_estimates && shop.price_estimates.length > 0) {
      const index = shop.price_estimates.findIndex(
        (e) => e.shop_price_estimate == null || e.shop_price_estimate == ""
      )
      if (index > -1) {
        return false
      } else {
        return true
      }
    }
  }
  const shopDisplayPrice = () => {
    if (shop.price_estimates && shop.price_estimates.length > 0) {
      const index = shop.price_estimates.findIndex(
        (e) => e.shop_price_estimate == null || e.shop_price_estimate == ""
      )
      if (index > -1) {
        return t("common:priceAtShopLbl")
      } else {
        let price = 0
        shop.price_estimates.forEach((e) => {
          price = price + Number(e.shop_price_estimate)
        })
        return numberFormatToCurrency(price.toString())
      }
    }
  }
  const retailPrice = () => {
    if (shop.price_estimates && shop.price_estimates.length > 0) {
      const index = shop.price_estimates.findIndex(
        (e) => e.shop_price_estimate == null || e.shop_price_estimate == ""
      )
      if (index > -1) {
        return false
      } else {
        let price = 0
        shop.price_estimates.forEach((e) => {
          price = price + Number(e.shop_price_estimate)
        })
        return numberFormatToCurrency((price + shop.total_retail_savings).toString())
      }
    }
  }

  const onSelectClick = () => {
    onSelectShopClick && onSelectShopClick(shop)
    onClose && onClose()
  }

  const isHighlighted = cart && cart.shop && cart.shop.id == shop.id

  let vehicleTypeId
  if (props.user) {
    const activeVehicle = getActiveVehicle()
    vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
  }

  let logo = shop.logo_url ? (
    <img style={{ width: "40px" }} src={shop.logo_url} />
  ) : (
    <Image style={{ width: "40px" }} src={EmptyShopLogo} />
  )

  return (
    <div
      className={
        isPopUpComponent
          ? "shopItem shopItemPopup"
          : isHighlighted
          ? "shopItem shopItemHighlighted"
          : "shopItem shopItemHover"
      }
      onClick={() => {
        if (!isPopUpComponent) {
          onSelectClick()
        }
      }}
      style={
        shop.shop_featured && !isPopUpComponent
          ? { borderRadius: "0px 5px 5px 5px", border: "1px solid #282828" }
          : { borderRadius: "5px" }
      }
    >
      {isPopUpComponent && !isMobileViewExpanded && (
        <div className="closeIconContainer">
          <Image
            className="closeIcon"
            src={closeIconImage}
            onClick={() => {
              onClose && onClose()
            }}
          />
        </div>
      )}
      <div className="container closeIconStyle">
        {isMobileViewExpanded && (
          <Icon
            className="closeIcon"
            name="close black"
            onClick={() => {
              setShopSelected && setShopSelected(undefined)
            }}
          />
        )}
      </div>
      <div className={isPopUpComponent ? "container spacing" : "container"}>
        <div className="savingsRibbonContainer">
          <div className="shopPriceContainer">
            {shop.hasRetailSavings && !shop.cdk_dealer && vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID && (
              <SavingsRibbon
                isPopup={isPopUpComponent}
                isExpanded={showMapExpanded}
                savingsText={shop.total_retail_savings}
                containerStyle={{
                  marginTop: "2px",
                  width: "max-content"
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="container">
        {shop.average_rating && shop.average_rating >= 1 ? (
          <div className="iconContainer star">
            <Image src={StarIcon} />
            <span className="ratingText">
              {" "}
              {shop.average_rating && shop.average_rating != "0.0" ? shop.average_rating : "--"}
            </span>
          </div>
        ) : (
          ""
        )}
        {shop.completed_shop_orders_count && shop.completed_shop_orders_count >= 1 ? (
          <div className="iconContainer caradviseIcon">
            <Image src={CarAdviseIcon} />
            <span className="ratingText">
              {" "}
              {shop.completed_shop_orders_count
                ? `${shop.completed_shop_orders_count} `
                : "-- "}{" "}
              <span className="ratingText" style={{ fontWeight: 500 }}>
                {t("common:carAdvisors")}
              </span>
            </span>
          </div>
        ) : (
          ""
        )}
        {shop.company.pricing_disabled &&
        !shop.cdk_dealer &&
        vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID ? (
          <div />
        ) : !shop.company.pricing_disabled &&
          !shop.cdk_dealer &&
          vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID &&
          shop.price_estimates &&
          shop.price_estimates.length > 0 ? (
          !showPrices() ? (
            <div className="shopPrice">{t("common:priceAtShopLbl")}</div>
          ) : (
            <div />
          )
        ) : (
          <div className="shopPrice">
            {shop.cdk_dealer
              ? t("common:seeDealerPriceLbl")
              : vehicleTypeId === MD_HD_VEHICLE_TYPE_ID
              ? t("common:mdHdpricedAtShopLbl")
              : !!getNewTiresServiceFromServices(
                  cart && cart.order_services.length > 0 ? cart.order_services : []
                ) && shop.supports_tire_selection
              ? t("common:pricingAvailableLbl")
              : cart && cart.order_services.length > 0
              ? t("common:priceAtShopLbl")
              : ""}
          </div>
        )}

        {shop.hasRetailSavings && !shop.cdk_dealer && vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID && (
          <div className="savingsRibbonWeb">
            <SavingsRibbon
              isPopup={isPopUpComponent}
              isExpanded={showMapExpanded}
              savingsText={shop.total_retail_savings}
              containerStyle={{
                marginTop: "2px",
                width: "max-content"
              }}
            />
          </div>
        )}
      </div>
      <div className="container">
        <div
          className={
            isMobileViewExpanded
              ? "shopLogoContainer shopLogoContainerExpanded"
              : "shopLogoContainer"
          }
        >
          {logo}
        </div>

        <div className="shopName shopNameHeader">
          <span className="shopNameHeader" style={{ margin: "0px" }}>
            {shop.name.toUpperCase()}
          </span>
          <br />
          {isPopUpComponent && (
            <>
              <span className="shopNameSubtitle">{shop.address_line1}</span>
              <br />
              <span className="shopNameSubtitle">
                {shop.city}, {shop.state} {shop.zip}
                {" - "}
                <span className="shopNameSubtitle shopNameSubtitleDistance">
                  {shop && shop.distance_config ? shop.distance_config.display_value : ""}
                </span>
              </span>
            </>
          )}
          {!isPopUpComponent && (
            <span className="shopNameSubtitle">
              {shop.address_line1}, {shop.city}, {shop.state} {shop.zip}
              {" - "}
              <span className="shopNameSubtitle shopNameSubtitleDistance">
                {shop.distance_config.display_value}
              </span>
            </span>
          )}
        </div>
        {shop.company.pricing_disabled &&
        !shop.cdk_dealer &&
        vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID ? (
          <div className="shopPriceContainer">
            <span className="shopPrice">
              {shop.company.retail_savings != null && shop.company.retail_savings != ""
                ? t("common:percentOffRetailLbl", {
                    percent: parseInt(shop.company.retail_savings)
                  })
                : ""}
            </span>
          </div>
        ) : showPrices() &&
          !shop.company.pricing_disabled &&
          !shop.cdk_dealer &&
          vehicleTypeId !== MD_HD_VEHICLE_TYPE_ID &&
          shop.price_estimates &&
          shop.price_estimates.length > 0 ? (
          <div className="shopPriceContainer">
            <span className="shopPrice">{shopDisplayPrice()}</span>
            {shop && shop.total_retail_savings > 0 && (
              <span className="retailPrice">{retailPrice()}</span>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
      {isPopUpComponent && (
        <div
          className={`buttonStyle ${isShopSelected ? "selected-shop-button-style" : ""}`}
          onClick={() => {
            onSelectClick()
          }}
        >
          {isShopSelected ? (
            <span className="selected-shop-btn">
              {t("dashboardMapComponent:selectedShopBtn")} <Image src={ShopCheckmark} />
            </span>
          ) : (
            t("dashboardMapComponent:selectShopBtn")
          )}
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  return {
    user: user,
    cart
  }
}

export default connect(mapStateToProps)(
  withTranslation(["dashboardMapComponent", "common"])(ShopItemComponent)
)
