import React, { Component } from "react"
import { connect } from "react-redux"
import RegHeader from "../components/regHeader.js"
import RegFooter from "../components/regFooter.js"
import { Link } from "react-router-dom"
import { logoForShop } from "../../shared_component/utils/shopLogos"
import { withTranslation } from "react-i18next"

class Warranty extends Component {
  renderSelectedShop() {
    return (
      <div className="container">
        <div className="selectedShop">
          <div className="selectedShopLogo">
            <img src={logoForShop(this.props.shop)} className="logoShop" alt="Shop Logo" />
          </div>
          <div className="selectedShopInfo">
            <strong>
              {this.props.shop.company && this.props.shop.company.name
                ? this.props.shop.company.name
                : this.props.shop.name}
            </strong>
            <br />
            {this.props.shop.address_line1} {this.props.shop.city}
          </div>
        </div>
        <div className="warrantyInfo">
          <div dangerouslySetInnerHTML={{ __html: this.props.shop.warranty_info }} />
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <div className="container">
        <RegHeader skin={this.props.origin} />

        <div className="deprecated-content">
          <p className="introText">{t("warrantyDetailsLbl")}</p>
          {this.props.shop ? this.renderSelectedShop() : null}
          <p>
            <Link to={`/schedule`} className="btnBack">
              &lsaquo; {t("backLbl")}
            </Link>
          </p>
        </div>
        <RegFooter />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id === user.activeVehicleId)
  let shop = cart ? cart.shop : {}
  let vehicle = state.selectedVehicle.selectedVehicle
  let addedService = state.addedService
  let origin = state.origin.origin
  return {
    origin,
    isLoggedIn: !!user.authentication_token,
    user,
    shop,
    vehicle,
    addedService,
    selectedTime: state.selectedTime
  }
}

export default connect(mapStateToProps, {})(withTranslation("warranty")(Warranty))
