export const SAVE_TIRE_DETAILS = "SAVE_TIRE_DETAILS"
export const SAVE_TIRE_SIZE_DETAILS = "SAVE_TIRE_SIZE_DETAILS"
export const SAVE_SELECTED_SHOP = "SAVE_SELECTED_SHOP"
export const SAVE_INSTALLATION_DATE = "SAVE_INSTALLATION_DATE"
export const SAVE_SELECTED_TIRE = "SAVE_SELECTED_TIRE"
export const RESET_TIRE_DATA = "RESET_TIRE_DATA"

const initialState = {
  tireArray: [],
  selectedTireSizeData: {},
  selectedShop: {},
  installationDateTime: "",
  selectedTire: ""
}

function saveBuyTireDetails(state = initialState, action) {
  switch (action.type) {
    case SAVE_TIRE_DETAILS:
      return {
        ...state,
        tireArray: action.payload.arr
      }
    case SAVE_TIRE_SIZE_DETAILS:
      return {
        ...state,
        selectedTireSizeData: action.payload.selectedTireData
      }
    case SAVE_SELECTED_SHOP:
      return {
        ...state,
        selectedShop: action.payload.selectedShop
      }
    case SAVE_INSTALLATION_DATE:
      return {
        ...state,
        installationDateTime: action.payload.installationDateTime
      }
    case SAVE_SELECTED_TIRE:
      return {
        ...state,
        selectedTire: action.payload.selectedTire
      }
    case RESET_TIRE_DATA:
      return {
        ...state,
        tireArray: [],
        selectedTireSizeData: {},
        selectedShop: {},
        installationDateTime: "",
        selectedTire: ""
      }
    default: {
      return state
    }
  }
}

export default saveBuyTireDetails
