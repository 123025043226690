import React from "react"

function IframeComponent({
  url,
  width = "100%",
  height = "600px",
  className,
  title,
  handleIframeLoad
}) {
  return (
    <div className={className}>
      <iframe
        src={url}
        width={width}
        height={height}
        frameBorder="0"
        allowFullScreen
        title={title}
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  )
}

export default IframeComponent
