"use-strict"

import React, { Component } from "react"
import { connect } from "react-redux"
import DashHeader from "./components/header.js"
import LeftNav from "./components/leftNav.js"
import SideMenu from "./sideMenu"
import { withTranslation } from "react-i18next"

// Currently unused component
class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  render() {
    const { t } = this.props
    return (
      <div className="container">
        <SideMenu />
        <DashHeader />
        <div className="mainContainer notificationsScreen">
          <LeftNav />
          <div className="rightContent">
            <div className="deprecated-content">
              <p className="introText">{t("notificationLbl")}</p>
              <div className="notificationSetup">
                <p>{t("completeVehicleProfileDesc")}</p>
                <p>
                  <strong>{t("currentMilageText")}</strong>
                  <br />
                  <input data-qa="notification-mileage-input-field" className="inputFld" />
                </p>
                <p className="pillContainer">
                  <strong>{t("milesPerMonthText")}</strong>
                  <br />
                  <label className="pillSelect">
                    <input type="radio" name="milesMonth" /> {t("500Miles")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="milesMonth" /> {t("1000Miles")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="milesMonth" /> {t("2000Miles")}
                  </label>
                </p>
                <p className="pillContainer">
                  <strong>{t("lastServiceDoneText")}</strong>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="oilchange" /> {t("oilChangeLbl")}
                  </label>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="tirerotation" /> {t("tireRotationLbl")}
                  </label>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="brakepads" /> {t("breakPadsReplacementLbl")}
                  </label>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="cabinairfilter" /> {t("airFilterReplacementLbl")}
                  </label>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="wheelalignment" /> {t("wheelAlignmentLbl")}
                  </label>
                  <br />
                  <label className="checkboxSelect">
                    <input type="checkbox" name="battery" /> {t("betteryReplacementLbl")}
                  </label>
                  <br />
                  <strong>{t("completedLbl")}</strong>{" "}
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("2WeeksAgoLbl")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("1MonthAgo")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("2MonthsAgo")}
                  </label>
                  &nbsp;&nbsp;&nbsp;<strong>{t("orLbl")}</strong>&nbsp;&nbsp;&nbsp;
                  <div className="selectDate">{t("selectDateLbl")}</div>
                </p>
              </div>
              <div className="notification">
                <p>{t("needOilChangeDesc")}</p>
                <p>
                  <div className="scheduleService">{t("scheduleServiceLbl")}</div>
                </p>
                <p className="pillContainer">
                  <strong>{t("orLbl")}</strong> {t("remindMeInLbl")}{" "}
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("2WeeksLbl")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("1MonthLbl")}
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label className="pillSelect">
                    <input type="radio" name="whenCompleted" /> {t("2MonthsLbl")}
                  </label>
                  &nbsp;&nbsp;&nbsp;<strong>{t("orLbl")}</strong>&nbsp;&nbsp;&nbsp;
                  <div className="selectDate">{t("selectDateLbl")}</div>&nbsp;&nbsp;&nbsp;
                  <div className="markComplete">{t("markCompleteLbl")}</div>
                </p>
              </div>
            </div>
            <p>
              <a href="javascript:history.back();" className="btnBack">
                &lsaquo; {t("backLbl")}
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    isLoggedIn: !!user.authentication_token,
    user,
    activeVehicle
  }
}

export default connect(mapStateToProps, {})(withTranslation("notifications")(Notifications))
