import React from "react"

import { Header, Grid, Image } from "semantic-ui-react"
import { DEFAULT_IMG_URL } from "../../../../constants/confirmDetails"

const CarComponent = (props) => {
  const { vehicle } = props

  const makeModel = vehicle && vehicle.make + " " + vehicle.model

  return (
    <div className="maintenance-schedule-car-container">
      <Grid.Column>
        <div
          className="vehicleHeader"
          style={{ display: "inline-flex", textAlign: "center", alignItems: "center" }}
        >
          <div className="vehicleYearLabel">{vehicle.year}</div>
          <div>
            <Header
              as="h1"
              className="makeModelHeader secondary-font"
              style={{ marginRight: "52px", textAlign: "left" }}
            >
              {makeModel}
            </Header>
          </div>
        </div>
        <Image
          style={{ marginLeft: "30px" }}
          className={vehicle && vehicle.dashboard_url ? "hide-bg" : "default_image"}
          src={vehicle && vehicle.dashboard_url ? vehicle.dashboard_url : DEFAULT_IMG_URL}
        />
      </Grid.Column>
    </div>
  )
}

export default CarComponent
