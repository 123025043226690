import { postJSON, putJSON } from "../../shared_component/utils/fetch"
import { setLoading, setNotLoading, updateActiveOrder, updateActiveCarts } from "./user"
import { constants } from "caradvise_shared_components"
import Notifications from "../components/Notifications"
import i18n from "../../shared_component/utils/i18n"
import { AFFIRM, BRAINTREE } from "../constants/tires"
import { resetRewardsState } from "./rewards"

const {
  orders: { SEEKING_QUOTE }
} = constants
const CREATE_ORDER_URL = "/api/v4/orders/"
export const CHANGE_APPOINTMENT_URL = "/api/v4/orders"
const CANCEL_APPOINTMENT_URL = "/api/v4/orders/?/cancel"
const UPDATE_ORDER_SERVICES_URL = "/api/v4/shopping_carts/?/update_order_services"

export const SET_ORDER_LOADING = "SET_ORDER_LOADING"
export function setOrderLoading() {
  return { type: SET_ORDER_LOADING }
}

export const UNSET_ORDER_LOADING = "UNSET_ORDER_LOADING"
export function unsetOrderLoading() {
  return { type: UNSET_ORDER_LOADING }
}

export const NEW_ORDER_CREATED = "NEW_ORDER_CREATED"
export function newOrderCreated(order) {
  return { type: NEW_ORDER_CREATED, payload: order }
}

export const ORDER_APPOINTMENT_UPDATED = "ORDER_APPOINTMENT_UPDATED"
export function appointmentUpdated(order) {
  return { type: ORDER_APPOINTMENT_UPDATED, payload: order }
}

export const ORDER_CANCELED = "ORDER_CANCELED"
export function orderCanceled(orderId) {
  return { type: ORDER_CANCELED, payload: { orderId: orderId } }
}

export function saveServiceDetails(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const params = {
      service_definition_id: payload.serviceID,
      price: payload.price,
      service_details: payload.serviceDetails
    }
    const response = await postJSON(
      UPDATE_ORDER_SERVICES_URL.replace("?", payload.cartID),
      params,
      { Authorization: user.authentication_token }
    )
    if (response.result) {
      dispatch(updateActiveCarts([response.result]))
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )
    }
  }
}

export function createOrder(cartId, prepay) {
  return async function (dispatch, getState) {
    dispatch(setLoading(i18n.t("orders:creatingOrderLoadingMessage")))
    let user = getState().user || {}
    const rewards = getState().rewards
    let cart = user.active_carts ? user.active_carts.find((c) => c.id === cartId) : null
    let shop = cart ? cart.shop : null
    let fee = cart ? cart.transaction_fee : 0
    const cartTotal = cart ? cart.estimated_total : null
    let addedServices = cart ? cart.order_services : null
    let date = cart ? cart.appointment_datetime : null
    let services = addedServices.map((service) => {
      return {
        service_definition_id: service.id,
        selected_option: {
          position: service.position,
          service_option_id: service.service_option_id
        },
        price_estimate_id: (service.price_estimate || {}).id
      }
    })

    let params = {
      vehicle_id: cart.vehicle_id || user.activeVehicleId,
      shop_id: shop && shop.id,
      transaction_fee: fee,
      order_services: services,
      appointment_datetime: date,
      total_from_cart: cartTotal,
      // payment_type: prepay ? "braintree" : cart.paymentType,
      payment_type: cart.paymentType === AFFIRM ? AFFIRM : BRAINTREE,
      opted_for_prepayment: prepay,
      interval_id: cart.interval_id,
      customer_comments: cart.customerComment || "",
      reward_miles: cart.any_order_service_lacks_price ? 0 : rewards.miles || 0
    }
    if (cart.is_seeking_quote) params.status = SEEKING_QUOTE

    let response = await postJSON(CREATE_ORDER_URL, params, {
      Authorization: user.authentication_token
    })
    if (response.result) {
      let res = response.result
      dispatch(newOrderCreated({ newOrder: res }))
      dispatch(resetRewardsState())
      return response
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )
    }

    dispatch(setNotLoading())
    return false
  }
}

export function updateOrder(cart, order, prepay) {
  return async function (dispatch, getState) {
    dispatch(setLoading(i18n.t("orders:creatingOrderLoadingMessage")))
    let user = getState().user || {}
    let shop = cart ? cart.shop : null
    let fee = cart ? cart.transaction_fee : 0
    const cartTotal = cart ? cart.estimated_total : null
    let addedServices = cart ? cart.order_services : null
    let date = cart ? cart.appointment_datetime : null
    const canModify = cart && !cart.is_tire_order ? true : false
    let services = addedServices.map((service) => {
      return {
        service_definition_id: service.id,
        selected_option: {
          position: service.position,
          service_option_id: service.service_option_id
        },
        price_estimate_id: (service.price_estimate || {}).id
      }
    })

    let params = {
      vehicle_id: cart.vehicle_id || user.activeVehicleId,
      shop_id: shop && shop.id,
      transaction_fee: fee,
      order_services: services,
      appointment_datetime: date,
      total_from_cart: cartTotal,
      payment_type: cart.paymentType === AFFIRM ? AFFIRM : BRAINTREE,
      opted_for_prepayment: prepay,
      interval_id: cart.interval_id,
      customer_comments: cart.customerComment || "",
      update_existing_order: canModify
    }
    if (cart.is_seeking_quote) params.status = SEEKING_QUOTE

    let response = await putJSON(`${CHANGE_APPOINTMENT_URL}/${order.id}`, params, {
      Authorization: user.authentication_token
    })

    if (response.error) {
      const msg = `${i18n.t("orders:appointmentUpdateErrorMessage")} ${response.error}`
      dispatch(
        Notifications.error({ title: i18n.t("common:notificationErrorTitle"), message: msg })
      )
      return false
    } else {
      dispatch(appointmentUpdated(response.result))
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("orders:appointmentUpdatedMessage")
        })
      )
      return response
    }
  }
}

export function changeAppointment(order, datetime) {
  return async function (dispatch, getState) {
    let user = getState().user || {}

    let response = await putJSON(
      `${CHANGE_APPOINTMENT_URL}/${order.id}`,
      { appointment_datetime: datetime },
      { Authorization: user.authentication_token }
    )

    if (response.error) {
      const msg = `${i18n.t("orders:appointmentUpdateErrorMessage")} ${response.error}`
      dispatch(
        Notifications.error({ title: i18n.t("common:notificationErrorTitle"), message: msg })
      )
      return false
    } else {
      dispatch(appointmentUpdated(response.result))
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("orders:appointmentUpdatedMessage")
        })
      )
      return response
    }
  }
}

export function updatePaymentType(order, paymentType, affirmCheckoutToken, status = false) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("orders:sendingApprovalsLoadingMessage")))

    let user = getState().user || {}
    let bodyParams = {
      payment_type: paymentType,
      affirm_checkout_token: affirmCheckoutToken
    }
    if (status) bodyParams["status"] = status

    const request = {
      url: `/api/v4/orders/${order.id}`,
      body: bodyParams,
      headers: {
        Authorization: user.authentication_token
      }
    }

    let response = await putJSON(request.url, request.body, request.headers)

    if (response.result) {
      // dispatch(
      //   Notifications.success({
      //     title: i18n.t("common:notificationSuccessTitle"),
      //     message: i18n.t("orders:updatePaymentTypeMessage")
      //   })
      // )

      // dispatch(updateActiveOrder(response.result))
      dispatch(setNotLoading())
      return true
    } else {
      const messages = [response.errors || response.error].flat().join(", ")
      dispatch(
        Notifications.error({ title: i18n.t("common:notificationErrorTitle"), message: messages })
      )
      dispatch(setNotLoading())

      return { errors: messages }
    }
  }
}

export function approveOrder(
  order,
  services,
  customerComments,
  isCustomerRefund,
  rewardMiles,
  tempOrderTotal
) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("orders:sendingApprovalsLoadingMessage")))
    let user = getState().user || {}

    const request = {
      url: `/api/v4/orders/${order.id}`,
      body: {
        order_services: services,
        customer_comments: customerComments,
        is_customer_refund: isCustomerRefund,
        reward_miles: rewardMiles,
        temp_order_total: tempOrderTotal
      },
      headers: {
        Authorization: user.authentication_token
      }
    }

    let response = await putJSON(request.url, request.body, request.headers)

    if (response.result) {
      !rewardMiles &&
        dispatch(
          Notifications.success({
            title: i18n.t("common:notificationSuccessTitle"),
            message: i18n.t("orders:approvalsSubmittedMessage")
          })
        )

      dispatch(updateActiveOrder(response.result))
      dispatch(setNotLoading())
      return response
    } else {
      const messages = [response.errors || response.error].flat().join(", ")
      dispatch(
        Notifications.error({ title: i18n.t("common:notificationErrorTitle"), message: messages })
      )
      dispatch(setNotLoading())

      return { errors: messages }
    }
  }
}

export function cancelOrder(orderId) {
  return async function (dispatch, getState) {
    dispatch(setLoading(i18n.t("orders:cancelingOrderLoadingMessage")))
    dispatch(setOrderLoading())
    let user = getState().user || {}
    let response = await putJSON(
      CANCEL_APPOINTMENT_URL.replace("?", orderId),
      {},
      { Authorization: user.authentication_token }
    )

    if (response.error) {
      dispatch(setNotLoading())
      dispatch(unsetOrderLoading())
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: i18n.t("orders:cancelingOrderErrorMessage")
        })
      )
      return { success: false }
    } else {
      dispatch(orderCanceled(response.result.id))
      dispatch(unsetOrderLoading())
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("orders:appointmentCancelledMessage")
        })
      )
      return { success: true, result: response.result }
    }
  }
}
