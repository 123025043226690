import React, { PureComponent } from "react"
// import { Membership } from "caradvise_shared_components"
import { hideChrome, showChrome } from "../../actions/framework"
import { connect } from "react-redux"
import { getStyles } from "../Config/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { Card } from "semantic-ui-react"
import barCode from "../../../images/membershippanel/validBarcode.png"

const styles = getStyles()
class MembershipCardBackFormat extends PureComponent {
  render() {
    const { t, plan, bg, clr } = this.props
    return (
      <div
        className="front-card"
        style={{
          backgroundColor: bg,
          paddingBottom: "3%",
          boxShadow: "2px 2px 0px #ccc",
          borderBottompLeftRdius: "80px 80px"
        }}
      >
        <div className="back-card-main-container">
          <div className="back-card-inner-container">
            <span className="back-card-title" style={{ color: clr }}>
              {t("carAdvisePremium")}
            </span>

            <span className="back-card-description" style={{ color: clr }}>
              {t("carAdvisePremium-text-1")}
              <br /> {t("carAdvisePremium-text-2")}
            </span>
          </div>

          <div className="back-card-inner-container">
            <span className="back-card-title" style={{ color: clr }}>
              {t("howToUseCard")}
            </span>

            <span className="back-card-description" style={{ color: clr }}>
              {t("howToUseCard-text-1")}
            </span>
            <span className="back-card-description" style={{ color: clr }}>
              {t("howToUseCard-text-2")}
            </span>
          </div>
          <div className="back-card-need-help-div">
            <a
              className="back-card-help"
              style={{ color: clr }}
              href="https://caradvise.com/contactus/"
              target="_blank"
            >
              Need Help?
            </a>
            <div className="back-card-barcode-div">
              <img style={{ width: "20%" }} src={barCode} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  const vehicles = user.vehicles || []
  const vehicle = vehicles.find((v) => v.id === user.activeVehicleId) || vehicles[0]
  const name = [user.firstName, user.lastName].filter((n) => n != null).join(" ")

  return { name, vehicle, user }
}

export default connect(mapStateToProps, { hideChrome, showChrome })(
  withTranslation("membershipCardBackFormat")(MembershipCardBackFormat)
)
