import { TIRE_CONNECT } from "../constants/tires"
import { getJSON, patchJSON, postJSON, putJSON } from "../../shared_component/utils/fetch"
import { setUserData, setUserError } from "./user"

export const LOAD_USER_STATES = "LOAD_USER_STATES"
export const SAVE_TIRE_DETAILS = "SAVE_TIRE_DETAILS"
export const SAVE_TIRE_SIZE_DETAILS = "SAVE_TIRE_SIZE_DETAILS"
export const SAVE_SELECTED_SHOP = "SAVE_SELECTED_SHOP"
export const SAVE_INSTALLATION_DATE = "SAVE_INSTALLATION_DATE"
export const SAVE_SELECTED_TIRE = "SAVE_SELECTED_TIRE"
export const RESET_TIRE_DATA = "RESET_TIRE_DATA"

const UPDATE_GUEST_USER = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/user_details`
const UPDATE_VEHICLE_VIN = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/vehicles`
const GET_USER_ACTIVE_VEHICLE = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/user`

export const USER_NOT_LOADING = "USER_NOT_LOADING"
export function setUserNotLoading() {
  return { type: USER_NOT_LOADING }
}

export function setNotLoading() {
  return async function (dispatch) {
    await dispatch(setUserNotLoading())
    return () => {}
  }
}

export const saveTireDetails = (arr) => async (dispatch) => {
  dispatch({
    type: SAVE_TIRE_DETAILS,
    payload: { arr }
  })
}

export const saveTireSizeDetails = (selectedTireData) => async (dispatch) => {
  dispatch({
    type: SAVE_TIRE_SIZE_DETAILS,
    payload: { selectedTireData }
  })
}

export const saveSelectedTire = (selectedTire) => async (dispatch) => {
  dispatch({
    type: SAVE_SELECTED_TIRE,
    payload: { selectedTire }
  })
}

export const saveSelectedShop = (selectedShop) => async (dispatch) => {
  dispatch({
    type: SAVE_SELECTED_SHOP,
    payload: { selectedShop }
  })
}

export const saveInstallationDate = (installationDateTime) => async (dispatch) => {
  dispatch({
    type: SAVE_INSTALLATION_DATE,
    payload: { installationDateTime }
  })
}

export const resetTireData = () => async (dispatch) => {
  dispatch({
    type: RESET_TIRE_DATA
  })
}

export function getFilteredListOfTires(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let url = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tires?${payload}`
      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getTireDetails(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tires/${payload}`,
        {},
        request.headers
      )

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getRimSize(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tires/sizes`,
        { vehicle_id: activeVehicleId },
        request.headers
      )

      if (response.result) {
        return response
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getShopsForTire(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/v1/shops`,
        {
          vehicle_id: activeVehicleId,
          tire_integration: TIRE_CONNECT,
          ...payload
        },
        request.headers
      )

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getTireBookingDetails(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      const data = {
        tires: payload.tires,
        shop_id: payload.shop_id,
        supplier: payload.supplier
      }

      let response = await postJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tires/preview`,
        data,
        request.headers
      )

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getTimeSlotsForShop(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `/api/v4/shops/${payload.shopId}/schedule?date=${payload.date}&days=1&service_definition_ids[]=[]`,
        {},
        request.headers
      )

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getTireOrderDetails(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token && activeVehicleId) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tire_order_details`,
        { vehicle_id: activeVehicleId },
        request.headers
      )

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function updateGuestUserDetails(user, data) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: UPDATE_GUEST_USER,
        headers: {
          Authorization: authentication_token
        },
        body: {
          ...data
        }
      }

      const response = await putJSON(request.url, request.body, request.headers)
      if (response.result) {
        dispatch(setUserData({ ...response.result, ...data }))
      } else {
        dispatch(setUserError(response.error))
      }

      return response
    }
  }
}

export function updateVehicleDetails(user, data, vehicleId) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: `${UPDATE_VEHICLE_VIN}/${vehicleId}`,
        headers: {
          Authorization: authentication_token
        },
        body: {
          ...data
        }
      }

      const response = await patchJSON(request.url, request.body, request.headers)

      return response
    }
  }
}
