import React, { Component } from "react"
import { connect } from "react-redux"
class Appcues extends Component {
  componentDidUpdate(prevProps) {
    // NOTE: Appcues -- since we have a SPA we track page changes here
    // and wrap withRouter() so that we can track the /paths
    const {
      location: { pathname }
    } = this.props
    const currentUser = this.props.user
    // now that we have a reference to the user, we can identify them with Appcues
    const envVarString = process.env.REACT_APP_ENVIRONMENT || ""
    const currentUserId = currentUser.id
    const userFirstName = currentUser.firstName
    const userEmail = currentUser.email

    const previousLocation = prevProps.location.pathname
    if (pathname.toLowerCase() !== previousLocation.toLowerCase()) {
      window.analytics.page(pathname.toLowerCase(), {
        path: pathname
      })
    }
    if (pathname !== previousLocation && currentUserId) {
      window.Appcues &&
        window.Appcues.identify(`${envVarString}-${currentUserId}`, {
          userFirstName: userFirstName,
          userEmail: userEmail
        })
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = (state) => {
  const user = state.user
  return {
    user: user || {}
  }
}

export default connect(mapStateToProps)(Appcues)
