import React from "react"
import LoadingComponent from "../../../components/LoadingComponent"

export function ProgressHUD({ msg }) {
  return (
    <div>
      <div className="spinnerTransparentContainer">
        <div className="spinnerTextBlack">
          <LoadingComponent />
          <br />
          {msg ? msg : "Loading..."}
        </div>
      </div>
    </div>
  )
}

export function ProgressHUDMap({ msg }) {
  return (
    <div>
      <div className="spinnerTransparentContainerMap">
        <div className="spinnerTextBlack">
          <LoadingComponent />
          <br />
          {msg ? msg : "Loading..."}
        </div>
      </div>
    </div>
  )
}
