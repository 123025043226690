import React from "react"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import {
  TIRE_FILTER_CHECKBOX,
  TIRE_FILTER_RANGE,
  TIRE_FILTER_SORTING
} from "../../../constants/tires"
import TireFilterChip from "./TireFilterChip"

const TireAppliedFilters = ({ selectedFilters, handleClearFilter, handleClearAllFilters }) => {
  const { t } = useTranslation("tireFilters")

  return (
    <div className="applied__filters">
      <div className="label__flex">
        <p className="applied__label">{t("appliedFilters")}</p>
        <p className="clear_all" onClick={() => handleClearAllFilters(true)}>
          {t("clearAll")}
        </p>
      </div>

      <div className="applied__filters-container">
        {Object.keys(selectedFilters).map((filterType) => {
          const checkboxFilters = get(selectedFilters, `${filterType}.${TIRE_FILTER_CHECKBOX}`, [])
          const rangeFilter = get(selectedFilters, `${filterType}.${TIRE_FILTER_RANGE}`, {})

          return (
            <>
              {checkboxFilters.map((filterLabel) => (
                <TireFilterChip
                  key={`${filterType}-${TIRE_FILTER_CHECKBOX}-${filterLabel}`}
                  filterType={filterType}
                  filterLabel={filterLabel}
                  handleClearFilter={handleClearFilter}
                  filterCategory={TIRE_FILTER_CHECKBOX}
                />
              ))}

              {rangeFilter && (rangeFilter.start_price || rangeFilter.end_price) && (
                <TireFilterChip
                  filterType={filterType}
                  filterLabel=""
                  handleClearFilter={handleClearFilter}
                  filterCategory={TIRE_FILTER_RANGE}
                  filterValue={rangeFilter}
                />
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default TireAppliedFilters
