import React from "react"
import AppleWallet from "../../../images/Isolation_Mode.svg"
import { Image } from "semantic-ui-react"
import BookingCard from "./BookingCard"
import { withTranslation } from "react-i18next"
import { isIOS, isDesktop, isMacOs } from "react-device-detect"
import { connect } from "react-redux"

const BookingMembership = ({ t, appleWalletUrl }) => {
  return (
    <div className="booking-membership">
      <div className="mobile-membership">
        <p className="label">{t("membership")}</p>
        <p className="card-desc">{t("showMembershipCard")}</p>
      </div>
      <div className="card card-membership">
        <BookingCard />
      </div>
      <div className="card">
        <div className="web-membership">
          <p className="label">{t("membership")}</p>
          <p className="card-desc">{t("showMembershipCard")}</p>
        </div>
        {isIOS || (isDesktop && isMacOs) ? (
          <a href={isIOS || (isDesktop && isMacOs) ? appleWalletUrl : ""} target="_blank">
            <button className="add-membership">
              <Image src={AppleWallet} />
              {t("addToAppleWallet")}
            </button>
          </a>
        ) : null}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const appleWalletUrl = state.membershipData && state.membershipData.appleWalletKeyUrl

  return {
    appleWalletUrl
  }
}

export default connect(mapStateToProps)(withTranslation("bookingMembership")(BookingMembership))
