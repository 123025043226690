import React, { PureComponent } from "react"
import moment from "moment"
import MembershipBenefitsList from "./MembershipBenefitsList"
import { getStyles } from "../Config/StylesheetInjector"
import { includesRoadside } from "../../helpers/membershipHelpers"
import { withTranslation } from "react-i18next"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"

const styles = getStyles()

class MembershipUpgradeCard extends PureComponent {
  render() {
    const { plan, onSelectMembership, isSelected, t } = this.props
    let style = this.props.style || {}
    if (isSelected) style["border"] = `2px solid ${styles.carAdviseOrange}`

    return (
      <div
        className="membership-upgrade-card"
        style={style}
        onClick={() => !isSelected && onSelectMembership(plan)}
      >
        <div>
          {includesRoadside(plan) && (
            <div className="annual-membership-card-header">{t("annualMembershipLbl")}</div>
          )}
          {!includesRoadside(plan) && (
            <div className="membership-card-header-spacer">{"\u00A0"}</div>
          )}
          <div className="logo-and-benefits">
            <div className="membership-ca-logo" />

            <div className="caradvise-electric-blue plan-name-wrapper">
              <small className="plan-name">{plan.name.toUpperCase()}</small>
            </div>

            <div className="benefits-list">
              <MembershipBenefitsList plan={plan} isSelected={isSelected} />
            </div>
          </div>
        </div>

        <div className="plan-price">
          <div className="caradvise-electric-blue">
            {plan.is_free_trial && <div className="price">{t("freeTrial")}</div>}
            <span className={`price ${plan.is_free_trial && "strike-through"}`}>
              {t("planPrice", { price: numberFormatToCurrency(plan.price) })}
            </span>
          </div>
          {plan.is_free_trial && (
            <div className="trial-expiration">
              {t("freeTrialThrough", { date: moment(plan.trial_expiration_date).format("LL") })}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation("membershipUpgradeCard")(MembershipUpgradeCard)
