import React, { useState } from "react"
import { Button, Dropdown, Image, Input, Modal } from "semantic-ui-react"
import CrossIcon from "../../../images/btn-delete.png"
import CarImage from "../../../images/car-image.svg"
import VinDialogLogo from "../../../images/vin-model-logo.svg"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { updateVehicleDetails } from "../../actions/tires"
import Notifications from "../Notifications"
import store from "../../../shared_component/utils/configureStore"
import LoadingComponent from "../LoadingComponent"
import { updateVehicle } from "../../actions/user"
import { CA_STATE, PLATE, SUCCESS, VIN } from "../../constants/tires"
import { getSubdomain } from "../Config/StylesheetInjector"
import { ProvinceNamesAndAbbreviations, StateNamesAndAbbreviations } from "../../constants/states"
import { includes } from "lodash"

const VinDialog = ({ openVinDialog, handleVinDialog, t, updateVehicleDetails, user }) => {
  const [vehicleSection, setVehicleSection] = useState(VIN)
  const [vehicleVin, setVehicleVin] = useState("")
  const [vehiclePlate, setVehiclePlate] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const states = (includes(getSubdomain(), CA_STATE)
    ? ProvinceNamesAndAbbreviations
    : StateNamesAndAbbreviations
  ).map((s) => ({
    key: s.abbreviation,
    value: s.abbreviation,
    text: s.name
  }))

  const saveVehicleDetails = async () => {
    setIsLoading(true)
    try {
      let payload = {}

      if (vehicleSection === VIN) {
        payload.vin = vehicleVin
      } else {
        payload.license_plate_number = vehiclePlate && vehiclePlate.license_plate_number
        payload.license_plate_state = vehiclePlate && vehiclePlate.license_plate_state
      }
      const vehicleId = user.activeVehicleId
      const response = await updateVehicleDetails(user, payload, vehicleId)

      if (response.error) {
        handleAPIToaster(response, "error", t("errorTitle"))
        setIsLoading(false)
      } else {
        handleAPIToaster(response, "success", t("successTitle"))
        store.dispatch(updateVehicle(response.result.lite_vehicle))
        handleVinDialog()
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
    }
  }

  const handleAPIToaster = (response, type, title) => {
    store.dispatch(
      Notifications[type]({
        title: title,
        message: `${type === SUCCESS ? t("vehicle_updated") : response.error}`,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  return (
    <Modal
      open={openVinDialog}
      onClose={() => handleVinDialog()}
      onOpen={() => handleVinDialog()}
      className="vin-dialog"
    >
      <Modal.Content>
        <div className="cross-logo-container">
          <Image className="cross-logo" src={CrossIcon} onClick={handleVinDialog} />
        </div>
        <div className="dialog-logo-container">
          <Image className="dialog-logo" src={VinDialogLogo} onClick={handleVinDialog} />
        </div>
        <p className="dialog-header">{t("diloagHeader")}</p>
        <p className="dialog-subline">{t("dialogSubline")}</p>

        <div className="grid-container">
          <div
            className={`vehicle-vin ${vehicleSection === VIN ? "active-vehicle-section" : ""}`}
            onClick={() => setVehicleSection(VIN)}
          >
            {t("vinNumber")}
          </div>
          <div
            className={`vehicle-plate ${vehicleSection === PLATE ? "active-vehicle-section" : ""}`}
            onClick={() => setVehicleSection(PLATE)}
          >
            {t("plateNumber")}
          </div>
        </div>

        <div className="vehicle-content">
          {vehicleSection === VIN ? (
            <div>
              <p className="vin-label">
                {t("vinNumber")} <span>*</span>
              </p>
              <Input
                onChange={(e) => setVehicleVin(e.target.value)}
                placeholder={t("vinPlaceholder")}
                value={vehicleVin}
                type="text"
                maxLength={17}
              />
              <p className="section-info">{t("whereToFindMyNumber")}</p>
              <p className="section-info section-sub-info">{t("vinCanFound")}</p>
              <Image className="car-image" src={CarImage} />
            </div>
          ) : (
            <div>
              <p className="vin-label">{t("plateNumber")}</p>
              <Input
                onChange={(e) => {
                  const license_plate_number = e.target.value
                  setVehiclePlate((prev) => {
                    return {
                      ...prev,
                      license_plate_number: license_plate_number
                    }
                  })
                }}
                placeholder={t("platePlaceholder")}
                value={vehiclePlate.license_plate_number}
                type="text"
                key="license_plate_number"
              />
              <p className="vin-plate">{t("selectState")}</p>
              <Dropdown
                onChange={(e, data) => {
                  setVehiclePlate((prev) => {
                    return {
                      ...prev,
                      license_plate_state: data.value
                    }
                  })
                }}
                value={vehiclePlate.license_plate_state}
                upward
                placeholder={t("select")}
                search
                className="region-country-selector"
                options={states}
              />
            </div>
          )}
        </div>

        <Button
          className="vehicle-btn"
          onClick={saveVehicleDetails}
          disabled={
            isLoading ||
            (vehicleSection === VIN
              ? !vehicleVin
              : !(vehiclePlate.license_plate_number && vehiclePlate.license_plate_state))
          }
        >
          {isLoading ? (
            <LoadingComponent flexDirection="unset" />
          ) : vehicleSection === PLATE ? (
            t("savePlate")
          ) : (
            t("saveVin")
          )}
        </Button>
      </Modal.Content>
    </Modal>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps, {
  updateVehicleDetails
})(withTranslation("vehicleVinDialog")(VinDialog))
