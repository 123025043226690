import "core-js/fn/array/index"
import React from "react"
import { HashRouter, withRouter } from "react-router-dom"

import NotificationContainer from "./v1/components/NotificationContainer"
import InitialDataLoader from "./v1/components/InitialDataLoader"

import Appcues from "./v1/components/Config/Appcues"
import Router_v1 from "./Router_v1"
import Router_v2 from "./Router_v2"
import { getSubdomain } from "./v1/components/Config/StylesheetInjector"
import SplitClient, {
  CARADVISE_2_NAVIGATION,
  REWARDS,
  V1
} from "./v1/components/Config/SplitClient"
import store from "./shared_component/utils/configureStore"
import { APP_NAME } from "./shared_component/constants/common"
import {
  shouldStartNewSession,
  startNewSession
} from "./shared_component/utils/sessionManagerHelper"

const WrappedAppcues = withRouter(Appcues)

class Router extends React.Component {
  state = {
    isCarAdviseNavigation: null,
    isRewardsFeature: null
  }
  async componentDidMount() {
    const isNavigation = await SplitClient.getTreatments(
      [CARADVISE_2_NAVIGATION, REWARDS],
      store.getState().user,
      {
        sub_domain: getSubdomain(),
        app_name: APP_NAME
      }
    )
    this.setState({
      isCarAdviseNavigation: isNavigation[CARADVISE_2_NAVIGATION],
      isRewardsFeature: isNavigation[REWARDS]
    })
    if (shouldStartNewSession()) {
      const params = {
        version: isNavigation[CARADVISE_2_NAVIGATION]
      }
      startNewSession(params)
    }
  }
  render() {
    const { isCarAdviseNavigation, isRewardsFeature } = this.state
    return (
      <>
        {isCarAdviseNavigation && (
          <>
            <NotificationContainer />
            <HashRouter>
              <WrappedAppcues>
                <InitialDataLoader />
                {isCarAdviseNavigation && isCarAdviseNavigation !== V1 ? (
                  <Router_v2
                    isRewardsFeature={isRewardsFeature}
                    isCarAdviseNavigation={isCarAdviseNavigation}
                  />
                ) : (
                  <Router_v1 isRewardsFeature={isRewardsFeature} />
                )}
              </WrappedAppcues>
            </HashRouter>
          </>
        )}
      </>
    )
  }
}

export default Router
