import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faShoppingCart } from "@fortawesome/pro-regular-svg-icons"
import { Link, Redirect } from "react-router-dom"
import { Image, Confirm, Checkbox } from "semantic-ui-react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import {
  saveTireDetails,
  getFilteredListOfTires,
  saveSelectedTire,
  getRimSize,
  resetTireData,
  saveTireSizeDetails
} from "../actions/tires"
import TopTireShimmer from "./TopTireShimmer"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { routerPaths } from "../constants/paths"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import {
  DEFAULT_TIRE_QUENTY,
  TIRE_FILTER_CHECKBOX,
  TIRE_FILTER_RANGE,
  TIRE_FILTER_SORTING,
  TIRE_SORTING_FILTER_OPTIONS
} from "../constants/tires"
import TireSizeImage from "../../images/tire-size.svg"
import { formatParagraph } from "../helpers/stringHelpers"
import ModalDialog from "./ModalDialog"
import CustomDropdown from "../../shared_component/elements/CustomDropdown"
import { fetchVehicleName, selectOptions } from "../helpers/vehicleHelpers"
import { DEFAULT_IMG_URL } from "../constants/confirmDetails"
import { get, isEmpty, set, unset } from "lodash"
import { TireDetailsSize } from "./Tires/TireSizeDetails"
import { updateActiveVehicle } from "../../v1/actions/user"
import CartWarningDialog from "./Tires/CartWarningDialog"
import TireExlamationLogo from "../../images/tire-exlamation.svg"
import {
  gaJSON,
  logFirebaseAnalyticsScreenName,
  trackFirebaseAnalytics
} from "../../shared_component/utils/googleAnalyticsHelpers"
import TireFilterAndSearch360 from "./Tires/TireFilters/TireFilterAndSearch360"
import FilterLogo from "../../images/tire-filter.svg"
import ReactSVG from "react-svg"
import FilterDrawer from "./Tires/TireFilters/FilterDrawer"
import TireAppliedFilters from "./Tires/TireFilters/TireAppliedFilters"
import { isDesktop, isMobile, isTablet } from "react-device-detect"
import {
  areTireFiltersEmpty,
  manageTireFilters,
  prepareFilterAPIUrl,
  shouldApplyFilterRendered,
  transformString
} from "../../shared_component/utils/TireFilterHelpers"
import TireModuleTreadDepth from "./Tires/TireFilters/TireModuleTreadDepth"

function TopTires({
  t,
  activeVehicle,
  saveTireDetails,
  savedTires,
  getFilteredListOfTires,
  selectedTireSizeData,
  user,
  saveSelectedTire,
  vehicles,
  getRimSize,
  resetTireData,
  saveTireSizeDetails,
  updateActiveVehicle
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [tires, setTires] = useState([])
  const [rimSizes, setRimSizes] = useState()
  const [partnerFlag, setPartnerFlag] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openCarWarningDialog, setOpenCarWarningDialog] = useState(false)
  const [changedVehicle, setChangedVehicle] = useState(false)
  const [isVehicleChanged, setIsVehicleChanged] = useState(isEmpty(selectedTireSizeData))
  const [tireQty, setTireQty] = useState(savedTires && savedTires.length > 0 ? savedTires : [])
  const objectCollection = [
    { id: 1, label: "1" },
    { id: 2, label: "2" },
    { id: 3, label: "3" },
    { id: 4, label: "4" }
  ]
  const [selectedTire, setSelectedTire] = useState(
    savedTires && savedTires.length > 0
      ? {
          id: savedTires[0].tireId
        }
      : {}
  )
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
  const [tireSelectedFilters, setTireSelectedFilters] = useState({})
  const [vehicleRimAndTire, setVehicleRimAndTire] = useState({})
  const [isFilterApiCalling, setFilterApiCalling] = useState(false)
  const [isFilterAppliedOnMobile, setIsFilterAppliedOnMobile] = useState(false)
  const [filters, setFilters] = useState([])

  const dataprovider = objectCollection.map((object) => {
    return { key: object.id, value: object, text: object.label }
  })

  const handleFilterApiLoader = () => {
    setFilterApiCalling((prev) => !prev)
  }

  const handleSelectedFilter = (selectedFilter, filterType, filterCategory) => {
    const localFilterCategory =
      filterCategory === TIRE_FILTER_SORTING || filterCategory === TIRE_FILTER_RANGE

    if (filterCategory === TIRE_FILTER_SORTING) {
      handleGAEvent({
        filterType: TIRE_SORTING_FILTER_OPTIONS.find(
          (options) => options.value === selectedFilter.value
        ).key,
        eventName: gaJSON.tire_sorted
      })
    } else {
      handleGAEvent({
        filterType: localFilterCategory ? `${filterType}_${filterCategory}` : filterType
      })
    }

    manageTireFilters({
      selectedFilter,
      filterType,
      filterCategory,
      setTireSelectedFilters,
      fetchTireData
    })
  }

  const handleClearFilter = (filterType, filterLabel, filterCategory) => {
    setTireSelectedFilters((prevFilters) => {
      const currentFilters = get(prevFilters, filterType, {})
      if (filterCategory === TIRE_FILTER_CHECKBOX) {
        const checkboxFilters = get(currentFilters, TIRE_FILTER_CHECKBOX, [])
        set(
          prevFilters,
          `${filterType}.${TIRE_FILTER_CHECKBOX}`,
          checkboxFilters.filter((label) => label !== filterLabel)
        )
      } else if (filterCategory === TIRE_FILTER_RANGE) {
        unset(prevFilters, `${filterType}.${TIRE_FILTER_RANGE}`)
      } else if (filterCategory === TIRE_FILTER_SORTING) {
        unset(prevFilters, `${filterType}.${TIRE_FILTER_SORTING}`)
      }

      if (isMobile) {
        setIsFilterAppliedOnMobile(areTireFiltersEmpty(prevFilters))
      }

      isMobile ? fetchTireDataonMobile(prevFilters) : fetchTireData(prevFilters)

      return { ...prevFilters }
    })
  }

  const handleClearAllFilters = (wantToTriggerAPI = false) => {
    setTireSelectedFilters({})
    setIsFilterAppliedOnMobile(false)

    if (wantToTriggerAPI) {
      isMobile ? fetchTireDataonMobile() : fetchTireData()
    }
  }

  const fetchTireData = async (filters = {}) => {
    if (!isMobile && !isTablet) {
      try {
        handleFilterApiLoader()
        const response = await getFilteredListOfTires(
          `${vehicleRimAndTire}&${prepareFilterAPIUrl(filters)}`
        )
        setTires(response && response.result.tires)
      } catch (error) {
      } finally {
        handleFilterApiLoader()
      }
    }
  }

  const fetchTireDataonMobile = async (filters = {}) => {
    try {
      handleFilterApiLoader()
      setIsFilterAppliedOnMobile(true)
      const response = await getFilteredListOfTires(
        `${vehicleRimAndTire}&${prepareFilterAPIUrl(filters)}`
      )
      setTires(response && response.result.tires)
    } catch (error) {
    } finally {
      handleFilterApiLoader()
    }
  }

  const shoppingItems = savedTires.reduce((prev, next) => prev + next.quantity, 0)

  useEffect(() => {
    getFeatureFlagResult()
    fetchRimSizes()
    logFirebaseAnalyticsScreenName(gaJSON.tread_depth_tracker_tire)
  }, [])

  const handleFilterDrawer = () => {
    setOpenFilterDrawer((prev) => !prev)
  }

  const openDeleteModal = (tire) => {
    setSelectedTire(tire)
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleDeleteTire = () => {
    setSelectedTire({})
    setTireQty([])
    const finalArr = savedTires.filter((item) => item.tireId !== selectedTire.id)
    saveTireDetails(finalArr)
    closeModal()
  }

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const handleVehicleRim = (tireSizeData) => {
    if (!isEmpty(tireSizeData) && tireSizeData.rim_sizes && tireSizeData.rim_sizes.length === 1) {
      const rim_sizes = (tireSizeData || {}).rim_sizes || []
      const firstRim = rim_sizes[0] || {}
      const [rim, size] = Object.entries(firstRim)[0] || []
      onOptionChange({ text: `${rim}" ${t("inchLabel")}`, value: size, key: rim })
    }
  }

  const fetchRimSizes = async () => {
    const response = await getRimSize()
    setRimSizes(response.result)
    return response.result
  }

  useEffect(() => {
    getFilteredTopTires()
  }, [])

  const getFilteredTopTires = async () => {
    if (!isEmpty(selectedTireSizeData)) {
      setIsLoading(true)
      let carTireIdParams = ""
      selectedTireSizeData &&
        selectedTireSizeData.value.forEach((item, index) => {
          carTireIdParams += `cartireids[]=${item}&`
        })
      carTireIdParams += `rim_size=${selectedTireSizeData.key}`
      setVehicleRimAndTire(carTireIdParams)
      const response = await getFilteredListOfTires(carTireIdParams)
      setIsLoading(false)
      setTires(response && response.result.tires)
      setFilters(response && response.result.filters)
    }
    setIsLoading(false)
  }

  const changeHandler = (event, data, tire) => {
    const tempArr = tireQty ? tireQty.filter((item) => item.tireId !== tire.id) : []
    const finalArr = [...tempArr, { tireId: tire.id, quantity: data.value.id, price: tire.price }]
    setTireQty(finalArr)
  }

  const handleAddToCartBtn = (tire) => {
    if (selectedTire && selectedTire.id && selectedTire.id !== tire.id) {
      store.dispatch(
        Notifications.warning({
          title: t("Tire_purchase_detail"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
      return
    }

    setSelectedTire(tire)
    let data = tireQty.filter((item) => item.tireId === tire.id)
    if (data && data.length === 0) {
      data = [{ tireId: tire.id, quantity: DEFAULT_TIRE_QUENTY, price: tire.price }]
    }
    const tempArr = savedTires ? savedTires.filter((item) => item.tireId !== tire.id) : []
    const finalArr = [
      // ...tempArr, // uncomment this line if want to add different tires in cart
      { tireId: tire.id, tireDetails: tire, quantity: data[0].quantity, price: data[0].price }
    ]
    saveTireDetails(finalArr)
    trackEvent("tc-tire-added-to-cart", {}, true, false, false)
    store.dispatch(
      Notifications.success({
        title:
          data[0].quantity > 1
            ? `${data[0].quantity} ${t("Tires_success_title")}`
            : `${data[0].quantity} ${t("Tire_success_title")}`,
        position: "tr",
        autoDismiss: 2,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  const renderFindTireSizeContent = () => {
    return (
      <div className="finding-tire-modal-content">
        <div className="finding-text-section">
          <span className="finding-title-text">{t("whereToFindTireSizeLabel")}</span>
          <span className="finding-meta-text">
            {formatParagraph(t("findingTireSizeMetaText"), 700)}
          </span>
        </div>
        <Image src={TireSizeImage} />
      </div>
    )
  }
  const processRimSizesResponse = (sizes) => {
    return (
      sizes &&
      sizes.rim_sizes.map((rimSizeObj) => {
        const [text, value] = Object.entries(rimSizeObj)[0]
        return { text: `${text}" ${t("inchLabel")}`, value, key: text }
      })
    )
  }

  const onOptionChange = async (size) => {
    handleClearAllFilters()
    setIsVehicleChanged(false)
    setSelectedTire({})
    resetTireData()
    saveTireSizeDetails(size)
    setIsLoading(true)
    let carTireIdParams = ""
    size &&
      size.value.forEach((item, index) => {
        carTireIdParams += `cartireids[]=${item}&`
      })
    carTireIdParams += `rim_size=${size.key}`
    setVehicleRimAndTire(carTireIdParams)
    const response = await getFilteredListOfTires(carTireIdParams)
    setIsLoading(false)
    setTires(response && response.result.tires)
    setFilters(response && response.result.filters)
  }

  const setActiveVehicle = async (vehicle, isFromWarningCart = false) => {
    setChangedVehicle(vehicle)
    if (savedTires && savedTires.length > 0 && !isFromWarningCart) {
      handleCartWarningDialog()
      return
    }
    setIsVehicleChanged(true)
    setTires([])
    setFilters([])
    saveTireSizeDetails({})
    await updateActiveVehicle(vehicle.value.id)
    const tirerimSize = await fetchRimSizes()
    setChangedVehicle({})
    setOpenCarWarningDialog(false)
    handleVehicleRim(tirerimSize)
  }

  const handleCartWarningDialog = () => {
    setOpenCarWarningDialog((prev) => !prev)
  }

  const removeCart = () => {
    handleDeleteTire()
    handleCartWarningDialog()
    setActiveVehicle(changedVehicle, true)
  }

  const handleGAEvent = ({ filterType, eventName }) => {
    trackFirebaseAnalytics({
      event: eventName || gaJSON.tire_360_filters,
      params: {
        selectedFilter: transformString(filterType)
      }
    })
  }

  const rimSizesOptions = !isEmpty(rimSizes) ? processRimSizesResponse(rimSizes) : []
  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <div className="top-tires-section">
          <div className="tire__filter-desktop">
            {isLoading ? (
              <div className="tire__filter-animation"></div>
            ) : (
              <TireFilterAndSearch360
                handleSelectedFilter={handleSelectedFilter}
                filters={filters}
                tireSelectedFilters={tireSelectedFilters}
                tires={tires}
              />
            )}
          </div>
          <div className="top-tires">
            <div className="heading-parent">
              <div className="tires-heading">
                <Link to={routerPaths.tireSize}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <h4>{t("selectTireLabel")}</h4>
              </div>
            </div>
            <div className="mobile__tire-filter">
              <div className="mobile__tire-btn" onClick={handleFilterDrawer}>
                <ReactSVG src={FilterLogo} />
                <p>{t("tireFilters:filterAndShort")}</p>
              </div>
              {/* Will release this in next sprint */}
              {/* <div className="caradvise__recommonded">
                <Checkbox
                  className="caradvise__recommended-checkbox"
                  checked
                  label={t("tireFilters:caradviseRecommended")}
                />
              </div> */}
            </div>

            {!isDesktop && (
              <div className={`tread__depth-desktop ${isVehicleChanged && "tread__depth-width"}`}>
                <TireModuleTreadDepth vehicle={activeVehicle} />
              </div>
            )}

            <div className="tires-subheading-section">
              <div className="tires-help-section">
                {isLoading ? null : (
                  <>
                    <span className="tire-count-text">
                      {formatParagraph(t("tireCountLabel", { tireCount: tires.length }), 700)}
                    </span>
                    <ModalDialog
                      linkText={t("findingTireSizeLabel")}
                      className="finding-tire-size-link"
                    >
                      {renderFindTireSizeContent()}
                    </ModalDialog>
                  </>
                )}
              </div>
              {shouldApplyFilterRendered(tireSelectedFilters) &&
                (isMobile ? isFilterAppliedOnMobile : true) && (
                  <TireAppliedFilters
                    handleClearFilter={handleClearFilter}
                    handleClearAllFilters={handleClearAllFilters}
                    selectedFilters={tireSelectedFilters}
                  />
                )}
              <div className="tire-vehicles-detail-section">
                <div className="vehicle-dropdown-section">
                  <span className="label-style">{t("yourVehicleLabel")}</span>
                  <CustomDropdown
                    options={selectOptions(vehicles)}
                    includeBlank={true}
                    isVehicleDropdown={true}
                    defaultOption={{
                      text: fetchVehicleName(activeVehicle),
                      image: activeVehicle.dashboard_mobile_url
                        ? activeVehicle.dashboard_mobile_url
                        : DEFAULT_IMG_URL,
                      key: activeVehicle.id,
                      value: activeVehicle
                    }}
                    imageClass="default-vehicle-image"
                    onOptionChange={(vehicle) => {
                      setActiveVehicle(vehicle)
                      handleClearAllFilters()
                    }}
                    customeClass="dropdown-width"
                  />
                </div>
                <div className="tire-size-dropdown-section">
                  <span className={`label-style ${isVehicleChanged && "label-danger"}`}>
                    {t("tireSizeLabel")}
                  </span>
                  <CustomDropdown
                    customeClass={`${isVehicleChanged && "dropdown-danger"} dropdown-width`}
                    options={rimSizesOptions}
                    includeBlank={true}
                    defaultOption={
                      !isEmpty(selectedTireSizeData)
                        ? {
                            text:
                              selectedTireSizeData.key &&
                              `${selectedTireSizeData.key}" ${t("inchLabel")}`,
                            value: selectedTireSizeData.value,
                            key: selectedTireSizeData.key
                          }
                        : {
                            text: t("tireSizePlaceholder"),
                            value: t("tireSizePlaceholder"),
                            key: t("tireSizePlaceholder")
                          }
                    }
                    disabled={false}
                    onOptionChange={onOptionChange}
                  />
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className={`tread__depth-desktop ${isVehicleChanged && "tread__depth-width"}`}>
                <TireModuleTreadDepth vehicle={activeVehicle} />
              </div>
            )}

            {isVehicleChanged && (
              <div className="tire_size">
                <div className="tire_size__content">
                  <Image src={TireExlamationLogo} />
                  <p>{t("selectTires")}</p>
                </div>
              </div>
            )}

            <div className="tires">
              <div className="align-loader-center">
                {isLoading || isFilterApiCalling ? (
                  <TopTireShimmer />
                ) : (
                  !isVehicleChanged && (
                    <div className="tire-card-button-parent">
                      <div className="card-parent">
                        <div
                          className={`tires-card-parent ${
                            !(tires && tires.length) ? "placeholder_contianer" : ""
                          }`}
                        >
                          {tires.length > 0 ? (
                            tires.map((tire) => {
                              return (
                                <TireDetailsSize
                                  tire={tire}
                                  tireQty={tireQty}
                                  changeHandler={changeHandler}
                                  selectedTire={selectedTire}
                                  openDeleteModal={openDeleteModal}
                                  handleAddToCartBtn={handleAddToCartBtn}
                                  t={t}
                                  objectCollection={objectCollection}
                                  saveSelectedTire={saveSelectedTire}
                                />
                              )
                            })
                          ) : (
                            <div>
                              <p className="no_result">{t("No_result")}</p>
                              {!isEmpty(filters) && isEmpty(tires) && (
                                <p
                                  className="clear__filters"
                                  onClick={() => handleClearAllFilters(true)}
                                >
                                  {t("tireFilters:clearFilters")}
                                </p>
                              )}
                              <p className="no_tier">{t("No_tires_placeholder")}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {savedTires && savedTires.length > 0 ? (
                        <Link to={routerPaths.confirmTire} className="review-btn">
                          <button>
                            {t("Next_Review_Cart")}
                            <FontAwesomeIcon icon={faShoppingCart} />
                            <span className="badge">{shoppingItems}</span>
                          </button>
                        </Link>
                      ) : null}
                    </div>
                  )
                )}
                <Confirm
                  open={openModal}
                  onCancel={closeModal}
                  onConfirm={handleDeleteTire}
                  content={t("confirmTire:Are_you_sure")}
                  className="delete-modal"
                />
                <CartWarningDialog
                  openCarWarningDialog={openCarWarningDialog}
                  handleCartWarningDialog={handleCartWarningDialog}
                  removeCart={removeCart}
                />
              </div>
            </div>
          </div>
          <FilterDrawer
            open={openFilterDrawer}
            filters={filters}
            tireSelectedFilters={tireSelectedFilters}
            handleFilterDrawer={handleFilterDrawer}
            handleSelectedFilter={handleSelectedFilter}
            clearTireFilters={handleClearAllFilters}
            fetchTireList={fetchTireDataonMobile}
            tires={tires}
            isFilterApiCalling={isFilterApiCalling || isLoading}
          />
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let savedTires = state.saveBuyTireDetails.tireArray
  let selectedTireSizeData = state.saveBuyTireDetails.selectedTireSizeData
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    user: user,
    vehicles: user.vehicles,
    activeVehicle: activeVehicle,
    savedTires: savedTires,
    selectedTireSizeData: selectedTireSizeData
  }
}

export default connect(mapStateToProps, {
  saveTireDetails,
  getFilteredListOfTires,
  saveSelectedTire,
  getRimSize,
  resetTireData,
  saveTireSizeDetails,
  updateActiveVehicle
})(withTranslation("topTires")(TopTires))
