import React, { Component } from "react"
import CheckoutIndex from "./CheckoutIndex"
import { SCHEDULE_PAGE } from "../../../shared_component/constants/common"
import { openShopListView } from "../../../v1/actions/checkout"
import { connect } from "react-redux"
class ScheduleComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: (this.props.location.state && this.props.location.state.showMapListView) || false
    }
  }
  componentWillUnmount() {
    const { openShopListView } = this.props
    openShopListView(false)
  }

  render() {
    const { history } = this.props
    const { showMap } = this.state
    return <CheckoutIndex pageContent={SCHEDULE_PAGE} history={history} showMap={showMap} />
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  return {
    user
  }
}

export default connect(mapStateToProps, { openShopListView })(ScheduleComponent)
