import { REWARD_COOKIE } from "../constants/rewards"
export const KEY_PARAM = "key"
export const ACTIVE_VEHICLE_ID_PARAM = "active_vehicle_id"
export const NOTIFICATION_MESSAGE_PARAM = "notification_message"
export const PROMO_CODE_PARAM = "promo_code"
export const AFFILIATION_PARAM = "affiliation"
export const ADDING_INSPECTION_PARAM = "adding_inspection"
export const PREPOPULATE_PARAMS = "services"
export const SIGN_UP_STEP_PARAM = "step"
export const SID = "sid"
export const FUEL_REWARDS = "fuelrewards"
export const DATE_PARAM = "date"
export const ORDER_ID = "order_id"
export const SHOP_ID = "shop_id"
export const UTM_SOURCE = "utm_source"
export const UTM_CAMPAIGN = "utm_campaign"
export const UTM_MEDIUM = "utm_medium"
export const VARIATION = "variation"
export const KX_PARAM = "_kx"
export const RESET_PASSWORD_TOKEN = "reset_password_token"

export function extractFirstSubDomain(host) {
  if (!host) {
    host = window.location.hostname
  }

  let firstPeriod = host.indexOf(".")
  let subdomain

  if (firstPeriod !== -1) subdomain = host.substring(0, firstPeriod).toLowerCase()
  else subdomain = host

  return subdomain
}

export function getEnvironment(host) {
  if (!host) host = window.location.host

  let secondPiece = host.split(".")[1]

  if (!secondPiece) return "development"
  else return secondPiece
}

export function isDevelopment() {
  return window.location.hostname.match(/localhost/) || getEnvironment() === "development"
}

export function getUrlProtocol() {
  return isDevelopment() ? "http://" : "https://"
}

export function getApiUrl() {
  if (process.env.NODE_ENV === "development") {
    return "localhost:3001"
  } else {
    let host = window.location.hostname
    let subDomain = extractFirstSubDomain(host)
    return host.replace(subDomain, "api")
  }
}

export function hashRouteWithParams(route) {
  let stringParams = paramsString()
  if (stringParams != "") stringParams = `?${stringParams}`
  return `/#${route}${stringParams}`
}

export function paramsString() {
  return (window.location.href.split("?") || [])[1] || ""
}

// The current implementation of this function is unreliable since a key which is a
// substring of another key will match since we're just looking at whether a param
// key `includes` the passed in key string.
//
// A better way to do this is to construct a URLSearchParams object with the query
// string extracted from the URL.
//
// TODO: change this to the below code and find the places that rely on this function
// and make sure they still work as expected.
//
// const params = new URLSearchParams(paramsString())
// return params.get(key)
//
// NOTE: A params string in our app could may be the real `window.location.search` value (when
// the `App` component mounts but before the Router takes over), or sourced from
// `params.location.search` via the HashRouter props once the Routes component mounts. (The
// latter is actually a substring of `window.location.hash`).
//
// NOTE: relying on splitting the url at ? is also be unreliable since a url could feasibly
// have both types of params, e.g.,
//
// myapp.com?param=val/#/some-route?param=other-val
//
// In the above case, splitting on ? and taking the string at [1] would not give a value string
// for use with `URLSearchParams`. It is is unlikely that real url params are applied once the
// HashRouter takes over but should be considered if experiencing unexpected behavior.
export function getUrlParams(key) {
  const params = paramsString().split("&")
  const paramString = params.find((param) => param.includes(key)) || ""
  const paramValue = paramString.split("=")[1]
  return paramValue ? decodeURIComponent(paramValue) : null
}

export function getUrlParamsArray(key) {
  const params = paramsString().split("&")
  return params.filter((param) => param.includes(key))
}

// Returns a URL with specified params removed
export function getUrlWithoutParams(paramsToRemove) {
  if ((paramsToRemove || []).length === 0) return window.location.href

  const urlComponents = window.location.href.split("?")
  const paramElements = (urlComponents[1] || "").split("&")
  const filteredParams = paramElements.filter((param) => {
    const paramPresent = ![null, undefined, ""].includes(param)
    return paramPresent && paramsToRemove.find((key) => param.includes(key)) == null
  })

  if (filteredParams.length === 0) return urlComponents[0]
  return `${urlComponents[0]}?${filteredParams.join("&")}`
}

export function getCookie(cname) {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const switchSitesIfApplicable = async (user, props) => {
  if (window.location.origin.match(/localhost/)) return false

  if (user.base_url) {
    const base = user.base_url
    if (!base.match(window.location.origin) && !window.location.origin.match(base)) {
      await props.signOut()
      const hasHTTPS = base.match(/http/)
      const nextOrigin = hasHTTPS ? base.replace("http:", "https:") : "https://" + base
      const fullUrl = `${nextOrigin}${props.location.pathname}?key=${user.authentication_token}&${props.location.search}`
      window.location = fullUrl
    }
  }
}

export function removeParamsAndPreserveRoute() {
  const urlObj = new URL(window.location.href)
  const hashIndex = urlObj.href.indexOf("#")
  // Check if there's a hash in the URL
  if (hashIndex !== -1) {
    const hashPart = urlObj.href.substring(hashIndex)
    const pathname = hashPart.split("?")[0]
    // Retain the hash and pathname, remove query parameters
    const newUrlObj = new URL(urlObj.origin)
    newUrlObj.search = ""
    return newUrlObj.toString() + hashPart
  }
  // If no hash found, return the URL as it is
  return urlObj.toString()
}
