import { routeChange, routerPaths } from "../../v1/constants/paths"
import PepboysLogo from "../../images/rewards/pepboys.svg"
import ServicesLogo from "../../images/rewards/services.svg"
import { isCookieExpired, setCookie } from "../utils/cookies"
import moment from "moment"

export const OPEN_REWARSD_WORKS_DIALOG = "openRewardWorksDialog"
export const COOKIE_TRUE = "true"
export const REWARDS = "rewards"
export const EARN_PER_MILES = 0.01
export const DEVID_MILES = 50
export const TEXT_AREA = "textarea"
export const COPY = "copy"

export const REWARD_COOKIE = {
  expires: "expires",
  path: "path",
  expireRegex: /expires=([^;]*)/,
  minutes: 60,
  miliseconds: 1000,
  expiredTime: "Thu, 01 Jan 1970 00:00:00 UTC"
}

export const ALL = "All"
export const COMPLETED = "Completed"
export const EARN = "earn"
export const PENDING = "Pending"
export const CSS_PERCENTAGE = "--percentage"

export const REFERAL_CODE = "CarAdviseKWCON"
export const REWARD_REGISTER = "Register"
export const CURRENCY_REMOVER_REGEX = /[^0-9.-]/g
export const GET_FIRST_NON_ZERO_NUMBER = /[1-9]/
export const DEFAULT_PAD_START_VALUE = "0"

export const SHOW_REWARDS_SECTION = "rewards_instructions"
export const FALSE = "false"
export const LAST_VISIT = "lastVisit"
export const DEFAULT_VISIBLE_TIME_REWARDS = 90 // it is in day
export const DEFAULT_VISIBLE_EXPIRY_REWARDS = 3650 // it is in day
export const DAYS = "days"
export const NEW_USER = "newUser"
export const DEFAULT_NUMBER_LOCAL = "en-US"
export const SCROLL = "scroll"

export const TAGS = [
  {
    id: 1,
    tagLabel: ALL
  },
  {
    id: 2,
    tagLabel: COMPLETED
  }
]

export const convertCurrencyToNumber = (currencyString) => {
  // Accepts a currency string (e.g., "$0.00") and converts it into a valid number
  const numericString =
    currencyString && currencyString.toString()
      ? currencyString.toString().replace(CURRENCY_REMOVER_REGEX, "")
      : 0
  const numericValue = parseFloat(numericString)
  return numericValue
}

export const appliedMilesDollar = (miles, milesPerDollar) => {
  return (+miles / +milesPerDollar).toFixed(2)
}

export const gotoRewards = (history) => {
  const isDialogOpendExpired = isCookieExpired(OPEN_REWARSD_WORKS_DIALOG)

  setCookie(OPEN_REWARSD_WORKS_DIALOG, isDialogOpendExpired, 7)
  routeChange(history, routerPaths.rewards)
}

export const SPONSER_PROMOTION_LIST = [
  {
    id: 1,
    miles: "+10000 Miles",
    sponserLogo: PepboysLogo,
    bookServices: "Book an Oil Change at Pep Boys (2x 5000)",
    expiredAt: "Sept 4th"
  },
  {
    id: 2,
    miles: "+10000 Miles",
    sponserLogo: ServicesLogo,
    bookServices: "Book Package for $99. That includes:",
    expiredAt: "Sept 4th"
  }
]

export const getNonExpiredRewards = (rewardsList) => {
  return rewardsList && rewardsList.length > 0
    ? rewardsList.filter((reward) => {
        if (reward.expiration_date) {
          return moment(reward.expiration_date).isAfter(moment())
        } else {
          return true
        }
      })
    : []
}
