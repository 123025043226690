import React from "react"
import { useTranslation } from "react-i18next"

export default function MembershipTerms({ style, message }) {
  const { t } = useTranslation("membershipTerms")
  return (
    <div style={{ paddingBottom: "20px", fontSize: "0.7em", ...style }} className="confident-blue">
      {message ? message : t("membershipTermsDefaultDescription")} <br />
      <a
        className="underline"
        href="http://caradvise.com/privacy/"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("privacyPolicyLbl")}
      </a>
      {` ${t("andLbl")} `}
      <a
        className="underline"
        href="http://caradvise.com/terms/"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("termsOfUseLbl")}
      </a>
    </div>
  )
}
