import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import thunkMiddleware from "redux-thunk"
import rootReducer from "../../v1/reducers"
// import storage from "redux-persist/lib/storage/session"; // Import sessionStorage version
import storage from "redux-persist/lib/storage" // defaults to localStorage for web and AsyncStorage for react-native

import { composeWithDevTools } from "redux-devtools-extension"
import * as Sentry from "@sentry/react"

const middlewares = [thunkMiddleware]

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`)

  middlewares.push(createLogger({ collapsed: true }))
}
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const persistConfig = {
  key: "root",
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer)
)

const persistor = persistStore(store)

export function getPersistor() {
  return persistor
}

function canUseSessionStorage(data) {
  try {
    sessionStorage.setItem("test", data)
    sessionStorage.removeItem("test")
    return true
  } catch (e) {
    return false
  }
}

export function storeData(key, data) {
  if (canUseSessionStorage(data)) {
    sessionStorage.setItem(key, data)
  } else {
    localStorage.setItem(key, data)
    localStorage.setItem(`${key}_fallback`, "true") // Set flag
  }
}

// Retrieve data, considering the fallback
export function retrieveData(key) {
  if (localStorage.getItem(`${key}_fallback`) === "true") {
    return localStorage.getItem(key)
  }
  return sessionStorage.getItem(key)
}

export default store
