const LOAD_SELECTED_TIME = "LOAD_SELECTED_TIME"

const initialState = {}

export default function selectedTime(state = initialState, action) {
  switch (action.type) {
    case LOAD_SELECTED_TIME:
      return Object.assign({}, state, { selectedTime: action.selectedTime })
    default:
      return state
  }
}
