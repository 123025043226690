import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../../v1/actions/user"
import { fetchCarAdviseRecemendedServices } from "../../../v1/CarAdvise2/actions/maintenance"
import ReactSVG from "react-svg"
import { fetchOEMCurrentIntevals } from "../../../v1/actions/maintenanceSchedules"
import MaintenanceScheduleServices from "../Cart/MaintenanceScheduleServicesSection"
import PopularServices from "./PopularServices"
import { getServices } from "../../../shared_component/utils/searchServices"
import ServicesCategoryIndex from "./ServicesCategoryIndex"
import { FILTERS } from "../../../shared_component/constants/checkout"
import { FilterChip } from "../../../shared_component/components/FilterChip"
import { withRouter } from "react-router-dom"

class AddServices extends Component {
  state = {
    filters: [FILTERS.POPULAR_SERVICES, FILTERS.MAINTENANCE_SERVICES, FILTERS.BY_CATEGORY],
    activeFilter: FILTERS.POPULAR_SERVICES,
    isLoading: false,
    popularServices: [],
    isCategorySelected: false
  }

  async componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state == 1) {
      await this.setState({ activeFilter: FILTERS.POPULAR_SERVICES })
    }
    if (this.props.location && this.props.location.state && this.props.location.state == 2) {
      await this.setState({ activeFilter: FILTERS.MAINTENANCE_SERVICES })
    }
    if (this.props.location && this.props.location.state && this.props.location.state == 3) {
      await this.setState({ activeFilter: FILTERS.BY_CATEGORY })
    }
  }
  handleFilterClick = (filter) => {
    this.setState({ activeFilter: filter })
  }

  handleChangeCategory = () => {
    this.setState({ isCategorySelected: !this.state.isCategorySelected })
  }
  renderContent() {
    const { activeFilter, cart } = this.state

    switch (activeFilter) {
      case FILTERS.MAINTENANCE_SERVICES:
        return <MaintenanceScheduleServices order={cart} updateCart={updateCart} isFilter={true} />

      case FILTERS.BY_CATEGORY:
        return <ServicesCategoryIndex handleChangeCategory={this.handleChangeCategory} />

      default:
        return (
          <PopularServices order={cart} updateCart={updateCart} isFilter={true} isCheckBox={true} />
        )
    }
  }

  render() {
    const { t } = this.props
    const { filters, isCategorySelected } = this.state

    return (
      <div className="add-services-container">
        {!isCategorySelected && (
          <div className="add-services-header-parent">
            <div className="add-services_header">
              <ReactSVG
                src="images/go_back_icon.svg"
                onClick={() => window.history.back()}
                style={{ cursor: "pointer" }}
              />
              <div className="add-services_header-name">{t("addServiceLabel")}</div>
            </div>
            <FilterChip
              items={filters}
              activeFilter={this.state.activeFilter}
              onFilterClick={this.handleFilterClick}
            />
          </div>
        )}
        <div className="add-services-content-parent">{this.renderContent()}</div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}
export default connect(mapStateToProps, {
  updateCart,
  fetchOEMCurrentIntevals,
  fetchCarAdviseRecemendedServices,
  getServices
})(withTranslation("addService")(withRouter(AddServices)))
