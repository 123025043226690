import React from "react"

export const ShimmerServiceCard = ({ count }) => {
  return Array(count)
    .fill(0)
    .map((_, i) => (
      <div key={i} className="service-card-shimmer">
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke">
              <div className="animate title" />
              <div className="animate description" />
            </div>
          </div>
        </div>
      </div>
    ))
}
