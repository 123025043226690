export const GET = "GET"
export const PUT = "PUT"
export const APPLICATION_JSON = "application/json"
export const CONTENT_TYPE = "Content-Type"
export const ONBOARDING_WELCOME_CARDS_FLAG = "ONBOARDING_WELCOME_CARDS_FLAG"
export const ONBOARDING_WELCOME_CARDS_VERSION = "ONBOARDING_WELCOME_CARDS_VERSION"
//If want to add new welcome cards...after adding that card in welcome cards
//increment count by 1 of the version number to reflect it on all the users screens
export const ONBOARDING_WELCOME_CARDS_VERSION_NUMBER = 1

export const DATE_TIME_FORMAT = Object.freeze({
  LLLL: "dddd MMMM Do YYYY",
  YYDM: "MM-DD-YY HH:mm",
  YYYYMMDD: "YYYYMMDD",
  LL: "ll",
  YYYY_MM_DDTHH: "YYYY-MM-DDTHH:mm:ss.SSSZ",
  YYYY_MM_DD: "YYYY-MM-DD",
  HH_MM: "hh:mm a",
  LLL: "lll",
  MM_YY: "MMMM YY",
  YYYYMMDDTHHmmss: "YYYYMMDDTHHmmss",
  MM_DD_YYYY_H_M: "MM-DD-YYYY HH:mm",
  HH_M: "HH:mm",
  APPOINTMENT_DATETIME_DATE_PREVIEW: "dddd, MMM D",
  APPOINTMENT_DATETIME_TIME_PREVIEW: "h:mm a",
  MM_DD_YYYY: "MM/DD/YYYY",
  YYYY_MM_DD_HH_mm: "YYYY-MM-DD HH:mm",
  MMMDth_H_MM_A: "MMM D[th], h:mm A",
  MMM: "MMM",
  D: "D",
  DDDD: "dddd",
  AM: "AM",
  PM: "PM",
  yyyy_MM_dd: "yyyy-MM-dd"
})

export const BACKSPACE = "Backspace"
export const TAB = "Tab"
export const UP_ARROW = "ArrowUp"
export const DOWN_ARROW = "ArrowDown"
export const LEFT_ARROW = "ArrowLeft"
export const RIGHT_ARROW = "ArrowRight"
export const MD_HD_VEHICLE_TYPE_ID = 10
export const MILEBAND = 2500

export const CARADVISE = "CarAdvise"
export const OEM_RECOMMENDED = "OEM_RECOMMENDED"
export const FS_NAMESPACES = "CarAdviseFS"
export const WALK_IN_TIME = "03:00"

export const CART_EVENTS = Object.freeze({
  SET_WALK_IN: "set_walk_in",
  SET_TIME: "set_time"
})

export const SUPPORT_CARADVISE_EMAIL = "support@caradvise.com"
export const SUPPORT_CARADVISE_NUMBER = "8449238473"
export const USD_CURRENCY = "usd"

export const PAYMENT_METHODS = Object.freeze({
  CREDIT_CARD: "credit_card",
  PAYPAL: "pay_pal_account"
})

export const EVENT_LISTENERS = Object.freeze({
  RESIZE: "resize",
  POPSTATE: "popstate",
  MOUSEDOWN: "mousedown"
})
