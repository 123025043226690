import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { Button } from "semantic-ui-react"
import { DemandBanner } from "../Banners/DemandBanner"
import dollorLabelLogo from "../../../images/dollor_label_vector.svg"

const StepperFooter = ({
  isPrevBtn,
  handlePreviousStep,
  handleNextStep,
  handleStepperSubmit,
  stepperContent,
  currentTabIndex,
  t,
  isSubmitting
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth)
    return () => {
      window.removeEventListener("resize", updateWindowWidth)
    }
  }, [])

  const submitCurrentStep = async () => {
    await stepperContent[currentTabIndex].clicked()
    await handleNextStep()
  }
  const infoMessage = false
  return (
    <div className="stepper-footer">
      {windowWidth <= 500 && (
        <DemandBanner iconUrl={dollorLabelLogo} titleText={t("header:alwaysBetterLabel")} />
      )}
      <div className="footer-label primary-font">{t("highlightedCta:scheduleAppointmentText")}</div>
      <div className="footer-button-section">
        {/* {isPrevBtn && (
          <button
            className="stepper-footer-btn"
            onClick={handlePreviousStep}
            disabled={currentTabIndex === 0}
          >
            Back to {stepperContent[currentTabIndex - 1].label}
          </button>
        )} */}
        <div className="footer-info-section">
          <ReactSVG src="images/caradvise_shield.svg" />
          <p>{t("bookAndPayLabel")}</p>
          {infoMessage && <ReactSVG src="images/info_icon_vector_orange.svg" />}
        </div>
        <div>
          <Button
            className={`stepper-footer-btn ${
              stepperContent[currentTabIndex].isLastStep ? "success" : "success"
            }`}
            onClick={
              stepperContent[currentTabIndex].isLastStep
                ? handleStepperSubmit
                : stepperContent[currentTabIndex].clicked
                ? submitCurrentStep
                : handleNextStep
            }
            disabled={!stepperContent[currentTabIndex].isComplete}
            loading={isSubmitting}
          >
            {stepperContent[currentTabIndex].buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}

StepperFooter.propTypes = {
  isPrevBtn: PropTypes.bool,
  handlePreviousStep: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool,
  nextStepHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  stepperContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.func.isRequired,
      clicked: PropTypes.func,
      isWarning: PropTypes.bool,
      isError: PropTypes.bool,
      isComplete: PropTypes.bool,
      isLoading: PropTypes.bool
    })
  ),
  firstStepCompletion: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  buttonLabel: PropTypes.string
}

export default StepperFooter
