import { postJSON, getJSON, putJSON, deleteJSON } from "../../shared_component/utils/fetch"
import { setLoading, setNotLoading, setUserError, setUserData, me } from "./user"
import Notifications from "../components/Notifications"
import i18n from "../../shared_component/utils/i18n"

const MEMBERSHIPS_URL = "/api/v4/memberships" // POST, PUT
const GET_MEMBERSHIP_PLANS_URL = "/api/v4/membership_plans" // GET#index
const WALLETS_URL = "/api/v5/wallets"

export const USER_MEMBERSHIP_CREATED = "USER_MEMBERSHIP_CREATED"
function userMembershipCreated(info) {
  return { type: USER_MEMBERSHIP_CREATED, payload: info }
}

export const USER_MEMBERSHIP_UPDATED = "USER_MEMBERSHIP_UPDATED"
function userMembershipUpdated(membership) {
  return { type: USER_MEMBERSHIP_UPDATED, payload: membership }
}

export const MEMBERSHIP_UPGRADE_SELECTED = "MEMBERSHIP_UPGRADE_SELECTED"
function membershipUpgradeSelected(plan) {
  return { type: MEMBERSHIP_UPGRADE_SELECTED, payload: plan }
}

export const SELECTED_MEMBERSHIP_UPGRADE_CLAUSES_UPDATED =
  "SELECTED_MEMBERSHIP_UPGRADE_CLAUSES_UPDATED"
function membershipUpgradeClausesUpdated(plan) {
  return { type: SELECTED_MEMBERSHIP_UPGRADE_CLAUSES_UPDATED, payload: plan }
}

export const AVAILABLE_MEMBERSHIP_PLANS_LOADED = "AVAILABLE_MEMBERSHIP_PLANS_LOADED"
function availableMembershipPlansLoaded(availableMembershipPlans) {
  return { type: AVAILABLE_MEMBERSHIP_PLANS_LOADED, payload: availableMembershipPlans }
}

export const SELECTED_MEMBERSHIP_UPGRADE_CLEARED = "SELECTED_MEMBERSHIP_UPGRADE_CLEARED"
function selectedMembershipUpgradeCleared() {
  return { type: SELECTED_MEMBERSHIP_UPGRADE_CLEARED, payload: {} }
}

export const APPLE_WALLET_KEY_URL = "APPLE_WALLET_KEY_URL"
function getAppleWalletKeyUrl(url) {
  return { type: APPLE_WALLET_KEY_URL, payload: url }
}

export const GOOGLE_WALLET_KEY_URL = "GOOGLE_WALLET_KEY_URL"
function getGoogleWalletKeyUrl(url) {
  return { type: GOOGLE_WALLET_KEY_URL, payload: url }
}

export function createMembership(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("common:updatingMembershipLoadingMessage")))
    let user = getState().user || {}
    let { authentication_token } = user
    let activeVehicle =
      (user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)) || {}
    let hasActiveOrder = !!(
      user.active_orders && user.active_orders.find((o) => o.vehicle_id == activeVehicle.id)
    )
    if (authentication_token) {
      const request = {
        url: MEMBERSHIPS_URL,
        headers: {
          Authorization: authentication_token
        }
      }

      let requestPayload = payload
      const plan = payload.plan
      if (plan) {
        requestPayload = {
          membership_plan_id: plan.id,
          accepted_passenger_vehicle_clause: plan.acceptedPassengerVehicleClause,
          accepted_terms_and_conditions: plan.acceptedTermsAndConditions
        }
      }

      const response = await postJSON(request.url, requestPayload, request.headers)
      if (response.result) {
        payload.callback && payload.callback("success", {})
        await dispatch(userMembershipCreated(response.result))
        await dispatch(me())
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function getMembershipPlans(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: GET_MEMBERSHIP_PLANS_URL,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }
      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(availableMembershipPlansLoaded(response.result))
      } else {
        dispatch(setUserError(response.error))
      }
      return () => response
    }
  }
}

export function getAppleWalletUrl(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: WALLETS_URL,
        body: {
          wallet_type: 2,
          auth_token: authentication_token
        }
      }
      const response = await postJSON(request.url, {
        wallet_type: 2,
        auth_token: authentication_token
      })

      if (response.result) {
        dispatch(getAppleWalletKeyUrl(response.result.pk_pass_file_url))
      } else {
        dispatch(setUserError(response.error))
      }
      return () => response
    }
  }
}

export function getGoogleWalletUrl(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: WALLETS_URL,
        body: {
          wallet_type: 2,
          auth_token: authentication_token
        }
      }
      const response = await postJSON(request.url, {
        wallet_type: 1,
        auth_token: authentication_token
      })

      if (response.result) {
        dispatch(getGoogleWalletKeyUrl(response.result.wallet_url))
      } else {
        dispatch(setUserError(response.error))
      }
      return () => response
    }
  }
}

export function updateMembership(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const membership = payload.membership
      const request = {
        url: `${MEMBERSHIPS_URL}/${membership.id}`,
        headers: {
          Authorization: authentication_token
        },
        body: membership
      }
      const response = await putJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(userMembershipUpdated(response.result))
        dispatch(
          Notifications.success({ message: i18n.t("membership:membershipUpdateSuccessMessage") })
        )
      } else {
        dispatch(setUserError(response.error))
        dispatch(
          Notifications.error({ message: i18n.t("membership:membershipUpdateFailureMessage") })
        )
      }

      return response.result
    }
  }
}

export function extendMembership(payload) {
  return async function (dispatch, getState) {
    const membership = payload.membership
    await dispatch(
      updateMembership({
        ...payload,
        membership: { id: membership.id, opted_in_to_extend_trial: true }
      })
    )
  }
}

export function clearSelectedMembershipUpgrade(payload) {
  return async function (dispatch, getState) {
    dispatch(selectedMembershipUpgradeCleared(payload))
  }
}

export function updateSelectedMembershipUpgrade(payload) {
  return async function (dispatch, getState) {
    dispatch(membershipUpgradeSelected(payload))
  }
}

export function updateSelectedMembershipUpgradeClauses(payload) {
  return async function (dispatch, getState) {
    dispatch(membershipUpgradeClausesUpdated(payload))
  }
}
