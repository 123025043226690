"use-strict"

import React, { Component } from "react"
import { connect } from "react-redux"
import YouTube from "react-youtube"

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      isLoading: false
    }
  }

  componentDidMount() {}

  render() {
    const opts = {
      height: "415",
      width: "738",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    }

    return (
      <div className="container">
        <div>
          <p>
            <YouTube videoId="sMiNdut8F3k" opts={opts} onReady={this._onReady} />
          </p>
        </div>
      </div>
    )
  }

  renderService(service, i) {
    return (
      <div>
        <div className="container">
          <p className="introHD">{service.service_name}</p>
          <div className="selectMaintenanceContainer">
            <div className="allServicesName">${Number(service.override_total).toFixed(2)}</div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex =
      user.vehicles && user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    isLoggedIn: !!user.authentication_token,
    user,
    activeVehicle
  }
}

export default connect(mapStateToProps, {})(Video)
