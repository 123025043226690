import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import { Link } from "react-router-dom"
import placeholderCarImg from "../../../../../images/placeholder_car.svg"

class ActiveVehicleComponent extends Component {
  render() {
    const { activeVehicle, t } = this.props

    return (
      <div className="dashboard_vehicle_component">
        <div className="dashboard_vehicle_heading">
          <div
            className="dashboard_vehicle_image"
            style={{ minHeight: activeVehicle.dashboard_url ? null : "23vh" }}
          >
            <Image src={activeVehicle.dashboard_url || placeholderCarImg} size="large" />
          </div>
          <div className="dashboard_vehicle_title">
            <h2 style={{ margin: "0px", padding: "0px", fontFamily: "Catamaran" }}>
              {activeVehicle.year ? activeVehicle.year : ""}{" "}
              {activeVehicle.make ? activeVehicle.make : ""}{" "}
              {activeVehicle.model ? activeVehicle.model : ""}
            </h2>
          </div>
        </div>
        <strong style={{ textAlign: "start" }}>
          {t("engine")}: {activeVehicle.engine_description}
        </strong>
        <strong>
          {t("vin")}: {activeVehicle.vin}
        </strong>
        <strong>
          {t("miles")}:
          {activeVehicle.odometer_config && activeVehicle.odometer_config.display_value
            ? activeVehicle.odometer_config.display_value
            : ""}
        </strong>
        <Link to={"/managevehicles"}>
          <p className="go_to_garage">{t("goToGarage")}</p>
        </Link>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}

  let vehicles = user.vehicles ? user.vehicles : []

  return {
    activeVehicle:
      user && user.activeVehicleId && vehicles
        ? vehicles.find((v) => v.id === user.activeVehicleId)
        : {}
  }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardVehicleComponent")(ActiveVehicleComponent)
)
