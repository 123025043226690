import React, { Component } from "react"
import cache from "../../shared_component/utils/cache"
import { getJSON } from "../../shared_component/utils/fetch"
import sortBy from "lodash/sortBy"
import { addVehicleYearRange } from "../helpers/vehicleHelpers"
import uniqBy from "lodash/uniqBy"
import { connect } from "react-redux"
import { addNewVehicle, me, setLoading, setNotLoading } from "../actions/user"
import Button from "../components/Button"
import { Redirect } from "react-router"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import MiniLoader from "./MiniLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { Grid } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { usesMembership } from "../helpers/membershipHelpers"
import { routerPaths } from "../constants/paths"
const BY_YEAR_URL = "/api/v4/vehicles/years/:year/makes"
const BY_YEAR_AND_MAKE_URL = "/api/v4/vehicles/years/:year/makes/:make"
const BY_MODEL_AND_SUB_MODEL_URL =
  "/api/v4/vehicles/years/:year/makes/:make/models/:model/submodels/:submodel"
const BY_YMM = "/api/v4/vehicles/years/:year/makes/:make/models/:model"

class MyGarageVehicleDetails extends Component {
  constructor(props) {
    super(props)

    // Note: `miles` field gets converted to `odometer` in the api call to account for mi or km.
    this.state = {
      hide_years: true,
      years: addVehicleYearRange()
        .map((n) => n.toString())
        .map((value) => ({ value, label: value, key: value })),
      hide_makes: true,
      makes: cache.get("vehicleDetails-makes") || [],
      hide_models: true,
      models: cache.get("vehicleDetails-models") || [],
      hide_sub_models: true,
      sub_models: cache.get("vehicleDetails-sub_models") || [],
      hide_engines: true,
      engines: cache.get("vehicleDetails-engines") || [],
      fields: Object.assign(
        {
          year: { value: null, invalid: false, validators: ["_isPresent"] },
          make: { value: null, invalid: false, validators: ["_isPresent"] },
          model: { value: null, invalid: false, validators: ["_isPresent"] },
          sub_model: { value: null, invalid: false, validators: ["_isPresent"] },
          engine: { value: null, invalid: false, validators: ["_isPresent"] },
          miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
        },
        cache.get("vehicleDetails-fields") || {}
      )
    }
    this._fetchMakes = this._fetchMakes.bind(this)
    this._fetchModels = this._fetchModels.bind(this)
    this._onClickNext = this._onClickNext.bind(this)
    this._validateFields = this._validateFields.bind(this)
    this._handleInput = this._handleInput.bind(this)
  }

  componentDidMount() {
    this._fetchMakes()
  }

  _renderSelector({ key, value, items, isHidden, emptyMessage, isLoading, onSelect }) {
    let renderKey = this.state.fields[key]
    if (isLoading)
      return (
        <div
          style={{
            height: "55px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FontAwesomeIcon icon={faSpinner} size={"2x"} spin />
        </div>
      )
    if (items.length === 0) items = [{ key: "0", label: emptyMessage, value: renderKey.name }]
    return (
      <div>
        <select
          className="selectMenu"
          value={renderKey.value}
          onChange={(e) => {
            let selectedValue = e.target.value
            this._onFieldChange(key, selectedValue, onSelect)
          }}
        >
          {items.map((item, i) => (
            <option key={i} value={item.value}>
              {item.value}
            </option>
          ))}
          ;
        </select>
      </div>
    )
  }

  async _fetchModels() {
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    const { t } = this.props
    if (!year || !make) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    this.setState({ loadingModels: true })
    let response = await getJSON(
      BY_YEAR_AND_MAKE_URL.replace(":year", year).replace(":make", make_id)
    )
    this.setState({ loadingModels: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let models = response.result.map((vehicle) => ({
        key: vehicle.model_id,
        label: vehicle.model,
        value: vehicle.model
      }))
      let sub_models = response.result.map((vehicle) => ({
        key: vehicle.sub_model_id,
        label: vehicle.sub_model,
        value: vehicle.sub_model,
        model: vehicle.model
      }))

      models = uniqBy(models, "key")
      models = sortBy(models, ({ label }) => label.toLowerCase())
      sub_models = sortBy(sub_models, ({ label }) => label.toLowerCase())
      cache.set("vehicleDetails-models", models)
      cache.set("vehicleDetails-sub_models", sub_models)
      cache.remove("vehicleDetails-engines")

      this.setState(
        {
          models,
          sub_models,
          engines: [],
          fields: {
            ...this.state.fields,
            model: { ...this.state.fields.model, value: "", invalid: false },
            sub_model: { ...this.state.fields.sub_model, value: "", invalid: false },
            engine: { ...this.state.fields.engine, value: "", invalid: false }
          }
        },
        () => cache.set("vehicleDetails-fields", this.state.fields)
      )
    }
  }

  async _fetchMakes() {
    const { t } = this.props
    let year = this.state.fields.year.value
    if (!year) return

    this.setState({ loadingMakes: true })
    let response = await getJSON(BY_YEAR_URL.replace(":year", year))
    this.setState({ loadingMakes: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let makes = response.result.map(({ make_id, make }) => {
        return { key: make_id, label: make, value: make }
      })
      makes = sortBy(makes, ({ label }) => label.toLowerCase())
      cache.set("vehicleDetails-makes", makes)
      cache.remove("vehicleDetails-models")
      cache.remove("vehicleDetails-sub_models")
      cache.remove("vehicleDetails-engines")
      this.setState(
        {
          makes,
          models: [],
          sub_models: [],
          engines: [],
          fields: {
            ...this.state.fields,
            make: { ...this.state.fields.make, value: "", invalid: false },
            model: { ...this.state.fields.model, value: "", invalid: false },
            sub_model: { ...this.state.fields.sub_model, value: "", invalid: false },
            engine: { ...this.state.fields.engine, value: "", invalid: false }
          }
        },
        () => cache.set("vehicleDetails-fields", this.state.fields)
      )
    }
  }

  async _fetchEngines() {
    const { t } = this.props
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    let model = this.state.fields.model.value
    let sub_model = this.state.fields.sub_model.value
    if (!year || !make || !model || !sub_model) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    sub_model = this.state.sub_models.find((a) => a.value === sub_model && a.model === model) || {}
    let sub_model_id = sub_model.key
    if (!sub_model_id) return

    model = this.state.models.find(({ value }) => value === model) || {}
    let model_id = model.key
    if (!model_id) return

    this.setState({ loadingEngines: true })
    let response = await getJSON(
      BY_MODEL_AND_SUB_MODEL_URL.replace(":year", year)
        .replace(":make", make_id)
        .replace(":model", model_id)
        .replace(":submodel", sub_model_id)
    )
    this.setState({ loadingEngines: false })

    if (response.error) {
      alert(`${t("errorText")} ${response.error}`)
    } else if (response.result) {
      let engines = response.result.map(({ id, description }) => {
        return { key: id, label: description, value: description }
      })

      engines = sortBy(engines, ({ label }) => label.toLowerCase())
      cache.set("vehicleDetails-engines", engines)

      this.setState(
        {
          engines,
          fields: {
            ...this.state.fields,
            engine: {
              ...this.state.fields.engine,
              value: engines[1] ? engines[1].value : "",
              engine_id: engines[1] ? engines[1].key : "",
              invalid: false
            }
          }
        },
        () => {
          cache.set("vehicleDetails-fields", this.state.fields)
        }
      )
    }
  }

  async _fetchVehicle() {
    let year = this.state.fields.year.value
    let make = this.state.fields.make.value
    let model = this.state.fields.model.value
    if (!year || !make || !model) return

    make = this.state.makes.find(({ value }) => value === make) || {}
    let make_id = make.key
    if (!make_id) return

    model = this.state.models.find(({ value }) => value === model) || {}
    let model_id = model.key
    if (!model_id) return

    let response = await getJSON(
      BY_YMM.replace(":year", year).replace(":make", make_id).replace(":model", model_id)
    )

    this.setState({
      fields: {
        ...this.state.fields,
        vehicleId: { value: response.result.base_vehicle_id }
      }
    })
  }

  _onClickNext() {
    let fields = this.state.fields
    this._validateFields(async () => {
      let data = {
        miles: fields.miles.value,
        year: fields.year.value,
        make: fields.make.value
      }

      let models = cache.get("vehicleDetails-models") || []
      let model = models.find(({ value }) => value === fields.model.value) || {}
      data.model_id = model.key
      data.model = model.value

      let engines = cache.get("vehicleDetails-engines") || []
      let engine = engines.find(({ value }) => value === fields.engine.value) || {}
      data.vehicle_type_extension_engine_id = engine.key
      await this.props.setLoading()
      let success = await this.props.addNewVehicle(data)
      if (success) {
        this.setState({ gotoNext: true })
      } else this.props.setNotLoading()
    })
  }

  subModelFilter(arr) {
    const { t } = this.props
    let newarray = arr.filter((a) => a.model === this.state.fields.model.value)
    newarray.unshift({ key: "0", label: t("subModelPlaceholder"), value: t("subModelPlaceholder") })
    return newarray
  }

  unshiftDropdownItems(arr, label) {
    let newarray = arr.filter((a) => a !== label)
    newarray.unshift({ key: "0", label: label, value: label })
    return newarray
  }

  _handleInput(e) {
    this._onFieldChange("miles", e.target.value)
  }

  render() {
    const { isLoggedIn, isGeneric, isLoading } = this.props
    const { user, t } = this.props
    if (!usesMembership(user) && user.vehicles.length > 0) {
      return <Redirect to={routerPaths.dashboard} />
    }
    let vinLink
    if (this.props.isMissingVehicle) {
      vinLink = "/no_vehicle_vin"
    } else if (this.props.isAddVehicle) {
      vinLink = "/addvehiclebyvin"
    } else {
      vinLink = "/sign_up/2-vin"
    }
    if (this.state.gotoNext) {
      if (this.props.isMissingVehicle || this.props.isAddVehicle) {
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/sign_up/3" />
      }
    }

    return (
      <div className="container" style={{ width: "100%" }}>
        <div className="contentCenter">
          {this.props.isAddVehicle && <p className="introText">{t("myGarageAddVehicleHeader")}</p>}
          {!this.props.isAddVehicle &&
            (!this.props.isMissingVehicle ? (
              <div>
                <div className="progressBar">
                  <div className="progress">
                    <strong>{t("step1Lbl")}</strong>
                    <br />
                    {t("accountDetailsLbl")}
                  </div>
                  <div className="progress progressOn">
                    <strong>{t("step2Lbl")}</strong>
                    <br />
                    {t("vehicleDetailsLbl")}
                  </div>
                  <div className="progress">
                    <strong>{t("step3Lbl")}</strong>
                    <br />
                    {t("paymentDetailsLbl")}
                  </div>
                </div>
                <p className="accountText">{t("almostDoneMessage")}</p>
              </div>
            ) : (
              <p className="accountText">
                {t("welcomeToCarAdviseMessage")}
                <br />
                <br />
                {t("addVehicleMessage")}
              </p>
            ))}

          {this._renderSelector({
            key: "year",
            value: this.state.fields.year.value,
            items: this.unshiftDropdownItems(this.state.years, t("yearPlaceholder")),
            isHidden: this.state.hide_years,
            emptyMessage: t("yearErrorMessage"),
            onSelect: this._fetchMakes
          })}
          {this._renderSelector({
            key: "make",
            value: this.state.fields.make.value,
            items: this.unshiftDropdownItems(this.state.makes, t("makePlaceholder")),
            isHidden: this.state.hide_makes,
            emptyMessage: t("makeErrorMessage"),
            isLoading: this.state.loadingMakes,
            onSelect: () => this._fetchModels()
          })}
          {this._renderSelector({
            key: "model",
            value: this.state.fields.model.value,
            items: this.unshiftDropdownItems(this.state.models, t("modelPlaceholder")),
            isHidden: this.state.hide_models,
            emptyMessage: t("modelErrorMessage"),
            isLoading: this.state.loadingModels,
            onSelect: () =>
              this.setState(
                {
                  engines: [],
                  fields: {
                    ...this.state.fields,
                    sub_model: { ...this.state.fields.sub_model, value: "", invalid: false },
                    engine: { ...this.state.fields.engine, value: "", invalid: false }
                  }
                },
                () => {
                  cache.set("vehicleDetails-fields", this.state.fields)
                  this._fetchVehicle()
                }
              )
          })}
          {this._renderSelector({
            key: "sub_model",
            value: this.state.fields.sub_model.value,
            items: this.subModelFilter(this.state.sub_models),
            isHidden: this.state.hide_sub_models,
            emptyMessage: t("subModelErrorMessage"),
            onSelect: () => {
              this._fetchEngines()
            }
          })}
          {this._renderSelector({
            key: "engine",
            value: this.state.fields.engine.value,
            items: this.unshiftDropdownItems(this.state.engines, t("enginePlaceholder")),
            isHidden: this.state.hide_engines,
            isLoading: this.state.loadingEngines,
            emptyMessage: t("engineErrorMessage")
          })}
          <div>
            <input
              data-qa="registration-vehicle-details-mileage-input-field"
              refs="inputMiles"
              type="number"
              className="inputFld"
              style={{ width: "240px", marginLeft: "-5px", marginTop: "7px" }}
              placeholder={t("milesPlaceholder")}
              value={this.state.fields.miles.value}
              onChange={this._handleInput}
            />
            <br />
          </div>
          <div style={{ width: "255px", margin: "10px auto" }}>
            <Grid columns="equal">
              <Grid.Column>
                <Button text={t("enterVinBtn")} colorStyle="grey" linkTo={vinLink} />
              </Grid.Column>
              <Grid.Column>
                <Button
                  disabled={isLoading}
                  text={isLoading ? <MiniLoader /> : t("nextBtn")}
                  colorStyle="orange"
                  onClick={this._onClickNext}
                />
              </Grid.Column>
            </Grid>
          </div>
          {this.props.isMissingVehicle && (isGeneric || !isLoggedIn) && (
            <p>
              {t("alreadyHaveAccountLbl")}
              <span className="primary-link">
                <Link to="/signIn">{t("clickHereLbl")}</Link>
              </span>
            </p>
          )}
          <br />
        </div>
      </div>
    )
  }

  _isPresent(value) {
    return !!value
  }

  _isPositive(value) {
    return parseInt(value) > 0
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _onFieldChange(key, value, onSelect) {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      },
      onSelect
    )
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props
    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (firstInvalidKey) alert(`${t("alertErrorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else if (callback) {
        callback()
      }
    })
  }
}

function mapStateToProps(state, ownProps) {
  let user = state.user || {}
  let pathName = ownProps.match.path

  return {
    originPath: pathName,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading,
    isGeneric: user.generic,
    firstName: user.firstName,
    lastName: user.lastName,
    activeVehicleId: user.activeVehicleId,
    user: user
  }
}

export default connect(mapStateToProps, { addNewVehicle, me, setLoading, setNotLoading })(
  withTranslation("myGarageVehicleDetails")(MyGarageVehicleDetails)
)
