import React from "react"
import MapThumbnail from "../../../shared_component/components/MapThumbnail"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { DATE_TIME_FORMAT } from "../../../v1/constants/application"
import { getMapLink, getShopAddress } from "../../../v1/helpers/shopHelpers"
import { Image, Button } from "semantic-ui-react"
import EmptyShopLogo from "../../../images/empty_shop_logo.svg"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { constants } from "caradvise_shared_components"
import { WALK_IN_ACTIVE } from "../../../shared_component/constants/common"
import ReactSVG from "react-svg"
import EditIcon from "../../../images/edit-pencil-icon.svg"
import { Link } from "react-router-dom"
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

export const CheckInDetails = ({ cart, history, restrictNavigation }) => {
  const { t } = useTranslation("reviewOrder")
  const showDateTime = (cart) => {
    return (
      <>
        <span>
          {cart.appointment_datetime &&
            moment(cart.appointment_datetime)
              .parseZone()
              .format(DATE_TIME_FORMAT.APPOINTMENT_DATETIME_DATE_PREVIEW)}
        </span>
        <span>
          {cart.appointment_time_pretty.includes("3:00 AM")
            ? " - "
            : cart.appointment_time_pretty && " @ "}
        </span>
        {cart.appointment_time_pretty.includes("3:00 AM") ? (
          t("timeInformation:walkInShopAvailablityText")
        ) : (
          <span>
            {cart.appointment_datetime &&
              moment(cart.appointment_datetime)
                .parseZone()
                .format(DATE_TIME_FORMAT.APPOINTMENT_DATETIME_TIME_PREVIEW)}
          </span>
        )}
      </>
    )
  }
  const { shop, company } = cart
  const includesStatuses = [ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE]

  const onHandleClick = async () => {
    await restrictNavigation()
    routeChange(history, routerPaths.checkoutSchedule)
  }

  return (
    <div className="check-in-details-container">
      <div className="check-in-header-section">
        <div className="header">
          <span className="label">{t("appointmentDetailsLbl")}</span>
          <span className="label-text">{showDateTime(cart)}</span>
        </div>
        {cart.status == null ||
          (includesStatuses.includes(cart.status) &&
            (cart.is_tire_order ? (
              <Link to={routerPaths.rescheduleAppointment}>
                <span className="change-link">{t("changeLabel")}</span>
              </Link>
            ) : (
              <Button onClick={() => onHandleClick()} className="modify-appointment-button">
                <ReactSVG src={EditIcon} className="modify-appointment-edit-icon" />
                <span className="modify-appointment-text">{t("modifyAppointmentLbl")}</span>
              </Button>
            )))}
      </div>
      <div className="horizontal-divider" />
      <div className="shop-company-section">
        <div className="company-name-section">
          <Image
            style={{ width: "30px" }}
            src={cart.shop && cart.shop.logo_url ? cart.shop.logo_url : EmptyShopLogo}
          />
          <p className="name">{`${shop.name} ${
            shop.store_number && shop.store_number !== null && shop.store_number
          }`}</p>
        </div>
      </div>
      <div className="check-in-content-section">
        <div className="map-section">
          <MapThumbnail
            latitude={shop.lat}
            longitude={shop.lng}
            zoom={13}
            width={100}
            height={100}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            shopName={shop.name}
          />
        </div>
        <div className="direction-section">
          <div className="label">{t("drivingDirectionsLabel")}</div>
          <a className={"label-text"} href={getMapLink(shop)} target={"_blank"}>
            {getShopAddress(shop)}
          </a>
        </div>
      </div>
    </div>
  )
}
