import { constants } from "caradvise_shared_components"
import store from "../../shared_component/utils/configureStore"
import { getActiveVehicle } from "./vehicleHelpers"

const {
  orders: { INITIATED_BY_USER, ACTIVE, AWAITING_APPROVAL, COMPLETED, PAID, APPROVAL }
} = constants

// TODO: This deprecated status is still coming back from a serializer in the api.
// Need to go through and get rid of its usage.
const DEPRECATED_APPROVED = "approved"
const AWAITING_APPROVAL_LABEL = "Needs Approval"
const INITIATED_LABEL = "Initiated"
const APPROVED_LABEL = "Approved"
const COMPLETED_LABEL = "Completed"

export function isApproved(order) {
  if (order == null) return false

  return [APPROVAL, DEPRECATED_APPROVED].indexOf(order.status) != -1
}

export const getActiveOrder = () => {
  let activeOrder
  const state = store.getState()
  const activeVehicle = getActiveVehicle()
  const activeOrders =
    state.user && state.user.active_orders && state.user.active_orders.length
      ? state.user.active_orders
      : []
  activeOrder =
    activeVehicle && activeOrders.find(({ vehicle_id }) => vehicle_id === activeVehicle.id)
  return activeOrder || null
}

export const isWalkIn = (order) => {
  if (order && order.appointment_time_pretty) {
    return order.appointment_time_pretty.includes("3:00 AM")
  }
  return false
}
export const getUpdatedCart = (user, order, props, isCartFlag = false) => {
  let isCart
  let cart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let newOrder =
    (user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)) ||
    currentActiveOrder
  if (order) {
    cart = newOrder
    isCart = isCartFlag ? true : false
  } else if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }
  return {
    cart: cart,
    isCart: isCart
  }
}

export const getOrderStatus = (order) => {
  if ([INITIATED_BY_USER, ACTIVE].includes(order.state)) return INITIATED_LABEL
  else if (order.state == AWAITING_APPROVAL) return AWAITING_APPROVAL_LABEL
  else if ([COMPLETED, PAID].includes(order.state)) return COMPLETED_LABEL
  else if ([APPROVAL].includes(order.state)) return APPROVED_LABEL
}
