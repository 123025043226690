import React, { Component, Fragment } from "react"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"

class RoadsideInfoCta extends Component {
  render() {
    const { t } = this.props
    return (
      <Fragment>
        <div class="need-a-tow-outer-wrapper">
          <div class="need-a-tow-border-wrapper">
            <div class="need-a-tow-inner-box">
              <div class="tow-truck-img">
                <ReactSVG src={`images/truck.svg`} />
              </div>
              {t("needATow?")}
              <br />
              <a className="primary-link secondary-font" href="tel:877-244-5140">
                {t("call")}
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation("roadsideInfoCta")(RoadsideInfoCta)
