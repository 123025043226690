// TODO: add tests
export function sortByAttr(collection, attr, direction = "asc") {
  const lessThanVal = direction === "asc" ? -1 : 1
  const greaterThanVal = lessThanVal === -1 ? 1 : -1

  return collection.sort((a, b) => {
    let aVal = a[attr]
    let bVal = b[attr]
    if (aVal == null) return lessThanVal
    if (bVal == null) return greaterThanVal

    if (typeof a[attr] === "string") {
      aVal = aVal.toLowerCase()
      bVal = bVal.toLowerCase()
    }

    return aVal < bVal ? lessThanVal : aVal > bVal ? greaterThanVal : 0
  })
}
