import React, { Component } from "react"
import { connect } from "react-redux"
import NotificationSystem from "./Notifications"
import PropTypes from "prop-types"

class NotificationContainer extends Component {
  render() {
    const { notifications } = this.props

    return <NotificationSystem notifications={notifications} />
  }
}

NotificationContainer.contextTypes = {
  store: PropTypes.object
}

NotificationContainer.propTypes = {
  notifications: PropTypes.array
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps)(NotificationContainer)
