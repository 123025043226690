import React from "react"
import MilesEarnedCard from "./MilesEarnedCard"
import EstimatedMilesToEarn from "./EstimatedMilesToEarn"

const RewardMilesAndEarn = () => {
  return (
    <div className="reward-miles-earn">
      <MilesEarnedCard />
      <EstimatedMilesToEarn />
    </div>
  )
}

export default RewardMilesAndEarn
