import React, { useEffect, useState } from "react"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Redirect, withRouter } from "react-router-dom"
import defaultCar from "../../images/placeholder_car.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getRimSize, resetTireData, saveTireSizeDetails } from "../actions/tires"
import { FACTORY_TIRE_SIZE, CUSTOM_TIRE_SIZE } from "../constants/tires"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { routerPaths } from "../constants/paths"
import TireSizeInfoImage from "../../images/tire-size.svg"
import FullPageLoader from "../../shared_component/components/FullPageLoader"

function TireSizeLoading() {
  return (
    <div className="loading-shimmer">
      <button className="shimmer-btn-loading"></button>
      <button className="shimmer-btn-loading"></button>
    </div>
  )
}

function TireSize({
  activeVehicle,
  getRimSize,
  t,
  saveTireSizeDetails,
  selectedTireSizeData,
  resetTireData,
  user,
  history
}) {
  const [toggleState, setToggleState] = useState(FACTORY_TIRE_SIZE)
  const [topping, setTopping] = useState(selectedTireSizeData && selectedTireSizeData.key)
  const [isLoading, setIsLoading] = useState(true)
  const [size, setSize] = useState()
  const [activeBtn, setActiveBtn] = useState(false)
  const [partnerFlag, setPartnerFlag] = useState()

  const onOptionChange = (e, selectedTire) => {
    setTopping(e.target.value)
    setActiveBtn(true)
    resetTireData()
    saveTireSizeDetails(selectedTire)
  }

  useEffect(() => {
    if (size && size.length === 1) {
      setActiveBtn(true)
      saveTireSizeDetails(size[0])
      history.push(routerPaths.topTires)
    } else if (size && size.length > 1 && selectedTireSizeData && selectedTireSizeData.key) {
      setActiveBtn(true)
    }
  }, [size])

  const toggleTab = (index) => {
    setToggleState(index)
  }

  const rimSize = async () => {
    const response = await getRimSize()
    setIsLoading(false)
    const rimSizes = response.result && response.result.rim_sizes

    const output = rimSizes
      ? rimSizes.map(function (obj) {
          const entries = Object.entries(obj)
          const key = entries[0] && entries[0][0]
          const value = entries[0] && entries[0][1]
          return { key, value }
        })
      : []
    setSize(output)
  }

  useEffect(() => {
    rimSize()
  }, [])

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : isLoading ? (
        <div className="tiresize-loader">
          <FullPageLoader />
        </div>
      ) : (
        <div className="tire-size-sec">
          <div className="tire-size">
            <div className="back-btn">
              <Link to={routerPaths.offers}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h3>{t("Back")}</h3>
            </div>
            <div className="tires">
              <div className="tire-size-box-parent">
                <div className="tire-size-box">
                  <div className="vehicle-details">
                    <div className="vehicle-heading">{t("Tire_size_text")}</div>
                    <div className="about">
                      {activeVehicle && activeVehicle.make + " " + activeVehicle.model}{" "}
                      {t("Tire_size_desc")}
                    </div>
                  </div>
                  <div className="tabs-parent">
                    <div className="bloc-tabs">
                      <div
                        className={toggleState === FACTORY_TIRE_SIZE ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(FACTORY_TIRE_SIZE)}
                      >
                        {t("Factory_tire_size")}
                      </div>
                      {/* Will need this later on */}
                      {/* <div
                        className={toggleState === CUSTOM_TIRE_SIZE ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(CUSTOM_TIRE_SIZE)}
                      >
                        {t("Custom_tire_size")}
                      </div> */}
                    </div>
                    <div className="content-tabs">
                      <div
                        className={
                          toggleState === FACTORY_TIRE_SIZE ? "content active-content" : "content"
                        }
                      >
                        {isLoading ? (
                          <TireSizeLoading />
                        ) : (
                          <div className="size-selection">
                            {size && size.length > 0 ? (
                              size.map((item, index) => (
                                <label
                                  className={`sizes ${
                                    topping === item.key || (size.length === 1 && index === 0)
                                      ? "checked"
                                      : ""
                                  }`}
                                  key={index}
                                  htmlFor={`size-${index}`}
                                >
                                  {item.key}'' {t("inch")}
                                  <input
                                    type="radio"
                                    id={`size-${index}`}
                                    name=""
                                    value={item.key}
                                    checked={
                                      topping === item.key || (size.length === 1 && index === 0)
                                    }
                                    onChange={(e) => onOptionChange(e, size[index])}
                                  />
                                </label>
                              ))
                            ) : (
                              <p className="no-size-text">
                                {t("no_size_text")}
                                <Link to={routerPaths.managevehicles}>{t("goto_garage")}</Link>
                                {t("change_vehicle_text")}.
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      {/* Will need this later on */}
                      {/* <div
                        className={
                          toggleState === CUSTOM_TIRE_SIZE ? "content active-content" : "content"
                        }
                      >
                        <div className="size-selection">
                          <div className="custom-size">
                            <label>{t("Enter_customized_tire_size")}</label>
                            <Input type="number" />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="tire-size-button-container">
                    <Image src={TireSizeInfoImage} />
                    {size && size.length > 0 && (
                      <div className="btn-parent">
                        <Link to={routerPaths.topTires}>
                          <button
                            className={`button ${activeBtn ? "active-btn" : ""}`}
                            disabled={!activeBtn}
                          >
                            {t("Shop_tires")}
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="car-img">
                <h4 className="car-name">
                  {activeVehicle && activeVehicle.make + " " + activeVehicle.model}
                </h4>
                <p className="car-year">{activeVehicle && activeVehicle.year}</p>
                <div className="img-parent">
                  <span className="img-strip">
                    <Image
                      className="hide-bg"
                      src={
                        activeVehicle && activeVehicle.dashboard_url
                          ? activeVehicle.dashboard_url
                          : defaultCar
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  let selectedTireSizeData = state.saveBuyTireDetails.selectedTireSizeData
  return {
    user: user,
    activeVehicle: activeVehicle,
    selectedTireSizeData: selectedTireSizeData
  }
}

export default connect(mapStateToProps, { getRimSize, saveTireSizeDetails, resetTireData })(
  withTranslation("tireSize")(withRouter(TireSize))
)
