import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import HowEarnMilesCards from "./HowEarnMilesCards"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { withRouter } from "react-router-dom"
import RewardTagFilter from "../RewardsHistory/RewardTagFilter"
import { getRewards } from "../../../v1/actions/rewards"
import { connect } from "react-redux"
import Notifications from "../../../v1/components/Notifications"
import store from "../../../shared_component/utils/configureStore"
import RewardsPlaceholder from "./RewardsPlaceholder"
import { ALL, EARN, TAGS } from "../../../shared_component/constants/rewards"

const HowEarnMiles = ({ t, history, getRewards, userRewards }) => {
  const [selectedTag, setSelectedTag] = useState(ALL)
  const [isLoading, setIsLoading] = useState(false)

  const getRewardsData = async () => {
    setIsLoading(true)
    try {
      let rewardsRes = {}

      if (selectedTag === ALL) {
        rewardsRes = await getRewards()
      } else {
        rewardsRes = await getRewards({ rewardType: EARN })
      }

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    } catch (error) {
      console.log(t("errorInHowEarnMiles"), error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRewardsData()
  }, [selectedTag])

  const rewardsPlaceholderUI = userRewards && userRewards.length && userRewards.length > 0

  return (
    <div className="miles-container">
      <div className="earn-miles-header-box">
        <p className="earn-miles-header">{t("earnMiles")}</p>
        <p
          className="earn-miles-how-works"
          onClick={() => {
            routeChange(history, routerPaths.earnMiles)
          }}
        >
          {t("seeAll")}
        </p>
      </div>
      <div className="earn-miles-filter-box">
        {TAGS.map((option) => (
          <RewardTagFilter
            key={option.id}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            tag={option.tagLabel}
          />
        ))}
      </div>
      <div
        className={
          rewardsPlaceholderUI || isLoading ? "how-earn-miles-container" : "placeholder_image"
        }
      >
        {isLoading ? (
          [...new Array(5)].map(() => <div className="miles_shimmer"></div>)
        ) : rewardsPlaceholderUI ? (
          userRewards.map((reward) => (
            <HowEarnMilesCards
              isActionButtonVisible={selectedTag === ALL}
              key={reward.id}
              earnMiles={reward}
            />
          ))
        ) : (
          <RewardsPlaceholder />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userRewards:
      state.rewards && state.rewards.userRewards && state.rewards.userRewards.length > 0
        ? state.rewards.userRewards
        : []
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getRewards
  })(withTranslation("rewards")(HowEarnMiles))
)
