import React, { Component } from "react"
import LocationIconOranges from "../../../../images/location_vector_orange.svg"
import { Image, Input } from "semantic-ui-react"
import ReactSVG from "react-svg"
import MiniLoader from "../../../../v1/components/MiniLoader"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { identifyEvent, trackEvent } from "../../../../shared_component/utils/segmentAnalytics"
import _ from "lodash"
import { getSubdomain } from "../../../../v1/components/Config/StylesheetInjector"
import { POSTAL_CODE_REGEX, ZIP_CODE_REGEX } from "../../../../shared_component/utils/stringHelpers"
import store from "../../../../shared_component/utils/configureStore"
import Notifications from "../../../../v1/components/Notifications"
import { updateInfo } from "../../../../v1/actions/user"

class ZipEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      isSubmitting: false,
      fields: {
        zip: {
          name: "zipCode",
          value: props.user.zip || "",
          invalid: false,
          validators: ["_isPresent", "_isZipCodeValid"]
        }
      }
    }
  }
  componentDidUpdate(prevprops) {
    const { user } = this.props
    if (prevprops.user.zip !== user.zip) {
      this.setState({
        fields: {
          zip: {
            value: user.zip
          }
        }
      })
    }
  }

  saveDetails = () => {
    const { user, t } = this.props
    const { fields } = this.state
    const { zip } = fields
    this._validateFields(async () => {
      this.setState({ isSubmitting: true })
      if (user.zip !== zip.value) {
        const response = await this.props.updateInfo({
          zip: zip.value
        })
        if (response === true) {
          await identifyEvent({
            traits: {
              zip: fields.zip.value
            }
          })
          trackEvent("profile-updated", {
            zip: fields.zip.value
          })
          this.showAlert(t("header:infoUpdatedAlertMessage"))
        }
      }
      this.setState({ isEditing: false, isSubmitting: false })
    })
  }

  render() {
    const { t } = this.props
    const { fields, isEditing, isSubmitting } = this.state
    const { zip } = fields
    const svgStyle = { width: 25, height: 25 }
    return (
      <div className="zip-edit-container">
        <Image src={LocationIconOranges} />
        <Input
          transparent
          placeholder={t("header:zipCodeText")}
          value={zip.value}
          className="zip-input"
          ref="zipCode"
          onClick={() => this.onEditing()}
          onChange={(value) => this._onFieldChange("zip", value.target.value)}
        >
          <input />
        </Input>
        <div className="zip-actions">
          {!isEditing ? (
            <span onClick={() => this.onEditing()}>
              <ReactSVG svgStyle={svgStyle} src={`images/edit_circle.svg`} />
            </span>
          ) : !isSubmitting ? (
            <>
              <span onClick={() => this.saveDetails()}>
                <ReactSVG svgStyle={svgStyle} src={`images/check_circle.svg`} />
              </span>
              &nbsp; &nbsp;
              <span onClick={() => this.onCancel()}>
                <ReactSVG svgStyle={svgStyle} src={`images/cancel_circle.svg`} />
              </span>
            </>
          ) : (
            <MiniLoader inverted={false} />
          )}
        </div>
      </div>
    )
  }
  _isPresent(value) {
    return !!value.trim()
  }

  _isZipCodeValid(value) {
    if (_.includes(getSubdomain(), "-ca")) {
      return POSTAL_CODE_REGEX.test(value)
    } else return ZIP_CODE_REGEX.test(value)
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _onFieldChange(key, value) {
    this.setState({
      onFieldChange: true,
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _validateZipCode = () => {
    const { fields } = this.state
    const zipCode = fields.zip.value
    const { t } = this.props

    if (!this._isZipCodeValid(zipCode)) {
      this.showError(t("header:invalidZipCodeMessage"))
      return false
    }
    return true
  }

  _validateFields = (callback) => {
    if (!this._validateZipCode()) return
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey)
        this.showError(`${t("header:errorMessage")} ${t(`header:${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("header:errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("header:successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  onEditing = () => {
    this.setState({
      isEditing: true
    })
  }

  onCancel = () => {
    const { user } = this.props
    this.setState({
      isEditing: false,
      fields: {
        zip: {
          value: user.zip
        }
      }
    })
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let activeOrder =
    user.active_orders && user.active_orders.find((s) => s.vehicle_id == user.activeVehicleId)
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    user: user,
    cart: cart,
    location: props.location,
    activeOrder,
    activeVehicle,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, { updateInfo })(
  withTranslation(["selectShop", "header", "common"])(ZipEdit)
)
