export const AUTOGLASS_CONTACT = "888-201-9269"
export const AUTOGLASS_EMAIL = "Caradvise@autoglass.com"
export const AUTO_GLASS = "AUTO_GLASS"
export const CARADVISE_AUTOGLASS = "CarAdvise Auto Glass"
export const SAFELITE = "Safelite"
export const WINSHIELD_REPLACEMENT =
  "https://ad.doubleclick.net/ddm/trackclk/N1068.2825COMMISSIONJUNCTION/B25410315.314730328;dc_trk_aid=506959616;dc_trk_cid=146182569;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=?https://www.safelite.com/cjreplace-30?promo=CJ30RPLC&utm_source=cjunction&utm_medium=affiliate&utm_campaign=cjreplace"

export const WINSHIELD_REPAIR =
  "https://ad.doubleclick.net/ddm/trackclk/N1068.2825COMMISSIONJUNCTION/B25410315.314976713;dc_trk_aid=507698287;dc_trk_cid=146182569;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=?https://www.safelite.com/cjrepair-15?promo=15RPRCJ&utm_source=cjunction&utm_medium=affiliate&utm_campaign=15RPRCJ"

export const AUTO_GLASS_NOW = "AutoGlassNow"

export const CARADVISE_AUTOGLASS_URL =
  "https://app.smartsheet.com/b/form/d80ebcb8577a44a5849d4fa1dcf9ea40"

export const BLANK_TARGET = "_blank"
