import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { routerPaths } from "../../../constants/paths"
import { withTranslation } from "react-i18next"

const CustomHeaderComponent = (props) => {
  const { t } = props
  const getHeaderTitle = (route) => {
    switch (route) {
      case `#${routerPaths.updatePassword}`:
        return <p>{t("update-password")}</p>
      case `#${routerPaths.paymentInformation}`:
        return <p>{t("payment-info")}</p>
      case `#${routerPaths.personalInfo}`:
        return <p>{t("personal-info")}</p>
      case `#${routerPaths.preferences}`:
        return <p>{t("profile:manage-alerts.page-title")}</p>
      default:
        return (
          <>
            <div className="headerLogo" />
            <div className="partnerLogo" />
          </>
        )
    }
  }

  return getHeaderTitle(props.page)
}

export default connect()(withTranslation("header")(CustomHeaderComponent))
