import React, { Component } from "react"
import { connect } from "react-redux"
import { Button, Dropdown } from "semantic-ui-react"
import debounce from "lodash/debounce"
import { postJSON } from "../../../shared_component/utils/fetch"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import NoServicesContent from "./NoServicesContent"
import {
  maxPercentDiscountDiff,
  nextMembershipUpgrades
} from "../../../v1/helpers/membershipHelpers"
import LoadingServices from "../../../v1/components/BrowseServices/LoadingServices"
import { withTranslation } from "react-i18next"
import NoServices from "../../../v1/components/BrowseServices/NoServices"
import ServiceListItem from "./ServiceListItems"
import MembershipUpgradeModal2_0 from "../../../v2/components/Membership2.0/MembershipUpgradeModal"
import { Redirect, withRouter } from "react-router-dom"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const SEARCH_SERVICES_URL = "/api/v4/services/search"

class SearchServices extends Component {
  constructor(props) {
    super(props)
    this.debouncedHandleApiCall = debounce(this.handleApiCall, 500)
    this.timeout = 0
  }

  state = {
    currentServices: [],
    searchQuery: "",
    value: null,
    redirectTo: null,
    membershipModalOpen: false
  }

  handleChange = (e, { value }) => {
    if (value && value != this.state.value) {
      // dont rerender if unneeded
      this.setState({ value })
    }
  }

  handleRedirect = (redirectTo) => {
    this.setState({ redirectTo: redirectTo })
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the pathname has changed
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ redirectTo: null })
    }
  }

  closeItem = () => this.setState({ value: null })

  handleApiCall = async (searchQuery) => {
    const { t } = this.props
    if (searchQuery.length <= 1) return false
    await this.setState({ isFetching: true })

    const response = await postJSON(
      SEARCH_SERVICES_URL,
      {
        query: {
          type: "terms",
          where: searchQuery
        },
        vehicle_id: this.props.user.activeVehicleId
      },
      {
        Authorization: this.props.user.authentication_token
      }
    )

    const currentServices = response.result || []

    this.setState({ isFetching: false, currentServices })
  }

  handleTrackEvent = (searchQuery) => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      trackEvent("search-service-search-bar", {
        searchTerm: searchQuery
      })
    }, 2000)
  }

  handleSearchChange = async (e, { searchQuery }) => {
    this.handleTrackEvent(searchQuery)
    this.setState({ searchQuery })
    this.debouncedHandleApiCall(searchQuery)
  }

  getFilteredServices() {
    const { user, cart, isCart, order } = this.props
    const shop = (cart && cart.shop) || {}
    const hasActiveOrder =
      user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)

    let cartItemsIds = []
    if (cart && cart.order_services)
      cartItemsIds = cart.order_services.map((os) => os.service_definition_id)

    return this.state.currentServices.map((serviceDefinition) => ({
      key: serviceDefinition.id,
      text: serviceDefinition.name,
      value: serviceDefinition.id,
      content: (
        <ServiceListItem
          closeItem={this.closeItem}
          serviceDefinition={serviceDefinition}
          selected={serviceDefinition.id == this.state.value}
          shop={shop}
          activeVehicle={this.props.activeVehicle}
          hasActiveOrder={hasActiveOrder}
          isPresentInCart={cartItemsIds.includes(serviceDefinition.id)}
          os={
            (cart &&
              cart.order_services &&
              cart.order_services.length > 0 &&
              cart.order_services.find((os) => os.id === serviceDefinition.id)) ||
            {}
          }
          handleRedirect={this.handleRedirect}
          isCart={isCart}
          order={order}
        />
      )
    }))
  }

  toggleMembershipUpgradeModal = () => {
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  membershipUpgradeUpsell = (percentDiscount) => {
    const { t } = this.props
    return {
      key: "membership-upsell",
      text: null,
      value: null,
      className: "membership-div",
      content: (
        <div onClick={(e) => e.stopPropagation()} className="membership-upsell">
          <small>{`${t("saveAdditionalLbl")} ${parseInt(percentDiscount)}%`}</small>
          <Button
            size="tiny"
            content={t("upgradeMembershipText")}
            onClick={this.toggleMembershipUpgradeModal}
          />
        </div>
      )
    }
  }

  getEmptyResultSet() {
    return [
      {
        key: 1,
        text: this.state.searchQuery,
        value: 1,
        content: !this.state.searchQuery ? (
          <NoServicesContent />
        ) : this.state.searchQuery <= 1 ? (
          <NoServicesContent />
        ) : (
          <NoServices />
        )
      }
    ]
  }

  getLoadingResultSet() {
    return [
      {
        key: 1,
        text: this.state.searchQuery,
        value: 1,
        content: <LoadingServices />
      }
    ]
  }

  getResultSet() {
    const { user } = this.props
    const filteredServices = this.getFilteredServices()
    const emptyResults = filteredServices.length === 0
    const { isFetching } = this.state
    if (emptyResults && !isFetching) return this.getEmptyResultSet()
    else if (emptyResults && isFetching) return this.getLoadingResultSet()
    else {
      let results = this.getFilteredServices()
      const { nextUpgrades } = this.props
      const { membership } = user
      if (!membership || nextUpgrades.length === 0) return results
      const discount = maxPercentDiscountDiff(membership, nextUpgrades)

      if (discount && discount > 0) results.unshift(this.membershipUpgradeUpsell(discount))

      return results
    }
  }

  render() {
    const {
      isFetching,
      searchQuery,
      currentServices,
      value,
      membershipModalOpen,
      redirectTo
    } = this.state
    const { nextUpgrades, t } = this.props

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    return (
      <div className="search-bar">
        {membershipModalOpen && (
          <MembershipUpgradeModal2_0
            isOpen={membershipModalOpen}
            closeModal={this.toggleMembershipUpgradeModal}
            variableContentType="settingsSection"
            nextUpgrades={nextUpgrades}
          />
        )}
        <Dropdown
          fluid
          loading={isFetching}
          value={null}
          onChange={this.handleChange}
          onSearchChange={this.handleSearchChange}
          options={this.getResultSet()}
          placeholder={t("searchAServicePlaceholder")}
          search={(options) => options}
          // fullTextSearch={false}
          // filterRemoteData={false}
          noResultsMessage={t("noResultMessage")}
          selection
          icon={"search"}
          closeOnChange={false}
        />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  const membershipData = state.membershipData || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  return {
    cart,
    isCart,
    order,
    activeVehicle,
    nextUpgrades: nextMembershipUpgrades(user, membershipData.availableMembershipPlans),
    availableMembershipPlans: membershipData.availableMembershipPlans || [],
    user: user
  }
}

export default connect(mapStateToProps)(
  withTranslation("browseServices")(withRouter(SearchServices))
)
