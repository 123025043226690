import { getSubdomain } from "../components/Config/StylesheetInjector"
import {
  EBAY,
  CARVANA,
  ALLSTATE,
  PAYLINK,
  USAA,
  CARADVISE,
  UBER_CANADA,
  UBER,
  DASHERDIRECT
} from "../constants/affiliations"

export function hidePartnerOffers() {
  return isAllstate() || isCarvana() || isPaylink() || isUsaa() || isUberCanada()
}

export function isDasherDirect() {
  return isAffiliation(DASHERDIRECT)
}

export function isAllstate() {
  return isAffiliation(ALLSTATE)
}

export function isCarvana() {
  return isAffiliation(CARVANA)
}

export function isEbay() {
  return isAffiliation(EBAY)
}

export function isPaylink() {
  return isAffiliation(PAYLINK)
}

export function isUsaa() {
  return isAffiliation(USAA)
}

export function isCaradvise() {
  return isAffiliation(CARADVISE)
}

export function isUberCanada() {
  return isAffiliation(UBER_CANADA)
}

export function isUber() {
  return isAffiliation(UBER)
}

export function isEbayCart(user = {}) {
  const affiliation = user.affiliation || {}
  return isEbay() && affiliation.name && affiliation.name.toLowerCase() === EBAY
}

// Determine if we're in the context of a given Affiliation.
function isAffiliation(name) {
  return getSubdomain() === name
}
