import React, { PureComponent } from "react"
import MembershipBenefitsList from "./MembershipBenefitsList"
import { getSubdomain } from "../../../v1/components/Config/StylesheetInjector"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { withTranslation } from "react-i18next"
import { BASIC, PREMIUM } from "../../../v1/constants/tires"
import { Image } from "semantic-ui-react"
import UberLogo from "../../../images/dashboard/uber-logo.svg"
import InstaCartLogo from "../../../images/dashboard/instacart-logo.svg"
import USAALogo from "../../../images/dashboard/usaa-logo.svg"
import ShiptLogo from "../../../images/dashboard/shipt-logo.svg"
import GrubhubLogo from "../../../images/dashboard/grubhub-logo.svg"
import {
  GRUBHUB,
  INSTACART,
  INSTACART_CA,
  SHIPT,
  UBER,
  UBER_CANADA,
  USAA
} from "../../../v1/constants/affiliations"
import { ELITE } from "../../../v1/helpers/membershipHelpers"

const subDomain = getSubdomain()
class MembershipUpgradeCard extends PureComponent {
  render() {
    const {
      plan,
      isSelected,
      t,
      onSelectMembership,
      currentMembershipPlan,
      isAffiliation,
      isDashboardModal
    } = this.props
    const isPremium = plan && plan.name === PREMIUM
    const isBasic = plan && plan.name === BASIC
    const selectedAccountTier = currentMembershipPlan.name == plan.name
    const isPriceAvailable = plan.is_free_trial || plan.price == 0
    const previousPlan = currentMembershipPlan.name == PREMIUM ? isBasic : false
    const eliteSelected = currentMembershipPlan.name == ELITE ? isBasic || isPremium : false
    const affilationInfo = [
      {
        name: UBER,
        logo: UberLogo
      },
      {
        name: UBER_CANADA,
        logo: UberLogo
      },
      {
        name: INSTACART,
        logo: InstaCartLogo
      },
      {
        name: INSTACART_CA,
        logo: InstaCartLogo
      },
      {
        name: USAA,
        logo: USAALogo
      },
      {
        name: SHIPT,
        logo: ShiptLogo
      },
      {
        name: GRUBHUB,
        logo: GrubhubLogo
      }
    ]
    const affilationData = affilationInfo.filter((item) => item.name === subDomain)
    const showCardBlur =
      eliteSelected && isDashboardModal
        ? true
        : previousPlan && isDashboardModal
        ? true
        : isDashboardModal
        ? false
        : isAffiliation && previousPlan
    return (
      <div
        className={`membership-upgrade-card-content
           ${isSelected ? "upgrade-card-bg" : ""} 
           ${showCardBlur ? "card-blurred" : ""}
           `}
        onClick={() => !isSelected && onSelectMembership(plan)}
      >
        {isPremium && (
          <div className="membership-upgrade-recommended-card">{t("recommendedText")}</div>
        )}
        <div className="membership-upgrade-card-container">
          <div className="membership-upgrade-card-header">
            <span className="plan-type">
              {plan.name}
              {selectedAccountTier && (
                <span className="your-current-plan">{t("yourCurrentPlanText")}</span>
              )}
            </span>
            <input
              type="radio"
              name="plan"
              className="membership-card-radio-btn"
              checked={isSelected}
            />
          </div>
          <div className="plan-price">
            <div className="caradvise-plan-price">
              <span className="price">
                <span className={`plan-price-section ${isPriceAvailable && "plan-free-label"}`}>
                  {isPriceAvailable ? t("freeLabel") : numberFormatToCurrency(plan.price)}
                </span>
                {!isPriceAvailable && (
                  <span className="plan-per-month-section">{t("planPriceMonthLbl")}</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="benefits-list">
          <MembershipBenefitsList
            plan={plan}
            isSelected={isSelected}
            currentMembershipPlan={currentMembershipPlan}
          />
        </div>
        {/* {isAffiliation && selectedAccountTier && affilationData.length >= 1 && (
          <div className="membership-affiliation-section">
            <span>{t("membershipSponsoredByText", {membershipPlan:currentMembershipPlan.name})}</span>
            <Image src={affilationData[0].logo} />
          </div>
        )} */}
      </div>
    )
  }
}

export default withTranslation("membershipUpgradeCard")(MembershipUpgradeCard)
