import React, { Component } from "react"
import { connect } from "react-redux"
import { resetPassword } from "../actions/user"
import queryString from "query-string"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import Button from "../components/Button"
import { withTranslation } from "react-i18next"

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwords: {
        email: { name: "Email", value: null, invalid: false, validators: ["_isPresent"] },
        newPassword: {
          name: "New Password",
          value: null,
          invalid: false,
          validators: ["_isPresent"]
        },
        confirmNewPassword: {
          name: "Confirm New Password",
          value: null,
          invalid: false,
          validators: ["_isPasswordMatched"]
        }
      }
    }
    this._onFieldChange = this._onFieldChange.bind(this)
    this._setAndValidateField = this._setAndValidateField.bind(this)
  }

  _onPasswordChange(key, value) {
    this.setState({
      passwords: {
        ...this.state.passwords,
        [key]: this._setAndValidatePassword(key, value)
      }
    })
  }

  _setAndValidatePassword(key, value) {
    let password = this.state.passwords[key]
    let validators = password.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...password, value, invalid }
  }

  _validatePassword(callback) {
    let passwords = {}
    let firstInvalidKey = null

    Object.keys(this.state.passwords).forEach((key) => {
      let password = this.state.passwords[key]
      passwords[key] = password = this._setAndValidatePassword(key, password.value)
      if (!firstInvalidKey && password.invalid) firstInvalidKey = key
    })
    this.setState({ passwords }, () => {
      const { t } = this.props
      if (firstInvalidKey) window.alert(`${t("alertMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else {
        callback()
      }
    })
  }
  _isPasswordMatched(value) {
    return value === this.state.passwords.newPassword.value
  }
  async updatePassword(reset_token, auth_reset_token) {
    let response = await this.props.resetPassword({
      new_password: this.state.passwords.newPassword.value,
      new_password_confirmation: this.state.passwords.confirmNewPassword.value,
      reset_token,
      auth_reset_token
    })
    if (response.result) {
      this.showAlert()
      window.location.href = "/#/signIn"
    } else if (response.error) {
      this.showError(response.error)
    }
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss",
          callback: () => {
            window.location.href = "/#/password"
          }
        }
      })
    )
  }

  showAlert = () => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: t("passwordUpdatedSuccessMessage"),
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  renderUpdatePasswordForm(reset_token, auth_reset_token) {
    const { t } = this.props

    return (
      <div className="contentCenter">
        {auth_reset_token ? (
          <p className="introText">{t("createPasswordLbl")}</p>
        ) : (
          <p className="introText">{t("updateYourPasswordLbl")}</p>
        )}
        <p>
          <input
            data-qa="registration-reset-password-input-field"
            name="newPassword"
            type="password"
            className="inputFld"
            ref="password"
            placeholder={t("newPasswordPlaceholder")}
            onChange={(value) => this._onPasswordChange("newPassword", value.target.value)}
          />
          <br />
          <input
            data-qa="registration-reset-password-confirm-input-field"
            name="confirmNewPassword"
            type="password"
            className="inputFld"
            ref="password"
            placeholder={t("confirmNewPasswordPlaceholder")}
            onChange={(value) => this._onPasswordChange("confirmNewPassword", value.target.value)}
          />
          <br />
        </p>
        <Button
          text={t("signinText")}
          colorStyle="orange"
          onClick={() => this.updatePassword(reset_token, auth_reset_token)}
        />
      </div>
    )
  }

  _onFieldChange(key, value) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _isPresent(value) {
    return !!value
  }

  _validateFields(callback) {
    const { t } = this.props
    let fields = {}
    let firstInvalidKey = null

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey) window.alert(`${t("alertMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  render() {
    const { t } = this.props
    let reset_token = queryString.parse(this.props.location.search)
    reset_token = reset_token && reset_token.reset_password_token
    let auth_reset_token = queryString.parse(this.props.location.search)
    auth_reset_token = auth_reset_token && auth_reset_token.auth_reset_token
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        {this.renderUpdatePasswordForm(reset_token, auth_reset_token)}
        <a href="/#/signIn" className="btnBack">
          &lsaquo; {t("backLbl")}
        </a>
        <div style={{ marginBottom: 20 }}></div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let user = state.user || {}
  let token = ownProps.match.params.token
  return {
    isLoggedOut: !user.authentication_token,
    token,
    firstName: user.firstName,
    lastName: user.lastName,
    cellPhone: user.cellPhone,
    isLoading: false,
    error: user.error
  }
}

export default connect(mapStateToProps, { resetPassword })(
  withTranslation("resetPassword")(ResetPassword)
)
