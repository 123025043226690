import React, { Component } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { getJSON } from "../../shared_component/utils/fetch"

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import moment from "moment"
import Loading from "react-loading-overlay"

import ItemSelector from "./ItemSelector"
import { constants } from "caradvise_shared_components"
import { isEbayCart } from "../helpers/affiliationHelpers"
import { datePickerLocale } from "../helpers/translationHelpers"
import { withTranslation } from "react-i18next"
import { getNewTiresServiceFromServices } from "../helpers/orderServiceHelpers"
import { isDayDisabled } from "../../shared_component/utils/dateHelpers"

const {
  orders: { WALK_IN }
} = constants
/*
TODO move up time to value so that the value can be sent in as a parameter
TODO -- for now keep time here
TODO move fetchTimeSlots to action and out of component
*/
class ShopAppointmentPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null,
      timeSlots: null,
      time: null,
      dateTimesHash: {},
      loading: false,
      loadingMessage: ""
    }
    this.onDateChange = this.onDateChange.bind(this)
    this.setTimeSlots = this.setTimeSlots.bind(this)
    this.fetchTimeSlots = this.fetchTimeSlots.bind(this)
    this.fetchTimeSlotsFromServer = this.fetchTimeSlotsFromServer.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  async setLoadingState(message) {
    this.setState({ loading: true, loadingMessage: message })
  }
  async clearLoadingState() {
    this.setState({ loading: false, loadingMessage: "" })
  }

  hasNewTiresWithTireConnect(cart) {
    return (
      cart.shop.supports_tire_selection && !!getNewTiresServiceFromServices(cart.order_services)
    )
  }
  //TODO: When refactoring is possible, let's move the logic around determining walk-ins in the backend since we already
  // have logic to filter out dates/times in the base scheduler in the backend.

  enableWalkIn = (date) => {
    const { user } = this.props
    const momentDate = moment(date)
    var isSunday = date.getDay(0)
    const cart = this.fetchOrderLikeObject()
    const isEbay = isEbayCart(user)
    // check for scheduling blackout dates (NOTE: month is zero-indexed)
    const isBlackoutDate = [
      { month: 11, day: 25 } // Christmas
    ].find((bod) => bod.month === momentDate.month() && bod.day === momentDate.date())

    return (
      !this.props.disableWalkIns &&
      isSunday &&
      !isEbay &&
      !isBlackoutDate &&
      !this.hasNewTiresWithTireConnect(cart)
    )
  }

  async setTimeSlots(date, times) {
    const { t } = this.props
    const orderLikeObject = this.fetchOrderLikeObject()
    const walkInsOnly = (orderLikeObject.shop.company || {}).walk_ins_only
    const walkIn = { formatted_time: t("walkInText"), time: moment(date).set("hour", 3) }

    if (walkInsOnly) {
      await this.setState({ timeSlots: [walkIn], time: null })
    } else if (times) {
      let timesData = times.map((tm) => {
        let hour_min = tm.split(":")
        let hour = parseInt(hour_min[0])
        let min = parseInt(hour_min[1])
        const dt = moment(date)

        dt.add(hour, "hours")
        dt.add(min, "minutes")
        if (this.props.i18n.language === "en") {
          let ampm = "AM"
          if (hour === 12) {
            ampm = "PM"
          } else if (hour > 12) {
            hour = hour - 12
            ampm = "PM"
          } else if (hour === 0) {
            hour = 12
          }
          return { formatted_time: `${hour}:${hour_min[1]} ${ampm}`, time: dt }
        } else {
          return { formatted_time: `${hour}:${hour_min[1]}`, time: dt }
        }
      })

      if (this.enableWalkIn(date)) timesData.unshift(walkIn)
      // TODO:  Here I want to set the cached times as well.

      await this.setState({ timeSlots: timesData, time: null })
    } else {
      const timeSlots = this.enableWalkIn(date) ? [walkIn] : null
      await this.setState({ timeSlots: timeSlots, time: null })
    }
  }

  fetchOrderLikeObject() {
    let { user, orderLikeObject } = this.props
    return (
      orderLikeObject ||
      (user &&
        user.active_carts &&
        user.active_carts.find((c) => c.vehicle_id === user.activeVehicleId))
    )
  }

  async fetchTimeSlotsFromServer(date) {
    let { user } = this.props
    const date_string = moment(date).format("YYYYMMDD")
    // here we want to load in the times, call to load the times
    //TODO: Need to pass in the shop id, authentication token and cart id
    const authentication_token = this.props.user.authentication_token

    const orderLikeObject = this.fetchOrderLikeObject(user)
    const shopId =
      orderLikeObject && orderLikeObject.shop ? orderLikeObject.shop.id : orderLikeObject.shop_id

    if (shopId === null) {
      return
    }

    let orderLikeIdParam

    if (orderLikeObject.status !== undefined) orderLikeIdParam = `&order_id=${orderLikeObject.id}`
    else orderLikeIdParam = `&cart_id=${orderLikeObject.id}`

    let response = await getJSON(
      `/api/v4/shops/${shopId}/schedule?date=${date_string}&days=1${orderLikeIdParam}`,
      {},
      { Authorization: authentication_token }
    )

    let data = response.result && response.result[0] ? response.result[0] : null
    const dateTimesKey = moment(date).format("YYYY-MM-DD")
    let dateTimesHash = { ...this.state.dateTimesHash }
    dateTimesHash[dateTimesKey] = data
    await this.setState({ dateTimesHash: dateTimesHash })

    return data
  }

  async fetchTimeSlots(date) {
    const { t } = this.props
    await this.setLoadingState(t("RetrievingShopTimeLoadingMessage"))
    //Check the has first
    const dateTimesKey = moment(date).format("YYYY-MM-DD")
    let data = this.state.dateTimesHash[dateTimesKey] || (await this.fetchTimeSlotsFromServer(date))

    await this.clearLoadingState()

    return data && data.times ? data.times : null
  }

  async onDateChange(date) {
    await this.setState({ date: date })

    let time_data = await this.fetchTimeSlots(date)

    await this.setTimeSlots(date, time_data)
  }

  async onSelect(value) {
    const { t } = this.props
    await this.setState({ time: value })
    await this.setLoadingState(t("updatingAppointmentTimeLoadingMessage"))
    if (this.props.onSelect) {
      await this.props.onSelect(value)
    }
    await this.clearLoadingState()
  }

  render() {
    const { user, t } = this.props
    const date_str = this.state.date ? moment(this.state.date).format("dddd MMMM Do YYYY") : ""
    const emptyMessage =
      this.state.date && !this.state.loading
        ? `${t("noTimeAvailableText")} ${date_str}`
        : this.state.loading
        ? t("loadingMessage")
        : t("selectDateFirstLbl")

    const cart = this.fetchOrderLikeObject()
    let minDate = cart && cart.valid_at ? moment(cart.valid_at) : moment()
    if (minDate < moment()) minDate = moment()
    return (
      <div className="shop-appointment-picker-panel">
        <Loading active={this.state.loading} spinner={true} text={this.state.loadingMessage}>
          <div className="heading">{t("selectDateLbl")}</div>
          <div className="calendar">
            <DatePicker
              inline
              selected={this.state.date}
              onChange={this.onDateChange.bind(this)}
              minDate={minDate._d}
              locale={datePickerLocale()}
              filterDate={isDayDisabled}
            />
          </div>
          <div className="heading">{t("selectTimeLbl")}</div>

          <div className="scrollable-in-mobile">
            <ItemSelector
              items={this.state.timeSlots}
              labelField="formatted_time"
              valueField="time"
              emptyMessage={emptyMessage}
              value={this.state.time}
              onSelect={this.onSelect}
            />
          </div>
        </Loading>
      </div>
    )
  }
}

ShopAppointmentPicker.propTypes = {
  user: PropTypes.object.isRequired,
  orderLikeObject: PropTypes.object,
  onSelect: PropTypes.func
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user
  }
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation("shopAppointmentPicker")(ShopAppointmentPicker))
