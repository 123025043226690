import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import Affirm_Logo from "../../images/Affirm_Logo.svg"
import { Image } from "semantic-ui-react"
import LockIcon from "../../images/LockIcon.svg"
import AddCardIcon from "../../images/AddCardIcon.svg"
import DeleteIcon from "../../images/delete_icon_vector.svg"
import { connect } from "react-redux"
import {
  createPaymentMethod,
  getBraintreePaymentToken,
  setDefaultPaymentCard,
  removePaymentCard,
  getPaymentCards
} from "../actions/paymentMethods"
import ShimmerSquar from "./ShimmerSquar"
import { TIRES, AFFIRM, PAYPAL, BRAINTREE } from "../constants/tires"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import CrossIcon from "../../images/btn-delete.png"
import { prepareCheckoutObj, prepareTireCheckoutObj } from "../helpers/affirmHelper"
import { NoChargeBanner } from "../../shared_component/elements/NoChargeBanner"
import { PAYMENT_METHODS, USD_CURRENCY } from "../constants/application"
import ReactSVG from "react-svg"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import RewardMilesPayment from "../../v2/components/Rewards/RewardMilesPayment"
import { isEmpty } from "lodash"
import { getUpdatedCart } from "../helpers/orderHelpers"
import LoadingComponent from "./LoadingComponent"
import CreditCardForm from "./CreditCardForm"
import { setupPaypalBraintree } from "../../shared_component/utils/setupBraintree"

export function TirePaymentLoading({ parentClassName, showBtnShimmer, addNewCardBtn }) {
  const shimmerCards = Array.from({ length: 4 }, (_, index) => {
    return <div className="paypal-btn-loading cards-shimmer-effect"></div>
  })

  return (
    <div className="shimmer-parent-width">
      <div className={`${parentClassName}`}>{shimmerCards}</div>
      {showBtnShimmer ? (
        <>
          <br />
          <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
          <br />
          <div className="cards-parent row">
            <div className="paypal-btn-loading btns-shimmer-effect"></div>
            <div className="paypal-btn-loading btns-shimmer-effect"></div>
          </div>
          <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
        </>
      ) : null}
      {addNewCardBtn ? (
        <ShimmerSquar containerClass="h-[25px]" height={50} className="w-2 add-new-card-shimmer" />
      ) : null}
    </div>
  )
}

export function AddNewCardShimmer() {
  return (
    <div className="add-card-parent-shimmer">
      <div className="label-shimmer">
        <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2 span-shimmer" />
        <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2 span-shimmer" />
      </div>
      <ShimmerSquar containerClass="h-[25px]" height={40} className="w-2" />
      <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
      <ShimmerSquar containerClass="h-[25px]" height={40} className="w-2" />
      <div className="security-parent-shimmer">
        <ShimmerSquar
          containerClass="h-[25px]"
          height={20}
          className="w-2 security-input-shimmer"
        />
        <ShimmerSquar
          containerClass="h-[25px]"
          height={20}
          className="w-2 security-input-shimmer"
        />
      </div>
      <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
      <ShimmerSquar containerClass="h-[25px]" height={60} className="w-2" />
    </div>
  )
}

function TirePayment({
  t,
  has_cc_info_saved,
  user,
  braintreePaymentMethods,
  createPaymentMethod,
  setPaymentCard,
  setDefaultPaymentCard,
  getPaymentCards,
  removePaymentCard,
  module,
  bookingDetails,
  totalAmountDue,
  shopDetails,
  setPaymentMethod,
  paymentMethod,
  showNoChargeBanner,
  navOpen,
  anyOrderServiceLackPrice
}) {
  const [isCardAvailable, setIsCardAvailable] = useState(false)
  const [openAddNewModal, setOpenAddNewModal] = useState(false)
  const [headerText, setHeaderText] = useState()
  const [selectedCard, setSelectedCard] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [openEditCardsModal, setOpenEditCardsModal] = useState(false)
  const [affirmCheckoutLoading, setAffirmCheckoutLoading] = useState(false)
  const [isPaypalLoading, setIsPaypalLoading] = useState(true)
  const [paypalMethodElement, setPaypalMethodElement] = useState("")
  const [paypalNonce, setPaypalNonce] = useState("")
  const [previousLength, setPreviousLength] = useState(
    braintreePaymentMethods && braintreePaymentMethods.length
  )
  const [isSavingPayment, setIsSavingPayment] = useState(false)

  const handleClose = (e) => {
    setOpenAddNewModal(false)
  }

  const handleOpen = (e) => {
    handleCloseEditCardsModal()
    setOpenAddNewModal(true)
  }

  useEffect(() => {
    setSelectedCard()
    getSavedCardDetails()
    if (has_cc_info_saved && braintreePaymentMethods && braintreePaymentMethods.length > 0) {
      setIsCardAvailable(true)
    }
  }, [])

  const handlePaypalMethodBorder = (paymentType = PAYPAL) => {
    const methodsContainer = document.querySelector(
      '[data-braintree-id="methods-container"] > div:first-child'
    )
    if (paymentType === PAYPAL && methodsContainer) {
      methodsContainer.classList.add("custom-paypal-border")
    } else if (methodsContainer && paymentType === BRAINTREE) {
      methodsContainer.classList.remove("custom-paypal-border")
    }
  }

  useEffect(() => {
    if (paypalMethodElement && module === TIRES) {
      handlePaypalMethodBorder()
      paypalMethodElement.addEventListener("click", () => {
        handlePaypalMethodBorder()
        setPaymentMethod(PAYPAL)
        setPaymentCard(paypalNonce)
        setSelectedCard()
      })
    }
  }, [paypalMethodElement])

  useEffect(() => {
    if (braintreePaymentMethods && braintreePaymentMethods.length !== previousLength) {
      setSelectedCard()
      getSavedCardDetails()
      if (has_cc_info_saved && braintreePaymentMethods && braintreePaymentMethods.length > 0) {
        setIsCardAvailable(true)
      }
      setPreviousLength(braintreePaymentMethods.length)
    }
  }, [braintreePaymentMethods, has_cc_info_saved, previousLength])

  const onCardChange = (e, card) => {
    if (module === TIRES) {
      handlePaypalMethodBorder(BRAINTREE)
    }
    setSelectedCard(card.token)
    setPaymentCard(module === TIRES ? card.token : card)
    setPaymentMethod(BRAINTREE)
    trackEvent("select-payment-method", {
      eventSourceName: "Checkout_2.0"
    })
  }

  const handleDefaultCard = async (braintreePaymentMethods) => {
    const defaultSelected =
      braintreePaymentMethods.filter((card) => card.isdefault).length > 0
        ? braintreePaymentMethods.filter((card) => card.isdefault)[0]
        : braintreePaymentMethods[0]

    setSelectedCard(defaultSelected && defaultSelected.token)
    setPaymentCard(module === TIRES ? defaultSelected && defaultSelected.token : defaultSelected)
    setPaymentMethod(BRAINTREE)
  }

  const getSavedCardDetails = async () => {
    if (has_cc_info_saved && braintreePaymentMethods && braintreePaymentMethods.length > 0) {
      let defaultSelected =
        braintreePaymentMethods.filter((i) => i.isdefault).length > 0
          ? braintreePaymentMethods.filter((i) => i.isdefault)[0].token
          : braintreePaymentMethods[0].token

      let selected =
        braintreePaymentMethods.filter((i) => i.isdefault).length > 0
          ? braintreePaymentMethods.filter((i) => i.isdefault)[0]
          : braintreePaymentMethods[0]
      setSelectedCard(defaultSelected)
      await setPaymentMethod(BRAINTREE)
      await setPaymentCard(module === TIRES ? defaultSelected : selected)
      setHeaderText(braintreePaymentMethods.length + t("saved_card"))
    } else {
      setHeaderText(t("Pay_by_card"))
      setPaymentCard()
      const response = await getPaymentCards(user)
      if (response.result && response.result.length > 0) {
        let cardToken =
          response.result.filter((i) => i.isdefault).length > 0
            ? response.result.filter((i) => i.isdefault)[0].token
            : response.result[0].token

        let card =
          response.result.filter((i) => i.isdefault).length > 0
            ? response.result.filter((i) => i.isdefault)[0]
            : response.result[0]
        setPaymentMethod(BRAINTREE)
        setPaymentCard(module === TIRES ? cardToken : card)
      }
    }
  }

  useEffect(() => {
    if (module === TIRES && totalAmountDue) setupData()
    else if (module !== TIRES) setupData()
  }, [totalAmountDue])

  const handleRemoveCard = async (e, card) => {
    setIsLoading(true)
    const response = await removePaymentCard(user, card.token)
    if (response && response.result) {
      store.dispatch(
        Notifications.success({
          title: t("Card_removed"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("Card_deletion_failed"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    setIsLoading(false)
  }

  const handleSetDefault = async (card) => {
    if (card.isdefault) return false
    setIsLoading(true)
    const response = await setDefaultPaymentCard(user, card.token)
    if (response && response.result) {
      setSelectedCard(card.token)
      store.dispatch(
        Notifications.success({
          title: t("Default_card_updated"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: t("Default_card_update_failed"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    setIsLoading(false)
  }

  const handleOpenEditCardsModal = () => {
    setOpenEditCardsModal(true)
  }

  const handleCloseEditCardsModal = () => {
    setOpenEditCardsModal(false)
  }

  const handleAffirmPayment = async (e) => {
    if (user && user.generic) {
      return
    }
    setAffirmCheckoutLoading(true)
    let checkoutObj =
      module === TIRES
        ? prepareTireCheckoutObj(user, bookingDetails, totalAmountDue, shopDetails)
        : prepareCheckoutObj(user, bookingDetails)
    window.affirm.checkout(checkoutObj)

    await window.affirm.checkout.open({
      onFail: (a) => onFailedCheckout(a),
      onSuccess: (a) => onSuccessCheckout(a)
    })
    if (e) e.preventDefault()
  }

  const onFailedCheckout = (obj) => {
    window.affirm.ui.refresh()
    getSavedCardDetails()
    setAffirmCheckoutLoading(false)
    setupData()
  }

  const onSuccessCheckout = (obj) => {
    let affirmCheckoutToken = obj["checkout_token"]
    if (affirmCheckoutToken) {
      setPaymentCard(affirmCheckoutToken)
      setPaymentMethod(AFFIRM)
      setSelectedCard()
      setupData()
    }
    window.affirm.ui.refresh()
    setAffirmCheckoutLoading(false)
  }

  const setupData = async () => {
    setIsPaypalLoading(true)
    const paypalCheckoutInstance = await setupPaypalBraintree()
    paypalCheckoutInstance
      .loadPayPalSDK({
        vault: true,
        intent: "tokenize",
        "disable-funding": "card"
      })
      .then(() => {
        window.paypal
          .Buttons({
            style: {
              disableMaxWidth: true,
              align: "center",
              height: 50
            },
            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                flow: "vault",
                intent: "tokenize"
              })
            },
            onApprove: (data) => {
              return paypalCheckoutInstance.tokenizePayment(data, async (err, payload) => {
                setIsLoading(true)
                await createPaymentMethod(user, payload.nonce, PAYMENT_METHODS.PAYPAL)
                setIsLoading(false)
              })
            },
            onError: function (err) {
              console.error("PayPal error", err)
            }
          })
          .render("#paypal-custom-btn")
          .then(() => {
            setIsPaypalLoading(false)
          })
      })
  }

  let orderPrice, orderCurrency, order_previous_payments, remaining_payment

  if (bookingDetails) {
    orderPrice = bookingDetails.estimated_total
    orderCurrency = bookingDetails.currency
    order_previous_payments = 0.0
  }

  remaining_payment = orderPrice - order_previous_payments

  const showAffirm =
    remaining_payment === 0.0 ||
    (remaining_payment !== 0.0 && remaining_payment < 50) ||
    orderCurrency !== USD_CURRENCY ||
    (bookingDetails.any_order_service_lacks_price &&
      bookingDetails.any_order_service_lacks_price) ||
    bookingDetails.estimated_total < 50

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const isOrderExists =
    user.active_orders && user.active_orders.length && user.active_orders.length > 0
      ? user.active_orders.find((order) => {
          if (+order.vehicle_id === +user.activeVehicleId) {
            return order
          }
        })
      : {}

  return (
    <>
      <div className="pay-card">
        <div className="booking-heading">
          {!isCardAvailable ? t("Complete_your_booking") : t("Select_payment_method")}
        </div>
        {isCardAvailable && showNoChargeBanner && module !== TIRES ? <NoChargeBanner /> : null}
        {isEmpty(isOrderExists) &&
          module !== TIRES &&
          isRewardsFeature &&
          !user.generic &&
          !anyOrderServiceLackPrice && <RewardMilesPayment />}
        <div className="outerDiv">
          <div className="leftDiv">
            {headerText}
            {braintreePaymentMethods && braintreePaymentMethods.length > 0 ? (
              <p className="edit-card-btn" onClick={handleOpenEditCardsModal}>
                {t("Edit_cards")}
              </p>
            ) : null}
          </div>
        </div>
        {affirmCheckoutLoading ? (
          <TirePaymentLoading
            parentClassName="cards-parent"
            showBtnShimmer={true}
            addNewCardBtn={false}
          />
        ) : (
          <div className="payment-details">
            {!isCardAvailable ? (
              <CreditCardForm handleDefaultCard={handleDefaultCard} popup={false} />
            ) : !isLoading ? (
              <div className="cards-parent">
                {braintreePaymentMethods && braintreePaymentMethods.length > 0
                  ? braintreePaymentMethods.map((card) => {
                      const logo =
                        card.type === PAYMENT_METHODS.CREDIT_CARD
                          ? card.card_type
                          : PAYMENT_METHODS.PAYPAL
                      return (
                        <button
                          className={`card-btn card-btn-selected ${
                            selectedCard === card.token ? "default-card" : ""
                          } ${navOpen ? "custom-media" : ""}`}
                          onClick={(e) => onCardChange(e, card)}
                        >
                          <div className="card-details">
                            <ReactSVG src={`images/paymentLogo/${logo.replace(/\s+/g, "")}.svg`} />
                            <div>
                              {card.type === PAYMENT_METHODS.CREDIT_CARD ? (
                                card.cardholder_name ? (
                                  <p className="card-details-name"> {card.cardholder_name} </p>
                                ) : (
                                  <span>
                                    <p className="dot-input">{card.last_4}</p>&nbsp;
                                    {card.last_4}
                                  </span>
                                )
                              ) : card.email ? (
                                <p className="card-details-name"> {card.email} </p>
                              ) : (
                                "--"
                              )}
                              {card.cardholder_name && (
                                <span>
                                  <p className="dot-input">{card.last_4}</p>&nbsp;
                                  {card.last_4}
                                </span>
                              )}
                            </div>
                          </div>
                          <input
                            type="radio"
                            name=""
                            id={card.token}
                            checked={selectedCard === card.token}
                          />
                        </button>
                      )
                    })
                  : null}
              </div>
            ) : (
              <TirePaymentLoading
                parentClassName="cards-parent"
                showBtnShimmer={false}
                addNewCardBtn={false}
              />
            )}
            <p className="form-text">{t("Choose_payment_method")}</p>
            {isSavingPayment && <LoadingComponent />}
            <div className={`btn-parent row ${isSavingPayment ? "display-none" : ""}`}>
              <div
                style={
                  remaining_payment == 0.0 ||
                  (bookingDetails && bookingDetails.any_order_service_lacks_price)
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
                id="paypal-custom-btn"
                className={`${
                  isPaypalLoading ? "display-none" : navOpen ? "payment-media-width" : ""
                }`}
              ></div>
              {isPaypalLoading ? <button className="paypal-btn-loading btn-column"></button> : null}
              {isPaypalLoading ? (
                <button className="paypal-btn-loading btn-column"></button>
              ) : (
                <div
                  style={
                    showAffirm && module !== TIRES ? { pointerEvents: "none", opacity: "0.4" } : {}
                  }
                  className={`affirm-parent ${
                    paymentMethod === AFFIRM ? "affirm-active-payment-method" : ""
                  } ${navOpen ? "payment-media-width" : ""} ${
                    user && user.generic ? "black-white-container" : ""
                  }`}
                  onClick={handleAffirmPayment}
                >
                  <button disabled={user && user.generic} className="affirm-btn btn-column">
                    <img src={Affirm_Logo} />
                    &nbsp;{t("Monthy_payments")}
                  </button>
                  <label className="affirm-label">{t("Starting_at")}</label>
                </div>
              )}
            </div>
            <span>
              <img src={LockIcon} />
              {t("Payment_info_protected")}
            </span>
          </div>
        )}
      </div>
      {openAddNewModal ? (
        <CreditCardForm handleDefaultCard={handleDefaultCard} handleClose={handleClose} />
      ) : null}
      {openEditCardsModal && braintreePaymentMethods && braintreePaymentMethods.length > 0 ? (
        <div
          className="edit-card-modal-backdrop"
          onClick={() => {
            handleCloseEditCardsModal()
          }}
        >
          <div
            className="edit-card-modal-content"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className="edit-card-modal-header">
              <h2>{t("Saved_Cards")}</h2>
              <Image src={CrossIcon} onClick={handleCloseEditCardsModal} />
            </div>
            <hr />
            {!isLoading ? (
              <div className="edit-cards-parent row">
                {braintreePaymentMethods && braintreePaymentMethods.length > 0
                  ? braintreePaymentMethods.map((card) => {
                      const logo =
                        card.type === PAYMENT_METHODS.CREDIT_CARD
                          ? card.card_type
                          : PAYMENT_METHODS.PAYPAL
                      return (
                        <button className="card-btn">
                          <ReactSVG src={`images/paymentLogo/${logo.replace(/\s+/g, "")}.svg`} />
                          <div className="edit-cards-description">
                            <div className="description-section">
                              <p
                                className={`${
                                  card.type === PAYMENT_METHODS.CREDIT_CARD ? "" : "card-email"
                                }`}
                              >
                                {card.type === PAYMENT_METHODS.CREDIT_CARD
                                  ? `${card.cardholder_name ? card.cardholder_name : ""}`
                                  : `${card.email}`}
                              </p>
                              &nbsp;
                              <div className="card-last-digit">
                                <p className="dot-input">{card.last_4}</p> &nbsp;
                                {card.last_4}
                              </div>
                            </div>
                            <div className="checkbox-label">
                              <input
                                type="radio"
                                id={card.token}
                                checked={card.isdefault ? true : false}
                                onClick={(e) => handleSetDefault(card)}
                              />
                              <label>{t("Set_default")}</label>
                            </div>
                          </div>
                          {!card.isdefault && (
                            <Image
                              src={DeleteIcon}
                              className="remove-card-btn"
                              onClick={(e) => handleRemoveCard(e, card)}
                            />
                          )}
                        </button>
                      )
                    })
                  : null}
                <button className="card-btn new-card-btn" onClick={handleOpen}>
                  <img src={AddCardIcon} className="add-new-img" />
                  {t("Add_new_card")}
                </button>
              </div>
            ) : (
              <TirePaymentLoading
                parentClassName="edit-cards-parent"
                showBtnShimmer={false}
                addNewCardBtn={true}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

function mapStateToProps(state, props) {
  let user = state.user
  let has_cc_info_saved = user.has_cc_info_saved

  let activeVehicle =
    user.vehicles &&
    user.vehicles.length &&
    user.vehicles.length > 0 &&
    user.vehicles.find((v) => v.id == user.activeVehicleId)
  let braintreePaymentMethods = state.paymentMethods.braintreePaymentMethods
  let order =
    activeVehicle &&
    activeVehicle.id &&
    ((user && user.active_orders) || []).find((o) => o.vehicle_id === activeVehicle.id)

  let cart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
  }

  return {
    user: user,
    has_cc_info_saved: has_cc_info_saved,
    braintreePaymentMethods: braintreePaymentMethods,
    navOpen: state && state.leftnav && state.leftnav.navOpen,
    anyOrderServiceLackPrice: cart && cart.any_order_service_lacks_price
  }
}

export default connect(mapStateToProps, {
  getBraintreePaymentToken,
  createPaymentMethod,
  removePaymentCard,
  setDefaultPaymentCard,
  getPaymentCards
})(withTranslation("tirePayment")(TirePayment))
