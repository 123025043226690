import { MILLIONS_CODE, THOUSANDS_CODE } from "../../v1/constants/tires"

export function formatPrice(price) {
  // eg.98 -> 98.00, 98.456 -> 98.45
  return parseFloat(price).toFixed(2)
}

export function formatPriceNumber(number) {
  if (number >= 1000 && number < 1000000) {
    // Format as "1k" for thousands
    return (
      (number / 1000).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
      }) + THOUSANDS_CODE
    )
  } else if (number >= 1000000) {
    // Format as "1M" for millions
    return (
      (number / 1000000).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
      }) + MILLIONS_CODE
    )
  } else {
    // Leave as is for numbers less than 1000
    return number.toLocaleString()
  }
}

export const getMilesFromDollar = (milesPerDollar, redeemDiscount) => {
  return +milesPerDollar * +redeemDiscount
}
