import React, { Component } from "react"
import Button from "../../components/Button.js"
import { connect } from "react-redux"
import { constants } from "caradvise_shared_components"
import ReviewTable from "../Cart/ReviewTable"
import { isApproved } from "../../helpers/orderHelpers"
import MiniLoader from "../MiniLoader"
import trustGuarantee from "../../../images/trust-guarantee.png"
import { withTranslation, Trans } from "react-i18next"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"

const {
  orders: { APPROVAL }
} = constants

class ApprovalsFollowup extends Component {
  goToDashboard = () => {
    const { t } = this.props
    return (
      <Button
        style={{ height: "20px", width: "200px", marginTop: "30px", marginBottom: "30px" }}
        colorStyle="orange"
        styleText={{ color: "white" }}
        text={t("gotoDashboardText")}
        linkTo={"/dashboard"}
      />
    )
  }

  render() {
    const { order, t } = this.props

    return (
      <div>
        <div className="approvals-followup">
          {order == null && this.goToDashboard()}
          <div className="approvals-followup-section">
            {order != null && (
              <React.Fragment>
                <div className="approvals-followup-header-section" size="medium">
                  {t("youSavedDesc")}
                  <div className="approvals-followup-big-savings">
                    {order.total_savings >= 0 ? (
                      `${numberFormatToCurrency(Number(order.total_savings).toFixed(2))}`
                    ) : (
                      <MiniLoader />
                    )}
                  </div>
                  {t("caradviseFleetDiscountDesc")}
                </div>
                {this.goToDashboard()}
                <div className="approvals-followup-cta-text">
                  <div>{t("approvalSentDesc")}</div>
                  <div>{t("workWillBeginDesc")}</div>
                </div>
                <div className="approvals-followup-approved-work">
                  {t("orderSummaryLbl")}
                  <hr />
                </div>
                <div className="approvals-followup-review-table-wrapper">
                  {order.total_savings && <ReviewTable order={order} />}
                </div>
                <div className="approvals-followup-trust-guarantee">
                  <img className="trust-guarantee-img" src={trustGuarantee} alt="" />
                  <div>
                    {t("satisfiedServiceDesc")}
                    {` `}
                    <span style={{ fontWeight: "bold" }} className="caradvise-orange">
                      <Trans>{t("callUsText")}</Trans>
                    </span>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}

  const vehicleId = user.activeVehicleId

  const activeOrders = user.active_orders || []

  const order = activeOrders.find((order) => order.vehicle_id === vehicleId && isApproved(order))
  return {
    order
  }
}

export default connect(mapStateToProps)(withTranslation("approvalsFollowup")(ApprovalsFollowup))
