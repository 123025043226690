import React, { Component } from "react"
import spinner from "../../images/spinner.gif"
import btnCheck from "../../images/checkbox-on.png"
import { connect } from "react-redux"
import { postJSON } from "../../shared_component/utils/fetch"
import Button from "../components/Button"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { withTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import { selectLanguage } from "../helpers/selectLanguage"
import { EMAIL_REGEX } from "../../shared_component/utils/stringHelpers"
import { setupSplitFlags } from "../components/Config/SplitClient"
import { getSubdomain } from "../components/Config/StylesheetInjector"
import { APP_NAME } from "../../shared_component/constants/common"

//PICK UP WITH CREATING THIS ROUTE IN THE API

const RESET_PASSWORD_URL = "/api/v4/customers/reset_password"
const recaptchaRef = React.createRef()

class Password extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSpinner: false,
      fields: {
        email: {
          name: "Email",
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        }
      },
      isLoading: false
    }

    this.resetPasswordEmail = this.resetPasswordEmail.bind(this)
  }
  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain(), app_name: APP_NAME })
  }
  componentWillMount() {}

  onRecaptchaChange(value) {}

  async resetPasswordEmail() {
    const { t } = this.props
    if (
      this.state.isReCaptchaflagEnabled &&
      this.state.isReCaptchaflagEnabled &&
      window.grecaptcha.getResponse().length == 0
    ) {
      alert(t("recaptchaCheck"))
      return false
    } else {
      this.setState({
        showSpinner: true
      })
      let data = {
        email: this.state.fields.email.value
      }
      if (this.state.fields.email.value.length === 0) {
        this.showError(`${t("errorMessage")} ${this.state.fields.email.name}`)
        this.setState({
          showSpinner: false
        })
      } else if (!this.state.fields.email.invalid) {
        let response = await postJSON(RESET_PASSWORD_URL, data)
        this.setState({
          showSpinner: false
        })

        if (response.error && response.error !== "Unexpected end of JSON input") {
          this.showError(response.error)
        } else {
          this.showAlert(t("resetPasswordMessage"))
        }
      } else {
        this.showError(t("invalidEmail"))
      }
    }
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss",
          callback: () => {
            window.location.href = "/#/"
          }
        }
      })
    )
  }

  render() {
    if (!!this.props.isLoggedIn) {
      window.location.href = "/dashboard"
    }
    const { t } = this.props

    return (
      <div className="container" style={{ width: "100%" }}>
        <div className="contentCenter">
          <p className="introText">{t("forgotPasswordLbl")}</p>
          <div className="passwordSpinner">
            {this.state.showSpinner ? <img src={spinner} className="arrowRange" alt="" /> : null}
          </div>
          <p>
            <input
              data-qa="registration-password-input-field"
              className="inputFld"
              ref="email"
              placeholder={t("enterEmailPlaceholder")}
              onChange={(value) => this._onFieldChange("email", value.target.value)}
            />
            <br />
            {this.state.isReCaptchaflagEnabled && this.state.isReCaptchaflagEnabled && (
              <ReCAPTCHA
                ref={recaptchaRef}
                hl={selectLanguage()}
                className="g-recaptcha"
                sitekey="6LeUpj4hAAAAABS0te5UEjImtPjlKY33ggB01jBe"
                onChange={this.onRecaptchaChange}
              />
            )}
            <Button
              text={t("submitBtnText")}
              colorStyle="orange"
              onClick={this.resetPasswordEmail}
            />
          </p>
        </div>
      </div>
    )
  }
  _isPresent(value) {
    return !!value
  }
  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }
  _onFieldChange(key, value) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }
  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (firstInvalidKey) window.alert(`${t("errorMessage")} ${firstInvalidKey}`)
      else if (callback) callback()
    })
  }
}

function mapStateToProps(state) {
  let user = state.user || {}

  return {
    user,
    error: user.error
  }
}

export default connect(mapStateToProps, {})(withTranslation("password")(Password))
