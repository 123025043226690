import React from "react"
import { useTranslation } from "react-i18next"
import {
  SUPPORT_CARADVISE_EMAIL,
  SUPPORT_CARADVISE_NUMBER
} from "../../../v1/constants/application"
import { Image } from "semantic-ui-react"
import EmailIcon from "../../../images/icon_email.svg"
import CallIcon from "../../../images/icon_call.svg"
import WarningQMarkIcon from "../../../images/icon_warning_qmark.svg"
import { formatPhoneNumber } from "../../../v1/helpers/stringHelpers"

export const CancellationPolicy = () => {
  const { t } = useTranslation("orderDetails")

  return (
    <div className="cancellation-policy-container">
      {/* <div className="cancellation-policy-header">{t("cancelPolicy")}</div> */}
      <div className="cancel-text">{t("cancelPolicyText")}</div>
      <div className="contact-us-details">
        <Image src={EmailIcon} />
        <div className="text">{SUPPORT_CARADVISE_EMAIL}</div>
      </div>
      <div className="contact-us-details">
        <Image src={CallIcon} />
        <div className="text">{formatPhoneNumber(SUPPORT_CARADVISE_NUMBER)}</div>
      </div>
      <div className="contact-us-details">
        <Image src={WarningQMarkIcon} />
        <a className="link-text" href="https://caradvise.com/faq/" target="_blank">
          {t("moreInfoText")}
        </a>
      </div>
    </div>
  )
}
