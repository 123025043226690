import React from "react"
import { useTranslation } from "react-i18next"
import MileageOdometerLogo from "../../../images/rewards/mileage-odometer.svg"
import ReactSVG from "react-svg"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const EstimateEarnMiles = ({ estimateEarnMiles, className }) => {
  const { t } = useTranslation("reviewOrder")

  return (
    <div className={`estimate-earn-miles ${className}`}>
      <ReactSVG src={MileageOdometerLogo} />
      <p className="earn-miles">
        {t("youWillEarn")} {numberFormater(estimateEarnMiles)} {t("miles")}
      </p>
    </div>
  )
}

export default EstimateEarnMiles
