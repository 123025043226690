import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { cancelOrder } from "../../actions/orders"
import ModalDialog from "../ModalDialog"
import { withTranslation } from "react-i18next"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { getVehicleName } from "../../helpers/vehicleHelpers"
import { getShopAddress, getShopName } from "../../helpers/shopHelpers"
import { getCurrencyCode, numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { getServiceNameForMap } from "../../helpers/serviceHelper"
import { isWalkIn } from "../../helpers/orderHelpers"
import {
  getBookingAppointmentDateTime,
  getBookingAppointmentDay
} from "../../helpers/segmentHelper"
import i18n from "../../../shared_component/utils/i18n"
import { orderCancelledEvents } from "../../../shared_component/utils/googleAnalyticsHelpers"

class CancelLink extends React.Component {
  cancelAppointment = async () => {
    const { cancelOrder, history, user, order, activeVehicle } = this.props
    if (!user.activeVehicleId || !user.authentication_token) return

    const response = await cancelOrder(order.id)
    if (response.success === true) {
      await orderCancelledEvents({ response, activeVehicle })
      trackEvent("order-cancelled", {
        bookingAppointmentDay:
          response.result &&
          response.result.appointment_datetime &&
          getBookingAppointmentDay(response.result.appointment_datetime),
        bookingAppointmentDate:
          response.result &&
          response.result.appointment_datetime &&
          getBookingAppointmentDateTime(response.result.appointment_datetime),
        bookingAppointmentTime:
          response.result && isWalkIn(response.result)
            ? "Walk In"
            : response.result.appointment_time_pretty,
        orderServices:
          response.result &&
          response.result.order_services &&
          response.result.order_services.map(getServiceNameForMap),
        shopId: response.result && response.result.shop && parseInt(response.result.shop.id),
        orderId: response.result && parseInt(response.result.id),
        walkIn: response.result && isWalkIn(response.result) ? "Yes" : "No",
        shopName: getShopName({ shop: order.shop, company: order.company }),
        shopAddress: getShopAddress(order.shop),
        vehicleName: getVehicleName(order.vehicle),
        yourTotal: order.estimated_total ? numberFormatToCurrency(order.estimated_total) : 0,
        promoCode: order.promotions && order.promotions.length > 0 ? order.promotions[0].name : "",
        revenue: order.estimated_total,
        currency: getCurrencyCode(i18n.language),
        vehicleId: response.result && parseInt(response.result.vehicle_id),
        email: this.props && this.props.user && this.props.user.email
      })
      history.push("/dashboard")
    }
  }

  render() {
    const { t, order } = this.props
    let buttons = [
      {
        text: t("keepMyAppointmentText"),
        trackingClassName: "gtm-keepappointment",
        colorStyle: "grey",
        onClick: () => {
          // trackEvent(EVENT_NAME.keepMyAppointmentClicked, {
          //   ...getAdditionalProps({ shouldIncludeOrderId: false }),
          //   orderId: order.id
          // })
        },
        appendClose: true
      },
      {
        text: t("cancelOrderLbl"),
        trackingClassName: "gtm-cancelappointment",
        colorStyle: "orange",
        onClick: this.cancelAppointment,
        appendClose: true
      }
    ]

    return (
      <span className="black-link">
        <ModalDialog
          linkText={t("cancelOrderLbl")}
          colorStyle="orange"
          buttons={buttons}
          afterOpenModal={() => {
            // trackEvent(EVENT_NAME.clickCancelOrder, {
            //   ...getAdditionalProps({
            //     shouldIncludeShopAddress: false
            //   }),
            //   bookingAppointmentDay: moment(order.appointment_date_pretty).format("dddd"),
            //   // date: moment(order.appointment_date_pretty).format("YYYY-MM-DD"), it will be modified by klaviyo this is built in field
            //   shopName: getShopName({ shop: order.shop, company: order.company }),
            //   shopAddress: getShopAddress(order.shop),
            //   vehicleName: getVehicleName(order.vehicle),
            //   // "payment method": order.paymentType, skipping this as it require changes from backend side
            //   yourTotal: order.any_order_service_lacks_price
            //     ? this.props.t("common:notApplicableLbl")
            //     : numberFormatToCurrency(order.estimated_total),
            //   promoCode: order.promotions && order.promotions.length > 0 && order.promotions[0].name
            // })
          }}
        >
          <p>{t("cancelYourAppointmentLbl")}</p>
        </ModalDialog>
      </span>
    )
  }
}
function mapStateToProps(state) {
  const user = state.user || {}
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    activeVehicle
  }
}

export default withRouter(
  connect(mapStateToProps, { cancelOrder })(withTranslation("cancelLink")(CancelLink))
)
