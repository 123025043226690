import { MILEBAND } from "../constants/application"

export const getServiceName = (pv, cv, idx, arr) => {
  return `${pv}${cv ? cv.name : ""}${cv && cv.position ? ` - ${cv.position}` : ""}${
    idx !== arr.length - 1 && arr.length > 1 ? ", " : ""
  }`
}

export const getServiceNameForMap = (obj) => {
  return `${obj ? obj.name || obj.name : ""}${obj && obj.position ? " - " + obj.position : ""}`
}

export const getMaintenanceScheduleHeaderLabel = (interval, vehicle) => {
  let headerText = ""
  if (vehicle) {
    const miles = vehicle.predicted_current_miles
    let currentMiles = miles ? parseInt(miles.value || miles) : 0

    let past = interval < currentMiles - MILEBAND
    let current = interval >= currentMiles - MILEBAND && interval <= currentMiles + MILEBAND
    let upcoming = interval > currentMiles + MILEBAND

    if (past) headerText = "past due"
    if (current) headerText = "currently due"
    if (upcoming) headerText = "upcoming"
  }
  return headerText
}

export const isUpcoming = (interval, vehicle) =>
  getMaintenanceScheduleHeaderLabel(interval, vehicle) === "upcoming"

export const isCurrentlyDue = (interval, vehicle) =>
  getMaintenanceScheduleHeaderLabel(interval, vehicle) === "currently due"

export const isPastDue = (interval, vehicle) =>
  getMaintenanceScheduleHeaderLabel(interval, vehicle) === "past due"
