import React, { PureComponent } from "react"
import { toCurrencyString } from "../../../shared_component/utils/currency"
import ToggleButton from "../../elements/ToggleButton"
import { connect } from "react-redux"
import store from "../../../shared_component/utils/configureStore"
import { updateCart } from "../../actions/user"

import EstimatedPrice from "./EstimatedPrice"
import PricingPromise from "../PricingPromise"
import PromotionsModal from "../Modal/PromotionsModal"

import { constants } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
const { ACTIVE, INITIATED_BY_USER } = constants.orders

class CartHeader extends React.Component {
  state = { promotionsModalOpen: false }

  togglePromotionsModal = () => {
    this.setState({ promotionsModalOpen: !this.state.promotionsModalOpen })
  }

  render() {
    const { orderLikeObject, shopLikeObject, isFixedPrice, cart, isCart, user, t } = this.props
    const { promotionsModalOpen } = this.state

    const estimatedStatus =
      orderLikeObject.status == null || [ACTIVE, INITIATED_BY_USER].includes(orderLikeObject.status)
    let estimated = null
    const fixedPrice = isFixedPrice && !orderLikeObject.any_order_service_lacks_price

    if (fixedPrice) {
      estimated = t("caradviseGuaranteedLbl")
    } else if (estimatedStatus) {
      estimated = t("estimatedLbl")
    }

    return (
      <React.Fragment>
        {shopLikeObject && !shopLikeObject.cdk_dealer && (
          <div className={`cart-header`}>
            <div className="price-container">
              <p className="cart-title">{t("yourGuaranteedPriceText", { estimated: estimated })}</p>
              <EstimatedPrice orderLikeObject={orderLikeObject} shop={shopLikeObject} />
              {isCart && !user.generic && (
                <div
                  style={{
                    display: "flex",
                    width: "75%",
                    borderStyle: "solid",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                  onClick={this.togglePromotionsModal}
                >
                  <b>{t("redeemPromotionLbl")}</b>
                </div>
              )}
            </div>
          </div>
        )}
        <PricingPromise />
        {promotionsModalOpen && (
          <PromotionsModal
            isOpen={promotionsModalOpen}
            closeModal={this.togglePromotionsModal}
            cart={cart}
          />
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation("cartHeader")(CartHeader)
