import React, { useState } from "react"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import UnlockMilesInfoLogo from "../../../images/rewards/unlock-miles-info.svg"
import SpeedMeterLogo from "../../../images/rewards/speedmeter.svg"
import LockLogo from "../../../images/rewards/lock.svg"
import HowToUnlockMiles from "./HowToUnlockMiles"
import { getMilesToUnlock } from "../../../v1/actions/rewards"
import { connect } from "react-redux"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { useEffect } from "react"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const EstimatedMilesToEarn = ({ t, getMilesToUnlock, user, unlockMiles }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const milesToUnlock = async () => {
    try {
      const unlockMiles = await getMilesToUnlock()

      if (unlockMiles.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${unlockMiles.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
      const odometerEventPayload = {
        milesToUnlock: unlockMiles && unlockMiles.result && unlockMiles.result.lite_vehicle.miles
      }
      identifyEvent({
        traits: odometerEventPayload,
        userId: user.id
      })
      trackEvent("rewards-page-odometer", odometerEventPayload)
      trackFirebaseAnalytics({
        event: gaJSON.rewards_page_odometer,
        params: {
          miles_to_unlock: odometerEventPayload.milesToUnlock
        }
      })
    } catch (error) {
      console.log(t("errorInmilesToUnlock"), error)
    }
  }

  useEffect(() => {
    user && user.activeVehicleId && milesToUnlock()
  }, [])

  return (
    <div className="estimated-miles-container">
      <div className={`estimate-miles-box ${user.generic ? "disable-color" : ""}`}>
        <div className="mile-logo-box">
          <ReactSVG src={SpeedMeterLogo} />
          <ReactSVG src={LockLogo} className="miles-lock-logo" />
        </div>
        <div>
          <p className="estimate-miles-label">{t("estimatedMilesToUnlock")}</p>
          <p className="estimate-miles">
            {unlockMiles ? numberFormater(unlockMiles) : 0} {t("miles")}
          </p>
          <p className="estimate-miles-note">{t("vefifiedAtShop")}</p>
        </div>
      </div>
      <div className="estimate-miles-footer">
        <ReactSVG src={UnlockMilesInfoLogo} className="info-logo" />
        <p className="how-unlock-miles">{t("howUnlockMiles")}</p>
        <p className="learn-here" onClick={() => setOpenDialog(true)}>
          {t("learnHere")}
        </p>
      </div>
      <HowToUnlockMiles setOpenDialog={setOpenDialog} openDialog={openDialog} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    unlockMiles: state.rewards.milesToUnlock
  }
}

export default connect(mapStateToProps, {
  getMilesToUnlock
})(withTranslation("estimatedMilesToEarn")(EstimatedMilesToEarn))
