import React, { Component } from "react"
import { connect } from "react-redux"
import Logo from "../../../images/CarAdvise-Logo.svg"
import RenderOptions from "./RenderOptions"
import { withTranslation } from "react-i18next"
import EmailLogo from "../../../images/magic-link/icon_email.svg"
import PhoneLogo from "../../../images/magic-link/icon_phone.svg"
import { EMAIL_REGEX } from "../../utils/stringHelpers"
import store from "../../utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { APP_NAME, KEYWORDS, OPTIONS } from "../../constants/common"
import {
  me,
  sendMagicLink,
  setLoading,
  setNotLoading,
  signInViaMagicLink,
  signOut
} from "../../../v1/actions/user"
import { routerPaths } from "../../../v1/constants/paths"
import { setupSplitFlags } from "../../../v1/components/Config/SplitClient"
import { getSubdomain } from "../../../v1/components/Config/StylesheetInjector"
import { Redirect } from "react-router"
import SuccessRedirectComponent from "./SuccessRedirectComponent"
import ErrorRedirectComponent from "./ErrorRedirectComponent"
import ReactSVG from "react-svg"
import MagicIcon from "../../../images/icons/magic_icon.svg"
import { Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import {
  KEY_PARAM,
  getUrlParams,
  getUrlWithoutParams,
  removeParamsAndPreserveRoute,
  switchSitesIfApplicable
} from "../../utils/urlUtils"
import { isCarPerks } from "../../../v1/helpers/carPerksHelpers"
import { isEmpty } from "lodash"
import FailureLoginViaMagicLink from "./FailureLoginViaMagicLink"
import { Loader2 } from "../Loader2"

class MagicLinkIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        email: {
          name: OPTIONS.EMAIL,
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        cellPhone: {
          name: OPTIONS.TEXT,
          value: "",
          invalid: false,
          formattedValue: "",
          validators: ["_isPresent", "_isValidCellPhone"]
        }
      },
      activeOption: null,
      successRedirect: false,
      errorRedirect: false,
      redirectType: null,
      redirectionType: null,
      redirectionValue: null,
      redirectError: null,
      isSubmitting: false
    }
  }

  async componentDidMount() {
    const { user, signInViaMagicLink, me, isLoggedIn, t } = this.props
    window.history.replaceState(null, null, removeParamsAndPreserveRoute())
    const key = getUrlParams(KEY_PARAM)
    const toRemove = [KEY_PARAM]
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain(), app_name: APP_NAME })
    window.history.replaceState(null, null, getUrlWithoutParams(toRemove))
    if (!isCarPerks(user) && key && !isLoggedIn) {
      let signInParams = { key: key, isFullStoryFlagEnabled: this.state.isFullStoryFlagEnabled }
      await signInViaMagicLink(signInParams).then((response) => {
        if (response.result) me()
        else {
          this.setState({
            redirectType: KEYWORDS.FAILURE,
            redirectError:
              response && response.status !== 500
                ? response.error || response.errors
                : t("somethingWentWrongLabel")
          })
        }
        this.setState({ loggingIn: false })
        switchSitesIfApplicable(user, this.props)
      })
    }
  }

  handleOptionChange = (option) => {
    const { activeOption } = this.state
    if (activeOption !== option) {
      this.setState({ activeOption: option }, () => {
        this._resetFields()
      })
    }
  }

  _onNumberChange = (value) => {
    const formattedValue = value
      .replace(/[^\d]/g, "")
      .slice(0, 10)
      .replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
        let formatted = ""
        if (p1) formatted += `${p1}`
        if (p2) formatted += `-${p2}`
        if (p3) formatted += `-${p3}`
        return formatted
      })

    const rawNumericValue = formattedValue.replace(/[^\d]/g, "")

    const updatedCellPhone = {
      ...this.state.fields.cellPhone,
      formattedValue: formattedValue,
      value: rawNumericValue,
      invalid: !this._isValidCellPhone(rawNumericValue)
    }

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        cellPhone: updatedCellPhone
      }
    }))
  }

  _onFieldChange(key, value) {
    const updatedFields = {
      ...this.state.fields,
      [key]: {
        ...this.state.fields[key],
        value: value,
        invalid: !this._isEmailValid(value)
      }
    }
    this.setState({ fields: updatedFields })
  }

  textMagicLinkForm = (isError) => {
    const { t } = this.props
    const { fields } = this.state
    const displayValue = `+1 ${fields.cellPhone.formattedValue}`
    return (
      <div className="option-content">
        {isError && this.magicLinkButtons(this._onSubmit)}
        {!isError && <span className="option-helper-text">{t("textHelperLabel")}</span>}
        <div className="input-container">
          <span className="plus-one">+1</span>
          <input
            className="inputFld"
            placeholder={t("textPlaceholderLabel")}
            value={fields.cellPhone.formattedValue}
            onChange={(value) => this._onNumberChange(value.target.value)}
          />
        </div>
      </div>
    )
  }

  emailMagicLinkForm = (isError) => {
    const { t } = this.props
    const { fields } = this.state
    return (
      <div className="option-content">
        {isError && this.magicLinkButtons(this._onSubmit)}
        {!isError && <span className="option-helper-text">{t("emailHelperText")}</span>}
        <div className="input-container">
          <input
            className="inputFld"
            value={fields.email.value}
            placeholder={t("emailPlaceholderLabel")}
            onChange={(e) => this._onFieldChange(OPTIONS.EMAIL, e.target.value)}
          />
        </div>
      </div>
    )
  }

  defaultOptions() {
    const { t } = this.props
    return [
      {
        name: OPTIONS.TEXT,
        label: t("textMagicLinkLabel"),
        icon: PhoneLogo,
        component: () => this.textMagicLinkForm()
      },
      {
        name: OPTIONS.EMAIL,
        label: t("emailMagicLinkLabel"),
        icon: EmailLogo,
        component: () => this.emailMagicLinkForm()
      }
    ]
  }

  magicLinkButtons = (
    onSubmit = this._onSubmit,
    opts = { showMagicLink: true, showSignInButton: true }
  ) => {
    const { t } = this.props
    const { isSubmitting } = this.state
    return (
      <div className="magic-link-buttons">
        {opts && opts.showMagicLink && (
          <Button className="magic-link-button" onClick={() => onSubmit()} loading={isSubmitting}>
            {!isSubmitting && <ReactSVG src={MagicIcon} className="magic-link-button-icon" />}
            {t("sendMagicLinkLabel")}
          </Button>
        )}
        {opts && opts.showSignInButton && (
          <Link
            className="white-link-button"
            to={{
              pathname: routerPaths.signIn
            }}
          >
            {t("goToSignInLabel")}
          </Link>
        )}
      </div>
    )
  }

  _onSubmit = () => {
    this._validateFields(async () => {
      await this.setState({ isSubmitting: true })
      const { fields, activeOption } = this.state

      const areFieldsValid = Object.values(fields).every((field) => !field.invalid)

      if (areFieldsValid) {
        const data = {}

        Object.keys(fields).forEach((key) => {
          if (!fields[key].invalid && fields[key].value.trim() !== "") {
            // data[key] = fields[key].value
            data.value = fields[key].value.toLowerCase()
          }
          if (activeOption === OPTIONS.EMAIL) {
            data.type = OPTIONS.EMAIL
          } else {
            data.type = OPTIONS.PHONE
          }
        })
        if (data.value && data.value.length > 0) {
          const response = await this.props.sendMagicLink({ data })
          if (response && !response.hasOwnProperty("error") && !response.hasOwnProperty("errors")) {
            this.setState({
              redirectType: KEYWORDS.SUCCESS,
              redirectionType: data.type,
              redirectionValue: data.value
            })
          } else {
            this.setState({
              redirectType: KEYWORDS.ERROR,
              redirectionType: data.type,
              redirectionValue: data.value,
              redirectError: response && (response.error || response.errors)
            })
          }
        }
      }
      await this.setState({ isSubmitting: false })
    })
  }

  _resetFields = () => {
    const { activeOption } = this.state
    const { fields } = this.state

    const updatedFields = { ...fields }

    if (activeOption === OPTIONS.EMAIL) {
      updatedFields.cellPhone.value = ""
      updatedFields.cellPhone.invalid = false
    } else if (activeOption === OPTIONS.TEXT) {
      updatedFields.email.value = ""
      updatedFields.email.invalid = false
    }
    this.setState({ fields: updatedFields })
  }

  generatePageView = () => {
    const {
      redirectType,
      redirectionType,
      redirectionValue,
      redirectError,
      isSubmitting
    } = this.state
    const options = this.defaultOptions()
    if (redirectType === KEYWORDS.FAILURE) {
      return (
        <FailureLoginViaMagicLink
          redirectType={redirectType}
          redirectError={redirectError}
          buttonsContainer={this.magicLinkButtons}
          handleTryAgain={() => this.setState({ redirectType: null })}
        />
      )
    }
    const getRedirectComponent = () => {
      switch (redirectType) {
        case KEYWORDS.SUCCESS:
          return (
            <>
              <SuccessRedirectComponent
                redirectType={redirectType}
                redirectionType={redirectionType}
                redirectionValue={redirectionValue}
              />
            </>
          )
        case KEYWORDS.ERROR:
          const isError = true
          const contentToRender =
            redirectionType === OPTIONS.EMAIL
              ? this.emailMagicLinkForm(isError)
              : this.textMagicLinkForm(isError)
          return (
            <ErrorRedirectComponent
              redirectType={redirectType}
              redirectionType={redirectionType}
              redirectionValue={redirectionValue}
              redirectError={redirectError}
              contentComponent={contentToRender}
            />
          )
        default:
          return (
            <RenderOptions
              options={options}
              onOptionChange={this.handleOptionChange}
              onSubmit={this._onSubmit}
              isLoading={isSubmitting}
              buttonsContainer={this.magicLinkButtons}
            />
          )
      }
    }

    return (
      <div className="magic-link-container">
        <div className="magic-link-left-container responsive-large-container">
          <ReactSVG
            src={`/images/session-info-image.svg`}
            style={{
              position: "relative",
              height: "100vh",
              left: "50%",
              width: "50vw",
              transform: "translateX(-50%)"
            }}
            className="logo-image"
          />
        </div>
        <div className="magic-link-right-container">
          <div className="responsive-small-magic-link">
            <div className="header-logo">
              <img src={Logo} />
            </div>
          </div>
          <div className="contentCenter">{getRedirectComponent()}</div>
        </div>
      </div>
    )
  }

  renderOptionsPage = () => {
    const options = this.defaultOptions()
    const { isSubmitting } = this.state
    const { isLoading } = this.props
    return this.generatePageView(
      <RenderOptions
        options={options}
        onOptionChange={this.handleOptionChange}
        onSubmit={this._onSubmit}
        isLoading={isSubmitting}
      />
    )
  }

  render() {
    const { isLoading, isLoggedIn, user } = this.props
    if (this.state.isMagicLinkFeature === false) {
      return <Redirect to={routerPaths.signIn} />
    }
    if (isLoggedIn && user && user.id) {
      return <Redirect to={routerPaths.dashboard} />
    }
    return (
      <React.Fragment>
        {isLoading && <Loader2 allowText={true} />}

        {!isLoading && this.generatePageView()}
      </React.Fragment>
    )
  }

  //Validators
  _isPresent(value) {
    return !!value.trim()
  }

  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  _isValidCellPhone = (value) => {
    return value && value.length == 10 ? true : false
  }

  _validateEmail = () => {
    const { fields } = this.state
    const email = fields.email.value
    const { t } = this.props
    if (email && email.length > 0) {
      if (!this._isEmailValid(email)) {
        this.showError(t("settings:invalidEmailMessage"))
        return false
      }
    } else {
      this.showError(`${t("alertMessage")} ${t(`accountDetails:emailFieldKey`)}`)
    }
    return true
  }

  _validateCellPhone = () => {
    const { fields } = this.state
    const { t } = this.props
    const cellPhone = fields.cellPhone.value
    if (cellPhone && cellPhone.length > 0) {
      if (!this._isValidCellPhone(cellPhone)) {
        this.showError(t("settings:invalidCellPhoneMessage"))
        return false
      }
    } else {
      this.showError(`${t("alertMessage")} ${t(`accountDetails:cellPhoneFieldKey`)}`)
    }
    return true
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _validateFields(callback) {
    const { t } = this.props
    const { activeOption, fields } = this.state
    if (activeOption === OPTIONS.EMAIL) {
      if (!this._validateEmail()) return
    } else if (activeOption === OPTIONS.TEXT) {
      if (!this._validateCellPhone()) return
    }
    let firstInvalidKey = null
    const updatedFields = {}

    Object.keys(fields).forEach((key) => {
      const fieldValue = fields[key].value.trim()
      if (fieldValue !== "") {
        const updatedField = this._setAndValidateField(key, fieldValue)
        updatedFields[key] = updatedField
        if (!firstInvalidKey && updatedField.invalid) {
          firstInvalidKey = key
        }
      }
    })

    if (Object.keys(updatedFields).length > 0) {
      this.setState({ fields: { ...fields, ...updatedFields } }, () => {
        if (firstInvalidKey) {
          store.dispatch(
            Notifications.error({
              title: t("errorTitle"),
              message: `${t("alertMessage")} ${t(`accountDetails:${firstInvalidKey}FieldKey`)}`,
              position: "tr",
              autoDismiss: 5,
              action: {
                label: "Dismiss"
              }
            })
          )
        } else if (callback) {
          callback()
        }
      })
    } else if (callback) {
      callback()
    }
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading
  }
}

export default connect(mapStateToProps, {
  sendMagicLink,
  signInViaMagicLink,
  setLoading,
  setNotLoading,
  signOut,
  me
})(withTranslation("signIn")(MagicLinkIndex))
