import React, { useState } from "react"
import ReactSVG from "react-svg"
import { Button, Modal } from "semantic-ui-react"
import DialogCrossLogo from "../../../images/rewards/dialog-close.svg"
import MilesInfoLogo from "../../../images/rewards/miles-info.svg"
import RangeSlider from "../../../shared_component/components/RangeSlider"
import { withTranslation } from "react-i18next"
import { appliedMilesDollar } from "../../../shared_component/constants/rewards"

const ApplyRewards = ({
  openDialog,
  appliedMiles,
  setOpenDialog,
  handleCustomizeMiles,
  t,
  earnedMiles,
  milesPerDollar
}) => {
  const [slideValue, setSlideValue] = useState(appliedMiles || milesPerDollar)

  const handleSliderValue = (slideValue) => {
    setSlideValue(+slideValue)
  }

  const handleDialog = (isSaveBtnClicked) => {
    isSaveBtnClicked &&
      handleCustomizeMiles(slideValue, `$${appliedMilesDollar(slideValue, milesPerDollar)}`)
    setOpenDialog(false)
  }

  return (
    <Modal
      onClose={() => handleDialog()}
      onOpen={() => setOpenDialog(true)}
      open={openDialog}
      className="apply-miles"
    >
      <Modal.Content>
        <Modal.Description>
          <ReactSVG
            onClick={() => setOpenDialog(false)}
            src={DialogCrossLogo}
            className="apply-miles-logo"
          />
          <p className="dialog-header">{t("redeemMiles")}</p>
          <p className="dialog-sub-header">{t("whenToUseMiles")}</p>

          <div className="range-slider-container">
            <RangeSlider
              handleSliderValue={handleSliderValue}
              minMiles={+milesPerDollar}
              step={milesPerDollar}
              maxMiles={+earnedMiles}
              selectedMiles={slideValue}
              slideValue={
                slideValue && milesPerDollar ? appliedMilesDollar(slideValue, milesPerDollar) : 0
              }
            />
            <div className="available-miles">{earnedMiles}</div>
          </div>
          <p className="miles-slider-value">
            ${slideValue && milesPerDollar ? appliedMilesDollar(slideValue, milesPerDollar) : 0}
          </p>

          <div className="miles-info">
            <ReactSVG src={MilesInfoLogo} className="apply-miles-logo" />
            <p className="miles-charged-info">{t("whenWillCharged")}</p>
          </div>
          <Button content={t("save")} onClick={() => handleDialog(true)} className="miles-save" />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default withTranslation("applyRewards")(ApplyRewards)
