import { getJSON } from "../../shared_component/utils/fetch"
import { S3_PATH } from "../../shared_component/utils/shopLogos"

const FILE_LOCATION = `${S3_PATH}segment_events/segment-events-combined.json`

export const SET_EVENT_NAMES = "SET_EVENT_NAMES"
function setEventName(payload) {
  return {
    type: SET_EVENT_NAMES,
    payload
  }
}

export function getEventNames() {
  return async function (dispatch, getState) {
    const response = await getJSON(FILE_LOCATION)
    if (response.result) {
      dispatch(setEventName(response.result))
    }
    return response
  }
}
