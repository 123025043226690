import React, { Component } from "react"
import { connect } from "react-redux"
import {
  setNotLoading,
  signIn,
  signUp,
  signOut,
  setLoading,
  me,
  updateActiveOrder,
  updateActiveVehicle,
  signInViaMagicLink
} from "../actions/user"
import { promotionParamAvailable } from "../actions/promotion"
import { prepopulatedServicesRequested } from "../actions/services"
import "url-search-params-polyfill"
import store from "../../shared_component/utils/configureStore"

import { withRouter } from "react-router"
import CartPanel from "./Cart/CartPanel"
import withWebSocketConnection from "./withWebSocketConnection"
import LoadingComponent from "./LoadingComponent"
import withGAPageviewTracking from "../tracking/withGAPageviewTracking"
import { isSigningUp } from "../helpers/pathHelpers"
import {
  hashRouteWithParams,
  getUrlParams,
  getUrlParamsArray,
  getUrlWithoutParams,
  removeParamsAndPreserveRoute,
  KEY_PARAM,
  ACTIVE_VEHICLE_ID_PARAM,
  NOTIFICATION_MESSAGE_PARAM,
  PROMO_CODE_PARAM,
  PREPOPULATE_PARAMS,
  DATE_PARAM,
  SHOP_ID,
  ORDER_ID,
  UTM_CAMPAIGN,
  UTM_SOURCE,
  UTM_MEDIUM,
  VARIATION,
  KX_PARAM,
  RESET_PASSWORD_TOKEN
} from "../../shared_component/utils/urlUtils"
import { getSubdomain } from "./Config/StylesheetInjector"
import { SHOW_NOTIFICATION_MODAL } from "../constants/reduxActions"
import { routerPaths } from "../constants/paths"
import { isCarPerks } from "../helpers/carPerksHelpers"
import {
  getMaintenanceSchedules,
  pullOemMaintenanceSchedules
} from "../actions/maintenanceSchedules"
import { isMobile } from "react-device-detect"
import { selectLanguage } from "../helpers/selectLanguage"
import { getAppleWalletUrl, getGoogleWalletUrl } from "../actions/membership"
import SplitClient, {
  MAINTENANCE_SCHEDULES,
  OFF,
  ON,
  V1,
  setupSplitFlags
} from "./Config/SplitClient"
import { APP_NAME } from "../../shared_component/constants/common"
import { Loader2 } from "../../shared_component/components/Loader2"

// Full page loader
export function Loader({ msg }) {
  return (
    <div className="spinnerContainer">
      <div>
        <div className="spinnerText">
          <LoadingComponent />
          <br />
          {msg ? msg : "Loading..."}
        </div>
      </div>
    </div>
  )
}

// This class is a wrapper component (that isn't used across the board) that once provided a Loader
// for its children.
// It also: parses URL params, is (was?) a central place for data loading, corrects rendering of
// the cart on mobile, and handles certain redirect cases.
// The Loader functionality has been removed since a refactor of how data is fetched.

// TODO: Separate out and consolidate functionality
// (Note: InitialDataLoader has copied certain of the functionality for data loading purposes.)
class LoadingMask extends Component {
  constructor(props) {
    super(props)

    if (props.isGeneric && isCarPerks()) {
      props.signOut()
      return
    }

    window.history.replaceState(null, null, removeParamsAndPreserveRoute())

    const key = getUrlParams(KEY_PARAM)

    let activeVehicleId = getUrlParams(ACTIVE_VEHICLE_ID_PARAM)
    if (activeVehicleId) {
      activeVehicleId = parseInt(activeVehicleId)
    }

    const notification = getUrlParams(NOTIFICATION_MESSAGE_PARAM)
    if (notification) {
      store.dispatch({ type: SHOW_NOTIFICATION_MODAL, payload: { message: notification } })
    }

    const promoCode = getUrlParams(PROMO_CODE_PARAM)
    if (promoCode) {
      store.dispatch(promotionParamAvailable({ promoCode: promoCode }))
    }

    const prepopulatedServices = getUrlParamsArray(PREPOPULATE_PARAMS)
    if (prepopulatedServices.length) {
      store.dispatch(prepopulatedServicesRequested({ prepopulatedServices: prepopulatedServices }))
    }

    // NOTE: remove select params from url after use
    const toRemove = [
      KEY_PARAM,
      ACTIVE_VEHICLE_ID_PARAM,
      NOTIFICATION_MESSAGE_PARAM,
      PROMO_CODE_PARAM,
      PREPOPULATE_PARAMS,
      DATE_PARAM,
      SHOP_ID,
      ORDER_ID,
      UTM_CAMPAIGN,
      UTM_SOURCE,
      UTM_MEDIUM,
      VARIATION,
      KX_PARAM,
      RESET_PASSWORD_TOKEN
    ]

    window.history.replaceState(null, null, getUrlWithoutParams(toRemove))
    if (!isCarPerks(props.user) && key && !props.isLoggedIn) {
      let signInParams = { key: key }
      if (activeVehicleId) signInParams.vehicleId = activeVehicleId
      this.state.loggingIn = true
      props.signIn(signInParams).then(async (isSignedIn) => {
        if (isSignedIn) await props.me()
        this.setState({ loggingIn: false })
        this.switchSitesIfApplicable(props.user)
      })
    } else if (
      !isCarPerks(props.user) &&
      !props.isLoggedIn &&
      this.state.isCarAdviseNavigation &&
      this.state.isCarAdviseNavigation === V1 &&
      [/no_vehicle/i, /no_vehicle_vin/i].find((m) => window.location.href.match(m))
    ) {
      this.state.loggingIn = true
      props
        .signUp({
          affiliation: props.affiliation || getSubdomain(),
          key: key,
          language: selectLanguage().includes("fr") ? "fr" : "en"
        })
        .then(() => {
          this.setState({ loggingIn: false })
          this.switchSitesIfApplicable(props.user)
        })
    } else if (activeVehicleId) {
      this.props.user.activeVehicleId = activeVehicleId
      props.me()
    }
  }

  state = {
    initialPageRenderLoadingResetCompleted: false,
    needHiddenCartForMobile: true,
    checkLoggedIn: false
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    await setupSplitFlags.bind(this)({ app_name: APP_NAME })
  }

  onDataReceived = (activeOrderData) => {
    let { updateActiveOrder, children } = this.props
    updateActiveOrder(activeOrderData)
    if (children.onDataReceived) children.onDataReceived(activeOrderData)
  }

  switchSitesIfApplicable = async (user) => {
    if (window.location.origin.match(/localhost/)) return false
    if (user.base_url) {
      const base = user.base_url

      if (!base.match(window.location.origin) && !window.location.origin.match(base)) {
        await this.props.signOut()
        const hasHTTPS = base.match(/http/)
        const nextOrigin = hasHTTPS ? base.replace("http:", "https:") : "https://" + base
        const fullUrl = `${nextOrigin}${this.props.location.pathname}?key=${user.authentication_token}&${this.props.location.search}`
        window.location = fullUrl
      }
    }
  }

  /* If some bug happens, and loading is set to true, and user tries to return to dashboard, will be stuck in loading unless
     we forcefully reset loading whenever this top level component is mounted for first time. */

  // Update: The solution here to the above problem doesn't seem great.
  // TODO: revisit
  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn != this.state.checkLoggedIn) {
      this.setState({ checkLoggedIn: nextProps.isLoggedIn })
    }
    if (
      !this.props.loading &&
      nextProps.loading &&
      !this.state.initialPageRenderLoadingResetCompleted
    )
      this.props.setNotLoading()

    this.setState({ initialPageRenderLoadingResetCompleted: true })
  }

  // TODO: refactor how we render the cart to eliminate the need for this.
  async componentDidUpdate() {
    // WE don't want the cart on every page in desktop, but we need it available on mobile.
    // So what we do is we render it twice on every page, and if we discover after a render that one
    // is already on the child component, we remove our hidden one.
    const { user } = this.props
    setTimeout(this.doubleCartCheck, 1000)
    if (
      window.location.href.includes("maintenanceSchedule") &&
      user.vehicles &&
      user.vehicles.length &&
      user.authentication_token
    ) {
      if (!this.state.isMaintenanceSchedulesAPICalled) {
        const isMaintenanceScheduleFlag = await SplitClient.getTreatments(
          [MAINTENANCE_SCHEDULES],
          user,
          {
            app_name: APP_NAME
          }
        )
        await this.setState({
          isMaintenanceScheduleFlag: isMaintenanceScheduleFlag[MAINTENANCE_SCHEDULES]
        })
        // TODO: can we cache this?
        this.setState(
          {
            isMaintenanceSchedulesAPICalled: true
          },
          () => {
            const activeVehicle =
              user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
            if (
              isMaintenanceScheduleFlag &&
              isMaintenanceScheduleFlag[MAINTENANCE_SCHEDULES] === ON
            ) {
              store.dispatch(pullOemMaintenanceSchedules({ vehicle: activeVehicle }))
            } else {
              store.dispatch(getMaintenanceSchedules({ vehicle: activeVehicle }))
            }
          }
        )
      }
    }
    if (
      window.location.href.includes("membership") &&
      user.vehicles &&
      user.vehicles.length &&
      user.authentication_token
    ) {
      if (!this.state.isWalletsAPICalled) {
        this.setState(
          {
            isWalletsAPICalled: true
          },
          () => {
            store.dispatch(getAppleWalletUrl())
            store.dispatch(getGoogleWalletUrl())
          }
        )
      }
    }
  }

  doubleCartCheck = () => {
    if (
      document.getElementsByClassName("cart-panel").length === 2 &&
      this.state.needHiddenCartForMobile
    ) {
      this.setState({ needHiddenCartForMobile: false })
    }
  }

  render() {
    const { loading, msg, children, isLoggedIn, user, disableLoggedInCheck } = this.props
    const { loggingIn } = this.state
    if (loggingIn) {
      return <Loader2 allowText={true} />
    }

    const isCarPerksCustomer = isCarPerks()
    // Not sure what scenario this is for.
    if (
      !isCarPerksCustomer &&
      !isLoggedIn &&
      !loggingIn &&
      !disableLoggedInCheck &&
      !window.location.href.match(routerPaths.signIn)
    ) {
      window.location = hashRouteWithParams(routerPaths.signIn)
    }
    if (isSigningUp()) return <div>{children}</div>

    return (
      <div>
        <div className="dash">
          {children.props && children.props.location.pathname === "/" ? (
            this.state.checkLoggedIn === true ? (
              children
            ) : (
              <div className="spinnerContainer">
                <div>
                  <div className="spinnerText">
                    <LoadingComponent />
                  </div>
                </div>
              </div>
            )
          ) : (
            children
          )}
          {this.state.needHiddenCartForMobile &&
            isMobile &&
            this.state.isCarAdviseNavigation === "v2" && (
              <div className="dashRight" style={{ alignSelf: "center" }}>
                <div className="visible-md-down">
                  <CartPanel />
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    loading: !!user.loading,
    msg: user.loadingMessage,
    isGeneric: user.generic,
    isLoggedIn: !!user.authentication_token,
    user: user
  }
}

export default connect(mapStateToProps, {
  setNotLoading,
  signIn,
  signUp,
  signOut,
  setLoading,
  me,
  updateActiveOrder,
  updateActiveVehicle,
  signInViaMagicLink
})(withRouter(withGAPageviewTracking(withWebSocketConnection(LoadingMask, "ShopOrderChannel"))))
