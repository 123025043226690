import React from "react"
import ReactSVG from "react-svg"

const EarnMilesStepInfo = ({ steps, isLastChild }) => {
  return (
    <div className="earn-step-details-container">
      <div className="earn-steps">
        <ReactSVG src={steps.image} className={`${isLastChild ? "" : "earn-steps-logo"}`} />
        <div>
          <p className="earn-steps-title">{steps.title}</p>
          <p className="earn-steps-details">{steps.whenYouEarn}</p>
        </div>
      </div>
    </div>
  )
}

export default EarnMilesStepInfo
