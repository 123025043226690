import React from "react"
import RecenterIcon from "../../../images/shops/recenter.svg"
import { Divider, Icon, Image } from "semantic-ui-react"

const MapControlContainer = (props) => {
  const { recenterClick, expandCompressClick, isExpanded } = props

  return (
    <div className="map-control-container">
      <Image
        src={RecenterIcon}
        className="map-control-icon recenterIcon"
        onClick={() => recenterClick && recenterClick()}
      />
      <Divider className="divider-style" />
      <Icon
        name={isExpanded ? "compress" : "expand"}
        className="map-control-icon recenterIcon"
        onClick={() => {
          expandCompressClick && expandCompressClick(!isExpanded)
        }}
      />
    </div>
  )
}

export default MapControlContainer
