import React from "react"
import { Accordion, Divider, Grid, Header, Icon, Image, Loader } from "semantic-ui-react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { fetchCarAdviseRecemendedServices } from "../../CarAdvise2/actions/maintenance"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faFolder } from "@fortawesome/free-solid-svg-icons"
import { DEFAULT_IMG_URL } from "../../constants/confirmDetails"

class MaintenanceDetails extends React.Component {
  state = { activeIndex: -1, carAdviceServices: [], loading: false }
  handleClick = (_, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  async getCarAdviseRecommendedServices(data) {
    const carAdviseRecommendedResponse = this.props.fetchCarAdviseRecemendedServices(data)
  }
  render() {
    const { activeIndex, loading, carAdviceServices } = this.state
    const { vehicle, t, hasVehicle } = this.props
    const mm = (vehicle && vehicle.make) + " " + vehicle.model
    const vehicleYear = (vehicle && vehicle.year) || ""
    const isCompleted =
      vehicle &&
      vehicle.oemServices &&
      vehicle.oemServices.find((item) => {
        if (item.is_completed) {
          return {
            ...item
          }
        }
      })
    return (
      <React.Fragment>
        <div className="maintenance-schedules-container1">
          <Grid>
            <Grid.Row>
              <Grid.Column computer={1}></Grid.Column>
              <Grid.Column mobile={4} tablet={8} computer={7}>
                <Grid.Row>
                  <div style={{ alignSelf: "center" }}>
                    <Header as="h1" className="primary-font maintenanceScheduleHeader">
                      <Icon.Group size="small">
                        <Icon
                          name="arrow left"
                          onClick={() => this.props.history.goBack()}
                          style={{ cursor: "pointer" }}
                        />
                      </Icon.Group>
                      {t("maintenanceServiceHistory")}
                    </Header>
                  </div>
                  {hasVehicle && (
                    <span className="seeHistory" style={{ alignSelf: "center" }}>
                      <Link
                        to={{
                          pathname: `/vehicles/${vehicle.id}/history`,
                          params: { isFromNoVehicle: true }
                        }}
                      >
                        {t("detailsHistory")}
                      </Link>
                    </span>
                  )}
                </Grid.Row>
                <Grid.Row></Grid.Row>

                <div
                  style={{
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "820px"
                  }}
                >
                  {vehicle &&
                    vehicle.oemServices &&
                    vehicle.oemServices.map((item, i) => {
                      if (item.is_completed) {
                        return (
                          <div
                            style={{
                              padding: "6px",
                              position: "relative"
                            }}
                          >
                            <Grid.Row>
                              <Accordion
                                fluid
                                styled
                                onClick={async () => {
                                  const data = {
                                    vehicleId: this.props.vehicle.id,
                                    interval: item.interval,
                                    interval_id: item.interval_id
                                  }
                                  this.setState({ loading: true })
                                  const carAdviseRecommendedResponse = await this.props.fetchCarAdviseRecemendedServices(
                                    data
                                  )

                                  if (!carAdviseRecommendedResponse.result.errors) {
                                    this.setState({
                                      carAdviceServices: carAdviseRecommendedResponse.result,
                                      loading: false
                                    })
                                  } else {
                                    this.setState({ loading: false })
                                  }
                                }}
                              >
                                <Accordion.Title
                                  active={activeIndex === i}
                                  index={i}
                                  onClick={this.handleClick}
                                >
                                  <Icon
                                    name="angle right"
                                    style={{
                                      position: "absolute",
                                      right: 30,
                                      color: "black",
                                      top: 30
                                    }}
                                  />
                                  <div className="tertiary-font color">
                                    {t("mileage")}:{item.interval} mi
                                  </div>

                                  <div style={{ marginTop: "6px" }}>
                                    <Image src="../../images/completedIcon.png" size={20} />
                                  </div>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === i}>
                                  <div>
                                    {item.services.length ? (
                                      item.services.map((item) => {
                                        return (
                                          <>
                                            <Divider fitted />
                                            <ul className="secondary-font color">
                                              {item.service_name}
                                            </ul>
                                            <Divider fitted hidden />
                                          </>
                                        )
                                      })
                                    ) : (
                                      <ul className="secondary-font color">{t("noRecordFound")}</ul>
                                    )}
                                    {carAdviceServices &&
                                      carAdviceServices.map((item) => {
                                        return (
                                          <>
                                            <Divider fitted />
                                            <ul className="secondary-font color">{item.name}</ul>
                                            <Divider fitted hidden />
                                          </>
                                        )
                                      })}
                                    <div style={{ textAlign: "center" }}>
                                      {loading ? <Loader active={loading} /> : null}
                                    </div>
                                  </div>
                                </Accordion.Content>
                              </Accordion>
                            </Grid.Row>
                          </div>
                        )
                      }
                    })}
                  {!isCompleted ? (
                    <div
                      style={{
                        padding: "15px",
                        display: "flex",
                        textAlign: "center",
                        height: "40px",
                        alignItems: "center",
                        fontSize: "18px",
                        backgroundColor: "white"
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "inline",
                            margin: "10px",
                            // background: mileStatusBackground,
                            borderRadius: "42px"
                          }}
                        >
                          <FontAwesomeIcon icon={faFolder} />
                          <span style={{ marginLeft: "6px" }}>{t("noRecordFound")}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={5}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <div
                    className="vehicleHeader"
                    style={{
                      display: "inline-flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div className="vehicleYearLabel">{vehicleYear}</div>
                    <div>
                      <Header as="h1" className="makeModelHeader secondary-font">
                        {mm}
                      </Header>
                    </div>
                  </div>
                  <div>
                    <Image
                      className={vehicle && vehicle.dashboard_url ? "hide-bg" : "default_image"}
                      src={
                        vehicle && vehicle.dashboard_url ? vehicle.dashboard_url : DEFAULT_IMG_URL
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column computer={1}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart = {}
  if (props.cartId)
    cart =
      (user.active_carts && user.active_carts.filter((c) => c.id === Number(props.cartId))[0]) || {}
  else
    cart =
      (user.active_carts && user.active_carts.find((c) => c.vehicle_id === user.activeVehicleId)) ||
      {}

  let hasVehicle = !!cart.vehicle_id
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id === user.activeVehicleId)

  return {
    hasVehicle: !!activeVehicle,
    hasVIN: activeVehicle && !!activeVehicle.vin,
    vehicle: activeVehicle || {},
    country: user.country
  }
}
export default connect(mapStateToProps, { fetchCarAdviseRecemendedServices })(
  withTranslation("maintenanceHistoryDetails")(MaintenanceDetails)
)
