import { updateCart, setUserError, setLoading, setNotLoading } from "./user"
import { getJSON, postJSON } from "../../shared_component/utils/fetch"
import i18n from "../../shared_component/utils/i18n"
import { result } from "lodash"
import { PREV_PARAM, SHOP_API_CALL } from "../reducers/shop"

const DELETE_SHOP = "DELETE_SHOP"
const SERVICE_PRICING_URL = "/api/v4/shops/?/pricing"
const FIND_SHOPS_URL = "/api/v4/shops/select_shops"

export function selectShop(selectedShop, opts) {
  return async function (dispatch, getState) {
    dispatch(
      updateCart(
        [{ event: "set_shop", shop_id: selectedShop.id }],
        undefined,
        undefined,
        true,
        opts
      )
    )
  }
}

export const deleteShop = (deleted_key) => {
  return {
    type: DELETE_SHOP,
    deleted_key
  }
}

export function setMakeShopsApiCallData(shops) {
  return { type: SHOP_API_CALL, shops }
}
export function setPrevShopSearchParams(prevShopSearchParams) {
  return { type: PREV_PARAM, payload: prevShopSearchParams }
}

export function makeShopsApiCall(params) {
  return async function (dispatch, getState) {
    let user = getState().user || {}

    let { authentication_token } = user
    let result = null

    if (authentication_token) {
      const response = await getJSON(FIND_SHOPS_URL, params, {
        Authorization: user.authentication_token
      })
      if (response.result) {
        result = response.result

        dispatch(setMakeShopsApiCallData(result))
      } else {
        dispatch(setMakeShopsApiCallData([]))
      }
    }
    return result
  }
}

export function getPriceEstimates(services, shopId, vehicleId) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("common:pricingLoadingMessage")))
    let user = getState().user || {}
    let { authentication_token } = user
    let result = null
    if (authentication_token) {
      let url = `${SERVICE_PRICING_URL.replace("?", shopId)}`

      let response = await postJSON(
        url,
        { services_requested: services, shop_id: shopId, vehicle_id: vehicleId },
        { Authorization: authentication_token }
      )
      // I see no need to cache this.

      if (response.result) {
        result = response.result
      } else {
        dispatch(setUserError(response.error))
      }
    }
    dispatch(setNotLoading())
    return result
  }
}
