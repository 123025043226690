import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Popup, Accordion, Icon, Checkbox, Divider } from "semantic-ui-react"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"

import RadioOutline from "../../../../../images/radio_outline.svg"
import RadioSelected from "../../../../../images/radio_selected.svg"

const SortingFilterMobileView = (props) => {
  const { sortingType, setSortingType, vehicleTypeId, cart, isSortOpen, setIsSortOpen } = props

  const handleFilterIconClick = () => {
    setIsSortOpen && setIsSortOpen(false)
  }

  return (
    <div className="mobileViewSorting">
      <div className="sortFilter">
        <Icon className="closeIcon" name="close black" onClick={() => handleFilterIconClick()} />
        <div className="indicator" />
        <span className="sortTitle">Sort By</span>

        <div className="divider" />

        <FilterItem
          isSelected={sortingType && sortingType == "price"}
          onClick={(value) => setSortingType(value)}
          label="Price"
          value="price"
        />
        <FilterItem
          isSelected={sortingType && sortingType == "distance"}
          onClick={(value) => setSortingType(value)}
          label="Distance"
          value="distance"
        />
      </div>
    </div>
  )
}

const FilterItem = (props) => {
  const { isSelected, onClick, label, value } = props
  return (
    <div className="filterItem" onClick={() => onClick(value)}>
      <span className={isSelected ? "fitlerItemlabelSelected" : "fitlerItemlabel"}>{label}</span>
      <img src={isSelected ? RadioSelected : RadioOutline} />
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let vehicleTypeId = activeVehicle.vehicleTypeId

  return {
    user: user,
    cart,
    vehicleTypeId
  }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardMapComponent")(SortingFilterMobileView)
)
