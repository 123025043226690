import React from "react"
import { Image } from "semantic-ui-react"

const CuvrdPlanServiceHeader = ({ title, headerLogo }) => {
  return (
    <div className="cuvrd-plan-service-header">
      <p className="cuvrd-service-header">{title}</p>
    </div>
  )
}

export default CuvrdPlanServiceHeader
