import React from "react"
import ReactSVG from "react-svg"
import { isCarPerksSignUp } from "./../../helpers/carPerksHelpers"
import store from "../../../shared_component/utils/configureStore"
import { logoutUser } from "./../../actions/user"
import { HOSTED_IMAGE_BASE_URL } from "./../../constants/paths"

const SignUpContainerDesktop = (props) => {
  const isCarPerks = isCarPerksSignUp()
  const params = new URLSearchParams(`?${window.location.href.split("?")[1]}`)
  const affiliation = params.get("affiliation")
  const step = params.get("step")

  return (
    <div>
      <div className="responsive-large-signup">
        <div>
          <div className="signup-container">
            {affiliation ? (
              <BrandedSignupLeft affiliation={affiliation} />
            ) : (
              <div className="signup-left">
                <ReactSVG
                  src={`/images/CaradviseSignupLogo.svg`}
                  svgClassName="ca-logo"
                  style={{
                    position: "relative",
                    left: "50%",
                    width: "60%",
                    transform: "translateX(-50%)"
                  }}
                />
              </div>
            )}
            {props.children}
          </div>
        </div>
      </div>

      <div className="responsive-small-signup">{props.children}</div>
    </div>
  )
}

const BrandedSignupLeft = ({ affiliation }) => {
  const fallbackImage = (
    <img
      style={{ maxWidth: "50%", margin: "auto" }}
      src={`${HOSTED_IMAGE_BASE_URL}${affiliation}_logo-light.png`}
      alt=""
    />
  )
  return (
    <div className="signup-left branded">
      <div>
        <ReactSVG
          fallback={() => fallbackImage}
          src={`${HOSTED_IMAGE_BASE_URL}${affiliation}_logo-light.svg`}
        />
      </div>
      <div>
        <ReactSVG
          src={`/images/CaradviseSignupLogo.svg`}
          svgClassName="ca-logo"
          style={{
            position: "relative",
            left: "50%",
            width: "60%",
            transform: "translateX(-50%)"
          }}
        />
      </div>
    </div>
  )
}

export default SignUpContainerDesktop
