import moment from "moment"
import { BLACKOUT_DATES, BLOCK_DATES } from "../constants/common"

export const isDayDisabled = (date) => {
  const blackoutDays = BLACKOUT_DATES.map((date) => {
    return new Date(0, date.month, date.day) // Year 0 represents yearless dates
  })

  return !blackoutDays.some(
    (day) => date.getMonth() === day.getMonth() && date.getDate() === day.getDate()
  )
}

export const compareDate = (givenDate, compareDate) => {
  // givenDate will be compared with compareDate argument
  const isSameDate = moment(givenDate).isSame(compareDate, BLOCK_DATES.day)
  return isSameDate
}

export const findNextAvailableDate = (dateToCheck) => {
  let date = moment(dateToCheck)

  while (BLACKOUT_DATES.some(({ month, day }) => date.month() === month && date.date() === day)) {
    date = date.add(1, "day")
  }

  return date.toDate()
}

export const isCarAdviseOff = (date) => {
  const dayjsDate = moment(date)
  return BLACKOUT_DATES.some((blackoutDate) => {
    return blackoutDate.month === dayjsDate.month() && blackoutDate.day === dayjsDate.date()
  })
}
