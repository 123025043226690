export const COMMON_SERVICES = [
  {
    image: require("../../../images/caradvise2/oil_change.svg"),
    translationKey: "oilChangeTitle",
    selected: false,
    referenceId: "oil_change",
    isGroup: false,
    link: "/oilChange"
  },
  {
    image: require("../../../images/caradvise2/tire_rotation.svg"),
    translationKey: "tireRotationTitle",
    selected: false,
    referenceId: "tire_rotation",
    isGroup: false,
    link: "/tireRotation"
  },
  {
    image: require("../../../images/caradvise2/air.svg"),
    translationKey: "cabinAirFilterTitle",
    selected: false,
    referenceId: "cabin_air_filter_replacement",
    isGroup: false,
    link: "/cabinAirFilter"
  },
  {
    image: require("../../../images/caradvise2/break_pad.svg"),
    translationKey: "brakePadsTitle",
    selected: false,
    referenceId: "brake_pads_replacement",
    isGroup: false,
    link: "/brakePads"
  },
  {
    image: require("../../../images/caradvise2/wiper_baldes.svg"),
    translationKey: "wiperBladesTitle",
    selected: false,
    referenceId: "windshield_wiper_blade_replacement",
    isGroup: false,
    link: "/wiperBlades"
  },
  {
    image: require("../../../images/caradvise2/inpecstion.svg"),
    translationKey: "inspectionTitle",
    selected: false,
    groupReferenceIds: [
      "vehicle_multi_point_inspection",
      "rideshare_visual_inspection",
      "rideshare_state_inspection"
    ],
    isGroup: true,
    link: "/inspection"
  },
  {
    image: require("../../../images/caradvise2/new_battery.svg"),
    translationKey: "newBatteryTitle",
    selected: false,
    referenceId: "battery_replacement",
    isGroup: false,
    link: "/batteryReplacement"
  },
  {
    image: require("../../../images/caradvise2/wheel_aligemnt.svg"),
    translationKey: "wheelAlignmentTitle",
    selected: false,
    referenceId: "wheel_alignment",
    isGroup: false,
    link: "/wheelAlignment"
  }
]
