import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Accordion, Icon, Checkbox } from "semantic-ui-react"
import SpacingComponent from "./SpacingComponent"

const ShopSortingComponent = (props) => {
  const { sortingType, isSortOpen, setIsSortOpen } = props

  const handleFilterIconClick = () => {
    setIsSortOpen && setIsSortOpen()
  }

  return (
    <div style={{ display: "flex" }}>
      <FilterIcon sortingType={sortingType} isOpen={isSortOpen} onClick={handleFilterIconClick} />
    </div>
  )
}

const FilterIcon = (props) => {
  const SortIcon = () => {
    return (
      <div className="sort_icon">
        <Icon name="chevron up" size="tiny" />
        <Icon name="chevron down" size="tiny" />
      </div>
    )
  }

  return (
    <div
      className="mapHeaderComponent"
      onClick={() => props.onClick()}
      style={{
        backgroundColor: props.isSortOpen ? "#e7e7e7" : "#fff",
        padding: "6px",
        borderRadius: "50px",
        marginLeft: "10px"
      }}
    >
      <span>
        Sort by: <span>{props.sortingType == "price" ? "Price" : "Distance"}</span>
      </span>
      <SortIcon />
    </div>
  )
}

const FilterOptions = (props) => {
  const { sortingType, setSortingType } = props
  return (
    <Accordion vertical>
      <div style={{ width: "120px" }}>
        <FilterItem
          isSelected={sortingType && sortingType == "price"}
          onClick={(value) => setSortingType(value)}
          label="Price"
          value="price"
        />
        <SpacingComponent />
        <FilterItem
          isSelected={sortingType && sortingType == "distance"}
          onClick={(value) => setSortingType(value)}
          label="Distance"
          value="distance"
        />
      </div>
    </Accordion>
  )
}

const FilterItem = (props) => {
  const { isSelected, onClick, label, value } = props
  return (
    <div className="filterComponent">
      <Checkbox value={value} checked={isSelected} onChange={() => onClick(value)} />
      <span className="checkBoxLabel">{label}</span>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let vehicleTypeId = activeVehicle && activeVehicle.vehicleTypeId

  return {
    user: user,
    cart,
    vehicleTypeId
  }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardMapComponent")(ShopSortingComponent)
)
