import { getJSON } from "./fetch"
import sortBy from "lodash/sortBy"
import uniqBy from "lodash/uniqBy"
import store from "./configureStore"

let SERVICES_URL = "/api/v4/services/service_hierarchy"

/*
  Series of mixin functions used by a couple of pages to search services.
*/

export function servicesToListArray(services) {
  // Takes a service object and turns it into a List-able array.
  return services
    .map((service) => {
      return ((service.app_services && service.app_services.services) || [service]).map(
        (actualService) => {
          return {
            ...service,
            onClick: this.props.selectService ? this.props.selectService.bind(this, service) : null,
            name: actualService.name
          }
        }
      )
    })
    .reduce((arr, el) => {
      arr = arr.concat(el)
      return arr
    }, [])
}

export async function getServices(selectedCategory) {
  if (this.state.allServices) return this.state.allServices

  this.props.setLoading("Loading services...")
  let opts = {}
  if (selectedCategory && selectedCategory !== "Common") opts.category = selectedCategory

  if (selectedCategory === "Common") opts.common = true

  // TODO: move this to a file in /actions
  let user = store.getState().user || {}
  let { authentication_token } = user
  let response = await getJSON(SERVICES_URL, opts, { Authorization: user.authentication_token })
  let allServices = sortBy(response.result.services, (service) =>
    (service.name || "").toLowerCase()
  )
  let services = allServices
  let hasNextLevel = false

  if (selectedCategory) {
    // Means we're on a category page. Filtering time.
    allServices = allServices.filter((s) => s.app_first_level || s.app_second_level)
    if (!this.state.viewAll) {
      services = services.filter((s) => s.app_first_level)
      hasNextLevel = allServices.filter((s) => s.app_second_level)
      hasNextLevel = !!hasNextLevel.length
    }
  }

  services = servicesToListArray.apply(this, [services])
  allServices = servicesToListArray.apply(this, [allServices])

  await this.setState({ allServices, hasNextLevel, services })
  this.props.setNotLoading()

  return allServices
}

const searchServices = async function (searchText) {
  if (!searchText) searchText = this.state.searchText
  let allServices = await getServices.apply(this)

  searchText = searchText.toLowerCase()
  const services = []

  ;(!this.state.viewAll && this.props.selectedCategory
    ? allServices.filter((s) => s.app_first_level)
    : allServices
  ).forEach((service) => {
    if (service.name.toLowerCase().match(searchText)) {
      services.push(service)
    }

    if (service.services) {
      services.push(
        ...service.services.filter((service) => service.name.toLowerCase().match(searchText))
      )
    }
  })

  await this.setState({ services: uniqBy(services, "name"), showServices: searchText !== "" })
}

export default searchServices
