export const suggestGeoSuggestClick = (suggest, onSuggestSelect) => {
  if (suggest && suggest.hasOwnProperty("custom") && suggest.custom == true) {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          (data) => {
            const { latitude, longitude } = data.coords
            onSuggestSelect({
              name: suggest.label,
              location: {
                lat: latitude,
                lng: longitude
              }
            })
          },
          (error) => {}
        )
      } else {
        navigator.geolocation.getCurrentPosition(
          (data) => {
            const { latitude, longitude } = data.coords
            onSuggestSelect({
              name: suggest.label,
              location: {
                lat: latitude,
                lng: longitude
              }
            })
          },
          (error) => {}
        )
      }
    })
  } else if (suggest) {
    onSuggestSelect(suggest)
  }
}
