import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Image } from "semantic-ui-react"
import CheckMarkImage from "../../images/dashboard/stepper-checkmark.svg"
import StepperArrow from "../../images/dashboard/stepper-arrow.svg"
import { getUpdatedCart } from "../../v1/helpers/orderHelpers"
import { isEmpty } from "lodash"

class DashboardStepper extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { t, user, cart, activeVehicle, order } = this.props

    let dashboardStepperContent = [
      {
        label: t("addFirstVehicleLabel"),
        isComplete: !isEmpty(activeVehicle),
        isOpen: isEmpty(activeVehicle)
      },
      {
        label: t("selectShopLabel"),
        isComplete: !!cart.shop,
        isOpen: !isEmpty(activeVehicle) && !cart.shop
      },
      {
        label: t("selectServicesLabel"),
        isComplete: (!!cart.shop && cart.order_services.length > 0) || !!order,
        isOpen: !isEmpty(activeVehicle) && cart.shop && cart.order_services.length === 0 && !order
      },
      {
        label: t("bookAppointmentLabel"),
        isComplete: !!order,
        isOpen: (!isEmpty(activeVehicle) && cart.shop && cart.order_services.length > 0) || !!order
      }
    ]

    if (!isEmpty(activeVehicle) && user && user.sign_in_count >= 1) {
      dashboardStepperContent = dashboardStepperContent.slice(1)
    }
    const stepperLength = dashboardStepperContent.length - 1 || 0

    return (
      <div className="dashboard-stepper-container">
        {dashboardStepperContent &&
          dashboardStepperContent.map((stepper, index) => {
            return (
              <div className="dashboard-stepper">
                <div className="dashboard-stepper-section">
                  {stepper.isComplete ? (
                    <Image className="dashboard-stepper-image" src={CheckMarkImage} />
                  ) : (
                    <span className="dashboard-stepper-number">{index + 1}</span>
                  )}
                  <span
                    className={`dashboard-stepper-label ${
                      stepper.isOpen ? "stepper-mobile-label" : ""
                    }`}
                  >
                    {stepper.label}
                  </span>
                </div>
                {index < stepperLength && <Image src={StepperArrow} />}
              </div>
            )
          })}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { user } = state
  const activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let cart
  let isCart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    user,
    activeVehicle,
    order,
    cart,
    isCart
  }
}

export default connect(mapStateToProps)(withTranslation("dashboard")(DashboardStepper))
