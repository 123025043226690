import React from "react"

const EarnMilesStpesDetails = ({ steps }) => {
  return (
    <div className="earn-miles-steps-details-container">
      <p className="step-title">{steps.title}</p>
      <p className="step-detail">{steps.detail}</p>
    </div>
  )
}

export default EarnMilesStpesDetails
