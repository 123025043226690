"use-strict"

import React, { Component } from "react"
import { connect } from "react-redux"
import { Trans, withTranslation } from "react-i18next"
import { getSubdomain } from "./components/Config/StylesheetInjector"
import carInsurance from "../images/carinsurance.png"
import extendewaranty from "../images/extendwaranty.png"
import autoGlass from "../images/autoglass.png"
// import repair from "../images/image 7.png"
import tires from "../images/tires.png"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import {
  UBER,
  DOORDASH,
  INSTACART,
  STRIDE,
  AMAZON,
  FAVOR,
  PLAYOCTOPUS
} from "./constants/affiliations"
import { postJSON, getJSON } from "../shared_component/utils/fetch"
import _ from "lodash"
import LoadingComponent from "../v1/components/LoadingComponent"
import {
  PRODUCTION_LEADS_URL,
  STAGING_LEADS_URL,
  VEHICLES_LEAD_QUOTE_URL,
  SAFELITE_LOGO,
  VSC,
  INSURANCE,
  TIRE,
  ROADSIDE_ASSISTANCE_URL,
  PREMIUM,
  BASIC,
  AUTO_REFINANCE_URL
} from "./constants/offers"
import { Row, Col } from "react-grid-system"
import carchexLogo from "../images/carchex-logo-offer.png"
import forevercarLogo from "../images/ForeverCar-Vertical-Logo.jpg"
import gridwiseLogo from "../images/GridwiseCombinationHorizontalLight.svg"
import { ON, setupSplitFlags } from "./components/Config/SplitClient"
import { Image } from "semantic-ui-react"
import { getSplitIOForFeatures } from "./actions/user"
import RoadsideAssistance from "../images/RoadsideAssistance.png"
import AutoRefinanceImage from "../images/offers/auto_refinance.png"
import { trackEvent } from "../shared_component/utils/segmentAnalytics"
import { routerPaths } from "./constants/paths"
import store from "../shared_component/utils/configureStore"
import Notifications from "./components/Notifications"
import { APP_NAME } from "../shared_component/constants/common"

class Offers extends Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ app_name: APP_NAME })
  }

  render() {
    return (
      <>
        {this.state && this.state.isPartnerScreenFlag !== ON ? (
          <PartnerOffers {...this.props} />
        ) : (
          <DealsAndOffers {...this.props} />
        )}
      </>
    )
  }
}

class DealsAndOffers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vscResponse: {},
      insuranceResponse: {},
      tiresResponse: {}
    }
  }

  async componentDidMount() {
    await this.getSplitIODetails()
  }

  getSplitIODetails = async () => {
    const { getSplitIOForFeatures } = this.props
    const responseVSC = await getSplitIOForFeatures(VSC)
    this.setState({ vscResponse: responseVSC && responseVSC.result })
    const responseInsurance = await getSplitIOForFeatures(INSURANCE)
    this.setState({ insuranceResponse: responseInsurance && responseInsurance.result })
    const responseTires = await getSplitIOForFeatures(TIRE)
    this.setState({ tiresResponse: responseTires && responseTires.result })
  }

  handleVehicleToaster = () => {
    store.dispatch(
      Notifications.info({
        title: this.props.t("vehicle"),
        message: this.props.t("addVehicle"),
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  autoRefinanceClick = () => {
    trackEvent("auto-refinance-clicked", {}, false, false, false)
    window.open(AUTO_REFINANCE_URL, "_blank")
  }

  render() {
    const { t, membershipPlan, user } = this.props
    const { vscResponse, insuranceResponse, tiresResponse } = this.state

    return (
      <div className="deprecated-content">
        <>
          <div className="dealsaving">
            <h3>{t("CarAdvise_360")}</h3>
            <p className="deals-description">{t("CarAdvise_360_Description")}</p>
            <Row className="cards-parent">
              {vscResponse && (vscResponse.axiom || vscResponse.curvd) ? (
                <Link
                  to={
                    user && user.vehicles && user.vehicles.length > 0
                      ? routerPaths.confirmDetails
                      : routerPaths.offers
                  }
                  onClick={() => {
                    if (!(user && user.vehicles && user.vehicles.length > 0)) {
                      this.handleVehicleToaster()
                      return
                    }
                    trackEvent("vehicle-protection-plan-viewed", {}, true, false, false)
                  }}
                >
                  <Col className="cards vsc-card">
                    <Image src={extendewaranty} />
                    <div className="title">
                      <h5>{t("Extended_Warranty")}</h5>
                      <div className="subtitle">{t("Extended_warranty_subtitle")}</div>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Link>
              ) : null}
              {insuranceResponse && (insuranceResponse.clearcover || insuranceResponse.zebra) ? (
                <Link
                  to={
                    user && user.vehicles && user.vehicles.length > 0
                      ? routerPaths.chooseCoverage
                      : routerPaths.offers
                  }
                  onClick={() => {
                    if (!(user && user.vehicles && user.vehicles.length > 0)) {
                      this.handleVehicleToaster()
                      return
                    }
                    trackEvent("insurance-plan-viewed", {}, true, false, false)
                  }}
                >
                  <Col className="cards">
                    <img src={carInsurance} />
                    <div className="title">
                      <h5>{t("Car_Insurance")}</h5>
                      <div className="subtitle">{t("Insurance_subtitle")}</div>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Link>
              ) : null}
              <Link
                to={
                  user && user.vehicles && user.vehicles.length > 0
                    ? routerPaths.autoGlass
                    : routerPaths.offers
                }
                onClick={() => {
                  if (!(user && user.vehicles && user.vehicles.length > 0)) {
                    this.handleVehicleToaster()
                    return
                  }
                }}
              >
                <Col className="cards">
                  <Image src={autoGlass} />
                  <div className="title">
                    <h5>{t("Auto_Glass")}</h5>
                    <div className="subtitle">{t("Auto_glass_subtitle")}</div>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Link>
              {/* <Link to={"/offer/collision-repair"}>
                <Col className="cards">
                  <Image src={repair} />
                  <div className="title">
                    <h5>{t("Collision_repair")}</h5>
                    <div className="subtitle">{t("Subtitle")}</div>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Link> */}
              {tiresResponse && tiresResponse.tireconnect ? (
                <Link
                  to={
                    user && user.vehicles && user.vehicles.length > 0
                      ? routerPaths.tireSize
                      : routerPaths.offers
                  }
                  onClick={() => {
                    if (!(user && user.vehicles && user.vehicles.length > 0)) {
                      this.handleVehicleToaster()
                      return
                    }
                    trackEvent("tc-tire-clicked", {}, true, false, false)
                  }}
                >
                  <Col className="cards">
                    <Image src={tires} />
                    <div className="title">
                      <h5>{t("Tires")}</h5>
                      <div className="subtitle">{t("Tires_subtitle")}</div>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </Link>
              ) : null}
              {membershipPlan === BASIC || membershipPlan === PREMIUM ? (
                <a href={ROADSIDE_ASSISTANCE_URL} target="_blank">
                  <Col className="cards">
                    <Image src={RoadsideAssistance} />
                    <div className="title">
                      <h5>{t("Roadside_Assistance")}</h5>
                      <div className="subtitle">{t("Roadside_assistance_subtitle")}</div>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Col>
                </a>
              ) : null}
              <div
                className="cursor-pointer"
                href={AUTO_REFINANCE_URL}
                onClick={() => this.autoRefinanceClick()}
              >
                <Col className="cards">
                  <Image src={AutoRefinanceImage} />
                  <div className="title">
                    <h5>{t("autoRefinance")}</h5>
                    <div className="subtitle">{t("autoRefinance_subtitle")}</div>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </div>
            </Row>
          </div>
        </>
      </div>
    )
  }
}

const Offer = ({ link, text, logoPath, t }) => {
  return (
    <div className="offer">
      <Image src={logoPath} width="200" height="90" style={{ margin: "0 auto" }} />
      <p>{text}</p>
      <a className="button orange-button" href={link} target="_blank">
        <span className="orange-button-text-color">{t("viewOfferDetailBtn")}</span>
      </a>
    </div>
  )
}

const ForevercarOffer = ({ activeVehicle, link, logoPath, t, quote }) => {
  const { make, model, year } = activeVehicle
  const foreverText = `${t("foreverDescription", {
    price: quote.actual_monthly_payment,
    carMake: activeVehicle && activeVehicle.make ? make : "",
    carModel: activeVehicle && activeVehicle.model ? model : "",
    carYear: activeVehicle && activeVehicle.year ? year : ""
  })}`
  return (
    <div className="offer">
      <Image src={logoPath} width="200" height="90" style={{ margin: "0 auto" }} />
      <p>
        {foreverText}
        <a
          href={link}
          className="secondary-font primary-color"
          style={{ textDecoration: "underline", fontWeight: "500" }}
        >
          {t("clickHere")}
        </a>{" "}
        !
      </p>
    </div>
  )
}

class PartnerOffers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      foreverCarDetails: null
    }
  }

  async componentDidMount() {
    await this.fetchLeadQuote()
  }

  isViewable() {
    let subdomain = getSubdomain()
    return [UBER, DOORDASH, INSTACART, STRIDE, AMAZON, FAVOR, PLAYOCTOPUS].includes(subdomain)
  }

  fetchLeadQuote = async () => {
    const { activeVehicle, user } = this.props
    const { vin, odometer_config } = activeVehicle

    if (activeVehicle && vin) {
      await postJSON(
        VEHICLES_LEAD_QUOTE_URL,
        {
          vehicle: {
            vin: vin,
            mileage: odometer_config.quantity
          }
        },
        {
          Authorization: user.authentication_token
        }
      ).then((responseData) => {
        if (responseData && responseData.result) {
          this.setState({ foreverCarDetails: responseData.result, isLoading: true })
          return responseData.result
        } else {
          this.setState({ isLoading: true })
          return responseData.error
        }
      })
    }
    this.setState({ isLoading: true })
  }

  render() {
    const { t, activeVehicle } = this.props
    const { foreverCarDetails, isLoading } = this.state
    let host = window.location.hostname
    let leadsUrl = null
    if (
      foreverCarDetails &&
      (process.env.NODE_ENV === "staging" ||
        process.env.NODE_ENV === "development" ||
        host.match(/development/))
    ) {
      leadsUrl = STAGING_LEADS_URL + foreverCarDetails.lead.latest_quote_url
    } else if (foreverCarDetails && process.env.NODE_ENV === "production") {
      leadsUrl = PRODUCTION_LEADS_URL + foreverCarDetails.lead.latest_quote_url
    }

    return (
      <div className="dashContainer">
        <div className="dashLeftContainer">
          <div className="deprecated-content partner-offer-parent">
            <h2 className="primary-font confident-blue">{t("CarAdvise_360")}</h2>
            {!isLoading && <LoadingComponent />}
            {isLoading && (
              <>
                <div className="offer">
                  <Image src={SAFELITE_LOGO} width="200" height="90" style={{ margin: "0 auto" }} />
                  <p>
                    {t("saveText")}
                    <a
                      className="secondary-font primary-color"
                      href="https://ad.doubleclick.net/ddm/trackclk/N1068.2825COMMISSIONJUNCTION/B25410315.314730328;dc_trk_aid=506959616;dc_trk_cid=146182569;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=?https://www.safelite.com/cjreplace-30?promo=CJ30RPLC&utm_source=cjunction&utm_medium=affiliate&utm_campaign=cjreplace"
                      target="_blank"
                    >
                      <u>{t("safeLiteOfferDescriptionReplacement")}</u>
                    </a>
                    {t("andText")}
                    <a
                      className="secondary-font primary-color"
                      href="https://ad.doubleclick.net/ddm/trackclk/N1068.2825COMMISSIONJUNCTION/B25410315.314976713;dc_trk_aid=507698287;dc_trk_cid=146182569;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=?https://www.safelite.com/cjrepair-15?promo=15RPRCJ&utm_source=cjunction&utm_medium=affiliate&utm_campaign=15RPRCJ"
                      target="_blank"
                    >
                      <u>{t("safeLiteOfferDescriptionRepair")}</u>
                    </a>
                    {t("withText")} Safelite.
                  </p>
                </div>
                <Offer
                  link="https://www.carchex.com/auto-warranty/caradvise/?paid=100559&amp;subpaid=app"
                  text={<div>{<Trans>{t("carchexDescription")}</Trans>}</div>}
                  logoPath={carchexLogo}
                  t={t}
                />
                {this.isViewable() && (
                  <Offer
                    link="https://www.gridwisetrack.com/cmp/K2378/LFH7H/"
                    text={<div>{<Trans>{t("gridwiseDescription")}</Trans>}</div>}
                    logoPath={gridwiseLogo}
                    t={t}
                  />
                )}
                {foreverCarDetails &&
                  foreverCarDetails.quotes.length > 0 &&
                  foreverCarDetails.quotes.map((quote, i) => (
                    <ForevercarOffer
                      activeVehicle={activeVehicle}
                      link={leadsUrl}
                      text={<div>{<Trans>{t("foreverText")}</Trans>}</div>}
                      logoPath={forevercarLogo}
                      t={t}
                      quote={quote}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex =
      user.vehicles && user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  let membershipPlan = user && user.membership && user.membership.plan_name

  return {
    isLoggedIn: !!user.authentication_token,
    user,
    activeVehicle,
    membershipPlan
  }
}

export default connect(mapStateToProps, { getSplitIOForFeatures })(
  withTranslation("offers")(Offers)
)
