import i18n from "i18next"
import { registerLocale } from "react-datepicker"
import fr from "date-fns/locale/fr"
registerLocale("fr", fr)

export function setLanguage(language, country) {
  if (language === "en" && country === "us") i18n.changeLanguage("en")
  if (language === "en" && country === "ca") i18n.changeLanguage("en-CA")
  if (language === "fr" && country === "ca") i18n.changeLanguage("fr-CA")
}

// In internationalizing, translating the `position` attribute at the database-level would require
// deep data remodeling.  Instead, we elected to superficially translate `position` in the UI for
// time.  This function maintains a map of all positions from the database to keys used for translation.
export function translatedPosition({ position, t }) {
  const key = {
    "Rear - all": "positionRearAll",
    "Rear-left": "positionRearLeft",
    "Rear-right": "positionRearRight",
    All: "positionAll",
    "Front - all": "positionFrontAll",
    "Front-left": "positionFrontLeft",
    "Front-right": "positionFrontRight",
    "Left - all": "positionLeftAll",
    "Right - all": "positionRightAll",
    Inner: "positionInner",
    Exterior: "positionExterior",
    Left: "positionLeft",
    Right: "positionRight",
    Aux: "positionAux",
    Outer: "positionOuter",
    Positive: "positionPositive",
    Negative: "positionNegative",
    Front: "positionFront",
    Rear: "positionRear",
    "Front - right": "positionFrontRight",
    "Front- left": "positionFrontLeft",
    "Rear - right": "positionRearRight",
    Conventional: "positionConventional",
    "Synthetic-blend": "positionSyntheticBlend",
    "Full-synthetic": "positionFullSynthetic",
    "Euro-synthetic": "positionEuroSynthetic",
    "Diesel-oil": "dieselOil"
  }[position]

  return key ? t(`common:${key}`) : position
}

export function datePickerLocale() {
  if (i18n.language.toLowerCase() === "fr-ca") return "fr"
}

export function globalMomentLocale(userLocale, country) {
  if (country && country.toLowerCase() === "ca" && userLocale === "fr") {
    require("moment/locale/fr")
  }
}

export function isCanada(country) {
  return country && country.toLowerCase() === "ca"
}

export function countryForDropdown(country) {
  switch (country) {
    case "ca":
      return "Canada"
    default:
      return "United States"
  }
}

export function localeForTireConnect() {
  switch (i18n.language) {
    case "en":
      return "en_US"
    case "en-CA":
      return "en_CA"
    case "fr-CA":
      return "fr_CA"
  }
}
