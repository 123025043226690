import React from "react"
import { Redirect } from "react-router"
import { saveServiceDetails } from "../../actions/orders"
import { getNewTiresServiceFromServices } from "../../helpers/orderServiceHelpers"
import { localeForTireConnect } from "../../helpers/translationHelpers"
import { routerPaths } from "../../constants/paths"
import { withTranslation } from "react-i18next"
import BreadcrumbNav, { SHOP } from "../BreadcrumbNav"
import GenericPanel from "../GenericPanel"
import store from "../../../shared_component/utils/configureStore"
import loadjs from "loadjs"

const TireConnect = (props) => {
  const myRef = React.useRef()
  const [loaded, setLoaded] = React.useState(false)
  const [formCompleted, setFormCompleted] = React.useState(false)

  const init = () => {
    const { year, make, model, sub_model } = props.vehicle
    const { shop, order_services } = props.cart
    const tireService = getNewTiresServiceFromServices(order_services)

    window.TCWidget.init({
      apikey: process.env.REACT_APP_TIRE_CONNECT_API_KEY,
      container: "tireconnect",
      locale: localeForTireConnect(),
      locationId: shop.tire_location_id,
      locationLock: true
    }).then(function (widget) {
      widget.searchByVehicle({
        year: year,
        make: make,
        model: model,
        trim: sub_model
      })
    })

    window.TCWidget.on("onAppointmentClick", (event) => {
      event.reject()
      const { quote } = event.data

      store.dispatch(
        saveServiceDetails({
          serviceDetails: {
            parts: quote.tires,
            services: quote.services
          },
          serviceID: tireService.id,
          cartID: props.cart.id
        })
      )
      setFormCompleted(true)
    })
  }

  React.useEffect(() => {
    loadjs.reset()
    if (myRef.current && !loaded) {
      loadjs(["https://app.tireconnect.ca/js/widget.js"], "tireconnect")
      loadjs.ready("tireconnect", () => {
        setLoaded(true)
        init()
      })
    }
  }, [myRef.current, loaded])

  {
    return formCompleted ? (
      <Redirect to={routerPaths.schedule} />
    ) : (
      <GenericPanel>
        <BreadcrumbNav
          back={SHOP}
          forward={null}
          forwardDisabled
          currentName={props.t("tires:selectTiresLbl")}
        />
        <div ref={myRef} id="tireconnect"></div>
      </GenericPanel>
    )
  }
}

export default withTranslation(["tires"])(TireConnect)
