import React, { Component } from "react"
import { connect } from "react-redux"
import store from "../../shared_component/utils/configureStore"
import { signUp, signIn, logoutUser } from "../actions/user"
import { Redirect } from "react-router"
import Button from "../components/Button"
import SignUpContainer from "../components/SignUp/SignUpContainer"
import "url-search-params-polyfill"
import { Link } from "react-router-dom"
import { EMAIL_REGEX } from "../../shared_component/utils/stringHelpers"
import MembershipTerms from "../components/Membership/MembershipTerms"
import DismissibleBlock from "../components/DismissibleBlock"
import zipcodes from "zipcodes"
import {
  AFFILIATION_PARAM,
  getUrlWithoutParams,
  getUrlParams,
  getCookie,
  SID,
  FUEL_REWARDS
} from "../../shared_component/utils/urlUtils"
import { getSubdomain } from "../components/Config/StylesheetInjector"
import { isCaradvise } from "../helpers/affiliationHelpers"
import MiniLoader from "../components/MiniLoader"
import { withTranslation } from "react-i18next"
import { routerPaths } from "../constants/paths"
import { isCarPerks } from "../helpers/carPerksHelpers"
import ReCAPTCHA from "react-google-recaptcha"
import Notifications from "../components/Notifications"
import NotificationContainer from "../components/NotificationContainer"
import { selectLanguage } from "../helpers/selectLanguage"
import { GAEvent, GAEventCategories } from "../tracking/GAEvent"
import { V1, setupSplitFlags } from "../components/Config/SplitClient"
import { loadAdRollScript } from "../constants/helpers/adrollHelper"
import { setCookie } from "../../shared_component/utils/cookies"
import { NEW_USER } from "../../shared_component/constants/rewards"

const recaptchaRef = React.createRef()

class AccountDetails extends Component {
  constructor(props) {
    super(props)
    const sid = getCookie(SID)
    let affiliation = props.affiliation || getUrlParams(AFFILIATION_PARAM)
    window.history.replaceState(null, null, getUrlWithoutParams([AFFILIATION_PARAM]))
    if (!affiliation && !isCaradvise()) affiliation = getSubdomain()
    if (sid) {
      affiliation = FUEL_REWARDS
    }
    this.state = {
      nextButtonClicked: false,
      emailChk: false,
      cellPhoneChk: false,
      passwordChk: false,
      fields: {
        email: {
          name: "Email",
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        firstName: {
          name: "Firstname",
          value: "",
          invalid: false,
          validators: ["_isPresent"]
        },
        lastName: {
          name: "Lastname",
          value: "",
          invalid: false,
          validators: ["_isPresent"]
        },
        cellPhone: {
          name: "Mobilephone",
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isValidCellPhone"]
        },
        zip: {
          name: "Zipcode",
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isValidZip"]
        },
        password: { name: "Password", value: "", invalid: false, validators: ["_isPresent"] },
        confirmPassword: {
          name: "Confirm Password",
          value: "",
          invalid: false,
          validators: ["_isPasswordMatched"]
        },
        affiliation: { name: "affiliation", value: affiliation, invalid: false, validators: [] },
        userAlreadyHasAccount: false
      },
      frId: sid,
      isRecaptchaChecked: false
    }

    if (this.props.carperks) {
      this.handleCarperksCustomer()
    }
  }

  async componentDidMount() {
    const { isGeneric } = this.props
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    if (isGeneric && isCarPerks()) {
      store.dispatch(logoutUser())
    }
    loadAdRollScript()
  }

  changedRecaptcha(val) {
    if (val.length > 0) {
      this.props.setState({ isRecaptchaChecked: true })
    } else {
      this.props.setState({ isRecaptchaChecked: false })
    }
  }

  componentWillReceiveProps({ error, user }) {
    const { t } = this.props
    if (error) {
      if (typeof error === "string") {
        if (error === "Email has already been taken") this.setState({ userAlreadyHasAccount: true })
        else {
          let messageList = error.split(",")
          messageList.map((msg) => {
            store.dispatch(
              Notifications.error({
                title: t("vehicleDetails:errorText"),
                message: `${t("errorLbl")} ${msg}`,
                position: "tr",
                autoDismiss: 5,
                action: {
                  label: "Dismiss"
                }
              })
            )
          })
        }
      } else {
        let errorKeys = Object.keys(error)
        let errorMessages = []
        let errorFields = {}

        errorKeys.forEach((key) => {
          let field = this.state.fields[key]
          if (!field) return

          errorMessages.push(field.name + " " + error[key])
          errorFields[key] = { ...field, invalid: true }
        })

        store.dispatch(
          Notifications.error({
            title: t("vehicleDetails:errorText"),
            message: `${t("errorLbl")} ${errorMessages.join(",")}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )

        this.setState({ fields: { ...this.state.fields, ...errorFields } })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isGeneric && isCarPerks()) {
      store.dispatch(logoutUser())
    } else if (
      this.props.isLoggedIn &&
      !prevProps.isLoggedIn &&
      this.state.isCarAdviseNavigation === V1
    ) {
      this.handleCarperksCustomer()
    }
  }

  afterDismiss = () => {
    this.setState({ userAlreadyHasAccount: false })
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") this._onClickNext()
  }

  handleCarperksCustomer = () => {
    const { isLoggedIn, isLoggingIn, user, location, history, isGeneric } = this.props
    const params = new URLSearchParams(location.search)

    const currentStep = params.get("step")

    const shouldGoToStep1 = isGeneric || !currentStep || (!isLoggingIn && !isLoggedIn)
    const shouldGoToStep2 = isLoggedIn && user.vehicles && user.vehicles.length === 0
    const shouldGoToStep3 = isLoggedIn && user.vehicles && user.vehicles.length > 0
    const nextStep = shouldGoToStep1
      ? "1"
      : shouldGoToStep2
      ? "2"
      : shouldGoToStep3
      ? "3"
      : undefined

    if (this.props.affiliation && !params.has("affiliation")) {
      params.set("affilation", this.props.affiliation)
    }

    params.set("step", nextStep)
    history.push({ search: params.toString() })
  }

  render() {
    const {
      userAlreadyHasAccount,
      nextButtonClicked,
      fields,
      emailChk,
      cellPhoneChk,
      passwordChk
    } = this.state
    const {
      user,
      isLoading,
      t,
      match: {
        params: { referralCode }
      },
      location: { search }
    } = this.props

    if (!this.props.carperks && this.state.isCarAdviseNavigation === "v1") {
      if ((user.referee_id || !referralCode) && user.vehicles && user.vehicles.length === 0)
        return <Redirect to={{ pathname: routerPaths.signUp2, search: search }} />
    }

    if (
      (!referralCode && user.vehicles && user.vehicles.length > 0) ||
      (this.state.isCarAdviseNavigation === "v2" && user && user.id)
    )
      return <Redirect to={{ pathname: routerPaths.dashboard, search: search }} />
    return (
      <SignUpContainer affiliation={this.props.carperks ? this.props.affiliation : undefined}>
        <NotificationContainer></NotificationContainer>
        <div className="signup-right">
          <div className="signup-right-content-wrapper">
            <div className="signup-right-form-section" onKeyDown={this.handleKeyDown}>
              {userAlreadyHasAccount && (
                <DismissibleBlock
                  afterDismiss={this.afterDismiss}
                  visible={userAlreadyHasAccount}
                  content={
                    <div style={{ textAlign: "left", backgroundColor: "#f8f8f8" }}>
                      <p>{t("alreadyHaveAccountDesc")}</p>
                      <span className="primary-link">
                        <Link to="/signIn">{t("clickHereToSigninText")}</Link>
                      </span>
                    </div>
                  }
                />
              )}
              <h1 className="signupStepTitle">
                {referralCode ? t("signUpWithReferralCodeLbl") : t("signupLbl")}
              </h1>

              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("firstNameLbl")}</label>
                <div className="input-image--container">
                  <input
                    name="name"
                    autocomplete="name"
                    onFocus={() => this.setState({ userAlreadyHasAccount: false })}
                    data-qa="registration-account-details-firstName-input-field"
                    className={
                      nextButtonClicked && fields.firstName.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="firstName"
                    value={this.state.fields.firstName.value}
                    onChange={(value) => this._onFieldChange("firstName", value.target.value)}
                  />
                  {nextButtonClicked &&
                  fields.firstName.value !== "" &&
                  fields.firstName.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {fields.firstName.invalid && fields.firstName.value == "" ? (
                  <span className="error-message">
                    {t("firstNameFieldKey") + " " + t("isRequired")}
                  </span>
                ) : fields.firstName.invalid ? (
                  <span className="error-message">
                    {t("errorMessage") + " " + t("firstNameFieldKey")}
                  </span>
                ) : (
                  ""
                )}
                <br />
              </div>
              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("lastNameLbl")}</label>
                <div className="input-image--container">
                  <input
                    name="name"
                    autocomplete="name"
                    onFocus={() => this.setState({ userAlreadyHasAccount: false })}
                    data-qa="registration-account-details-lastName-input-field"
                    className={
                      nextButtonClicked && fields.lastName.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="lastName"
                    value={this.state.fields.lastName.value}
                    onChange={(value) => this._onFieldChange("lastName", value.target.value)}
                  />
                  {nextButtonClicked && fields.lastName.value !== "" && fields.lastName.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>

                {fields.lastName.invalid && fields.lastName.value == "" ? (
                  <span className="error-message">
                    {t("lastNameFieldKey") + " " + t("isRequired")}
                  </span>
                ) : fields.lastName.invalid ? (
                  <span className="error-message">
                    {t("errorMessage") + " " + t("lastNameFieldKey")}
                  </span>
                ) : (
                  ""
                )}
                <br />
              </div>

              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("mobilePhoneLbl")}</label>
                <div className="input-image--container">
                  <input
                    type="tel"
                    name="phone"
                    autocomplete="tel"
                    onFocus={() => this.setState({ userAlreadyHasAccount: false })}
                    data-qa="registration-account-details-cellPhone-input-field"
                    className={
                      nextButtonClicked && fields.cellPhone.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="cellPhone"
                    value={this.state.fields.cellPhone.value}
                    onChange={(value) => this._onNumberChange("cellPhone", value.target.value)}
                  />
                  {nextButtonClicked &&
                  cellPhoneChk &&
                  fields.cellPhone.value !== "" &&
                  fields.cellPhone.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>

                {fields.cellPhone.invalid && fields.cellPhone.value == "" ? (
                  <span className="error-message">
                    {t("cellPhoneFieldKey") + " " + t("isRequired")}
                  </span>
                ) : cellPhoneChk && fields.cellPhone.invalid ? (
                  <span className="error-message">{t("phoneFormatInvalid")}</span>
                ) : (
                  ""
                )}

                <br />
              </div>

              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("zipCodeLbl")}</label>
                <div className="input-image--container">
                  <input
                    name="postal-code"
                    autocomplete="postal-code"
                    data-qa="registration-account-details-zipcode-input-field"
                    className={
                      nextButtonClicked && fields.zip.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="zip"
                    value={this.state.fields.zip.value}
                    onChange={(value) => this._onFieldChange("zip", value.target.value)}
                  />
                  {nextButtonClicked && fields.zip.value !== "" && fields.zip.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>

                {fields.zip.invalid && fields.zip.value == "" ? (
                  <span className="error-message">{t("zipFieldKey") + " " + t("isRequired")}</span>
                ) : fields.zip.invalid ? (
                  <span className="error-message">
                    {t("errorMessage") + " " + t("zipFieldKey")}
                  </span>
                ) : (
                  ""
                )}
                <br />
              </div>
              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("emailLbl")}</label>
                <div className="input-image--container">
                  <input
                    name="email"
                    autocomplete="email"
                    onFocus={() => this.setState({ userAlreadyHasAccount: false })}
                    data-qa="registration-account-details-email-input-field"
                    className={
                      nextButtonClicked && fields.email.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="email"
                    value={this.state.fields.email.value}
                    onChange={(value) => this._onFieldChange("email", value.target.value)}
                  />
                  {nextButtonClicked &&
                  emailChk &&
                  fields.email.value !== "" &&
                  fields.email.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {fields.email.invalid && fields.email.value == "" ? (
                  <span className="error-message">
                    {t("emailFieldKey") + " " + t("isRequired")}
                  </span>
                ) : emailChk && fields.email.invalid ? (
                  <span className="error-message">{t("emailFormatInvalid")}</span>
                ) : (
                  ""
                )}
                <br />
              </div>

              <div className="inputFldWrapper">
                <label className="inputFldTitle required">{t("passwordLbl")}</label>
                <div className="input-image--container">
                  <input
                    type="password"
                    data-qa="registration-account-details-password-input-field"
                    className={
                      nextButtonClicked && fields.password.invalid ? "inputFld error" : "inputFld"
                    }
                    ref="password"
                    autocomplete="new-password"
                    value={this.state.fields.password.value}
                    onChange={(value) => this._onFieldChange("password", value.target.value)}
                  />
                  {nextButtonClicked &&
                  (passwordChk || fields.password.invalid) &&
                  fields.password.value !== "" ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {fields.password.invalid && fields.password.value == "" ? (
                  <span className="error-message">
                    {t("passwordFieldKey") + " " + t("isRequired")}
                  </span>
                ) : passwordChk || fields.password.invalid ? ( //password error coming from
                  <span className="error-message">
                    {t("passwordFieldKey") + " " + t("sixDigits")}
                  </span>
                ) : (
                  ""
                )}
                <br />
              </div>

              <div className="inputFldWrapper" style={{ paddingBottom: "10px" }}>
                <label className="inputFldTitle required">{t("confirmPasswordLbl")}</label>
                <div className="input-image--container">
                  <input
                    type="password"
                    data-qa="registration-account-details-password-confirm-input-field"
                    className={
                      nextButtonClicked && fields.confirmPassword.invalid
                        ? "inputFld error"
                        : "inputFld"
                    }
                    ref="confirmPassword"
                    value={this.state.fields.confirmPassword.value}
                    onChange={(value) => this._onFieldChange("confirmPassword", value.target.value)}
                  />
                  {nextButtonClicked &&
                  fields.confirmPassword.value !== "" &&
                  fields.confirmPassword.invalid ? (
                    <i
                      style={{ marginLeft: "-10%", marginTop: "5%", scale: "1.5", color: "red" }}
                      class="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </div>
                {!fields.confirmPassword.invalid ? (
                  ""
                ) : fields.confirmPassword.invalid && fields.confirmPassword.value == "" ? (
                  <span className="error-message">
                    {t("confirmPasswordFieldKey") + " " + t("isRequired")}
                  </span>
                ) : fields.confirmPassword.invalid ? (
                  <span className="error-message">
                    {t("passwordFieldKey") + " " + t("doesNotMatch")}
                  </span>
                ) : (
                  ""
                )}

                <br />
              </div>
              {this.state.isReCaptchaflagEnabled && this.state.isReCaptchaflagEnabled && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  hl={selectLanguage()}
                  className="g-recaptcha"
                  sitekey="6LeUpj4hAAAAABS0te5UEjImtPjlKY33ggB01jBe"
                  onChange={this.changedRecaptcha}
                  props={this}
                />
              )}
              <div className="inputFldWrapper" style={{ paddingBottom: "10px" }}>
                <Button
                  style={{ height: "25px", width: "140px", marginLeft: "0", marginBottom: "20px" }}
                  text={isLoading ? <MiniLoader /> : t("nextBtnText")}
                  disabled={isLoading}
                  trackingClassName="accountDetails"
                  colorStyle="orange"
                  onClick={this._onClickNext}
                />
              </div>
            </div>

            <div>
              <div className="already-customer-prompt">{t("alreadyCustomerLbl")} </div>
              <div className="primary-link">
                <Link to={{ pathname: routerPaths.signIn, search: search }}>
                  {t("clickHereLbl")}
                </Link>
              </div>
            </div>

            <div className="terms-of-service-container">
              <div className="terms-of-service-links-wrapper">
                <div className="terms-of-service-line">
                  <MembershipTerms message={t("membershipTerms:membershipTermsAltDescription1")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SignUpContainer>
    )
  }
  _isPresent(value) {
    return !!value.trim()
  }

  _isPasswordMatched(value) {
    return value === this.state.fields.password.value
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _onFieldChange(key, value) {
    this.setState({
      onFieldChange: true,
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (firstInvalidKey) {
      } else if (callback) callback()
    })
  }
  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }
  // NOTE: This only allows numeric keys to be entered. This is used over setting input type to "number", because browsers may style number fields differently.
  _onNumberChange = (key, value) => {
    const numericRegex = /^\d*$/

    const isOnlyNumeric = numericRegex.test(value)

    if (isOnlyNumeric) {
      this.setState({
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      })
    }
  }
  _isValidCellPhone = (value) => {
    return value.length == 10 ? true : false
  }

  _isValidZip = (value) => {
    const result = zipcodes.lookup(value)
    return result !== undefined
  }

  _setAndValidateField = (key, value) => {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _onClickNext = () => {
    const { t } = this.props
    this.setState({
      nextButtonClicked: true
    })

    if (this.state.fields.email.value !== "") {
      this.setState({
        emailChk: true
      })
    } else {
      this.setState({
        emailChk: false
      })
    }
    if (this.state.fields.cellPhone.value !== "") {
      this.setState({
        cellPhoneChk: true
      })
    } else {
      this.setState({
        cellPhoneChk: false
      })
    }
    if (this.state.fields.password.value !== "" && this.state.fields.password.value.length < 6) {
      this.setState({
        passwordChk: true
      })
    } else {
      this.setState({
        passwordChk: false
      })
    }
    if (this.state.isRecaptchaChecked == false && this.state.isReCaptchaflagEnabled) {
      store.dispatch(
        Notifications.error({
          title: t("vehicleDetails:errorText"),
          message: `${t("recaptchaCheck")}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      return false
    } else {
      this._validateFields(async () => {
        let data = {
          email: this.state.fields.email.value.toLowerCase(),
          first_name: this.state.fields.firstName.value,
          last_name: this.state.fields.lastName.value,
          cell_phone: this.state.fields.cellPhone.value,
          password: this.state.fields.password.value,
          pushid: this.state.pushid,
          affiliation: this.state.fields.affiliation.value,
          zip: this.state.fields.zip.value,
          referral_code: this.props.match.params.referralCode,
          fr_id: this.state.frId
        }
        const response = await this.props.signUp(data)
        setCookie(NEW_USER, true, 1)
        if (response().result) {
          GAEvent(GAEventCategories.USER, "form-submitted", "Form Submitted")
        }
      })
    }
  }
}

function mapStateToProps(state) {
  let user = state.user || {}

  return {
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading,
    isGeneric: !!user.generic,
    user: user,
    error: user.error
  }
}

export default connect(mapStateToProps, { signUp, signIn })(
  withTranslation(["accountDetails", "membershipTerms"])(AccountDetails)
)
