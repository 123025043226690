import store from "../../shared_component/utils/configureStore"

export const getDefaultPaymentMethod = () => {
  const state = store.getState()
  if (
    state.paymentMethods &&
    state.paymentMethods.braintreePaymentMethods &&
    state.paymentMethods.braintreePaymentMethods.length > 0
  ) {
    const defaultPaymentMethod =
      state.paymentMethods.braintreePaymentMethods.find(
        (paymentMethod) => paymentMethod.isdefault === true
      ) || {}
    return defaultPaymentMethod
  }
  return {}
}

export const getPaymentMethodName = () => {
  const defaultPaymentMethod = getDefaultPaymentMethod()
  if (defaultPaymentMethod && defaultPaymentMethod.type) {
    return defaultPaymentMethod.type === "credit_card"
      ? "Credit Card"
      : defaultPaymentMethod.type === "pay_pal_account"
      ? "Paypal"
      : defaultPaymentMethod.type === "affirm"
      ? "Affirm"
      : null
  }
  return null
}
