export const OIL_CHANGE_REFERENCE_ID = "oil_change"
export const OIL_POSITION_CONVENTIONAL = "Conventional"
export const NEW_TIRES_REFERENCE_ID = "new_tires"
export const RIDESHARE_VISUAL_INSPECTION_REFERENCE_ID = "rideshare_visual_inspection"
export const RIDESHARE_STATE_INSPECTION_REFERENCE_ID = "rideshare_state_inspection"
export const OIL_CHANGE = "Oil Change"

export function findOilPositionIndex(position) {
  const positionsHierarchy = ["Conventional", "Synthetic-blend", "Full-synthetic", "Euro-synthetic"]
  return positionsHierarchy.indexOf(
    positionsHierarchy.find((positionName) => {
      return positionName === position
    })
  )
}

export const getNewTiresServiceFromServices = (orderServices) => {
  return (orderServices || []).find((service) => service.reference_id === NEW_TIRES_REFERENCE_ID)
}

export const isRideshareVisualInspection = (orderService) => {
  const serviceDefinition = orderService.service_definition || {}
  const referenceId = serviceDefinition.reference_id || orderService.reference_id

  return RIDESHARE_VISUAL_INSPECTION_REFERENCE_ID === referenceId
}
