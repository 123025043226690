import React, { Component } from "react"
import { connect } from "react-redux"
import { constants } from "caradvise_shared_components"
import SplitClient, {
  CREATE_ACCOUNT_AFTER_SSO_LOGIN,
  ON
} from "../../../v1/components/Config/SplitClient"
import { withTranslation } from "react-i18next"
import UpdateDetailsModal from "../../../v1/components/Modal/UpdateDetailsModal"
import { me } from "../../../v1/actions/user"
import InstructionItemCards from "./InstructionItemCards"
import { Grid } from "semantic-ui-react"
import MembershipCardSummary from "./MembershipCardSummary"
import { CheckInDetails } from "./CheckInDetails"
import { ServicesSummary } from "./ServicesSummary"
import { CancellationPolicy } from "./CancellationPolicy"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { Redirect, withRouter } from "react-router-dom"
import ReactSVG from "react-svg"
import {
  BookingCompleted,
  BookingCompletedModal,
  BookingCompletedNotificationContainer
} from "./BookingCompleted"
import { Loader2 } from "../../../shared_component/components/Loader2"
import { EVENT_LISTENERS } from "../../../v1/constants/application"

const {
  orders: { SEEKING_QUOTE }
} = constants

class ReviewOrderIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialDataLoaded: false,
      open: false,
      openCompleteModal: true,
      windowWidth: window.innerWidth,
      navigationFlag: true
    }
    this.restrictNavigation = this.restrictNavigation.bind(this)
  }

  // TODO: refactor to have the backend send all needed information about the order in the
  // response of #createOrder to avoid this refetch of data with `#me`
  async componentDidMount() {
    const { order, me } = this.props
    this.setState({ initialDataLoaded: true })
    await me()
    window.addEventListener(EVENT_LISTENERS.RESIZE, this.updateWindowWidth)
    window.addEventListener(EVENT_LISTENERS.POPSTATE, this.handleBackButton)

    const isSSOLogin = await SplitClient.getTreatment(
      CREATE_ACCOUNT_AFTER_SSO_LOGIN,
      this.props.user
    )
    this.setState({ isSSOLogin: isSSOLogin[CREATE_ACCOUNT_AFTER_SSO_LOGIN] })
    this.setState({ isFixedPrice: order && order.is_fixed_price })
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT_LISTENERS.RESIZE, this.updateWindowWidth)
    window.removeEventListener(EVENT_LISTENERS.POPSTATE, this.handleBackButton)
  }

  restrictNavigation = () => {
    this.setState({ navigationFlag: false })
  }

  handleBackButton = (event) => {
    const { navigationFlag } = this.state
    event.preventDefault()
    if (navigationFlag) {
      routeChange(this.props.history, routerPaths.dashboard)
    }
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  closeModal = () => {
    const { order } = this.props
    this.setState({
      openCompleteModal: false
    })
    const bookingModalKey = order && order.id && `hasSeenBookingModal_${order.id}`
    localStorage.setItem(bookingModalKey, "true")
  }
  render() {
    const { order, user, loading, t, history, estimateEarnMiles } = this.props
    const { initialDataLoaded, isSSOLogin, openCompleteModal, windowWidth } = this.state
    const { is_password_changed_from_prompt, total_shop_orders } = user
    const bookingModalKey = order && order.id && `hasSeenBookingModal_${order.id}`
    const hasSeenBookingModal = order && order.id && localStorage.getItem(bookingModalKey)
    const { affiliation } = user
    if (user && !user.id) {
      return (
        <Redirect
          to={{
            pathname: routerPaths.dashboard
          }}
        />
      )
    }
    if (!order && !loading) return <p>{t("reviewOrder:shopNotFoundLbl")}</p>

    // Full page loader
    if (!initialDataLoaded || loading) return <Loader2 allowText={true} />
    return (
      <div className="review-order-index-container">
        {isSSOLogin &&
          isSSOLogin === ON &&
          user &&
          total_shop_orders &&
          !is_password_changed_from_prompt && (
            <UpdateDetailsModal open={!is_password_changed_from_prompt} />
          )}
        <div className="review-order-header-parent">
          <div className="review-order-header">
            <ReactSVG
              src="images/go_back_icon.svg"
              onClick={() => routeChange(history, routerPaths.dashboard)}
              style={{ cursor: "pointer" }}
            />
            <div className="review-order-header-name">{t("bookingConfirmation")}</div>
          </div>
        </div>
        <div>
          <Grid columns={2} centered>
            <Grid.Column width={6}>
              <div className="booking-details-container">
                <div className="booking-details-header">{t("bookingDetails")}</div>
                {affiliation && affiliation.uses_membership && <MembershipCardSummary />}
                <div className="horizontal-divider" />
                <CheckInDetails
                  cart={order}
                  history={history}
                  restrictNavigation={this.restrictNavigation}
                />
                <div className="horizontal-divider" />
                <ServicesSummary cart={order} user={user} estimateEarnMiles={estimateEarnMiles} />
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <InstructionItemCards />
            </Grid.Column>
          </Grid>
          {openCompleteModal && !hasSeenBookingModal && windowWidth > 500 && (
            <BookingCompletedNotificationContainer
              cart={order}
              user={user}
              onClose={() => this.closeModal()}
              estimateEarnMiles={estimateEarnMiles}
            />
          )}
        </div>
        {openCompleteModal && !hasSeenBookingModal && windowWidth <= 500 && (
          <BookingCompletedModal
            cart={order}
            user={user}
            estimateEarnMiles={estimateEarnMiles}
            onClose={() => this.closeModal()}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user

  let order =
    user.active_orders &&
    user.active_orders.filter((o) => o.id === parseInt(props.match.params.id))[0]
  if (!order)
    order =
      user.closed_orders &&
      user.closed_orders.filter((o) => o.id === parseInt(props.match.params.id))[0]

  return {
    user: user,
    loading: user.loading,
    order,
    estimateEarnMiles: state.rewards && state.rewards.estimateEarnMiles
  }
}

export default connect(mapStateToProps, { me })(
  withRouter(withTranslation(["bookingDetails", "reviewOrder"])(ReviewOrderIndex))
)
