import React, { useState } from "react"
import IframeComponent from "../../shared_component/components/IframeComponent"
import ReactSVG from "react-svg"
import GoBackLogo from "../../images/rewards/back.svg"
import { useTranslation } from "react-i18next"
import CarGIF from "../../images/gifs/car_animation.gif"
import { Image } from "semantic-ui-react"
import UnlockMilesInfoLogo from "../../images/rewards/unlock-miles-info.svg"

const ProviderIframe = ({ iframeURL, handleGoBack, providerName }) => {
  const { t } = useTranslation("chooseCoverage")

  const [isIframeLoading, setIsIframeLoading] = useState(true)

  const handleIframeLoad = () => {
    setIsIframeLoading(false)
  }

  return (
    <div className={`provider_iframe ${isIframeLoading && "provider_iframe__loading"}`}>
      {isIframeLoading && (
        <div className="iframe_loader">
          <Image src={CarGIF} />
          <p className="loading_provider">
            {t("loadingYour")} {providerName} {t("experience")}
          </p>
          <p className="go_back" onClick={handleGoBack}>
            {t("goBackToCarAdvise")}
          </p>
        </div>
      )}
      <div>
        {!isIframeLoading && (
          <div className="iframe_header">
            <div className="iframe_direction">
              <ReactSVG className="iframe_back" onClick={handleGoBack} src={GoBackLogo} />
              <p>{t("goBackToCarAdvise")}</p>
            </div>
            <div className="iframe_note">
              <ReactSVG src={UnlockMilesInfoLogo} className="iframe_info__logo" />
              <p className="iframe_note__text">{t("dataUsingNote")}</p>
            </div>
          </div>
        )}
        <IframeComponent
          handleIframeLoad={handleIframeLoad}
          url={iframeURL}
          height="900px"
          width="100%"
          className="iframContainer"
          title={providerName}
        />
      </div>
    </div>
  )
}

export default ProviderIframe
