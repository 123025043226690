import React, { useEffect, useState } from "react"
import SAFELITE_LOGO from "../../images/safelite.svg"
import CARADVISE_AUTOGLASS_LOGO from "../../images/carAdvise_autoglass.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Redirect } from "react-router-dom"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import InsuranceProviders from "./InsuranceProviders"
import CustomDropdown from "../../shared_component/elements/CustomDropdown"
import { fetchVehicleName, selectOptions } from "../helpers/vehicleHelpers"
import { DEFAULT_IMG_URL } from "../constants/carMileage"
import {
  AUTOGLASS_CONTACT,
  AUTOGLASS_EMAIL,
  AUTO_GLASS,
  AUTO_GLASS_NOW,
  BLANK_TARGET,
  CARADVISE_AUTOGLASS,
  CARADVISE_AUTOGLASS_URL,
  SAFELITE,
  WINSHIELD_REPAIR,
  WINSHIELD_REPLACEMENT
} from "../../shared_component/constants/autoglass"
import { Button } from "semantic-ui-react"
import ProviderIframe from "./ProviderIframe"
import { routerPaths } from "../constants/paths"
import { copyToClipboard } from "../../shared_component/utils/commonHelpers"
import { isMobile } from "react-device-detect"

function AutoGlass({ t, user, activeVehicle, vehicles, isLeftNavOpen }) {
  const [partnerFlag, setPartnerFlag] = useState()
  const [hasClicked, setHasClicked] = useState(false)

  const SAFELITE_BENEFITS = [t("safeliteBenefit1"), t("safeliteBenefit2"), t("safeliteBenefit3")]

  const AUTOGLASS_BENEFITS = [
    t("autoglassBenefit1"),
    t("autoglassBenefit2"),
    t("autoglassBenefit3")
  ]

  const AUTOGLASS = [
    {
      logo: CARADVISE_AUTOGLASS_LOGO,
      name: CARADVISE_AUTOGLASS,
      benefits: AUTOGLASS_BENEFITS,
      onClick: () => {},
      isReviewsAvailable: false,
      tagline: t("autoglassTagline"),
      poweredBy: t("autoglassPoweredBy"),
      onClick: (autoglass) => setHasClicked(true)
    },
    {
      logo: SAFELITE_LOGO,
      name: SAFELITE,
      benefits: SAFELITE_BENEFITS,
      onClick: () => {},
      isReviewsAvailable: false,
      tagline: t("safeliteTagline"),
      btnText: t("chooseCoverage:getReplacementQuote"),
      replacementUrl: WINSHIELD_REPLACEMENT,
      repairUrl: WINSHIELD_REPAIR,
      onClick: (autoglass) => openToNewWindow(autoglass.replacementUrl)
    }
  ]

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const openToNewWindow = (url) => {
    window.open(url, BLANK_TARGET)
  }

  const handleGoBack = () => {
    setHasClicked(false)
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to="/dashboard" />
      ) : (
        !hasClicked && (
          <div className="auto_glass">
            <div className="autoglass-heading">
              <Link to={routerPaths.offers}>
                <FontAwesomeIcon icon={faArrowLeft} />{" "}
              </Link>
              <h2>{t("header")}</h2>
            </div>
            <div className="vehicle_list">
              <p className="vehicle_label">{t("chooseCoverage:yourVehicle")}</p>
              <CustomDropdown
                options={selectOptions(vehicles)}
                isVehicleDropdown={true}
                defaultOption={{
                  text: fetchVehicleName(activeVehicle),
                  image: activeVehicle.dashboard_mobile_url
                    ? activeVehicle.dashboard_mobile_url
                    : DEFAULT_IMG_URL,
                  key: activeVehicle.id,
                  value: activeVehicle
                }}
                imageClass="default-vehicle-image"
              />
            </div>
            <div className={`auto__glass-card ${isLeftNavOpen && "media_auto__glass-card"}`}>
              {AUTOGLASS.map((autoglass) => (
                <InsuranceProviders
                  provider={autoglass}
                  benefits={autoglass.benefits}
                  image={autoglass.logo}
                  onClick={(providerData) => {
                    autoglass.onClick(providerData)
                  }}
                  providerName={autoglass.name}
                  isReviewsAvailable={autoglass.isReviewsAvailable}
                  isButtonDisabled={false}
                  tagline={autoglass.tagline}
                  poweredBy={autoglass.poweredBy}
                  dynamicFooterUI={
                    autoglass.name === CARADVISE_AUTOGLASS ? (
                      ""
                    ) : (
                      <Button
                        onClick={() => openToNewWindow(autoglass.repairUrl)}
                        className="repaire___quote-button"
                      >
                        {t("chooseCoverage:getRepairQuote")}
                      </Button>
                    )
                  }
                  module={AUTO_GLASS}
                />
              ))}
            </div>
          </div>
        )
      )}
      {hasClicked && (
        <ProviderIframe
          iframeURL={CARADVISE_AUTOGLASS_URL}
          handleGoBack={handleGoBack}
          providerName={AUTO_GLASS_NOW}
        />
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  const activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    user: user,
    activeVehicle,
    vehicles: user.vehicles,
    isLeftNavOpen: state.leftnav.navOpen
  }
}

export default connect(mapStateToProps)(withTranslation("autoGlass")(AutoGlass))
