import React from "react"
import { Button, Image, Modal } from "semantic-ui-react"
import WarningLogo from "../../../images/order_warning.svg"
import TireDetailConfirmScreen from "./TireDetailConfirmScreen"
import ShopDetailConfirmScreen from "./ShopDetailConfirmScreen"
import LoadingComponent from "../LoadingComponent"
import { withTranslation } from "react-i18next"
import { formatPhoneNumber } from "../../helpers/stringHelpers"
import { SUPPORT_CARADVISE_NUMBER } from "../../constants/application"

const ConfirmOrderDialog = ({ openOrderDialog, handleOrderDialog, orderDetails, t }) => {
  const {
    estimatedTotal,
    shopImage,
    appointmentTime,
    shopName,
    shopAddress,
    tireImage,
    createOrder,
    isBookingLoading,
    tireSize,
    tireName,
    milesRating
  } = orderDetails
  return (
    <Modal
      open={openOrderDialog}
      onClose={() => handleOrderDialog()}
      onOpen={() => handleOrderDialog()}
      className="order-dialog"
    >
      <Modal.Content>
        <div className="dialog-header">
          <Image className="order-warning-logo" src={WarningLogo} />
          <div className="dialog-header-label">
            <p className="header1">{t("placeOrder")}</p>
            <p className="header2">
              {t("needHelp")} {t("callUsAt")} {formatPhoneNumber(SUPPORT_CARADVISE_NUMBER)},{" "}
              {t("willCancel")}
            </p>
          </div>
        </div>
        <p className="total-service">{t("service")} (1)</p>
        <TireDetailConfirmScreen
          tireName={tireName}
          milesRating={milesRating}
          tireImage={tireImage}
          tireSize={tireSize}
        />
        <p className="appointment">{t("appointment")}</p>
        <ShopDetailConfirmScreen
          shopImage={shopImage}
          appointmentTime={appointmentTime}
          shopName={shopName}
          shopAddress={shopAddress}
        />
        <div className="price-container">
          <div className="price-label">
            <p>{t("estimatedTotal")}</p>
            <p>{estimatedTotal}</p>
          </div>
          <p className="cancellation-available-note">{t("cancellationAvailable")}</p>
        </div>
        <div className="order-action">
          {!isBookingLoading && (
            <Button className="order-undo-btn" onClick={handleOrderDialog}>
              {t("undo")}
            </Button>
          )}
          <Button
            disabled={isBookingLoading}
            className={"order-dialog-button"}
            onClick={() => {
              handleOrderDialog()
              createOrder()
            }}
          >
            {isBookingLoading ? <LoadingComponent flexDirection="unset" /> : t("confirm")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default withTranslation("confirmOrderDialog")(ConfirmOrderDialog)
