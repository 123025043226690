import React from "react"
import ReactSVG from "react-svg"
import CompleteServiceLogo from "../../../images/rewards/complete-service.svg"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"
import { useTranslation } from "react-i18next"

const RewardsServiceCard = ({ service }) => {
  const { t } = useTranslation("rewards")

  return (
    <div className="rewards-services-card-container">
      <ReactSVG src={CompleteServiceLogo} className="rewards-services-logo" />
      <div className="rewards-service-detail">
        <p className="rewards-service-name">{service.name}</p>
        <p className="rewards-service-label">{service.description}</p>
      </div>
      <p className="reward-service-miles">
        {service.miles ? numberFormater(service.miles) : 0} {t("miles")}
      </p>
    </div>
  )
}

export default RewardsServiceCard
