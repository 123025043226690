import React, { useState, useEffect } from "react"
import { Form, Image, Input } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { connect } from "react-redux"
import { saveUserDetails } from "../actions/confirmDetails"
import { DATE, MILEAGE, DEFAULT_IMG_URL } from "../constants/confirmDetails"
import { withTranslation } from "react-i18next"
import ReactDatePicker from "react-datepicker"
import { updateVehicleData } from "../actions/vehicles"
import { getUserDetails, updateUserDetails } from "../actions/user"
import { Redirect } from "react-router-dom"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import VIN_Car_Img from "../../images/VIN_Car_Img.png"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { isCanada } from "../helpers/translationHelpers"

const ConfirmDetails = ({
  user,
  coverageDate,
  coverageMileage,
  saveUserDetails,
  t,
  updateVehicleData,
  getUserDetails,
  updateUserDetails,
  history,
  isLeftNavOpen
}) => {
  const [mileage, setMileage] = useState()
  const [zipCode, setZipCode] = useState()
  const [vin, setVin] = useState()
  const [nextMileage, setNextMileage] = useState()
  const [coverageTime, setCoverageTime] = useState(coverageMileage ? MILEAGE : DATE)
  const [expirationDate, setExpirationDate] = useState(new Date())
  const [userData, setUserData] = useState()
  const [expirationMileage, setExpirationMileage] = useState()
  const [errorMileage, setErrorMileage] = useState(false)
  const [errorZip, setErrorZip] = useState(false)
  const [errorVIN, setErrorVIN] = useState(false)
  const [errorNextMileage, setErrorNextMileage] = useState(false)
  const [partnerFlag, setPartnerFlag] = useState()
  const [isVINDisabled, setIsVINDisabled] = useState()
  const [isDisplay, setIsDisplay] = useState("d_none")
  const [vinErrorMsg, setVinErrorMsg] = useState("")

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const handleOptionChange = (e) => {
    setCoverageTime(e.target.value)
    if (e.target.value === DATE) {
      setErrorNextMileage(false)
    } else {
      setErrorNextMileage(true)
    }
  }

  const _getUserDetails = async () => {
    const response = await getUserDetails()
    if (response && response.result) {
      setUserData(response.result)
      setMileage(response.result.active_vehicle.miles)
      setZipCode(response.result.zip)
      setVin(response.result.active_vehicle.vin)
      setErrorVIN(response.result.active_vehicle.vin ? false : true)
      setVinErrorMsg(response.result.active_vehicle.vin ? "" : t("Enter_VIN_Number"))
      setIsVINDisabled(response.result.active_vehicle.vin ? true : false)
    }
  }

  useEffect(() => {
    _getUserDetails()
  }, [])

  const handleMileage = (e) => {
    if (e.target.value === 0 || e.target.value === "") {
      setErrorMileage(true)
    } else {
      setMileage(e.target.value)
      setErrorMileage(false)
    }
  }

  const handleNextMileage = (e) => {
    if (e.target.value === "" || e.target.value === 0 || e.target.value < mileage) {
      setErrorNextMileage(true)
    } else {
      setNextMileage(e.target.value)
      setErrorNextMileage(false)
    }
  }

  const handleZipCode = (e) => {
    if (
      e.target.value === 0 ||
      e.target.value === "" ||
      (isCanada(user.country) ? e.target.value.length != 7 : e.target.value.length != 5)
    ) {
      setErrorZip(true)
      setZipCode(e.target.value)
    } else if (!isCanada(user.country) && e.target.value.match(/^[0-9]+$/) == null) {
      setErrorZip(true)
      setZipCode(e.target.value)
    } else {
      setZipCode(e.target.value)
      setErrorZip(false)
    }
  }

  const handleVINNumber = (e) => {
    if (e.target.value === 0 || e.target.value === "") {
      setErrorVIN(true)
      setVinErrorMsg(t("Enter_VIN_Number"))
    } else if (e.target.value.length !== 17) {
      setErrorVIN(true)
      setVinErrorMsg(t("VIN_length_error"))
    } else {
      setVin(e.target.value)
      setErrorVIN(false)
      setVinErrorMsg("")
    }
  }

  const handleNextBtnClick = async (e) => {
    if (!zipCode) {
      return
    }
    let responseData
    if (
      userData.active_vehicle.miles !== mileage ||
      userData.zip !== zipCode ||
      userData.active_vehicle.vin !== vin
    ) {
      const data = {
        vehicle_id: userData.active_vehicle.id,
        zip: zipCode,
        mileage: mileage,
        vin: vin,
        language: user.language
      }
      responseData = await updateUserDetails(data)
    }
    if (responseData && responseData.error) {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: responseData.error[0],
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      saveUserDetails(expirationDate, expirationMileage)
      if (responseData && responseData.result.active_vehicle) {
        if (userData.active_vehicle.miles != mileage || userData.active_vehicle.vin != vin) {
          updateVehicleData({
            vin: responseData.result.active_vehicle.vin,
            vehicleId: responseData.result.active_vehicle.id,
            miles: responseData.result.active_vehicle.miles,
            milesPerMonth: responseData.result.active_vehicle.miles_per_month,
            licensePlateNumber: responseData.result.active_vehicle.license_plate_number,
            licensePlateState: responseData.result.active_vehicle.license_plate_state
          })
        }
      }
      setIsVINDisabled(true)
      history.push({
        pathname: "/WarrantyPlan",
        state: {
          mileage: mileage && mileage,
          zipCode: zipCode && zipCode,
          expirationDate: expirationDate ? expirationDate : "",
          expirationMileage: expirationMileage ? expirationMileage : ""
        }
      })
    }
  }

  const handleClose = (e) => {
    setIsDisplay("d_none")
  }

  const handleOpen = (e) => {
    setIsDisplay("d_flex")
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to="/dashboard" />
      ) : (
        <div className="confirm-section">
          <div className="confirmDetails">
            <div className="step-title">
              <Link to="/offers">
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h3>
                {t("Step_I")}: {t("Confirm_Details")}
              </h3>
            </div>
            <div className={`form-parent ${isLeftNavOpen && "form-parent-container"}`}>
              <Form>
                <p>{t("Step_I")}</p>
                <h3>{t("Confirm_Details")}</h3>
                <hr className="horizontal-line" />
                <div className="input-fields">
                  <div className="field-parent">
                    <label>{t("Zip_Code")}*</label>
                    <Input
                      maxLength={isCanada(user.country) ? 7 : 5}
                      name="zip"
                      type="text"
                      onChange={handleZipCode}
                      defaultValue={zipCode && zipCode}
                      onKeyUp={handleZipCode}
                    />
                    {!Boolean(zipCode) ? (
                      <p className="require-text">{t("enterZipcode")}</p>
                    ) : errorZip ? (
                      <p className="require-text">{t("Invalid_ZipCode")}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="field-parent">
                    <label>{t("VIN_Number")}</label>
                    <Input
                      type="text"
                      onChange={handleVINNumber}
                      defaultValue={vin && vin}
                      onKeyUp={handleVINNumber}
                      disabled={isVINDisabled}
                    />
                    {errorVIN ? <p className="require-text">{vinErrorMsg}</p> : ""}
                    <p className="require-text cursor-pointer" onClick={handleOpen}>
                      {t("Where_can_I_find_the_VIN")}
                    </p>
                  </div>
                  <div className="field-parent mileage-field">
                    {/* <label>{t("Coverage_Time")}</label> */}
                    <div className="mileage-box">
                      {/* Eric suggested to remove this field so making comment this code */}
                      {/* <div className="mileage-field-section">
                        <div className="d-flex">
                          <label className="radio-btn mr-10 label-padding">
                            <input
                              type="radio"
                              name="date"
                              value={DATE}
                              checked={coverageTime === DATE}
                              onChange={handleOptionChange}
                              className="radio-padding"
                            />
                            {t("Date")}
                          </label>
                          <label className="radio-btn">
                            <input
                              type="radio"
                              name="mileage"
                              value={MILEAGE}
                              checked={coverageTime === MILEAGE}
                              onChange={handleOptionChange}
                              className="radio-padding"
                            />
                            {t("Mileage")}
                          </label>
                        </div>
                        {coverageTime === DATE ? (
                          <ReactDatePicker
                            selected={expirationDate}
                            onChange={(date) => setExpirationDate(date)}
                            minDate={new Date()}
                            placeholderText="dd/mm/yyyy"
                          />
                        ) : (
                          <div>
                            <Input
                              type="number"
                              onChange={handleNextMileage}
                              defaultValue={nextMileage}
                              onKeyUp={handleNextMileage}
                            />
                            {errorNextMileage ? (
                              <p className="require-text">{t("mileage_should_greater_than")}</p>
                            ) : null}
                          </div>
                        )}
                      </div> */}
                      {/* <div className="field-parent mileage-field-section mileage-margin">
                        <label className="mileage-label">
                          {t("CurrentMileage")}* &nbsp;<span>{t("only_numbers_no_symbols")}</span>
                        </label>
                        <Input
                          type="number"
                          onChange={handleMileage}
                          defaultValue={mileage && mileage}
                          onKeyUp={handleMileage}
                        />
                        {errorMileage ? (
                          <p className="require-text">{t("Invalid_Mileage")}</p>
                        ) : null}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="btn-parent">
                  <button
                    className="choose-plan-btn"
                    onClick={handleNextBtnClick}
                    disabled={
                      errorMileage || errorZip || errorNextMileage || errorVIN ? true : false
                    }
                  >
                    {t("Next")}: {t("Choose_Plan")}
                  </button>
                </div>
              </Form>
              <div className={`${isLeftNavOpen && "protection-plan-desc-container"}`}>
                <div className="protection-plan-desc">
                  <div className="protection-plan-detail">
                    <h3>💡 {t("Why_consider_protection_plan")}</h3>
                    <p>{t("Confirm_details_desc")}</p>
                  </div>
                </div>
                <div className="img-parent">
                  <span className="img-strip">
                    <Image
                      className="car_img"
                      src={
                        userData &&
                        userData.active_vehicle.images &&
                        userData.active_vehicle.images[0]
                          ? userData.active_vehicle.images[0].url
                          : DEFAULT_IMG_URL
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`VIN-modal ${isDisplay}`} onClick={handleClose}>
            <div className="VIN-details-container">
              <div className="VIN-content">
                <h1>{t("Where_can_I_find_the_VIN")}</h1>
                <p className="description">{t("VIN_Description")}</p>
              </div>
              <div className="Car-VIN-position">
                <Image src={VIN_Car_Img} />
              </div>
              <button className="got-it-btn" onClick={handleClose}>
                {t("Got_it_btn")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let coverageDate = state.saveDetails.coverageDate && state.saveDetails.coverageDate
  let coverageMileage = state.saveDetails.coverageMileage && state.saveDetails.coverageMileage
  let isLeftNavOpen = state.leftnav && state.leftnav.navOpen
  return {
    user: user,
    coverageDate: coverageDate,
    coverageMileage: coverageMileage,
    isLeftNavOpen: isLeftNavOpen
  }
}

export default connect(mapStateToProps, {
  saveUserDetails,
  updateVehicleData,
  getUserDetails,
  updateUserDetails
})(withTranslation("confirmDetails")(ConfirmDetails))
