import React, { PureComponent } from "react"
import { Membership } from "caradvise_shared_components"
import { hideChrome, showChrome } from "../../actions/framework"
import { connect } from "react-redux"
import { getStyles } from "../Config/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { getGoogleWalletUrl, getAppleWalletUrl } from "../../actions/membership"
import { isAndroid, isIOS, isDesktop, isMacOs } from "react-device-detect"

import printer from "../../../images/membershippanel/Printer-Icon.png"
import MembershipCardFrontFormat from "./MembershipCardFrontFormat"
import MembershipCardBackFormat from "./MembershipCardBackFormat"
import infoImg from "../../../images/membershippanel/Vector.svg"
import googlePay from "../../../images/membershippanel/googlePay.svg"
import applePay from "../../../images/membershippanel/applePay.png"
import SplitClient, {
  MEMBERSHIP_CARD_FEATURE,
  OFF,
  ON,
  setupSplitFlags
} from "../Config/SplitClient"
import { getSubdomain } from "../Config/StylesheetInjector"
import { BASIC, PREMIUM, ELITE } from "../../../v1/constants/membershipPanel"
import ReactToPrint from "react-to-print"
import LoadingComponent from "../LoadingComponent"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { APP_NAME } from "../../../shared_component/constants/common"
const styles = getStyles()
class MembershipPanel extends PureComponent {
  constructor(props) {
    super(props)
    const { t } = props
    this.state = {}
    this.myRef = React.createRef()
  }

  async componentDidMount() {
    const { user, getGoogleWalletUrl, getAppleWalletUrl } = this.props
    const membershipCardFeatureFlag = await SplitClient.getTreatments(
      [MEMBERSHIP_CARD_FEATURE],
      user,
      { app_name: APP_NAME }
    )

    await this.setState({
      isMembershipCardFeatureFlag: membershipCardFeatureFlag[MEMBERSHIP_CARD_FEATURE]
    })
    if (user && user.authentication_token) {
      await getAppleWalletUrl()
      await getGoogleWalletUrl()
    }
  }

  getPlanDetails = (planType, membershipPlans) => {
    const { t } = this.props
    if (membershipPlans || membershipPlans.length !== 0) {
      switch (planType) {
        case t("elite"):
          return membershipPlans[2]
        case t("premium"):
          return membershipPlans[1]
        default:
          return membershipPlans[0]
      }
    }
  }

  render() {
    const {
      t,
      user,
      availableMembershipPlans,
      vehicles,
      isLoggedIn,
      appleWalletUrl,
      googleWalletUrl
    } = this.props
    const plan =
      user && user.membership && user.membership.plan_name === ELITE
        ? t("elite")
        : user && user.membership && user.membership.plan_name === PREMIUM
        ? t("premium")
        : t("basic")

    const bg = plan === t("elite") ? "#2e2e2e" : plan === t("premium") ? "#F1F3F9" : "#FFFFFF"
    const clr = plan === t("elite") ? "#FFFFFF" : plan === t("premium") ? "#1D1D1D" : "#343434"

    const planDetails = this.getPlanDetails(plan, availableMembershipPlans)
    const vehicleCount = planDetails && planDetails.max_num_vehicles
    const discount = planDetails && planDetails.percent_discount

    return (
      <React.Fragment>
        {this.state.isMembershipCardFeatureFlag !== undefined && isLoggedIn ? (
          <>
            {this.state.isMembershipCardFeatureFlag &&
            this.state.isMembershipCardFeatureFlag === ON ? (
              <div className="main-container1">
                <div className="inner-container1">
                  <div
                    ref={(el) => (this.myRef = el)}
                    className="membership-card-full"
                    style={{ padding: "2%" }}
                  >
                    <MembershipCardFrontFormat
                      user={user}
                      plan={plan}
                      vehicles={vehicles}
                      bg={bg}
                      clr={clr}
                    />
                    <div className="page-break"></div>
                    <MembershipCardBackFormat
                      user={user}
                      plan={plan}
                      vehicles={vehicles}
                      bg={bg}
                      clr={clr}
                    />
                  </div>
                  <div className="wallet">
                    <div className="wallet-apple">
                      <a
                        href={isIOS || (isDesktop && isMacOs) ? appleWalletUrl : ""}
                        target="_blank"
                      >
                        <div className="wallet-apple-inner-div">
                          {isIOS || (isDesktop && isMacOs) ? (
                            <img
                              onClick={() => {
                                trackEvent("membership-add-to-wallet-clicked", {
                                  walletType: "apple",
                                  planType: (plan && plan.toLowerCase()) || null
                                })
                              }}
                              style={{ width: "48%", alignSelf: "center" }}
                              src={applePay}
                            ></img>
                          ) : (
                            ""
                          )}
                        </div>
                      </a>
                      <div style={{ marginTop: "5%", width: "100%" }}>
                        <a
                          style={{ alignSelf: "center", marginTop: "5%", width: "80%" }}
                          href={isAndroid || isDesktop ? googleWalletUrl : ""}
                          target="_blank"
                        >
                          <div className="wallet-google-inner-div">
                            {isAndroid || isDesktop ? (
                              <img
                                onClick={() => {
                                  trackEvent("membership-add-to-wallet-clicked", {
                                    walletType: "google",
                                    planType: (plan && plan.toLowerCase()) || null
                                  })
                                }}
                                style={{ width: "75%" }}
                                src={googlePay}
                              ></img>
                            ) : (
                              ""
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{height:"100%"}}> */}
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <a href="#/membership" style={{ height: "100%" }}>
                          <button className="membership-print-button">
                            {t("printMemberCard")}
                          </button>
                        </a>
                      )
                    }}
                    content={() => this.myRef}
                  />
                  {/* </div> */}
                </div>
                <div className="right-container1">
                  <p className="align-left-style">{t("descriptionText1")}</p>
                  <p className="align-left-style">{t("descriptionText2")}</p>
                  <p className="align-left-style">{t("descriptionText3")}</p>
                  <p className="align-left-style">{t("descriptionText4")}</p>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginBottom: "-2%"
                      }}
                    >
                      <img src={infoImg}></img>
                      <p className="membership-right-container-header">{`${
                        (user && user.membership && user.membership.plan_name) ||
                        t("membershipCard:basic")
                      }  ${t("membership")}`}</p>
                    </div>
                    {plan == t("elite") ? (
                      <ul style={{ textAlign: "left" }}>
                        {discount && (
                          <li>
                            {` ${t("additional")} ${discount.split(".")[0]}% ${t(
                              "planDiscountDescription"
                            )}`}
                          </li>
                        )}
                        <li>{t("elitePlanDescription1")}</li>

                        <li>{t("elitePlanDescription2")}</li>
                        {vehicleCount && <li>{`${vehicleCount} ${t("vehiclesPerAccountLbl")}`}</li>}
                      </ul>
                    ) : plan == t("premium") ? (
                      <ul style={{ textAlign: "left" }}>
                        {discount && (
                          <li>
                            {` ${t("additional")} ${discount.split(".")[0]}% ${t(
                              "planDiscountDescription"
                            )}`}
                          </li>
                        )}
                        <li>{` ${t("premiumPlanDescription2")}`}</li>
                        {vehicleCount && <li>{`${vehicleCount} ${t("vehiclesPerAccountLbl")}`}</li>}
                      </ul>
                    ) : (
                      <ul style={{ textAlign: "left" }}>
                        <li>{t("basicPlanDescription1")}</li>
                        <li>{t("basicPlanDescription2")}</li>
                        {vehicleCount && (
                          <li>{`${vehicleCount} ${t("vehiclesPerAccountLbl-basic")}`}</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="dashLeftContainer">
                <Membership.MembershipPanel
                  logo={styles.logoWhiteOrange}
                  primaryColor={styles.primaryColor}
                  logoWidth={styles.logoWidth}
                  language={user && user.language.includes("fr") ? "fr" : "en"}
                  apple_wallet_url={isIOS || (isDesktop && isMacOs) ? appleWalletUrl : ""}
                  google_wallet_url={isAndroid || isDesktop ? googleWalletUrl : ""}
                  gradient={{
                    start: "#5abaff",
                    mid: "#2da5ff",
                    end: "#008bff"
                  }}
                  {...this.props}
                >
                  <p>{t("descriptionText1")}</p>
                  <p>{t("descriptionText2")}</p>
                  <p>{t("descriptionText3")}</p>
                  <p>{t("descriptionText4")}</p>
                </Membership.MembershipPanel>
              </div>
            )}
          </>
        ) : (
          <div className="spinnerContainer">
            <div>
              <div className="spinnerText">
                <LoadingComponent />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  const vehicles = user.vehicles || []
  const vehicle = vehicles.find((v) => v.id === user.activeVehicleId) || vehicles[0]
  const name = [user.firstName, user.lastName].filter((n) => n != null).join(" ")
  const availableMembershipPlans =
    state.membershipData && state.membershipData.availableMembershipPlans
  const isLoggedIn = !!user.authentication_token
  const googleWalletUrl = state.membershipData && state.membershipData.googleWalletKeyUrl
  const appleWalletUrl = state.membershipData && state.membershipData.appleWalletKeyUrl

  return {
    name,
    vehicle,
    user,
    availableMembershipPlans,
    isLoggedIn,
    googleWalletUrl,
    appleWalletUrl
  }
}

export default connect(mapStateToProps, {
  hideChrome,
  showChrome,
  getGoogleWalletUrl,
  getAppleWalletUrl
})(withTranslation("membershipPanel")(MembershipPanel))
