import React, { PureComponent } from "react"
import { connect } from "react-redux"
import store from "../../shared_component/utils/configureStore"
import { logoutUser, getUserDetails, updateInfo } from "../../v1/actions/user"
import { withRouter, Link } from "react-router-dom"
import { Menu, Dropdown, Responsive, Image, Input, Grid, Header } from "semantic-ui-react"
import { nextMembershipUpgrades } from "../../v1/helpers/membershipHelpers"
import MembershipUpgradeModal2_0 from "./Membership2.0/MembershipUpgradeModal"
import { withTranslation } from "react-i18next"
import { Row } from "react-grid-system"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { getAdditionalProps } from "../../v1/helpers/segmentHelper"
import User from "../../images/user.png"
import downArrow from "../../images/down_arrow.png"
import dollorLabelLogo from "../../images/dollor_label_vector.svg"
import LocationLogo from "../../images/location_vector.svg"
import _, { isEmpty } from "lodash"
import { ZIP_CODE_REGEX, POSTAL_CODE_REGEX } from "../../shared_component/utils/stringHelpers"
import { getSubdomain } from "../../v1/components/Config/StylesheetInjector"
import ReactSVG from "react-svg"
import Notifications from "../../v1/components/Notifications"
import MiniLoader from "../../v1/components/MiniLoader"
import SearchServices from "./Search/SearchServices"
import CAIcon from "../../images/CA-Icon.svg"
import BackIcon from "../../images/left-arrow-icon-red.svg"
import ArrowLeft from "../../images/go_back_icon_white.svg"
import VerticalDivider from "../../images/dashboard/header-divider.svg"
import { routeChange, routerPaths } from "../../v1/constants/paths"
import DashboardStepper from "./DashboardStepper"
import ShopInformation from "./ShopInformation"
export const CART_ICON_ID = "toggle-cart"

class DashHeader_v2 extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef(null)
    this.state = {
      open: false,
      membershipModalOpen: false,
      isEditing: false,
      isSubmitting: false,
      fields: {
        zip: {
          name: "zipCode",
          value: props.zip || "",
          invalid: false,
          validators: ["_isPresent", "_isZipCodeValid"]
        }
      }
    }
    this.toggleCart = this.toggleCart.bind(this)
    this.onRouteChanged = this.onRouteChanged.bind(this)
  }

  toggleCart(event, fromState) {
    if (fromState === undefined) fromState = this.state.cartOpen
    let newState = !fromState
    const types = [".dashLeftContainer", ".content", ".dashLeft", ".container", ".generic-panel"]
    if (newState) {
      let divs
      types.map((type) => {
        divs = document.querySelectorAll(type)
        const start = type === ".container" ? 1 : 0
        for (let i = start; i < divs.length; i++) {
          divs[i].classList.add("shopping-cart-open")
        }
      })
      document.querySelectorAll(".dashRight .cart-panel")[0].classList.add("visible")
    } else {
      let divs
      types.map((type) => {
        divs = document.querySelectorAll(type)
        const start = type === ".container" ? 1 : 0
        for (let i = 0; i < divs.length; i++) {
          divs[i].classList.remove("shopping-cart-open")
        }
      })
      divs = document.querySelectorAll(".cart-panel")
      for (let i = 0; i < divs.length; i++) {
        divs[i].classList.remove("visible")
      }
    }

    this.setState({ cartOpen: newState })
  }

  componentWillUpdate(prevProps) {
    // if (this.props.location !== prevProps.location) {
    //   this.onRouteChanged()
    // }
    if (this.props.zip !== prevProps.zip) {
      this.setState({
        fields: {
          zip: {
            name: "zipCode",
            value: prevProps.zip || "",
            invalid: false,
            validators: ["_isPresent", "_isZipCodeValid"]
          }
        }
      })
    }
  }

  onRouteChanged() {
    this.toggleCart(null, true)
  }

  toggleMembershipUpgradeModal = () => {
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  routeChange(path) {
    this.props.history.push(path)
  }

  saveDetails = () => {
    const { user, t } = this.props
    const { fields } = this.state
    const { zip } = fields
    if (zip.value.length < 5) {
      this._validateZipCode()
      this.setState({
        isEditing: false,
        fields: {
          zip: {
            value: user.zip
          }
        }
      })
      return
    }
    this._validateFields(async () => {
      this.setState({ isSubmitting: true })
      if (this.props.zip !== zip.value) {
        const response = await this.props.updateInfo({
          zip: zip.value
        })
        if (response === true) {
          await identifyEvent({
            traits: {
              zip: fields.zip.value
            }
          })
          trackEvent("profile-updated", {
            zip: fields.zip.value
          })
          this.showAlert(t("infoUpdatedAlertMessage"))
        }
      }
      this.setState({ isEditing: false, isSubmitting: false })
    })
  }

  render() {
    let {
      cart,
      activeOrder,
      isLoggedIn,
      isGeneric,
      nextUpgrades,
      accountTierName,
      t,
      firstName,
      lastName,
      email,
      hideChrome,
      user,
      activeVehicle,
      history
    } = this.props
    const { membershipModalOpen, open, fields, isEditing, isSubmitting } = this.state
    const { zip } = fields
    const hasFirstName = firstName != null
    const hasLasttName = lastName != null
    const hasFirstAndLastName = hasFirstName && hasLasttName
    const currentUserName = hasFirstAndLastName ? firstName + " " + lastName : ""

    if (hideChrome) return null

    let count =
      !activeOrder && cart && cart.order_services && cart.order_services.length > 0
        ? cart.order_services.length
        : null
    if (activeOrder) count = "!"
    const svgStyle = { width: 25, height: 25, color: "#FFFFFF" }
    const shortened = currentUserName
      .split(" ")
      .map((s) => (s[0] ? s[0].toUpperCase() : null))
      .join("")
      .slice(0, 2)
    const routes = ["/password", "/?reset_password_token"]
    const isResetPassword = routes.some((route) => window.location.href.includes(route))
    return (
      <div>
        <Responsive minWidth={540}>
          {!isResetPassword && (
            <div className="site-header_v2">
              <div className="borderless massive ui header-width">
                {isLoggedIn && !isGeneric && (
                  <Row className="header-parent">
                    <div className="headerLogo-white" />
                    <Menu.Item>
                      <div className="partnerLogo" />
                    </Menu.Item>
                    {isLoggedIn && !isGeneric && (
                      <Menu.Menu className="header-main-section" position="right">
                        <ShopInformation t={t} />
                        <div className="zip-container">
                          <Input
                            transparent
                            iconPosition="left"
                            placeholder={t("zipFieldKey")}
                            value={zip.value}
                            className={`zip-input ${isEditing ? "editing" : ""}`}
                            ref="zipCode"
                            onClick={() => this.onEditing()}
                            onChange={(value) => this._onFieldChange("zip", value.target.value)}
                            onBlur={() => this.saveDetails()}
                          >
                            <Image src={LocationLogo} />
                            <input />
                          </Input>
                        </div>
                        <Image src={VerticalDivider} />
                        <div className="user">
                          <div
                            className="user-details"
                            onClick={() => this.setState({ open: !open })}
                          >
                            <img src={User} />
                            <div className="user-name">{currentUserName}</div>
                            <img src={downArrow} />
                          </div>
                          <div className="user-details-content">
                            {accountTierName ? (
                              <span className="tier-banner_v2">
                                {accountTierName} {t("accountLbl")}
                              </span>
                            ) : (
                              <span className="tier-banner_v2">
                                {t("membershipCard:basic")} {t("accountLbl")}
                              </span>
                            )}
                            {nextUpgrades.length > 0 && (
                              <span
                                className="upgradeModalLink"
                                onClick={this.toggleMembershipUpgradeModal}
                              >
                                {t("upgradeAccountLbl")}
                              </span>
                            )}
                          </div>
                          {membershipModalOpen && (
                            <MembershipUpgradeModal2_0
                              isOpen={membershipModalOpen}
                              closeModal={this.toggleMembershipUpgradeModal}
                              variableContentType="settingsSection"
                              showUpgradeBenefits={true}
                            />
                          )}
                        </div>
                        <div
                          className={`profile_modal ${open ? "active" : "inactive"}`}
                          onClick={() => this.setState({ open: !open })}
                        ></div>
                        <div
                          className={`profile-dropdown ${open ? "active" : "inactive"}`}
                          ref={this.myRef}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingLeft: "16px"
                            }}
                          ></div>
                          <Dropdown.Item onClick={() => this.routeChange("/myaccount")}>
                            <Link
                              className={`profile-link ${open ? "active" : "inactive"}`}
                              to="/myaccount"
                              onClick={() => {
                                trackEvent("my-account-clicked", { ...getAdditionalProps() })
                                this.setState({ open: !open })
                              }}
                            >
                              {t("myAccountLbl")}
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.routeChange("/membership")
                              this.setState({ open: !open })
                            }}
                          >
                            <Link
                              className={`profile-link ${open ? "active" : "inactive"}`}
                              to="/membership"
                            >
                              {t("membershipCardLbl")}
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => store.dispatch(logoutUser())}>
                            <Link
                              className={`profile-link ${open ? "active" : "inactive"}`}
                              to="/signin"
                              onClick={() => {
                                store.dispatch(logoutUser())
                                this.setState({ open: !open })
                              }}
                            >
                              {t("signoutLbl")}
                            </Link>
                          </Dropdown.Item>
                        </div>
                      </Menu.Menu>
                    )}
                  </Row>
                )}
                {!/checkout|reschedule-appointment/.test(window.location.href) && (
                  <Grid stackable columns="equal" className="search-services-header">
                    <Grid.Row>
                      <Grid.Column>
                        <div className="dollor-label">
                          <div className="dollor-label-description">
                            <Image src={dollorLabelLogo} />
                            <span className="secondary-font always-better-label">
                              {t("alwaysBetterLabel")}
                            </span>
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <div className="service-search-bar">
                          <SearchServices user={user} />
                        </div>
                      </Grid.Column>
                      <Grid.Column floated="right"></Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </div>
            </div>
          )}
        </Responsive>
        <Responsive maxWidth={539}>
          {!isResetPassword && (
            <div className="site-header_v2">
              {!/checkout/.test(window.location.href) ? (
                <>
                  <Row className="header-parent">
                    <div className="header-content">
                      <Image src={CAIcon} className="header-logo-icon" />
                      <div className="header-main-section">
                        <ShopInformation t={t} />
                        <div className="zip-container">
                          <Input
                            transparent
                            iconPosition="left"
                            placeholder={t("zipFieldKey")}
                            value={zip.value}
                            className="zip-input"
                            ref="zipCode"
                            onClick={() => this.onEditing()}
                            onChange={(value) => this._onFieldChange("zip", value.target.value)}
                            onBlur={() => this.saveDetails()}
                          >
                            <Image src={LocationLogo} />
                            <input />
                          </Input>
                        </div>
                        <div>
                          {isLoggedIn && !isGeneric && (
                            <Dropdown
                              className="circle"
                              item
                              text={<span style={{ padding: "inherit" }}>{shortened}</span>}
                              icon={null}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Header>
                                  <Header>{currentUserName}</Header>
                                  {accountTierName ? (
                                    <span className="tier-banner_v2">
                                      {accountTierName} {t("accountLbl")}
                                    </span>
                                  ) : (
                                    <span className="tier-banner_v2">
                                      {t("membershipCard:basic")} {t("accountLbl")}
                                    </span>
                                  )}
                                  {nextUpgrades.length > 0 && (
                                    <span
                                      className="upgradeModalLink"
                                      onClick={this.toggleMembershipUpgradeModal}
                                    >
                                      {t("upgradeAccountLbl")}
                                    </span>
                                  )}
                                </Dropdown.Header>
                                <Dropdown.Item onClick={() => this.routeChange("/myaccount")}>
                                  <Link
                                    className={`profile-link ${open ? "active" : "inactive"}`}
                                    to="/myaccount"
                                    onClick={() => {
                                      trackEvent("my-account-clicked", { ...getAdditionalProps() })
                                      this.setState({ open: !open })
                                    }}
                                  >
                                    {t("myAccountLbl")}
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    this.routeChange("/membership")
                                    this.setState({ open: !open })
                                  }}
                                >
                                  <Link
                                    className={`profile-link ${open ? "active" : "inactive"}`}
                                    to="/membership"
                                  >
                                    {t("membershipCardLbl")}
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => store.dispatch(logoutUser())}>
                                  <Link
                                    className={`profile-link ${open ? "active" : "inactive"}`}
                                    to="/signin"
                                    onClick={() => {
                                      store.dispatch(logoutUser())
                                      this.setState({ open: !open })
                                    }}
                                  >
                                    {t("signoutLbl")}
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                          {membershipModalOpen && (
                            <MembershipUpgradeModal2_0
                              isOpen={membershipModalOpen}
                              closeModal={this.toggleMembershipUpgradeModal}
                              variableContentType="settingsSection"
                              showUpgradeBenefits={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="search-header-parent">
                    <div className="book-service-label">{t("bookServiceLabel")}</div>
                    <div className="search-services-header">
                      <div className="service-search-bar">
                        <SearchServices user={user} />
                      </div>
                    </div>
                  </Row>
                </>
              ) : (
                <Row className="header-parent">
                  <div className="header-parent-mobile-section">
                    <ReactSVG
                      onClick={() => {
                        routeChange(history, routerPaths.cartSummary)
                      }}
                      src={ArrowLeft}
                    />
                    <div className="header-parent-title">{t("completeBookingLabel")}</div>
                    <div
                      className="header-parent-cancel-btn"
                      onClick={() => {
                        routeChange(history, routerPaths.dashboard)
                      }}
                    >
                      {t("cancelBtn")}
                    </div>
                  </div>
                </Row>
              )}
              {/reschedule-appointment/.test(window.location.href) && (
                <Row className="reschedule-header-parent">
                  <Image
                    src={BackIcon}
                    onClick={() => routeChange(this.props.history, routerPaths.dashboard)}
                    className="back-icon-header"
                  />
                  <div className="reschedule-service-label">{t("Reschedule Appointment")}</div>
                </Row>
              )}
            </div>
          )}
        </Responsive>
      </div>
    )
  }
  _isPresent(value) {
    return !!value.trim()
  }

  _isZipCodeValid(value) {
    if (_.includes(getSubdomain(), "-ca")) {
      return POSTAL_CODE_REGEX.test(value)
    } else return ZIP_CODE_REGEX.test(value)
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _onFieldChange(key, value) {
    this.setState({
      onFieldChange: true,
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _validateZipCode = () => {
    const { fields } = this.state
    const zipCode = fields.zip.value
    const { t } = this.props

    if (!this._isZipCodeValid(zipCode)) {
      this.showError(t("invalidZipCodeMessage"))
      return false
    }
    return true
  }

  _validateFields = (callback) => {
    if (!this._validateZipCode()) return
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey) this.showError(`${t("errorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  onEditing = () => {
    this.setState({
      isEditing: true
    })
  }

  onCancel = () => {
    const { user } = this.props
    this.setState({
      isEditing: false,
      fields: {
        zip: {
          value: user.zip
        }
      }
    })
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  const { membershipData, paymentMethods } = state
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let activeOrder =
    user.active_orders && user.active_orders.find((s) => s.vehicle_id == user.activeVehicleId)
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    zip: user.zip || "",
    user: user,
    hideChrome: state.framework && state.framework.hideChrome,
    cart: cart,
    location: props.location,
    activeOrder,
    isLoggedIn: !!user.authentication_token,
    isGeneric: user.generic,
    accountTierName: user.membership && user.membership.plan_name,
    hasPaymentMethod: user.has_cc_info_saved,
    nextUpgrades: nextMembershipUpgrades(user, membershipData.availableMembershipPlans),
    paymentMethods: paymentMethods.braintreePaymentMethods,
    activeVehicle: activeVehicle
  }
}

export default withRouter(
  connect(mapStateToProps, { logoutUser, getUserDetails, updateInfo })(
    withTranslation("header")(DashHeader_v2)
  )
)
