import React from "react"
import CAIconLogo from "../../images/CA_CheckIcon.svg"
import { Image } from "semantic-ui-react"

export default function FullPageLoader(props) {
  return (
    <div className="loader-content-full">
      <Image src={CAIconLogo} className="loader-image" />
    </div>
  )
}
