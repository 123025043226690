import React from "react"
import ReactSVG from "react-svg"
import { Modal } from "semantic-ui-react"
import DialogCrossLogo from "../../../images/rewards/dialog-close.svg"
import MilesCalendarLogo from "../../../images/rewards/miles-calendar.svg"
import MilesCheckLogo from "../../../images/rewards/miles-check.svg"
import MilesVerifiedLogo from "../../../images/rewards/miles-verified.svg"
import UnlockMilesSteps from "./UnlockMilesSteps"
import { withTranslation } from "react-i18next"

const HowToUnlockMiles = ({ openDialog, setOpenDialog, t }) => {
  const UNLOCK_MILES = [
    {
      id: 1,
      stepLogo: MilesCalendarLogo,
      stepInfo: t("bookAppointment")
    },
    {
      id: 2,
      stepLogo: MilesVerifiedLogo,
      stepInfo: t("storeWillLogMileage")
    },
    {
      id: 3,
      stepLogo: MilesCheckLogo,
      stepInfo: (
        <>
          {t("unlockMiles")} <span className="unlock_miles__forYou">{t("unlockMilesForYou")}</span>
        </>
      )
    }
  ]

  return (
    <Modal
      onClose={() => setOpenDialog(false)}
      onOpen={() => setOpenDialog(true)}
      open={openDialog}
      className="how-to-unlock-miles"
    >
      <Modal.Content>
        <Modal.Description>
          <div className="unlock-miles-header">
            <p className="dialog-header">{t("howUnlockMiles")}</p>
            <ReactSVG
              onClick={() => setOpenDialog(false)}
              src={DialogCrossLogo}
              className="unlock-dialog-cross"
            />
          </div>
          <p className="unlock-miles-desc">{t("unlockMilesDesc")}</p>
          {UNLOCK_MILES.map((steps, index) => (
            <UnlockMilesSteps
              key={steps.id}
              steps={steps}
              isLastChild={UNLOCK_MILES.length - 1 === index}
            />
          ))}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default withTranslation("howToUnlockMiles")(HowToUnlockMiles)
