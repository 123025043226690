import React from "react"
import RegHeader from "./components/regHeader.js"
import RegFooter from "./components/regFooter.js"
import { useTranslation } from "react-i18next"

export default function () {
  const { t } = useTranslation("moreInfo")
  return (
    <div className="container">
      <RegHeader />
      <div className="contentCenter">
        <p>{t("infoText")}</p>
      </div>
      <p>
        <a href="javascript:history.back();" className="btnBack">
          &lsaquo; {t("backText")}
        </a>
      </p>
      <RegFooter />
    </div>
  )
}
