import React from "react"

export default function TopTireShimmer() {
  return (
    <div className="tire-card-button-parent">
      <div className="card-parent">
        <div className="tires-card-parent">
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <div className="tire-card-shimmer" key={i}>
                <div className="shimmer">
                  <div className="wrapper">
                    <div className="tire-image-container-shimmer animate"></div>
                    <div className="tire-size-details-container-shimmer">
                      <div className="stroke">
                        <div className="animate title" />
                        <div className="stroke-row">
                          <div className="animate description" />
                          <div className="animate description" />
                        </div>
                        <div className="animate title" />
                        <div className="stroke-row">
                          <div className="animate description" />
                          <div className="animate meta" />
                        </div>
                        <div className="stroke-row">
                          <div className="animate meta" />
                          <div className="animate meta" />
                          <div className="animate meta" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
