import React from "react"
import { Button, Segment, Sidebar } from "semantic-ui-react"
import TireFilterAndSearch360 from "./TireFilterAndSearch360"
import ReactSVG from "react-svg"
import RemoveFilterLogo from "../../../../images/remove-filter.svg"
import FilterLogo from "../../../../images/tire-filter.svg"
import { useTranslation } from "react-i18next"

const FilterDrawer = ({
  open,
  filters,
  handleFilterDrawer,
  clearTireFilters,
  tireSelectedFilters,
  fetchTireList,
  handleSelectedFilter,
  tires,
  isFilterApiCalling
}) => {
  const { t } = useTranslation("tireFilters")

  return (
    <Sidebar
      as={Segment}
      animation="overlay"
      className="filter__sidebar-drawer"
      direction="bottom"
      visible={open}
    >
      <div className="drawer__header">
        <ReactSVG className="close__logo" onClick={handleFilterDrawer} src={RemoveFilterLogo} />
        <div className="drawer__header-label">
          <ReactSVG src={FilterLogo} />
          <p>{t("filterAndShort")}</p>
        </div>
      </div>
      <div className="filter__container">
        {isFilterApiCalling ? (
          [...new Array(5)].map(() => <div className="tire__filter-animation-mobile"></div>)
        ) : (
          <TireFilterAndSearch360
            handleSelectedFilter={handleSelectedFilter}
            filters={filters}
            tireSelectedFilters={tireSelectedFilters}
            tires={tires}
          />
        )}
      </div>
      <div className="drawer__footer">
        <Button
          className="clear__all-btn"
          onClick={() => {
            clearTireFilters(true)
            handleFilterDrawer()
          }}
        >
          {t("clearAll")}
        </Button>
        <Button
          className="apply__btn"
          onClick={() => {
            fetchTireList(tireSelectedFilters)
            handleFilterDrawer()
          }}
        >
          {t("applyFilters")}
        </Button>
      </div>
    </Sidebar>
  )
}

export default FilterDrawer
