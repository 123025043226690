import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ShimmerServiceCard } from "./ShimmerServiceCard"
import { updateCart } from "../../../v1/actions/user"
import { getCategoryServices } from "../../../v1/actions/services"
import ServiceCard from "./ServiceCard"
import { useTranslation } from "react-i18next"
import { SearchComponent } from "./SearchComponent"
import ReactSVG from "react-svg"
import { NoServiceFound } from "./NoServiceFound"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const CategoryWiseServices = (props) => {
  const [services, setServices] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 100

  const [loadingStates, setLoadingStates] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { getCategoryServices, cart, activeVehicle } = props
  const { t } = useTranslation("common")

  const fetchAvailableCategoriesServices = async () => {
    setIsLoading(true)

    const categoryServices =
      (await getCategoryServices({
        first_level: props.firstLevelCategory,
        second_level: props.secondLevelCategory
      })) || null

    if (categoryServices !== null) {
      setServices(categoryServices)
      const initialLoadingStates =
        categoryServices &&
        categoryServices.reduce((acc, service) => {
          acc[service.id || service.service_id || service.service_definition_id] = false
          return acc
        }, {})
      setLoadingStates(initialLoadingStates)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAvailableCategoriesServices()
  }, [])

  const addService = async (index, os, event) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [index]: true
    }))
    const { activeVehicle, isCart, order } = props
    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id,
      successCallback: async () => {
        trackFirebaseAnalytics({
          event: gaJSON.service_selected,
          params: {
            [gaJSON.service_name]: os.name,
            [gaJSON.service_type]: !os.position ? os.name : `${os.name} - ${os.position}`,
            [gaJSON.from_where]: window.location.hash,
            [gaJSON.vehicle_name]: activeVehicle
              ? `${activeVehicle.year || ""} ${activeVehicle.make.toUpperCase() || ""} ${
                  activeVehicle.model.toUpperCase() || ""
                }`
              : null
          }
        })
      }
    }
    try {
      await props.updateCart(
        [
          {
            service_definition_id: os.service_id || os.id || os.service_definition_id,
            event: event,
            prepaid: os.prepaid,
            position: os.position
          }
        ],
        props.cart.vehicle_id,
        undefined,
        undefined,
        opts
      )
      // Add Segment Event
    } catch (error) {
      console.log(error)
      return { error: error.message }
    } finally {
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [index]: false
      }))
    }
  }
  const filteredServices = services.filter((rec) => {
    const isInCart = props.cart && props.cart.order_services.some((item) => item.id === rec.id)
    const matchesSearch =
      rec.friendly_name && rec.friendly_name.toLowerCase().includes(searchQuery.toLowerCase())
    return !isInCart && matchesSearch
  })

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      currentPage * recordsPerPage < filteredServices.length
    ) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    const scrollListener = () => {
      handleScroll()
    }

    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [currentPage, filteredServices])

  const startIndex = 0
  const endIndex = currentPage * recordsPerPage

  return (
    <div className="second-level-services-container">
      <div className="add-services_header">
        <ReactSVG
          src="images/go_back_icon.svg"
          onClick={() => props.handleCategoryCardClick(null)}
          style={{ cursor: "pointer" }}
        />
        <div className="category-header-section">
          <p className="meta-heading">{t("categoaryLabel")}</p>
          <p className="category-heading">{`${props.firstLevelCategory} -  ${props.secondLevelCategory}`}</p>
        </div>
        {(filteredServices.length > 0 && searchQuery === "") || searchQuery !== "" ? (
          <SearchComponent searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        ) : null}
      </div>
      <div className="second-level-services">
        {isLoading && <ShimmerServiceCard count={15} />}
        {!isLoading && filteredServices.length > 0 ? (
          filteredServices
            .slice(startIndex, endIndex)
            .map((os, index) => (
              <ServiceCard
                key={os.id || os.service_id || os.service_definition_id}
                isCheckbox={true}
                index={startIndex + (os.id || os.service_id || os.service_definition_id)}
                service={os}
                addService={addService}
                loadingStates={loadingStates[os.id || os.service_id || os.service_definition_id]}
                order={props.cart}
                vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
              />
            ))
        ) : !isLoading && searchQuery === "" ? (
          <NoServiceFound
            serviceName={`${props.firstLevelCategory} -  ${props.secondLevelCategory}`}
          />
        ) : (
          !isLoading && searchQuery !== "" && <NoServiceFound searchQuery={searchQuery} />
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    order
  }
}

export default connect(mapStateToProps, { getCategoryServices, updateCart })(CategoryWiseServices)
