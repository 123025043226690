import React, { Component } from "react"
import _, { isEmpty } from "lodash"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import GenericPanel from "../../../components/GenericPanel"
import PageTitle from "./components/PageTitle"
import { Checkbox, Grid, Header, Icon, Image, Responsive } from "semantic-ui-react"
import Button from "../../../components/Button"
import MiniLoader from "../../../components/MiniLoader"
import {
  fetchCarAdviseRecemendedServices,
  fetchServicesPricing,
  markServicesCompletedAPI
} from "../../actions/maintenance"
import { Redirect } from "react-router"
import SectionTitle from "./components/SectionTitle"
import Services from "./components/Services"
import CarComponent from "./components/CarComponent"
import { showAlert } from "../../helpers/showAlertHelper"
import { updateCart } from "../../../actions/user"
import { pullOemMaintenanceSchedules } from "../../../actions/maintenanceSchedules"
import { trackEvent } from "../../../../shared_component/utils/segmentAnalytics"
import { getVehicleName } from "../../../helpers/vehicleHelpers"
import { getServiceNameForMap } from "../../../helpers/serviceHelper"

import ServicesLoadingComponent from "./components/ServicesLoadingComponent"
import { ProgressHUD } from "../components/ProgressHUD"
import { CARADVISE, OEM_RECOMMENDED } from "../../../constants/application"
import { routerPaths } from "../../../constants/paths"
import { getSubdomain } from "../../../components/Config/StylesheetInjector"
import { V1, setupSplitFlags } from "../../../components/Config/SplitClient"
import { getUpdatedCart } from "../../../helpers/orderHelpers"
const OIL_CHANGE = "Oil Change"

class MaintenanceScheduleHistoryDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      oemRecommendedServices: [],
      caradviseServices: [],
      interval: undefined,
      interval_id: undefined,
      is_completed: false,
      loading: false,
      isCartLoading: false,
      serviceToBeMarkedAsCompleted: [],
      servicesSelectedToBeBooked: [],
      markAllCompleted: false,
      selectAllChecked: false,
      caradviseServicesLoading: true,
      isChecked: false,
      isCheckedForCarAdvise: false,
      isCheckedForOemRecommended: false,
      showCheckAllCarAdviseRecommended: true
    }
  }

  getCarAdviseRecommendedServices = async (interval) => {
    this.setState({
      caradviseServicesLoading: true,
      caradviseServices: []
    })
    let currentInterval = this.getCurrentInterval().interval
    let intervalId = this.getCurrentInterval().interval_id

    if (interval) {
      currentInterval = interval
    }

    const data = {
      vehicleId: this.props.activeVehicleId,
      interval: currentInterval,
      interval_id: intervalId
    }
    const carAdviseRecommendedResponse = await this.props.fetchCarAdviseRecemendedServices(data)
    if (carAdviseRecommendedResponse.result) {
      const services = carAdviseRecommendedResponse.result.map((element) => {
        let isCompleted = false
        try {
          isCompleted = element.is_completed
        } catch (e) {}
        return {
          service_id: element.service_id,
          service_name: element.name,
          positions: element.positions,
          is_completed: isCompleted,
          logo: element.logo,
          low_price_estimate: element.low_price_estimate,
          high_price_estimate: element.high_price_estimate
        }
      })
      const incompleteCaradviseRecommendedServiceIndex = services.findIndex(
        (s) => s.is_completed == false
      )

      this.setState({
        caradviseServices: services,
        showCheckAllCarAdviseRecommended:
          incompleteCaradviseRecommendedServiceIndex == -1 ? false : true
      })
    }

    this.setState({
      caradviseServicesLoading: false
    })
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    const currentInterval = this.getCurrentInterval()
    await this.getCarAdviseRecommendedServices()
    if (!currentInterval.is_completed) {
      this.addAllServices(OEM_RECOMMENDED)
      this.addAllServices(CARADVISE)
    }
  }

  updateInterval = (interval) => {
    this.setState({
      interval: interval,
      serviceToBeMarkedAsCompleted: [],
      servicesSelectedToBeBooked: [],
      markAllCompleted: false,
      selectAllChecked: false,
      isChecked: false,
      isCheckedForCarAdvise: false,
      isCheckedForOemRecommended: false
    })
    this.getCarAdviseRecommendedServices(interval)
  }

  hasNextInterval = () => {
    const { oemServices } = this.props
    const totalOemLength = oemServices.length
    const currentInterval = this.getCurrentInterval()
    const currentIntervalIndex = oemServices.findIndex(
      (service) => currentInterval.interval_id == service.interval_id
    )
    if (currentIntervalIndex != -1) {
      const hasNext = currentIntervalIndex < totalOemLength - 1
      if (hasNext) {
        const nextInterval = oemServices[currentIntervalIndex + 1].interval
        this.updateInterval(nextInterval)
      }
    }
  }

  markServicesCompleted = async () => {
    this.setState({
      loading: true
    })
    const { serviceToBeMarkedAsCompleted, selectAllChecked } = this.state
    const { t } = this.props
    const service_completed_details = serviceToBeMarkedAsCompleted.map((s) => {
      return {
        id: s.service_id,
        is_completed: true
      }
    })
    const servicesArray = serviceToBeMarkedAsCompleted.map((service) => {
      return {
        id: service.service_id,
        name: service.service_name
      }
    })
    const data = {
      vehicle_id: this.props.activeVehicleId,
      interval_id: this.getCurrentInterval().interval_id,
      service_completed_details: service_completed_details,
      are_all_completed: selectAllChecked
    }
    const key = "name"

    const req = await this.props.markServicesCompletedAPI(data)
    if (req.result) {
      trackEvent(
        "maintenance-schedule-services-completed",
        {
          [this.getCurrentInterval().interval]: {
            services: [...new Map(servicesArray.map((item) => [item[key], item])).values()]
          }
        },
        true
      )

      if (selectAllChecked) {
        this.hasNextInterval()
      }

      await this.props.pullOemMaintenanceSchedules({ vehicle: this.props.activeVehicle })
      await this.getCarAdviseRecommendedServices(this.getCurrentInterval().interval)
      showAlert({
        title: t("completed-success-title"),
        message: t("completed-success-message")
      })
    }
    this.setState({
      loading: false,
      serviceToBeMarkedAsCompleted: [],
      servicesSelectedToBeBooked: []
    })
  }

  addorRemoveServices = (service, position = undefined) => {
    const { servicesSelectedToBeBooked, caradviseServices } = this.state
    const services = servicesSelectedToBeBooked

    if (services.length == 0) {
      if (position !== undefined) {
        const newService = {
          ...service,
          positions: [position]
        }
        services.push(newService)
      }
      if (position === undefined) {
        services.push(service)
      }
    } else {
      const serviceIndex = services.findIndex((s) => s.service_id === service.service_id)
      if (serviceIndex == -1) {
        if (position !== undefined) {
          const newService = {
            ...service,
            positions: [position]
          }
          services.push(newService)
        }
        if (position === undefined) {
          services.push(service)
        }
      } else {
        services.splice(serviceIndex, 1)
        if (position !== undefined) {
          const newService = {
            ...service,
            positions: [position]
          }
          services.push(newService)
        }
      }
    }
    if (services) {
      this.setState({
        isChecked:
          services.length ===
          caradviseServices.length +
            this.getCurrentInterval().services.filter((x) => {
              if (!x.is_completed) {
                return x
              }
            }).length
            ? true
            : false,
        isCheckedForCarAdvise:
          services.filter((x) => caradviseServices.find((i) => x.service_id === i.service_id))
            .length ===
          caradviseServices.filter((x) => {
            if (!x.is_completed) {
              return x
            }
          }).length
            ? true
            : false,
        isCheckedForOemRecommended:
          services.filter((x) =>
            this.getCurrentInterval().services.find((i) => x.service_id === i.service_id)
          ).length ===
          this.getCurrentInterval().services.filter((x) => {
            if (!x.is_completed) {
              return x
            }
          }).length
            ? true
            : false
      })
    }
    this.setState({
      servicesSelectedToBeBooked: services
    })
  }

  onMarkasCompletedCheckboxClick(service) {
    const { serviceToBeMarkedAsCompleted, caradviseServices } = this.state
    const servicesArray = serviceToBeMarkedAsCompleted
    const oemRecommendedServices = this.getCurrentInterval().services
    const oemServicesCompleted = oemRecommendedServices.filter((s) => s.is_completed)
    const caradviseServicesCompleted = caradviseServices.filter((s) => {
      if (s.hasOwnProperty("is_completed") && s.is_completed) {
        return s
      }
    })
    if (oemServicesCompleted && oemServicesCompleted.length > 0) {
      servicesArray.push(...oemServicesCompleted)
    }
    if (caradviseServicesCompleted && caradviseServicesCompleted.length > 0) {
      servicesArray.push(...caradviseServicesCompleted)
    }

    if (serviceToBeMarkedAsCompleted.length == 0) {
      servicesArray.push(service)
    } else {
      const index = serviceToBeMarkedAsCompleted.findIndex(
        (s) => s.service_id == service.service_id
      )
      if (index == -1) {
        servicesArray.push(service)
      } else {
        servicesArray.splice(index, 1)
        this.setState({
          selectAllChecked: false
        })
      }
    }
    this.setState({
      serviceToBeMarkedAsCompleted: servicesArray,
      selectAllChecked:
        [...new Set(servicesArray.map((item) => item.service_id))].length ===
        caradviseServices.length + oemRecommendedServices.length
          ? true
          : false
    })
  }

  getCurrentInterval = () => {
    const { oemServices, activeVehicleId, fetchServicesPricing } = this.props
    const { interval } = this.state
    let currentInterval
    let pricingResponse
    if (oemServices) {
      if (interval == undefined) {
        currentInterval =
          oemServices &&
          oemServices.filter((i) => i.interval_id === parseInt(this.props.match.params.id))[0]
      } else {
        currentInterval = oemServices && oemServices.filter((i) => i.interval == interval)[0]
      }
      const service_ids =
        currentInterval && currentInterval.services.map(({ service_id, ...rest }) => service_id)
      pricingResponse =
        activeVehicleId &&
        !isEmpty(service_ids) &&
        fetchServicesPricing({ vehicleId: activeVehicleId, service_ids: service_ids }).then(
          (services) => {
            if (services.result) {
              const map = new Map()
              currentInterval.services.forEach((service) => {
                map.set(service.service_id, service)
              })
              services.result.forEach((service) => {
                if (map.has(service.id)) {
                  Object.assign(map.get(service.id), service)
                }
              })
            }
          }
        )
    }
    return currentInterval
  }

  selectAllToggle = () => {
    const { caradviseServices, selectAllChecked } = this.state

    const oem = this.getCurrentInterval().services.slice()
    const crs = caradviseServices.slice()
    const services = [...oem, ...crs]
    if (selectAllChecked) {
      this.setState({
        serviceToBeMarkedAsCompleted: []
      })
    } else {
      this.setState({
        serviceToBeMarkedAsCompleted: services
      })
    }
    this.setState({
      selectAllChecked: !selectAllChecked
    })
  }
  addAllServices = (serviceTag) => {
    const {
      isCheckedForCarAdvise,
      servicesSelectedToBeBooked,
      caradviseServices,
      isCheckedForOemRecommended
    } = this.state
    if (serviceTag === CARADVISE) {
      if (isCheckedForCarAdvise) {
        this.setState({
          servicesSelectedToBeBooked: servicesSelectedToBeBooked.filter(
            (x) => !caradviseServices.find((i) => x.service_id === i.service_id)
          )
        })

        this.setState({ isCheckedForCarAdvise: !isCheckedForCarAdvise })
      } else {
        this.setState({
          servicesSelectedToBeBooked: [
            ...servicesSelectedToBeBooked,
            ...caradviseServices.filter((x) => {
              if (!x.is_completed) {
                return x
              }
            })
          ]
        })

        this.setState({ isCheckedForCarAdvise: !isCheckedForCarAdvise })
      }
    }
    if (serviceTag === OEM_RECOMMENDED) {
      if (isCheckedForOemRecommended) {
        this.setState({
          servicesSelectedToBeBooked: servicesSelectedToBeBooked.filter(
            (x) => !this.getCurrentInterval().services.find((i) => x.service_id === i.service_id)
          )
        })
        this.setState({ isCheckedForOemRecommended: !isCheckedForOemRecommended })
      } else {
        const currentInterval = this.getCurrentInterval()
        if (currentInterval && Array.isArray(currentInterval.services)) {
          this.setState({
            servicesSelectedToBeBooked: [
              ...servicesSelectedToBeBooked,
              ...currentInterval.services.filter((x) => !x.is_completed)
            ]
          })
          this.setState({ isCheckedForOemRecommended: !isCheckedForOemRecommended })
        }
      }
    }
  }

  render() {
    const {
      caradviseServices,
      caradviseServicesLoading,
      oemRecommendedServices,
      isCheckedForCarAdvise,
      isCheckedForOemRecommended,
      showCheckAllCarAdviseRecommended
    } = this.state

    const { oemServices } = this.props
    const currentInterval = this.getCurrentInterval()

    const oemIncompleteServiceIndex =
      currentInterval &&
      currentInterval.services &&
      currentInterval.services.findIndex((s) =>
        s.hasOwnProperty("is_completed") ? s.is_completed == false : true
      )

    const showCheckAllOEMRecommended = oemIncompleteServiceIndex == -1 ? false : true

    let scheduleStatus = ""
    try {
      scheduleStatus = currentInterval && currentInterval.tag
    } catch (e) {
      scheduleStatus = ""
    }
    const isCompleted = currentInterval && currentInterval.is_completed

    const iconBgColor =
      scheduleStatus && scheduleStatus.includes("Past Due") ? "#B91C1C" : "#282828"

    const totalOemLength = oemServices.length
    let hasPreviousInterval = false
    let previousInterval = undefined
    let nextInterval = undefined
    let hasNextInterval = false

    const currentIntervalIndex = oemServices.findIndex(
      (service) => currentInterval.interval_id == service.interval_id
    )

    if (currentIntervalIndex == -1) {
      return <Redirect to={routerPaths.maintenanceSchedule} />
    } else {
      hasPreviousInterval = currentIntervalIndex != 0
      hasNextInterval = currentIntervalIndex < totalOemLength - 1
      if (currentIntervalIndex != 0) {
        previousInterval = oemServices[currentIntervalIndex - 1].interval
      }
      if (hasNextInterval) {
        nextInterval = oemServices[currentIntervalIndex + 1].interval
      }
    }

    const {
      selectAllChecked,
      serviceToBeMarkedAsCompleted,
      markAllCompleted,
      servicesSelectedToBeBooked,
      loading,
      isCartLoading
    } = this.state
    const { t, cart, isCart, order } = this.props
    return (
      <>
        <div className="maintenanc-schedule-backbround">
          {loading && <ProgressHUD />}
          <div className="maintenanc-schedule-container">
            <div className="scroll-on-phone">
              <div className="maintenance-schedule-child-container">
                <Grid.Column>
                  <PageTitle
                    showBack={true}
                    title="Maintenance Schedule"
                    onBackClick={() => this.props.history.goBack()}
                  />
                  <div className="miles-header-container">
                    {hasPreviousInterval ? (
                      <div
                        className="icon-container"
                        onClick={() =>
                          previousInterval != undefined && this.updateInterval(previousInterval)
                        }
                      >
                        <Icon className="arrow-icon" name="angle left" />
                      </div>
                    ) : (
                      <div style={{ width: "30px" }} />
                    )}

                    <Header as="h3" className="primary-font services-header">
                      {t("services-due-at", { interval: currentInterval.interval })} mi
                    </Header>
                    {hasNextInterval ? (
                      <div
                        className="icon-container"
                        onClick={() => this.updateInterval(nextInterval)}
                      >
                        <Icon className="arrow-icon" name="angle right" />
                      </div>
                    ) : (
                      <div style={{ width: "30px" }} />
                    )}
                  </div>

                  {markAllCompleted == false && !isCompleted ? (
                    <Header
                      as="h5"
                      className="primary-font services-sub-header"
                      onClick={() => {
                        this.setState({
                          serviceToBeMarkedAsCompleted: [],
                          servicesSelectedToBeBooked: [],
                          isCheckedForCarAdvise: false,
                          isCheckedForOemRecommended: false,
                          markAllCompleted: true
                        })
                      }}
                    >
                      {t("mark-as-completed")}
                    </Header>
                  ) : (
                    <div className="mark-all-completed-container">
                      <div style={{ flexDirection: "row" }}>
                        {!isCompleted && (
                          <Icon.Group size="large" className="back-icon">
                            <Icon
                              color="black"
                              name={selectAllChecked ? "check circle" : "circle outline"}
                              onClick={() => this.selectAllToggle()}
                            />
                          </Icon.Group>
                        )}

                        {!isCompleted && <span>{t("mark-all-completed")}</span>}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <span
                          disabled={serviceToBeMarkedAsCompleted.length == 0}
                          className="primary-font  services-sub-header1"
                          onClick={() => {
                            this.setState({
                              markAllCompleted: false,
                              selectAllChecked: false,
                              serviceToBeMarkedAsCompleted: []
                            })
                          }}
                          style={{ marginRight: "20px", color: "black" }}
                        >
                          {!isCompleted && "Cancel"}
                        </span>
                        <span
                          disabled={serviceToBeMarkedAsCompleted.length == 0}
                          className="primary-font  services-sub-header"
                          onClick={() => {
                            if (
                              serviceToBeMarkedAsCompleted &&
                              serviceToBeMarkedAsCompleted.length > 0
                            ) {
                              this.markServicesCompleted()
                              this.setState({
                                markAllCompleted: false
                              })
                            }
                          }}
                        >
                          {!isCompleted && t("done")}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="scroll">
                    {caradviseServicesLoading ? (
                      <ServicesLoadingComponent />
                    ) : currentInterval.services && currentInterval.services.length > 0 ? (
                      <GenericPanel>
                        <div
                          style={{
                            cursor: "pointer",
                            alignSelf: "flex-end",

                            textAlign: "right"
                          }}
                        >
                          {/* {!isCompleted && (
                        <Checkbox
                          onChange={() => {
                            if (this.state.isChecked) {
                              this.setState({
                                servicesSelectedToBeBooked: []
                              })
                              this.setState({ isChecked: !this.state.isChecked })
                            } else {
                              this.setState({
                                servicesSelectedToBeBooked: [
                                  ...caradviseServices,
                                  ...currentInterval.services.filter((x) => {
                                    if (!x.is_completed) {
                                      return x
                                    }
                                  })
                                ]
                              })
                              this.setState({ isChecked: !this.state.isChecked })
                            }
                          }}
                          checked={this.state.isChecked}
                        />
                      )} */}
                        </div>
                        <>
                          <SectionTitle
                            title={t("manufacturer-recommended")}
                            count={currentInterval.services.length}
                            bgColor={iconBgColor}
                            completed={isCompleted}
                            checked={isCheckedForOemRecommended}
                            markAllCompleted={markAllCompleted}
                            addAllServices={() => this.addAllServices(OEM_RECOMMENDED)}
                            showSelectAllCheckbox={showCheckAllOEMRecommended}
                          />

                          {currentInterval.services.map((service, index) => {
                            let serviceStatus = false
                            try {
                              serviceStatus = service.is_completed
                            } catch (e) {
                              serviceStatus = false
                            }
                            return (
                              <Services
                                key={index}
                                service={service}
                                completed={serviceStatus || isCompleted}
                                showMarkCompletedCheckBox={serviceStatus || !isCompleted}
                                selectedServices={servicesSelectedToBeBooked}
                                isLast={currentInterval.services.length - 1 == index}
                                addorRemoveServices={this.addorRemoveServices}
                                onMarkasCompletedCheckboxClick={(service) => {
                                  this.onMarkasCompletedCheckboxClick(service)
                                }}
                                serviceToBeMarkedAsCompleted={serviceToBeMarkedAsCompleted}
                                markAllCompleted={markAllCompleted}
                                activeVehicle={this.props.activeVehicle}
                                order={cart}
                              />
                            )
                          })}
                        </>
                      </GenericPanel>
                    ) : (
                      <div />
                    )}
                    {caradviseServicesLoading ? (
                      <ServicesLoadingComponent />
                    ) : caradviseServices && caradviseServices.length > 0 ? (
                      <div className="extra-padding">
                        <GenericPanel>
                          <div>
                            <SectionTitle
                              title={t("you-may-also-need")}
                              count={caradviseServices.length}
                              bgColor={iconBgColor}
                              completed={isCompleted}
                              checked={isCheckedForCarAdvise}
                              markAllCompleted={markAllCompleted}
                              addAllServices={() => this.addAllServices(CARADVISE)}
                              showSelectAllCheckbox={showCheckAllCarAdviseRecommended}
                            />
                            {caradviseServices.map((service, index) => {
                              let serviceStatus = false
                              try {
                                serviceStatus = service.is_completed
                              } catch (e) {
                                serviceStatus = false
                              }
                              return (
                                <Services
                                  key={index}
                                  service={service}
                                  completed={serviceStatus || isCompleted}
                                  showMarkCompletedCheckBox={isCompleted}
                                  selectedServices={servicesSelectedToBeBooked}
                                  isLast={caradviseServices.length - 1 == index}
                                  addorRemoveServices={this.addorRemoveServices}
                                  onMarkasCompletedCheckboxClick={(service) => {
                                    this.onMarkasCompletedCheckboxClick(service)
                                  }}
                                  serviceToBeMarkedAsCompleted={serviceToBeMarkedAsCompleted}
                                  markAllCompleted={markAllCompleted}
                                  activeVehicle={this.props.activeVehicle}
                                  order={cart}
                                />
                              )
                            })}
                          </div>
                        </GenericPanel>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div style={{ height: "100px" }}></div>
                  </div>

                  <div className="bottom-fixed">
                    <GenericPanel>
                      <div className="manufacturer-title">
                        <Header as="h3" className="primary-font">
                          {t("total-services", { count: servicesSelectedToBeBooked.length })}
                        </Header>

                        <Button
                          colorStyle="orange"
                          text={isCartLoading ? <MiniLoader /> : t("book-services")}
                          style={{ margin: "0px", height: "20px", width: "-webkit-fill-available" }}
                          onClick={async (e) => {
                            const services = servicesSelectedToBeBooked.map((service) => {
                              const preparedService = {
                                event: "added",
                                service_definition_id: service.service_id,
                                position:
                                  service.positions &&
                                  service.positions.length === 1 &&
                                  service.positions[0]
                              }
                              if (!preparedService.position && service.positions) {
                                if (service.service_name === OIL_CHANGE) {
                                  service.positions.map((position) => {
                                    preparedService.position =
                                      position === this.props.activeVehicle.oil_type_name
                                  })
                                } else {
                                  preparedService.position = service.positions[0]
                                }
                              }
                              return preparedService
                            })

                            const servicesArray = servicesSelectedToBeBooked.map((service) => {
                              return {
                                id: service.service_id,
                                name: service.service_name
                              }
                            })
                            this.setState({ loading: true, isCartLoading: true })
                            let opts = {
                              isOrder: !isCart && order && !order.is_tire_order,
                              orderId: order && order.id
                            }
                            const req = await this.props.updateCart(
                              services,
                              this.props.activeVehicleId,
                              currentInterval.interval_id,
                              undefined,
                              opts
                            )

                            if (req().result) {
                              trackEvent(
                                "maintenance-schedule-services-added-to-cart",
                                {
                                  [currentInterval.interval]: {
                                    services: servicesArray
                                  }
                                },
                                true
                              )
                              if (
                                this.state.isCarAdviseNavigation &&
                                this.state.isCarAdviseNavigation === V1
                              ) {
                                window.location = `/#${routerPaths.shop}`
                              } else {
                                window.location = `/#${routerPaths.cartSummary}`
                              }
                            }
                            this.setState({ loading: false, isCartLoading: false })
                            // e.preventDefault()
                          }}
                          disabled={
                            loading || markAllCompleted || servicesSelectedToBeBooked.length == 0
                          }
                        />
                      </div>
                    </GenericPanel>
                  </div>
                </Grid.Column>
              </div>
            </div>
            <Responsive minWidth={1360}>
              <CarComponent vehicle={this.props.activeVehicle} />
            </Responsive>
          </div>
        </div>
        <div className="bottom-fixed-in-mobile">
          <GenericPanel>
            <div className="manufacturer-title1">
              <Header as="h3" className="primary-font" style={{ marginTop: "5px" }}>
                {t("total-services", { count: servicesSelectedToBeBooked.length })}
              </Header>

              <Button
                colorStyle="orange"
                text={isCartLoading ? <MiniLoader /> : t("book-services")}
                style={{ margin: "0px", height: "20px" }}
                onClick={async (e) => {
                  const services = servicesSelectedToBeBooked.map((service) => {
                    return {
                      event: "added",
                      service_definition_id: service.service_id,
                      position: service.positions ? service.positions[0] : null,
                      vehicle_id: this.props.activeVehicleId
                    }
                  })
                  const ServiceName = servicesSelectedToBeBooked.map((service) => {
                    return {
                      name: service.service_name,
                      service_definition_id: service.service_id,
                      position: service.positions ? service.positions[0] : null,
                      vehicle_id: this.props.activeVehicleId
                    }
                  })
                  const servicesArray = servicesSelectedToBeBooked.map((service) => {
                    return {
                      id: service.service_id,
                      name: service.service_name
                    }
                  })
                  this.setState({ loading: true, isCartLoading: true })
                  let opts = {
                    isOrder: !isCart && order && !order.is_tire_order,
                    orderId: order && order.id
                  }
                  const req = await this.props.updateCart(
                    services,
                    this.props.activeVehicleId,
                    currentInterval.interval_id,
                    undefined,
                    opts
                  )

                  if (req().result) {
                    trackEvent(
                      "maintenance-schedule-services-added-to-cart",
                      {
                        [currentInterval.interval]: {
                          services: servicesArray
                        }
                      },
                      true
                    )
                  }
                  this.setState({ loading: false, isCartLoading: false })
                  if (this.state.isCarAdviseNavigation && this.state.isCarAdviseNavigation === V1) {
                    window.location = `/#${routerPaths.shop}`
                  } else {
                    window.location = `/#${routerPaths.cartSummary}`
                  }
                  // e.preventDefault()
                }}
                disabled={loading || markAllCompleted || servicesSelectedToBeBooked.length == 0}
              />
            </div>
          </GenericPanel>
        </div>
      </>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  let activeVehicleId = user.activeVehicleId
  const carAdviseRecommended = state.carAdviseRecommended
  const oemServices = activeVehicle.oemServices ? activeVehicle.oemServices : []
  let cart
  const findActiveCart = (carts, id, property) => carts.find((c) => c[property] === id)

  const currentActiveOrder = user.active_orders.find((o) => o.vehicle_id === user.activeVehicleId)
  cart =
    currentActiveOrder ||
    (user.active_carts && findActiveCart(user.active_carts, Number(props.cartId)[0], "id")) ||
    (user.active_carts && findActiveCart(user.active_carts, user.activeVehicleId, "vehicle_id"))

  let isCart
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  return {
    activeVehicleId,
    activeVehicle,
    carAdviseRecommended,
    oemServices,
    cart: cart || {},
    isCart,
    order
  }
}

export default connect(mapStateToProps, {
  fetchCarAdviseRecemendedServices,
  updateCart,
  markServicesCompletedAPI,
  pullOemMaintenanceSchedules,
  fetchServicesPricing
})(withTranslation("maintenance")(MaintenanceScheduleHistoryDetails))
