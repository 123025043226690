import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import moment from "moment"
import enCommon from "../../v1/locales/en/common.json"
import frCommon from "../../v1/locales/fr/common.json"
import enMultiSelectServiceModal from "../../v1/locales/en/multiSelectServiceModal.json"
import frMultiSelectServiceModal from "../../v1/locales/fr/multiSelectServiceModal.json"
import enVehicleDetails from "../../v1/locales/en/vehicleDetails.json"
import caVehicleDetails from "../../v1/locales/ca/vehicleDetails.json"
import frVehicleDetails from "../../v1/locales/fr/vehicleDetails.json"
import enManageVehicles from "../../v1/locales/en/manageVehicles.json"
import frManageVehicles from "../../v1/locales/fr/manageVehicles.json"
import enMaintenanceHistoryDetails from "../../v1/locales/en/maintenaceHistoryDetails.json"
import frMaintenanceHistoryDetails from "../../v1/locales/fr/maintenaceHistoryDetails.json"
import caManageVehicles from "../../v1/locales/ca/manageVehicles.json"
import enVehicleReminderModal from "../../v1/locales/en/vehicleReminderModal.json"
import frVehicleReminderModal from "../../v1/locales/fr/vehicleReminderModal.json"
import frGloveboxComponent from "../../v1/locales/fr/gloveboxComponent.json"
import caGloveboxComponent from "../../v1/locales/ca/gloveboxComponent.json"
import enGloveboxComponent from "../../v1/locales/en/gloveboxComponent.json"
import enRecommendedService from "../../v1/locales/en/recommendedServiceModal.json"
import frRecommendedService from "../../v1/locales/fr/recommendedServiceModal.json"
import enVehicleEditModal from "../../v1/locales/en/vehicleEditModal.json"
import caVehicleEditModal from "../../v1/locales/ca/vehicleEditModal.json"
import frVehicleEditModal from "../../v1/locales/fr/vehicleEditModal.json"
import enSuggestedMaintenance from "../../v1/locales/en/suggestedMaintenance.json"
import frSuggestedMaintenance from "../../v1/locales/fr/suggestedMaintenance.json"
import caSuggestedMaintenance from "../../v1/locales/ca/suggestedMaintenance.json"
import enServiceDetail from "../../v1/locales/en/serviceDetail.json"
import enOrderDetails from "../../v1/locales/en/orderDetails.json"
import frOrderDetails from "../../v1/locales/fr/orderDetails.json"
import frServiceDetail from "../../v1/locales/fr/serviceDetail.json"
import enSuggestedIndicators from "../../v1/locales/en/suggestedIndicators.json"
import frSuggestedIndicators from "../../v1/locales/fr/suggestedIndicators.json"
import enMyGarageVehicleDetails from "../../v1/locales/en/myGarageVehicleDetails.json"
import caMyGarageVehicleDetails from "../../v1/locales/ca/myGarageVehicleDetails.json"
import frMyGarageVehicleDetails from "../../v1/locales/fr/myGarageVehicleDetails.json"
import enSummary from "../../v1/locales/en/summary.json"
import frSummary from "../../v1/locales/fr/summary.json"
import enCancelLink from "../../v1/locales/en/cancelLink.json"
import frCancelLink from "../../v1/locales/fr/cancelLink.json"
import enTimeInformation from "../../v1/locales/en/timeInformation.json"
import frTimeInformation from "../../v1/locales/fr/timeInformation.json"
import enAppointmentSelectorModal from "../../v1/locales/en/appointmentSelectorModal.json"
import frAppointmentSelectorModal from "../../v1/locales/fr/appointmentSelectorModal.json"
import enShopInformation from "../../v1/locales/en/shopInformation.json"
import frShopInformation from "../../v1/locales/fr/shopInformation.json"
import enReviewTable from "../../v1/locales/en/reviewTable.json"
import frReviewTable from "../../v1/locales/fr/reviewTable.json"
import enMaintenanceSummary from "../../v1/locales/en/maintenanceSummary.json"
import frMaintenanceSummary from "../../v1/locales/fr/maintenanceSummary.json"
import enMaintenanceHistoryRow from "../../v1/locales/en/maintenanceHistoryRow.json"
import frMaintenanceHistoryRow from "../../v1/locales/fr/maintenanceHistoryRow.json"
import enAddMaintenanceModal from "../../v1/locales/en/addMaintenanceModal.json"
import caAddMaintenanceModal from "../../v1/locales/ca/addMaintenanceModal.json"
import frAddMaintenanceModal from "../../v1/locales/fr/addMaintenanceModal.json"
import enLeftNav from "../../v1/locales/en/leftNav.json"
import frLeftNav from "../../v1/locales/fr/leftNav.json"
import enSideMenu from "../../v1/locales/en/sideMenu.json"
import frSideMenu from "../../v1/locales/fr/sideMenu.json"
import enHeader from "../../v1/locales/en/header.json"
import frHeader from "../../v1/locales/fr/header.json"
import enMembershipSelect from "../../v1/locales/en/membershipSelect.json"
import frMembershipSelect from "../../v1/locales/fr/membershipSelect.json"
import enMembershipCard from "../../v1/locales/en/membershipCard.json"
import frMembershipCard from "../../v1/locales/fr/membershipCard.json"
import enMembershipFlowCCDetails from "../../v1/locales/en/membershipFlowCCDetails.json"
import frMembershipFlowCCDetails from "../../v1/locales/fr/membershipFlowCCDetails.json"
import enApprovalsNew from "../../v1/locales/en/approvalsNew.json"
import frApprovalsNew from "../../v1/locales/fr/approvalsNew.json"
import enMyAccount from "../../v1/locales/en/myAccount.json"
import frMyAccount from "../../v1/locales/fr/myAccount.json"
import enSettings from "../../v1/locales/en/settings.json"
import frSettings from "../../v1/locales/fr/settings.json"
import enPaymentMethodListHeader from "../../v1/locales/en/paymentMethodListHeader.json"
import frPaymentMethodListHeader from "../../v1/locales/fr/paymentMethodListHeader.json"
import enPaymentList from "../../v1/locales/en/paymentList.json"
import frPaymentList from "../../v1/locales/fr/paymentList.json"
import enPaymentMethod from "../../v1/locales/en/paymentMethod.json"
import frPaymentMethod from "../../v1/locales/fr/paymentMethod.json"
import enPaymentMethodDetails from "../../v1/locales/en/paymentMethodDetails.json"
import frPaymentMethodDetails from "../../v1/locales/fr/paymentMethodDetails.json"
import enMembershipUpgradeModal from "../../v1/locales/en/membershipUpgradeModal.json"
import frMembershipUpgradeModal from "../../v1/locales/fr/membershipUpgradeModal.json"
import enMembershipUpgradeContent from "../../v1/locales/en/membershipUpgradeContent.json"
import frMembershipUpgradeContent from "../../v1/locales/fr/membershipUpgradeContent.json"
import enMembershipClauseAgreementSection from "../../v1/locales/en/membershipClauseAgreementSection.json"
import frMembershipClauseAgreementSection from "../../v1/locales/fr/membershipClauseAgreementSection.json"
import enMembershipTerms from "../../v1/locales/en/membershipTerms.json"
import frMembershipTerms from "../../v1/locales/fr/membershipTerms.json"
import enMembershipUpgradeCard from "../../v1/locales/en/membershipUpgradeCard.json"
import frMembershipUpgradeCard from "../../v1/locales/fr/membershipUpgradeCard.json"
import enMembershipBenefitsList from "../../v1/locales/en/membershipBenefitsList.json"
import frMembershipBenefitsList from "../../v1/locales/fr/membershipBenefitsList.json"
import enMembershipCongratsContent from "../../v1/locales/en/membershipCongratsContent.json"
import frMembershipCongratsContent from "../../v1/locales/fr/membershipCongratsContent.json"
import enCancelMembershipModal from "../../v1/locales/en/cancelMembershipModal.json"
import frCancelMembershipModal from "../../v1/locales/fr/cancelMembershipModal.json"
import enExtendMembershipModal from "../../v1/locales/en/extendMembershipModal.json"
import frExtendMembershipModal from "../../v1/locales/fr/extendMembershipModal.json"
import enMembershipSection from "../../v1/locales/en/membershipSection.json"
import frMembershipSection from "../../v1/locales/fr/membershipSection.json"
import enRoadsideInfoCta from "../../v1/locales/en/roadsideInfoCta.json"
import frRoadsideInfoCta from "../../v1/locales/fr/roadsideInfoCta.json"
import enRoadsideTooltip from "../../v1/locales/en/roadsideTooltip.json"
import caRoadsideTooltip from "../../v1/locales/ca/roadsideTooltip.json"
import frRoadsideTooltip from "../../v1/locales/fr/roadsideTooltip.json"
import enMembershipPanel from "../../v1/locales/en/membershipPanel.json"
import frMembershipPanel from "../../v1/locales/fr/membershipPanel.json"
import enMembershipCardFrontFormat from "../../v1/locales/en/membershipCardFrontFormat.json"
import frMembershipCardFrontFormat from "../../v1/locales/fr/membershipCardFrontFormat.json"
import enMembershipCardBackFormat from "../../v1/locales/en/membershipCardBackFormat.json"
import frMembershipCardBackFormat from "../../v1/locales/fr/membershipCardBackFormat.json"
import enMapCard from "../../v1/locales/en/MicroComponents/mapCard.json"
import frMapCard from "../../v1/locales/fr/MicroComponents/mapCard.json"
import enAccountMobile from "../../v1/locales/en/accountMobile.json"
import frAccountMobile from "../../v1/locales/fr/accountMobile.json"
import enDashboardsFooter from "../../v1/locales/en/dashboardFooter.json"
import frDashboardFooter from "../../v1/locales/fr/dashboardFooter.json"
import enDashboardMembership from "../../v1/locales/en/dashboardMembership.json"
import frDashboardMembership from "../../v1/locales/fr/dashboardMembership.json"
import enDashboardMessage from "../../v1/locales/en/dashboardMessage.json"
import frDashboardMessage from "../../v1/locales/fr/dashboardMessage.json"
import enDashboardServiceSearch from "../../v1/locales/en/dashboardServiceSearch.json"
import frDashboardServiceSearch from "../../v1/locales/fr/dashboardServiceSearch.json"
import enDashboardShopList from "../../v1/locales/en/dashboardShopList.json"
import frDashboardShopList from "../../v1/locales/fr/dashboardShopList.json"
import enDashboardVehicleComponent from "../../v1/locales/en/dashboardVehicleComponent.json"
import frDashboardVehicleComponent from "../../v1/locales/fr/dashboardVehicleComponent.json"
import enDashboardVWelcomeCard from "../../v1/locales/en/dashboardWelcomeCard.json"
import frDashboardVWelcomeCard from "../../v1/locales/fr/dashboardWelcomeCard.json"
import enVehicleHeader from "../../v1/locales/en/vehicleHeader.json"
import caVehicleHeader from "../../v1/locales/ca/vehicleHeader.json"
import frVehicleHeader from "../../v1/locales/fr/vehicleHeader.json"
import enOdometerDetailModal from "../../v1/locales/en/odometerDetailModal.json"
import caOdometerDetailModal from "../../v1/locales/ca/odometerDetailModal.json"
import frOdometerDetailModal from "../../v1/locales/fr/odometerDetailModal.json"
import enDashboard from "../../v1/locales/en/dashboard.json"
import frDashboard from "../../v1/locales/fr/dashboard.json"
import enSchedulePanel from "../../v1/locales/en/schedulePanel.json"
import frSchedulePanel from "../../v1/locales/fr/schedulePanel.json"
import enMaintenanceSchedule from "../../v1/locales/en/maintenanceSchedule.json"
import frMaintenanceSchedule from "../../v1/locales/fr/maintenanceSchedule.json"
import enShopOrderRatingModal from "../../v1/locales/en/shopOrderRatingModal.json"
import frShopOrderRatingModal from "../../v1/locales/fr/shopOrderRatingModal.json"
import enHighlightedCta from "../../v1/locales/en/highlightedCta.json"
import frHighlightedCta from "../../v1/locales/fr/highlightedCta.json"
import enAffiliationSiblingModal from "../../v1/locales/en/affiliationSiblingModal.json"
import frAffiliationSiblingModal from "../../v1/locales/fr/affiliationSiblingModal.json"
import enPriceReviewPanel from "../../v1/locales/en/priceReviewPanel.json"
import frPriceReviewPanel from "../../v1/locales/fr/priceReviewPanel.json"
import enAwaitingPanel from "../../v1/locales/en/awaitingPanel.json"
import frAwaitingPanel from "../../v1/locales/fr/awaitingPanel.json"
import enInProgressPanel from "../../v1/locales/en/inProgressPanel.json"
import frInProgressPanel from "../../v1/locales/fr/inProgressPanel.json"
import enInviteFriends from "../../v1/locales/en/inviteFriends.json"
import frInviteFriends from "../../v1/locales/fr/inviteFriends.json"
import enProductTutorialStartModal from "../../v1/locales/en/productTutorialStartModal.json"
import frProductTutorialStartModal from "../../v1/locales/fr/productTutorialStartModal.json"
import enReminderTutorialModal from "../../v1/locales/en/reminderTutorialModal.json"
import frReminderTutorialModal from "../../v1/locales/fr/reminderTutorialModal.json"
import enBrowseServices from "../../v1/locales/en/browseServices.json"
import frBrowseServices from "../../v1/locales/fr/browseServices.json"
import enNoServices from "../../v1/locales/en/noServices.json"
import frNoServices from "../../v1/locales/fr/noServices.json"
import enLoadingServices from "../../v1/locales/en/loadingServices.json"
import frLoadingServices from "../../v1/locales/fr/loadingServices.json"
import enOffers from "../../v1/locales/en/offers.json"
import frOffers from "../../v1/locales/fr/offers.json"
import enVin from "../../v1/locales/en/vin.json"
import caVin from "../../v1/locales/ca/vin.json"
import frVin from "../../v1/locales/fr/vin.json"
import enOrderServicePriceRow from "../../v1/locales/en/orderServicePriceRow.json"
import frOrderServicePriceRow from "../../v1/locales/fr/orderServicePriceRow.json"
import enGuaranteedPriceLink from "../../v1/locales/en/guaranteedPriceLink.json"
import frGuaranteedPriceLink from "../../v1/locales/fr/guaranteedPriceLink.json"
import enPricingPromise from "../../v1/locales/en/pricingPromise.json"
import frPricingPromise from "../../v1/locales/fr/pricingPromise.json"
import enCartPanel from "../../v1/locales/en/cartPanel.json"
import frCartPanel from "../../v1/locales/fr/cartPanel.json"
import enCartHeader from "../../v1/locales/en/cartHeader.json"
import frCartHeader from "../../v1/locales/fr/cartHeader.json"
import enCartButtons from "../../v1/locales/en/cartButtons.json"
import frCartButtons from "../../v1/locales/fr/cartButtons.json"
import enAddButtons from "../../v1/locales/en/addButtons.json"
import frAddButtons from "../../v1/locales/fr/addButtons.json"
import enNoPriceExplanation from "../../v1/locales/en/noPriceExplanation.json"
import frNoPriceExplanation from "../../v1/locales/fr/noPriceExplanation.json"
import enPositionSelector from "../../v1/locales/en/positionSelector.json"
import frPositionSelector from "../../v1/locales/fr/positionSelector.json"
import enPriceRange from "../../v1/locales/en/priceRange.json"
import frPriceRange from "../../v1/locales/fr/priceRange.json"
import enCalendarDropdown from "../../v1/locales/en/calendarDropdown.json"
import frCalendarDropdown from "../../v1/locales/fr/calendarDropdown.json"
import enAvailablePromotionsContent from "../../v1/locales/en/availablePromotionsContent.json"
import frAvailablePromotionsContent from "../../v1/locales/fr/availablePromotionsContent.json"
import enPromotionsModal from "../../v1/locales/en/promotionsModal.json"
import frPromotionsModal from "../../v1/locales/fr/promotionsModal.json"
import enConfirmOrder from "../../v1/locales/en/confirmOrder.json"
import frConfirmOrder from "../../v1/locales/fr/confirmOrder.json"
import enPaymentPanel from "../../v1/locales/en/paymentPanel.json"
import frPaymentPanel from "../../v1/locales/fr/paymentPanel.json"
import enReviewOrder from "../../v1/locales/en/reviewOrder.json"
import frReviewOrder from "../../v1/locales/fr/reviewOrder.json"
import enSummaryPanel from "../../v1/locales/en/summaryPanel.json"
import frSummaryPanel from "../../v1/locales/fr/summaryPanel.json"
import enUpsellPanel from "../../v1/locales/en/upsellPanel.json"
import frUpsellPanel from "../../v1/locales/fr/upsellPanel.json"
import enUserInfoPanel from "../../v1/locales/en/userInfoPanel.json"
import frUserInfoPanel from "../../v1/locales/fr/userInfoPanel.json"
import enVehicleInfoPanel from "../../v1/locales/en/vehicleInfoPanel.json"
import frVehicleInfoPanel from "../../v1/locales/fr/vehicleInfoPanel.json"
import caVehicleInfoPanel from "../../v1/locales/ca/vehicleInfoPanel.json"
import enSavingsRibbon from "../../v1/locales/en/savingsRibbon.json"
import frSavingsRibbon from "../../v1/locales/fr/savingsRibbon.json"
import enSelectShop from "../../v1/locales/en/selectShop.json"
import frSelectShop from "../../v1/locales/fr/selectShop.json"
import enShopList from "../../v1/locales/en/shopList.json"
import frShopList from "../../v1/locales/fr/shopList.json"
import enShopMapCard from "../../v1/locales/en/shopMapCard.json"
import frShopMapCard from "../../v1/locales/fr/shopMapCard.json"
import enShopMapView from "../../v1/locales/en/shopMapView.json"
import frShopMapView from "../../v1/locales/fr/shopMapView.json"
import enDiagnosis from "../../v1/locales/en/diagnosis.json"
import frDiagnosis from "../../v1/locales/fr/diagnosis.json"
import enMoreInfo from "../../v1/locales/en/moreInfo.json"
import frMoreInfo from "../../v1/locales/fr/moreInfo.json"
import enNotifications from "../../v1/locales/en/notifications.json"
import caNotifications from "../../v1/locales/ca/notifications.json"
import frNotifications from "../../v1/locales/fr/notifications.json"
import enDiscountFlag from "../../v1/locales/en/discountFlag.json"
import frDiscountFlag from "../../v1/locales/fr/discountFlag.json"
import enFairPrice from "../../v1/locales/en/fairPrice.json"
import frFairPrice from "../../v1/locales/fr/fairPrice.json"
import enRetailPriceRow from "../../v1/locales/en/retailPriceRow.json"
import frRetailPriceRow from "../../v1/locales/fr/retailPriceRow.json"
import enShopAppointmentPicker from "../../v1/locales/en/shopAppointmentPicker.json"
import frShopAppointmentPicker from "../../v1/locales/fr/shopAppointmentPicker.json"
import enScheduleServiceTutorialModal from "../../v1/locales/en/scheduleServiceTutorialModal.json"
import frScheduleServiceTutorialModal from "../../v1/locales/fr/scheduleServiceTutorialModal.json"
import enAccountDetails from "../../v1/locales/en/accountDetails.json"
import frAccountDetails from "../../v1/locales/fr/accountDetails.json"
import enCCDetails from "../../v1/locales/en/ccDetails.json"
import frCCDetails from "../../v1/locales/fr/ccDetails.json"
import enPassword from "../../v1/locales/en/password.json"
import frPassword from "../../v1/locales/fr/password.json"
import enRecall from "../../v1/locales/en/recall.json"
import frRecall from "../../v1/locales/fr/recall.json"
import enResetPassword from "../../v1/locales/en/resetPassword.json"
import frResetPassword from "../../v1/locales/fr/resetPassword.json"
import enSchedule from "../../v1/locales/en/schedule.json"
import frSchedule from "../../v1/locales/fr/schedule.json"
import enServiceList from "../../v1/locales/en/serviceList.json"
import frServiceList from "../../v1/locales/fr/serviceList.json"
import enSignIn from "../../v1/locales/en/signIn.json"
import frSignIn from "../../v1/locales/fr/signIn.json"
import enWarranty from "../../v1/locales/en/warranty.json"
import frWarranty from "../../v1/locales/fr/warranty.json"
import enGatherZipModal from "../../v1/locales/en/gatherZipModal.json"
import frGatherZipModal from "../../v1/locales/fr/gatherZipModal.json"
import enSelectCommonMaintenance from "../../v1/locales/en/selectCommonMaintenance.json"
import frSelectCommonMaintenance from "../../v1/locales/fr/selectCommonMaintenance.json"
import enApprovalsFollowup from "../../v1/locales/en/approvalsFollowup.json"
import frApprovalsFollowup from "../../v1/locales/fr/approvalsFollowup.json"
import enWalkInWarningModal from "../../v1/locales/en/walkInWarningModal.json"
import frWalkInWarningModal from "../../v1/locales/fr/walkInWarningModal.json"
import enApprovalComplete from "../../v1/locales/en/approvalComplete.json"
import frApprovalComplete from "../../v1/locales/fr/approvalComplete.json"
import enApprovalDetail from "../../v1/locales/en/approvalDetail.json"
import frApprovalDetail from "../../v1/locales/fr/approvalDetail.json"
import enService from "../../v1/locales/en/service.json"
import frService from "../../v1/locales/fr/service.json"
import enRegFooter from "../../v1/locales/en/regFooter.json"
import frRegFooter from "../../v1/locales/fr/regFooter.json"
import enSetupBraintree from "../../v1/locales/en/setupBraintree.json"
import frSetupBraintree from "../../v1/locales/fr/setupBraintree.json"
import enUser from "../../v1/locales/en/user.json"
import frUser from "../../v1/locales/fr/user.json"
import enMembership from "../../v1/locales/en/membership.json"
import frMembership from "../../v1/locales/fr/membership.json"
import enTires from "../../v1/locales/en/tires.json"
import frTires from "../../v1/locales/fr/tires.json"
import enOrders from "../../v1/locales/en/orders.json"
import frOrders from "../../v1/locales/fr/orders.json"
import enVehicles from "../../v1/locales/en/vehicles.json"
import frVehicles from "../../v1/locales/fr/vehicles.json"
import enPromotion from "../../v1/locales/en/promotion.json"
import frPromotion from "../../v1/locales/fr/promotion.json"
import enLanguageSelectModal from "../../v1/locales/en/languageSelectModal.json"
import frLanguageSelectModal from "../../v1/locales/fr/languageSelectModal.json"
import enBreadcrumbNav from "../../v1/locales/en/breadcrumbNav.json"
import frBreadcrumbNav from "../../v1/locales/fr/breadcrumbNav.json"
import enPurchaseCaradviseCashForm from "../../v1/locales/en/purchaseCaradviseCashForm.json"
import frPurchaseCaradviseCashForm from "../../v1/locales/fr/purchaseCaradviseCashForm.json"
import enWelcomeCards from "../../v1/locales/en/welcomeCards.json"
import frWelcomeCards from "../../v1/locales/fr/welcomeCards.json"
import { locales } from "caradvise_shared_components"
import { selectLanguage } from "../../v1/helpers/selectLanguage"
import enConfirmDetails from "../../v1/locales/en/confirmDetails.json"
import frConfirmDetails from "../../v1/locales/fr/confirmDetails.json"
import enWarrantyPlan from "../../v1/locales/en/warrantyPlan.json"
import frWarrantyPlan from "../../v1/locales/fr/warrantyPlan.json"
import enPlanDetails from "../../v1/locales/en/planDetails.json"
import frPlanDetails from "../../v1/locales/fr/planDetails.json"
import enDealsCard from "../../v1/locales/en/dealsCard.json"
import frDealsCard from "../../v1/locales/fr/dealsCard.json"
import enCarMileage from "../../v1/locales/en/carMileage.json"
import frCarMileage from "../../v1/locales/fr/carMileage.json"
import enChooseCoverage from "../../v1/locales/en/chooseCoverage.json"
import frChooseCoverage from "../../v1/locales/fr/chooseCoverage.json"
import enAutoGlass from "../../v1/locales/en/autoGlass.json"
import frAutoGlass from "../../v1/locales/fr/autoGlass.json"
import enCollisionRepair from "../../v1/locales/en/collisionRepair.json"
import frCollisionRepair from "../../v1/locales/fr/collisionRepair.json"
import enProfile from "../../v1/locales/en/profile.json"
import frProfile from "../../v1/locales/fr/profile.json"
import enDashboardAppointment from "../../v1/locales/en/dashboardAppointment.json"
import frDashboardAppointment from "../../v1/locales/fr/dashboardAppointment.json"
import enMaintenance from "../../v1/locales/en/maintenance.json"
import frMaintenance from "../../v1/locales/fr/maintenance.json"
import enRedirectWarning from "../../v1/locales/en/redirectWarning.json"
import frRedirectWarning from "../../v1/locales/fr/redirectWarning.json"
import enDashboardMapComponent from "../../v1/locales/en/dashboardMapComponent.json"
import frDashboardMapComponent from "../../v1/locales/fr/dashboardMapComponent.json"
import enTopTires from "../../v1/locales/en/topTires.json"
import frTopTires from "../../v1/locales/fr/topTires.json"
import enTireDetails from "../../v1/locales/en/tireDetails.json"
import frTireDetails from "../../v1/locales/fr/tireDetails.json"
import enConfirmTire from "../../v1/locales/en/confirmTire.json"
import frConfirmTire from "../../v1/locales/fr/confirmTire.json"
import enConfirmInstallation from "../../v1/locales/en/confirmInstallation.json"
import frConfirmInstallation from "../../v1/locales/fr/confirmInstallation.json"
import enConfirmInstallationDate from "../../v1/locales/en/confirmInstallationDate.json"
import frConfirmInstallationDate from "../../v1/locales/fr/confirmInstallationDate.json"
import enClearScreen from "../../v1/locales/en/clearScreen.json"
import frClearScreen from "../../v1/locales/fr/clearScreen.json"
import enTireSize from "../../v1/locales/en/tireSize.json"
import frTireSize from "../../v1/locales/fr/tireSize.json"
import enBooking from "../../v1/locales/en/booking.json"
import frBooking from "../../v1/locales/fr/booking.json"
import enTirePayment from "../../v1/locales/en/tirePayment.json"
import frTirePayment from "../../v1/locales/fr/tirePayment.json"
import enTireOrderDetails from "../../v1/locales/en/tireOrderDetails.json"
import frTireOrderDetails from "../../v1/locales/fr/tireOrderDetails.json"
import enBookingContact from "../../v1/locales/en/bookingContact.json"
import frBookingContact from "../../v1/locales/fr/bookingContact.json"
import enBookingDetail from "../../v1/locales/en/bookingDetails.json"
import frBookingDetail from "../../v1/locales/fr/bookingDetails.json"
import enBookingMembership from "../../v1/locales/en/bookingMembership.json"
import frBookingMembership from "../../v1/locales/fr/bookingMembership.json"
import enBookingCard from "../../v1/locales/en/bookingCard.json"
import frBookingCard from "../../v1/locales/fr/bookingCard.json"
import enBookingInstallLocation from "../../v1/locales/en/installLocation.json"
import frBookingInstallLocation from "../../v1/locales/fr/installLocation.json"
import enServicePositionsModal from "../../v1/locales/en/servicePositionsModal.json"
import frServicePositionsModal from "../../v1/locales/fr/servicePositionsModal.json"
import caServicePositionsModal from "../../v1/locales/ca/servicePositionsModal.json"
import enAddServices from "../../v1/locales/en/addService.json"
import frAddServices from "../../v1/locales/fr/addService.json"
import enCheckout from "../../v1/locales/en/checkout.json"
import frCheckout from "../../v1/locales/fr/checkout.json"
import enJustForYou from "../../v1/locales/en/justForYouCards.json"
import frJustForYou from "../../v1/locales/fr/justForYouCards.json"
import enInstructions from "../../v1/locales/en/instructions.json"
import frInstructions from "../../v1/locales/en/instructions.json"
import enMaintenanceCards from "../../v1/locales/en/maintenanceCards.json"
import frMaintenanceCards from "../../v1/locales/fr/maintenanceCards.json"
import enRewards from "../../v1/locales/en/rewards.json"
import frRewards from "../../v1/locales/fr/rewards.json"
import enEarnMilesSteps from "../../v1/locales/en/earnMilesSteps.json"
import frEarnMilesSteps from "../../v1/locales/fr/earnMilesSteps.json"
import enEastimatedMilesToEarn from "../../v1/locales/en/estimatedMilesToEarn.json"
import frEastimatedMilesToEarn from "../../v1/locales/fr/estimatedMilesToEarn.json"
import enInviteFriendsReawrds from "../../v1/locales/en/inviteFriendsRewards.json"
import frInviteFriendsReawrds from "../../v1/locales/fr/inviteFriendsRewards.json"
import enMilesEarnCard from "../../v1/locales/en/milesEarnCard.json"
import frMilesEarnCard from "../../v1/locales/fr/milesEarnCard.json"
import enRewardsIndex from "../../v1/locales/en/rewardsIndex.json"
import frRewardsIndex from "../../v1/locales/fr/rewardsIndex.json"
import enSponserPromotionCard from "../../v1/locales/en/sponserPromotionCard.json"
import frSponserPromotionCard from "../../v1/locales/fr/sponserPromotionCard.json"
import enEarnMilesPerOrder from "../../v1/locales/en/earnMilesPerOrder.json"
import frEarnMilesPerOrder from "../../v1/locales/fr/earnMilesPerOrder.json"
import enHowToUnlockMiles from "../../v1/locales/en/howToUnlockMiles.json"
import frHowToUnlockMiles from "../../v1/locales/fr/howToUnlockMiles.json"
import enApplyRewards from "../../v1/locales/en/applyRewards.json"
import frApplyRewards from "../../v1/locales/fr/applyRewards.json"
import enMilesInstructions from "../../v1/locales/en/milesInstructions.json"
import frMilesInstructions from "../../v1/locales/fr/milesInstructions.json"
import enHowRewardWorksSteps from "../../v1/locales/en/howRewardWorks.json"
import frHowRewardWorksSteps from "../../v1/locales/fr/howRewardWorks.json"
import enCuvrdCard from "../../v1/locales/en/cuvrdCard.json"
import frCuvrdCard from "../../v1/locales/fr/cuvrdCard.json"
import enVehicleVinDialog from "../../v1/locales/en/vehicleVinDialog.json"
import frVehicleVinDialog from "../../v1/locales/fr/vehicleVinDialog.json"
import enConfirmOrderDialog from "../../v1/locales/en/confirmOrderDialog.json"
import frConfirmOrderDialog from "../../v1/locales/fr/confirmOrderDialog.json"
import enTireCartWarning from "../../v1/locales/en/tireCartWarning.json"
import frTireCartWarning from "../../v1/locales/fr/tireCartWarning.json"
import enTreadDepthTracker from "../../v1/locales/en/treadDepthTracker.json"
import frTreadDepthTracker from "../../v1/locales/fr/treadDepthTracker.json"
import enTireFilters from "../../v1/locales/en/tireFilters.json"
import frTireFilters from "../../v1/locales/fr/tireFilters.json"

const {
  enApprovalsEmptyState,
  enApprovalsForm,
  enApprovalsFormHeader,
  enApprovalsFormFields,
  enApprovalsFormField,
  enApprovalsInfoModal,
  enApprovalsFormNotes,
  enApprovalsFormTotals,
  enApprovalsFormPaymentMethod,
  enApprovalsFormCancel,
  enApprovalsFormSubmit,
  enPriceInfoDisplay,
  enNoInformation,
  enOrderServiceModalInfo,
  enPriceExplanation,
  enApprovalToggleButton,
  enOrderServiceComment
} = locales.en
const {
  frApprovalsEmptyState,
  frApprovalsForm,
  frApprovalsFormHeader,
  frApprovalsFormFields,
  frApprovalsFormField,
  frApprovalsInfoModal,
  frApprovalsFormNotes,
  frApprovalsFormTotals,
  frApprovalsFormPaymentMethod,
  frApprovalsFormCancel,
  frApprovalsFormSubmit,
  frPriceInfoDisplay,
  frNoInformation,
  frOrderServiceModalInfo,
  frPriceExplanation,
  frApprovalToggleButton,
  frOrderServiceComment
} = locales.fr

i18n.use(initReactI18next).init({
  lng: selectLanguage(),
  ns: {
    namespaces: [
      "common",
      "vehicleDetails",
      "manageVehicles",
      "vehicleReminderModal",
      "vehicleEditModal",
      "gloveboxComponent",
      "maintenanceSummary",
      "maintenanceHistoryRow",
      "addMaintenanceModal",
      "suggestedMaintenance",
      "serviceDetail",
      "suggestedIndicators",
      "myGarageVehicleDetails",
      "summary",
      "cancelLink",
      "timeInformation",
      "appointmentSelectorModal",
      "shopInformation",
      "reviewTable",
      "approvalsNew",
      "myAccount",
      "settings",
      "paymentMethodListHeader",
      "paymentList",
      "paymentMethod",
      "paymentMethodDetails",
      "recommendedServiceModal",
      "membershipUpgradeModal",
      "membershipUpgradeContent",
      "membershipClauseAgreementSection",
      "membershipTerms",
      "membershipUpgradeCard",
      "membershipBenefitsList",
      "membershipCongratsContent",
      "cancelMembershipModal",
      "extendMembershipModal",
      "membershipSection",
      "roadsideInfoCta",
      "roadsideTooltip",
      "membershipPanel",
      "tires",
      "accountMobile",
      "dashboardFooter",
      "dashboardMapComponent",
      "dashboardMembership",
      "dashboardMessage",
      "dashboardServiceSearch",
      "dashboardShopList",
      "dashboardVehicleComponent",
      "dashboardWelcomeCard",
      "vehicleHeader",
      "odometerDetailModal",
      "dashboard",
      "schedulePanel",
      "maintenanceHistoryDetails",
      "maintenanceSchedule",
      "shopOrderRatingModal",
      "highlightedCta",
      "affiliationSiblingModal",
      "priceReviewPanel",
      "awaitingPanel",
      "inProgressPanel",
      "inviteFriends",
      "productTutorialStartModal",
      "reminderTutorialModal",
      "browseServices",
      "orderServicePriceRow",
      "guaranteedPriceLink",
      "pricingPromise",
      "cartPanel",
      "cartHeader",
      "cartButtons",
      "addButtons",
      "noPriceExplanation",
      "positionSelector",
      "priceRange",
      "calendarDropdown",
      "noServices",
      "loadingServices",
      "offers",
      "vin",
      "membershipSelect",
      "membershipCard",
      "membershipFlowCCDetails",
      "leftNav",
      "sideMenu",
      "header",
      "availablePromotionsContent",
      "promotionsModal",
      "confirmOrder",
      "paymentPanel",
      "reviewOrder",
      "summaryPanel",
      "upsellPanel",
      "userInfoPanel",
      "vehicleInfoPanel",
      "savingsRibbon",
      "selectShop",
      "shopList",
      "shopMapCard",
      "shopMapView",
      "diagnosis",
      "moreInfo",
      "notifications",
      "discountFlag",
      "fairPrice",
      "retailPriceRow",
      "shopAppointmentPicker",
      "priceList",
      "scheduleServiceTutorialModal",
      "accountDetails",
      "ccDetails",
      "orderDetails",
      "password",
      "recall",
      "resetPassword",
      "schedule",
      "selectVehicle",
      "serviceList",
      "signIn",
      "warranty",
      "gatherZipModal",
      "selectCommonMaintenance",
      "approvalsFollowup",
      "walkInWarningModal",
      "approvalComplete",
      "approvalDetail",
      "service",
      "approvalsEmptyState",
      "approvalsForm",
      "approvalsFormHeader",
      "approvalsFormFields",
      "approvalsFormField",
      "approvalsInfoModal",
      "approvalsFormNotes",
      "approvalsFormTotals",
      "approvalsFormPaymentMethod",
      "approvalsFormCancel",
      "approvalsFormSubmit",
      "approvalToggleButton",
      "priceInfoDisplay",
      "noInformation",
      "orderServiceComment",
      "orderServiceModalInfo",
      "priceExplanation",
      "setupBraintree",
      "regFooter",
      "user",
      "membership",
      "orders",
      "vehicles",
      "promotion",
      "languageSelectModal",
      "multiSelectServiceModal",
      "breadcrumbNav",
      "purchaseCaradviseCashForm",
      "welcomeCards",
      "confirmDetails",
      "warrantyPlan",
      "planDetails",
      "dealsCard",
      "carMileage",
      "chooseCoverage",
      "autoGlass",
      "collisionRepair",
      "maintenance",
      "redirectWarning",
      "topTires",
      "tireDetails",
      "confirmTire",
      "confirmInstallation",
      "confirmInstallationDate",
      "clearScreen",
      "tireSize",
      "booking",
      "tirePayment",
      "tireOrderDetails",
      "bookingContact",
      "bookingDetails",
      "bookingMembership",
      "bookingCard",
      "installLocation",
      "servicePositionsModal",
      "addService",
      "checkout",
      "justForYouCards",
      "instructions",
      "maintenanceCards",
      "rewards",
      "earnMilesSteps",
      "estimatedMilesToEarn",
      "inviteFriendsRewards",
      "milesEarnCard",
      "rewardsIndex",
      "sponserPromotionCard",
      "maintenanceCards",
      "earnMilesPerOrder",
      "howToUnlockMiles",
      "applyRewards",
      "milesInstructions",
      "howRewardWorksSteps",
      "cuvrdCard",
      "vehicleVinDialog",
      "confirmOrderDialog",
      "treadDepthTracker"
    ],
    defaultNS: "common"
  },
  resources: {
    en: {
      common: enCommon,
      vehicleDetails: enVehicleDetails,
      manageVehicles: enManageVehicles,
      gloveboxComponent: enGloveboxComponent,
      vehicleReminderModal: enVehicleReminderModal,
      vehicleEditModal: enVehicleEditModal,
      suggestedMaintenance: enSuggestedMaintenance,
      serviceDetail: enServiceDetail,
      suggestedIndicators: enSuggestedIndicators,
      myGarageVehicleDetails: enMyGarageVehicleDetails,
      summary: enSummary,
      cancelLink: enCancelLink,
      timeInformation: enTimeInformation,
      appointmentSelectorModal: enAppointmentSelectorModal,
      shopInformation: enShopInformation,
      reviewTable: enReviewTable,
      maintenanceSummary: enMaintenanceSummary,
      maintenanceHistoryRow: enMaintenanceHistoryRow,
      addMaintenanceModal: enAddMaintenanceModal,
      leftNav: enLeftNav,
      sideMenu: enSideMenu,
      header: enHeader,
      membershipSelect: enMembershipSelect,
      membershipCard: enMembershipCard,
      membershipFlowCCDetails: enMembershipFlowCCDetails,
      approvalsNew: enApprovalsNew,
      myAccount: enMyAccount,
      settings: enSettings,
      paymentMethodListHeader: enPaymentMethodListHeader,
      paymentList: enPaymentList,
      paymentMethod: enPaymentMethod,
      paymentMethodDetails: enPaymentMethodDetails,
      membershipUpgradeModal: enMembershipUpgradeModal,
      membershipUpgradeContent: enMembershipUpgradeContent,
      membershipClauseAgreementSection: enMembershipClauseAgreementSection,
      membershipTerms: enMembershipTerms,
      membershipUpgradeCard: enMembershipUpgradeCard,
      membershipBenefitsList: enMembershipBenefitsList,
      membershipCongratsContent: enMembershipCongratsContent,
      cancelMembershipModal: enCancelMembershipModal,
      extendMembershipModal: enExtendMembershipModal,
      membershipSection: enMembershipSection,
      roadsideInfoCta: enRoadsideInfoCta,
      roadsideTooltip: enRoadsideTooltip,
      accountMobile: enAccountMobile,
      dashboardFooter: enDashboardsFooter,
      dashboardMapComponent: enDashboardMapComponent,
      dashboardMembership: enDashboardMembership,
      dashboardMessage: enDashboardMessage,
      dashboardServiceSearch: enDashboardServiceSearch,
      dashboardShopList: enDashboardShopList,
      dashboardVehicleComponent: enDashboardVehicleComponent,
      dashboardWelcomeCard: enDashboardVWelcomeCard,
      vehicleHeader: enVehicleHeader,
      odometerDetailModal: enOdometerDetailModal,
      dashboard: enDashboard,
      schedulePanel: enSchedulePanel,
      maintenanceHistoryDetails: enMaintenanceHistoryDetails,
      maintenanceSchedule: enMaintenanceSchedule,
      recommendedServiceModal: enRecommendedService,
      shopOrderRatingModal: enShopOrderRatingModal,
      highlightedCta: enHighlightedCta,
      affiliationSiblingModal: enAffiliationSiblingModal,
      priceReviewPanel: enPriceReviewPanel,
      awaitingPanel: enAwaitingPanel,
      inProgressPanel: enInProgressPanel,
      inviteFriends: enInviteFriends,
      productTutorialStartModal: enProductTutorialStartModal,
      reminderTutorialModal: enReminderTutorialModal,
      browseServices: enBrowseServices,
      noServices: enNoServices,
      loadingServices: enLoadingServices,
      membershipPanel: enMembershipPanel,
      membershipCardFrontFormat: enMembershipCardFrontFormat,
      membershipCardBackFormat: enMembershipCardBackFormat,
      mapCard: enMapCard,
      offers: enOffers,
      vin: enVin,
      orderServicePriceRow: enOrderServicePriceRow,
      guaranteedPriceLink: enGuaranteedPriceLink,
      pricingPromise: enPricingPromise,
      cartPanel: enCartPanel,
      cartHeader: enCartHeader,
      cartButtons: enCartButtons,
      addButtons: enAddButtons,
      noPriceExplanation: enNoPriceExplanation,
      positionSelector: enPositionSelector,
      priceRange: enPriceRange,
      calendarDropdown: enCalendarDropdown,
      availablePromotionsContent: enAvailablePromotionsContent,
      promotionsModal: enPromotionsModal,
      confirmOrder: enConfirmOrder,
      paymentPanel: enPaymentPanel,
      reviewOrder: enReviewOrder,
      summaryPanel: enSummaryPanel,
      upsellPanel: enUpsellPanel,
      userInfoPanel: enUserInfoPanel,
      vehicleInfoPanel: enVehicleInfoPanel,
      savingsRibbon: enSavingsRibbon,
      selectShop: enSelectShop,
      shopList: enShopList,
      shopMapCard: enShopMapCard,
      shopMapView: enShopMapView,
      diagnosis: enDiagnosis,
      moreInfo: enMoreInfo,
      notifications: enNotifications,
      discountFlag: enDiscountFlag,
      fairPrice: enFairPrice,
      retailPriceRow: enRetailPriceRow,
      shopAppointmentPicker: enShopAppointmentPicker,
      scheduleServiceTutorialModal: enScheduleServiceTutorialModal,
      accountDetails: enAccountDetails,
      ccDetails: enCCDetails,
      password: enPassword,
      recall: enRecall,
      resetPassword: enResetPassword,
      schedule: enSchedule,
      serviceList: enServiceList,
      signIn: enSignIn,
      warranty: enWarranty,
      gatherZipModal: enGatherZipModal,
      selectCommonMaintenance: enSelectCommonMaintenance,
      approvalsFollowup: enApprovalsFollowup,
      walkInWarningModal: enWalkInWarningModal,
      approvalComplete: enApprovalComplete,
      approvalDetail: enApprovalDetail,
      service: enService,
      regFooter: enRegFooter,
      setupBraintree: enSetupBraintree,
      user: enUser,
      membership: enMembership,
      tires: enTires,
      orders: enOrders,
      orderDetails: enOrderDetails,
      vehicles: enVehicles,
      promotion: enPromotion,
      languageSelectModal: enLanguageSelectModal,
      approvalsEmptyState: enApprovalsEmptyState,
      approvalsForm: enApprovalsForm,
      approvalsFormHeader: enApprovalsFormHeader,
      approvalsFormFields: enApprovalsFormFields,
      approvalsFormField: enApprovalsFormField,
      approvalsInfoModal: enApprovalsInfoModal,
      approvalsFormNotes: enApprovalsFormNotes,
      approvalsFormTotals: enApprovalsFormTotals,
      approvalsFormPaymentMethod: enApprovalsFormPaymentMethod,
      approvalsFormCancel: enApprovalsFormCancel,
      approvalsFormSubmit: enApprovalsFormSubmit,
      approvalToggleButton: enApprovalToggleButton,
      priceInfoDisplay: enPriceInfoDisplay,
      noInformation: enNoInformation,
      orderServiceModalInfo: enOrderServiceModalInfo,
      orderServiceComment: enOrderServiceComment,
      priceExplanation: enPriceExplanation,
      multiSelectServiceModal: enMultiSelectServiceModal,
      breadcrumbNav: enBreadcrumbNav,
      purchaseCaradviseCashForm: enPurchaseCaradviseCashForm,
      welcomeCards: enWelcomeCards,
      confirmDetails: enConfirmDetails,
      warrantyPlan: enWarrantyPlan,
      planDetails: enPlanDetails,
      dealsCard: enDealsCard,
      carMileage: enCarMileage,
      chooseCoverage: enChooseCoverage,
      autoGlass: enAutoGlass,
      collisionRepair: enCollisionRepair,
      profile: enProfile,
      maintenance: enMaintenance,
      profile: enProfile,
      redirectWarning: enRedirectWarning,
      dashboardMapComponent: enDashboardMapComponent,
      dashboardAppointment: enDashboardAppointment,
      topTires: enTopTires,
      tireDetails: enTireDetails,
      confirmTire: enConfirmTire,
      confirmInstallation: enConfirmInstallation,
      confirmInstallationDate: enConfirmInstallationDate,
      clearScreen: enClearScreen,
      tireSize: enTireSize,
      booking: enBooking,
      tirePayment: enTirePayment,
      tireOrderDetails: enTireOrderDetails,
      bookingContact: enBookingContact,
      bookingDetails: enBookingDetail,
      bookingMembership: enBookingMembership,
      bookingCard: enBookingCard,
      installLocation: enBookingInstallLocation,
      sevicePositionsModal: enServicePositionsModal,
      addService: enAddServices,
      checkout: enCheckout,
      justForYouCards: enJustForYou,
      instructions: enInstructions,
      maintenanceCards: enMaintenanceCards,
      rewards: enRewards,
      earnMilesSteps: enEarnMilesSteps,
      estimatedMilesToEarn: enEastimatedMilesToEarn,
      inviteFriendsRewards: enInviteFriendsReawrds,
      milesEarnCard: enMilesEarnCard,
      rewardsIndex: enRewardsIndex,
      sponserPromotionCard: enSponserPromotionCard,
      maintenanceCards: enMaintenanceCards,
      earnMilesPerOrder: enEarnMilesPerOrder,
      howToUnlockMiles: enHowToUnlockMiles,
      applyRewards: enApplyRewards,
      milesInstructions: enMilesInstructions,
      howRewardWorksSteps: enHowRewardWorksSteps,
      cuvrdCard: enCuvrdCard,
      vehicleVinDialog: enVehicleVinDialog,
      confirmOrderDialog: enConfirmOrderDialog,
      tireCartWarning: enTireCartWarning,
      treadDepthTracker: enTreadDepthTracker,
      tireFilters: enTireFilters
    },
    "fr-CA": {
      common: frCommon,
      vehicleDetails: frVehicleDetails,
      manageVehicles: frManageVehicles,
      vehicleReminderModal: frVehicleReminderModal,
      vehicleEditModal: frVehicleEditModal,
      suggestedMaintenance: frSuggestedMaintenance,
      serviceDetail: frServiceDetail,
      suggestedIndicators: frSuggestedIndicators,
      gloveboxComponent: frGloveboxComponent,
      myGarageVehicleDetails: frMyGarageVehicleDetails,
      summary: frSummary,
      cancelLink: frCancelLink,
      timeInformation: frTimeInformation,
      appointmentSelectorModal: frAppointmentSelectorModal,
      shopInformation: frShopInformation,
      reviewTable: frReviewTable,
      maintenanceSummary: frMaintenanceSummary,
      maintenanceHistoryRow: frMaintenanceHistoryRow,
      addMaintenanceModal: frAddMaintenanceModal,
      leftNav: frLeftNav,
      sideMenu: frSideMenu,
      header: frHeader,
      membershipSelect: frMembershipSelect,
      membershipCard: frMembershipCard,
      membershipFlowCCDetails: frMembershipFlowCCDetails,
      approvalsNew: frApprovalsNew,
      myAccount: frMyAccount,
      settings: frSettings,
      paymentMethodListHeader: frPaymentMethodListHeader,
      paymentList: frPaymentList,
      paymentMethod: frPaymentMethod,
      paymentMethodDetails: frPaymentMethodDetails,
      recommendedServiceModal: enRecommendedService,
      membershipUpgradeModal: frMembershipUpgradeModal,
      membershipUpgradeContent: frMembershipUpgradeContent,
      membershipClauseAgreementSection: frMembershipClauseAgreementSection,
      membershipTerms: frMembershipTerms,
      membershipUpgradeCard: frMembershipUpgradeCard,
      membershipBenefitsList: frMembershipBenefitsList,
      membershipCongratsContent: frMembershipCongratsContent,
      cancelMembershipModal: frCancelMembershipModal,
      extendMembershipModal: frExtendMembershipModal,
      membershipSection: frMembershipSection,
      roadsideInfoCta: frRoadsideInfoCta,
      roadsideTooltip: frRoadsideTooltip,
      membershipPanel: frMembershipPanel,
      membershipCardFrontFormat: frMembershipCardFrontFormat,
      membershipCardBackFormat: frMembershipCardBackFormat,
      mapCard: frMapCard,
      accountMobile: frAccountMobile,
      dashboardFooter: frDashboardFooter,
      dashboardMapComponent: frDashboardMapComponent,
      dashboardMembership: frDashboardMembership,
      dashboardMessage: frDashboardMessage,
      dashboardServiceSearch: frDashboardServiceSearch,
      dashboardShopList: frDashboardShopList,
      dashboardVehicleComponent: frDashboardVehicleComponent,
      dashboardWelcomeCard: frDashboardVWelcomeCard,
      vehicleHeader: frVehicleHeader,
      odometerDetailModal: frOdometerDetailModal,
      dashboard: frDashboard,
      schedulePanel: frSchedulePanel,
      maintenanceHistoryDetails: frMaintenanceHistoryDetails,
      maintenanceSchedule: frMaintenanceSchedule,
      shopOrderRatingModal: frShopOrderRatingModal,
      highlightedCta: frHighlightedCta,
      affiliationSiblingModal: frAffiliationSiblingModal,
      priceReviewPanel: frPriceReviewPanel,
      awaitingPanel: frAwaitingPanel,
      inProgressPanel: frInProgressPanel,
      inviteFriends: frInviteFriends,
      productTutorialStartModal: frProductTutorialStartModal,
      reminderTutorialModal: frReminderTutorialModal,
      browseServices: frBrowseServices,
      noServices: frNoServices,
      loadingServices: frLoadingServices,
      offers: frOffers,
      vin: frVin,
      orderServicePriceRow: frOrderServicePriceRow,
      guaranteedPriceLink: frGuaranteedPriceLink,
      pricingPromise: frPricingPromise,
      cartPanel: frCartPanel,
      cartHeader: frCartHeader,
      cartButtons: frCartButtons,
      addButtons: frAddButtons,
      noPriceExplanation: frNoPriceExplanation,
      positionSelector: frPositionSelector,
      priceRange: frPriceRange,
      calendarDropdown: frCalendarDropdown,
      availablePromotionsContent: frAvailablePromotionsContent,
      promotionsModal: frPromotionsModal,
      confirmOrder: frConfirmOrder,
      paymentPanel: frPaymentPanel,
      reviewOrder: frReviewOrder,
      summaryPanel: frSummaryPanel,
      upsellPanel: frUpsellPanel,
      userInfoPanel: frUserInfoPanel,
      vehicleInfoPanel: frVehicleInfoPanel,
      savingsRibbon: frSavingsRibbon,
      selectShop: frSelectShop,
      shopList: frShopList,
      shopMapCard: frShopMapCard,
      shopMapView: frShopMapView,
      diagnosis: frDiagnosis,
      moreInfo: frMoreInfo,
      notifications: frNotifications,
      discountFlag: frDiscountFlag,
      fairPrice: frFairPrice,
      retailPriceRow: frRetailPriceRow,
      shopAppointmentPicker: frShopAppointmentPicker,
      scheduleServiceTutorialModal: frScheduleServiceTutorialModal,
      accountDetails: frAccountDetails,
      ccDetails: frCCDetails,
      password: frPassword,
      recall: frRecall,
      resetPassword: frResetPassword,
      schedule: frSchedule,
      serviceList: frServiceList,
      signIn: frSignIn,
      warranty: frWarranty,
      gatherZipModal: frGatherZipModal,
      selectCommonMaintenance: frSelectCommonMaintenance,
      approvalsFollowup: frApprovalsFollowup,
      walkInWarningModal: frWalkInWarningModal,
      approvalComplete: frApprovalComplete,
      approvalDetail: frApprovalDetail,
      service: frService,
      regFooter: frRegFooter,
      setupBraintree: frSetupBraintree,
      user: frUser,
      membership: frMembership,
      tires: frTires,
      orders: frOrders,
      orderDetails: frOrderDetails,
      vehicles: frVehicles,
      promotion: frPromotion,
      languageSelectModal: frLanguageSelectModal,
      approvalsEmptyState: frApprovalsEmptyState,
      approvalsForm: frApprovalsForm,
      approvalsFormHeader: frApprovalsFormHeader,
      approvalsFormFields: frApprovalsFormFields,
      approvalsFormField: frApprovalsFormField,
      approvalsInfoModal: frApprovalsInfoModal,
      approvalsFormNotes: frApprovalsFormNotes,
      approvalsFormTotals: frApprovalsFormTotals,
      approvalsFormPaymentMethod: frApprovalsFormPaymentMethod,
      approvalsFormCancel: frApprovalsFormCancel,
      approvalsFormSubmit: frApprovalsFormSubmit,
      approvalToggleButton: frApprovalToggleButton,
      priceInfoDisplay: frPriceInfoDisplay,
      noInformation: frNoInformation,
      orderServiceModalInfo: frOrderServiceModalInfo,
      orderServiceComment: frOrderServiceComment,
      priceExplanation: frPriceExplanation,
      multiSelectServiceModal: frMultiSelectServiceModal,
      breadcrumbNav: frBreadcrumbNav,
      purchaseCaradviseCashForm: frPurchaseCaradviseCashForm,
      welcomeCards: frWelcomeCards,
      confirmDetails: frConfirmDetails,
      warrantyPlan: frWarrantyPlan,
      planDetails: frPlanDetails,
      dealsCard: frDealsCard,
      carMileage: frCarMileage,
      chooseCoverage: frChooseCoverage,
      autoGlass: frAutoGlass,
      collisionRepair: frCollisionRepair,
      profile: frProfile,
      dashboardAppointment: frDashboardAppointment,
      maintenance: frMaintenance,
      redirectWarning: frRedirectWarning,
      topTires: frTopTires,
      tireDetails: frTireDetails,
      confirmTire: frConfirmTire,
      confirmInstallation: frConfirmInstallation,
      confirmInstallationDate: frConfirmInstallationDate,
      clearScreen: frClearScreen,
      tireSize: frTireSize,
      booking: frBooking,
      tirePayment: frTirePayment,
      tireOrderDetails: frTireOrderDetails,
      bookingContact: frBookingContact,
      bookingDetails: frBookingDetail,
      bookingMembership: frBookingMembership,
      bookingCard: frBookingCard,
      installLocation: frBookingInstallLocation,
      sevicePositionsModal: frServicePositionsModal,
      addService: frAddServices,
      checkout: frCheckout,
      justForYouCards: frJustForYou,
      instructions: frInstructions,
      maintenanceCards: frMaintenanceCards,
      rewards: frRewards,
      earnMilesSteps: frEarnMilesSteps,
      estimatedMilesToEarn: frEastimatedMilesToEarn,
      inviteFriendsRewards: frInviteFriendsReawrds,
      milesEarnCard: frMilesEarnCard,
      rewardsIndex: frRewardsIndex,
      sponserPromotionCard: frSponserPromotionCard,
      maintenanceCards: frMaintenanceCards,
      earnMilesPerOrder: frEarnMilesPerOrder,
      applyRewards: frApplyRewards,
      milesInstructions: frMilesInstructions,
      howRewardWorksSteps: frHowRewardWorksSteps,
      cuvrdCard: frCuvrdCard,
      vehicleVinDialog: frVehicleVinDialog,
      confirmOrderDialog: frConfirmOrderDialog,
      tireCartWarning: frTireCartWarning,
      treadDepthTracker: frTreadDepthTracker,
      tireFilters: frTireFilters
    },
    "en-CA": {
      manageVehicles: caManageVehicles,
      suggestedMaintenance: caSuggestedMaintenance,
      gloveboxComponent: caGloveboxComponent,
      myGarageVehicleDetails: caMyGarageVehicleDetails,
      notifications: caNotifications,
      roadsideTooltip: caRoadsideTooltip,
      vehicleEditModal: caVehicleEditModal,
      vehicleDetails: caVehicleDetails,
      vin: caVin,
      addMaintenanceModal: caAddMaintenanceModal,
      vehicleInfoPanel: caVehicleInfoPanel,
      vehicleHeader: caVehicleHeader,
      odometerDetailModal: caOdometerDetailModal,
      dashboardMapComponent: frDashboardMapComponent,
      sevicePositionsModal: caServicePositionsModal,
      howToUnlockMiles: frHowToUnlockMiles
    }
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (value instanceof Date) {
        moment.locale(lng)
        return moment(value).locale(lng).format(format)
      }
      if (format === "kilometers") {
        return Math.round(value / 0.62137)
      }
      return value
    }
  }
})

export default i18n
