import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import store from "../../../shared_component/utils/configureStore"
import { Image, Modal, Button, Grid, ModalHeader } from "semantic-ui-react"
import Notifications from "../../../v1/components/Notifications"
import { createMembership, getMembershipPlans } from "../../../v1/actions/membership"
import {
  nextMembershipUpgrades,
  usesMembership,
  isPremium,
  planFor,
  includesRoadside,
  ELITE
} from "../../../v1/helpers/membershipHelpers"
import { PERSONAL_INFO_SECTION, PAYMENT_SECTION } from "../../../v1/accountMobile"
import MiniLoader from "../../../v1/components/MiniLoader"
import { withTranslation } from "react-i18next"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import CAIconLogo from "../../../images/CA_CheckIcon.svg"
import ReactSVG from "react-svg"
import DialogCrossLogo from "../../../images/rewards/dialog-close.svg"
import MembershipUpgradeContent from "./MembershipUpgradeContent"
import MembershipAgreement from "./MembershipAgreement"
import MembershipCongratsContent from "../../../v1/components/Membership/MembershipCongratsContent"
import { BASIC } from "../../../v1/constants/tires"
import { updateInfo } from "../../../v1/actions/user"
import {
  gaJSON,
  subscriptionPurchasedEvents,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import {
  GRUBHUB,
  INSTACART,
  INSTACART_CA,
  SHIPT,
  UBER,
  UBER_CANADA,
  USAA
} from "../../../v1/constants/affiliations"
import { getSubdomain } from "../../../v1/components/Config/StylesheetInjector"

const UPGRADE = "upgrade"
const PAYMENT = "payment"
const USER_INFO = "userInfo"
const CONGRATS = "congrats"

const affiliationSubdomains = [UBER, UBER_CANADA, INSTACART, INSTACART_CA, SHIPT, USAA, GRUBHUB]
class MembershipUpgradeModal2_0 extends React.Component {
  state = {
    isSubmitting: false,
    activeContent: UPGRADE,
    selectedMembership: null,
    customMessage: null,
    selectedAccountTier: null
  }

  async componentDidMount() {
    const { user, getMembershipPlans, updateInfo } = this.props

    if (usesMembership(user)) {
      getMembershipPlans()
      this.setInitialMembership()
    }
    trackFirebaseAnalytics({
      event: gaJSON.subscription_plan_modal_displayed
    })
  }

  componentDidUpdate() {
    this.setInitialMembership()
  }

  setInitialMembership = () => {
    const { nextUpgrades, currentMembershipPlan } = this.props
    const { selectedMembership } = this.state
    if (nextUpgrades.length > 0 && !selectedMembership && currentMembershipPlan) {
      const plan = nextUpgrades
      this.setState({ selectedMembership: currentMembershipPlan })
      this.setState({ selectedAccountTier: currentMembershipPlan })
    }
  }

  attemptCreateMembership = (payload) => {
    const {
      paymentMethods,
      createMembership,
      user,
      t,
      currentMembershipPlan,
      updateInfo,
      activeMembership,
      closeModal
    } = this.props
    const { firstName, lastName, cellPhone, zip } = user
    const { selectedMembership } = this.state
    if (selectedMembership.name === ELITE) {
      selectedMembership.acceptedPassengerVehicleClause = true
    }
    const isBasic = selectedMembership && selectedMembership.name === BASIC

    const missingPayment = !paymentMethods || paymentMethods.length < 1
    const paymentMethodRequired =
      !selectedMembership.is_free_trial || !selectedMembership.trial_requires_opt_in
    const missingPersonalInfo =
      includesRoadside(selectedMembership) && (!firstName || !lastName || !cellPhone || !zip)
    const isAlreadyHasPlan =
      activeMembership && activeMembership.plan_name === selectedMembership.name
    if (isAlreadyHasPlan) {
      updateInfo({ send_through_membership_flow: false })
      return closeModal()
    } else if (missingPayment && paymentMethodRequired && missingPersonalInfo) {
      this.setState({
        activeContent: USER_INFO,
        customMessage: t("enterPaymentMethodAndAccountInfoMessage")
      })
    } else if (missingPayment && paymentMethodRequired && !isBasic) {
      this.setState({ activeContent: PAYMENT })
    } else if (missingPersonalInfo) {
      this.setState({ activeContent: USER_INFO })
    } else {
      this.setState({ isSubmitting: true }, async () => {
        const response = await createMembership({
          plan: selectedMembership,
          callback: this.afterRequest
        })
        if (response().result) {
          await subscriptionPurchasedEvents({ response: response().result })
          await identifyEvent({ traits: { selectedPlan: response().result.plan_name } })
          trackEvent("plan-type-chosen", {
            selectedPlan: response().result.plan_name,
            previousPlan: (currentMembershipPlan && currentMembershipPlan.name) || null,
            duringOnboarding: "No"
          })
          await updateInfo({ send_through_membership_flow: false })
        }
      })
    }
  }

  afterRequest = (status, data) => {
    const { t } = this.props
    this.setState({ isSubmitting: false }, () => {
      if (status === "success") {
        this.setState({ activeContent: CONGRATS })
      } else {
        store.dispatch(
          Notifications.error({
            title: t("errorNotificationTitle"),
            message: `${data.message || t("errorNotificationMessage")}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }

  onSelectMembership = (plan) => {
    this.setState({ selectedMembership: plan })
    this.setState({ selectedAccountTier: plan })
  }

  navigateToMyAccount = (section) => {
    const { closeModal, history, location } = this.props

    if (section == PAYMENT_SECTION) {
      history.push({
        pathname: "/payment_info",
        state: { section: section }
      })
    } else if (section == PERSONAL_INFO_SECTION) {
      history.push({
        pathname: "/personal_info",
        state: { section: section }
      })
    }
    closeModal()
  }

  contentConfig = () => {
    const { closeModal } = this.props
    const { selectedMembership } = this.state
    const { t } = this.props

    return {
      [UPGRADE]: {
        headerTitle: t("headerTitle"),
        buttonText: t("selectPlanBtn"),
        onButtonClick: this.attemptCreateMembership
      },
      [PAYMENT]: {
        headerTitle: t("upgradeToText", { membershipName: selectedMembership.name }),
        buttonText: t("addPaymentMethodBtn"),
        onButtonClick: () => this.navigateToMyAccount(PAYMENT_SECTION)
      },
      [USER_INFO]: {
        headerTitle: t("upgradeToText", { membershipName: selectedMembership.name }),
        buttonText: t("updateInfoBtn"),
        onButtonClick: () => this.navigateToMyAccount(PERSONAL_INFO_SECTION)
      },
      [CONGRATS]: {
        headerTitle: t("congratulationTitle"),
        buttonText: t("gotItBtn"),
        onButtonClick: closeModal
      }
    }[this.state.activeContent]
  }

  disableButton = () => {
    const { selectedMembership, selectedAccountTier } = this.state
    return selectedAccountTier && selectedMembership.acceptedTermsAndConditions
  }

  updateSelectedMembership = (payload) => {
    const { selectedMembership } = this.state
    this.setState({ selectedMembership: { ...selectedMembership, ...payload } })
  }

  render() {
    const {
      closeModal,
      user,
      nextUpgrades,
      variableContentType,
      preMembershipDiscountSubtotal,
      showUpgradeBenefits,
      currentMembershipPlan,
      availableMembershipPlans,
      isDashboardModal = false
    } = this.props
    const {
      activeContent,
      isSubmitting,
      selectedMembership,
      customMessage,
      selectedAccountTier
    } = this.state
    if (!selectedMembership) return null
    const config = this.contentConfig()
    const { t, isOpen } = this.props
    const affiliationSubdomains = [UBER, UBER_CANADA, INSTACART, INSTACART_CA, SHIPT, USAA, GRUBHUB]
    const isAffiliation = affiliationSubdomains.includes(getSubdomain())
    const isNotUpgrade = activeContent !== UPGRADE
    return (
      <Modal
        closeIcon={isDashboardModal ? false : true}
        open={isOpen}
        onClose={closeModal}
        closeOnDimmerClick={isDashboardModal ? false : true}
        closeOnEscape={isDashboardModal ? false : true}
        className={`membership-upgrade-modal-section ${
          isNotUpgrade ? "membership-congrats-modal" : ""
        }`}
      >
        {activeContent !== UPGRADE && (
          <ModalHeader className="membership-upgrade-modal-header">
            {config.headerTitle}
          </ModalHeader>
        )}
        {!isDashboardModal ? (
          <ReactSVG
            onClick={closeModal}
            src={DialogCrossLogo}
            className="membership-upgrade-cross-icon"
          />
        ) : null}
        {activeContent === UPGRADE && (
          <div className="membership-upgrade-modal">
            <div className="membership-upgrade-modal-heading">
              <span className="upgrade-membership-text">{t("upgradeMembershipText")}</span>
              <span className="unlock-saving-text">{t("unlockSavingsText")}</span>
            </div>
            <div className="upgrade-membership-sub-section">
              <Image className="upgrade-membership-caradvise-icon" src={CAIconLogo} />
              <p className="upgrade-membership-subtext">
                <span>{t("membershipSubText1")}</span>
                <span className="upgrade-membership-para">{t("membershipSubText2")}</span>
              </p>
            </div>
            <MembershipUpgradeContent
              variableContentType={variableContentType}
              nextUpgrades={nextUpgrades}
              preMembershipDiscountSubtotal={preMembershipDiscountSubtotal}
              onSelectMembership={this.onSelectMembership}
              selectedMembership={selectedMembership}
              updateSelectedMembership={this.updateSelectedMembership}
              currentMembership={user.membership || {}}
              availableMembershipPlans={availableMembershipPlans}
              currentMembershipPlan={currentMembershipPlan}
              isDashboardModal={isDashboardModal}
              isAffiliation={isAffiliation}
            />
            <MembershipAgreement
              updateSelectedMembership={this.updateSelectedMembership}
              selectedMembership={selectedMembership}
              selectedAccountTier={selectedAccountTier}
              nextUpgrades={nextUpgrades}
              isDashboardModal={isDashboardModal}
            />
          </div>
        )}
        {activeContent === PAYMENT && (
          <span className="membership-sub-text">
            {`${t("missingInfoMessage", { selectedMembership: selectedMembership.name })} `}
            {customMessage || t("paymentMethodMissingMessage")}
          </span>
        )}

        {activeContent === USER_INFO && (
          <span className="membership-sub-text">
            {`${t("missingInfoMessage", { selectedMembership: selectedMembership.name })} `}
            {customMessage || t("userInfoMissingMessage")}
          </span>
        )}

        {activeContent === CONGRATS && (
          <MembershipCongratsContent
            plan={currentMembershipPlan}
            showUpgradeBenefits={showUpgradeBenefits}
          />
        )}

        {
          <Grid centered columns={8}>
            <Button
              disabled={!this.disableButton()}
              className="membership-select-plan-btn"
              content={isSubmitting ? <MiniLoader /> : config.buttonText}
              onClick={config.onButtonClick}
            />
          </Grid>
        }
      </Modal>
    )
  }
}

MembershipUpgradeModal2_0.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variableContentType: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  preMembershipDiscountSubtotal: PropTypes.number
}

function mapStateToProps(state, ownProps) {
  let { user, membershipData, paymentMethods } = state
  const { availableMembershipPlans } = membershipData
  const isAffiliation = affiliationSubdomains.includes(getSubdomain())

  return {
    paymentMethods: paymentMethods.braintreePaymentMethods,
    user: user || {},
    availableMembershipPlans: availableMembershipPlans || [],
    currentMembershipPlan: planFor(user, availableMembershipPlans),
    nextUpgrades: isAffiliation
      ? availableMembershipPlans
      : nextMembershipUpgrades(user, availableMembershipPlans),
    createMembership,
    getMembershipPlans,
    activeMembership: user.membership || {}
  }
}

export default withRouter(
  connect(mapStateToProps, { createMembership, getMembershipPlans, updateInfo })(
    withTranslation("membershipUpgradeModal")(MembershipUpgradeModal2_0)
  )
)
