import React from "react"
import CuvrdPlanServiceList from "./CuvrdPlanServiceList"
import CuvrdPlanServiceHeader from "./CuvrdPlanServiceHeader"
import CuvrdNote from "./CuvrdNote"
import { replaceSubstring } from "../../helpers/cuvrdHelpers"
import { withTranslation } from "react-i18next"
import { SPECIAL_AND_CODE, SPECIAL_AND_SYMBOL } from "../../../shared_component/constants/cuvrd"

const CuvrdPlan = ({
  converedParts,
  notCoveredParts,
  title,
  headerLogo,
  beforeText,
  afterText,
  t
}) => {
  return (
    <div className="cuvrd-plan-container">
      <CuvrdPlanServiceHeader
        title={title ? replaceSubstring(title, SPECIAL_AND_CODE, SPECIAL_AND_SYMBOL) : ""}
        headerLogo={headerLogo}
      />
      {beforeText && <CuvrdNote note={beforeText} />}
      <CuvrdPlanServiceList
        parts={
          converedParts.length > 0
            ? replaceSubstring(converedParts, SPECIAL_AND_CODE, SPECIAL_AND_SYMBOL).split(", ")
            : notCoveredParts.length > 0
            ? replaceSubstring(
                replaceSubstring(notCoveredParts, t("partsThatArenotCovered"), ""),
                SPECIAL_AND_CODE,
                SPECIAL_AND_SYMBOL
              ).split(", ")
            : []
        }
      />
      {afterText && <CuvrdNote note={afterText} />}
    </div>
  )
}

export default withTranslation("common")(CuvrdPlan)
