export const CARADVISE = "caradvise"
export const CARVANA = "carvana"
export const EBAY = "ebay"
export const ALLSTATE = "allstate"
export const PAYLINK = "paylink"
export const USAA = "usaa"
export const UBER_CANADA = "uber-canada"
export const UBER = "uber"
export const DOORDASH = "doordash"
export const INSTACART = "instacart"
export const STRIDE = "stride"
export const AMAZON = "amazon"
export const FAVOR = "favor"
export const PLAYOCTOPUS = "playoctopus"
export const DASHERDIRECT = "dasherdirect"
export const GASBUDDY = "GasBuddy"
export const CLEARCOVER = "ClearCover"
export const SHIPT = "shipt"
export const INSTACART_CA = "instacart-ca"
export const GRUBHUB = "grubhub"
