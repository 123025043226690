import React from "react"
import { Image } from "semantic-ui-react"

export const DemandBanner = (props) => {
  const { iconUrl, titleText, text, width } = props
  return (
    <div className="banner-container" style={{ width: width ? width : "" }}>
      <div className="image-container">{iconUrl && <Image src={iconUrl} />}</div>
      <div className="content-container">
        <div className="title">{titleText && titleText}</div>
        {text && <div className="meta-text">{text && text}</div>}
      </div>
    </div>
  )
}
