import React from "react"
import PropTypes from "prop-types"

const Step = ({
  indicator,
  label,
  navigateToStepHandler,
  index,
  isActive,
  isComplete,
  isWarning,
  isError,
  isRightToLeftLanguage
}) => {
  const classes = ["stepper-step"]

  if (isActive) {
    classes.push("is-active")
  }
  if (isComplete) {
    classes.push("is-complete")
  }
  if (isWarning) {
    classes.push("is-warning")
  }
  if (isError) {
    classes.push("is-error")
  }
  if (isRightToLeftLanguage) {
    classes.push("rightToLeft")
  }

  return (
    <div className={classes.join(" ")}>
      <div className="stepper-indicator">
        <span
          className="stepper-indicator-info"
          onClick={isComplete || isError ? () => navigateToStepHandler(index) : null}
        >
          {isComplete ? index + 1 : indicator}
        </span>
      </div>
      <div className={`stepper-label ${index == 2 ? "review-label" : ""} `}>{label}</div>
    </div>
  )
}

Step.propTypes = {
  indicator: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
  label: PropTypes.string.isRequired,
  navigateToStepHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  isError: PropTypes.bool,
  isWarning: PropTypes.bool
}

export default Step
