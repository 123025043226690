import React from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import Step from "./Step"

const StepperHead = ({
  stepperContent,
  navigateToStepHandler,
  isVertical,
  isInline,
  currentTabIndex,
  history,
  t,
  pageContent
}) => (
  <>
    <div
      className="back-link-button"
      onClick={() => {
        routeChange(history, routerPaths.cartSummary)
      }}
    >
      <ReactSVG src="images/go_back_icon.svg" />
      <div className="back-link-button-name">{t("goToCartLabel")}</div>
    </div>
    <div className="step-wrapper">
      <div
        className={`stepper-head ${isVertical ? "vertical-stepper-head" : ""} ${
          isInline ? "inline-stepper-head" : ""
        }`}
      >
        {stepperContent.map((el, i) => (
          <Step
            key={i}
            index={i}
            navigateToStepHandler={navigateToStepHandler}
            isActive={el.name == pageContent}
            isComplete={el.isComplete}
            isWarning={el.isWarning}
            isError={el.isError}
            indicator={i + 1}
            label={el.label}
          />
        ))}
      </div>
    </div>
  </>
)

StepperHead.propTypes = {
  stepperContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.func.isRequired,
      clicked: PropTypes.func,
      isWarning: PropTypes.bool,
      isError: PropTypes.bool,
      isComplete: PropTypes.bool,
      isLoading: PropTypes.bool
    })
  ),
  navigateToStepHandler: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  isInline: PropTypes.bool,
  isVertical: PropTypes.bool,
  isRightToLeftLanguage: PropTypes.bool,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default StepperHead
