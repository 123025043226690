import { postJSON, putJSON, getJSON } from "../../shared_component/utils/fetch"
import Notifications from "../components/Notifications"
import { setLoading, setNotLoading, setUserData, setUserError, updateVehicle } from "./user"
import { constants } from "caradvise_shared_components"
import i18n from "../../shared_component/utils/i18n"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { getTransformedMaintenanceScheduleResponse } from "../helpers/vehicleHelpers"
import { getVehicleProps } from "../helpers/segmentHelper"
import { getMaintenanceSchedules } from "./maintenanceSchedules"
import { has, isEmpty } from "lodash"
import store from "../../shared_component/utils/configureStore"
import { gaJSON, trackFirebaseAnalytics } from "../../shared_component/utils/googleAnalyticsHelpers"

const {
  orders: { SEEKING_QUOTE }
} = constants
const LOAD_SELECTED_VEHICLE = "LOAD_SELECTED_VEHICLE"
const PLATE_TO_VIN_URL = "api/v4/vehicle_plate/convert_plate"
const VEHICLES_URL = "/api/v4/vehicles/"
const VEHICLE_MODAL_STEPS = "/api/v4/onboarding_resources/create_or_update"
const ONBOARDING_TYPE = "maintainance_schedule"
const ONBOARDING_ATTACHABLE_TYPE = "Vehicle"

export const selectVehicle = (selectedVehicle) => {
  return {
    type: LOAD_SELECTED_VEHICLE,
    selectedVehicle
  }
}

export const SET_ACTIVE_VEHICLE = "SET_ACTIVE_VEHICLE"
function setActiveVehicle(vehicleId) {
  // sendVehicleIdAlert(vehicleId)
  return {
    type: SET_ACTIVE_VEHICLE,
    activeVehicleId: vehicleId,
    loading: false
  }
}

export const ADD_NEW_VEHICLE_BY_VIN = "ADD_NEW_VEHICLE_BY_VIN"
export function addNewVehicleByVin(vin, odometer, forceActive = false, isNewUser = false) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    let user = getState().user || {}
    let { authentication_token } = user

    const handleResponse = async (response) => {
      if (!response.error) {
        user.loading = false
        user.error = null
        let vehicle = response.result.vehicle
        if (user.vehicles) user.vehicles.push(vehicle)
        else user.vehicles = [vehicle]

        if (!user.activeVehicleId || forceActive) {
          user.activeVehicleId = vehicle.id
          dispatch(setActiveVehicle(vehicle.id))
        }

        dispatch(setUserData(user))
        if (!has(vehicle, "maintenanceSchedules[0]")) {
          const maintenanceScheduleResponse = await dispatch(
            getMaintenanceSchedules({ vehicle: vehicle })
          )
          vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
            maintenanceScheduleResponse
          )
        }
        await identifyEvent({
          traits: getVehicleProps(vehicle),
          replaceUndefinedOrNullValues: false
        })
        trackFirebaseAnalytics({
          event: gaJSON.vehicle_added,
          params: {
            ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
            methodToAddVehicle: "Vin",
            duringOnboarding: !isNewUser ? "No" : "Yes"
          }
        })
        trackEvent("vehicle-added", {
          ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
          email: user.email,
          methodToAddVehicle: "Vin",
          duringOnboarding: !isNewUser ? "No" : "Yes"
        })
        dispatch(setNotLoading())
        return true
      } else {
        dispatch(setUserError(response.error))
        return false
      }
    }

    // Try to find a vehicle by the given VIN.
    const getResponse = await getJSON(
      `/api/v4/vehicles/search?search[vin]=${vin}`,
      {},
      { Authorization: authentication_token }
    )

    if (getResponse) {
      // The GET response was unsuccessful, so we go to create a vehicle by the given VIN.
      const postResponse = await postJSON(
        "/api/v4/vehicles",
        {
          vehicle: {
            vin: vin,
            odometer: odometer
          }
        },
        {
          Authorization: authentication_token
        }
      )

      return handleResponse(postResponse)
    } //else {
    //   // The initial GET response found a matching vehicle by the VIN, so we send a subsequent
    //   // PUT request to update the mileage.
    //   const updateResponse = await putJSON(
    //     `/api/v4/vehicles/${getResponse.result.vehicle.id}`,
    //     {
    //       odometer: odometer
    //     },
    //     {
    //       Authorization: authentication_token
    //     }
    //   )

    //   return handleResponse(updateResponse)
    // }
  }
}

export const ADD_NEW_VEHICLE_BY_PLATE = "ADD_NEW_VEHICLE_BY_PLATE"
export function addNewVehicleByPlate(plateNumber, plateState, odometer) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    let user = getState().user || {}
    let { authentication_token } = user

    let response = await getJSON(
      PLATE_TO_VIN_URL,
      { license_plate_number: plateNumber, license_plate_state: plateState },
      { Authorization: authentication_token }
    )

    if (!response.error) {
      user.loading = false
      user.error = null

      let vehicle = response.result
      await dispatch(addNewVehicleByVin(vehicle.vin, odometer))

      dispatch(setNotLoading())
      if (!has(vehicle, "maintenanceSchedules[0]")) {
        const maintenanceScheduleResponse = await dispatch(
          getMaintenanceSchedules({ vehicle: vehicle })
        )
        vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
          maintenanceScheduleResponse
        )
      }
      await identifyEvent({ traits: getVehicleProps(vehicle), replaceUndefinedOrNullValues: false })
      trackFirebaseAnalytics({
        event: gaJSON.vehicle_added,
        params: {
          ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
          methodToAddVehicle: "Manually",
          duringOnboarding: "No"
        }
      })
      trackEvent("vehicle-added", {
        ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
        email: user.email,
        methodToAddVehicle: "Manually",
        duringOnboarding: "No"
      })
      return true
    } else {
      dispatch(setUserError(`${i18n.t("common:invalidLicensePlateMsg")}`))
    }

    dispatch(setNotLoading())
    return false
  }
}

export const UPDATE_ACTIVE_VEHICLE_VIN_BY_PLATE = "UPDATE_ACTIVE_VEHICLE_VIN_BY_PLATE"
export function updateActiveVehicleVinByPlate(plateNumber, plateState) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    const user = getState().user || {}
    const { authentication_token, vehicles, activeVehicleId } = user
    const putUrl = `${VEHICLES_URL}${activeVehicleId}`
    const activeVehicleIndex = vehicles.findIndex((v) => v.id === activeVehicleId)
    let activeVehicle = vehicles[activeVehicleIndex]

    let response = await getJSON(
      PLATE_TO_VIN_URL,
      { license_plate_number: plateNumber, license_plate_state: plateState },
      { Authorization: authentication_token }
    )

    if (!response.error) {
      activeVehicle.vin = response.result.vin
      activeVehicle.license_plate_number = plateNumber
      activeVehicle.license_plate_state = plateState

      let putResponse = await putJSON(putUrl, activeVehicle, {
        Authorization: authentication_token
      })

      if (putResponse.result) {
        user.loading = false
        user.error = null
        user.vehicles.splice(activeVehicleIndex, 1, putResponse.result.vehicle)

        dispatch(
          Notifications.success({
            title: i18n.t("common:notificationSuccessTitle"),
            message: i18n.t("vehicles:vinUpdatedMessage"),
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Ok"
            }
          })
        )

        if (putResponse.result.vehicle.id === user.activeVehicleId)
          await identifyEvent({
            traits: getVehicleProps(putResponse.result.vehicle, { shouldAddCarsAdded: false }),
            replaceUndefinedOrNullValues: false
          })
        dispatch(setUserData(user))
        dispatch(setNotLoading())

        return true
      } else {
        dispatch(
          Notifications.error({
            title: i18n.t("common:notificationErrorTitle"),
            message: `${i18n.t("vehicles:vinUpdateErrorMessage")} ${putResponse.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )

        dispatch(setUserError(putResponse.error))
        dispatch(setNotLoading())

        return false
      }
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: `${i18n.t("vehicles:vinUpdateErrorMessage")} ${response.error}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )

      dispatch(setUserError(response.error))
      dispatch(setNotLoading())
    }

    dispatch(setNotLoading())

    return false
  }
}

export const UPDATE_ACTIVE_VEHICLE_VIN = "UPDATE_ACTIVE_VEHICLE_VIN"
export function updateActiveVehicleVin(vin) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token, vehicles, activeVehicleId } = user
    const activeVehicleIndex = vehicles.findIndex((v) => v.id === activeVehicleId)
    let activeVehicle = vehicles[activeVehicleIndex]
    const putUrl = `${VEHICLES_URL}${activeVehicle.id}`

    let response = await putJSON(
      putUrl,
      { vehicle: { ...activeVehicle, vin: vin } },
      { Authorization: authentication_token }
    )

    if (response.error) {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )
      return false
    } else {
      const updatedVehicle = response.result.vehicle || {}
      const updatedVehicles = user.vehicles.map((vehicle) => {
        return vehicle.id === updatedVehicle.id ? { ...vehicle, ...updatedVehicle } : vehicle
      })

      if (response.result.vehicle.id === user.activeVehicleId)
        await identifyEvent({
          traits: getVehicleProps(response.result.vehicle, { shouldAddCarsAdded: false }),
          replaceUndefinedOrNullValues: false
        })
      dispatch(setUserData({ ...user, vehicles: updatedVehicles }))
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("vehicles:vinUpdatedSuccessMessage"),
          action: { label: "Ok" }
        })
      )
      return true
    }
  }
}

export function updateActiveVehicle(vehicleId) {
  return function (dispatch, getState) {
    dispatch(setActiveVehicle(vehicleId))
  }
}

export function updateVehicleData({
  vehicleId,
  vin,
  miles,
  milesPerMonth,
  reminderDate,
  licensePlateNumber,
  licensePlateState,
  lastRecordedMileage,
  lastMaintenanceDate,
  nextMaintenanceDate
}) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user

    dispatch(setLoading())
    const response = await putJSON(
      VEHICLES_URL + vehicleId,
      {
        vehicle: {
          id: vehicleId,
          odometer: miles,
          odometer_per_month: milesPerMonth,
          reminder_date: reminderDate,
          vin: vin,
          license_plate_number: licensePlateNumber,
          license_plate_state: licensePlateState,
          last_maintenance_miles: lastRecordedMileage,
          last_maintenance_date: lastMaintenanceDate,
          next_maintenance_date: nextMaintenanceDate
        }
      },
      { Authorization: authentication_token }
    )

    if (response.error) {
      dispatch(setNotLoading())
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      dispatch(updateVehicle(response.result.vehicle))
      dispatch(setNotLoading())
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("vehicles:vehicleUpdatedMessage"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      if (response.result.vehicle.id === user.activeVehicleId) {
        if (!has(response.result.vehicle, "maintenanceSchedules[0]")) {
          const maintenanceScheduleResponse = await dispatch(
            getMaintenanceSchedules({ vehicle: response.result.vehicle })
          )
          response.result.vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
            maintenanceScheduleResponse
          )
        }
        await identifyEvent({
          traits: getVehicleProps(response.result.vehicle, { shouldAddCarsAdded: false }),
          replaceUndefinedOrNullValues: false
        })
      }
    }
    return () => response
  }
}

export function updateMaintenanceModalSteps({ vehicleId, currentStep }) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user

    const response = await postJSON(
      VEHICLE_MODAL_STEPS,
      {
        onboarding_attachable_id: vehicleId,
        step: currentStep,
        onboarding_attachable_type: ONBOARDING_ATTACHABLE_TYPE,
        onboarding_type: ONBOARDING_TYPE
      },
      { Authorization: authentication_token }
    )

    if (response.error) {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }

    return () => response
  }
}

export function updateModalVehicleData({
  vehicleId,
  vin,
  miles,
  milesPerMonth,
  reminderDate,
  licensePlateNumber,
  licensePlateState,
  lastRecordedMileage,
  lastMaintenanceDate,
  nextMaintenanceDate
}) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user

    dispatch(setLoading())
    const response = await putJSON(
      VEHICLES_URL + vehicleId,
      {
        vehicle: {
          id: vehicleId,
          odometer: miles,
          odometer_per_month: milesPerMonth,
          reminder_date: reminderDate,
          vin: vin,
          license_plate_number: licensePlateNumber,
          license_plate_state: licensePlateState,
          last_maintenance_miles: lastRecordedMileage,
          last_maintenance_date: lastMaintenanceDate,
          next_maintenance_date: nextMaintenanceDate
        }
      },
      { Authorization: authentication_token }
    )

    if (response.error) {
      dispatch(setNotLoading())
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    } else {
      dispatch(updateVehicle(response.result.vehicle))
      dispatch(setNotLoading())
      if (response.result.vehicle.id === user.activeVehicleId) {
        if (!has(response.result.vehicle, "maintenanceSchedules[0]")) {
          const maintenanceScheduleResponse = await dispatch(
            getMaintenanceSchedules({ vehicle: response.result.vehicle })
          )
          response.result.vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
            maintenanceScheduleResponse
          )
        }
      }
    }
    return () => response
  }
}

export function addNewNoVehicleByPlate(plateNumber, plateState, odometer) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    let user = getState().user || {}
    let { authentication_token } = user

    let response = await getJSON(
      PLATE_TO_VIN_URL,
      { license_plate_number: plateNumber, license_plate_state: plateState },
      { Authorization: authentication_token }
    )

    if (!response.error) {
      user.loading = false
      user.error = null

      const handleResponse = async (response) => {
        if (!response.error) {
          user.loading = false
          user.error = null
          let vehicle = response.result.vehicle
          if (user.vehicles) user.vehicles.push(vehicle)
          else user.vehicles = [vehicle]

          if (!user.activeVehicleId) {
            user.activeVehicleId = vehicle.id
            dispatch(setActiveVehicle(vehicle.id))
          }

          dispatch(setUserData(user))
          dispatch(setNotLoading())
          if (!has(vehicle, "maintenanceSchedules[0]")) {
            const maintenanceScheduleResponse = await dispatch(
              getMaintenanceSchedules({ vehicle: vehicle })
            )
            vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
              maintenanceScheduleResponse
            )
          }
          await identifyEvent({
            traits: getVehicleProps(vehicle),
            replaceUndefinedOrNullValues: false
          })
          trackFirebaseAnalytics({
            event: gaJSON.vehicle_added,
            params: {
              ...getVehicleProps(vehicle, {
                shouldAddCarsAdded: false,
                shouldRemoveNullKeys: true
              }),
              methodToAddVehicle: "License Plate",
              duringOnboarding: "Yes"
            }
          })
          trackEvent("vehicle-added", {
            ...getVehicleProps(vehicle, {
              shouldAddCarsAdded: false,
              shouldRemoveNullKeys: true
            }),
            email: user.email,
            methodToAddVehicle: "License Plate",
            duringOnboarding: "Yes"
          })
          return true
        } else {
          dispatch(setUserError(`${i18n.t("common:invalidLicensePlateMsg")}`))
          return false
        }
      }

      let vehicle = response.result
      // Try to find a vehicle by the given VIN.
      const getResponse = await getJSON(
        `/api/v4/vehicles/search?search[vin]=${vehicle.vin}`,
        {},
        { Authorization: authentication_token }
      )
      if (getResponse.error) {
        // The GET response was unsuccessful, so we go to create a vehicle by the given VIN.
        const postResponse = await postJSON(
          "/api/v4/vehicles",
          {
            vehicle: {
              vin: vehicle.vin,
              odometer: odometer,
              license_plate_number: plateNumber,
              license_plate_state: plateState
            }
          },
          {
            Authorization: authentication_token
          }
        )
        return handleResponse(postResponse)
      } else {
        dispatch(
          Notifications.error({
            title: i18n.t("common:notificationErrorTitle"),
            message: `${i18n.t("vehicles:vinAlreadyPresent")}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
        dispatch(setNotLoading())
        return false
      }
    } else {
      dispatch(setUserError(`${i18n.t("common:invalidLicensePlateMsg")}`))
    }
    dispatch(setNotLoading())
    return false
  }
}

export const ADD_NEW_NO_VEHICLE_BY_VIN = "ADD_NEW__NO_VEHICLE_BY_VIN"
export function addNewNoVehicleByVin(vin, odometer, forceActive = false, isNewUser = false) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    let user = getState().user || {}
    let { authentication_token } = user

    const handleResponse = async (response) => {
      if (!response.error) {
        user.loading = false
        user.error = null
        let vehicle = response.result.vehicle
        if (user.vehicles) user.vehicles.push(vehicle)
        else user.vehicles = [vehicle]

        if (!user.activeVehicleId || forceActive) {
          user.activeVehicleId = vehicle.id
          dispatch(setActiveVehicle(vehicle.id))
        }

        dispatch(setUserData(user))
        if (!has(vehicle, "maintenanceSchedules[0]")) {
          const maintenanceScheduleResponse = await dispatch(
            getMaintenanceSchedules({ vehicle: vehicle })
          )
          vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
            maintenanceScheduleResponse
          )
        }
        await identifyEvent({
          traits: getVehicleProps(vehicle),
          replaceUndefinedOrNullValues: false
        })
        trackFirebaseAnalytics({
          event: gaJSON.vehicle_added,
          params: {
            ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
            methodToAddVehicle: "Vin",
            duringOnboarding: !isNewUser ? "No" : "Yes"
          }
        })
        trackEvent("vehicle-added", {
          ...getVehicleProps(vehicle, { shouldAddCarsAdded: false, shouldRemoveNullKeys: true }),
          email: user.email,
          methodToAddVehicle: "Vin",
          duringOnboarding: !isNewUser ? "No" : "Yes"
        })
        dispatch(setNotLoading())
        return true
      } else {
        dispatch(setUserError(response.error))
        return false
      }
    }

    // Try to find a vehicle by the given VIN.
    const getResponse = await getJSON(
      `/api/v4/vehicles/search?search[vin]=${vin}`,
      {},
      { Authorization: authentication_token }
    )

    if (getResponse) {
      // The GET response was unsuccessful, so we go to create a vehicle by the given VIN.
      const postResponse = await postJSON(
        "/api/v4/vehicles",
        {
          vehicle: {
            vin: vin,
            odometer: odometer
          }
        },
        {
          Authorization: authentication_token
        }
      )
      return handleResponse(postResponse)
    } // else {
    //   dispatch(
    //     Notifications.error({
    //       title: i18n.t("common:notificationErrorTitle"),
    //       message: `${i18n.t("vehicles:vinAlreadyPresent")}`,
    //       position: "tr",
    //       autoDismiss: 5,
    //       action: {
    //         label: "Dismiss"
    //       }
    //     })
    //   )
    //   dispatch(setNotLoading())
    // }
    return false
  }
}

export const UPDATE_VIN_BY_PLATE_NUMBER = "UPDATE_VIN_BY_PLATE_NUMBER"
export function updateVinByPlateNumber(plateNumber, plateState) {
  return async function (dispatch, getState) {
    dispatch(setLoading())

    const user = getState().user || {}
    const { authentication_token, vehicles, activeVehicleId } = user
    const putUrl = `${VEHICLES_URL}${activeVehicleId}`
    const activeVehicleIndex = vehicles.findIndex((v) => v.id === activeVehicleId)

    let response = await getJSON(
      PLATE_TO_VIN_URL,
      { license_plate_number: plateNumber, license_plate_state: plateState },
      { Authorization: authentication_token }
    )

    if (!response.error) {
      let putResponse = await store.dispatch(
        updateVin(response.result.vin, {
          license_plate_number: plateNumber,
          license_plate_state: plateState
        })
      )

      if (putResponse.result) {
        user.loading = false
        user.error = null
        user.vehicles.splice(activeVehicleIndex, 1, putResponse.result.vehicle)

        dispatch(
          Notifications.success({
            title: i18n.t("common:notificationSuccessTitle"),
            message: i18n.t("vehicles:vinUpdatedMessage"),
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Ok"
            }
          })
        )

        if (putResponse.result.vehicle.id === user.activeVehicleId)
          await identifyEvent({
            traits: getVehicleProps(putResponse.result.vehicle, { shouldAddCarsAdded: false }),
            replaceUndefinedOrNullValues: false
          })
        dispatch(setUserData(user))
        dispatch(setNotLoading())

        return true
      } else {
        dispatch(
          Notifications.error({
            title: i18n.t("common:notificationErrorTitle"),
            message: `${i18n.t("vehicles:vinUpdateErrorMessage")} ${putResponse.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
        dispatch(setNotLoading())

        return false
      }
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: `${i18n.t("vehicles:vinUpdateErrorMessage")} ${response.error}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )

      dispatch(setNotLoading())
    }

    dispatch(setNotLoading())

    return false
  }
}

export const UPDATE_VIN = "UPDATE_VIN"
export function updateVin(vin, options = {}) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token, vehicles, activeVehicleId } = user
    const activeVehicleIndex = vehicles.findIndex((v) => v.id === activeVehicleId)
    let activeVehicle = vehicles[activeVehicleIndex]
    const putUrl = `${VEHICLES_URL}${activeVehicle.id}`
    if (options.license_plate_state && options.license_plate_number) {
      activeVehicle = {
        ...activeVehicle,
        license_plate_number: options.license_plate_number,
        license_plate_state: options.license_plate_state
      }
    }
    let response = await putJSON(
      putUrl,
      { vehicle: { ...activeVehicle, vin: vin } },
      { Authorization: authentication_token }
    )
    if (response.error) {
      if (!isEmpty(options)) {
        return response
      }
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )
      return false
    } else {
      const updatedVehicle = response.result.vehicle || {}
      const updatedVehicles = user.vehicles.map((vehicle) => {
        return vehicle.id === updatedVehicle.id ? { ...vehicle, ...updatedVehicle } : vehicle
      })

      if (response.result.vehicle.id === user.activeVehicleId)
        await identifyEvent({
          traits: getVehicleProps(response.result.vehicle, { shouldAddCarsAdded: false }),
          replaceUndefinedOrNullValues: false
        })
      dispatch(setUserData({ ...user, vehicles: updatedVehicles }))
      if (!isEmpty(options)) {
        return response
      }
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("vehicles:vinUpdatedSuccessMessage"),
          action: { label: "Ok" }
        })
      )
      return true
    }
  }
}
