import React from "react"
import { withTranslation } from "react-i18next"
import SponserPromotionCard from "./SponserPromotionCard"
import PepboysLogo from "../../../images/rewards/pepboys.svg"
import ServicesLogo from "../../../images/rewards/services.svg"
import { withRouter } from "react-router-dom"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { SPONSER_PROMOTION_LIST } from "../../../shared_component/constants/rewards"

const SponserPromotions = ({ t, history }) => {
  return (
    <div className="sponser-promotion">
      <div className="sponser-promotion-box">
        <p className="sponser-promotion-header">{t("sponsorPromotion")}</p>
        <p
          className="sponser-promotion-see-all"
          onClick={() => {
            routeChange(history, routerPaths.sponsoredPromotions)
          }}
        >
          {t("seeAll")}
        </p>
      </div>

      <div className="sponser-promotion-card">
        {SPONSER_PROMOTION_LIST.map((sponsers) => (
          <SponserPromotionCard key={sponsers.id} sponsers={sponsers} />
        ))}
      </div>
    </div>
  )
}

export default withRouter(withTranslation("sponserPromotionCard")(SponserPromotions))
