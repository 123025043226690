import React from "react"
import { Link } from "react-router-dom"
import ReactSVG from "react-svg"
import { Button, Dropdown, Image } from "semantic-ui-react"
import { formatParagraph } from "../../helpers/stringHelpers"
import { Chip } from "../../../shared_component/elements/Chip"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import CheckIcon from "../../../images/CheckIcon.svg"
import RemoveIcon from "../../../images/delete_icon_vector.svg"
import SheildIcon from "../../../images/warranty-logo.svg"
import DefaultLogo from "../../../images/NoLogoAvailable.png"
import CartIcon from "../../../images/icon-cart.svg"
import defaultTire from "../../../images/DefaultTire.png"
import { formatPriceNumber } from "../../../shared_component/utils/helpers"
import { routerPaths } from "../../constants/paths"
import { NO, YES } from "../../constants/order"
import { DEFAULT_TIRE_QUENTY } from "../../constants/tires"

export const TireDetailsSize = ({
  tire,
  tireQty,
  changeHandler,
  selectedTire,
  openDeleteModal,
  handleAddToCartBtn,
  t,
  objectCollection,
  saveSelectedTire
}) => {
  const onImageError = (e) => {
    e.target.src = defaultTire
  }

  const dataprovider = objectCollection.map((object) => {
    return { key: object.id, value: object, text: object.label }
  })

  return (
    <div className="tire-card" key={tire.id}>
      <div className="tire-image-container">
        <Image
          src={tire && tire.image ? tire.image : defaultTire}
          onError={onImageError}
          className="tire-size-image"
        />
        <div className="tire-original-description-section">
          <ReactSVG src={CheckIcon} />
          {t("originalLabel")}
        </div>
      </div>
      <div className="tire-size-details-container">
        <div className="tire-size-details-section">
          <div className="tire-size-company-details">
            <Image
              src={tire.brand_logo || tire.image || DefaultLogo}
              className="tire-image-brand-logo"
            />
            <div className="tire-brand-miles-details">
              {tire.mileage_rating ? (
                <Chip
                  icon={SheildIcon}
                  label={`${formatPriceNumber(tire.mileage_rating)} ${t(
                    "confirmOrderDialog:miles"
                  )} ${t("confirmOrderDialog:warranty")}`}
                  backgroundColor={"#F8F8F8"}
                />
              ) : null}
              {tire.season ? (
                <Chip
                  label={formatParagraph(`${t("seasonLabel")}: *${tire.season}*`, 600)}
                  backgroundColor={"#FEF6F4"}
                />
              ) : null}
            </div>
          </div>
          <div className="tire-size-details">
            <div className="tire-name">{tire && tire.brand + " " + tire.model}</div>
            <div className="tire-size-details-section">
              <span className="tire-size-detail"> {tire && tire.size}</span>
              <Link to={routerPaths.tireDetails} className="details_link">
                <span onClick={() => saveSelectedTire(tire)}>{t("seeDetailsLabel")}</span>
              </Link>
            </div>
            <div className="tire-size-config-section">
              <div className="tire-size-config">
                {t("runFlatLabel")} <strong>{tire.run_flat ? YES : NO}</strong>
              </div>
              <div className="tire-size-config">
                {t("loadRangeLabel")} <strong>{tire.load_range ? tire.load_range : "-"}</strong>
              </div>
              <div className="tire-size-config">
                {t("speedRatingLabel")}{" "}
                <strong>{tire.speed_rating ? tire.speed_rating : "-"}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="tire-size-cart-section">
          <div className="tire-cart-summary">
            <div className="tire-price-section">
              <div className="tire-price">{tire && numberFormatToCurrency(tire.price)}</div>
              <div className="tire-price-summary">
                {tireQty && tireQty.filter((item) => item.tireId === tire.id).length > 0
                  ? `${numberFormatToCurrency(
                      tireQty.filter((item) => item.tireId === tire.id)[0].quantity * tire.price
                    )} ${t("setForLabel")} ${
                      tireQty.filter((item) => item.tireId === tire.id)[0].quantity
                    }`
                  : `${numberFormatToCurrency(DEFAULT_TIRE_QUENTY * tire.price)} ${t(
                      "setForLabel"
                    )} ${DEFAULT_TIRE_QUENTY}`}
              </div>
            </div>
            <div>
              <Dropdown
                options={dataprovider}
                onChange={(event, data) => changeHandler(event, data, tire)}
                className="tire-qty-dropdown"
                placeholder={
                  tireQty && tireQty.filter((item) => item.tireId === tire.id).length > 0
                    ? tireQty.filter((item) => item.tireId === tire.id)[0].quantity
                    : DEFAULT_TIRE_QUENTY
                }
              />
            </div>
          </div>
          <div className="add-button">
            <Button
              className={
                Object.keys(selectedTire).length === 0
                  ? "shop-btn"
                  : selectedTire.id === tire.id
                  ? "shop-btn-selected"
                  : "shop-btn-disabled"
              }
              onClick={() =>
                selectedTire.id === tire.id ? openDeleteModal(tire) : handleAddToCartBtn(tire)
              }
            >
              <ReactSVG
                src={selectedTire.id === tire.id ? RemoveIcon : CartIcon}
                className="shop-button-icon"
              />
              {selectedTire.id === tire.id ? t("removeFromCartLabel") : t("Add_to_cart")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
