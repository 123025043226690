import i18n from "./i18n"

export function toCurrencyString(value) {
  if (value == undefined || value === "") {
    // check null or undefined or empty string
    return ""
  } else {
    const num = typeof value === "number" ? value : Number(value)
    return `$${num.toFixed(2)}`
  }
}

export const numberFormatToCurrency = (value) => {
  if (value == null) return ""

  return new Intl.NumberFormat(getCurrencyLocale(i18n.language), {
    style: "currency",
    currency: getCurrencyCode(i18n.language)
  }).format(value)
}

export function getCurrencyCode(lng) {
  switch (lng && lng.toLowerCase()) {
    case "en":
      return "USD"
    case "en-ca":
      return "CAD"
    case "fr-ca":
      return "CAD"
  }
}

export function getCurrencyLocale(lng) {
  switch (lng && lng.toLowerCase()) {
    case "en":
      return "en"
    case "en-ca":
      return "en"
    case "fr-ca":
      return "fr-CA"
  }
}
