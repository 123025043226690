import React, { Component } from "react"
import { connect } from "react-redux"
import { signIn, signOut } from "../actions/user"
import { setLoading } from "../actions/user"
import Button from "../components/Button"
import { Link } from "react-router-dom"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import MiniLoader from "../components/MiniLoader"
import { withTranslation, Trans } from "react-i18next"
import { routerPaths } from "../constants/paths"
import { hashRouteWithParams } from "../../shared_component/utils/urlUtils"
import { selectLanguage } from "../helpers/selectLanguage"
import AppBannerSignIn from "./AppBannerSignIn"
import { CLEARCOVER, GASBUDDY, GRUBHUB } from "../constants/affiliations.js"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import ReCAPTCHA from "react-google-recaptcha"
import CompanyLogoHeader from "../components/SignUp/component/CompanyLogoHeader"
import { FS_NAMESPACES } from "../constants/application"
import { getSubdomain } from "../components/Config/StylesheetInjector"
import { setupSplitFlags } from "../components/Config/SplitClient"
import { Image, Button as SemanticButton } from "semantic-ui-react"
import MagicIcon from "../../images/icons/magic_icon.svg"
import { APP_NAME } from "../../shared_component/constants/common.js"
import ReactSVG from "react-svg"
const recaptchaRef = React.createRef()

class SignIn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        email: { name: "Email", value: "", invalid: false, validators: ["_isPresent"] },
        password: { name: "Password", value: "", invalid: false, validators: ["_isPresent"] }
      },
      isSigningIn: false,
      isRecaptchaChecked: false,
      showV2: true
    }

    this.keyPress = this.keyPress.bind(this)
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain(), app_name: APP_NAME })
  }

  signOutGeneric = (shouldCallTrackEvent = true) => {
    if (shouldCallTrackEvent) trackEvent("click-uber-driver-signin")
    this.props.signOut()
  }

  changedRecaptcha(val) {
    if (val.length > 0) {
      this.props.setState({ isRecaptchaChecked: true })
    } else {
      this.props.setState({ isRecaptchaChecked: false })
    }
  }

  render() {
    const { user, isLoggedIn, t, location } = this.props
    if (isLoggedIn && !user.generic) {
      window.location = hashRouteWithParams(routerPaths.dashboard)
    }
    const params = new URLSearchParams(`?${window.location.href.split("?")[1]}`)
    const affiliation = params.get("affiliation")
    const bannerHide = [GASBUDDY, CLEARCOVER].includes(affiliation)

    const { isFromNoVehicle } = (location.params && location.params) || false

    if (bannerHide) {
      return (
        <div className="container" style={{ width: "100%" }}>
          <div className="contentCenter">
            <p className="introText">{t("signInBelowText")}</p>
            <div>
              <p className="signInMessage">
                <Trans>{t("firstTimeUserDesc")}</Trans>
              </p>
              <input
                data-qa="signup-email-input"
                className="inputFld"
                ref="email"
                placeholder={t("emailPlaceholder")}
                onChange={(value) => this._onFieldChange("email", value.target.value)}
              />
              <br />
              <input
                data-qa="signup-password-input"
                type="password"
                className="inputFld"
                ref="password"
                placeholder={t("passwordPlaceholder")}
                onChange={(value) => this._onFieldChange("password", value.target.value)}
                onKeyDown={this.keyPress}
              />
              <br />
              <p className="signInMessageTheGeneral disclaimer">
                <Trans>{t("byClickSigninDesc")}</Trans>
              </p>
              <p className="disclaimer">
                {t("signinDesc")}
                <br />
                <a
                  className="primary-link-orange"
                  href="http://caradvise.com/privacy/"
                  target="_blank"
                >
                  {t("privacyPolicyLbl")}
                </a>
                {` ${t("andLbl")} `}
                <a
                  className="primary-link-orange"
                  href="http://caradvise.com/terms/"
                  target="_blank"
                >
                  {t("termsOfUseLbl")}
                </a>
                .
              </p>
              {this.state.isReCaptchaflagEnabled && this.state.isReCaptchaflagEnabled && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  hl={selectLanguage()}
                  className="g-recaptcha"
                  sitekey="6LeUpj4hAAAAABS0te5UEjImtPjlKY33ggB01jBe"
                  onChange={this.changedRecaptcha}
                  props={this}
                />
              )}
              <Button
                data-qa="signup-sign-in-button"
                text={this.state.isSigningIn ? <MiniLoader /> : <b>{t("signinText")}</b>}
                colorStyle="orange"
                disabled={this.state.isSigningIn}
                onClick={() => this.onClickSignIn(isFromNoVehicle)}
              />
              {getSubdomain() !== GRUBHUB && (
                <>
                  <p className="signInUber">
                    <span>
                      <strong className="secondary-font">{t("driveOrDeliverWithUberText")}</strong>
                    </span>
                    <a
                      data-qa="signin-uber-link"
                      href={`${process.env.REACT_APP_API_URL}/api/v4/customers/auth/uber`}
                      onClick={this.signOutGeneric}
                      className="signIn"
                    >
                      {t("clickHereLbl")}
                    </a>
                  </p>
                  {[/localhost/i, /ngrok/i, /development/i, /staging/i].find((m) =>
                    process.env.REACT_APP_API_URL.match(m)
                  ) && (
                    <div>
                      <span>
                        <strong className="secondary-font">
                          {t("uberRideshareInspectionDesc")}
                        </strong>
                      </span>
                      <a
                        data-qa="signin-uber-link"
                        href={`${process.env.REACT_APP_API_URL}/api/v4/customers/auth/uber?add_services%5Bservices%5D%5B%5D%5Breference_id%5D=rideshare_visual_inspection`}
                        onClick={this.signOutGeneric}
                        className="signIn"
                      >
                        {t("clickHereText")}
                      </a>
                    </div>
                  )}
                </>
              )}
              <p data-qa="signup-create-account-link" className="secondary-font">
                {t("orLbl")}{" "}
                <Link
                  to={{ pathname: routerPaths.signUp1, search: location.search }}
                  className="signIn"
                  onClick={() => trackEvent("signup-started")}
                >
                  {t("createAccountLbl")}
                </Link>
              </p>
            </div>
            <p>
              <a data-qa="signup-forgot-password-link" href="/#/password" className="forgotPwd">
                {t("forgotPasswordLbl")}
              </a>
              <br />
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="signup-container">
          <div className="signup-left responsive-large-signup">
            <ReactSVG
              src={`/images/session-info-image.svg`}
              style={{
                position: "relative",
                height: "100vh",
                left: "50%",
                width: "50vw",
                transform: "translateX(-50%)"
              }}
            />
          </div>
          <div className="signup-right">
            <CompanyLogoHeader />
            <div className="signup-right-content-wrapper">
              <div className="signup-right-form-section">
                <div className="contentCenter">
                  <h1 className="introText">{t("signInText")}</h1>
                  <div>
                    <input
                      data-qa="signup-email-input"
                      className="inputFld"
                      style={{ padding: "6%", marginTop: "6%" }}
                      ref="email"
                      placeholder={t("emailPlaceholder")}
                      onChange={(value) => this._onFieldChange("email", value.target.value)}
                    />
                    <br />
                    <input
                      data-qa="signup-password-input"
                      type="password"
                      className="inputFld"
                      style={{ padding: "6%", marginTop: "6%" }}
                      ref="password"
                      placeholder={t("passwordPlaceholder")}
                      onChange={(value) => this._onFieldChange("password", value.target.value)}
                      onKeyDown={this.keyPress}
                    />
                    <br />
                    <a
                      data-qa="signup-forgot-password-link"
                      href="/#/password"
                      className="forgotPwd"
                      style={{
                        float: "left",
                        // color: "#B3B3B3",
                        marginRight: "3em"
                      }}
                    >
                      {t("forgotPasswordLbl")}
                    </a>
                    <br />
                    {this.state.isReCaptchaflagEnabled && this.state.isReCaptchaflagEnabled && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        hl={selectLanguage()}
                        className="g-recaptcha"
                        sitekey="6LeUpj4hAAAAABS0te5UEjImtPjlKY33ggB01jBe"
                        onChange={this.changedRecaptcha}
                        props={this}
                      />
                    )}
                    <Button
                      data-qa="signup-sign-in-button"
                      text={this.state.isSigningIn ? <MiniLoader /> : <b>{t("signinText")}</b>}
                      colorStyle="orange"
                      disabled={this.state.isSigningIn}
                      onClick={() => this.onClickSignIn(isFromNoVehicle)}
                    />
                    {this.state.isMagicLinkFeature && this.state.isMagicLinkFeature && (
                      <div className="magic-link-sign-in-container">
                        <Link
                          className="magic-link-button"
                          to={{
                            pathname: routerPaths.magicLink
                          }}
                        >
                          <Image src={MagicIcon} />
                          {t("magicLinkLoginLabel")}
                        </Link>
                      </div>
                    )}
                    <p className="signInMessageTheGeneral">
                      <Trans className="confident-blue">{t("byClickSigninDesc")}</Trans>
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      {`${t("signinDesc")} `}
                      <a
                        className="primary-link-orange"
                        href="http://caradvise.com/privacy/"
                        target="_blank"
                      >
                        {t("privacyPolicyLbl")}
                      </a>
                      {` ${t("andLbl")} `}
                      <a href="http://caradvise.com/terms/" target="_blank">
                        <b className="primary-link-orange"> {t("termsOfUseLbl")} </b>
                      </a>
                      .
                    </p>
                    {/* <p className="confident-blue">{t("notMember")}</p> */}
                    <div className="ui horizontal divider" style={{ height: "4px" }}>
                      <p className="or-label">Or</p>
                    </div>
                    <p data-qa="signup-create-account-link">
                      <Link
                        to={{
                          pathname: routerPaths.signUp1,
                          search: location.search
                        }}
                        className="classic-ca-button white-button"
                        onClick={() => trackEvent("signup-started")}
                      >
                        {t("createAccountLbl")}
                      </Link>
                    </p>
                    {getSubdomain() !== GRUBHUB && (
                      <>
                        <p className="signInUber">
                          <span className="uber-label">{t("driveOrDeliverWithUberText")}</span>
                          <a
                            data-qa="signin-uber-link"
                            href={`${process.env.REACT_APP_API_URL}/api/v4/customers/auth/uber`}
                            // style={{ color: "#1354fa" }}
                            onClick={this.signOutGeneric}
                            className="signIn"
                          >
                            {t("clickHereLbl")}
                          </a>
                        </p>

                        {[/localhost/i, /ngrok/i, /development/i, /staging/i].find((m) =>
                          process.env.REACT_APP_API_URL.match(m)
                        ) && (
                          <div className="signInUber">
                            <span className="uber-label">{t("uberRideshareInspectionDesc")}</span>
                            <a
                              data-qa="signin-uber-link"
                              href={`${process.env.REACT_APP_API_URL}/api/v4/customers/auth/uber?add_services%5Bservices%5D%5B%5D%5Breference_id%5D=rideshare_visual_inspection`}
                              onClick={this.signOutGeneric}
                              className="signIn"
                            >
                              {t("clickHereText")}
                            </a>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  _isPresent(value) {
    return !!value
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))

    return { ...field, value, invalid }
  }

  _onFieldChange(key, value) {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      const { t } = this.props
      if (firstInvalidKey) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${t("alertMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
        this.setState({
          isSigningIn: false
        })
      } else if (callback) callback()
    })
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      this.onClickSignIn()
    }
  }

  async onClickSignIn(isFromNoVehicle) {
    const { t } = this.props
    const { isFullStoryFlagEnabled } = this.state
    var recaptcha = this.state.isReCaptchaflagEnabled && window.grecaptcha.getResponse()
    if (this.state.isRecaptchaChecked == false && this.state.isReCaptchaflagEnabled) {
      alert(t("recaptchaCheck"))
      return false
    } else {
      trackEvent("click-login-button")
      if (isFullStoryFlagEnabled) {
        window[FS_NAMESPACES].event("Clicked Sign In", {
          email: this.state.fields.email.value
        })
      }
      if (this.state.isSigningIn) return
      this.setState({ isSigningIn: true })
      this._validateFields(async () => {
        this.signOutGeneric(false)
        let result = await this.props.signIn({
          email: this.state.fields.email.value,
          password: this.state.fields.password.value,
          isFromNoVehicle,
          isFullStoryFlagEnabled
        })

        if (result) {
          window.location.href = hashRouteWithParams(routerPaths.dashboard)
        }

        this.setState({ isSigningIn: false })
      })
    }
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, { signIn, signOut, setLoading })(
  withTranslation("signIn")(SignIn)
)
