import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import DefaultShop from "../../images/DefaultShop.png"
import { Link } from "react-router-dom"
import { Image, Input, Card, Popup, Button } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getTireBookingDetails, resetTireData, updateGuestUserDetails } from "../actions/tires"
import LoadingComponent from "./LoadingComponent"
import moment from "moment"
import { toCurrencyString } from "../../shared_component/utils/currency"
import { DATE_TIME_FORMAT } from "../constants/application"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import TirePayment from "./TirePayment"
import TireStatic from "../../images/TireStatic.png"
import downArrow from "../../images/down-arrow.png"
import RatingIcon from "../../images/Rating_Icon.png"
import CalendarIcon from "../../images/CalendarIcon.png"
import {
  BRAINTREE,
  EMAIL_REGEX,
  GUEST_ZIPCODE,
  INSTALLATION_POPUP,
  IS_ALPHANUMERIC,
  MOBILE_PHONE_REGEX,
  TIRES,
  ZERO_VALUE,
  ZIPCODE_REGEX_CANADA,
  ZIPCODE_REGEX_US
} from "../constants/tires"
import { navigationPaths, routerPaths } from "../constants/paths"
import { makeBraintreePayment } from "../actions/paymentMethods"
import InstallLocationLogo from "../../images/InstallLocationImg.png"
import DefaultShopImage from "../../images/default-shop-image.png"
import InfoImage from "../../images/exlamation.svg"
import CrossIcon from "../../images/btn-delete.png"
import { ShimmerCategoryItem, ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects"
import ShimmerSquar from "./ShimmerSquar"
import VinDialog from "./Tires/VinDialog"
import { isCanada } from "../helpers/translationHelpers"
import ConfirmOrderDialog from "./Tires/ConfirmOrderDialog"

function Booking({
  t,
  selectedShop,
  getTireBookingDetails,
  tireArray,
  installationDateTime,
  history,
  makeBraintreePayment,
  user,
  resetTireData,
  updateGuestUserDetails
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [bookingDetails, setBookingDetails] = useState()
  const [collapsed, setCollapsed] = useState(true)
  const [textValue, setTextValue] = useState("")
  const [paymentCard, setPaymentCard] = useState()
  const [isGuestUser, setIsGuestUser] = useState(user.generic)
  const [totalAmountDue, setTotalAmountDue] = useState()
  const [bookAppointmentDisabled, setBookAppointmentDisabled] = useState(false)
  const [isBookingLoading, setIsBookingLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(BRAINTREE)
  const [showTextInfo, setShowTextInfo] = useState(false)
  const [showInstallationPopup, setShowInstallationPopup] = useState(false)
  const [taxAndFees, setTaxAndFees] = useState()
  const [vehicleVIN, setVehicleVIN] = useState(false)
  const [userOrderDialog, setUserOrderDialog] = useState(false)
  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState(false)

  useEffect(() => {
    getBookingDetails()
  }, [])

  const handleVinDialog = () => {
    const userActiveVehicle =
      user &&
      user.vehicles &&
      user.vehicles.length > 0 &&
      user.vehicles.find((vehicle) => vehicle.id === user.activeVehicleId)

    if (!userActiveVehicle.vin) {
      setVehicleVIN((prev) => !prev)
    } else {
      createUser()
    }
  }

  const createUser = async () => {
    const { firstName, lastName, email, password, mobileNumber, zipcode } = createUserForm
    setIsCreateAccountLoading(true)
    try {
      const res = await updateGuestUserDetails(user, {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        password: password.value,
        cellPhone: mobileNumber.value,
        zip: zipcode.value,
        generic: false
      })

      if (res && res.error) {
        store.dispatch(
          Notifications.error({
            title: t("Error"),
            message: res.error,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      } else {
        store.dispatch(
          Notifications.success({
            title: t("successTitle"),
            message: t("userCreated"),
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
        setIsGuestUser(false)
      }
      setIsCreateAccountLoading(false)
    } catch (err) {
      setIsCreateAccountLoading(false)
    }
  }

  const getBookingDetails = async () => {
    let tiresList = []
    if (tireArray && tireArray.length > 0 && selectedShop) {
      tireArray.map((tire) => {
        tiresList.push({
          tire_id: tire.tireId,
          quantity: tire.quantity
        })
      })
    }
    const data = {
      tires: tiresList,
      shop_id: selectedShop.id,
      supplier: tireArray && tireArray.length > 0 ? tireArray[0].tireDetails.supplier : ""
    }
    const response = await getTireBookingDetails(data)
    if (response && response.result) {
      const previewDetails = response.result.tires_preview
      setIsLoading(false)
      setBookingDetails(previewDetails)
      setTotalAmountDue(calculateTotalAmount(previewDetails))
      setTaxAndFees(calculateTax(previewDetails))
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: response.error || t("Could_not_fetch_details"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      history.push(routerPaths.confirmInstallation)
    }
  }

  const calculateTax = (details) => {
    return details.total_tire_price * details.tax
  }

  const calculateTotalAmount = (details) => {
    return (
      details.total_tire_price +
      details.delivery_fee +
      details.installation_fee +
      (details.total_tire_price * details.tax + details.transaction_fee)
    )
  }

  const handleToggle = () => {
    setCollapsed(!collapsed)
  }

  const handleChange = (event) => {
    setTextValue(event.target.value)
  }

  const checkVinExists = () => {
    const userActiveVehicle =
      user &&
      user.vehicles &&
      user.vehicles.length > 0 &&
      user.vehicles.find((vehicle) => vehicle.id === user.activeVehicleId)

    return userActiveVehicle.vin
  }

  const makeBookingPayment = async () => {
    setIsBookingLoading(true)
    setBookAppointmentDisabled(true)
    let tiresList = []
    bookingDetails &&
      bookingDetails.items &&
      bookingDetails.items[0].tires.map((tire) => {
        tiresList.push({
          tire_id: tire.tire_id,
          quantity: tire.quantity,
          price: tire.price,
          retail_price: tire.retail_price,
          total_price: tire.price * tire.quantity,
          delivery_fee: bookingDetails.delivery_fee || 0
        })
      })

    const { image, brand, model, size, supplier } =
      tireArray && tireArray.length > 0 ? tireArray[0].tireDetails : {}

    const data = {
      shop_id: selectedShop && selectedShop.id,
      vehicle_id: user.activeVehicleId,
      token: paymentCard,
      amount: bookingDetails && totalAmountDue,
      braintree_customer_id: "",
      appointment_datetime:
        installationDateTime && installationDateTime.isWalkInSelected
          ? moment(installationDateTime.date).format(DATE_TIME_FORMAT.YYYY_MM_DD)
          : moment(installationDateTime.dateTime).format(DATE_TIME_FORMAT.YYYY_MM_DD_HH_mm),
      payment_type: paymentMethod,
      tax: taxAndFees,
      tires: tiresList,
      note: textValue,
      transaction_fee: bookingDetails && bookingDetails.transaction_fee,
      supplier: supplier || "",
      tire_image: image,
      tire_brand: `${brand} ${model}`,
      tire_details: size
    }
    const response = await makeBraintreePayment(user, data)
    if (response && response.result) {
      resetTireData()
      localStorage.removeItem(GUEST_ZIPCODE)
      history.push({
        pathname: navigationPaths.bookingConfirmation(response.result.shop_order.id)
      })
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: [response.errors || response.error].flat().join(", "),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    setIsBookingLoading(false)
    setBookAppointmentDisabled(false)
  }

  const handleEditCartBtn = () => {
    history.push(routerPaths.confirmTire)
  }

  const handleTaxPopUp = () => {
    setShowInstallationPopup(false)
    setShowTextInfo((prev) => !prev)
  }

  const handleInstallaionPopUp = () => {
    setShowTextInfo(false)
    setShowInstallationPopup((prev) => !prev)
  }

  const onNumberChange = (value) => {
    const isOnlyNumeric = IS_ALPHANUMERIC.test(value)
    if (isOnlyNumeric) {
      return value
    }
    return createUserForm.mobileNumber.value
  }

  const onZipcodeChage = (value) => {
    const isOnlyNumeric = IS_ALPHANUMERIC.test(value)
    if (isOnlyNumeric) {
      return value
    }
    return createUserForm.zipcode.value
  }

  const formHandler = (e, fieldName) => {
    const newValue = e.target.value
    const formField = createUserForm[fieldName]
    let newField = {
      ...createUserForm[fieldName],
      value:
        formField && formField.validateFunction ? formField.validateFunction(newValue) : newValue,
      isError:
        newValue.trim().length > 0
          ? formField && formField.validateField
            ? !formField.validateField(newValue)
            : false
          : true
    }

    setCreateUserForm({
      ...createUserForm,
      [fieldName]: newField
    })
  }

  const isEmailValid = (email) => {
    return EMAIL_REGEX.test(email)
  }

  const isPhoneNumberValid = (phone) => {
    return MOBILE_PHONE_REGEX.test(phone)
  }

  const isZipcodeValid = (phone) => {
    return isCanada(user.country) ? ZIPCODE_REGEX_CANADA.test(phone) : ZIPCODE_REGEX_US.test(phone)
  }

  const isCreateFormValid = () =>
    Object.values(createUserForm).every((field) => Boolean(field.value) && !field.isError)

  const [createUserForm, setCreateUserForm] = useState({
    firstName: {
      label: t("First_Name"),
      placholder: t("Write_your_name"),
      value: "",
      required: true,
      isError: false,
      type: "text"
    },
    lastName: {
      label: t("Last_Name"),
      placholder: t("Write_your_last_name"),
      value: "",
      required: true,
      isError: false,
      type: "text"
    },
    mobileNumber: {
      label: t("Mobile_Number"),
      placholder: t("mobilePlacehoder"),
      value: "",
      required: true,
      isError: false,
      errorMessage: t("mobilePhoneErrorMessage"),
      type: "text",
      maxLength: 10,
      validateFunction: onNumberChange,
      validateField: isPhoneNumberValid
    },
    email: {
      label: t("Email"),
      placholder: t("emailPlacehoder"),
      value: "",
      required: true,
      isError: false,
      errorMessage: t("emailPhoneErrorMessage"),
      type: "email",
      validateField: isEmailValid
    },
    password: {
      label: t("Password"),
      placholder: t("Set_password"),
      value: "",
      required: true,
      isError: false,
      type: "password"
    },
    zipcode: {
      label: t("zipcode"),
      placholder: t("zipcodePlaceholder"),
      value:
        localStorage.getItem(GUEST_ZIPCODE) !== "null" ? localStorage.getItem(GUEST_ZIPCODE) : "",
      required: true,
      isError: false,
      type: "text",
      maxLength: isCanada(user.country) ? 7 : 5,
      errorMessage: t("zipcodeErrorMessage"),
      validateFunction: onZipcodeChage,
      validateField: isZipcodeValid
    }
  })

  return (
    <div className="booking-sec">
      <div className="booking">
        <div className="back-btn">
          <Link to={routerPaths.confirmInstallationDate}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h3>{t("Back_to_cart")}</h3>
        </div>
        <div className="complete-booking-parent">
          <div className="complete-booking">
            <div className="leftDiv">
              <TirePayment
                setPaymentCard={setPaymentCard}
                module={TIRES}
                bookingDetails={bookingDetails && bookingDetails}
                totalAmountDue={bookingDetails && totalAmountDue}
                shopDetails={selectedShop}
                setPaymentMethod={setPaymentMethod}
                paymentMethod={paymentMethod}
              />
              {isGuestUser ? (
                <div className="contact-information">
                  <h2 className="heading">{t("Contact_Information")}</h2>
                  <div className="information-inputs">
                    {Object.entries(createUserForm).map(([fieldName, field]) => (
                      <div className="input-parent">
                        <label>{field.label}</label>
                        <Input
                          placeholder={field.placholder}
                          value={field.value}
                          onChange={(e) => formHandler(e, fieldName)}
                          type={field.type}
                          maxLength={field.maxLength}
                        />
                        {field.isError && <p className="error-note">{field.errorMessage}</p>}
                      </div>
                    ))}
                    <div className="input-create-btn btn-padding">
                      <Button
                        className={`${
                          isCreateFormValid()
                            ? "create-account-btn create-account-btn-primary"
                            : "create-account-btn"
                        }`}
                        disabled={isCreateAccountLoading || !isCreateFormValid()}
                        onClick={handleVinDialog}
                      >
                        {isCreateAccountLoading ? (
                          <LoadingComponent flexDirection="unset" />
                        ) : (
                          t("Create_an_account")
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="services-shops">
              <div className="order-header">
                <h4 className="title-service">{t("Order_summary")}</h4>
                <p className="edit-cart" onClick={handleEditCartBtn}>
                  {t("Edit_cart")}
                </p>
              </div>
              <>
                {isLoading ? (
                  <ShimmerCategoryItem
                    hasImage
                    imageType="thumbnail"
                    imageWidth={100}
                    imageHeight={100}
                    title
                  />
                ) : (
                  <div className="tire-total">
                    <span className="set-tire tire-img">
                      <Image src={TireStatic} />
                      {bookingDetails && bookingDetails.items[0].tires[0].quantity}{" "}
                      {bookingDetails && bookingDetails.items[0].tires[0].quantity > 1
                        ? t("Tires")
                        : t("Tire")}
                    </span>
                    <span className="set-tire">
                      {toCurrencyString(bookingDetails && bookingDetails.total_tire_price)}
                    </span>
                  </div>
                )}
                {isLoading ? (
                  <>
                    <ShimmerSquar containerClass="h-[25px]" height={60} className="w-2" />
                    <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
                    <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
                    <div className="installation-parent">
                      <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
                      <Card className="card-container">
                        <Card.Content className="content-box">
                          <ShimmerThumbnail height={150} width={300} rounded />
                          <ShimmerSectionHeader center />
                        </Card.Content>
                      </Card>
                    </div>
                    <br />
                    <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
                    <ShimmerSquar containerClass="h-[25px]" height={50} className="w-2" />
                  </>
                ) : (
                  <>
                    <ul>
                      <hr className="horizontal-line" />
                      <div className="notes-area">
                        <span onClick={handleToggle}>
                          <b>{t("Add_notes")}</b>&nbsp;{t("Optional")}
                          <Image src={downArrow} />
                        </span>
                        {!collapsed && (
                          <textarea value={textValue} onChange={handleChange} rows={4} cols={50} />
                        )}
                      </div>
                      <hr className="horizontal-line" />
                      <li className="delivery-federal">
                        <span>{t("Delivery_Fee")}</span>
                        <span>
                          {bookingDetails &&
                          bookingDetails.items[0].order_total.delivery_fee !== 0 ? (
                            toCurrencyString(bookingDetails.items[0].order_total.delivery_fee)
                          ) : (
                            <span className="free-text">{t("Free")}</span>
                          )}
                        </span>
                      </li>
                      {bookingDetails && taxAndFees !== 0 ? (
                        <li className="delivery-federal">
                          <div className="tax-box">
                            <span>{t("Tax_and_fees")}</span>
                            <Popup
                              className="tax-popup"
                              on="click"
                              pinned
                              open={showTextInfo}
                              trigger={<Image onClick={handleTaxPopUp} src={InfoImage} />}
                            >
                              <div className="tax-tooltip">
                                <p>{t("Tax_details")}</p>
                                <Image
                                  src={CrossIcon}
                                  className="cross-icon"
                                  onClick={handleTaxPopUp}
                                />
                              </div>
                            </Popup>
                          </div>
                          <span>
                            {toCurrencyString(
                              bookingDetails && taxAndFees + bookingDetails.transaction_fee
                            )}
                          </span>
                        </li>
                      ) : null}
                      {bookingDetails && bookingDetails.items[0].order_total.fet !== 0 ? (
                        <li className="delivery-federal">
                          <span>{t("Federal_Excise_Tax")}</span>
                          <span>{toCurrencyString(bookingDetails.items[0].order_total.fet)}</span>
                        </li>
                      ) : null}
                    </ul>
                    <div className="installation-parent">
                      <ul>
                        <li className="delivery-federal">
                          <div className="tax-box">
                            <h4>{t("Installation_Fee")}</h4>
                            <Popup
                              className="tax-popup"
                              on="click"
                              pinned
                              open={showInstallationPopup}
                              trigger={<Image onClick={handleInstallaionPopUp} src={InfoImage} />}
                            >
                              <div className="installtion-tooltip">
                                <div className="cross-box">
                                  <Image
                                    src={CrossIcon}
                                    className="cross-icon"
                                    onClick={handleInstallaionPopUp}
                                  />
                                </div>
                                {INSTALLATION_POPUP.map((points) => (
                                  <ul className="installation-container">
                                    <li>{points.label}</li>
                                  </ul>
                                ))}
                              </div>
                            </Popup>
                          </div>
                          <span>
                            {toCurrencyString(bookingDetails && bookingDetails.installation_fee)}
                          </span>
                        </li>
                      </ul>
                      {selectedShop && selectedShop.id ? (
                        <>
                          <Card className="card-container">
                            <Card.Content className="content-box">
                              {/* as per discussion with Eric removing this default store image but keeping the code for reference */}
                              {/* <div className="shop-img">
                                <Image
                                  floated="left"
                                  className="default-store-image"
                                  size="small"
                                  src={
                                    (selectedShop && selectedShop.shop_image) || DefaultShopImage
                                  }
                                  alt="Image"
                                />
                                <Image
                                  src={InstallLocationLogo}
                                  size="small"
                                  className="abolute-img-label"
                                />
                              </div> */}
                              <Card.Description className="card-content">
                                <div className="heading-sec">
                                  <div className="img-parent">
                                    <Image
                                      src={
                                        selectedShop.logo_url ? selectedShop.logo_url : DefaultShop
                                      }
                                    />
                                  </div>
                                  <div className="card-store-details">
                                    <Card.Header className="card-title">
                                      {selectedShop.shop_name}
                                      {selectedShop.average_rating &&
                                      selectedShop.average_rating !== ZERO_VALUE ? (
                                        <div className="shop-rating">
                                          <img src={RatingIcon} />
                                          {selectedShop.average_rating}
                                        </div>
                                      ) : null}
                                    </Card.Header>
                                  </div>
                                  <Link
                                    to={routerPaths.confirmInstallation}
                                    className="change-shop-link"
                                  >
                                    {t("Change")}
                                  </Link>
                                </div>
                                <Card.Description className="card-description">
                                  {selectedShop.address_line1 +
                                    ", " +
                                    selectedShop.city +
                                    ", " +
                                    selectedShop.state +
                                    " " +
                                    selectedShop.zip}
                                  <span className="date-sec">
                                    <Image src={CalendarIcon} />
                                    {installationDateTime &&
                                      moment(installationDateTime.date).format(DATE_TIME_FORMAT.LL)}
                                    &nbsp;
                                    {installationDateTime && !installationDateTime.isWalkInSelected
                                      ? moment(installationDateTime.dateTime).format(
                                          DATE_TIME_FORMAT.HH_MM
                                        )
                                      : installationDateTime.walk_in_slot[0].formatted_time}
                                  </span>
                                </Card.Description>
                              </Card.Description>
                            </Card.Content>
                          </Card>

                          {/* old code - In case any UI issue uncomment this code */}
                          {/* <Card className="card-container">
                            <Card.Content className="content-box">
                              <div className="shop-img">
                                <Image
                                  floated="left"
                                  className="default-store-image"
                                  size="small"
                                  src={
                                    (selectedShop && selectedShop.shop_image) || DefaultShopImage
                                  }
                                  alt="Image"
                                />
                                <Image
                                  src={InstallLocationLogo}
                                  size="small"
                                  className="abolute-img-label"
                                />
                              </div>
                              <Card.Description className="card-content">
                                <div className="heading-sec">
                                  <div className="img-parent">
                                    <Image
                                      src={
                                        selectedShop.logo_url ? selectedShop.logo_url : DefaultShop
                                      }
                                    />
                                  </div>
                                  <Card.Header className="card-title">
                                    {selectedShop.shop_name}
                                    {selectedShop.average_rating &&
                                    selectedShop.average_rating !== ZERO_VALUE ? (
                                      <div className="shop-rating">
                                        <img src={RatingIcon} />
                                        {selectedShop.average_rating}
                                      </div>
                                    ) : null}
                                  </Card.Header>
                                  <Link
                                    to={routerPaths.confirmInstallation}
                                    className="change-shop-link"
                                  >
                                    {t("Change")}
                                  </Link>
                                </div>
                                <Card.Description className="card-description">
                                  {selectedShop.address_line1 +
                                    ", " +
                                    selectedShop.city +
                                    ", " +
                                    selectedShop.state +
                                    " " +
                                    selectedShop.zip}
                                  <span className="date-sec">
                                    <Image src={CalendarIcon} />
                                    {installationDateTime &&
                                      moment(installationDateTime.date).format(DATE_TIME_FORMAT.LL)}
                                    &nbsp;
                                    {installationDateTime && !installationDateTime.isWalkInSelected
                                      ? moment(installationDateTime.dateTime).format(
                                          DATE_TIME_FORMAT.HH_MM
                                        )
                                      : installationDateTime.walk_in_slot[0].formatted_time}
                                  </span>
                                </Card.Description>
                              </Card.Description>
                            </Card.Content>
                          </Card> */}
                        </>
                      ) : null}
                    </div>
                    <ul className="total">
                      <li>
                        <span>{t("Estimated_Total")}</span>
                        <span>{toCurrencyString(bookingDetails && totalAmountDue)}</span>
                      </li>
                    </ul>
                    <button
                      onClick={() => {
                        setShowInstallationPopup(false)
                        setShowTextInfo(false)
                        if (!checkVinExists()) {
                          setVehicleVIN((prev) => !prev)
                        } else {
                          setUserOrderDialog(true)
                        }
                      }}
                      disabled={
                        bookAppointmentDisabled || !paymentCard || isGuestUser ? true : false
                      }
                      className={`book-btn ${
                        !bookAppointmentDisabled && paymentCard && !isGuestUser ? "active-btn" : ""
                      }`}
                    >
                      {isBookingLoading ? (
                        <LoadingComponent flexDirection="unset" />
                      ) : (
                        t("Book_appointment")
                      )}
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      {vehicleVIN && (
        <VinDialog handleVinDialog={() => setVehicleVIN(false)} openVinDialog={vehicleVIN} />
      )}
      {userOrderDialog && (
        <ConfirmOrderDialog
          handleOrderDialog={() => setUserOrderDialog(false)}
          openOrderDialog={userOrderDialog}
          orderDetails={{
            estimatedTotal: toCurrencyString(bookingDetails && totalAmountDue),
            shopImage: selectedShop.logo_url ? selectedShop.logo_url : DefaultShop,
            appointmentTime: moment(installationDateTime.dateTime).format(
              DATE_TIME_FORMAT.MMMDth_H_MM_A
            ),
            tireName:
              tireArray &&
              tireArray.length > 0 &&
              `${tireArray[0].tireDetails.brand} ${tireArray[0].tireDetails.model}`,
            tireSize: tireArray && tireArray.length > 0 && tireArray[0].tireDetails.size,
            tireImage: tireArray && tireArray.length > 0 && tireArray[0].tireDetails.image,
            shopName: selectedShop.shop_name,
            shopAddress: `${selectedShop.address_line1}. ${selectedShop.city}, ${selectedShop.state} ${selectedShop.zip}`,
            createOrder: makeBookingPayment,
            isBookingLoading: isBookingLoading,
            milesRating:
              tireArray && tireArray.length > 0 && tireArray[0].tireDetails.mileage_rating
          }}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let selectedShop = state.saveBuyTireDetails.selectedShop
  let tireArray = state.saveBuyTireDetails.tireArray
  let installationDateTime = state.saveBuyTireDetails.installationDateTime

  return {
    user: user,
    selectedShop: selectedShop,
    tireArray: tireArray,
    installationDateTime: installationDateTime
  }
}

export default connect(mapStateToProps, {
  getTireBookingDetails,
  makeBraintreePayment,
  resetTireData,
  updateGuestUserDetails
})(withTranslation("booking")(Booking))
