import React, { useState, useEffect } from "react"
import { showNav, hideNav } from "../../v1/actions/leftNav"
import { connect } from "react-redux"
import { withRouter, NavLink } from "react-router-dom"
import { constants } from "caradvise_shared_components"
import Badge from "../../v1/components/Badge"
import { hidePartnerOffers } from "../../v1/helpers/affiliationHelpers"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { GAEvent, GAEventCategories } from "../../v1/tracking/GAEvent"
import { useTranslation } from "react-i18next"
import carAdvise360BlackLogo from "../../images/car360-black.svg"
import { getSubdomain } from "../../v1/components/Config/StylesheetInjector"
import SplitClient, {
  CARADVISE_2_NAVIGATION,
  GLOVEBOX_FEATURE,
  ON,
  V1
} from "../../v1/components/Config/SplitClient"
import { OFFERS } from "../../v1/constants/offers"
import { isEmpty } from "lodash"
import { routerPaths } from "../../v1/constants/paths"
import HamburgerMenu from "../../images/sidemenu_icon/HamburgerIcon.svg"
import ChevronLeftIcon from "../../images/sidemenu_icon/ChevronIcon.svg"
import DashbaordIcon from "../../images/sidemenu_icon/dashboard.svg"
import ApprovalsIcon from "../../images/sidemenu_icon/approval.svg"
import MaintenanceScheduleIcon from "../../images/sidemenu_icon/maintenance_schedule.svg"
import MyMembershipIcon from "../../images/sidemenu_icon/my-membership.svg"
import MyGarageIcon from "../../images/sidemenu_icon/my-garage.svg"
import MaintenanceHistoryIcon from "../../images/sidemenu_icon/maintenance-history.svg"
import InviteFriendsIcon from "../../images/sidemenu_icon/invite-friends.svg"
import FAQIcon from "../../images/sidemenu_icon/faq.svg"
import GloveboxIcon from "../../images/sidemenu_icon/glovebox.svg"
import RewardLogo from "../../images/rewards/reward-navigation.svg"
import { APP_NAME } from "../../shared_component/constants/common"
import ReactSVG from "react-svg"
const serviceStatuses = constants.services.statuses
const {
  orders: { AWAITING_APPROVAL }
} = constants

function LeftNavContent({
  isGloveBoxFeatureFlag,
  activeVehicleId,
  user,
  t,
  navOpen,
  hideNav,
  showNav
}) {
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const pendingApprovalsCount = () => {
    let count = 0
    const activeOrders = user.active_orders || []
    const order = activeOrders.find(
      (order) => order.vehicle_id === activeVehicleId && order.status === AWAITING_APPROVAL
    )
    if (order) {
      const pendingApprovalStatuses = [
        serviceStatuses["initiated"],
        serviceStatuses["initiatedByUser"]
      ]
      count = order.order_services.filter((order_service) =>
        pendingApprovalStatuses.includes(order_service.status)
      ).length
    }
    return count
  }
  const isOfferRoute = window.location.hash && window.location.hash.includes(OFFERS)

  return (
    <>
      {!navOpen ? (
        <ul className="left-nav-shrink-ul">
          <div
            className="left-nav-shrink-header"
            onClick={() => {
              if (navOpen) hideNav()
              else showNav()
            }}
          >
            <ReactSVG className="left-nav-shrink-icon" src={HamburgerMenu} />
          </div>
          <div className="tooltip">
            <NavLink
              onClick={() =>
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-dashboard",
                  "Left Nav Dashboard Link Clicked"
                )
              }
              to={routerPaths.dashboard}
              exact
            >
              <Badge
                count={null}
                icon={DashbaordIcon}
                customImage={{
                  isImage: true,
                  width: "18px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext">{t("dashboardMenu")}</span>
          </div>
          <div className="tooltip">
            <NavLink
              onClick={() => {
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-approvals",
                  "Left Nav Approvals Link Clicked"
                )
                trackEvent("click-navigation-drawer", {
                  itemName: "Approvals"
                })
              }}
              to={routerPaths.approvals}
              exact
            >
              <Badge
                count={pendingApprovalsCount(user) > 0 ? pendingApprovalsCount(user) : null}
                icon={ApprovalsIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext"> {t("approvalsMenu")}</span>
          </div>
          <div className="tooltip">
            <NavLink
              onClick={() => {
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-suggested-maintenance",
                  "Left Nav Suggested Maintenance Link Clicked"
                )
                trackEvent("click-navigation-drawer", {
                  itemName: "Maintenance Schedule"
                })
              }}
              to={routerPaths.maintenanceSchedule}
              exact
            >
              <Badge
                count={null}
                icon={MaintenanceScheduleIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext"> {t("maintenanceScheduleMenu")}</span>
          </div>
          <div className="tooltip">
            <NavLink
              onClick={() => {
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-membership-card",
                  "Membership Card Dashboard Link Clicked"
                )
                trackEvent("click-navigation-drawer", {
                  itemName: "Membership Card"
                })
              }}
              to="/membership"
              exact
            >
              <Badge
                count={null}
                icon={MyMembershipIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext"> {t("membershipCardMenu")} </span>
          </div>
          {isGloveBoxFeatureFlag === ON && (
            <div className="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-glovebox",
                    "My Garage Dashboard Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Glovebox"
                  })
                }}
                to={routerPaths.glovebox}
                exact
              >
                <Badge
                  count={null}
                  icon={GloveboxIcon}
                  customImage={{
                    isImage: true,
                    width: "24px",
                    height: "24px",
                    style: { margin: "4px" }
                  }}
                />
              </NavLink>
              <span className="tooltiptext"> {t("glovebox")} </span>
            </div>
          )}
          <div className="tooltip">
            <NavLink
              onClick={() => {
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-my-garage",
                  "My Garage Dashboard Link Clicked"
                )
                trackEvent("click-navigation-drawer", {
                  itemName: "My Garage"
                })
              }}
              to={routerPaths.managevehicles}
              exact
            >
              <Badge
                count={null}
                icon={MyGarageIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext"> {t("myGarageMenu")} </span>
          </div>
          {activeVehicleId && (
            <div className="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-history",
                    "Maintenance History Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Maintenance History"
                  })
                }}
                to={`/vehicles/${activeVehicleId}/history`}
                exact
              >
                <Badge
                  count={null}
                  icon={MaintenanceHistoryIcon}
                  customImage={{
                    isImage: true,
                    width: "24px",
                    height: "24px",
                    style: { margin: "4px" }
                  }}
                />
              </NavLink>
              <span className="tooltiptext"> {t("maintenanceHistoryMenu")} </span>
            </div>
          )}
          {isRewardsFeature && (
            <div className="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(GAEventCategories.REWARDS, "left-nav-to-rewards", "Rewards Link Clicked")
                  trackEvent("click-navigation-drawer", {
                    itemName: "Rewards"
                  })
                }}
                to={routerPaths.rewards}
                exact
              >
                <Badge
                  count={null}
                  icon={RewardLogo}
                  customImage={{
                    isImage: true,
                    width: "24px",
                    height: "24px",
                    style: { margin: "4px" }
                  }}
                />
              </NavLink>
              <span className="tooltiptext"> {t("rewards")} </span>
            </div>
          )}
          {!hidePartnerOffers() && (
            <div className="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-partner-offers",
                    "CarAdvise 360 Link Clicked"
                  )
                  trackEvent(
                    "caradvise-360-viewed",
                    {
                      itemName: "CarAdvise 360"
                    },
                    false,
                    false,
                    false
                  )
                }}
                to={routerPaths.offers}
                exact
              >
                <Badge
                  count={null}
                  icon={carAdvise360BlackLogo}
                  customImage={{
                    isImage: true,
                    width: 27,
                    height: 24,
                    style: { margin: "4px", fill: "#dc4017" }
                  }}
                />
              </NavLink>
              <span className="tooltiptext"> {t("partnerOffersMenu")} </span>
            </div>
          )}
          <div className="tooltip">
            <NavLink
              onClick={() => {
                GAEvent(
                  GAEventCategories.DASHBOARD,
                  "left-nav-to-invite-friends",
                  "Invite Friends Link Clicked"
                )
                trackEvent("click-navigation-drawer", {
                  itemName: "Invite Friends"
                })
              }}
              to={routerPaths.inviteFriends}
              exact
            >
              <Badge
                count={null}
                icon={InviteFriendsIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </NavLink>
            <span className="tooltiptext"> {t("inviteFriendsMenu")} </span>
          </div>

          <div
            className="tooltip"
            onClick={() => {
              trackEvent("click-navigation-drawer", {
                itemName: "FAQs"
              })
            }}
          >
            <a href="https://caradvise.com/faq/" target="_blank">
              <Badge
                count={null}
                icon={FAQIcon}
                customImage={{
                  isImage: true,
                  width: "24px",
                  height: "24px",
                  style: { margin: "4px" }
                }}
              />
            </a>
            <span className="tooltiptext"> {t("faqPageMenu")} </span>
          </div>
        </ul>
      ) : (
        <div className="leftNav">
          <div
            className="left-nav-shrink-header"
            onClick={() => {
              if (navOpen) hideNav()
              else showNav()
            }}
          >
            <div className="left-nav-shrink-container">
              <span>{t("menu")}</span>
              <ReactSVG className="left-nav-shrink-close-icon" src={ChevronLeftIcon} />
            </div>
          </div>
          <ul>
            <div className="left-nav-shrink-divider">
              <li>
                <NavLink
                  onClick={() =>
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-dashboard",
                      "Left Nav Dashboard Link Clicked"
                    )
                  }
                  to={routerPaths.dashboard}
                  exact
                >
                  <Badge
                    count={null}
                    icon={DashbaordIcon}
                    customImage={{
                      isImage: true,
                      width: "18px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name">{t("dashboardMenu")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-approvals",
                      "Left Nav Approvals Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Approvals"
                    })
                  }}
                  to={routerPaths.approvals}
                  exact
                >
                  <Badge
                    count={pendingApprovalsCount(user) > 0 ? pendingApprovalsCount(user) : null}
                    icon={ApprovalsIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name">{t("approvalsMenu")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-suggested-maintenance",
                      "Left Nav Suggested Maintenance Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Maintenance Schedule"
                    })
                  }}
                  to={routerPaths.maintenanceSchedule}
                  exact
                >
                  <Badge
                    count={null}
                    icon={MaintenanceScheduleIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name">{t("maintenanceScheduleMenu")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-membership-card",
                      "Membership Card Dashboard Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Membership Card"
                    })
                  }}
                  to={routerPaths.membership}
                  exact
                >
                  <Badge
                    count={null}
                    icon={MyMembershipIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name">{t("membershipCardMenu")}</span>
                </NavLink>
              </li>
              {isGloveBoxFeatureFlag === ON && (
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-glovebox",
                        "My Garage Dashboard Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Glovebox"
                      })
                    }}
                    to={routerPaths.glovebox}
                    exact
                  >
                    <Badge
                      count={null}
                      icon={GloveboxIcon}
                      customImage={{
                        isImage: true,
                        width: "24px",
                        height: "24px",
                        style: { margin: "4px" }
                      }}
                    />
                    <span className="left-nav-expand-menu-name"> {t("glovebox")}</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-my-garage",
                      "My Garage Dashboard Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "My Garage"
                    })
                  }}
                  to={routerPaths.managevehicles}
                  exact
                >
                  <Badge
                    count={null}
                    icon={MyGarageIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name"> {t("myGarageMenu")}</span>
                </NavLink>
              </li>
              {activeVehicleId && (
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-history",
                        "Maintenance History Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Maintenance History"
                      })
                    }}
                    to={`/vehicles/${activeVehicleId}/history`}
                    exact
                  >
                    <Badge
                      count={null}
                      icon={MaintenanceHistoryIcon}
                      customImage={{
                        isImage: true,
                        width: "24px",
                        height: "24px",
                        style: { margin: "4px" }
                      }}
                    />
                    <span className="left-nav-expand-menu-name">
                      {" "}
                      {t("maintenanceHistoryMenu")}
                    </span>
                  </NavLink>
                </li>
              )}
              {isRewardsFeature && (
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.REWARDS,
                        "left-nav-to-rewards",
                        "Rewards Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Rewards"
                      })
                    }}
                    to={routerPaths.rewards}
                    exact
                  >
                    <Badge
                      count={null}
                      icon={RewardLogo}
                      customImage={{
                        isImage: true,
                        width: "24px",
                        height: "24px",
                        style: { margin: "4px" }
                      }}
                    />
                    <span className="left-nav-expand-menu-name"> {t("rewards")}</span>
                  </NavLink>
                </li>
              )}
              {!hidePartnerOffers() && (
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-partner-offers",
                        "CarAdvise 360 Link Clicked"
                      )
                      trackEvent(
                        "caradvise-360-viewed",
                        {
                          itemName: "CarAdvise 360"
                        },
                        false,
                        false,
                        false
                      )
                    }}
                    to={routerPaths.offers}
                    exact
                  >
                    <Badge
                      count={null}
                      icon={carAdvise360BlackLogo}
                      customImage={{
                        isImage: true,
                        width: 24,
                        height: 24,
                        style: { margin: "4px", fill: "#dc4017" }
                      }}
                    />
                    <span className="left-nav-expand-menu-name"> {t("partnerOffersMenu")}</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-invite-friends",
                      "Invite Friends Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Invite Friends"
                    })
                  }}
                  to={routerPaths.inviteFriends}
                  exact
                >
                  <Badge
                    count={null}
                    icon={InviteFriendsIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name"> {t("inviteFriendsMenu")}</span>
                </NavLink>
              </li>

              <li
                onClick={() => {
                  trackEvent("click-navigation-drawer", {
                    itemName: "FAQs"
                  })
                }}
              >
                <a href="https://caradvise.com/faq/" target="_blank">
                  <Badge
                    count={null}
                    icon={FAQIcon}
                    customImage={{
                      isImage: true,
                      width: "24px",
                      height: "24px",
                      style: { margin: "4px" }
                    }}
                  />
                  <span className="left-nav-expand-menu-name"> {t("faqPageMenu")}</span>
                </a>
              </li>
            </div>
          </ul>
        </div>
      )}
    </>
  )
}

function LeftNav_v2({ activeVehicleId, hideChrome, isLoggedIn, user, navOpen, hideNav, showNav }) {
  const [isGloveBoxFeatureFlag, setGloveBoxFeatureFlag] = useState("")
  const [isCaradviseNavigation, setCarAdviseNavigation] = useState("")

  useEffect(() => {
    let isMounted = true // Flag to track if the component is mounted

    const fetchTreatments = async () => {
      try {
        const flags = await SplitClient.getTreatmentsWithConfig(
          [CARADVISE_2_NAVIGATION, GLOVEBOX_FEATURE],
          user,
          {
            sub_domain: getSubdomain(),
            app_name: APP_NAME
          }
        )

        if (isMounted) {
          setCarAdviseNavigation(flags[CARADVISE_2_NAVIGATION].treatment)
          setGloveBoxFeatureFlag(flags[GLOVEBOX_FEATURE].treatment)
        }
      } catch (error) {
        // Handle error
      }
    }

    fetchTreatments()

    return () => {
      isMounted = false // Mark the component as unmounted in the cleanup function
    }
  }, [])

  const { t } = useTranslation("leftNav")

  return (
    <>
      {!isLoggedIn || hideChrome || (!activeVehicleId && isCaradviseNavigation === V1) ? (
        <></>
      ) : (
        <div className="top-container_v2">
          <div className="left-nav-shrink-main-container">
            {/* <div
              className="left-nav-shrink-header"
              onClick={() => {
                if (navOpen) hideNav()
                else showNav()
              }}
            >
              {!navOpen ? (
                <img className="left-nav-shrink-icon" src={HamburgerMenu} />
              ) : (
                <div className="left-nav-shrink-container">
                  <span>{t("menu")}</span>
                  <img className="left-nav-shrink-close-icon" src={ChevronLeftIcon} />
                </div>
              )}
            </div> */}
            {isCaradviseNavigation && isCaradviseNavigation && (
              <LeftNavContent
                isGloveBoxFeatureFlag={isGloveBoxFeatureFlag}
                activeVehicleId={activeVehicleId}
                user={user}
                navOpen={navOpen}
                t={t}
                hideNav={hideNav}
                showNav={showNav}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state, props) {
  return {
    activeVehicleId:
      state.user.activeVehicleId ||
      (state.user &&
        state.user.vehicles &&
        state.user.vehicles.length > 0 &&
        state.user.vehicles[0].id),
    hasCC: state.user && state.user.has_cc_info_saved,
    hasZip: state.user && state.user.zip,
    hideChrome: state.framework && state.framework.hideChrome,
    isLoggedIn: state.user && state.user.id,
    user: state.user,
    navOpen: state && state.leftnav && state.leftnav.navOpen
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideNav: () => dispatch(hideNav()),
    showNav: () => dispatch(showNav())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftNav_v2))
