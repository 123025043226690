import React from "react"
import { Card, CardContent, CardHeader } from "semantic-ui-react"
import { ShimmerCategoryItem, ShimmerThumbnail } from "react-shimmer-effects"

export default function LoadingShimmer() {
  return (
    <div className="tire-card-button-parent">
      <div className="card-parent loading-shimmer-width">
        <div className="tires-card-parent">
          <Card className="tire-card">
            <CardHeader className="loading-card-header">
              <ShimmerCategoryItem
                hasImage
                imageType="thumbnail"
                imageWidth={73}
                imageHeight={73}
                title
              />
            </CardHeader>
            <div className="loader-btn">
              <ShimmerThumbnail height={42} rounded />
            </div>
          </Card>
          <Card className="tire-card">
            <CardHeader className="loading-card-header">
              <ShimmerCategoryItem
                hasImage
                imageType="thumbnail"
                imageWidth={73}
                imageHeight={73}
                title
              />
            </CardHeader>
            <div className="loader-btn">
              <ShimmerThumbnail height={42} rounded />
            </div>
          </Card>
          <Card className="tire-card">
            <CardHeader className="loading-card-header">
              <ShimmerCategoryItem
                hasImage
                imageType="thumbnail"
                imageWidth={73}
                imageHeight={73}
                title
              />
            </CardHeader>
            <div className="loader-btn">
              <ShimmerThumbnail height={42} rounded />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
