import React, { Component } from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import "url-search-params-polyfill"
import { signIn } from "../actions/user"
import { OFF, V1, setupSplitFlags } from "./Config/SplitClient"
import { getSubdomain } from "./Config/StylesheetInjector"
import { routerPaths } from "../constants/paths"

class Landing extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    let params = new URLSearchParams(this.props.location.search)

    let key = params.get("key")

    if (!this.props.carperks && key && !this.props.isLoggedIn) {
      await this.props.signIn({ key: key })
    }
  }

  render() {
    const { isLoggedIn, user, activeVehicle, vehicles, carperks } = this.props

    if (!carperks && !isLoggedIn && !user.loading) {
      return <Redirect to="/signIn" />
    } else if (
      !carperks &&
      vehicles.length === 0 &&
      this.state.isCarAdviseNavigation &&
      this.state.isCarAdviseNavigation !== undefined &&
      this.state.isCarAdviseNavigation === V1
    ) {
      return <Redirect to="/no_vehicle" />
    } else if ((!carperks && !activeVehicle.miles) || !activeVehicle.miles_per_month) {
      // Note: /dashboard doesn't currently handle enterMiles- only /managevehicles
      // Keeping that here for now since we may want to add the functionality soon.
      return (
        <Redirect
          to={{
            pathname: routerPaths.dashboard,
            state: { enterMiles: true }
          }}
        />
      )
    } else {
      return <Redirect to={routerPaths.dashboard} />
    }
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  const activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token,
    activeVehicle,
    vehicles: user.vehicles || []
  }
}

export default connect(mapStateToProps, { signIn })(Landing)
