import React, { Component } from "react"
import PropTypes from "prop-types"
import { elements } from "caradvise_shared_components"

import { getStyles } from "../components/Config/StylesheetInjector"
const styles = getStyles()
const SharedToggleButton = elements.ToggleButton

export default function ToggleButton({ onChange, value }) {
  return <SharedToggleButton onChange={onChange} value={value} styles={styles} />
}
