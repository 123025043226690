const ADD_SELECTED_SHOP = "ADD_SELECTED_SHOP"
const DELETE_SHOP = "DELETE_SHOP"

export const SHOP_API_CALL = "SHOP_API_CALL"
export const PREV_PARAM = "PREV_PARAM"

const initialState = {
  shops: []
}

export default function shopReducer(state = initialState, action) {
  const nextState = Object.assign({}, state)
  switch (action.type) {
    case ADD_SELECTED_SHOP:
      nextState["shop"] = action.selectedShop
      break

    case DELETE_SHOP:
      delete nextState[action.deleted_key]
      break
    case SHOP_API_CALL:
      return { ...state, shops: action.shops }
      break
    case PREV_PARAM:
      return { ...state, pev: action.payload }
      break

    default:
      return state
  }
  return nextState
}
