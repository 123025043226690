import React from "react"
import MiniLoader from "../../../v1/components/MiniLoader"
import ReactSVG from "react-svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const RemoveServiceFromCart = ({ handleRemove, t, isLoading }) => {
  return (
    <div className="price-range-section">
      {isLoading ? (
        <MiniLoader inverted={false} />
      ) : (
        <div className="remove-added-service-section" onClick={handleRemove}>
          <ReactSVG src="images/delete_icon_vector.svg" />
          <div className="remove-service-link">{t("removeLabel")}</div>
        </div>
      )}
      <div className="added-service-section">
        <FontAwesomeIcon className="added-service" icon={faCheck} />
        <span className="added-service">{t("addedLabel")}</span>
      </div>
    </div>
  )
}

export default RemoveServiceFromCart
