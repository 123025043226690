import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ScheduleIndex from "../Schedule/ScheduleIndex"
import { Grid } from "semantic-ui-react"
import {
  CART_EVENTS,
  DATE_TIME_FORMAT,
  MD_HD_VEHICLE_TYPE_ID,
  WALK_IN_TIME
} from "../../../v1/constants/application"
import { updateCart } from "../../../v1/actions/user"
import OrderSummaryIndex from "../OrderSummary/OrderSummaryIndex"
import { navigationPaths, routeChange, routerPaths } from "../../../v1/constants/paths"
import PaymentIndex from "./PaymentIndex"
import StepperComponent from "../../../shared_component/components/StepperComponent/Stepper"
import moment from "moment"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { createOrder, updateOrder } from "../../../v1/actions/orders"
import { Redirect } from "react-router-dom"
import {
  maxPercentDiscountDiff,
  nextMembershipUpgrades
} from "../../../v1/helpers/membershipHelpers"
import MembershipUpgradeModal2_0 from "../../../v2/components/Membership2.0/MembershipUpgradeModal"
import { preMembershipDiscountSubtotal } from "../../../v1/helpers/cartHelpers"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { getVehicleName } from "../../../v1/helpers/vehicleHelpers"
import {
  getBookingAppointmentDateTime,
  getBookingAppointmentDay
} from "../../../v1/helpers/segmentHelper"
import { getCurrencyCode, numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { getShopAddress } from "../../../v1/helpers/shopHelpers"
import { getUpdatedCart, isWalkIn } from "../../../v1/helpers/orderHelpers"
import { getCurrentUser } from "../../../v1/helpers/userHelper"
import { getPaymentMethodName } from "../../../v1/helpers/paymentHelpers"
import i18n from "../../../shared_component/utils/i18n"
import { getServiceNameForMap } from "../../../v1/helpers/serviceHelper"
import ReviewOrderMobile from "../ReviewOrder/ReviewOrderMobile"
import { NoChargeBanner } from "../../../shared_component/elements/NoChargeBanner"
import { BRAINTREE } from "../../../v1/constants/tires"
import VehicleInfoUpdatePanel from "../vehicle/VehicleInfoUpdatePanel"
import ContactInformation from "../ContactInformation"
import { setupSplitFlags } from "../../../v1/components/Config/SplitClient"
import { getSubdomain } from "../../../v1/components/Config/StylesheetInjector"
import { isEbay, isEbayCart } from "../../../v1/helpers/affiliationHelpers"
import {
  appointmentDateTimeSelectedEvents,
  gaJSON,
  logPurchase,
  orderBookedEvents,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { capitalCase } from "../../../shared_component/utils/stringHelpers"
import {
  PAYMENT_PAGE,
  REVIEW_PAGE,
  SCHEDULE_PAGE,
  WALK_IN_ACTIVE
} from "../../../shared_component/constants/common"
import { constants } from "caradvise_shared_components"
import { INITIATED, UNDEFINED } from "../../../shared_component/constants/checkout"
import { getPaymentCards } from "../../../v1/actions/paymentMethods"
import { openShopListView } from "../../../v1/actions/checkout"
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

class CheckoutIndex extends Component {
  constructor(props) {
    super(props)
    this.stepperRef = React.createRef()
    this.paymentRef = React.createRef()
    const { cart } = this.props
    this.state = {
      isDateTimeSelected: cart && cart.appointment_datetime ? true : false,
      isPaymentMethod: false,
      showMap: this.props.shopMap || false,
      isSubmitting: false,
      reviewOrder: false,
      membershipModalOpen: false,
      showDemandBanner: true,
      showNoChargeBanner: true,
      customerComments: (cart && cart.customer_comments) || "",
      paymentMethod: BRAINTREE,
      selectedPaymentType: null,
      selectedPaymentDetails: null,
      isOrderLoading: false
    }
  }

  toggleMapVisibility = (shouldShowMap) => {
    this.setState({ showMap: shouldShowMap })
  }

  handleShopChange = () => {
    const { openShopListView } = this.props
    openShopListView(true)
    if (this.stepperRef.current) {
      const response = this.stepperRef.current()
      this.toggleMapVisibility(true)
    }
  }
  handlePaymentChange = () => {
    if (this.paymentRef.current) {
      const response = this.paymentRef.current()
    }
  }

  showBanner = (currentStep) => {
    setTimeout(() => {
      switch (currentStep) {
        case "schedule":
          this.setState({ showDemandBanner: false })
          break
        case "payment":
          this.setState({ showNoChargeBanner: false })
          break
      }
    }, 5000)
  }

  async componentDidMount() {
    const { cart, history, isCart } = this.props
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    if (cart && cart.order_services.length === 0) {
      routeChange(history, routerPaths.addServices)
    }
  }
  componentDidUpdate(prevState) {
    const { openShopListView } = this.props
    if (prevState.cart.shop !== this.props.cart.shop) {
      this.setState({ showMap: false })
    }
  }
  setCustomerComments = (value) => {
    this.setState({ customerComments: value })
  }

  paymentMethodSelected = async (paymentType, paymentDetails) => {
    if (paymentType) {
      this.setState({
        isPaymentSelected: true,
        selectedPaymentType: paymentType,
        selectedPaymentDetails: paymentDetails
      })
    } else {
      this.setState({
        isPaymentSelected: false
      })
    }
  }

  paymentComplete = async () => {
    const { cart, user, activeVehicle, t, history } = this.props
    const { selectedPaymentType, selectedPaymentDetails } = this.state
    const todayDate = moment().format(moment.HTML5_FMT.DATE)

    this.setState({ isSubmitting: true })
    if (
      moment(cart.appointment_datetime).isBefore(moment()) &&
      !(
        cart.is_walk_in &&
        moment(cart.appointment_datetime).format(moment.HTML5_FMT.DATE) == todayDate
      )
    ) {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: t("confirmOrder:changeAppointmentTimeText")
        })
      )
    } else if (user.lacks_required_membership_to_transact) {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: t("confirmOrder:paidMembershipRequiredMessage")
        })
      )
    } else {
      this.props.cart.paymentType = selectedPaymentType
      this.props.cart.paymentDetails = selectedPaymentDetails
      trackFirebaseAnalytics({
        event: gaJSON.payment_selected,
        params: {
          [gaJSON.payment_type]: cart.paymentType && capitalCase(cart.paymentType)
        }
      })
      await trackEvent("select-payment-method", {
        paymentMode:
          cart.paymentType && cart.paymentType.toLowerCase() === "affirm"
            ? "Affirm"
            : getPaymentMethodName(),
        eventSourceName: "Checkout_2.0"
      })
    }
    this.setState({ isSubmitting: false })
    routeChange(history, routerPaths.checkoutReview)
    await this.props.getPaymentCards(user)
  }

  createOrder = async () => {
    this.setState({ isSubmitting: true, membershipModalOpen: false })
    let { cart, activeVehicle, order, paramsId = null } = this.props
    const { customerComments, isOrderLoading } = this.state
    if (customerComments) {
      cart.customerComment = customerComments
    }
    if (isOrderLoading) return
    this.setState({ isOrderLoading: true })
    try {
      let response = order
        ? await this.props.updateOrder(cart, order)
        : await this.props.createOrder(parseInt(paramsId || cart.id, 10))
      const orderId = response.result ? response.result.id : response
      if (orderId) {
        if (response.result) {
          let createdTime = moment().utcOffset("-05:00").format("hh:mm A")
          let createdDate = moment().toISOString()
          logPurchase(response.result.currency.toUpperCase(), response.result.total)
          await orderBookedEvents({ response, orderId, activeVehicle })

          await identifyEvent({
            traits: { numberOfServicesBooked: this.props.cart.order_services.length }
          })
          await trackEvent("appointment-requested", {
            vehicleName: getVehicleName(response.result.vehicle),
            orderServices: response.result.order_services.map(getServiceNameForMap),
            bookingAppointmentDate:
              response.result.appointment_datetime &&
              getBookingAppointmentDateTime(response.result.appointment_datetime),
            shopName: response.result.shop.name,
            yourTotal: response.result.total ? numberFormatToCurrency(response.result.total) : 0,
            revenue: response.result.total,
            currency: getCurrencyCode(i18n.language),
            shopPhoneNumber: response.result.shop.main_phone_number,
            orderId: parseInt(orderId),
            shopAddress: getShopAddress(response.result.shop),
            bookingCreatedDate: createdDate,
            bookingCreatedTime: createdTime,
            bookingAppointmentTime:
              response.result && isWalkIn(response.result)
                ? "Walk In"
                : response.result.appointment_time_pretty,
            bookingAppointmentDay:
              response.result &&
              response.result.appointment_datetime &&
              getBookingAppointmentDay(response.result.appointment_datetime),
            walkIn: response.result && isWalkIn(response.result) ? "Yes" : "No",
            shopId: response.result.shop && parseInt(response.result.shop.id),
            vehicleId: parseInt(response.result.vehicle_id),
            email: getCurrentUser().email || null,
            paymentMode:
              cart.paymentType && cart.paymentType.toLowerCase() === "affirm"
                ? "Affirm"
                : getPaymentMethodName(),
            promoCode:
              cart && cart.promotions && cart.promotions.length > 0 ? cart.promotions[0].name : "",
            eventSourceName: "Checkout_2.0"
          })
        }
      }

      let newState = { isSubmitting: false }

      if (orderId !== false) newState = { ...newState, reviewOrder: true, orderId: orderId }

      this.setState(newState)
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ isOrderLoading: false })
    }
  }

  dateTimeSelected = async (date) => {
    if (date) {
      this.setState({
        isDateTimeSelected: true,
        date: date
      })
    } else {
      this.setState({
        isDateTimeSelected: false
      })
    }
  }

  onDateTimeSubmit = async () => {
    const { updateCart, activeVehicle, order, isCart, history } = this.props
    const { date } = this.state

    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id,
      successCallback: async (response) => {
        await appointmentDateTimeSelectedEvents({ response, activeVehicle })
      }
    }

    if (date) {
      this.setState({ isSubmitting: true })
      const date_str = date.format(DATE_TIME_FORMAT.MM_DD_YYYY_H_M)
      const event_type = date_str.includes(WALK_IN_TIME)
        ? CART_EVENTS.SET_WALK_IN
        : CART_EVENTS.SET_TIME
      const response = await updateCart(
        [{ event: event_type, appointment_datetime: date_str }],
        this.props.activeVehicle.id,
        null,
        undefined,
        opts
      )
      await trackEvent("click-continue", {
        eventSourceName: "Checkout_2.0"
      })
      this.setState({ isSubmitting: false })
    }
  }

  generatePageView = (contentComponent, optional) => {
    const {
      customerComments,
      showDemandBanner,
      showNoChargeBanner,
      selectedPaymentType,
      selectedPaymentDetails
    } = this.state
    const { t } = this.props
    return () => (
      <Grid columns={2} centered>
        {contentComponent && contentComponent}
        <Grid.Column width={6} only="mobile" className="review-order-mobile">
          {optional && optional.showNoChargesBanner && showNoChargeBanner && <NoChargeBanner />}
          <ReviewOrderMobile
            handleShopChange={this.handleShopChange}
            isExpanded={optional.isExpanded}
            setCustomerComments={this.setCustomerComments}
            customerComments={customerComments}
            isNoteExpanded={optional.isNoteExpanded}
            handlePaymentChange={this.handlePaymentChange}
            isCartUpdating={this.state.isCartUpdating}
            paymentMethodSelected={this.paymentMethodSelected}
            paymentType={selectedPaymentType}
            paymentDetails={selectedPaymentDetails}
            isLastStep={optional.isLastStep}
          />
        </Grid.Column>
        <Grid.Column width={6} only="tablet computer">
          <OrderSummaryIndex
            handleShopChange={this.handleShopChange}
            setCustomerComments={this.setCustomerComments}
            customerComments={customerComments}
            isExpanded={optional.isExpanded}
            isNoteExpanded={optional.isNoteExpanded}
            handlePaymentChange={this.handlePaymentChange}
            isCartUpdating={this.state.isCartUpdating}
            paymentMethodSelected={this.paymentMethodSelected}
            paymentType={selectedPaymentType}
            paymentDetails={selectedPaymentDetails}
            isLastStep={optional.isLastStep}
          />
        </Grid.Column>
      </Grid>
    )
  }

  schedulePage = () => {
    const { t } = this.props
    const scheduleContent = (
      <>
        <Grid.Column width={6} only="tablet computer">
          <ScheduleIndex
            showBanner={this.showBanner}
            dateTimeSelected={this.dateTimeSelected}
            showMap={this.state.showMap}
          />
        </Grid.Column>
        <Grid.Column width={6} only="mobile">
          <ScheduleIndex
            showBanner={this.showBanner}
            dateTimeSelected={this.dateTimeSelected}
            showMap={this.state.showMap}
          />
        </Grid.Column>
      </>
    )
    return this.generatePageView(scheduleContent, {
      showBanner: true,
      isExpanded: false,
      isNoteExpanded: false
    })
  }

  paymentPage = () => {
    const paymentContent = (
      <>
        <Grid.Column width={6}>
          <PaymentIndex
            showBanner={this.showBanner}
            showNoChargeBanner={this.state.showNoChargeBanner}
            onPaymentComplete={this.paymentComplete}
            paymentMethodSelected={this.paymentMethodSelected}
          />
        </Grid.Column>
      </>
    )
    return this.generatePageView(paymentContent, {
      showNoChargesBanner: true,
      isExpanded: false,
      isNoteExpanded: false
    })
  }

  setCartUpdating = (value) => {
    this.setState({ isCartUpdating: value })
  }
  reviewOrderPage = () => {
    const { cart, t, activeVehicle, missingUserInfo } = this.props
    const { vin } = activeVehicle
    const { selectedPaymentType, selectedPaymentDetails } = this.state
    const OrderSummaryContent = (
      <>
        {(!vin || missingUserInfo) && (
          <Grid.Column width={6}>
            {!vin && (
              <Grid.Column width={6}>
                <VehicleInfoUpdatePanel
                  cartId={cart && cart.id}
                  setCartUpdating={this.setCartUpdating}
                />
              </Grid.Column>
            )}
            {missingUserInfo && <ContactInformation />}
          </Grid.Column>
        )}
      </>
    )
    return this.generatePageView(OrderSummaryContent, {
      isExpanded: !!this.props.activeVehicle.vin,
      isNoteExpanded: true,
      isLastStep: true
    })
  }

  toggleMembershipUpgradeModal = () => {
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  checkMembershipUpgradeOrCreateOrder = () => {
    trackEvent("request-appointment-clicked")
    const { user, nextUpgrades } = this.props
    const percentDiscount = maxPercentDiscountDiff(user.membership, nextUpgrades)

    if (this.state.isAccountUpgrade && percentDiscount && percentDiscount > 0) {
      this.toggleMembershipUpgradeModal()
    } else {
      this.setState({ userConfirmed: true })
      this.createOrder()
    }
  }

  render() {
    const {
      t,
      cart,
      history,
      hasCC,
      zeroPrice,
      missingUserInfo,
      activeVehicle,
      isCart,
      user,
      order,
      pageContent
    } = this.props
    const {
      isDateTimeSelected,
      isPaymentMethod,
      currentStep,
      isSubmitting,
      membershipModalOpen,
      selectedPaymentType
    } = this.state
    const { reviewOrder } = this.state
    const shop = cart.shop || {}
    if (reviewOrder) {
      return <Redirect to={navigationPaths.review(this.state.orderId)} />
    }

    if (
      order &&
      !reviewOrder &&
      ![ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE, INITIATED, UNDEFINED].includes(
        order.status
      )
    ) {
      return <Redirect to={routerPaths.dashboard} />
    }
    const savePaymentEnabled =
      (hasCC || this.props.affirmDefault || selectedPaymentType || isEbayCart(user)) &&
      !!shop.id &&
      !!(cart.appointment_datetime || cart.is_walk_in)
    const isSupportsHDMD = cart && cart.shop && cart.shop.support_medium_and_heavy_duty_vehicles
    const confirmEnabled =
      (shop.cdk_dealer ||
        isEbayCart(user) ||
        hasCC ||
        this.props.affirmDefault ||
        cart.paymentType) &&
      activeVehicle.vin &&
      shop.id &&
      cart.appointment_datetime !== null &&
      !missingUserInfo
    const isVehicleSupportsShop =
      !isSupportsHDMD && activeVehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID
    const stepperContent = [
      {
        name: SCHEDULE_PAGE,
        label: t("scheduleLabel"),
        content: this.schedulePage(),
        isLoading: isDateTimeSelected,
        isComplete:
          (isDateTimeSelected || (cart && cart.appointment_datetime)) &&
          cart &&
          cart.order_services.length > 0,
        buttonLabel: t("saveDateTimeLabel"),
        clicked: this.onDateTimeSubmit,
        isLastStep: false
      },
      {
        name: PAYMENT_PAGE,
        label: t("paymentMethodLabel"),
        content: this.paymentPage(),
        isLoading: isPaymentMethod,
        isComplete: savePaymentEnabled && cart && cart.order_services.length > 0,
        buttonLabel: t("savePaymentLabel"),
        isLastStep: false,
        clicked: this.paymentComplete
      },
      {
        name: REVIEW_PAGE,
        label: t("reviewLabel"),
        content: this.reviewOrderPage(),
        isLoading:
          isDateTimeSelected && cart && cart.order_services.length > 0 && savePaymentEnabled,
        isComplete:
          confirmEnabled && !isVehicleSupportsShop && cart && cart.order_services.length > 0,
        buttonLabel: order ? t("confirmAndModifyLabel") : t("confirmAndBookLabel"),
        isLastStep: true
      }
    ]
    const updatedStepperContent = stepperContent.filter((content) => {
      return content.name == pageContent
    })

    return (
      <>
        <StepperComponent
          stepperContent={stepperContent}
          updatedStepperContent={updatedStepperContent}
          currentStep={currentStep}
          submitStepper={this.checkMembershipUpgradeOrCreateOrder}
          isInline
          t={t}
          history={history}
          ref={this.stepperRef}
          isSubmitting={isSubmitting}
          paymentRef={this.paymentRef}
          pageContent={pageContent}
        />
        {membershipModalOpen && (
          <MembershipUpgradeModal2_0
            isOpen={membershipModalOpen}
            closeModal={this.toggleMembershipUpgradeModal}
            variableContentType="checkoutSection"
            preMembershipDiscountSubtotal={preMembershipDiscountSubtotal(cart)}
            altButtonText={t("paymentPanel:completeMyOrderText")}
            altButtonOnClick={this.createOrder}
          />
        )}
      </>
    )
  }
}

function mapStateToProps(state, props) {
  const { user, paymentInfo, membershipData } = state
  const activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let cart
  let isCart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  let zeroPrice =
    cart.estimated_total == 0 &&
    cart.order_services.filter(
      (os) => os.price_estimate && os.price_estimate.shop_price_estimate === null
    ).length === 0
  const missingUserInfo =
    ["firstName", "lastName", "cellPhone", "email"].filter((attr) => {
      return [null, undefined, ""].includes(user[attr])
    }).length > 0 ||
    (user.cellPhone && user.cellPhone.length < 10) ||
    user.generic

  return {
    user,
    activeVehicle,
    order,
    cart,
    zeroPrice,
    missingUserInfo,
    isLoggedIn: !!user.authentication_token,
    caradviseCash: paymentInfo.caradviseCash,
    hasCC: user.has_cc_info_saved,
    affirmDefault: state.paymentInfo.affirmDefault || false,
    nextUpgrades: nextMembershipUpgrades(user, membershipData.availableMembershipPlans),
    isCart
  }
}

export default connect(mapStateToProps, {
  updateCart,
  createOrder,
  updateOrder,
  getPaymentCards,
  openShopListView
})(withTranslation("checkout")(CheckoutIndex))
