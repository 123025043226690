import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const OrderSummaryFooter = (props) => {
  const { cart, t } = props
  const totalPriceText = (cart) => {
    if (cart.any_order_service_lacks_price) {
      return t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(cart.estimated_total)
    }
  }

  return (
    <div className="order-summary-footer-section">
      <div className="footer-header">
        <div className="header-label">{t("estimatedTotalLabel")}</div>
        <div className="header-value">{totalPriceText(cart)}</div>
      </div>
      <div className="header-meta">{t("additionalCostLabel")}</div>
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(withTranslation("cartPanel")(OrderSummaryFooter))
