import React from "react"

import { connect } from "react-redux"

import { withTranslation, useTranslation } from "react-i18next"

import ShopItemComponent from "./ShopItemComponent"
import { getActiveVehicle } from "../../../../helpers/vehicleHelpers"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"
import FeaturedLabel from "./FeaturedLabel"
import ModalDialog from "../../../../components/ModalDialog"
import { Image, Popup } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { filteredShops } from "../../../../helpers/shopHelpers"
import ReactSVG from "react-svg"
import ToolTipIcon from "../../../../../images/info_icon_vector.svg"

const ShopListViewItemComponent = (props) => {
  const { t } = useTranslation("dashboardMapComponent")

  let vehicleTypeId
  if (props.user) {
    const activeVehicle = getActiveVehicle()
    vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
  }

  const height =
    vehicleTypeId && vehicleTypeId === MD_HD_VEHICLE_TYPE_ID && props.showMapExpanded
      ? Math.max(window.innerHeight * 0.65, 300) - 80
      : Math.max(window.innerHeight * 0.65, 300) - 20

  const shopList = filteredShops(props.shops)
  const isMDHDVehicle = vehicleTypeId === MD_HD_VEHICLE_TYPE_ID
  return (
    <div
      className={`shopListView ${isMDHDVehicle ? "shopListView-with-md-hd" : ""}`}
      style={{ height: height }}
    >
      {props.shops && props.shops.length > 0 ? (
        shopList.allShops.map((shop, index) => (
          <>
            {shopList.sponsoredShops && shopList.sponsoredShops.length > 0 && index == 0 && (
              <div className="sponsored-shop-label">
                <span>
                  {t("sponsoredShopLabel")}
                  <ModalDialog
                    linkText={<ReactSVG src={ToolTipIcon} />}
                    className="primary-button-color"
                  >
                    {t("sponsoredShopInfo")}
                  </ModalDialog>
                </span>
              </div>
            )}
            {index == shopList.sponsoredShops.length && (
              <div className="sponsored-shop-label">{t("closestToYouLabel")}</div>
            )}
            <FeaturedLabel shop={shop} listView={true} />
            <ShopItemComponent
              showMapExpanded={true}
              key={index}
              onClose={props.onClose}
              shop={shop}
              onSelectShopClick={props.onSelectShopClick}
            />
          </>
        ))
      ) : (
        <div className={props.showMapExpanded ? "noShopFoundExpanded" : "noShopFound"}>
          <div className="shops-empty-state">{t("noShopFound")}</div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardMapComponent")(ShopListViewItemComponent)
)
