import React from "react"
import { useTranslation } from "react-i18next"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"

export default function SavingsRibbon({
  savingsText,
  ribbonStyle,
  isPopup = false,
  isExpanded = false,
  containerStyle = { marginTop: "5px", marginRight: "0px", paddingBottom: "10px" },
  textStyle
}) {
  const { t } = useTranslation("savingsRibbon")
  if (!savingsText) return null

  return (
    <div className="savings-ribbon-container" style={containerStyle}>
      <div
        className={
          (isPopup && isExpanded) || isExpanded
            ? "savings-ribbon savings-ribbon-expanded-popup"
            : isPopup
            ? "savings-ribbon savings-ribbon-expanded"
            : "savings-ribbon"
        }
        style={ribbonStyle}
      >
        <div
          className={
            (isPopup && isExpanded) || isExpanded
              ? "savings-text savings-text-expanded savings-text-popup"
              : isPopup
              ? "savings-text savings-text-expanded savings-text-popup"
              : "savings-text"
          }
          style={textStyle}
        >
          {`${numberFormatToCurrency(Number(savingsText).toFixed(2))} ${t("offRetailLbl")}`}
        </div>
      </div>
    </div>
  )
}
