import React from "react"
import { ServicePriceRangeSpan } from "./SearchServicesPriceRange"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import { formatParagraph } from "../../../v1/helpers/stringHelpers"

function NoInfo() {
  const { t } = useTranslation("noPriceExplanation")
  return (
    <div className="no-price-estimation-info-section">
      <div className="no-estimate-section">
        <ReactSVG src="images/sidemenu_icon/faq.svg" />
        <div>{t("noEstimateText")}</div>
      </div>
      <GuaranteedPriceSection />
    </div>
  )
}

function GuaranteedPriceSection() {
  const { t } = useTranslation("noPriceExplanation")
  return (
    <div className="guaranteed-price-section">
      <ul>
        <li className="guaranteed-price-text">
          {formatParagraph(t("guaranteedPriceDescription"), 700, "underline")}
        </li>
        <li className="guaranteed-price-text">
          {formatParagraph(t("finalPriceDescription"), 700, "underline")}
        </li>
      </ul>
    </div>
  )
}

function canDisplay(high, low) {
  return high && (low || low === 0)
}

function ShowInfo({ children, high, low }) {
  const { t } = useTranslation("noPriceExplanation")
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <p style={{ marginBottom: 0 }}>
          <strong>
            <ServicePriceRangeSpan high={high} low={low} />
          </strong>
        </p>
        <p style={{ marginTop: 0 }}>
          <strong>
            <small className="caradvise-electric-blue">{t("estimatedRangeLbl")}</small>
          </strong>
        </p>
      </div>
      <GuaranteedPriceSection />
    </div>
  )
}

export default function ServiceNoPriceExplanation({ priceEstimate }) {
  if (
    !priceEstimate ||
    priceEstimate.shop_price_estimate === 0 ||
    priceEstimate.shop_price_estimate
  )
    return null

  let low =
    parseFloat(priceEstimate.statistical_estimate) -
    2 * parseFloat(priceEstimate.statistical_estimate_stdev)
  if (low < 0) low = 0
  let high =
    parseFloat(priceEstimate.statistical_estimate) +
    2 * parseFloat(priceEstimate.statistical_estimate_stdev)
  if (high < 0) high = 0

  if (canDisplay(high, low)) return <ShowInfo high={high} low={low} />
  else if (
    priceEstimate.oem_labor !== null &&
    priceEstimate.oem_parts !== null &&
    priceEstimate.retail_labor_rate !== null &&
    priceEstimate.shop_labor_rate !== null
  ) {
    const retail =
      parseFloat(priceEstimate.oem_labor) * parseFloat(priceEstimate.retail_labor_rate) +
      parseFloat(priceEstimate.oem_parts)
    const shop =
      parseFloat(priceEstimate.oem_labor) * parseFloat(priceEstimate.shop_labor_rate) +
      parseFloat(priceEstimate.oem_parts)
    const [low, high] = [shop, retail].sort()
    return <ShowInfo high={high * 1.25} low={low * 0.75} />
  }

  return <NoInfo />
}
