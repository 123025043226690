import React, { useEffect, useState } from "react"
import { Button, Icon } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import ReactModal from "react-modal"

export default function ServicePositionModal({
  closeModal,
  onSave,
  isOpenModel,
  serviceOptions,
  index,
  service,
  serviceName,
  activeVehicle,
  loadingStates
}) {
  const { t } = useTranslation("sevicePositionsModal")

  const newServiceOptions = serviceOptions.map((item) => {
    return {
      name: item,
      checked: false
    }
  })

  useEffect(() => {
    if (activeVehicle && activeVehicle.length > 0) {
      const optionName = activeVehicle[0].oil_type_name
      const recommendedOptions = serviceOptions.find((item) => item === optionName)
      setPosition(recommendedOptions)
    }
  }, [])

  const [selectedPosition, setPosition] = useState(null)

  const handleChange = (selectedPosition) => {
    setPosition(selectedPosition)
  }
  return (
    <>
      <ReactModal
        className="service-position-container"
        isOpen={isOpenModel}
        overlayClassName="Overlay"
        onRequestClose={() => closeModal(service)}
      >
        <div className="service_position-header">
          <div className="primary-header">
            <p>
              {t("selectPositionLabel")} {serviceName}
            </p>
            <Icon name="times" className="header-icon" onClick={() => closeModal()} />
          </div>
          <div className="header-meta">
            <p>{t("pleaseSelect")}</p>
          </div>
        </div>
        <div className="model_checkbox-container">
          {serviceOptions &&
            serviceOptions.map((item, i) => (
              <div className="checkbox-container" key={i}>
                <input
                  type="radio"
                  id={item}
                  name="positionRadio"
                  checked={item === selectedPosition} // Set the checked value based on selectedPosition
                  className="modal-custom-radio"
                  value={item}
                  onChange={() => handleChange(item)} // Use onChange event for radio buttons
                />
                <label htmlFor={item}>{item}</label>
                {item ===
                (activeVehicle && activeVehicle.length > 0 && activeVehicle[0].oil_type_name) ? (
                  <div className="recommended-label-container">
                    <p className="recommended-label">{t("best")}</p>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        <Button
          className="save-position-button"
          disabled={selectedPosition ? false : true}
          onClick={() => onSave(index, service, selectedPosition)}
        >
          <span className="secondary-font text">{t("save")}</span>
        </Button>
      </ReactModal>
    </>
  )
}
