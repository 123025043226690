import { deviceDetect, isMobile, deviceType } from "react-device-detect"
import store from "../../shared_component/utils/configureStore"

export const getCurrentUser = () => {
  const state = store.getState()
  if (state && state.user) return state.user
  return {}
}

export const showChatWidget = (e) => {
  e.preventDefault()
  // window.zE("messanger", "open")
  window.zE("webWidget", "open")
}

export const getDeviceProperties = () => {
  let deviceInfo = deviceDetect()
  let device = deviceType
  let osName, isBrowser, browserName

  if (isMobile) {
    device = deviceInfo.model
    isBrowser = isMobile ? false : true
    osName = deviceInfo.os
  } else {
    isBrowser = deviceInfo.isBrowser
    browserName = deviceInfo.browserName
    osName = deviceInfo.osName
  }

  return {
    device,
    isBrowser: isBrowser,
    ...(isMobile ? {} : { browserName: browserName }),
    osName: osName || "N/A"
  }
}
