import { SHOW_NOTIFICATION_MODAL, HIDE_NOTIFICATION_MODAL } from "../constants/reduxActions"

const initialState = {}

export default function modalNotifications(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION_MODAL:
      return Object.assign({}, state, action.payload)
    case HIDE_NOTIFICATION_MODAL:
      return Object.assign({}, state, { message: null })

    default:
      return state
  }
}
