import React, { useEffect, useState } from "react"
import { Card, CardContent, CardHeader, Image, Dropdown } from "semantic-ui-react"
import defaultTire from "../../images/DefaultTire.png"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { Link, Redirect } from "react-router-dom"
import { saveTireDetails } from "../actions/tires"
import { connect } from "react-redux"
import LoadingComponent from "./LoadingComponent"
import { CHARACTERISTICS, FEATURES, BENEFITS, DEFAULT_TIRE_QUENTY } from "../constants/tires"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { routerPaths } from "../constants/paths"
import { formatPrice } from "../../shared_component/utils/helpers"
import { numberFormatToCurrency } from "../../shared_component/utils/currency"

function TireDetails({ t, savedTires, saveTireDetails, user, selectedTire }) {
  const [toggleState, setToggleState] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [characteristic, setCharacteristic] = useState([])
  const [feature, setFeature] = useState([])
  const [benefit, setBenefit] = useState([])
  const [partnerFlag, setPartnerFlag] = useState()
  const quantity =
    savedTires && savedTires.filter((item) => item.tireId === selectedTire.id).length > 0
      ? savedTires.filter((item) => item.tireId === selectedTire.id)[0].quantity
      : DEFAULT_TIRE_QUENTY
  const [selectedTireQty, setSelectedTireQty] = useState(quantity)

  const objectCollection = [
    { id: 1, label: "1" },
    { id: 2, label: "2" },
    { id: 3, label: "3" },
    { id: 4, label: "4" }
  ]

  const dataprovider = objectCollection.map((object) => {
    return { key: object.id, value: object, text: object.label }
  })

  useEffect(() => {
    getTireDetails()
  }, [])

  const getTireDetails = async () => {
    if (selectedTire) {
      let features = selectedTire.marketing.features
      if (features && features.length > 0) {
        features.map((item) => {
          if (item.characteristic) {
            setCharacteristic((old) => [...old, item.characteristic])
          }
          if (item.feature) {
            setFeature((old) => [...old, item.feature])
          }
          if (item.benefit) {
            setBenefit((old) => [...old, item.benefit])
          }
          setToggleState(item.characteristic ? CHARACTERISTICS : item.feature ? FEATURES : BENEFITS)
        })
      }
      setIsLoading(false)
    }
  }

  const changeHandler = (event, data) => {
    setSelectedTireQty(data.value.id)
  }

  const handleAddToCartBtn = () => {
    if (isTireSelected()) {
      store.dispatch(
        Notifications.warning({
          title: t("Tire_purchase_detail"),
          position: "tr",
          autoDismiss: 2,
          action: {
            label: "Dismiss"
          }
        })
      )
      return
    }

    const tempArr = savedTires.filter((item) => item.tireId !== selectedTire.id)
    const finalArr = [
      {
        tireId: selectedTire.id,
        tireDetails: selectedTire,
        quantity: selectedTireQty && selectedTireQty,
        price: selectedTire && selectedTire.price
      }
    ]
    saveTireDetails(finalArr)
    store.dispatch(
      Notifications.success({
        title:
          selectedTireQty > 1
            ? `${selectedTireQty} ${t("Tires_success_title")}`
            : `${selectedTireQty} ${t("Tire_success_title")}`,
        position: "tr",
        autoDismiss: 2,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  const toggleTab = (activeTab) => {
    setToggleState(activeTab)
  }

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const onImageError = (e) => {
    e.target.src = defaultTire
  }

  const isTireSelected = () => {
    return savedTires && savedTires.length > 0 ? savedTires[0].tireId !== selectedTire.id : false
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <div className="tire-details-screen">
          <div className="tire-details-parent">
            <div className="heading-parent">
              <div className="tires-heading">
                <div onClick={() => window.history.back()} className="back-link">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <h3>{t("Back")}</h3>
                </div>
              </div>
            </div>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <div className="tire-details">
                <div className="card-parent">
                  <Card className="tire-card">
                    <CardHeader>
                      <div className="tire-name-parent">
                        <div className="tire-name">
                          {selectedTire && selectedTire.brand + " " + selectedTire.model}
                        </div>
                        {/* Will need this section in future */}
                        {/* <div className="slug">
                      The TURANZA T005 touring tire has been specifically designed to meet demanding
                      original equipment manufacturer specifications. They allow the vehicle to
                      perform as the manufacture intended in dry and wet conditions
                    </div> */}
                      </div>
                      {selectedTire && selectedTire.brand_logo ? (
                        <div className="logo-parent">
                          <Image src={selectedTire.brand_logo} />
                        </div>
                      ) : null}
                    </CardHeader>
                    <div className="about-parent">
                      <ul>
                        <li>
                          <span>{selectedTire && selectedTire.size}</span>
                          <br />
                          {t("Tire_Size")}&nbsp;
                        </li>
                        <li>
                          <span>{selectedTire && selectedTire.part_number}</span>
                          <br />
                          {t("Manufacturer_Part")}
                        </li>
                        <li>
                          <span>
                            {selectedTire &&
                              selectedTire.utqg &&
                              selectedTire.utqg.wear +
                                "" +
                                selectedTire.utqg.traction +
                                "" +
                                selectedTire.utqg.temp}
                          </span>
                          <br />
                          {t("UTQG")}
                        </li>
                        <li>
                          <span>{selectedTire && selectedTire.side_wall_style}</span>
                          <br />
                          {t("Sidewall_color")}
                        </li>
                        <li>
                          <span>
                            {selectedTire &&
                              selectedTire.load_index + "" + selectedTire.speed_rating}
                          </span>
                          <br />
                          {t("Load_speed_rating")}
                        </li>
                      </ul>
                    </div>
                    <div className="tabs-parent">
                      <div className="content-parent">
                        <div className="bloc-tabs">
                          {characteristic && characteristic.length > 0 ? (
                            <div
                              className={
                                toggleState === CHARACTERISTICS ? "tabs active-tabs" : "tabs"
                              }
                              onClick={() => toggleTab(CHARACTERISTICS)}
                            >
                              {t("Characteristics")}
                            </div>
                          ) : null}
                          {feature && feature.length > 0 ? (
                            <div
                              className={toggleState === FEATURES ? "tabs active-tabs" : "tabs"}
                              onClick={() => toggleTab(FEATURES)}
                            >
                              {t("Features")}
                            </div>
                          ) : null}
                          {benefit && benefit.length > 0 ? (
                            <div
                              className={toggleState === BENEFITS ? "tabs active-tabs" : "tabs"}
                              onClick={() => toggleTab(BENEFITS)}
                            >
                              {t("Benefits")}
                            </div>
                          ) : null}
                        </div>
                        <div className="content-tabs">
                          <div
                            className={
                              toggleState === CHARACTERISTICS
                                ? "tire-content active-content-tire"
                                : "tire-content"
                            }
                          >
                            {characteristic && characteristic.length > 0 ? (
                              <div className="characteristics-parent">
                                <div className="characteristics">
                                  <ul>
                                    {characteristic.map((item) => {
                                      return <li>{item}</li>
                                    })}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={
                              toggleState === FEATURES
                                ? "tire-content active-content-tire"
                                : "tire-content"
                            }
                          >
                            {feature && feature.length > 0 ? (
                              <div className="characteristics-parent">
                                <div className="characteristics">
                                  <ul>
                                    {feature.map((item) => {
                                      return <li>{item}</li>
                                    })}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={
                              toggleState === BENEFITS
                                ? "tire-content active-content-tire"
                                : "tire-content"
                            }
                          >
                            {benefit && benefit.length > 0 ? (
                              <div className="characteristics-parent">
                                <div className="characteristics">
                                  <ul>
                                    {benefit.map((item) => {
                                      return <li>{item}</li>
                                    })}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="tire-img">
                        <Image
                          src={
                            selectedTire && selectedTire.image ? selectedTire.image : defaultTire
                          }
                          onError={onImageError}
                        />
                      </div>
                    </div>
                    <CardContent>
                      <div className="qty-box">
                        <div className="qty">{t("Qty")}:</div>
                        <Dropdown
                          options={dataprovider}
                          onChange={(event, data) => changeHandler(event, data)}
                          className="qty-dropdown"
                          placeholder={quantity}
                        />
                      </div>
                      <div className="price-box">
                        <div className="price">
                          ${selectedTire && formatPrice(selectedTire.price)}
                        </div>
                        <div className="price-for-set">
                          {savedTires &&
                          savedTires.filter((item) => item.tireId === selectedTire.id).length > 0
                            ? `${numberFormatToCurrency(
                                selectedTire && selectedTire.price * selectedTireQty
                              )} ${t("for")} ${selectedTireQty} ${
                                selectedTireQty > 1 ? t("tires") : t("tire")
                              }`
                            : `${numberFormatToCurrency(selectedTireQty * selectedTire.price)} ${t(
                                "for"
                              )} ${selectedTireQty} ${
                                selectedTireQty > 1 ? t("tires") : t("tire")
                              }`}
                        </div>
                      </div>
                    </CardContent>
                    <div className="card-btn" onClick={handleAddToCartBtn}>
                      <Link
                        to={isTireSelected() ? routerPaths.tireDetails : routerPaths.confirmTire}
                        className={isTireSelected() ? "shop-btn-disabled" : "shop-btn"}
                      >
                        {t("Add_to_Cart")}
                      </Link>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let savedTires = state.saveBuyTireDetails.tireArray
  let selectedTire = state.saveBuyTireDetails.selectedTire

  return {
    user: user,
    savedTires: savedTires,
    selectedTire: selectedTire
  }
}

export default connect(mapStateToProps, { saveTireDetails })(
  withTranslation("tireDetails")(TireDetails)
)
