import React from "react"
import store from "../../../shared_component/utils/configureStore"
import { Button, Form, Grid } from "semantic-ui-react"
import { createCustomersPromotion, loadCustomersPromotions } from "../../../v1/actions/promotion"
import Notifications from "../../../v1/components/Notifications"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class ApplyPromotion extends React.Component {
  state = {
    code: ""
  }

  async componentDidMount() {
    await this.props.loadCustomersPromotions({ cartId: this.props.cart.id })
  }

  onSubmitCode = (e) => {
    e.preventDefault()
    const { code } = this.state
    if ([null, undefined, ""].includes(code)) return

    this.props.createCustomersPromotion({
      code: this.state.code,
      cartId: this.props.cart.id,
      callback: this.afterRequest
    })
  }

  afterRequest = (status, data) => {
    let baseAttrs = { position: "tr", autoDismiss: 5, action: { label: "Dismiss" } }
    const { t } = this.props
    if (status === "success") {
      store.dispatch(
        Notifications.success({
          ...baseAttrs,
          title: t("successTitle"),
          message: t("promotionAppliedMessage")
        })
      )
    } else {
      store.dispatch(
        Notifications.error({
          ...baseAttrs,
          title: t("errorTitle"),
          message: `${data.message || t("errorMessage")}`
        })
      )
    }
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  render() {
    const { t } = this.props
    const { code } = this.state

    return (
      <React.Fragment>
        <div className="promotion-container">
          <div className="promotion-input">
            <Form.Input
              fluid
              value={code}
              onChange={this.onCodeChange}
              placeholder={t("promotionCodePlaceholderLabel")}
            />
          </div>
          <div className="promotion-button">
            <Button
              className="apply-button"
              onClick={this.onSubmitCode}
              content={t("applyLabel")}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const promotionData = state.promotionData || {}

  return {
    availablePromotions: promotionData.availablePromotions,
    usedPromotions: promotionData.usedPromotions
  }
}

export default connect(mapStateToProps, { createCustomersPromotion, loadCustomersPromotions })(
  withTranslation("availablePromotionsContent")(ApplyPromotion)
)
