import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { Sidebar } from "semantic-ui-react"
import EarnMilesSuccess from "./EarnMilesSuccess"

const EarnRewardsSuccessContainer = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false)
    }, 4000)
  }, [])

  return isMobile ? (
    <div className="booking-miles-complete">
      <Sidebar
        animation="overlay"
        direction="bottom"
        vertical
        visible={openDrawer}
        onHide={() => setOpenDrawer(false)}
        className="side-panel-container"
      >
        <EarnMilesSuccess setOpenDrawer={setOpenDrawer} />
      </Sidebar>
    </div>
  ) : (
    isVisible && (
      <div className="booking-miles-complete-web">
        <EarnMilesSuccess setOpenDrawer={() => setIsVisible(false)} />
      </div>
    )
  )
}

export default EarnRewardsSuccessContainer
