import React from "react"

import { Icon, Header } from "semantic-ui-react"

const PageTitle = (props) => {
  const { title, onBackClick = () => {}, showBack = true } = props

  return (
    <div className="flexbox-component-header">
      <Header
        as="h1"
        className="primary-font maintenance-schedule-header maintenanceScheduleHeader"
      >
        {showBack && (
          <Icon.Group size="small" className="back-icon">
            <Icon name="arrow left" onClick={onBackClick} />
          </Icon.Group>
        )}
        {title}
      </Header>
    </div>
  )
}

export default PageTitle
