export const routerPaths = {
  root: `/`,
  dashboard: "/dashboard",
  approvals: "/approvals",
  download: "/download",
  gloveboxComponent: "/gloveboxComponent",
  inviteFriends: "/invite-friends",
  myAccount: "/myaccount",
  schedule: "/schedule",
  selectMembership: "/select_membership",
  services: "/selectCommonMaintenance",
  shop: "/selectShop",
  signIn: "/signIn",
  signUp1: "/sign_up/1",
  signUp2: "/sign_up/2",
  signUp3: "/sign_up/3",
  tires: "/tires",
  reschedule: "/reschedule",
  carperks: {
    signup: "/carperks/signup"
  },
  suggestedMaintenanceDetails: "/maintenance_schedule_details/:id",
  updatePassword: "/update_password",
  paymentInformation: "/payment_info",
  personalInfo: "/personal_info",
  preferences: "/preferences",
  accountTier: "/account_tier",
  maintenanceSchedule: "/maintenanceSchedule",
  confirmInstallation: "/confirm-installation",
  confirmTire: "/confirm-tire",
  booking: "/booking",
  bookingConfirmation: "/booking-confirmation/:id",
  offers: "/offers",
  topTires: "/top-tires",
  tireDetails: "/tire-details",
  managevehicles: "/managevehicles",
  tireSize: "/tire-size",
  confirmInstallationDate: "/confirm-installation-date",
  offers: "/offers",
  glovebox: "/gloveboxComponent",
  inviteFriends: "/invite-friends",
  membership: "/membership",
  addServices: "/add-services",
  cartSummary: "/cart-summary",
  checkoutSchedule: "/checkout/schedule",
  review: "/shop_orders/:id/review",
  confirmDetails: "/ConfirmDetails",
  insuranceCarMileage: "/insurance-car-mileage",
  autoGlass: "/offer/auto-glass",
  warrantyPlan: "/WarrantyPlan",
  redirect: "/redirect",
  planDetails: "/plan-details",
  chooseCoverage: "/choose-coverage",
  rewards: "/rewards",
  rewardsHistory: "/rewards-history",
  review: "/shop_orders/:id/review",
  earnMiles: "/earn-miles",
  sponsoredPromotions: "/sponsor-promotions",
  rescheduleAppointment: "/reschedule-appointment",
  magicLink: "/magic_link",
  checkoutPayment: "/checkout/payment",
  checkoutReview: "/checkout/review"
}

export const navigationPaths = {
  checkout: (id) => `/carts/${id}/confirm`,
  suggestedMaintenanceDetails: (id) => `/maintenance_schedule_details/${id}`,
  maintenanceHistory: (vehicleId) => `/vehicles/${vehicleId}/history`,
  review: (orderId) => `/shop_orders/${orderId}/review`,
  addServices: () => `/add-services`,
  bookingConfirmation: (id) => `/booking-confirmation/${id}`
}

export const HOSTED_IMAGE_BASE_URL = "https://s3.amazonaws.com/assets.caradvise.com/"

export function routeChange(history, path) {
  history.push(path)
}
