import React from "react"
import { Dimmer, Loader } from "semantic-ui-react"

// This dimmed loading component will fill the size of the closest parent with CSS position: relative
export default function DimmerLoader({ message, size = "medium" }) {
  return (
    <Dimmer active inverted style={{ padding: 0 }}>
      <Loader indeterminate size={size}>
        {message}
      </Loader>
    </Dimmer>
  )
}
