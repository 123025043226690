import React from "react"
import { Image } from "semantic-ui-react"
import CardIcon from "../../../images/CA-Icon.svg"
import Vehicle from "../../../images/vehicle.svg"
import { withTranslation } from "react-i18next"
import basicImg from "../../../images/BasicBadge.svg"
import eliteImg from "../../../images/Elitebadge.svg"
import premiumImg from "../../../images/Premiumbadge.svg"
import moment from "moment"
import { connect } from "react-redux"
import { BASIC, PREMIUM } from "../../constants/tires"
import { DATE_TIME_FORMAT } from "../../constants/application"

const BookingCard = ({ user, t, membership }) => {
  const { plan_name } = membership

  const badgeImage = plan_name === BASIC ? basicImg : plan_name === PREMIUM ? premiumImg : eliteImg
  const username = user && user.firstName + " " + user.lastName

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "")

    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3]
    }

    return null
  }

  return (
    <div className="booking-card">
      <div className="container">
        <div className="element1">
          <Image className="cardIcon" src={CardIcon} />
        </div>
        <div className="membershipId">
          <p className="membership-label">{t("membershipId")}</p>
          <p className="membership-id">
            {t("id")}: {membership.id} | {t("Since")}{" "}
            {moment(membership.enrollment_date).format(DATE_TIME_FORMAT.MM_YY)}
          </p>
        </div>
        <div className="element3">
          <Image src={badgeImage} />
        </div>
      </div>
      <div className="membership-detail">
        <div className="member-info">
          <p className="label align-left">{t("member")}</p>
          <p className="value align-left">{username}</p>
        </div>
        <div className="member-info">
          <p className="label">
            {user && user.vehicles && user.vehicles.length <= 1 ? t("vehicle") : t("vehicles")}
          </p>
          <p className="value vehicle-detail">
            <Image className="cardIcon" src={Vehicle} />
            <p>{user && user.vehicles && user.vehicles.length}</p>
          </p>
        </div>
        <div className="member-info">
          <p className="label align-right">{t("contact")}</p>
          <p className="value align-right">
            {user && user.cellPhone ? formatPhoneNumber(user.cellPhone) : ""}
          </p>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let membership = state.user.membership
  return { user: user, membership: membership }
}

export default connect(mapStateToProps)(withTranslation("bookingCard")(BookingCard))
