import React from "react"
import { Grid } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { showChatWidget } from "../../helpers/userHelper"

export default function NoServices() {
  const { t } = useTranslation("noServices")
  return (
    <Grid verticalAlign="middle" padded="vertically" stackable className="search-item">
      <Grid.Column textAlign="left">
        {t("noServicesMessage")}
        &nbsp;
        <span className="link" onClick={showChatWidget}>
          {t("contactUsLbl")}
        </span>
      </Grid.Column>
    </Grid>
  )
}
