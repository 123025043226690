import React from "react"
import { Popup } from "semantic-ui-react"

const TIMEOUT_LENGTH = 1500

export default class CartInfoPopup extends React.Component {
  state = {
    lastCount: this.props.count,
    diff: 0
  }

  handleOpen = () => {
    this.setState({ isOpen: true })

    setTimeout(() => {
      this.setState({ isOpen: false })
    }, TIMEOUT_LENGTH)
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  componentDidUpdate() {
    const { count } = this.props
    const { lastCount, diff } = this.state

    if (count !== lastCount) {
      this.setState({ lastCount: count, diff: lastCount - count }, this.handleOpen())
    }
  }

  render() {
    const { count, trigger } = this.props
    const { diff } = this.state
    const positiveDiff = diff > 0
    const absDiff = Math.abs(diff)
    const textColor = positiveDiff ? "red" : "black"

    if (!Number.isInteger(count) || !Number.isInteger(diff)) return null

    return (
      <Popup
        content={
          <div style={{ height: "15px" }}>{`You ${positiveDiff ? "removed" : "added"} ${absDiff} ${
            absDiff > 1 ? "services!" : "service!"
          }`}</div>
        }
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position="bottom center"
        trigger={trigger}
        size="mini"
        style={{
          height: "15px",
          marginRight: "5px",
          transform: "translate(0, -15px)",
          color: textColor
        }}
      />
    )
  }
}
