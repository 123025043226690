import React from "react"
import { isAndroid, isDesktop, isIOS, isMacOs } from "react-device-detect"
import { Image } from "semantic-ui-react"
import { trackEvent } from "../utils/segmentAnalytics"
import AddToGoogleWalletIcon from "../../images/Add_to_Google_Wallet.svg"
import AddToAppleWalletIcon from "../../images/Add_to_Apple_Wallet.svg"
import { WALLET_TYPE } from "../constants/common"

export const Wallets = ({ appleWalletUrl = null, googleWalletUrl = null, planName = null }) => {
  if (!appleWalletUrl || !googleWalletUrl) return null
  return (
    <div className="wallet-buttons">
      {appleWalletUrl && (
        <>
          {isIOS || (isDesktop && isMacOs) ? (
            <a href={isIOS || (isDesktop && isMacOs) ? appleWalletUrl : ""} target="_blank">
              <Image
                size="small"
                onClick={() => {
                  trackEvent("membership-add-to-wallet-clicked", {
                    walletType: WALLET_TYPE.APPLE,
                    planType: (planName && planName.toLowerCase()) || null
                  })
                }}
                src={AddToAppleWalletIcon}
              />
            </a>
          ) : null}
        </>
      )}
      {googleWalletUrl && (
        <>
          {isAndroid || isDesktop ? (
            <a href={isAndroid || isDesktop ? googleWalletUrl : ""} target="_blank">
              <Image
                size="small"
                onClick={() => {
                  trackEvent("membership-add-to-wallet-clicked", {
                    walletType: WALLET_TYPE.GOOGLE,
                    planType: (planName && planName.toLowerCase()) || null
                  })
                }}
                src={AddToGoogleWalletIcon}
              />
            </a>
          ) : null}
        </>
      )}
    </div>
  )
}
