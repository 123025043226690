import React from "react"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import MilesProgramLogo from "../../../images/rewards/rewards_program_logo.svg"
import EarnMilesStepInfo from "./EarnMilesStepInfo"

const EarnMilesStpes = ({ howRewardsWorksDetails, t }) => {
  return (
    <div className="earn-miles-steps-container">
      <ReactSVG src={MilesProgramLogo} className="miles-program-logo" />
      <p className="earn-miles-header">{t("welcomeCarAdviseProgram")}</p>
      {howRewardsWorksDetails &&
        howRewardsWorksDetails.length > 0 &&
        howRewardsWorksDetails.map((steps, index) => (
          <EarnMilesStepInfo
            key={steps.id}
            steps={steps}
            isLastChild={howRewardsWorksDetails.length - 1 === index}
          />
        ))}
    </div>
  )
}

export default withTranslation("earnMilesSteps")(EarnMilesStpes)
