import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import store from "../../../shared_component/utils/configureStore"
import { updateCart } from "../../../v1/actions/user"
import LoadingComponent from "../../../v1/components/LoadingComponent"
import { GAEvent, GAEventCategories } from "../../../v1/tracking/GAEvent"
import ReactSVG from "react-svg"
import { withRouter } from "react-router-dom"
import { serviceSelectedEvents } from "../../../shared_component/utils/googleAnalyticsHelpers"

function AddCartButtons(props) {
  const { position, serviceDefinition, os, isPresentInCart, activeVehicle, isCart, order } = props
  const { t } = useTranslation("addButtons")
  const add = async () => {
    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id,
      successCallback: async () => {
        await serviceSelectedEvents({ serviceDefinition, position, activeVehicle })
      }
    }
    await store.dispatch(
      updateCart(
        [
          {
            event: "added",
            position: position,
            service_definition_id: serviceDefinition.id
          }
        ],
        null,
        undefined,
        true,
        opts
      )
    )
    const events = new CustomEvent("cartSidePanelOpen", { detail: true })
    document.dispatchEvent(events)
    setLoading(false)
  }

  const [isLoading, setLoading] = useState(false)

  if (isLoading) return <LoadingComponent />

  const disabled =
    (serviceDefinition &&
      (serviceDefinition.service_options || [])[0] &&
      serviceDefinition.service_options[0].positions &&
      serviceDefinition.service_options[0].positions.length > 0 &&
      !position) ||
    (isPresentInCart && os.position && os.position === position) ||
    (isPresentInCart && !position)
  return (
    <div className="search-service-buttons-section">
      <div
        className={`search-service-buttons${disabled ? "-disabled" : ""}`}
        onClick={() => {
          setLoading(true)
          GAEvent(GAEventCategories.SEARCH_SERVICES, "search-add-browse", "Add & Continue Browsing")
          add()
        }}
      >
        <ReactSVG src="images/dashboard/plus-icon.svg" />
        <div className="button-link">{t("addServicesAndContinueText")}</div>
      </div>
    </div>
  )
}

export default withRouter(AddCartButtons)
