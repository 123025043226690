import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  OIL_CHANGE_REFERENCE_ID,
  findOilPositionIndex
} from "../../../v1/helpers/orderServiceHelpers"
import { translatedPosition } from "../../../v1/helpers/translationHelpers"

class ServicePositionSelector extends Component {
  render() {
    const { serviceDefinition, position, setPosition, activeVehicle, t } = this.props
    const so = serviceDefinition.service_options[0]

    const recommendedPositionOrderValue =
      activeVehicle && findOilPositionIndex(activeVehicle.oil_type_name)
    const currentPositionOrderValue = findOilPositionIndex(position)

    let oilType = activeVehicle && activeVehicle.oil_type_name
    return (
      <div className="positions-container">
        <div className="position-content">
          {so.positions.map((p) => {
            const positionTranslation = translatedPosition({ position: p, t: t })
            return (
              <div className="position" onClick={() => setPosition(p)}>
                <input type="checkbox" checked={position == p} onChange={() => setPosition(p)} />
                <div textAlign="left">
                  <span>{positionTranslation} </span>
                </div>
                {p === oilType ? (
                  <div className="recommended-label">{t("recommendedLabel")}</div>
                ) : null}
              </div>
            )
          })}
          {serviceDefinition.reference_id === OIL_CHANGE_REFERENCE_ID &&
            currentPositionOrderValue < recommendedPositionOrderValue && (
              <div style={{ color: "red", width: "250px" }}>{t("warningMessage")}</div>
            )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let activeOrder =
    user.active_orders && user.active_orders.find((s) => s.vehicle_id == user.activeVehicleId)
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    user: user,
    cart: cart,
    location: props.location,
    activeOrder,
    activeVehicle,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps)(
  withTranslation(["positionSelector", "common"])(ServicePositionSelector)
)
