import React from "react"
import Button from "../Button.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import { showChatWidget } from "../../helpers/userHelper.js"

const { BaseModalDialog } = elements

class CancelMembershipModal extends React.Component {
  render() {
    const { isOpen, closeModal, t } = this.props

    return (
      <BaseModalDialog isOpen={isOpen} toggleModal={closeModal} headerTitle={t("header")}>
        <div style={{ margin: "15px 0px 5px" }}>{t("descriptionText1")}</div>
        <div style={{ margin: "5px" }}>{t("descriptionText2")}</div>
        <div style={{ margin: "5px" }}>
          <a href="mailto:support@caradvise.com" style={{ textDecoration: "underline" }}>
            {t("descriptionText3")}
          </a>
          <span> or call </span>
          <a href="tel:844-923-8473">{t("descriptionText4")}</a>
        </div>
        <Button
          onClick={showChatWidget}
          colorStyle="orange"
          text={t("chatWithCarAdvisorBtn")}
          style={{ paddingTop: 5, paddingBottom: 5, marginTop: 15, marginBottom: 15 }}
        />
      </BaseModalDialog>
    )
  }
}

export default withTranslation("cancelMembershipModal")(CancelMembershipModal)
