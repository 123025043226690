import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { Icon } from "semantic-ui-react"
import { MD_HD_VEHICLE_TYPE_ID } from "../../constants/application"
import { getActiveVehicle } from "../../helpers/vehicleHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"

class DisplayMessage extends Component {
  render() {
    const { message, vehicleTypeId } = this.props
    return (
      <div>
        {vehicleTypeId && vehicleTypeId === MD_HD_VEHICLE_TYPE_ID && (
          <div className="display-message-MDHD">
            <FontAwesomeIcon size="2x" icon={faExclamation} style={{ color: "#1354FA" }} />
            <p>{message}</p>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation(["selectShop", "common"])(DisplayMessage)
