import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import MinimalSidebar from "./MinimalSidebar"
import MembershipCard from "./MembershipCard"
import SignUpContainerMobile from "../components/SignUp/SignUpContainerMobile"
import { createMembership, getMembershipPlans } from "../actions/membership"
import { updateInfo } from "../actions/user"
import {
  nextMembershipUpgrades,
  plansSortedByNameArray,
  usesMembership
} from "../helpers/membershipHelpers"
import { withTranslation } from "react-i18next"
import LanguageSelectModal from "../components/Modal/LanguageSelectModal"
import { isUberCanada } from "../helpers/affiliationHelpers"
import { routerPaths } from "../constants/paths"
import SplitClient, {
  HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION,
  OFF
} from "../components/Config/SplitClient"
import { getSubdomain } from "../components/Config/StylesheetInjector"
import { APP_NAME } from "../../shared_component/constants/common"
import { loadAdRollScript } from "../constants/helpers/adrollHelper"

class MembershipSelect extends Component {
  state = {
    languageSelectModalOpen: true
  }

  closeLanguageSelectModal = () => {
    this.setState({ languageSelectModalOpen: false })
  }

  async componentDidMount() {
    const { updateInfo } = this.props

    this.props.getMembershipPlans()

    await updateInfo({ send_through_membership_flow: false })

    const isSubscriptionFeatureFlag = await SplitClient.getTreatments(
      [HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION],
      this.props.user,
      { app_name: APP_NAME }
    )

    await this.setState({
      isSubscriptionFeatureFlag:
        isSubscriptionFeatureFlag[HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION]
    })
    loadAdRollScript()
  }

  renderMembershipCardPlans = () => {
    const sortedPlans = plansSortedByNameArray(this.props.availableMembershipPlans)
    return sortedPlans.map((plan) => {
      return (
        <MembershipCard
          plan={plan}
          activeMembership={this.props.activeMembership}
          user={this.props.user}
          isUserInOnboardingFlow={
            this.props && this.props.location && this.props.location.isUserInOnboardingFlow
          }
        />
      )
    })
  }

  render() {
    const plans = this.props.availableMembershipPlans
    const { user, t, location } = this.props
    const next = nextMembershipUpgrades(this.props.user, plans)
    const currentPlan = this.props.user.membership

    return (
      <div>
        {(user && !usesMembership(user)) ||
          (this.state.isSubscriptionFeatureFlag && this.state.isSubscriptionFeatureFlag === OFF && (
            <Redirect to={{ pathname: routerPaths.dashboard, search: location.search }} />
          ))}
        {isUberCanada() && (
          <LanguageSelectModal
            user={user}
            isOpen={this.state.languageSelectModalOpen}
            closeModal={this.closeLanguageSelectModal}
          />
        )}
        <SignUpContainerMobile>
          <MinimalSidebar />
          {next && (
            <div className="selectMembershipMainContainer">
              <div className="selectMembershipTitleContainer">
                <div className="signupStepTitle">{t("selectYourPlanHeader")}</div>
              </div>

              <div className="selectMembershipCardsContainer">
                <ul class="multi-membership-cards-container">{this.renderMembershipCardPlans()}</ul>
              </div>
            </div>
          )}
        </SignUpContainerMobile>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let { user, membershipData, paymentMethods } = state

  return {
    paymentMethods: paymentMethods.braintreePaymentMethods,
    user: user || {},
    availableMembershipPlans: membershipData.availableMembershipPlans || [],
    activeMembership: user.membership || {}
  }
}

export default connect(mapStateToProps, { createMembership, getMembershipPlans, updateInfo })(
  withTranslation("membershipSelect")(MembershipSelect)
)
