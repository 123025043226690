import React from "react"
import { Popup } from "semantic-ui-react"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

export default function ({ numEvents, style = {} }) {
  const { t } = useTranslation("roadsideTooltip")
  return (
    <Popup
      style={{ padding: 0, zIndex: 99999 }}
      on="click"
      position="bottom left"
      trigger={
        <span style={{ paddingLeft: "5px", cursor: "pointer", ...style }}>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </span>
      }
      content={
        <div style={{ padding: "20px" }}>
          <strong className="caradvise-electric-blue">{t("includesLbl")}</strong>
          <ul style={{ textAlign: "left" }}>
            <li>{t("10MilesFreeTow")}</li>
            <li>{t("changeFlatTier")}</li>
            <li>{t("jumpStart")}</li>
            <li>{t("lockOut")}</li>
            <li>{t("fuelDelivery")}</li>
            <li>{t("winching")}</li>
            <li>{t("eventsPerYear", { numEvents: numEvents })}</li>
            <li>{t("beginsAfterSignup")}</li>
          </ul>
        </div>
      }
    />
  )
}
