import React, { Component } from "react"
import { withTranslation } from "react-i18next"

class Message extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { t } = this.props
    return (
      <div className="dashboard_message">
        <p className="dashboard_message_left">{t("frequentlyBooked")}</p>
        <p className="see_all">{t("seeAll")}</p>
      </div>
    )
  }
}

export default withTranslation("dashboardMessage")(Message)
