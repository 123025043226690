import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIdCard, faTachometerAlt } from "@fortawesome/free-solid-svg-icons"
import ReactSVG from "react-svg"

export default function Badge({
  count,
  icon,
  size = "sm",
  className = "fa-stack badge mySize",
  customImage
}) {
  return (
    <span className={className} data-count={count}>
      {customImage && customImage.isImage ? (
        <ReactSVG
          className="badge mySize2"
          src={icon}
          svgStyle={{
            width: customImage.width,
            height: customImage.height
          }}
          style={customImage && customImage.style}
        />
      ) : (
        <FontAwesomeIcon className="badge mySize2" icon={icon} size={size} />
      )}
    </span>
  )
}

Badge.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      prefix: PropTypes.string.isRequired,
      iconName: PropTypes.string.isRequired
    }),

    PropTypes.string
  ]).isRequired
}
