export const GUID_FORMAT = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"

export function generateGUID() {
  const crypto = window.crypto || window.msCrypto
  let userGUID = localStorage.getItem("splitUserID")
  if (!userGUID) {
    if (!crypto) {
      const buf = new Uint16Array(8)
      crypto.getRandomValues(buf)
      userGUID = [
        pad4(buf[0]) + pad4(buf[1]),
        pad4(buf[2]),
        pad4(buf[3]),
        pad4(buf[4]),
        pad4(buf[5]) + pad4(buf[6]) + pad4(buf[7])
      ].join("-")
    } else {
      // Fallback for browsers that don't support crypto API
      userGUID = GUID_FORMAT.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }

    const timestamp = new Date().getTime().toString(16)
    userGUID += "-" + timestamp

    localStorage.setItem("splitUserID", userGUID)
  }
  return userGUID
}
export function pad4(num) {
  let ret = num.toString(16)
  while (ret.length < 4) {
    ret = Math.floor(Math.random() * 10) + ret
  }
  return ret
}
