import React from "react"

export const TimeShimmer = ({ count }) => {
  return (
    <div className="time-slots-shimmer">
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <div className="shimmer">
            <div className="stroke">
              <div className="animate title" />
            </div>
          </div>
        ))}
    </div>
  )
}
