import React, { PureComponent } from "react"
// import { Membership } from "caradvise_shared_components"
import { hideChrome, showChrome } from "../../actions/framework"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import basic from "../../../images/BasicBadge.svg"
import elite from "../../../images/Elitebadge.svg"
import premium from "../../../images/Premiumbadge.svg"
import logo from "../../../images/BasicPremiumLogo.svg"
import logoOthers from "../../../images/EliteLogo.svg"
import callWhite from "../../../images/membershippanel/phone-white.svg"
import callBlack from "../../../images/membershippanel/phone-black.svg"
import snazzyimgOther from "../../../images/membershippanel/snazzy-elite1.png"
import snazzyimgBasic from "../../../images/membershippanel/snazzy-basic1.png"
import vehicleBasic from "../../../images/membershippanel/car-basic.svg"
import vehiclePremium from "../../../images/membershippanel/car-premium.svg"
import vehicleElite from "../../../images/membershippanel/car-elite.svg"
import { Image } from "semantic-ui-react"
import moment from "moment"

class MembershipCardFrontFormat extends PureComponent {
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "")

    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3]
    }

    return null
  }
  render() {
    const { t, user, plan, bg, clr } = this.props
    const logoDetails = plan === t("elite") ? logoOthers : logo
    const badgeImage = plan === t("basic") ? basic : plan === t("premium") ? premium : elite
    const snazzyImg = plan === t("elite") ? snazzyimgOther : snazzyimgBasic
    const createdSince = user && user.membership && user.membership.enrollment_date
    const username = user && user.firstName + " " + user.lastName
    const customerId = user && user.membership && user.membership.id
    const memberSince = moment(createdSince).format("MMMM YY")
    const bubblebg = plan === t("basic") ? "#ecf0f6" : plan === t("premium") ? "#4377fb" : "#272728"
    const vehicle =
      plan === t("basic") ? vehicleBasic : plan === t("premium") ? vehiclePremium : vehicleElite
    return (
      <div className="front-card" style={{ backgroundColor: bg, paddingBottom: "2%" }}>
        <div className="front-card-main-container">
          <div className="front-card-top-container">
            <div className="front-card-top-left-container">
              {(customerId || createdSince) && (
                <span className="front-membership" style={{ color: clr }}>
                  {t("membershipId")}
                </span>
              )}
              {customerId && (
                <span className="front-id" style={{ color: clr }}>
                  {`${t("id")}:${customerId}`}
                </span>
              )}
              {memberSince && createdSince && (
                <span
                  className="front-since"
                  style={{
                    color:
                      plan == t("basic") ? "#6F6F6F" : plan === t("premium") ? "#1D1D1D" : "#FFFFFF"
                  }}
                >{`${t("since")} ${memberSince}`}</span>
              )}
            </div>
            <div style={{ marginRight: "5%" }}>
              <Image
                className="front-logo"
                style={{
                  scale: plan == t("basic") || t("elite") ? "2" : "1.8",
                  marginLeft: plan == t("basic") ? "-5%" : "0%"
                }}
                src={logoDetails}
              ></Image>
            </div>
          </div>
        </div>

        <div
          className="front-card-bottom-container"
          style={{
            backgroundImage: `url(${snazzyImg})`
          }}
        >
          <div className="front-card-bottom-inner-container">
            <div className="front-card-member-div">
              <span
                className="front-member-vehicle-plan"
                style={{ color: plan == "BASIC" ? "#444444" : clr }}
              >
                {t("member")}
              </span>

              <span className="front-name" style={{ color: clr, paddingTop: "5%" }}>
                {username}
              </span>

              <div className="front-card-phone-div">
                <img className="front-phone" src={plan == t("elite") ? callWhite : callBlack}></img>

                <span className="front-number" style={{ color: plan == "BASIC" ? "#2f2f2f" : clr }}>
                  {user && user.cellPhone ? this.formatPhoneNumber(user.cellPhone) : ""}
                </span>
              </div>
            </div>
            <div className="front-card-member-div">
              <span
                className="front-member-vehicle-plan"
                style={{ color: plan == t("basic") ? "#444444" : clr }}
              >
                {user && user.vehicles && user.vehicles.length <= 1 ? t("vehicle") : t("vehicles")}
              </span>
              <div
                className="front-member-vehicle-count"
                style={{
                  marginTop: "10%",
                  paddingTop: "10px",
                  paddingBottom: "8px",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                  borderColor:
                    plan == t("premium") ? "#6F6F6F" : plan == t("elite") ? "#2C2C2C" : "#EFEFEF",
                  backgroundColor:
                    plan == t("premium") ? "#6F6F6F" : plan == t("elite") ? "#2C2C2C" : "#FFFFFF"
                }}
              >
                <img className="front-member-vehicle-image" src={vehicle}></img>
                <span
                  className="front-member-vehiclecount"
                  style={{ color: plan == t("basic") ? "#353535" : "#FFFFFF" }}
                >
                  {user && user.vehicles && user.vehicles.length}
                </span>
              </div>
            </div>
            <div className="front-card-member-div">
              <span
                className="front-member-vehicle-plan"
                style={{ color: plan == t("basic") ? "#444444" : clr }}
              >
                {plan}
              </span>
              <img
                className={
                  plan == t("premium") ? "front-card-badge premium-print" : "front-card-badge"
                }
                style={{ marginTop: plan == t("premium") ? "0%" : "5%" }}
                src={badgeImage}
              ></img>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const user = state.user || {}
  const vehicles = user.vehicles || []
  const vehicle = vehicles.find((v) => v.id === user.activeVehicleId) || vehicles[0]
  const name = [user.firstName, user.lastName].filter((n) => n != null).join(" ")

  return { name, vehicle, user }
}

export default connect(mapStateToProps, { hideChrome, showChrome })(
  withTranslation("membershipCardFrontFormat")(MembershipCardFrontFormat)
)
