import React from "react"
import ModalDialog from "./ModalDialog"
import { GAEvent, GAEventCategories } from "../tracking/GAEvent"
import { Trans, useTranslation } from "react-i18next"
import ReactSVG from "react-svg"

const PricingPromiseBanner = () => {
  return <ReactSVG src={"./images/pricing-promise-banner.svg"} svgStyle={{ width: "100%" }} />
}

export default function PricingPromise({ displayContent = <PricingPromiseBanner /> }) {
  const { t } = useTranslation("pricingPromise")

  return (
    <div style={{ backgroundColor: "white" }}>
      <ModalDialog
        afterOpenModal={() =>
          GAEvent(GAEventCategories.CART, "learn-more-guarantee", "Guaranteed Price Learn More")
        }
        customElement={displayContent}
        headerTitle={
          <ReactSVG src={"./images/CA_CheckIcon.svg"} svgStyle={{ width: 30, height: 30 }} />
        }
      >
        <>
          <h3 className="caradvise-electric-blue">{t("pricing-promise-header1")}</h3>
          <Trans>{t("pricing-promise-description1")}</Trans>

          <h3 className="caradvise-electric-blue">{t("pricing-promise-header2")}</h3>
          <Trans>{t("pricing-promise-description2")}</Trans>

          <h3 className="caradvise-electric-blue">{t("pricing-promise-header3")}</h3>
          <Trans>{t("pricing-promise-description3")}</Trans>
        </>
      </ModalDialog>
    </div>
  )
}
