import React from "react"

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

const ActiveOrderComponent = (props) => {
  const { order } = props

  return (
    <div>
      <span>order</span>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  let order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)

  return {
    user,
    activeVehicle,
    order
  }
}

export default connect(mapStateToProps)(
  withTranslation("dashboardMapComponent")(ActiveOrderComponent)
)
