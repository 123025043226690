export const DRIVING_LICENSE = "Driver License"
export const DRIVING_LICENSE_TYPE = "Drivers License"
export const VEHICLE_INSURANCE = "Vehicle Insurance"
export const REGISTRATION = "Registration"
export const EXTRA_DOCUMENTS = "Extra Documents"
export const EXTRA_DOCUMENT = "Extra Document"
export const EXTRA_DOCUMENTS_TYPE = "Other"
export const REPLACE = "replace"
export const CREATED = "Created"
export const UPDATED = "Updated"
export const DELETED = "Deleted"
export const CREATE_FAILED = "CreateFailed"
export const UPDATE_FAILED = "UpdateFailed"
export const DELETE_FAILED = "DeleteFailed"
export const OBJECT = "object"
export const UPDATED_DATE_FORMAT = "MM/dd/yyyy"
export const SELECT = "select"
