export const WALLET_TYPE = Object.freeze({
  APPLE: "apple",
  GOOGLE: "google"
})

export const WALK_IN_ACTIVE = "walk_in_active"
export const WALK_IN_TIME_STRING = "3:00 AM"
export const APP_NAME = "retail"

export const DEVICE_TYPE = Object.freeze({
  IOS: "ios",
  ANDROID: "android",
  WINDOWS: "windows",
  UNKNOWN: "unknown"
})

export const OPTIONS = Object.freeze({
  EMAIL: "email",
  TEXT: "cellPhone",
  PHONE: "phone"
})

export const KEYWORDS = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  FAILURE: "failure",
  DESC: "descending"
})

export const BLACKOUT_DATES = [
  { month: 0, day: 1 }, // January 1st
  { month: 11, day: 25 } // December 25th
]

export const BLOCK_DATES = {
  startDate: "Dec 25 2023",
  endDate: "Jan 1 2024",
  day: "day"
}

export const REFERENCE_ID = Object.freeze({
  NEW_TIRES: "new_tires"
})

export const SCREEN_VIEW = "screen_view"
export const PURCHASE = "purchase"
export const LOADING = "Loading"
export const ANCHOR_BLANK = "_blank"

export const SCHEDULE_PAGE = "schedulePage"
export const PAYMENT_PAGE = "paymentPage"
export const REVIEW_PAGE = "reviewPage"

export const VIN_PLATE_REGEX = /^[a-zA-Z0-9]+$/
export const MILEAGE_REGEX = /[0-9]/

export const VIN = "vin"
export const MILES = "miles"
export const PLATE_NUMBER = "plateNumber"
