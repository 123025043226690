import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { updateInfo, signIn } from "./actions/user"
import Notifications from "./components/Notifications"
import store from "../shared_component/utils/configureStore"
import Button from "./components/Button.js"
import zipcodes from "zipcodes"
import { Divider, Select } from "semantic-ui-react"
import { GAEvent, GAEventCategories } from "./tracking/GAEvent"
import MiniLoader from "./components/MiniLoader"
import { withTranslation } from "react-i18next"
import { isCanada } from "./helpers/translationHelpers"
import { trackEvent, identifyEvent } from "../shared_component/utils/segmentAnalytics"
import { COUNTRY_CODE, EMAIL_REGEX } from "../shared_component/utils/stringHelpers"

class Settings extends Component {
  constructor(props) {
    super(props)

    this._onFieldChange = this._onFieldChange.bind(this)
    this._setAndValidateField = this._setAndValidateField.bind(this)
    this.setupState(props)
  }

  // TODO: replace this deprecated method
  componentWillMount() {
    if (this.props.isLoggedOut) {
      this.setState({ redirect: true })
    }
  }

  setupState = ({ firstName, lastName, cellPhone, zip, email, language }) => {
    let state = {
      fields: {
        firstName: {
          name: "First Name",
          value: firstName,
          invalid: false,
          validators: ["_isPresent"]
        },
        lastName: {
          name: "Last Name",
          value: lastName,
          invalid: false,
          validators: ["_isPresent"]
        },
        cellPhone: {
          name: "Mobile Phone",
          value: cellPhone,
          invalid: false,
          validators: ["_isPresent", "_isValidCellPhone"]
        },
        email: {
          name: "Email",
          value: email,
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        zip: {
          name: "Zip Code",
          value: zip,
          invalid: false,
          validators: ["_isPresent"]
        },
        language: { name: "Language", value: language, invalid: false, validators: ["_isPresent"] }
      },
      passwords: {
        newPassword: {
          name: "New Password",
          value: null,
          invalid: false,
          validators: ["_isPresent"]
        },
        confirmNewPassword: {
          name: "Confirm Password",
          value: null,
          invalid: false,
          validators: ["_isPasswordMatched"]
        }
      },
      redirect: false
    }

    if (this.state) this.setState(state)
    else this.state = state
  }

  _onPasswordChange(key, value) {
    this.setState({
      passwords: {
        ...this.state.passwords,
        [key]: this._setAndValidatePassword(key, value)
      }
    })
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  _setAndValidatePassword = (key, value) => {
    let password = this.state.passwords[key]
    let validators = password.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...password, value, invalid }
  }

  _validatePasswordsMatch = () => {
    const { passwords } = this.state
    const newPassword = passwords.newPassword.value
    const confirmNewPassword = passwords.confirmNewPassword.value
    const { t } = this.props

    if (newPassword !== confirmNewPassword) {
      this.showError(t("passwordNotMatchedMessage"))
      return false
    }

    return true
  }

  _validatePassword = (callback) => {
    if (!this._validatePasswordsMatch()) return

    let passwords = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.passwords).forEach((key) => {
      let password = this.state.passwords[key]
      passwords[key] = password = this._setAndValidatePassword(key, password.value)
      if (!firstInvalidKey && password.invalid) firstInvalidKey = key
    })
    this.setState({ passwords }, () => {
      if (firstInvalidKey) this.showError(`${t("errorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else {
        callback()
      }
    })
  }

  _isPasswordMatched = (value) => {
    return value === this.state.passwords.newPassword.value
  }

  _isValidCellPhone = (value) => {
    return value.length == 10 ? true : false
  }

  _isValidZip = (value) => {
    if (value.length !== 5) {
      return false
    } else {
      const result = zipcodes.lookup(value)
      return result !== undefined
    }
  }
  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  updatePassword = async () => {
    this._validatePassword(async () => {
      const response = await this.props.updateInfo({
        password: this.state.passwords.newPassword.value
      })
      if (response) this.showAlert(this.props.t("passwordUpdatedSuccess"))
    })
  }

  // TODO: extract this to its own component
  renderUpdatePasswordForm = () => {
    const { loading } = this.props
    const { t } = this.props

    return (
      <div style={{ marginLeft: "-8px" }}>
        <div className="accountUpdatePasswordSection">
          <span className="accountUpdatePasswordHeader">{t("updatePasswordLbl")}</span>
          <Divider className="accountUpdatePasswordDivider" />
          <form>
            <label>
              <span className="accountSettingsSection">{t("newPasswordLbl")}</span>
              <br />
            </label>
            <input
              data-qa="settings-password-input-field"
              name="newPassword"
              type="password"
              className="inputFld inputFld-settings"
              ref="password"
              placeholder={t("newPasswordPlaceholder")}
              autoComplete="new-password"
              onChange={(value) => this._onPasswordChange("newPassword", value.target.value)}
            />
            <br />
            <label>
              <span className="accountSettingsSection">{t("newConfirmPasswordLbl")}</span>
              <br />
            </label>
            <input
              data-qa="settings-confirm-password-input-field"
              name="confirmNewPassword"
              type="password"
              className="inputFld inputFld-settings"
              ref="password"
              placeholder={t("newConfirmPasswordPlaceholder")}
              autoComplete="new-password"
              onChange={(value) => this._onPasswordChange("confirmNewPassword", value.target.value)}
            />
            <br />
          </form>
          <Button
            className="classic-ca-button account-button"
            colorStyle="orange"
            text={loading ? <MiniLoader /> : t("updateBtn")}
            style={{ margin: 0, height: "15px", width: "160px" }}
            onClick={() => this.updatePassword()}
            disabled={loading}
          />
        </div>
      </div>
    )
  }

  _onFieldChange = (key, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  // NOTE: This only allows numeric keys to be entered. This is used over setting input type to "number", because browsers may style number fields differently.
  _onNumberChange = (key, value) => {
    const numericRegex = /^\d*$/

    const isOnlyNumeric = numericRegex.test(value)

    if (isOnlyNumeric) {
      this.setState({
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      })
    }
  }

  _setAndValidateField = (key, value) => {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _isPresent = (value) => {
    return !!value.trim()
  }

  _validateFields = (callback) => {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey) this.showError(`${t("errorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  render() {
    if (!this.state.fields) return null
    const fields = this.state.fields || {}
    const { loading, t, user } = this.props
    return (
      <div className="accountSettingsContainer">
        {this.props.renderPersonalInfo && (
          <Fragment>
            <div>
              <label>
                <span className={`accountSettingsSection ${this.props.className}`}>
                  {t("firstNameLbl")}
                </span>
                <br />
                <input
                  data-qa="settings-first-name-input-field"
                  className="inputFld inputFld-settings"
                  onChange={(value) => this._onFieldChange("firstName", value.target.value)}
                  value={fields.firstName.value}
                  name="firstName"
                  type="text"
                />
                <br />
              </label>
            </div>
            <div>
              <label>
                <span className={`accountSettingsSection ${this.props.className}`}>
                  {t("lastNameLbl")}
                </span>
                <br />
                <input
                  data-qa="settings-last-name-input-field"
                  className="inputFld inputFld-settings"
                  value={fields.lastName.value}
                  name="lastName"
                  type="text"
                  onChange={(value) => this._onFieldChange("lastName", value.target.value)}
                />
                <br />
              </label>
            </div>
            <div>
              <label>
                <span className={`accountSettingsSection ${this.props.className}`}>
                  {t("mobilePhoneLbl")}
                </span>
                <br />
                <input
                  data-qa="settings-cell-phone-input-field"
                  className="inputFld inputFld-settings"
                  placeholder={this.props.cellPhone}
                  value={fields.cellPhone.value}
                  maxLength={10}
                  name="cellPhone"
                  type="text"
                  onChange={(value) => this._onNumberChange("cellPhone", value.target.value)}
                />
                <br />
              </label>
            </div>
            <div>
              <label>
                <span className={`accountSettingsSection ${this.props.className}`}>
                  {t("emailLbl")}
                </span>
                <br />
                <input
                  data-qa="settings-email-input-field"
                  className="inputFld inputFld-settings"
                  value={fields.email.value}
                  name="email"
                  type="text"
                  onChange={(value) => this._onFieldChange("email", value.target.value)}
                  readOnly={true}
                  disabled={true}
                />
                <br />
              </label>
            </div>
            <div>
              <label>
                <span className={`accountSettingsSection ${this.props.className}`}>
                  {t("zipCodeLbl")}
                </span>
                <br />
                <input
                  data-qa="settings-zip-input-field"
                  className="inputFld inputFld-settings"
                  value={fields.zip.value}
                  maxLength={7}
                  name="zip"
                  type="text"
                  onChange={(value) => this._onFieldChange("zip", value.target.value)}
                />
                <br />
              </label>
            </div>
            <div>
              {isCanada(user.country) && (
                <label>
                  <span className="accountSettingsSection">{t("languageLbl")}</span>
                  <br />
                  <Select
                    data-qa="settings-language-input-field"
                    options={[
                      { text: t("englishLabel"), value: "en", key: "language-type-en" },
                      { text: t("frenchLabel"), value: "fr", key: "language-type-fr" }
                    ]}
                    onChange={(e, selection) => this._onFieldChange("language", selection.value)}
                    value={fields.language.value}
                  />
                  <br />
                  <br />
                </label>
              )}
              <Button
                className="account-button"
                text={loading ? <MiniLoader /> : t("saveChangesBtn")}
                colorStyle="orange"
                disabled={loading}
                style={{ margin: 0, height: "15px", width: "160px", fontWeight: "10px" }}
                onClick={() => {
                  this._validateFields(async () => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "save-personal-info",
                      "Saved Personal Info"
                    )
                    const response = await this.props.updateInfo({
                      firstName: fields.firstName.value,
                      lastName: fields.lastName.value,
                      cellPhone: fields.cellPhone.value,
                      zip: fields.zip.value,
                      email: fields.email.value,
                      language: fields.language.value,
                      displayErrorNotification: true
                    })
                    if (response === true) {
                      await identifyEvent({
                        traits: {
                          zip: fields.zip.value,
                          phone: COUNTRY_CODE.concat(fields.cellPhone.value),
                          firstNameWithSpace: fields.firstName.value,
                          lastNameWithSpace: fields.lastName.value,
                          email: fields.email.value
                        }
                      })
                      trackEvent("profile-updated", {
                        firstNameWithSpace: fields.firstName.value,
                        lastNameWithSpace: fields.lastName.value,
                        phone: COUNTRY_CODE.concat(fields.cellPhone.value),
                        email: fields.email.value,
                        zip: fields.zip.value
                      })
                      this.showAlert(t("infoUpdatedAlertMessage"))
                    }
                  })
                }}
              />
            </div>
          </Fragment>
        )}
        {this.props.renderUpdatePassword && this.renderUpdatePasswordForm()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let vehicle = undefined
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  vehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    loading: user.loading,
    isLoggedOut: !user.authentication_token,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    cellPhone: user.cellPhone || "",
    error: user.error,
    vehicleNumber: vehicle.vehicleNumber,
    zip: user.zip || "",
    language: user.language || "en",
    email: user.generic ? "" : user.email
  }
}

export default connect(mapStateToProps, { updateInfo, signIn })(
  withTranslation("settings")(Settings)
)
