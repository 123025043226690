import React from "react"
import ReactSVG from "react-svg"
import InfoLogo from "../../../images/rewards/info.svg"
import { withTranslation } from "react-i18next"

const SponserPromotionCard = ({ sponsers, t }) => {
  return (
    <div className="sponser-card">
      <div className="sponser-card-box">
        <p className="sponser-miles">{sponsers.miles}</p>
        <ReactSVG src={sponsers.sponserLogo} className="sponser-logo" />
        <p className="sponser-service">{sponsers.bookServices}</p>
        <div className="sponser-expirey">
          <ReactSVG src={InfoLogo} className="sponser-info" />
          <p>{`${t("expires")} ${sponsers.expiredAt}`}</p>
        </div>
      </div>
    </div>
  )
}

export default withTranslation("rewardsIndex")(SponserPromotionCard)
