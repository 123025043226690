import {
  IDENTIFY_AND_SET_USER,
  USER_LOADING,
  USER_ERROR,
  REMOVE_USER,
  SET_ACTIVE_VEHICLE,
  NEW_USER_STATUS,
  USER_NOT_LOADING,
  LOGOUT_USER,
  UPDATE_ACTIVE_ORDER,
  UPDATE_ACTIVE_CARTS,
  LOAD_USER_STATES,
  UPDATE_USER_PREFERENCES
} from "../actions/user"
import { NEW_ORDER_CREATED, ORDER_APPOINTMENT_UPDATED, ORDER_CANCELED } from "../actions/orders"
import { USER_MEMBERSHIP_CREATED, USER_MEMBERSHIP_UPDATED } from "../actions/membership"
import { CUSTOMERS_PROMOTION_CREATED, CUSTOMERS_PROMOTION_APPLIED } from "../actions/promotion"
import { SHOP_ORDER_RATING_COMPLETED } from "../actions/rating"
import { constants } from "caradvise_shared_components"
import { convertCurrencyToNumber } from "../../shared_component/constants/rewards"
const { PAID, PAID_AT_SHOP, IN_MANUAL_REVIEW } = constants.orders

export default function user(state = {}, action = {}) {
  let result = { ...state, error: null }
  let activeCarts, newActiveCart

  if (!result.loadingCounter) result.loadingCounter = 0

  switch (action.type) {
    case "persist/PERSIST":
      result = { ...result, loadingCounter: result.loadingCounter + 1 }
      break
    case "persist/REHYDRATE":
      if (action.payload && action.payload.user)
        result = { ...result, ...action.payload.user, loadingCounter: result.loadingCounter - 1 }
      else result = { ...result, loadingCounter: result.loadingCounter - 1 }
      break

    case IDENTIFY_AND_SET_USER:
      const { user } = action
      // const user = result || {}
      // merge in vehicle data
      let newVehicles = []
      if ((result.vehicles || []).length === 0) {
        newVehicles = user.vehicles || []
      } else {
        // Not 100% sure why all this is needed- was it the redux-persist library?
        // TODO: get rid of this complexity
        newVehicles = user.vehicles.map((currentVehicle) => {
          const incomingVehicle =
            (user.vehicles || []).find((v) => v.id === currentVehicle.id) || {}
          let services = currentVehicle.vehicle_services
          if ((services || []).length === 0) services = incomingVehicle.vehicle_services || []
          services = services.map((service) => {
            const incomingService = (incomingVehicle.vehicle_services || []).find(
              (vs) => vs.id === service.id
            )
            return incomingService ? { ...service, ...incomingService } : service
          })

          return { ...currentVehicle, ...incomingVehicle, vehicle_services: services }
        })
      }

      // In adding a vehicle, I came across a scenario where a vehicle was duplicated in the store,
      // couldn't reproduce.
      // Until we possibly get rid of redux-persist, a page refresh doesn't solve this problem.
      // For now, manually make sure we have a distinct set of vehicles.
      const uniqueVehicleIds = [...new Set(newVehicles.map((v) => v.id))]
      newVehicles = uniqueVehicleIds.map((id) => newVehicles.find((v) => v.id === id))

      let ratableInfo = user.ratable_shop_order_info
      // The API is sending a single object in `ratable_shop_order_info`, even though the user may
      // have many orders to review. We're handling either case here, so that when we
      // change the API, this code won't break in the in between time of API and UI deployments.
      if (!Array.isArray(ratableInfo)) ratableInfo = [ratableInfo].filter((i) => i != null)

      result = {
        ...result,
        ...user,
        vehicles: newVehicles,
        ratable_shop_order_info: ratableInfo,
        affiliation: { ...result.affiliation, ...user.affiliation },
        active_orders: user.active_orders || result.active_orders || [],
        closed_orders: user.closed_orders || result.closed_orders || [],
        activeVehicleId: user.activeVehicleId || result.activeVehicleId,
        loadingCounter: result.loadingCounter,
        loading: result.loading,
        loadingMessage: result.loadingMessage
      }

      result.loadingCounter--
      break
    case USER_LOADING:
      result.loadingCounter++
      break
    case USER_NOT_LOADING:
      result.loadingCounter--
      break
    case USER_ERROR:
      result = {
        ...result,
        loading: false,
        loadingCounter: 0,
        loadingMessage: null,
        error: action.error
      }
      return result
    case LOGOUT_USER:
      result = {}
      return result
    case REMOVE_USER:
      result = {}
      return result
    case SET_ACTIVE_VEHICLE:
      result = { ...result, activeVehicleId: action.activeVehicleId, error: null }
      break
    case NEW_USER_STATUS:
      result = { ...result, newUserStatus: action.newUserStatus, error: null }
      break
    case NEW_ORDER_CREATED:
      const existingActiveOrders = result.active_orders || []
      const { newOrder } = action.payload

      result = {
        ...result,
        active_orders: [...existingActiveOrders, newOrder]
      }
      break

    case ORDER_APPOINTMENT_UPDATED:
      const updatedOrder = action.payload

      result = {
        ...result,
        active_orders: (result.active_orders || []).map((order) => {
          if (order.id === updatedOrder.id) {
            const {
              appointment_datetime,
              appointment_date_pretty,
              appointment_time_pretty
            } = updatedOrder
            return {
              ...order,
              appointment_datetime,
              appointment_date_pretty,
              appointment_time_pretty
            }
          }

          return order
        })
      }
      break
    case ORDER_CANCELED:
      result = {
        ...result,
        active_orders: (result.active_orders || []).filter((order) => {
          return order.id !== action.payload.orderId
        })
      }
      break
    case UPDATE_ACTIVE_ORDER:
      const activeOrders = result.active_orders
      const activeOrder = activeOrders.find((o) => o.id === action.payload.id)
      if (!activeOrder) {
        return result
      }

      const updatedActiveOrder = action.payload

      if ([PAID, PAID_AT_SHOP, IN_MANUAL_REVIEW].indexOf(updatedActiveOrder.status) === -1) {
        result = {
          ...result,
          active_orders: [
            ...activeOrders.filter((o) => o.id !== activeOrder.id),
            updatedActiveOrder
          ]
        }
      } else {
        result = {
          ...result,
          active_orders: [...activeOrders.filter((o) => o.id !== activeOrder.id)]
        }
      }
      break
    case SHOP_ORDER_RATING_COMPLETED:
      let info = (result.ratable_shop_order_info || []).filter(
        (i) => i.shop_order_id !== action.shopOrderId
      )
      result = { ...result, ratable_shop_order_info: info }
      break
    case USER_MEMBERSHIP_CREATED:
      result = { ...result, membership: action.payload }
      break
    case USER_MEMBERSHIP_UPDATED:
      result = { ...result, membership: { ...result.membership, ...action.payload } }
      break
    case UPDATE_ACTIVE_CARTS:
      const newActiveCarts = action.payload.shopping_carts || {}
      activeCarts = (state.active_carts || []).map((cart) => {
        return newActiveCarts.find((c) => c.id === cart.id) || cart
      })

      result = { ...result, active_carts: activeCarts }
      break
    case CUSTOMERS_PROMOTION_APPLIED:
      newActiveCart = action.payload.shopping_cart || {}
      activeCarts = (state.active_carts || []).map((cart) => {
        return cart.id === newActiveCart.id ? newActiveCart : cart
      })

      result = { ...result, active_carts: activeCarts }
      break
    case CUSTOMERS_PROMOTION_CREATED:
      newActiveCart = action.payload.shopping_cart || {}
      activeCarts = (state.active_carts || []).map((cart) => {
        return cart.id === newActiveCart.id ? newActiveCart : cart
      })

      result = { ...result, active_carts: activeCarts }
      break
    case LOAD_USER_STATES:
      return {
        ...state,
        states: action.payload
      }
      break

    case UPDATE_USER_PREFERENCES:
      return {
        ...result,
        preferences: action.payload
      }

    default:
      result = { ...result, error: null }
  }

  if (result.loadingCounter > 0) {
    result.loading = true
    if (action.loadingMessage) result.loadingMessage = action.loadingMessage
  } else {
    result.loadingCounter = 0
    result.loading = false
    result.loadingMessage = null
  }
  return result
}
