import moment from "moment"
import { DAYS, REWARD_COOKIE } from "../constants/rewards"

export function setCookie(name, value, expirationTimeInDays) {
  const expirationDate = moment().add(expirationTimeInDays, DAYS).toDate()
  const expires = expirationDate.toUTCString()

  document.cookie = `${name}=${value}; ${REWARD_COOKIE.expires}=${expires}; ${REWARD_COOKIE.path}=/`
}

export function isCookieExpired(cookieName) {
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim())

  const cookie = cookies.find((cookie) => cookie.startsWith(cookieName + "="))

  if (cookie) {
    const expirationMatch = cookie.match(REWARD_COOKIE.expireRegex)

    if (expirationMatch && expirationMatch[1]) {
      const expirationDateUTC = expirationMatch[1].trim()
      const expirationDate = new Date(expirationDateUTC)

      if (expirationDate.getTime() > Date.now()) {
        // Cookie is not expired
        return false
      }
    } else {
      // If the "expires" attribute is not present, assume the cookie is not expired
      return false
    }
  }

  return true
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const removeCookie = (cookieName) => {
  document.cookie = `${cookieName}=; ${REWARD_COOKIE.expires}=${REWARD_COOKIE.expiredTime}; ${REWARD_COOKIE.path}=/;`
}
