import React from "react"
import { connect } from "react-redux"
import { loadCustomersPromotions } from "../../actions/promotion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { Grid } from "semantic-ui-react"
import moment from "moment"
import { sortByAttr } from "../../../shared_component/utils/arrayHelpers"
import AvailablePromotionsContent from "./AvailablePromotionsContent"
import { elements } from "caradvise_shared_components"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import { withTranslation } from "react-i18next"
const { BaseModalDialog } = elements

const UsedPromotionsContent = ({ goToAvailablePromos, promos = [], t }) => {
  return (
    <React.Fragment>
      {promos.length === 0 && <div style={{ padding: "100px 0" }}>{t("noUsedPromotionsLbl")}</div>}
      <div style={{ margin: "40px 0" }}>
        {sortByAttr(promos, "used_on", "desc").map((promo, i) => {
          return (
            <Grid.Row columns="equal" key={`used-promotion-${i}`}>
              <Grid.Column
                floated="left"
                textAlign="left"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ textAlign: "left", paddingRight: "10px" }}>{promo.name}</span>
              </Grid.Column>
              <Grid.Column floated="right">
                <span>{moment(promo.used_on).format("LL")}</span>
              </Grid.Column>
            </Grid.Row>
          )
        })}
      </div>

      <span className="cursor-pointer primary-link secondary-font " onClick={goToAvailablePromos}>
        {t("viewAvailablePromotionsLbl")}
      </span>
    </React.Fragment>
  )
}

class PromotionsModal extends React.Component {
  state = {
    showAvailable: true,
    showUsed: false
  }

  async componentDidMount() {
    await this.props.loadCustomersPromotions({ cartId: this.props.cart.id })
  }

  goToUsedPromos = () => {
    GAEvent(GAEventCategories.CART, "view-used-promos", "View Used Promotions")
    this.setState({ showAvailable: false, showUsed: true })
  }

  goToAvailablePromos = () => {
    this.setState({ showAvailable: true, showUsed: false })
  }

  header = () => {
    const { showAvailable } = this.state
    const { t } = this.props
    return (
      <div className={`inline-modal-header`}>
        <span className="inline-modal-header-close">
          {showAvailable ? t("availablePromotionsText") : t("usedPromotionsText")}
        </span>
        <span className="inline-modal-header-close-button" onClick={this.props.closeModal}>
          <FontAwesomeIcon className="ca-gray" icon={faTimes} size="2x" />
        </span>
      </div>
    )
  }

  render() {
    const { isOpen, closeModal, availablePromotions, usedPromotions, t } = this.props
    const { showAvailable, showUsed } = this.state
    const cart = this.props.cart || {}
    const appliedPromotionId = cart.promotions && cart.promotions[0] && cart.promotions[0].id

    return (
      <BaseModalDialog isOpen={isOpen} toggleModal={closeModal} header={this.header()}>
        {showAvailable && (
          <AvailablePromotionsContent
            promos={availablePromotions}
            appliedPromotionId={appliedPromotionId}
            goToUsedPromos={this.goToUsedPromos}
            closeModal={closeModal}
            cartId={cart.id}
          />
        )}
        {showUsed && (
          <UsedPromotionsContent
            promos={usedPromotions}
            goToAvailablePromos={this.goToAvailablePromos}
            t={t}
          />
        )}
      </BaseModalDialog>
    )
  }
}

const mapStateToProps = (state, props) => {
  const promotionData = state.promotionData || {}

  return {
    availablePromotions: promotionData.availablePromotions,
    usedPromotions: promotionData.usedPromotions
  }
}

export default connect(mapStateToProps, { loadCustomersPromotions })(
  withTranslation("promotionsModal")(PromotionsModal)
)
