import React from "react"
import { some } from "lodash"
import { numberFormatToCurrency } from "../../shared_component/utils/currency"
import { getNewTiresServiceFromServices } from "./orderServiceHelpers"
import {
  FIRESTONE,
  HIBDON_TIRES_PLUS,
  MICHEL_TIRES_PLUS,
  TIRES_PLUS,
  WHEEL_WORKS
} from "../constants/shops"
import i18n from "../../shared_component/utils/i18n"
import { MD_HD_VEHICLE_TYPE_ID } from "../constants/application"
import { getActiveVehicle } from "./vehicleHelpers"
import { DEVICE_TYPE } from "../../shared_component/constants/common"

export function textifiedPrice({ price, company, t, prepaid = false }) {
  return prepaid
    ? t("prepaidLbl")
    : [null, undefined, ""].includes(price)
    ? noPriceLabel(company, t)
    : numberFormatToCurrency(price)
}

const summedEstimateConsideringNull = (priceEstimates, attr) => {
  if (
    priceEstimates == null ||
    priceEstimates.length === 0 ||
    some(priceEstimates, (pe) => pe[attr] == null)
  )
    return null

  return priceEstimates.reduce((acc, val) => {
    return acc + Number(val[attr])
  }, 0)
}

export function pricingDataForOrder(order = {}, company, t) {
  const priceEstimates = (order.order_services || [])
    .map((os) => os.price_estimate)
    .filter((pe) => pe != null)

  return pricingDataFromPriceEstimates(priceEstimates, company, t)
}

export function pricingDataFromPriceEstimates(priceEstimates, company, t) {
  const shopPriceEstimate = summedEstimateConsideringNull(priceEstimates, "shop_price_estimate")
  const retailPriceEstimate = summedEstimateConsideringNull(priceEstimates, "retail_price_estimate")
  const retailSavings = company.retail_savings
  const shopSavings = savingsFromDiscount(shopPriceEstimate, retailSavings)

  return {
    shopPriceEstimate: shopPriceEstimate,
    shopPriceText: textifiedPrice({ price: shopPriceEstimate, company: company, t: t }),
    shopSavings: shopSavings,
    shopSavingsText: numberFormatToCurrency(shopSavings),
    retailPriceEstimate: retailPriceEstimate,
    retailPriceText: textifiedPrice({ price: retailPriceEstimate, company: company, t: t }),
    hasRetailSavings: Boolean(retailSavings && shopSavings)
  }
}

export function withPricing(shops, t) {
  return (shops || []).map((shop) => {
    const priceEstimates = shop.price_estimates || []
    const company = shop.company || {}

    return {
      ...shop,
      ...pricingDataFromPriceEstimates(priceEstimates, company, t)
    }
  })
}

export function savingsFromDiscount(price, discount) {
  if (!price || !discount) return null

  const decimalSavings = parseFloat(discount) / 100.0
  // This gives the savings that are `discount` percent less than some original price which we back into.
  return price * (decimalSavings / (1 - decimalSavings))
}

export function truncatedName(name, charLength) {
  if (!name) return ""

  return name.length > charLength ? name.substr(0, charLength) + "..." : name
}

export function truncatedNameWithTitle(name, charLength = 50) {
  return <span title={name}>{truncatedName(name, charLength)}</span>
}

export function SchedulingWarningMessage(company) {
  if (!company) return

  const unavailableShops = [
    FIRESTONE,
    HIBDON_TIRES_PLUS,
    MICHEL_TIRES_PLUS,
    TIRES_PLUS,
    WHEEL_WORKS
  ]

  if (unavailableShops.includes(company.reference_id)) {
    return (
      <div>
        <strong>
          <p className="caradvise-red">
            {i18n.t("common:schedulingWarningMessage", { companyName: company.name })}
          </p>
        </strong>
      </div>
    )
  }
}

export function getPricingInfo(
  hasTiresInCart,
  supportsTireSelection,
  noPricingLabel,
  price,
  shop,
  t,
  vehicleTypeId
) {
  if (shop && shop.cdk_dealer) {
    return t("common:seeDealerPriceLbl")
  } else if (vehicleTypeId === MD_HD_VEHICLE_TYPE_ID) {
    return t("common:mdHdpricedAtShopLbl")
  } else if (price) {
    return numberFormatToCurrency(price)
  } else {
    return noPricingLabel
  }
}

export function cartHasStaleTirePricing(cart) {
  const tireService = getNewTiresServiceFromServices(cart.order_services)

  return (
    cart.shop &&
    cart.shop.supports_tire_selection &&
    tireService &&
    !tireService.price_estimate.shop_price_estimate
  )
}

export function noPriceLabel(company = {}, t) {
  const activeVehicle = getActiveVehicle()
  const vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
  if (vehicleTypeId === MD_HD_VEHICLE_TYPE_ID) {
    return t("common:mdHdpricedAtShopLbl")
  }
  return company.pricing_disabled && company.retail_savings
    ? t("common:percentOffRetailLbl", { percent: parseInt(company.retail_savings) })
    : t("common:priceAtShopLbl")
}

export const getShopAddress = (shop = {}) =>
  `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`

export const getShopAddressWithCountry = (shop = {}) => {
  const { address_line1 = "", city = "", state = "", zip = "", country = "" } = shop

  const formattedAddress = `${address_line1} ${city}, ${state} ${zip}, ${
    country && country.toUpperCase()
  }`

  return formattedAddress.trim()
}

export const getShopName = ({ shop, company }) => {
  const shopName = (shop && shop.name) || ""
  const companyName = (company && company.name) || ""
  return companyName === "Independent/Other" || companyName.endsWith("(w/o pricing)")
    ? shopName
    : companyName
}

export function googleMapLink(shop) {
  const { address_line1, city, state, zip } = shop

  const formattedAddress = address_line1.replace(/\s/g, "+")

  return `https://www.google.com/maps/place/${formattedAddress},+${city},+${state}+${zip}`
}

export function appleMapLink(shop) {
  const { lat, lng } = shop
  return `http://maps.apple.com/?daddr=${lat},${lng}`
}

const deviceType = () => {
  if (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent)) {
    return DEVICE_TYPE.IOS
  } else if (/Android/.test(navigator.userAgent)) {
    return DEVICE_TYPE.ANDROID
  } else if (/Win/.test(navigator.userAgent)) {
    return DEVICE_TYPE.WINDOWS
  } else {
    return DEVICE_TYPE.UNKNOWN
  }
}

export const getMapLink = (shop) => {
  const { lat, lng, address_line1, city, state, zip } = shop
  const formattedAddress = address_line1.replace(/\s/g, "+")

  switch (deviceType()) {
    case DEVICE_TYPE.IOS:
      return `http://maps.apple.com/?daddr=${lat},${lng}`

    case DEVICE_TYPE.ANDROID:
      return `https://www.google.com/maps/place/${formattedAddress},+${city},+${state}+${zip}`

    case DEVICE_TYPE.WINDOWS:
      return `https://www.google.com/maps/place/${formattedAddress},+${city},+${state}+${zip}`

    default:
      // Default web map link
      return `https://www.google.com/maps/place/${formattedAddress},+${city},+${state}+${zip}`
  }
}

export const filteredShops = (shops) => {
  const sponsoredShops = shops
    .filter((shop) => {
      return shop.shop_featured == true
    })
    .slice(0, 3)
  const otherShops = shops.filter((shop) => {
    return !sponsoredShops.includes(shop)
  })
  return {
    sponsoredShops: sponsoredShops,
    otherShops: otherShops,
    allShops: [...sponsoredShops, ...otherShops]
  }
}
