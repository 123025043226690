import Notifications from "../components/Notifications"
import { postJSON, getJSON, putJSON, deleteJSON } from "../../shared_component/utils/fetch"
import i18n from "../../shared_component/utils/i18n"
import { setLoading, setNotLoading, setUserError, updateVehicle } from "./user"
import { CURRENTLY_DUE } from "../constants/maintenanceSchedules"
import { fetchServicesPricing } from "../CarAdvise2/actions/maintenance"
import { isEmpty } from "lodash"

const VEHICLE_MAINTENANCE_URL = "/api/v4/vehicles/vehicle_maintenance"
const VEHICLE_INDICATORS_URL = "/api/v4/vehicles/vehicle_indicators"

export const MAINTENANCE_SCHEDULES_LOADING = "MAINTENANCE_SCHEDULES_LOADING"
export function setMaintenanceSchedulesLoading(msg) {
  return { type: MAINTENANCE_SCHEDULES_LOADING, loadingMessage: msg }
}

export const MAINTENANCE_SCHEDULES_NOT_LOADING = "MAINTENANCE_SCHEDULES_NOT_LOADING"
export function setMaintenanceSchedulesNotLoading() {
  return { type: MAINTENANCE_SCHEDULES_NOT_LOADING }
}

export const MAINTENANCE_INDICATORS_LOADING = "MAINTENANCE_INDICATORS_LOADING"
export function setMaintenanceIndicatorsLoading(msg) {
  return { type: MAINTENANCE_INDICATORS_LOADING, loadingMessage: msg }
}

export const MAINTENANCE_INDICATORS_NOT_LOADING = "MAINTENANCE_INDICATORS_NOT_LOADING"
export function setMaintenanceIndicatorsNotLoading() {
  return { type: MAINTENANCE_INDICATORS_NOT_LOADING }
}

export function getMaintenanceSchedules(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const { vehicle } = payload

    dispatch(setMaintenanceSchedulesLoading())

    if (authentication_token && vehicle && vehicle.id) {
      // TODO: update the endpoint to accept a vehicle_id.
      // The UI shouldn't need to know about Motor or that motorBaseVehicleID is needed.
      const request = {
        url: VEHICLE_MAINTENANCE_URL,
        headers: { Authorization: authentication_token },
        body: {
          base_vehicle_id: vehicle.motorBaseVehicleID,
          interval: vehicle.predicted_current_miles
        }
      }
      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        const schedules = response.result
        const services = (schedules.maintenance && schedules.maintenance.services) || []

        dispatch(
          updateVehicle({
            id: vehicle.id,
            maintenanceSchedules: services.filter((service) => !!service.services.length)
          })
        )
      } else {
        dispatch(setUserError(response.error))
      }

      dispatch(setMaintenanceSchedulesNotLoading())
      return response
    }
  }
}

export function getMaintenanceIndicators(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const { vehicle } = payload

    dispatch(setMaintenanceIndicatorsLoading())

    if (authentication_token && vehicle) {
      // TODO: update the endpoint to accept a vehicle_id.
      // The UI shouldn't need to know about Motor or that motorBaseVehicleID is needed.
      const request = {
        url: VEHICLE_INDICATORS_URL,
        headers: { Authorization: authentication_token },
        body: {
          base_vehicle_id: vehicle.motorBaseVehicleID,
          interval: vehicle.predicted_current_miles
        }
      }
      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        const serviceIndicators = (response.result.vehicles || [])
          .filter((service) => !!service.services.length)
          .map((indicator) => {
            // TODO: These objects expect to have ids set on services.  Fixing in this manner was
            // done in `suggestedIndicators.js`.  This needs refactoring.
            return {
              ...indicator,
              services: indicator.services.map((s) => ({ ...s, id: s.id || s.service_id }))
            }
          })

        dispatch(updateVehicle({ id: vehicle.id, maintenanceIndicators: serviceIndicators }))
      } else {
        dispatch(setUserError(response.error))
      }

      dispatch(setMaintenanceIndicatorsNotLoading())
      return response
    }
  }
}

// New Maintenance Schedules Implementation Phase - 3

const VEHICLE_MAINTENANCE_SCHEDULES = "/api/v4/services/recommended"
const INTERVAL_IS_COMPLETED = "/api/v4/interval_based_reminders/"

export const OEM_MAINTENANCE_SCHEDULES_LOADING = "OEM_MAINTENANCE_SCHEDULES_LOADING"
export function setOemMaintenanceSchedulesLoading(msg) {
  return { type: OEM_MAINTENANCE_SCHEDULES_LOADING, loadingMessage: msg }
}

export const OEM_MAINTENANCE_SCHEDULES_NOT_LOADING = "OEM_MAINTENANCE_SCHEDULES_NOT_LOADING"
export function setOemMaintenanceSchedulesNotLoading() {
  return { type: OEM_MAINTENANCE_SCHEDULES_LOADING }
}

export const CA_MAINTENANCE_SCHEDULES_LOADING = "CARADVISE_MAINTENANCE_SCHEDULES_LOADING"
export function setCaMaintenanceSchedulesLoading(msg) {
  return { type: CA_MAINTENANCE_SCHEDULES_LOADING, loadingMessage: msg }
}

export const CA_MAINTENANCE_SCHEDULES_NOT_LOADING = "CARADVISE_MAINTENANCE_SCHEDULES_NOT_LOADING"
export function setCaMaintenanceSchedulesNotLoading() {
  return { type: CA_MAINTENANCE_SCHEDULES_NOT_LOADING }
}

export function pullOemMaintenanceSchedules(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const { vehicle } = payload

    dispatch(setOemMaintenanceSchedulesLoading())

    if (authentication_token && vehicle) {
      // TODO: update the endpoint to accept a vehicle_id.
      // The UI shouldn't need to know about Motor or that motorBaseVehicleID is needed.
      const request = {
        url: `${VEHICLE_MAINTENANCE_SCHEDULES}/oem`,
        headers: { Authorization: authentication_token },
        body: {
          vehicleId: vehicle.id,
          interval: vehicle.miles
        }
      }
      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        const schedules = response.result
        const services = schedules.intervals || []

        dispatch(
          updateVehicle({
            id: vehicle.id,
            oemServices: services.filter((service) => service)
          })
        )
      } else {
        dispatch(setUserError(response.error))
      }

      dispatch(setOemMaintenanceSchedulesNotLoading())
      return response
    }
  }
}

export function pullCaMaintenanceSchedules(payload) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const { vehicle } = payload

    dispatch(setCaMaintenanceSchedulesLoading())

    if (authentication_token && vehicle) {
      // TODO: update the endpoint to accept a vehicle_id.
      // The UI shouldn't need to know about Motor or that motorBaseVehicleID is needed.
      const request = {
        url: `${VEHICLE_MAINTENANCE_SCHEDULES}/caradvise`,
        headers: { Authorization: authentication_token },
        body: {
          vehicleId: vehicle.id,
          interval: vehicle.miles
        }
      }
      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        const schedules = response.result
        const services = (schedules.maintenance && schedules.maintenance.services) || []

        dispatch(
          updateVehicle({
            id: vehicle.id,
            maintenanceSchedules: services.filter((service) => !!service.services.length)
          })
        )
      } else {
        dispatch(setUserError(response.error))
      }

      dispatch(setCaMaintenanceSchedulesNotLoading())
      return response
    }
  }
}

export const UPDATE_INTERVAL_MARK_AS_COMPLETED = "UPDATE_INTERVAL_MARK_AS_COMPLETED"
export function updateIntervalMarkAsCompleted(intervalId, flag, services_definitions) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token, vehicles, activeVehicleId, id } = user
    const activeVehicleIndex = vehicles.findIndex((v) => v.id === activeVehicleId)
    let activeVehicle = vehicles[activeVehicleIndex]
    const putUrl = `${INTERVAL_IS_COMPLETED}${activeVehicleId}`
    const body = {
      is_completed: flag,
      vehicle_id: activeVehicleId,
      interval_id: intervalId,
      customer_id: id,
      service_definition_ids: services_definitions
    }

    let response = await putJSON(putUrl, body, { Authorization: authentication_token })
    if (response.error) {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )

      return true
    } else {
      dispatch(pullOemMaintenanceSchedules({ vehicle: activeVehicle }))
      dispatch(
        Notifications.success({
          title: i18n.t("common:notificationSuccessTitle"),
          message: i18n.t("maintenanceSchedule:successMarkAllCompletedMsg"),
          action: { label: "Ok" }
        })
      )
      return true
    }
  }
}

export function fetchOEMCurrentIntevals(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    const { vehicle } = payload
    let { authentication_token } = user
    if (authentication_token && vehicle && vehicle.id) {
      const request = {
        url: `${VEHICLE_MAINTENANCE_SCHEDULES}/oem`,
        headers: { Authorization: authentication_token },
        body: {
          vehicleId: vehicle.id,
          interval: vehicle.miles
        }
      }

      let response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        const schedules = response.result.intervals
        const currentInterval =
          schedules && schedules.filter((interval) => interval.tag === CURRENTLY_DUE)
        const oemServices =
          (currentInterval &&
            currentInterval.length > 0 &&
            currentInterval[0].services.filter((service) => !service.is_completed)) ||
          []
        const service_ids = oemServices && oemServices.map(({ service_id, ...rest }) => service_id)
        const pricingResponse =
          vehicle.id &&
          !isEmpty(service_ids) &&
          (await dispatch(
            fetchServicesPricing({ vehicleId: vehicle.id, service_ids: service_ids })
          ))
        if (pricingResponse.result) {
          const map = new Map()
          oemServices.forEach((service) => {
            map.set(service.service_id, service)
          })
          pricingResponse.result.forEach((service) => {
            if (map.has(service.id)) {
              const existingService = map.get(service.id)
              Object.assign(existingService, service)
            }
          })
        }
        return currentInterval
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
    }
  }
}
