import { getJSON } from "../../shared_component/utils/fetch"
import {
  RESET_MILES_REWARDS,
  RESET_REWARDS,
  REWARD_SPLIT_FLAG,
  SET_MILE_ON_FIRST_MAINTENANCE,
  SET_REWARDS_DATA,
  SET_REWARDS_EARNED_MILES,
  SET_REWARDS_UNLOCK_MILE,
  SET_SERVICE_REMOVING_FLAG,
  USER_REDEEM_MILE,
  USER_REWARDS
} from "../reducers/rewards"

const REWARDS_BASE_URL = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1`

const GET_REWARDS = `${REWARDS_BASE_URL}/rewards`
const EARNED_MILES = `${REWARDS_BASE_URL}/rewards/earned_miles`
const MILES_TO_UNLOCK = `${REWARDS_BASE_URL}/vehicles`
const APPLY_MILES_AND_DISCOUNT = `${REWARDS_BASE_URL}/rewards/redeem`
const BOOK_FIRST_MAINTENANCE = `${REWARDS_BASE_URL}/rewards`

export const MILES = "miles"
export const TYPE = "type"

export const setUserRewards = () => {
  return { type: USER_REWARDS, payload: {} }
}

export const setRewardFlag = (isRewardsAvailable) => {
  return { type: REWARD_SPLIT_FLAG, payload: isRewardsAvailable }
}

export const applyRedeemMiles = (redeemedMiles) => {
  return {
    type: USER_REDEEM_MILE,
    payload: redeemedMiles
  }
}

export const resetRewardsState = () => {
  return {
    type: RESET_REWARDS,
    payload: {}
  }
}

export const resetMilesRewardsState = () => {
  return {
    type: RESET_MILES_REWARDS,
    payload: {}
  }
}

export const setRewardsData = (data) => {
  return {
    type: SET_REWARDS_DATA,
    payload: data
  }
}

export const setEarnMiles = (data) => {
  return {
    type: SET_REWARDS_EARNED_MILES,
    payload: data
  }
}

export const setUnlockMiles = (data) => {
  return {
    type: SET_REWARDS_UNLOCK_MILE,
    payload: data
  }
}

export const setMilesOnFirstMaintenance = (data) => {
  return {
    type: SET_MILE_ON_FIRST_MAINTENANCE,
    payload: data
  }
}

export const setServiceRemovingFlag = (data) => {
  return {
    type: SET_SERVICE_REMOVING_FLAG,
    payload: data.flag
  }
}

// In next release, I will have to work on reducer and will use data from the redux only

export function getRewards(payload) {
  return async function (dispatch, getState) {
    dispatch(setRewardsData([]))
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let url = GET_REWARDS

      if (payload && payload.rewardType) {
        url = `${url}?${TYPE}=${payload.rewardType}`
      }

      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        dispatch(setRewardsData(response.result))
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      return response
    }
  }
}

export function getEarnedMiles(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      const url = EARNED_MILES

      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        dispatch(setEarnMiles(response.result))
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      return response
    }
  }
}

export function getMilesToUnlock(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      const url = `${MILES_TO_UNLOCK}/${activeVehicleId}/${MILES}`

      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        dispatch(setUnlockMiles(response.result))
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      return response
    }
  }
}

export function getApplyMilesAndDiscount(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      let url = APPLY_MILES_AND_DISCOUNT

      if (payload && payload.miles) {
        url = `${url}?${MILES}=${payload.miles}`
      }

      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      return response
    }
  }
}

export function getBookFirstMaintenanceMiles(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      const url = `${BOOK_FIRST_MAINTENANCE}/${payload.rewardsId}`

      const response = await getJSON(url, {}, request.headers)

      if (response.result) {
        dispatch(setMilesOnFirstMaintenance(response.result))
        return response
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      return response
    }
  }
}
