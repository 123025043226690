import React from "react"
import ReactSVG from "react-svg"

const UnlockMilesSteps = ({ steps, isLastChild }) => {
  return (
    <div className="unlock-miles-step-container">
      <div className="unlock-steps">
        <div className="unlock-logo-box">
          <ReactSVG src={steps.stepLogo} className={`${isLastChild ? "" : "unlock-steps-line"}`} />
        </div>
        <p className="unlock-steps-title">{steps.stepInfo}</p>
      </div>
    </div>
  )
}

export default UnlockMilesSteps
