import React from "react"
import gtmParts from "react-google-tag-manager"
import PropTypes from "prop-types"
import { connect } from "react-redux"

class GoogleTagManager extends React.Component {
  componentDidMount() {
    const dataLayerName = this.props.dataLayerName

    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName,
      additionalEvents: this.props.additionalEvents,
      previewVariables: this.props.previewVariables
    })

    if (!window[dataLayerName]) {
      eval(gtm.scriptAsHTML().replace(/<\/?script>/g, ""))
    }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName,
      additionalEvents: this.props.additionalEvents,
      previewVariables: this.props.previewVariables
    })

    if (window[this.props.dataLayerName]) {
      window[this.props.dataLayerName].push(this.props.user)
    }

    return gtm.noScriptAsReact()
  }
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  previewVariables: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  scriptId: PropTypes.string,
  user: PropTypes.shape({
    affiliationId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    vehicles: PropTypes.arrayOf(
      PropTypes.shape({
        mileage: PropTypes.number,
        vin: PropTypes.string
      })
    ),
    enrollmentDate: PropTypes.string
  })
}

GoogleTagManager.defaultProps = {
  dataLayerName: "dataLayer",
  scriptId: "react-google-tag-manager-gtm",
  additionalEvents: {},
  previewVariables: false
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    gtmId: "GTM-NG97ZFZ",
    user: {
      affiliationId: user.affiliation_id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      vehicles:
        user.vehicles &&
        user.vehicles.map((v) => {
          return { vin: v.vin, mileage: v.miles }
        }),
      enrollmentDate: user.created_at
    }
  }
}

export default connect(mapStateToProps)(GoogleTagManager)
