import React from "react"
import { useTranslation } from "react-i18next"
import CheckIcon from "../../../../../images/dashboard/check-icon.svg"
import { Image, Popup } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import ModalDialog from "../../../../components/ModalDialog"
import ToolTipIcon from "../../../../../images/map/tooltip-white-icon.svg"
import ReactSVG from "react-svg"

function FeaturedLabel({ shop, listView = false, showCheckIcon = true, showTooltip = false }) {
  const { t } = useTranslation("common", "dashboardMapComponent")

  return (
    <>
      {shop && shop.shop_featured && (
        <div
          className={`feature-label-text ${listView ? "feature-label-listview" : "featured-label"}`}
        >
          {showCheckIcon && <Image src={CheckIcon} className="vehicle-popup-checkIcon" />}
          {t("featuredLabel")}
          {showTooltip && (
            <ModalDialog linkText={<ReactSVG src={ToolTipIcon} />} className="primary-button-color">
              {t("dashboardMapComponent:sponsoredShopInfo")}
            </ModalDialog>
          )}
        </div>
      )}
    </>
  )
}

export default FeaturedLabel
