import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Settings from "./settings"
import MembershipSection from "./components/MembershipSection"
import { Divider, Responsive } from "semantic-ui-react"
import PaymentList from "./components/PaymentList"
import PaymentMethodListHeader from "./components/PaymentMethods/PaymentMethodListHeader"
import AccountMobile from "./accountMobile"
import { withTranslation } from "react-i18next"
import { elements } from "caradvise_shared_components"

const { FullWidthGenericPanel } = elements

class MyAccount extends Component {
  render() {
    const { user, hasPaymentMethod, t } = this.props

    return (
      <div className="accountTopHeader">
        <div className="dashLeftContainer">
          <div className="accountTopHeader">
            <Responsive minWidth={540}>
              <span className="accountHeader primary-font confident-blue">{t("header")}</span>
              <div className="dashContainer" style={{ flex: 1 }}>
                <FullWidthGenericPanel>
                  <div className="accountContainer">
                    <div className="accountSectionContainers">
                      <span className="accountSectionHeaders">{t("personalInfoLbl")}</span>
                      <Divider />
                      <Settings renderPersonalInfo renderUpdatePassword user={user} />
                    </div>
                    {!user.generic && (
                      <div className="accountSectionContainers">
                        <span className="accountSectionHeaders">{t("paymentInfoLbl")}</span>
                        <Divider />
                        <PaymentMethodListHeader hasPaymentMethod={this.props.hasPaymentMethod} />
                        <PaymentList />
                      </div>
                    )}
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                      <span className="accountSectionHeaders">{t("accountTierLbl")}</span>
                      <MembershipSection />
                    </div>
                  </div>
                </FullWidthGenericPanel>
              </div>
            </Responsive>
            <Responsive maxWidth={539}>
              <AccountMobile />
            </Responsive>
          </div>
        </div>
      </div>
    )
  }
}

MyAccount.propTypes = {
  user: PropTypes.object.isRequired,
  hasPaymentMethod: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  let user = state.user || {}

  return {
    user: user,
    hasPaymentMethod: user.has_cc_info_saved
  }
}

export default connect(mapStateToProps)(withTranslation("myAccount")(MyAccount))
