import SplitClient, { ON } from "../../v1/components/Config/SplitClient"
import { getSubdomain } from "../../v1/components/Config/StylesheetInjector"
import { PARTNER_OFFERS_2 } from "../../v1/components/Config/SplitClient"
import { APP_NAME } from "../constants/common"

export async function getPartnerFeatureFlag(user) {
  try {
    const isPartnerFeatureFlag = await SplitClient.getTreatment(PARTNER_OFFERS_2, user, {
      app_name: APP_NAME
    })
    return isPartnerFeatureFlag[PARTNER_OFFERS_2] === ON ? true : false
  } catch (error) {
    console.log(error)
  }
}
