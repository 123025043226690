export const VEHICLES_LEAD_QUOTE_URL = "/api/v4/vehicles/lead_quote_create"
export const STAGING_LEADS_URL = "https://einstein.forevercar.com/"
export const PRODUCTION_LEADS_URL = "https://app.forevercar.com/"
export const SAFELITE_LOGO = "https://www.yceml.net/0912/15494032-1679497596852"
export const VSC = "vsc"
export const INSURANCE = "insurance"
export const TIRE = "tire"
export const OFFERS = "offers"
export const ROADSIDE_ASSISTANCE_URL =
  "https://app.urgent.ly/?campaign=CarAdvise&_ga=2.17219452.471890938.1691502289-655864227.1690987559#servicePicker"
export const PREMIUM = "Premium"
export const BASIC = "Basic"
export const AUTO_REFINANCE_URL = "http://upstart-auto-loans.sjv.io/jr0XPb"

export const ROOT_PROVIDER_GIG =
  "https://quote.joinroot.com/?utm_source=car_advise&utm_medium=display&utm_campaign=gig_flow&pid=car_advise&af_channel=display"

export const ROOT_PROVIDER_NON_GIG =
  "https://quote.joinroot.com/?utm_source=car_advise&utm_medium=display&utm_campaign=default_flow&pid=car_advise&af_channel=display"
