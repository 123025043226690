import React from "react"
import { Modal, Sidebar } from "semantic-ui-react"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import { isMobile, isMobileOnly } from "react-device-detect"
import EarnMilesStpes from "./EarnMilesStpes"
import EarnMilesStepDetails from "./EarnMilesStepInfo"
import DrivingMilesLogo from "../../../images/rewards/driving-miles.svg"
import MaintainVehicleLogo from "../../../images/rewards/maintain-vehicle.svg"
import UseCarAdviseLogo from "../../../images/rewards/by-using-caradvise.svg"
import DialogCrossLogo from "../../../images/rewards/rewardsDialogCross.svg"
import EarnMilesStpesDetails from "./EarnMilesStpesDetails"

const HowRewardsWorks = ({ setOpenDialog, openDialog, t }) => {
  const HowRewardsWorksDetails = [
    {
      id: 1,
      image: DrivingMilesLogo,
      title: t("driving"),
      whenYouEarn: t("driveEarn"),
      detail: t("driveDetailsEarn")
    },
    {
      id: 2,
      image: MaintainVehicleLogo,
      title: t("maintainVehicle"),
      whenYouEarn: t("maintainVehicleEarn"),
      detail: t("maintainVehicleDetails")
    },
    {
      id: 3,
      image: UseCarAdviseLogo,
      title: t("useCarAdvise"),
      whenYouEarn: t("useCarAdviseEarn"),
      detail: t("useCarAdviseDetails")
    }
  ]

  return isMobile ? (
    <Sidebar
      animation="overlay"
      direction="bottom"
      vertical
      visible={openDialog}
      onHide={() => setOpenDialog(false)}
      className="side-panel-container"
    >
      <div className="sidebar-container">
        <ReactSVG
          onClick={() => setOpenDialog(false)}
          src={DialogCrossLogo}
          className="rewards-dialog-cross"
        />
        <EarnMilesStpes howRewardsWorksDetails={HowRewardsWorksDetails} />

        <p className="how-program-works">{t("rewards:howRewardProgramWork")}</p>

        {HowRewardsWorksDetails.map((steps) => (
          <EarnMilesStpesDetails key={steps.id} steps={steps} />
        ))}
      </div>
    </Sidebar>
  ) : (
    <Modal
      onClose={() => setOpenDialog(false)}
      onOpen={() => setOpenDialog(true)}
      open={openDialog}
      className="reward-work-modal"
    >
      <Modal.Content>
        <Modal.Description>
          <ReactSVG
            onClick={() => setOpenDialog(false)}
            src={DialogCrossLogo}
            className="rewards-dialog-cross"
          />
          <EarnMilesStpes howRewardsWorksDetails={HowRewardsWorksDetails} />

          <p className="how-program-works">{t("rewards:howRewardProgramWork")}</p>

          {HowRewardsWorksDetails.map((steps) => (
            <EarnMilesStpesDetails key={steps.id} steps={steps} />
          ))}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default withTranslation("howRewardWorksSteps")(HowRewardsWorks)
