import React from "react"
import { withTranslation } from "react-i18next"

const CuvrdPlanServiceList = ({ parts, t }) => {
  return parts.length > 0 ? (
    <ul className="grid-container">
      {parts.map((part) => (
        <li key={part} className="grid-item">
          {part}
        </li>
      ))}
    </ul>
  ) : (
    <h1 className="placeholder">{t("NA")}</h1>
  )
}

export default withTranslation("common")(CuvrdPlanServiceList)
