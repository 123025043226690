import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

class PaymentMethodListHeader extends Component {
  renderSubHeader = () => {
    const { hasPaymentMethod } = this.props
    const { t } = this.props

    if (hasPaymentMethod) {
      return <div className="paymentInfoSubHeader">{t("header")}</div>
    } else {
      return null
    }
  }

  render() {
    return (
      <div>
        <div className="paymentInfoHeader"></div>

        {this.renderSubHeader()}
      </div>
    )
  }
} // PaymentMethodListHeader

PaymentMethodListHeader.propTypes = {
  hasPaymentMethod: PropTypes.bool.isRequired
}

export default withTranslation("paymentMethodListHeader")(PaymentMethodListHeader)
