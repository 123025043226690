import React, { Component } from "react"
import ErrorIconMobile from "../../../images/magic-link/magic-link-error.svg"
import ErrorIconDesktop from "../../../images/magic-link/magic-link-error-desktop.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class FailureLoginViaMagicLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: window.innerWidth
    }
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize)
  }

  transformTextWithLinks = (text) => {
    const { handleTryAgain } = this.props
    const parts = text.split(/\*(.*?)\*/)
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span key={index} className="button-link-orange" onClick={() => handleTryAgain()}>
            {part}
          </span>
        )
      } else {
        return <React.Fragment key={index}>{part}</React.Fragment>
      }
    })
  }

  render() {
    const { redirectError, buttonsContainer, t } = this.props
    const { windowWidth } = this.state

    const showOptions = {
      showMagicLink: false,
      showSignInButton: true
    }

    const infoTextForSignIn = `${t("infoTextForSignIn", {
      tryAgain: t("tryAgain")
    })}`

    return (
      <div className="magic-link-error-container">
        <div className="error-content">
          <Image
            src={windowWidth <= 500 ? ErrorIconMobile : ErrorIconDesktop}
            className="error-image"
          />
          <div className="error-info-content">
            <span className="title-text">{redirectError && redirectError}</span>
            <span className="meta-text">{this.transformTextWithLinks(infoTextForSignIn)}</span>
          </div>
          <div className="links">{buttonsContainer(undefined, showOptions)}</div>
        </div>
      </div>
    )
  }
}

export default withTranslation("signIn")(FailureLoginViaMagicLink)
