import React, { Component } from "react"

class ZendeskChatWidget extends Component {
  componentDidMount() {
    // Insert Zendesk Chat widget code here
    const script = document.createElement("script")
    script.id = "ze-snippet"
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`
    script.async = true

    document.body.appendChild(script)
  }

  render() {
    return null
  }
}

export default ZendeskChatWidget
