import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class AccountTierBanner extends PureComponent {
  render() {
    const { accountTierName, t } = this.props
    if (!accountTierName) return null

    // TODO: refactor to let callers control the style
    return (
      <p>
        <span className="tier-banner">{`${accountTierName} ${t("accountLbl")}`}</span>
        <br />
      </p>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let { user } = state

  return {
    accountTierName: user.membership && user.membership.plan_name
  }
}

export default connect(mapStateToProps)(withTranslation("common")(AccountTierBanner))
