import {
  postJSON,
  putJSON,
  deleteJSON,
  getJSON,
  patchJSON
} from "../../shared_component/utils/fetch"
import Notifications from "../components/Notifications"
import { getMembershipPlans } from "./membership"
import { setLanguage } from "../helpers/translationHelpers"
import i18n from "../../shared_component/utils/i18n"
import * as Sentry from "@sentry/react"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import {
  getActiveVehicle,
  getTransformedMaintenanceScheduleResponse
} from "../helpers/vehicleHelpers"
import { getSignInOrSignUpMethod, getUserType, getVehicleProps } from "../helpers/segmentHelper"
import { getMaintenanceSchedules } from "./maintenanceSchedules"
import { has, isEmpty } from "lodash"
import { FS_NAMESPACES } from "../constants/application"
import { COUNTRY_CODE } from "../../shared_component/utils/stringHelpers"
import {
  gaJSON,
  setFirebaseAnalyticsUserId,
  trackFirebaseAnalytics
} from "../../shared_component/utils/googleAnalyticsHelpers"
import store from "../../shared_component/utils/configureStore"
import { clearSession } from "../../shared_component/utils/sessionManagerHelper"

const SIGN_UP_URL = "/api/v4/customers"
const VERIFY_RIDESHARE_INSPECTION_URL = "/api/v4/shopping_carts/verify_rideshare_inspection"
const ACTIVE_CARTS_URL = "/api/v4/shopping_carts"
const RESET_PASSWORD_URL = "/api/v4/customers/reset_password"
const SIGN_IN_URL = "/api/v4/sessions"
const VEHICLES_URL = "/api/v4/vehicles"
const CART_URL = "/api/v4/customers/?/shopping_cart"
const ME_URL = "/api/v4/customers/me"
const PRODUCT_UPDATE_ACKNOWLEDGEMENTS_URL = "/api/v4/product_update_acknowledgements"
const PREFERENCES_URL = (userId) => `/api/v4/customers/${userId}/maintenance_preferences`
const MAGIC_LINK_URL_V5 = "api/v6/users/search_by"
const RETAIL = "retail_default"
const MAGIC_LINK = "magic_link"

export const USER_LOADING = "USER_LOADING"
export function setUserLoading(msg) {
  return { type: USER_LOADING, loadingMessage: msg }
}

export const USER_NOT_LOADING = "USER_NOT_LOADING"
export function setUserNotLoading() {
  return { type: USER_NOT_LOADING }
}

export const USER_ERROR = "USER_ERROR"
export function setUserError(error) {
  return { type: USER_ERROR, error }
}

export const LOGOUT_USER = "LOGOUT_USER"
export const logoutUser = () => {
  let user = store.getState().user || {}
  window.analytics.reset()
  clearSession()
  return {
    type: LOGOUT_USER
  }
}

export const IDENTIFY_AND_SET_USER = "IDENTIFY_AND_SET_USER"
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES"
// note: this isn't exported! should only be triggered via the calls to `setUserData` below
function setAndIdentifyUser(user) {
  return { type: IDENTIFY_AND_SET_USER, user }
}

function setAndUpdateUserPreferences(preferences) {
  return { type: UPDATE_USER_PREFERENCES, preferences }
}

export function setUserPreferences(preferences) {
  return async function (dispatch) {
    dispatch(setAndUpdateUserPreferences(preferences))
  }
}
export function setUserData(user) {
  return async function (dispatch) {
    dispatch(setAndIdentifyUser(user))
  }
}

export const REMOVE_USER = "REMOVE_USER"
export function removeUser() {
  return { type: REMOVE_USER, loading: false }
}

export const SET_ACTIVE_VEHICLE = "SET_ACTIVE_VEHICLE"
function setActiveVehicle(vehicleId) {
  return {
    type: SET_ACTIVE_VEHICLE,
    activeVehicleId: vehicleId,
    loading: false
  }
}

export const NEW_USER_STATUS = "NEW_USER_STATUS"
function newUserStatus(status) {
  return { type: NEW_USER_STATUS, newUserStatus: status }
}

export const UPDATE_ACTIVE_ORDER = "UPDATE_ACTIVE_ORDER"
export function updateActiveOrder(order) {
  return { type: UPDATE_ACTIVE_ORDER, payload: order }
}

export const UPDATE_ACTIVE_CARTS = "UPDATE_ACTIVE_CARTS"
export function updateActiveCarts(carts) {
  return { type: UPDATE_ACTIVE_CARTS, payload: { shopping_carts: carts } }
}

export const LOAD_USER_STATES = "LOAD_USER_STATES"
export function loadUserStates(data) {
  return { type: LOAD_USER_STATES, payload: data }
}

export function updateActiveVehicle(vehicleId) {
  return function (dispatch, getState) {
    dispatch(setActiveVehicle(vehicleId))
  }
}

export function setLoading(message) {
  return async function (dispatch) {
    dispatch(setUserLoading(message))
    return () => {}
  }
}

export function setNotLoading() {
  return async function (dispatch) {
    await dispatch(setUserNotLoading())
    return () => {}
  }
}

export const ME = "ME"
export function me(queryRequest = RETAIL) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    setFirebaseAnalyticsUserId(user.id)
    let url = `${ME_URL}`
    let response = await postJSON(
      url,
      { query: queryRequest },
      { Authorization: authentication_token } //this call will return data for user only with matching authentication id from backend.
    )
    if (response && response.result) {
      user.loading = false
      user.error = null
      let activatedVehicles = response.result.vehicles.filter(
        (vehicle) => vehicle.is_active === true
      )
      let deactivatedVehicles = response.result.vehicles.filter(
        (vehicle) => vehicle.is_active === false
      )
      let oldActiveVehicleID = null
      if (activatedVehicles && activatedVehicles.length > 0) {
        oldActiveVehicleID = user.activeVehicleId
      } else {
        oldActiveVehicleID = null
      }
      let userData = {
        ...response.result,
        activeVehicleId: oldActiveVehicleID,
        vehicles: activatedVehicles,
        deactivatedVehicles: deactivatedVehicles
      }
      try {
        Sentry.setUser({
          id: userData.id,
          email: userData.email,
          username: `${user.firstName} ${user.lastName}`,
          language: userData.language,
          fleet_id: userData.fleet_id,
          affiliation_name: userData.affiliation_name,
          customer_number: userData.customerNumber
        })
      } catch (e) {
        console.error("UNABLE TO SET SENTRY USER DATA", e)
      }
      dispatch(setUserData(userData))
      dispatch(setActiveVehicle(oldActiveVehicleID))
      if (userData) setLanguage(userData.language, userData.country)
    } else {
      if (response && response.error) dispatch(setUserError(response.error))
    }
    dispatch(setUserNotLoading())
    return () => response
  }
}

export const UPDATE_CART = "UPDATE_CART"
export function updateCart(events, vehicleId, interval_id, noLoad, opts = {}) {
  return async function (dispatch, getState) {
    if (!noLoad) dispatch(setUserLoading())
    let user = getState().user || {}

    const isGoogleBookingOrder = opts && opts.orderId && isEmpty(user.active_carts)
    const activeOrder =
      !isEmpty(user.active_orders) && user.active_orders.find((order) => order.id === opts.orderId)

    let { authentication_token } = user
    let url = `${CART_URL.replace("?", user.id)}`
    let payload
    if (Array.isArray(events)) {
      payload = {
        events: events,
        vehicle_id: vehicleId || user.activeVehicleId,
        interval_id: interval_id,
        update_existing_order: isGoogleBookingOrder ? false : opts && opts.isOrder,
        shop_order_id: opts && opts.orderId
      }
    } else {
      //prepay
      payload = {
        opted_for_prepayment: events,
        vehicle_id: vehicleId || user.activeVehicleId,
        interval_id: interval_id,
        update_existing_order: isGoogleBookingOrder ? false : opts && opts.isOrder,
        shop_order_id: opts && opts.orderId
      }
    }
    if (opts.promoCode) payload["promo_code"] = opts.promoCode
    if (opts.redeem_miles) payload["reward_miles"] = +opts.redeem_miles
    if (isGoogleBookingOrder) {
      payload["shop_id"] = activeOrder && activeOrder.shop_id
      payload["is_google_booking_order"] = isGoogleBookingOrder
    }
    let response = await putJSON(url, payload, { Authorization: authentication_token })
    //this will also return prepay discount and total,subtotal ,taxes and fees according to prepay
    //prepay_discount--prepay dsicount
    //estimated_total_prepay- estimated total with prepay discount applied
    //estimated_total_pre_tax_prepay- subtotal  with prepay discount applied

    if (response.result) {
      user.loading = false
      user.error = null
      if (!user.active_carts) user.active_carts = []
      let spliceIndex = user.active_carts.findIndex(
        (c) => c.vehicle_id === response.result.vehicle_id
      )
      if (spliceIndex !== -1) user.active_carts.splice(spliceIndex, 1)
      user.active_carts.push(response.result) //here we will push all the data in the active carts which will be new cart
      await dispatch(setUserData(user)) //here user will be updated with new user data having active carts updated.
      if (opts.successCallback) opts.successCallback(response.result)
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: `${response.error}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      dispatch(setUserError(response.error))
    }
    if (opts.callback) opts.callback()
    return () => response
  }
}

export function storeUser(user) {
  return function (dispatch) {
    dispatch(setUserData(user))
  }
}

export function addNewVehicle(data) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())

    let user = getState().user || {}
    let { authentication_token } = user
    let url = `${VEHICLES_URL}`
    let response = await postJSON(url, { vehicle: data }, { Authorization: authentication_token })

    if (response.result) {
      user.loading = false
      user.error = null
      let vehicle = response.result.vehicle

      user.vehicles.push(vehicle)
      dispatch(setUserData(user))
      user.activeVehicleId = vehicle.id
      dispatch(setActiveVehicle(vehicle.id))

      if (user.active_carts && user.active_carts.find((cart) => cart.vehicle_id === null)) {
        const request = {
          url: ACTIVE_CARTS_URL,
          headers: { Authorization: authentication_token },
          body: {}
        }
        const activeCartsResponse = await getJSON(request.url, request.body, request.headers)

        if (activeCartsResponse.result) {
          dispatch(updateActiveCarts(activeCartsResponse.result))
        } else {
          dispatch(
            Notifications.error({
              title: i18n.t("common:notificationErrorTitle"),
              message: i18n.t("user:cartUpdateFailedMessage")
            })
          )
        }
      }
      if (!has(vehicle, "maintenanceSchedules[0]")) {
        const maintenanceScheduleResponse = await dispatch(
          getMaintenanceSchedules({ vehicle: vehicle })
        )
        vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
          maintenanceScheduleResponse
        )
      }
      await identifyEvent({
        traits: getVehicleProps(vehicle),
        replaceUndefinedOrNullValues: false
      })
      trackFirebaseAnalytics({
        event: gaJSON.vehicle_added,
        params: {
          ...getVehicleProps(vehicle, { shouldAddCarsAdded: false }),
          methodToAddVehicle: "Manually",
          duringOnboarding: !data.isNewUser ? "No" : "Yes"
        }
      })
      trackEvent("vehicle-added", {
        ...getVehicleProps(vehicle, { shouldAddCarsAdded: false }),
        methodToAddVehicle: "Manually",
        duringOnboarding: !data.isNewUser ? "No" : "Yes"
      })
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )
      dispatch(setUserError(response.error))

      return false
    }

    return true
  }
}

export function updateVehicle(updatedVehicle) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let updatedVehicleId = updatedVehicle.id

    const vehicles = user.vehicles.map((vehicle) => {
      return vehicle.id === updatedVehicleId ? { ...vehicle, ...updatedVehicle } : vehicle
    })

    dispatch(setUserData({ ...user, vehicles: vehicles }))

    return () => true
  }
}

export function deleteVehicle(vehicleId) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())

    let user = getState().user || {}
    let { authentication_token } = user
    let url = `${VEHICLES_URL}/${vehicleId}`
    let response = await deleteJSON(url, {}, { Authorization: authentication_token })

    if (response.result) {
      user.loading = false
      user.error = null
      const vehicleIndex = user.vehicles.findIndex((v) => v.id === vehicleId)
      user.vehicles.splice(vehicleIndex, 1)
      dispatch(setUserData(user))
    } else {
      dispatch(setUserError(response.error))
    }
  }
}

export function signUp(data) {
  return async function (dispatch) {
    dispatch(setUserLoading())

    let response = await postJSON(SIGN_UP_URL, data)
    if (response.result) {
      let user = response.result.user
      setFirebaseAnalyticsUserId(user.id)
      dispatch(setUserData(user))
      trackFirebaseAnalytics({
        event: gaJSON.sign_up,
        params: {
          // [gaJSON.email]: user.email,
          [gaJSON.first_name]: user.firstName,
          [gaJSON.last_name]: user.lastName,
          [gaJSON.zip]: parseInt(user.zip),
          [gaJSON.phone]: COUNTRY_CODE.concat(user.cellPhone)
        }
      })
      await identifyEvent({
        traits: {
          firstNameWithSpace: user.firstName,
          lastNameWithSpace: user.lastName,
          phone: COUNTRY_CODE.concat(user.cellPhone),
          zip: user.zip,
          email: user.email
        },
        userId: user.id
      })
      window.analytics.reset()
    } else {
      dispatch(setUserError(response.error))
    }
    return () => response
  }
}

export function signIn({
  email,
  password,
  key,
  vehicleId,
  isFromNoVehicle,
  isFullStoryFlagEnabled
}) {
  return async function (dispatch) {
    let minimal = true,
      signedIn = false
    let response = await postJSON(SIGN_IN_URL, { key, email, password, query: RETAIL })
    if (response.result) {
      const { result } = response
      setFirebaseAnalyticsUserId(result.id)
      let activeVehicleId = null
      let activatedVehicles = result.vehicles.filter((vehicle) => vehicle.is_active === true)
      let deactivatedVehicles = result.vehicles.filter((vehicle) => vehicle.is_active === false)
      let cartWithVehicleIOwn = result.active_carts.find((c) => {
        return result.vehicles.find((v) => v.id === c.vehicle_id && v.is_active === true)
      })
      if (vehicleId) {
        activeVehicleId = vehicleId
      } else if (cartWithVehicleIOwn) {
        activeVehicleId = cartWithVehicleIOwn.vehicle_id
      } else if (activatedVehicles.length > 0 && activatedVehicles[0]) {
        activeVehicleId = activatedVehicles[0].id
      }
      await dispatch(
        setUserData({
          ...result,
          activeVehicleId: activeVehicleId,
          vehicles: activatedVehicles,
          deactivatedVehicles: deactivatedVehicles
        })
      )
      if (result.language) setLanguage(result.language, result.country)
      await dispatch(newUserStatus(false))
      await dispatch(getMembershipPlans())
      const activeVehicle = getActiveVehicle()
      signedIn = true
      sessionStorage.setItem("vehicle_list_modal_show", true)
      if (isFullStoryFlagEnabled) {
        // Identify user
        const user = {
          id: result.id,
          email: result.email,
          displayName: result.firstName + " " + result.lastName,
          firstName: result.firstName,
          lastName: result.lastName,
          phone: COUNTRY_CODE.concat(result.cellPhone),
          zip: result.zip,
          affiliation: result.affiliation_name
        }
        window[FS_NAMESPACES].identify(user.id, user)
        // window[FS_NAMESPACES].setUserVars(user.id, user)
      }

      identifyEvent({
        traits: {
          ...getVehicleProps(activeVehicle || {}, {
            shouldAddCarsAdded: false,
            omitIsDefault: true
          }),
          email: result.email,
          signInMethod: getSignInOrSignUpMethod("signin", response.result.base_url, key),
          userType: getUserType(response.result.base_url),
          affiliation: result.affiliation_name,
          firstNameWithSpace: result.firstName,
          lastNameWithSpace: result.lastName,
          phone: COUNTRY_CODE.concat(result.cellPhone),
          zip: result.zip
        },
        userId: result.id,
        onSuccess: () => {
          trackFirebaseAnalytics({
            event: gaJSON.sign_in,
            params: {
              // [gaJSON.email]: result.email,
              [gaJSON.first_name]: result.firstName,
              [gaJSON.last_name]: result.lastName,
              [gaJSON.zip]: parseInt(result.zip),
              [gaJSON.phone]: COUNTRY_CODE.concat(result.cellPhone),
              [gaJSON.affiliation]: result.affiliation_name,
              [gaJSON.user_type]: getUserType(response.result.base_url),
              [gaJSON.sign_in_method]: getSignInOrSignUpMethod(
                "signin",
                response.result.base_url,
                key
              )
            }
          })
          trackEvent("logged-in", {
            email: result.email,
            signInMethod: getSignInOrSignUpMethod("signin", response.result.base_url, key),
            userType: getUserType(response.result.base_url),
            affiliation: result.affiliation_name,
            firstNameWithSpace: result.firstName,
            lastNameWithSpace: result.lastName,
            phone: COUNTRY_CODE.concat(result.cellPhone),
            zip: parseInt(result.zip),
            didUserSignedFromNoVehicle: isFromNoVehicle ? "Yes" : "No"
          })
        }
      })
    } else {
      dispatch(
        Notifications.error({
          title: i18n.t("common:notificationErrorTitle"),
          message: response.error
        })
      )

      dispatch(setUserError(response.error))
    }
    return signedIn
  }
}

export function signOut() {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())

    // let { authentication_token } = getState().user || {};
    // let response = await deleteJSON(SIGN_OUT_URL, { authentication_token });

    // if (response.error) {
    //   dispatch(setUserError(response.error));
    // } else {
    dispatch(removeUser())
    // }
  }
}

export function updateInfo(data) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())
    const user = getState().user || {}
    const url = `${SIGN_UP_URL}/${user.id}`
    const response = await putJSON(
      url,
      { ...data, serialize_basic: true },
      { Authorization: user.authentication_token }
    )
    if (response.result) {
      const userResult = response.result.user || response.result || {}
      dispatch(setUserData({ ...user, ...userResult }))
      if (userResult) setLanguage(userResult.language, userResult.country)
      if (data.miles) dispatch(updateActiveVehicleInfo({ miles: data.miles }))
      if (data.displayNotification) {
        dispatch(
          Notifications.success({
            title: i18n.t("common:notificationSuccessTitle"),
            message: i18n.t("user:userUpdatedMessage")
          })
        )
      }
      return true
    } else {
      const error = response.error || response.errors
      dispatch(setUserError(error))

      if (data.displayNotification || data.displayErrorNotification) {
        dispatch(
          Notifications.error({
            title: i18n.t("common:notificationErrorTitle"),
            message: `${error}`
          })
        )
      }
      return error
    }
  }
}

export function verifyRideshareInspection(data) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const response = await postJSON(
      VERIFY_RIDESHARE_INSPECTION_URL,
      { shopping_cart_id: data.cartId },
      { Authorization: user.authentication_token }
    )

    const { result } = response
    if (result) {
      dispatch(updateActiveCarts(result.carts))
      return { success: true, serviceName: (result.service_definition || {}).name }
    } else {
      return { failure: true }
    }
  }
}

export function resetPassword(data) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const response = await putJSON(RESET_PASSWORD_URL, data, {
      Authorization: user.authentication_token
    })
    // if the password is updated, it will redirect to signIn.  otherwise it will throw an error and redirect to forgot password
    return response
  }
}

export function acknowledgeProductUpdate(data) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const response = await postJSON(PRODUCT_UPDATE_ACKNOWLEDGEMENTS_URL, data, {
      Authorization: user.authentication_token
    })
    // POST to create a ProductUpdateAcknowledgement on the user's behalf. They won't be shown this update again.
    return response
  }
}

export function updateActiveVehicleInfo(data) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())

    const user = getState().user || {}
    const { authentication_token, activeVehicleId } = user
    const url = `${VEHICLES_URL}/${activeVehicleId}`
    const response = await putJSON(url, data, { Authorization: authentication_token })

    if (response.result) {
      const activeVehicleIndex = user.vehicles.findIndex((v) => v.id === activeVehicleId)
      user.loading = false
      user.error = null
      user.vehicles.splice(activeVehicleIndex, 1, response.result.vehicle)

      dispatch(setUserData(user))
    } else {
      dispatch(setUserError(response.error))
    }
  }
}

export function fetchUserStates(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token, country } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(`/api/v4/states?country=${country}`, {}, request.headers)

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function getUserDetails(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/user`,
        { vehicle_id: activeVehicleId },
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function updateUserDetails(payload, data) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }
      const data = {
        vehicle_id: payload.vehicle_id,
        zip: payload.zip,
        mileage: payload.mileage,
        vin: payload.vin,
        language: payload.language
      }

      let response = await patchJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/user`,
        data,
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function updatePreferences(data) {
  return async function (dispatch, getState) {
    dispatch(setUserLoading())
    const user = getState().user || {}
    const { id, authentication_token } = user
    const url = PREFERENCES_URL(id)
    try {
      const headers = { Authorization: authentication_token }
      const response = await getJSON(url, data, headers)

      if (response.result) {
        dispatch(setUserPreferences(response.result))
        return response
      } else {
        const error = response.error || response.errors
        dispatch(setUserError(error))
        return error
      }
    } catch (e) {
      dispatch(setUserError(e))
      return e
    }
  }
}

export function getSplitIOForFeatures(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/providers/check_feature_flag?type=${payload}`,
        {},
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function getLogo(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/providers/logo?provider_names=["axiom", "forever", "zebra", "clearcover", "root"]`,
        {},
        request.headers
      )

      if (response.result) {
        dispatch(loadUserStates(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return response
    }
  }
}

export function sendMagicLink(payload) {
  return async function (dispatch, getState) {
    const { data } = payload
    let request = {
      body: {
        type: data.type,
        value: data.value,
        source: "web"
      }
    }

    let response = await getJSON(MAGIC_LINK_URL_V5, request.body, {})

    dispatch(setNotLoading())
    return response
  }
}

export function signInViaMagicLink({ key, vehicleId, isFullStoryFlagEnabled }) {
  return async function (dispatch) {
    dispatch(setUserLoading())
    let signedIn = false
    let response = await postJSON(SIGN_IN_URL, { key, query: RETAIL, method: MAGIC_LINK })
    if (response.result) {
      const { result } = response
      setFirebaseAnalyticsUserId(result.id)
      let activeVehicleId = null
      trackFirebaseAnalytics({
        event: gaJSON.sign_in,
        params: {
          // [gaJSON.email]: result.email,
          [gaJSON.first_name]: result.firstName,
          [gaJSON.last_name]: result.lastName,
          [gaJSON.zip]: parseInt(result.zip),
          [gaJSON.phone]: COUNTRY_CODE.concat(result.cellPhone),
          [gaJSON.affiliation]: result.affiliation_name,
          [gaJSON.user_type]: getUserType(response.result.base_url),
          [gaJSON.sign_in_method]: "Magic Link"
        }
      })
      let cartWithVehicleIOwn = result.active_carts.find((c) => {
        return result.vehicles.find((v) => v.id === c.vehicle_id)
      })
      if (vehicleId) {
        activeVehicleId = vehicleId
      } else if (cartWithVehicleIOwn) {
        activeVehicleId = cartWithVehicleIOwn.vehicle_id
      } else if (result.vehicles[0]) {
        activeVehicleId = result.vehicles[0].id
      }
      await dispatch(setUserData({ ...result, activeVehicleId: activeVehicleId }))
      if (result.language) setLanguage(result.language, result.country)
      await dispatch(newUserStatus(false))
      await dispatch(getMembershipPlans())
      signedIn = true
      sessionStorage.setItem("vehicle_list_modal_show", true)
      if (isFullStoryFlagEnabled) {
        // Identify user
        const user = {
          id: result.id,
          email: result.email,
          displayName: result.firstName + " " + result.lastName,
          firstName: result.firstName,
          lastName: result.lastName,
          phone: COUNTRY_CODE.concat(result.cellPhone),
          zip: result.zip,
          affiliation: result.affiliation_name
        }
        window[FS_NAMESPACES].identify(user.id, user)
      }
    } else {
      dispatch(setUserError(response.error))
    }
    dispatch(setUserNotLoading())
    return response
  }
}
