const LOAD_PAYMENT_METHODS = "LOAD_PAYMENT_INFO"

export default function paymentMethods(state = {}, action) {
  switch (action.type) {
    case LOAD_PAYMENT_METHODS:
      return { ...state, braintreePaymentMethods: action.braintreePaymentMethods }
    default:
      return state
  }
}
