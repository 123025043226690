import React from "react"
import { withTranslation } from "react-i18next"
import WarrantyIcon from "../../../images/warranty-logo.svg"
import { formatPriceNumber } from "../../../shared_component/utils/helpers"
import { Image } from "semantic-ui-react"

const TireMilesWarranty = ({ milesRating, t }) => {
  return (
    <div className="tire-warranty">
      <Image className="warranty-icon" src={WarrantyIcon} />
      <p className="tire-warranty-info">
        {formatPriceNumber(milesRating)} {t("miles")} {t("rating")}
      </p>
    </div>
  )
}

export default withTranslation("confirmOrderDialog")(TireMilesWarranty)
