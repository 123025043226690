import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

export default () => {
  const shouldSendEvents =
    process.env.REACT_APP_SENTRY_ENV === "production" ||
    process.env.REACT_APP_SENTRY_ENV === "staging"

  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: shouldSendEvents
      ? "https://a465f010bbb3496a83f6e83dafc30e48@o439534.ingest.sentry.io/5410137"
      : undefined,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}
