import { getJSON, postJSON } from "../../shared_component/utils/fetch"
import { setLoading, setNotLoading } from "./user"
import i18n from "../../shared_component/utils/i18n"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"

const PROMOTIONS_URL = "/api/v4/promotions"
const CUSTOMERS_PROMOTIONS_URL = "/api/v4/customers_promotions"
const UPDATE_CUSTOMERS_PROMOTIONS_URL = "/api/v4/customers_promotions/apply"

export const AVAILABLE_PROMOTIONS_LOADED = "AVAILABLE_PROMOTIONS_LOADED"
function availablePromotionsLoaded(data) {
  return { type: AVAILABLE_PROMOTIONS_LOADED, payload: data }
}

export const USED_PROMOTIONS_LOADED = "USED_PROMOTIONS_LOADED"
function usedPromotionsLoaded(data) {
  return { type: USED_PROMOTIONS_LOADED, payload: data }
}

export const CUSTOMERS_PROMOTION_CREATED = "CUSTOMERS_PROMOTION_CREATED"
function customersPromotionCreated(data) {
  return { type: CUSTOMERS_PROMOTION_CREATED, payload: data }
}

export const CUSTOMERS_PROMOTION_APPLIED = "CUSTOMERS_PROMOTION_APPLIED"
function customersPromotionApplied(data) {
  return { type: CUSTOMERS_PROMOTION_APPLIED, payload: data }
}

export const PROMOTION_PARAM_AVAILABLE = "PROMOTION_PARAM_AVAILABLE"
export function promotionParamAvailable(data) {
  return { type: PROMOTION_PARAM_AVAILABLE, payload: data }
}

export const CLEAR_PROMOTION_PARAM = "CLEAR_PROMOTION_PARAM"
export function clearPromotionParam() {
  return { type: CLEAR_PROMOTION_PARAM }
}

export function loadCustomersPromotions(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading())
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token },
        payload: { shopping_cart_id: payload.cartId }
      }

      let response = await getJSON(`${PROMOTIONS_URL}/available`, request.payload, request.headers)
      if (response.result) {
        dispatch(availablePromotionsLoaded(response.result))
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }

      response = await getJSON(`${PROMOTIONS_URL}/used`, request.payload, request.headers)
      if (response.result) {
        dispatch(usedPromotionsLoaded(response.result))
        payload.callback && payload.callback("success", {})
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }

      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function createCustomersPromotion(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("promotion:redeemingCodeLoadingMessage")))
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: CUSTOMERS_PROMOTIONS_URL,
        headers: {
          Authorization: authentication_token
        },
        payload: {
          code: payload.code,
          shopping_cart_id: payload.cartId
        }
      }

      const response = await postJSON(request.url, request.payload, request.headers)
      if (response.result) {
        dispatch(customersPromotionCreated(response.result))
        payload.callback && payload.callback("success", {})
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}

export function applyCustomersPromotion(payload) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("promotion:applyingPromotionLoadingMessage")))
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: UPDATE_CUSTOMERS_PROMOTIONS_URL,
        headers: {
          Authorization: authentication_token
        },
        payload: {
          promotion_id: payload.promotionId,
          shopping_cart_id: payload.cartId
        }
      }

      const response = await postJSON(request.url, request.payload, request.headers)
      if (response.result) {
        let res = response.result
        dispatch(customersPromotionApplied(res))
        payload.callback && payload.callback("success", {})
        trackEvent("promotional-code-applied", {
          promoCode: res.promotion.name
        })
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
      return () => response
    }
  }
}
