const LOAD_GROUP_SERVICE = "LOAD_GROUP_SERVICE"
const LOAD_PENDING_GROUP_SERVICE = "LOAD_PENDING_GROUP_SERVICE"

export default function groupReducer(state = {}, action) {
  const nextState = Object.assign({}, state)
  switch (action.type) {
    case LOAD_GROUP_SERVICE:
      nextState["grouped"] = action.GroupedServices
      break

    case LOAD_PENDING_GROUP_SERVICE:
      nextState["pendingGrouped"] = action.pendingGroup
      break

    default:
      return state
  }
  return nextState
}
