import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { signIn, setLoading, setNotLoading } from "../actions/user"
import PaymentList from "../components/PaymentList"
import MinimalSidebar from "./MinimalSidebar"
import MembershipCard from "./MembershipCard"
import { Responsive } from "semantic-ui-react"
import { getMembershipPlans } from "../actions/membership"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { includesRoadside } from "../helpers/membershipHelpers"
import { withTranslation } from "react-i18next"
import { isDasherDirect } from "../helpers/affiliationHelpers"

class MembershipFlowCCDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  goBack = () => {
    this.props.history.goBack()
  }

  async componentDidMount() {
    if ((this.props.user && this.props.user.generic) || this.props.hasCC)
      this.props.history.push("/dashboard")

    this.props.getMembershipPlans()
  }

  featuredBenefit = (str) => {
    return <strong>{str}</strong>
  }

  render() {
    const { user, selectedMembershipUpgrade, t } = this.props

    return (
      <div>
        <MinimalSidebar />
        {selectedMembershipUpgrade && (
          <div className="signup-cc-info-main-container">
            <Responsive minWidth={1300}>
              <a className="signupFlowGoBackLink" onClick={this.goBack}>
                <FontAwesomeIcon
                  style={{ padding: "3px 5px 15px 2px" }}
                  icon={faArrowLeft}
                  size={"1x"}
                />
                {t("backText")}
                <div> </div>
              </a>
            </Responsive>
            <div className="signup-ribbon-container">
              <div className="signup-ribbon caradvise-electric-blue-gradient signup-cc-info-row">
                {t("header", { planName: selectedMembershipUpgrade.name })}
              </div>
            </div>

            <div className="signup-cc-info-payment-info-title">
              <Responsive maxWidth={1300}>
                <a className="signupFlowGoBackLink" onClick={this.goBack}>
                  <FontAwesomeIcon
                    style={{ padding: "5px 5px 15px 2px" }}
                    icon={faArrowLeft}
                    size={"1x"}
                  />
                  {t("backText")}
                </a>
              </Responsive>
              {t("paymentInfoLbl")}
            </div>
            {isDasherDirect() && selectedMembershipUpgrade.is_free_trial && (
              <p style={{ marginLeft: "15%", marginRight: "30%" }}>
                Free DasherDirect Premium offer good through September 1 2021. Upon the end of the
                free trial period, your credit card will be automatically charged $1.99 per month
                for premium membership, getting you an additional 10% off already discounted fleet
                pricing.
              </p>
            )}
            <ul class="cc-info-signup-flex-container">
              <li className="upgrade-payment-list-container">
                <PaymentList
                  autoload={true}
                  altSavePaymentText={t("signupText")}
                  altSuccessMessage={t("membershipUpgradedSuccess")}
                  withMembershipUpgrade={true}
                  showMembershipClauses={
                    includesRoadside(selectedMembershipUpgrade) &&
                    user.membership.membership_tier_id !==
                      selectedMembershipUpgrade.membership_tier_id
                  }
                />

                <Responsive maxWidth={1300}>
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }}
                  >
                    <MembershipCard plan={selectedMembershipUpgrade} hideButton={true} />
                  </div>
                </Responsive>
              </li>

              <Responsive minWidth={1300}>
                <MembershipCard plan={selectedMembershipUpgrade} hideButton={true} />
              </Responsive>
            </ul>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let { user, membershipData, paymentMethods } = state

  return {
    paymentMethods: paymentMethods.braintreePaymentMethods,
    user: user || {},
    hasCC: state.user && state.user.has_cc_info_saved,
    availableMembershipPlans: membershipData.availableMembershipPlans || [],
    activeMembershipPlan: user.membership || {},
    selectedMembershipUpgrade: membershipData.selectedMembershipUpgrade || {}
  }
}

export default withRouter(
  connect(mapStateToProps, { signIn, setLoading, setNotLoading, getMembershipPlans })(
    withTranslation("membershipFlowCCDetails")(MembershipFlowCCDetails)
  )
)
