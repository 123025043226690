import { extractFirstSubDomain } from "../../shared_component/utils/urlUtils"

export function isCarPerks(user = {}) {
  // this will not work to identify CarPerks customers in local development
  const isAffiliatedCustomerOnRetailSubdomain =
    extractFirstSubDomain(window.location.host) === "app" &&
    user.affiliation &&
    user.affiliation.id !== 1 // is not CarAdvise default affiliation

  return isCarPerksSignUp() || isAffiliatedCustomerOnRetailSubdomain
}

export function isCarPerksSignUp() {
  return /carperks/.test(window.location.href)
}
