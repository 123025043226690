import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Image } from "semantic-ui-react"

const Membership = (props) => {
  const { t } = props
  return (
    <div className="dashboard_membership_container">
      <div className="bold_header">
        <h3 className="just_for_you">{t("justForYou")}</h3>
      </div>
      <div className="dashboard_membership_parent_container">
        <div className="dashboard_membership_child_container">
          <div className="dashboard_membership_left_container">
            <h2 className="what_is_membership">{t("whatMembership")}</h2>
            <p className="big_massage">
              {t("longMessage1")}
              <br />
              {t("longMessage2")}
            </p>
            <Link to={"/membership"}>
              <p className="see_details">{t("seeDetails")}</p>
            </Link>
          </div>
          <div className="dashboard_membership_right_container">
            <div>
              <Image
                className="dashboard_membership_left_group"
                src={require("../../../images/caradvise2/membership_card.svg")}
                alt="Group"
              />
            </div>
            <div>
              <Image
                className="dashboard_membership_right_group"
                src={require("../../../images/membership/smallPic.png")}
                alt="smallPic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation("dashboardMembership")(Membership)
