import { GET_ACTIVE_ZIP, UPDATE_ACTIVE_ZIP } from "../constants/serviceSearch"
export const initialState = {
  activeZip: null
}

export default function serviceSearch(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_ZIP:
      return {
        ...state,
        activeZipp: null
      }
    case UPDATE_ACTIVE_ZIP:
      return {
        ...state,
        activeZip: action.payload
      }

    default:
      return state
  }
}
