import React from "react"
import CalendarDropdown from "../Calendar/CalendarDropdown"
import ReviewTable from "./ReviewTable"
import { Link, withRouter } from "react-router-dom"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import { useTranslation } from "react-i18next"
import moment from "moment"
import SavingsRibbon from "../ShopSearch/SavingsRibbon"
import PrepayCheckbox from "../Cart/PrepayCheckbox"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../actions/user"

import { pricingDataForOrder } from "../../helpers/shopHelpers"

function Summary({
  orderLikeObject,
  shopLikeObject,
  isSeekingQuote,
  isFixedPrice,
  prepayDataFromSummaryToCartPanel,
  checkboxDisabled,
  isCart,
  payAheadTreatment,
  user,
  location
}) {
  const { i18n, t } = useTranslation("summary")
  const { company = {} } = shopLikeObject || {}
  const pricingData = pricingDataForOrder(orderLikeObject, company, t)
  const savingsText =
    orderLikeObject && orderLikeObject.services_retail_savings
      ? orderLikeObject.services_retail_savings
      : orderLikeObject && orderLikeObject.total_retail_savings

  function prepayDataFromReviewToSummary(data) {
    prepayDataFromSummaryToCartPanel(data)
  }

  const confirm_url_match = window.location.hash == "#/carts/" + orderLikeObject.id + "/confirm"

  var priced_at_shop
  if (orderLikeObject.order_services) {
    priced_at_shop = orderLikeObject.order_services.some(
      (order_service) =>
        order_service.price_estimate == null ||
        (order_service.price_estimate && order_service.price_estimate.shop_price_estimate == null)
    )
  }

  return (
    <React.Fragment>
      <p className="secondary-font">
        <strong>{t("summaryLbl")}</strong>
      </p>
      <CalendarDropdown user={user} order={orderLikeObject} />
      <p className="secondary-font">
        <small>
          <strong>
            {isSeekingQuote ? t("seekingQoutesForServicesLbl") : t("scheduledServicesLbl")}
          </strong>
        </small>
      </p>
      <hr />
      <ReviewTable
        order={orderLikeObject}
        shop={shopLikeObject}
        isFixedPrice={isFixedPrice}
        displayPrices={!!shopLikeObject}
        removable={orderLikeObject.status == undefined}
        showSavingsRibbon={false}
        prepayDataFromReviewToSummary={prepayDataFromReviewToSummary}
        checkboxDisabled={checkboxDisabled}
        user={user}
      />
      {/* Only show SavingsRibbon while in shopping cart. It's otherwise shown via ReviewTable */}
      {orderLikeObject.status == null && company && !company.cdk_dealer && (
        <SavingsRibbon savingsText={savingsText} ribbonStyle={{ width: "60%" }} />
      )}
      {orderLikeObject.status == null && (
        <p className="cart-add-service-cta secondary-font">
          <Link
            onClick={() => {
              GAEvent(GAEventCategories.DASHBOARD, "add-change-services", "Add or Change Services")
              // trackEvent(EVENT_NAME.chooseOrAddChange, {
              //   ...getAdditionalProps(),
              //   source: location.pathname,
              //   buttonName: t("addOrChangeServicesLbl")
              // })
            }}
            to="/selectCommonMaintenance"
          >
            {t("addOrChangeServicesLbl")}
          </Link>
        </p>
      )}
      {isCart && confirm_url_match && payAheadTreatment && !priced_at_shop && (
        <PrepayCheckbox
          prepayDataFromCheckboxToReview={prepayDataFromReviewToSummary}
          prepay={
            orderLikeObject.opted_for_prepayment == undefined
              ? true
              : orderLikeObject.opted_for_prepayment
          }
          force_prepay={
            orderLikeObject.force_prepay == undefined ? true : orderLikeObject.force_prepay
          }
          t={t}
          order={orderLikeObject}
        />
      )}
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const user = state.user || {}
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    isCart = false
  } else {
    isCart = true
  }

  return {
    isCart,
    activeVehicle
  }
}

export default connect(mapStateToProps, { updateCart })(
  withRouter(withTranslation(["reviewTable", "common"])(Summary))
)
