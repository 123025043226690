import React from "react"
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const ZIP_CODE_REGEX = /^\d{5}((-|\s)\d{4})?$/
export const POSTAL_CODE_REGEX = /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} \d{1}[A-Za-z]{1}\d{1}$/
export const COUNTRY_CODE = "+1"

export function titleCase(string) {
  return string
    .replace("_", " ")
    .replace("-", " ")
    .replace(/\w\S*/g, function (characters) {
      return characters.charAt(0).toUpperCase() + characters.substr(1).toLowerCase()
    })
}

export function capitalCase(string) {
  if (string == null) return ""

  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function capitalizeEachWord(string) {
  if (string == null) return ""

  return string
    .split(" ")
    .map((word) => capitalCase(word))
    .join(" ")
}

export const convertToLowerCase = (str) => {
  if (str == null) return ""
  return str && str.toLowerCase()
}

export const obfuscateEmail = (email) => {
  const [localPart, domain] = email.split("@")
  const obfuscatedLocalPart = obfuscateString(localPart)
  const obfuscatedDomain = obfuscateDomain(domain)
  return obfuscatedLocalPart + "@" + obfuscatedDomain
}

const obfuscateString = (str) => {
  const firstCharacters = str.slice(0, 2)
  const dots = ".".repeat(str.length - 4)
  return firstCharacters + dots
}

const obfuscateDomain = (domain) => {
  const [subdomain, topLevelDomain] = domain.split(".")
  const obfuscatedSubdomain = obfuscateString(subdomain)
  return obfuscatedSubdomain + "." + topLevelDomain
}

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "")
  const isValidNumber = cleaned.length === 10

  if (isValidNumber) {
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    return formatted
  } else {
    return phoneNumber
  }
}

export const formatSentence = (text, fontWeight = 900, textDecoration = "none") => {
  const parts = text.split("*")

  const elements = parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span>
    ) : (
      <p
        style={{
          fontWeight: fontWeight,
          textDecoration: textDecoration,
          marginBlockStart: "0em",
          marginBlockEnd: "0em"
        }}
        key={index}
      >
        {part}
      </p>
    )
  })

  return elements
}
