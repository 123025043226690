import React, { useState } from "react"
import { showNav, hideNav } from "../actions/leftNav"
import { connect } from "react-redux"
import { withRouter, NavLink } from "react-router-dom"
import { constants } from "caradvise_shared_components"
import { routerPaths } from "../constants/paths"
import Badge from "./Badge"
import { hidePartnerOffers } from "../helpers/affiliationHelpers"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { GAEvent, GAEventCategories } from "../tracking/GAEvent"
import { useTranslation } from "react-i18next"
import closeIcon from "../../images/icons/Icon.svg"
import carAdvise360Logo from "../../images/car360.svg"
import carAdvise360BlackLogo from "../../images/car360-black.svg"

import {
  faCalendar,
  faCar,
  faIdCard,
  faHome,
  faWrench,
  faEnvelopeOpenDollar,
  faGift,
  faCheckCircle,
  faQuestionCircle,
  faFileAlt
} from "@fortawesome/pro-regular-svg-icons"
import { getSubdomain } from "../components/Config/StylesheetInjector"
import SplitClient, { GLOVEBOX_FEATURE, ON } from "../components/Config/SplitClient"
import { OFFERS } from "../constants/offers"
import { isEmpty } from "lodash"
import { APP_NAME } from "../../shared_component/constants/common"
import RewardBlackLogo from "../../images/rewards/reward-navigation.svg"
import RewardWhiteLogo from "../../images/rewards/reward-navigation-white.svg"
import { REWARDS } from "../../shared_component/constants/rewards"

const serviceStatuses = constants.services.statuses
const {
  orders: { AWAITING_APPROVAL }
} = constants

/*
  https://reacttraining.com/react-router/web/api/NavLink
  needs activeClassName set to nav-dashboard but this feature doesn't work right with React HashRouter (the page you land on stays active no matter which other tab you choose).
  Maybe needs to be BrowserRouter.
  big TODO is to switch to that. Once we do, this sidebar will have the nav link active when the pafge first loads for the right nav, possibly.
  I tried this though and it didn't work. Still, no time left, need to move on now.
  -Jordan
*/
const gloveBoxFunctionality = (user) => {
  const [isGloveBoxFeatureFlag, setGloveBoxFeatureFlag] = useState()
  const gloveBoxFunctionalityFlag = async () => {
    const result = await SplitClient.getTreatments([GLOVEBOX_FEATURE], user, {
      app_name: APP_NAME
    })
    return result[GLOVEBOX_FEATURE]
  }
  gloveBoxFunctionalityFlag().then((flagValue) => {
    setGloveBoxFeatureFlag(flagValue)
  })
  return isGloveBoxFeatureFlag
}

function pendingApprovalsCount(user) {
  let count = 0
  const vehicleId = user.activeVehicleId
  const activeOrders = user.active_orders || []
  const order = activeOrders.filter(
    (order) => order.vehicle_id === vehicleId && order.status == AWAITING_APPROVAL
  )[0]
  if (order) {
    const pendingApprovalStatuses = [
      serviceStatuses["initiated"],
      serviceStatuses["initiatedByUser"]
    ]
    count = order.order_services.filter((order_service) =>
      pendingApprovalStatuses.includes(order_service.status)
    ).length
  }
  return count
}

function LeftNav({ activeVehicleId, hideChrome, isLoggedIn, user, navOpen, hideNav, showNav }) {
  if (!isLoggedIn || hideChrome || !activeVehicleId) {
    return null
  }

  const { t } = useTranslation("leftNav")

  const isOfferRoute = window.location.hash && window.location.hash.includes(OFFERS)
  const isRewardsRoute = window.location.hash && window.location.hash.includes(REWARDS)

  const isRewardFlag = window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature
  return (
    <div className="top-container">
      <div className="left-nav-shrink-main-container">
        <div
          className="left-nav-shrink-header"
          onClick={() => {
            if (navOpen) hideNav()
            else showNav()
          }}
        >
          {!navOpen ? (
            <i
              style={{
                color: "#ffffff",
                marginLeft: "40%",
                cursor: "pointer",
                marginTop: "50%",
                scale: "1.6"
              }}
              class="fa fa-bars"
              aria-hidden="true"
            ></i>
          ) : (
            <img className="left-nav-shrink-close-icon" src={closeIcon} />
          )}
        </div>

        {!navOpen ? (
          <ul className="left-nav-shrink-ul">
            <div class="tooltip">
              <NavLink
                onClick={() =>
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-dashboard",
                    "Left Nav Dashboard Link Clicked"
                  )
                }
                to="/dashboard"
                exact
              >
                <Badge count={null} icon={faHome} />
              </NavLink>
              <span class="tooltiptext">{t("dashboardMenu")}</span>
            </div>
            <div class="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-approvals",
                    "Left Nav Approvals Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Approvals"
                  })
                }}
                to="/approvals"
                exact
              >
                <Badge
                  count={pendingApprovalsCount(user) > 0 ? pendingApprovalsCount(user) : null}
                  icon={faCheckCircle}
                />
              </NavLink>

              <span class="tooltiptext"> {t("approvalsMenu")}</span>
            </div>
            <div class="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-suggested-maintenance",
                    "Left Nav Suggested Maintenance Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Maintenance Schedule"
                  })
                }}
                to="/maintenanceSchedule"
                exact
              >
                <Badge count={null} icon={faCalendar} />
              </NavLink>
              <span class="tooltiptext"> {t("maintenanceScheduleMenu")}</span>
            </div>
            <div class="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-membership-card",
                    "Membership Card Dashboard Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Membership Card"
                  })
                }}
                to="/membership"
                exact
              >
                <Badge count={null} icon={faIdCard} />
              </NavLink>
              <span class="tooltiptext"> {t("membershipCardMenu")} </span>
            </div>
            {gloveBoxFunctionality(user) === ON && (
              <div class="tooltip">
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-glovebox",
                      "My Garage Dashboard Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Glovebox"
                    })
                  }}
                  to="/gloveboxComponent"
                  exact
                >
                  <Badge count={null} icon={faFileAlt} />
                </NavLink>
                <span class="tooltiptext"> {t("glovebox")} </span>
              </div>
            )}
            <div class="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-my-garage",
                    "My Garage Dashboard Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "My Garage"
                  })
                }}
                to="/managevehicles"
                exact
              >
                <Badge count={null} icon={faCar} />
              </NavLink>
              <span class="tooltiptext"> {t("myGarageMenu")} </span>
            </div>
            {activeVehicleId && (
              <div class="tooltip">
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-history",
                      "Maintenance History Link Clicked"
                    )
                    trackEvent("click-navigation-drawer", {
                      itemName: "Maintenance History"
                    })
                  }}
                  to={`/vehicles/${activeVehicleId}/history`}
                  exact
                >
                  <Badge count={null} icon={faWrench} />
                </NavLink>
                <span class="tooltiptext"> {t("maintenanceHistoryMenu")} </span>
              </div>
            )}
            <div class="tooltip">
              <NavLink to={routerPaths.rewards} exact>
                <Badge
                  icon={isRewardsRoute ? RewardBlackLogo : RewardWhiteLogo}
                  customImage={{
                    isImage: true,
                    width: "18px",
                    height: "21px",
                    style: { marginTop: "4px" }
                  }}
                />
              </NavLink>
              <span class="tooltiptext"> {t("rewards")} </span>
            </div>
            {!hidePartnerOffers() && (
              <div class="tooltip">
                <NavLink
                  onClick={() => {
                    GAEvent(
                      GAEventCategories.DASHBOARD,
                      "left-nav-to-partner-offers",
                      "CarAdvise 360 Link Clicked"
                    )
                    trackEvent("caradvise-360-viewed", {
                      itemName: "CarAdvise 360"
                    })
                  }}
                  to={routerPaths.offers}
                  exact
                >
                  <Badge
                    count={null}
                    icon={isOfferRoute ? carAdvise360BlackLogo : carAdvise360Logo}
                    customImage={{ isImage: true, width: 27, height: 24 }}
                  />
                </NavLink>
                <span class="tooltiptext"> {t("partnerOffersMenu")} </span>
              </div>
            )}
            <div class="tooltip">
              <NavLink
                onClick={() => {
                  GAEvent(
                    GAEventCategories.DASHBOARD,
                    "left-nav-to-invite-friends",
                    "Invite Friends Link Clicked"
                  )
                  trackEvent("click-navigation-drawer", {
                    itemName: "Invite Friends"
                  })
                }}
                to={routerPaths.inviteFriends}
                exact
              >
                <Badge icon={faEnvelopeOpenDollar} />
              </NavLink>
              <span class="tooltiptext"> {t("inviteFriendsMenu")} </span>
            </div>

            <div
              class="tooltip"
              onClick={() => {
                trackEvent("click-navigation-drawer", {
                  itemName: "FAQs"
                })
              }}
            >
              <a href="https://caradvise.com/faq/" target="_blank">
                <Badge count={null} icon={faQuestionCircle} />
              </a>
              <span class="tooltiptext"> {t("faqPageMenu")} </span>
            </div>
          </ul>
        ) : (
          <div className="leftNav">
            <ul>
              <div className="left-nav-shrink-divider">
                <li>
                  <NavLink
                    onClick={() =>
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-dashboard",
                        "Left Nav Dashboard Link Clicked"
                      )
                    }
                    to="/dashboard"
                    exact
                  >
                    <Badge count={null} icon={faHome} />
                    <span className="left-nav-expand-menu-name">{t("dashboardMenu")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-approvals",
                        "Left Nav Approvals Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Approvals"
                      })
                    }}
                    to="/approvals"
                    exact
                  >
                    <Badge
                      count={pendingApprovalsCount(user) > 0 ? pendingApprovalsCount(user) : null}
                      icon={faCheckCircle}
                    />
                    <span className="left-nav-expand-menu-name">{t("approvalsMenu")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-suggested-maintenance",
                        "Left Nav Suggested Maintenance Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Maintenance Schedule"
                      })
                    }}
                    to="/maintenanceSchedule"
                    exact
                  >
                    <Badge count={null} icon={faCalendar} />
                    <span className="left-nav-expand-menu-name">
                      {t("maintenanceScheduleMenu")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-membership-card",
                        "Membership Card Dashboard Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Membership Card"
                      })
                    }}
                    to="/membership"
                    exact
                  >
                    <Badge count={null} icon={faIdCard} />
                    <span className="left-nav-expand-menu-name">{t("membershipCardMenu")}</span>
                  </NavLink>
                </li>
                {gloveBoxFunctionality(user) === ON && (
                  <li>
                    <NavLink
                      onClick={() => {
                        GAEvent(
                          GAEventCategories.DASHBOARD,
                          "left-nav-to-glovebox",
                          "My Garage Dashboard Link Clicked"
                        )
                        trackEvent("click-navigation-drawer", {
                          itemName: "Glovebox"
                        })
                      }}
                      to="/gloveboxComponent"
                      exact
                    >
                      <Badge count={null} icon={faFileAlt} />
                      <span className="left-nav-expand-menu-name"> {t("glovebox")}</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-my-garage",
                        "My Garage Dashboard Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "My Garage"
                      })
                    }}
                    to="/managevehicles"
                    exact
                  >
                    <Badge count={null} icon={faCar} />
                    <span className="left-nav-expand-menu-name"> {t("myGarageMenu")}</span>
                  </NavLink>
                </li>
                {activeVehicleId && (
                  <li>
                    <NavLink
                      onClick={() => {
                        GAEvent(
                          GAEventCategories.DASHBOARD,
                          "left-nav-to-history",
                          "Maintenance History Link Clicked"
                        )
                        trackEvent("click-navigation-drawer", {
                          itemName: "Maintenance History"
                        })
                      }}
                      to={`/vehicles/${activeVehicleId}/history`}
                      exact
                    >
                      <Badge count={null} icon={faWrench} />
                      <span className="left-nav-expand-menu-name">
                        {" "}
                        {t("maintenanceHistoryMenu")}
                      </span>
                    </NavLink>
                  </li>
                )}
              </div>
              {!hidePartnerOffers() && (
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-partner-offers",
                        "CarAdvise 360 Link Clicked"
                      )
                      trackEvent(
                        "caradvise-360-viewed",
                        {
                          itemName: "CarAdvise 360 viewed"
                        },
                        true
                      )
                    }}
                    to={routerPaths.offers}
                    exact
                  >
                    <Badge
                      count={null}
                      icon={isOfferRoute ? carAdvise360BlackLogo : carAdvise360Logo}
                      customImage={{
                        isImage: true,
                        width: 24,
                        height: 24,
                        style: { marginTop: "4px", marginLeft: "-5px" }
                      }}
                    />
                    <span className="left-nav-expand-menu-name"> {t("partnerOffersMenu")}</span>
                  </NavLink>
                </li>
              )}
              <div style={{ paddingTop: "20%" }}>
                {isRewardFlag && (
                  <li>
                    <NavLink to={routerPaths.rewards} exact>
                      <Badge
                        icon={isRewardsRoute ? RewardBlackLogo : RewardWhiteLogo}
                        customImage={{
                          isImage: true,
                          width: "18px",
                          height: "21px",
                          style: { marginTop: "4px" }
                        }}
                      />
                      <span className="left-nav-expand-menu-name"> {t("rewards")}</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    onClick={() => {
                      GAEvent(
                        GAEventCategories.DASHBOARD,
                        "left-nav-to-invite-friends",
                        "Invite Friends Link Clicked"
                      )
                      trackEvent("click-navigation-drawer", {
                        itemName: "Invite Friends"
                      })
                    }}
                    to={routerPaths.inviteFriends}
                    exact
                  >
                    <Badge icon={faEnvelopeOpenDollar} />
                    <span className="left-nav-expand-menu-name"> {t("inviteFriendsMenu")}</span>
                  </NavLink>
                </li>

                <li
                  onClick={() => {
                    trackEvent("click-navigation-drawer", {
                      itemName: "FAQs"
                    })
                  }}
                >
                  <a href="https://caradvise.com/faq/" target="_blank">
                    <Badge count={null} icon={faQuestionCircle} />
                    <span className="left-nav-expand-menu-name"> {t("faqPageMenu")}</span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  return {
    activeVehicleId:
      state.user.activeVehicleId ||
      (state.user &&
        state.user.vehicles &&
        state.user.vehicles.length > 0 &&
        state.user.vehicles[0].id),
    hasCC: state.user && state.user.has_cc_info_saved,
    hasZip: state.user && state.user.zip,
    hideChrome: state.framework && state.framework.hideChrome,
    isLoggedIn: state.user && state.user.id,
    user: state.user,
    navOpen: state && state.leftnav && state.leftnav.navOpen,
    isGloveBoxFeatureFlag: props.isGloveBoxFeatureFlag
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideNav: () => dispatch(hideNav()),
    showNav: () => dispatch(showNav())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftNav))
