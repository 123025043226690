import React, { useEffect, useState } from "react"
import { Image, Modal } from "semantic-ui-react"
import CuvrdPlan from "./CuvrdPlan"
import CrossIcon from "../../../images/btn-delete.png"
import { connect } from "react-redux"
import { getCuvrdPlanParts } from "../../actions/vsc"
import Notifications from "../Notifications"
import { withTranslation } from "react-i18next"
import store from "../../../shared_component/utils/configureStore"
import LoadingComponent from "../LoadingComponent"
import { replaceSubstring } from "../../helpers/cuvrdHelpers"

const CuvrdModel = ({
  handleCuvrdDialog,
  openCuvrdDialog,
  cuvrdPartsUrl,
  getCuvrdPlanParts,
  selectedPlan,
  t
}) => {
  const [cuvrdParts, setCuvrdParts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleCuvrdPlanParts = async () => {
    const response = await getCuvrdPlanParts({
      apiURL: cuvrdPartsUrl,
      callback: (err) => {}
    })
    if (response.error) {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: `${response.error}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      setIsLoading(false)
    } else {
      setCuvrdParts(response.result)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    cuvrdPartsUrl && openCuvrdDialog && handleCuvrdPlanParts()
  }, [openCuvrdDialog, cuvrdPartsUrl])

  return (
    <Modal
      open={openCuvrdDialog}
      onClose={() => handleCuvrdDialog()}
      onOpen={() => handleCuvrdDialog()}
      className="cuvrd-dialog"
    >
      <Modal.Content>
        <div className="parent-dialog-header">
          <p className="cuvrd-dialog-header">
            {t("yourPlan")} -{" "}
            {selectedPlan.plan ? replaceSubstring(selectedPlan.plan, "-", "") : t("NA")}
          </p>
          <Image className="cross-logo" src={CrossIcon} onClick={handleCuvrdDialog} />
        </div>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          cuvrdParts &&
          cuvrdParts.length > 0 &&
          cuvrdParts.map((parts) => (
            <div className="cuvrd-dialog-container">
              <div className="cuvrd-services-container">
                <CuvrdPlan
                  beforeText={parts.beforeText}
                  title={parts.name}
                  headerLogo={parts.icon}
                  afterText={parts.afterText}
                  converedParts={parts.coveredParts}
                  notCoveredParts={parts.notCoveredParts}
                />
              </div>
            </div>
          ))
        )}
      </Modal.Content>
    </Modal>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return {
    user: user
  }
}

export default connect(mapStateToProps, {
  getCuvrdPlanParts
})(withTranslation("common")(CuvrdModel))
