import caradviseStyles from "../../../css/caradvise.scss"
import allstateStyles from "../../../css/allstate.scss"
import carchexStyles from "../../../css/carchex.scss"
import generalgarageStyles from "../../../css/generalgarage.scss"
import plymouthrockStyles from "../../../css/plymouthrock.scss"
import simpletireStyles from "../../../css/simpletire.scss"
import uberStyles from "../../../css/uber.scss"
import uberCanadaStyles from "../../../css/uber-canada.scss"
import ebayStyles from "../../../css/ebay.scss"
import firestoneStyles from "../../../css/firestone.scss"
import jiffylubeStyles from "../../../css/jiffylube.scss"
import alliantStyles from "../../../css/alliant.scss"
import carvanaStyles from "../../../css/carvana.scss"
import shiptStyles from "../../../css/shipt.scss"
import grubhubStyles from "../../../css/grubhub.scss"
import { extractFirstSubDomain } from "../../../shared_component/utils/urlUtils"
import { CARADVISE, GRUBHUB } from "../../constants/affiliations"

const styles = {
  caradvise: caradviseStyles,
  allstate: allstateStyles,
  carchex: carchexStyles,
  generalgarage: generalgarageStyles,
  plymouthrock: plymouthrockStyles,
  uber: uberStyles,
  "uber-canada": uberCanadaStyles,
  simpletire: simpletireStyles,
  ebay: ebayStyles,
  firestone: firestoneStyles,
  jiffylube: jiffylubeStyles,
  alliant: alliantStyles,
  lmcur: caradviseStyles,
  favor: caradviseStyles,
  carvana: carvanaStyles,
  gasbuddy: caradviseStyles,
  paylink: caradviseStyles,
  instacart: caradviseStyles,
  gridwise: caradviseStyles,
  stride: caradviseStyles,
  amazon: caradviseStyles,
  usaa: caradviseStyles,
  take5: caradviseStyles,
  doordash: caradviseStyles,
  dasherdirect: caradviseStyles,
  aceable: caradviseStyles,
  "grubhub-gasbuddy": caradviseStyles,
  moneylion: caradviseStyles,
  cornershop: caradviseStyles,
  "instacart-ca": caradviseStyles,
  "cornershop-canada": caradviseStyles,
  lifemart: caradviseStyles,
  shipt: shiptStyles,
  grubhub: grubhubStyles
}

let loadStylesheet = (url, callback) => {
  let sheet = document.createElement("link")
  sheet.rel = "stylesheet"
  sheet.href = url
  sheet.type = "text/css"
  sheet.onload = () => callback()
  document.head.appendChild(sheet)
}

export function getSubdomain(host) {
  if (!host) {
    host = window.location.hostname
  }

  let subDomain = extractFirstSubDomain(host)

  if (!styles.hasOwnProperty(subDomain)) subDomain = CARADVISE
  return subDomain
}

export default function StylesheetInjector() {
  let subdomain = getSubdomain()

  let styleSheet = `${process.env.PUBLIC_URL}/css/semantic/semantic.min.css`

  loadStylesheet(styleSheet, () =>
    loadStylesheet(`${process.env.PUBLIC_URL}/css/${subdomain}.css`, () => {
      document.querySelector("#splash").style.display = "none"
      document.querySelector("#root").style.display = "block"
    })
  )
}

export function getStyles() {
  let subdomain = getSubdomain()
  return styles[subdomain]
}
