import React from "react"
import ReactSVG from "react-svg"
import { Modal } from "semantic-ui-react"
import DialogCrossLogo from "../../images/delete_icon.svg"
import RatingLogo from "../../images/ratings.svg"
import { useTranslation } from "react-i18next"

const InsuranceReview = ({ handleDialog, openDialog }) => {
  const { t } = useTranslation("chooseCoverage")

  const ROOT_REVIEWS = [
    {
      id: 1,
      review: t("rootReview1"),
      publishedBy: t("rootPublish1")
    },
    {
      id: 2,
      review: t("rootReview2"),
      publishedBy: t("rootPublish2")
    }
  ]

  return (
    <Modal
      onClose={() => handleDialog()}
      onOpen={() => handleDialog()}
      open={openDialog}
      className="insurance_review__dialog"
    >
      <Modal.Content>
        <Modal.Description>
          <div className="insurance_review__header">
            <div className="insurance_review__label">
              <ReactSVG className="insurance_review__logo" src={RatingLogo} />
              <p className="insurance_review__text">{t("reviewDialogLabel")}</p>
            </div>
            <ReactSVG
              onClick={() => handleDialog()}
              src={DialogCrossLogo}
              className="insurance_cross__logo"
            />
          </div>

          <div className="insurance_review">
            {ROOT_REVIEWS.map((review) => (
              <div className="review_container">
                <p className="review_text"> “{review.review}”</p>
                <p className="review_text reiew_published__by"> - {review.publishedBy}</p>
              </div>
            ))}
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default InsuranceReview
