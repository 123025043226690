import React, { Component } from "react"
import CheckoutIndex from "./CheckoutIndex"
import { PAYMENT_PAGE } from "../../../shared_component/constants/common"

class PaymentComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: (this.props.location.state && this.props.location.state.showMapListView) || false
    }
  }
  render() {
    const { history } = this.props
    const { showMap } = this.state
    return <CheckoutIndex pageContent={PAYMENT_PAGE} history={history} showMap={showMap} />
  }
}
export default PaymentComponent
