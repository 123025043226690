export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS"

const initialState = {
  coverageDate: "",
  coverageMileage: ""
}

function saveDetails(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_DETAILS:
      return {
        ...state,
        coverageDate: action.payload.expirationDate,
        coverageMileage: action.payload.expirationMileage
      }
    default: {
      return state
    }
  }
}

export default saveDetails
