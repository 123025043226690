import React, { useEffect, useState } from "react"
import HowEarnMilesCards from "../HowEarnMilesCards"
import ReactSVG from "react-svg"
import GoBackLogo from "../../../../images/go_back_icon.svg"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getRewards } from "../../../../v1/actions/rewards"
import Notifications from "../../../../v1/components/Notifications"
import store from "../../../../shared_component/utils/configureStore"
import { withTranslation } from "react-i18next"
import { routerPaths } from "../../../../v1/constants/paths"
import RewardsPlaceholder from "../RewardsPlaceholder"
import { EARN } from "../../../../shared_component/constants/rewards"

const EarnMilesIndex = ({ getRewards, history, userRewards, t }) => {
  const [isLoading, setIsLoading] = useState(false)

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  if (isRewardsFeature !== undefined && !isRewardsFeature) {
    history.push({
      pathname: routerPaths.dashboard
    })
  }

  useEffect(() => {
    getRewardsData()
  }, [])

  const getRewardsData = async () => {
    setIsLoading(true)
    try {
      const rewardsRes = await getRewards({ rewardType: EARN })

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
        setIsLoading(false)
      }
    } catch (error) {
      console.log(t("errorInEarnMileIndex"), error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="earn-miles-container">
      <div className="earn-miles-header">
        <ReactSVG src={GoBackLogo} onClick={() => window.history.back()} className="go-back-logo" />
        <p className="earn-miles-label">{t("earnedMiles")}</p>
      </div>
      <div
        className={
          (userRewards && userRewards.length > 0) || isLoading ? "earn-miles" : "placeholder_image"
        }
      >
        {isLoading ? (
          [...new Array(6)].map(() => <div className="miles_shimmer"></div>)
        ) : userRewards && userRewards.length > 0 ? (
          userRewards.map((reward) => (
            <HowEarnMilesCards isActionButtonVisible={false} key={reward.id} earnMiles={reward} />
          ))
        ) : (
          <RewardsPlaceholder />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userRewards:
      state.rewards && state.rewards.userRewards && state.rewards.userRewards.length > 0
        ? state.rewards.userRewards
        : []
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getRewards
  })(withTranslation("rewards")(EarnMilesIndex))
)
