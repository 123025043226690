import React from "react"
import { Icon, Popup } from "semantic-ui-react"
import ShopMapFilter from "./ShopMapFilter"
import { useTranslation } from "react-i18next"
import ShopSortingComponent from "./ShopSortingComponent"
import ZipEdit from "../../../../../v2/components/Schedule/Shop/ZipEdit"

const MapHeaderComponent = (props) => {
  const {
    setSortingType,
    sortingType,
    ratingFiltersApplied,
    typeFiltersApplied,
    addorRemoveFilterItem,
    clearAllFilters,
    activeTab,
    zip,
    setActiveTab = () => {},
    setIsSortOpen,
    setIsFilterOpen,
    isSortOpen,
    isFilterOpen,
    isCheckout
  } = props
  const { t } = useTranslation("dashboardMapComponent")
  return (
    <div className="map_header_container">
      <div className="map-header">
        <p className="map-header-title">{t("selectShopLabel")}</p>
        {isCheckout ? (
          <ZipEdit />
        ) : (
          <>
            <Icon name="map marker alternate" color="orange" /> <span>{zip ? zip : "-"}</span>
          </>
        )}
      </div>

      <div className="map-header">
        <ShopSortingComponent
          setSortingType={(value) => setSortingType(value)}
          sortingType={sortingType}
          setIsSortOpen={setIsSortOpen}
          isSortOpen={isSortOpen}
        />

        <ShopMapFilter
          ratingFiltersApplied={ratingFiltersApplied}
          typeFiltersApplied={typeFiltersApplied}
          addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
          clearAllFilters={clearAllFilters}
          setIsFilterOpen={setIsFilterOpen}
          isFilterOpen={isFilterOpen}
        />

        {activeTab != undefined && (
          <>
            <Popup
              mouseEnterDelay={500}
              mouseLeaveDelay={100}
              on="hover"
              disabled={window.innerWidth < 700}
              trigger={
                <div
                  className="map_header_component"
                  onClick={() => activeTab != "list" && setActiveTab("list")}
                >
                  <Icon name="unordered list" color={activeTab == "list" ? "orange" : "grey"} />
                </div>
              }
              content="List View"
              style={{ height: "20px" }}
              basic
            />

            <Popup
              mouseEnterDelay={500}
              mouseLeaveDelay={100}
              disabled={window.innerWidth < 700}
              on="hover"
              trigger={
                <div
                  className="map_header_component map_icon"
                  onClick={() => activeTab != "map" && setActiveTab("map")}
                >
                  <Icon name="map outline" color={activeTab == "map" ? "orange" : "#666666"} />
                </div>
              }
              content="Map View"
              style={{ height: "20px" }}
              basic
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MapHeaderComponent
