import React from "react"
import { Button, Card, Image } from "semantic-ui-react"
import CuvrdLogo from "../../../images/cuvrd.svg"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { numberFormater } from "../../helpers/cuvrdHelpers"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { segmentProps } from "../../constants/segmentProperties"
import { routerPaths } from "../../constants/paths"

const CurvdCard = ({ plan, handleCuvrdDialog, t, cuvrdDetail, activeVehicleId }) => {
  const trackCuvrdEvent = async (eventName) => {
    const eventPayload = {
      miles: cuvrdDetail.mileage,
      vehicleId: activeVehicleId,
      vehicleName: `${cuvrdDetail.year} ${cuvrdDetail.make} ${cuvrdDetail.model}`,
      vin: cuvrdDetail.vin,
      deductible: plan.deductible,
      total: plan.monthlySubscription,
      registrationFee: plan.registrationFee
    }
    identifyEvent({
      traits: { ...eventPayload },
      onSuccess: () => {
        trackEvent(eventName, eventPayload, false, false, false)
      }
    })
  }

  return (
    <Card className="cuvrd-card-container">
      <div className="cuvrd-card-header">
        {plan.plan && <p className="cuvrd-plan-name">{plan.plan}</p>}
        <Image src={CuvrdLogo} className="cuvrd-logo" />
      </div>
      <div className="cuvrd-properties">
        <p className="cuvrd-properties-label">{t("deductible")}:</p>
        <p className="cuvrd-properties-value">${plan.deductible || t("NA")}</p>
      </div>
      <div className="cuvrd-properties">
        <p className="cuvrd-properties-label">{t("registrationFee")}:</p>
        <p className="cuvrd-properties-value">${plan.registrationFee || t("NA")}</p>
      </div>
      <div className="cuvrd-properties">
        <p className="cuvrd-properties-label">{t("startingOdometerMileage")}:</p>
        <p className="cuvrd-properties-value">
          {cuvrdDetail.mileage ? numberFormater(cuvrdDetail.mileage) : t("NA")}
        </p>
      </div>
      <p
        className="view-cuvrd"
        onClick={async () => {
          handleCuvrdDialog(plan)
        }}
      >
        {t("viewWhatsCovered")}
      </p>
      <div className="cuvrd-total">
        <p className="cuvrd-total-label">{t("monthlySubscription")}</p>
        <p className="cuvrd-total-price">
          ${plan.monthlySubscription || t("NA")}/{t("mo")}
        </p>
      </div>
      <Link
        to={{
          pathname: routerPaths.redirect,
          state: {
            url: plan.personalized_url
          }
        }}
      >
        <Button
          onClick={() => {
            trackCuvrdEvent("vehicle-protection-plan-cuvrd-clicked")
          }}
          className="buy-btn"
        >
          {t("buyNow")}
        </Button>
      </Link>
    </Card>
  )
}

export default withTranslation("cuvrdCard")(CurvdCard)
