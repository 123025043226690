import React from "react"
import ReactSVG from "react-svg"
import DialogCrossLogo from "../../../images/rewards/cross.svg"
import MilesProgramLogo from "../../../images/rewards/milesProgram.svg"
import { withTranslation } from "react-i18next"

const EarnMilesSuccess = ({ setOpenDrawer, t }) => {
  return (
    <div className="earn-miles-success">
      <ReactSVG
        onClick={() => setOpenDrawer(false)}
        src={DialogCrossLogo}
        className="miles-dialog-cross"
      />

      <div className="miles-web">
        <ReactSVG src={MilesProgramLogo} className="miles-program-logo" />
        <div>
          <p className="congrats-label">{t("congrats")}</p>
          <p className="earn-miles">{t("unlockMiles")}</p>
          <p className="dialog-subline">{t("continueToEarnMiles")}</p>
        </div>
      </div>
    </div>
  )
}

export default withTranslation("rewards")(EarnMilesSuccess)
