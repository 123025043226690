import React from "react"
import PropTypes from "prop-types"
import Button from "../Button.js"
import MiniLoader from "../MiniLoader"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
const { BaseModalDialog } = elements

class ExtendMembershipModal extends React.Component {
  state = {
    isSubmitting: false
  }

  handleExtendMembership = async () => {
    const { extendMembership, closeModal } = this.props

    this.setState({ isSubmitting: true })
    await extendMembership()

    this.setState({ isSubmitting: false })
    closeModal()
  }

  render() {
    const { closeModal, hasPaymentMethod, t } = this.props

    return (
      <BaseModalDialog isOpen toggleModal={closeModal} headerTitle={t("header")}>
        <div style={{ margin: "15px 0px 5px" }}>{t("descriptionText1")}</div>
        <div style={{ margin: "5px" }}>{t("descriptionText2")}</div>
        {!hasPaymentMethod && <strong style={{ margin: "5px" }}>{t("descriptionText3")}</strong>}
        <Button
          disabled={!hasPaymentMethod}
          onClick={this.handleExtendMembership}
          colorStyle="orange"
          text={this.state.isSubmitting ? <MiniLoader /> : t("buttonLabel")}
          style={{ paddingTop: 5, paddingBottom: 5, marginTop: 15, marginBottom: 15 }}
        />
      </BaseModalDialog>
    )
  }
}

ExtendMembershipModal.propTypes = {
  hasPaymentMethod: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  extendMembership: PropTypes.func.isRequired
}

export default withTranslation("extendMembershipModal")(ExtendMembershipModal)
