import React, { Component } from "react"
import PropTypes from "prop-types"
import { Button } from "semantic-ui-react"

class PaymentMethodButton extends Component {
  render() {
    const { label, icon, onClick } = this.props

    return <Button basic onClick={onClick} icon={icon} />
  }
} // PaymentMethodButton

PaymentMethodButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default PaymentMethodButton
