import { GET_ACTIVE_ZIP, UPDATE_ACTIVE_ZIP } from "../constants/serviceSearch"
export function getActiveZip(options = {}) {
  return {
    type: GET_ACTIVE_ZIP
  }
}
export const updateActiveZip = (zip) => {
  return {
    type: UPDATE_ACTIVE_ZIP,
    payload: zip
  }
}
