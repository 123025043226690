const LOAD_SELECTED_VEHICLE = "LOAD_SELECTED_VEHICLE"

const initialState = {}

export default function selectedVehicle(state = initialState, action) {
  switch (action.type) {
    case LOAD_SELECTED_VEHICLE:
      return Object.assign({}, state, { selectedVehicle: action.selectedVehicle })
    default:
      return state
  }
}
