import { SHOW_NAV, HIDE_NAV } from "../constants/leftNav"
export function showNav(options = {}) {
  return {
    type: SHOW_NAV
  }
}
export function hideNav(options = {}) {
  return {
    type: HIDE_NAV
  }
}
