import React from "react"
import { Button, Image, Modal } from "semantic-ui-react"
import CartWarningLogo from "../../../images/cartWarningLogo.svg"
import CrossIcon from "../../../images/btn-delete.png"
import { withTranslation } from "react-i18next"

const CartWarningDialog = ({ t, openCarWarningDialog, handleCartWarningDialog, removeCart }) => {
  return (
    <Modal
      open={openCarWarningDialog}
      onClose={() => handleCartWarningDialog()}
      onOpen={() => handleCartWarningDialog()}
      className="cartwarning"
    >
      <Modal.Content>
        <div className="cartwarning_icon">
          <Image className="close_logo" onClick={handleCartWarningDialog} src={CrossIcon} />

          <Image className="cartwarning_logo" src={CartWarningLogo} />
        </div>
        <p className="cartwarning_header">{t("warningLabel")}</p>
        <Button className="cartwarning_confirm" onClick={removeCart}>
          {t("wantToContinue")}
        </Button>
        <Button className="cartwarning_no" onClick={handleCartWarningDialog}>
          {t("no")}
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default withTranslation("tireCartWarning")(CartWarningDialog)
