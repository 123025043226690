import React from "react"
import ReactSVG from "react-svg"
import FeatureRewardLogo from "../../../images/rewards/feature-rewards.svg"
import { useTranslation } from "react-i18next"
import { Button } from "semantic-ui-react"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import { withRouter } from "react-router-dom"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const FeatureRewards = ({ estimateEarnMiles, user, history }) => {
  const { t } = useTranslation("rewards")
  return (
    <div
      className={`feature_rewards_container ${
        user.generic && window.location.href.includes(routerPaths.rewards) ? "disable-color" : ""
      }`}
    >
      <div className="feature_rewards__header">
        <ReactSVG src={FeatureRewardLogo} />
        {estimateEarnMiles && (
          <p className="estimate_earn_miles">
            {numberFormater(estimateEarnMiles)} {t("miles")}
          </p>
        )}
      </div>
      <p className="feature_rewards__maintenance">{t("bookFirstMaintenance")}</p>
      <p className="feature_rewards__earn">{t("earnWhenBook")}</p>
      <Button
        className="feature_rewards__book"
        disabled={user.generic}
        onClick={(e) => {
          routeChange(history, routerPaths.addServices)
        }}
      >
        {t("bookNow")}
      </Button>
    </div>
  )
}

export default withRouter(FeatureRewards)
