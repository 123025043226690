import React, { Component } from "react"
import { connect } from "react-redux"
import moment from "moment"
import PropTypes from "prop-types"
import { Divider } from "semantic-ui-react"
import {
  hasNextMembershipUpgrade,
  usesMembership,
  hasPaidMembership,
  planFor,
  includesRoadside
} from "../helpers/membershipHelpers"
import { extendMembership } from "../actions/membership"
import MembershipUpgradeModal from "./Membership/MembershipUpgradeModal"
import MembershipUpgradeModal2_0 from "../../v2/components/Membership2.0/MembershipUpgradeModal.js"
import CancelMembershipModal from "./Membership/CancelMembershipModal"
import ExtendMembershipModal from "./Membership/ExtendMembershipModal"
import AccountTierBanner from "./Membership/AccountTierBanner"
import RoadsideInfoCta from "./Membership/RoadsideInfoCta"
import Button from "./Button.js"
import { GAEvent, GAEventCategories } from "../tracking/GAEvent"
import { withTranslation } from "react-i18next"
import { CARADVISE } from "../constants/affiliations"
import SplitClient, { CARADVISE_2_NAVIGATION, V1 } from "./Config/SplitClient.js"
import { getSubdomain } from "./Config/StylesheetInjector.js"

class MembershipSection extends Component {
  state = {
    membershipModalOpen: false,
    extendMembershipModalOpen: false,
    cancelMembershipModalOpen: false,
    isCarAdviseNavigation: null
  }

  async componentDidMount() {
    const { user } = this.props
    const isNavigation = await SplitClient.getTreatments([CARADVISE_2_NAVIGATION], user, {
      sub_domain: getSubdomain()
    })
    this.setState({ isCarAdviseNavigation: isNavigation[CARADVISE_2_NAVIGATION] })
  }

  formattedDate = (date) => {
    return moment(date).format("LL")
  }

  toggleCancelMembershipModal = () => {
    GAEvent(GAEventCategories.MY_ACCOUNT, "cancel-membership", "Cancel Membership")
    this.setState({ cancelMembershipModalOpen: !this.state.cancelMembershipModalOpen })
  }

  toggleMembershipUpgradeModal = () => {
    GAEvent(GAEventCategories.MY_ACCOUNT, "upgrade-membership", "Upgrade Membership")
    this.setState({ membershipModalOpen: !this.state.membershipModalOpen })
  }

  toggleExtendMembershipModal = () => {
    GAEvent(GAEventCategories.MY_ACCOUNT, "extend-trial-membership", "Extend Trial Membership")
    this.setState({ extendMembershipModalOpen: !this.state.extendMembershipModalOpen })
  }

  extendMembership = (payload) => {
    const { extendMembership, userMembership } = this.props
    return extendMembership({ ...payload, membership: userMembership })
  }

  freeTrialSection() {
    const { userMembership, t } = this.props
    if (!userMembership.is_free_trial) {
      return null
    } else if (userMembership.trial_requires_opt_in && userMembership.opted_in_to_extend_trial) {
      return <div>{t("membershipOptedInText")}</div>
    } else if (!userMembership.trial_requires_opt_in) {
      return null
    }

    return (
      <div className="underline caradvise-electric-blue" onClick={this.toggleExtendMembershipModal}>
        {t("extendTrialMembershipLbl")}
      </div>
    )
  }

  render() {
    const {
      hasNextUpgrade,
      userMembership,
      user,
      currentMembershipPlan,
      hasPaymentMethod,
      style,
      t,
      showDivider = true
    } = this.props
    const {
      membershipModalOpen,
      cancelMembershipModalOpen,
      extendMembershipModalOpen,
      isCarAdviseNavigation
    } = this.state
    const { is_free_trial, trial_expiration_date, next_billing_date } = userMembership
    const date = is_free_trial ? trial_expiration_date : next_billing_date
    const dateLabel = is_free_trial ? t("freeTrialThrough") : t("nextBillingDateLbl")

    if (!usesMembership(user)) return null

    return (
      <>
        {showDivider && <Divider />}

        <div style={{ paddingLeft: 15, ...(style || {}) }}>
          <AccountTierBanner />

          {hasPaidMembership(user) && (
            <>
              {this.freeTrialSection()}
              <div style={{ paddingTop: 5 }}>
                <strong>{dateLabel + " "}</strong>
                <span>{this.formattedDate(date)}</span>
              </div>
              <div
                className="underline caradvise-electric-blue"
                style={{ paddingTop: 15 }}
                onClick={this.toggleCancelMembershipModal}
              >
                {t("cancelMembershipLbl")}
              </div>
            </>
          )}

          {includesRoadside(currentMembershipPlan) && (
            <>
              <Divider />
              <div style={{ paddingBottom: 10 }}>
                <strong>{`${t("roadsideMemberNumber")} `}</strong>
                <span>
                  {CARADVISE.toUpperCase()}
                  {user.customerNumber}
                </span>
              </div>
              <RoadsideInfoCta />
            </>
          )}
        </div>

        <CancelMembershipModal
          isOpen={cancelMembershipModalOpen}
          closeModal={this.toggleCancelMembershipModal}
        />

        {extendMembershipModalOpen && (
          <ExtendMembershipModal
            closeModal={this.toggleExtendMembershipModal}
            hasPaymentMethod={hasPaymentMethod}
            extendMembership={this.extendMembership}
          />
        )}

        {membershipModalOpen && // wrap with the attr here to reset the modal when closed/re-opened
          (isCarAdviseNavigation == V1 ? (
            <MembershipUpgradeModal
              isOpen
              closeModal={this.toggleMembershipUpgradeModal}
              variableContentType="settingsSection"
            />
          ) : (
            <MembershipUpgradeModal2_0
              isOpen
              closeModal={this.toggleMembershipUpgradeModal}
              variableContentType="settingsSection"
            />
          ))}

        {hasNextUpgrade && (
          <Button
            style={{ marginTop: 15 }}
            colorStyle="orange"
            styleText={{ color: "white" }}
            text={t("upgradeMembershipBtn")}
            onClick={this.toggleMembershipUpgradeModal}
          />
        )}
      </>
    )
  }
}

MembershipSection.propTypes = {
  user: PropTypes.object.isRequired,
  hasNextUpgrade: PropTypes.bool.isRequired,
  userMembership: PropTypes.object.isRequired,
  currentMembershipPlan: PropTypes.object.isRequired,
  hasPaymentMethod: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const { membershipData } = state
  let user = state.user || {}
  const { availableMembershipPlans } = membershipData

  return {
    user: user,
    hasNextUpgrade: hasNextMembershipUpgrade(user, availableMembershipPlans),
    userMembership: user.membership || {},
    currentMembershipPlan: planFor(user, availableMembershipPlans),
    hasPaymentMethod: user.has_cc_info_saved
  }
}

export default connect(mapStateToProps, { extendMembership })(
  withTranslation("membershipSection")(MembershipSection)
)
