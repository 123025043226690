"use-strict"

import React, { Component } from "react"
import { connect } from "react-redux"
import DashHeader from "./components/header.js"
import LeftNav from "./components/leftNav.js"
import SideMenu from "./sideMenu"
import shopLogos from "../images/shop-logos.png"
import Button from "./components/Button"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

class PriceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      isLoading: false
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <SideMenu />
        <DashHeader />
        <div className="mainContainer">
          <LeftNav />
          <div className="rightContent">
            <div>
              <p className="introText">Member Pricing</p>
              <p>Here's a sample of our partners:</p>
              <p>
                <img src={shopLogos} className="shopLogos" alt="Shop logos" />
              </p>
              <div className="priceListContainer">
                <div className="priceListRow">
                  <div className="priceListServiceHd"></div>
                  <div className="priceListRangeHd">PRICING</div>
                  <div className="priceListSavingsHd">% SAVINGS</div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Battery Cleaning</div>
                  <div className="priceListRange">$7.99 - $15.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;73% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Check Engine Light</div>
                  <div className="priceListRange">$0 - $108.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;100% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Coolant Drain &amp; Fill</div>
                  <div className="priceListRange">$64.99 - $75.00</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;68% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Coolant Flush</div>
                  <div className="priceListRange">$69.99 - $119.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;65% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Differential Service</div>
                  <div className="priceListRange">$39.99 - $99.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;68% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">
                    Oil Change (Conventional)
                    <br />
                    <span>w/ filter up to 5 quarts</span>
                  </div>
                  <div className="priceListRange">$19.99 - $41.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;40% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">
                    Oil Change (Synthetic Blend)
                    <br />
                    <span>w/ filter up to 5 quarts</span>
                  </div>
                  <div className="priceListRange">$27.99 - $70.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;69% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">
                    Oil Change (Full Synthetic)
                    <br />
                    <span>w/ filter up to 5 quarts</span>
                  </div>
                  <div className="priceListRange">$41.99 - $102.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;72% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Tire Repair</div>
                  <div className="priceListRange">$15.50 - $27.98</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;45% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Tire Rotation</div>
                  <div className="priceListRange">$12.59 - $19.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;58% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">
                    Transmission Drain &amp; Fill
                    <br />
                    <span>(Standard or Synthetic)</span>
                  </div>
                  <div className="priceListRange">$79.99 - $120.00</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;73% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">
                    Transmission Flush
                    <br />
                    <span>(Standard or Synthetic)</span>
                  </div>
                  <div className="priceListRange">$89.99 - $139.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;69% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Vehicle Courtesy Check</div>
                  <div className="priceListRange">$0 - $47.50</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;100% off&nbsp;</strong> retail
                  </div>
                </div>
                <div className="priceListRow">
                  <div className="priceListService">Wheel Alignment</div>
                  <div className="priceListRange">$69.50 - $129.99</div>
                  <div className="priceListSavings">
                    Up to <strong>&nbsp;47% off&nbsp;</strong> retail
                  </div>
                </div>
              </div>
              <p className="priceListTxt">
                Any service not listed here is vehicle specific, please contact us for your vehicle
                pricing and/or for assistance with scheduling.
              </p>
              <p className="priceListScheduleService">
                <Button
                  text="Schedule Service"
                  colorStyle="orange"
                  iconRight={faAngleRight}
                  linkTo={"/selectCommonMaintenance"}
                />
              </p>
            </div>
            <a href="#dashboard" className="btnBack">
              &lsaquo; Back
            </a>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let activeVehicleIndex = 0
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  let activeVehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}

  return {
    isLoggedIn: !!user.authentication_token,
    user,
    activeVehicle
  }
}

export default connect(mapStateToProps, {})(PriceList)
