import React from "react"
import VerticalStepper from "../../../shared_component/elements/VerticalStepper"
import ReactSVG from "react-svg"
import { DemandBanner } from "../../../shared_component/components/Banners/DemandBanner"
import BadgeIcon from "../../../images/stepper_icons/medal-badge-icon.svg"
import { formatPhoneNumber } from "../../../v1/helpers/stringHelpers"
import { SUPPORT_CARADVISE_NUMBER } from "../../../v1/constants/application"
import { useTranslation } from "react-i18next"
import UnlockMilesConfirmed from "../Rewards/UnlockMilesConfirmed"

const ShowItemCard = ({
  showCustomeHeader = false,
  header,
  steps,
  headerIcon = null,
  subHeader = null,
  subHeaderIcon = null,
  metaText = null
}) => {
  return (
    <div className="instruction-card">
      {!showCustomeHeader ? (
        <div className="instruction-header">{header}</div>
      ) : (
        <div className="instruction-header-container">
          <ReactSVG src={headerIcon} />
          <div className="instruction-header">{header}</div>
        </div>
      )}
      {subHeader && (
        <div className="instruction-subheader-container">
          {subHeaderIcon && <ReactSVG src={subHeaderIcon} />}
          {subHeader && <div className="instruction-subheader">{subHeader}</div>}
        </div>
      )}
      {metaText && <div className="metaText-container">{metaText && metaText}</div>}
      <VerticalStepper steps={steps} />
    </div>
  )
}

const CheckInstructions = () => {
  const { t } = useTranslation("instructions")
  const steps = [
    {
      status: "completed",
      title: "Step 1",
      customIcon: "images/stepper_icons/userIcon.svg",
      showCustomIcon: true,
      description: t("membershipInstructionLabel")
    },
    {
      status: "completed",
      title: "Step 2",
      customIcon: "images/stepper_icons/mobileIcon.svg",
      showCustomIcon: true,
      description: t("serviceUpdatesLabel")
    },
    {
      status: "completed",
      title: "Step 3",
      customIcon: "images/stepper_icons/warningIcon.svg",
      showCustomIcon: true,
      description: t("doNotPayLabel")
    }
  ]
  return <ShowItemCard header="Check in Instruction" steps={steps} />
}

const MilesInstructions = () => {
  const { t } = useTranslation("instructions")
  const steps = [
    {
      status: "completed",
      title: "Step 1",
      customIcon: "images/stepper_icons/calenderIcon.svg",
      showCustomIcon: true,
      description: t("repairAppointmentLabel")
    },
    {
      status: "completed",
      title: "Step 2",
      customIcon: "images/stepper_icons/editIcon.svg",
      showCustomIcon: true,
      description: t("previousMileageLabel")
    },
    {
      status: "completed",
      title: "Step 3",
      customIcon: "images/stepper_icons/checkIcon.svg",
      showCustomIcon: true,
      description: t("newMilesLabel")
    }
  ]
  return (
    <ShowItemCard
      header="Unlock 235,000 Miles"
      steps={steps}
      showCustomeHeader={true}
      headerIcon="images/stepper_icons/miles-lock-Icon.svg"
      subHeader="Don't forget to remind the shop to save your current mileage into our log."
      subHeaderIcon="images/stepper_icons/warning_circle_black.svg"
      metaText="Verify your current mileage at the shop when taking your vehicle for maintenance or repair:"
    />
  )
}

const AboutPricingCard = () => {
  const { t } = useTranslation("reviewOrder")
  return (
    <div className="about-pricing-container">
      <DemandBanner iconUrl={BadgeIcon} titleText={t("alwaysBetterLabel")} />
      <div className="contact-us-section">
        <p className="text">{t("contactUsForPricingLabel")} </p>
        <p className="contact-section">{formatPhoneNumber(SUPPORT_CARADVISE_NUMBER)}</p>
      </div>
      <a className="read-more-link" href="https://caradvise.com/faq/" target="_blank">
        {t("readMoreLabel")}
      </a>
    </div>
  )
}
const InstructionItemCards = (props) => {
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  return (
    <div className="instruction-card-container">
      <CheckInstructions />
      {/* commenteed for next implementation */}
      {/* <MilesInstructions /> */}
      {isRewardsFeature && <UnlockMilesConfirmed />}
      <AboutPricingCard />
    </div>
  )
}

export default InstructionItemCards
