import _, { sortBy } from "lodash"
import React, { Component } from "react"
import { updateCart } from "../../../../actions/user"
import { MenuProvider, Menu, Item } from "react-contexify"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DimmerLoader from "../../../../components/DimmerLoader"
import { translatedPosition } from "../../../../helpers/translationHelpers"

import { getJSON } from "../../../../../shared_component/utils/fetch"
import { findOilPositionIndex, OIL_CHANGE_REFERENCE_ID } from "../../../helpers/orderServiceHelpers"

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { COMMON_SERVICES } from "./CommonServicesData"
import { ORANGE } from "../../../constant/color"

const SERVICES_URL = "/api/v4/services/service_hierarchy"

class CommonServiceCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warningModalOpen: false,
      warningModalEvent: null,
      notificationModalOpen: false,
      multiSelectServiceModalOpen: false,
      multiSelectService: null,
      loadingServiceDefinitionId: null,
      isCallMatchingServicesAPI: true,
      commonServices: COMMON_SERVICES
    }
  }
  async componentDidMount() {
    let popularService = {
      image: require("../../../images/caradvise2/tire_rotation.svg"),
      translationKey: "newTiresTitle",
      selected: false,
      referenceId: "new_tires",
      isGroup: false,
      link: "/newTires"
    }

    const { modalNotifications } = this.props

    const exisingServices = this.state.commonServices
    let commonServices = [
      ...exisingServices.slice(0, 6),
      popularService,
      ...exisingServices.slice(6)
    ]

    this.setState({ commonServices: commonServices })
  }
  async componentDidUpdate() {
    const { user } = this.props
    const { isCallMatchingServicesAPI } = this.state
    if (isCallMatchingServicesAPI && user && user.authentication_token) {
      await this.setState({ isCallMatchingServicesAPI: false })
      this.getMatchingServices()
    }
  }
  async getMatchingServices(latitude = null, longitude = null) {
    let params = { common: true }

    if (longitude && latitude) {
      params = { ...params, longitude, latitude }
    }
    // TODO: move this to /actions/services
    await getJSON(SERVICES_URL, params, {
      Authorization: (this.props.user || {}).authentication_token
    }).then(async (responseData) => {
      let servicesFromResponse = []
      if (responseData && responseData.result && responseData.result.services)
        servicesFromResponse = sortBy(responseData.result.services, (service) =>
          (service.name || "").toLowerCase()
        )
      //only show popular on first screen

      // Set each common service with its matching service from the server

      const { commonServices } = this.state
      servicesFromResponse.forEach((apiService) => {
        commonServices.find((commonService) => {
          if (commonService.isGroup) {
            const matchingService = commonService.groupReferenceIds.find(
              (referenceId) => apiService.reference_id === referenceId
            )

            if (matchingService) {
              if (!commonService.matchingServices) commonService.matchingServices = []
              commonService.matchingServices.push(apiService)
            }
          } else {
            if (apiService.reference_id === commonService.referenceId) {
              commonService.matchingService = apiService
            }
          }
        })
      })
      this.setState({ commonServices: commonServices })
    })
  }

  renderServicesGrid() {
    const serviceGridRows = []
    let serviceRow = []

    this.state.commonServices.forEach((commonService) => {
      const { addedServices, activeVehicle, t } = this.props
      const matchingService = commonService.matchingService || {}

      let selected = false
      if (commonService.isGroup) {
        selected = !!addedServices.find((addedService) => {
          const matchingServices = commonService.matchingServices || []
          return matchingServices.find((service) => service.id === addedService.id)
        })
      } else if (addedServices.find((service) => service.id === matchingService.id)) {
        selected = true
      }

      const serviceHasPositions =
        matchingService.service_options &&
        matchingService.service_options.length > 0 &&
        matchingService.service_options[0].positions.length > 0
      const showItems = !selected && serviceHasPositions

      const items = showItems
        ? matchingService.service_options[0].positions.map((p, i) => {
            const position = translatedPosition({ position: p, t: t })
            return (
              <Item key={`service-options-${i}`} onClick={this.onClick} data={{ position: p }}>
                {p === (activeVehicle && activeVehicle.oil_type_name)
                  ? `${position} ${t("manufacturerRecommendedText")}`
                  : position}
              </Item>
            )
          })
        : null

      serviceRow.push(
        showItems ? (
          <div>
            <MenuProvider id={commonService.referenceId} event="onClick" data={commonService}>
              {this.renderServicesGridItem(commonService)}
            </MenuProvider>
            <Menu id={commonService.referenceId}>{items}</Menu>
          </div>
        ) : (
          <div onClick={this.handleSelectServicesGridItem.bind(this, commonService, null)}>
            {this.renderServicesGridItem(commonService, selected)}
          </div>
        )
      )
    })

    return <div className="services-dashboard-grid">{serviceRow.slice()}</div>
  }

  renderServicesGridItem(commonService = {}, selected) {
    const matchingService = commonService.matchingService || {}
    const loadingService = this.state.loadingServiceDefinitionId === matchingService.id

    return (
      <a
        href={`#${commonService.link}`}
        onClick={(e) => e.preventDefault()}
        className="services-dashboard-grid-item"
        style={{ position: "relative", borderColor: selected ? ORANGE : null }}
      >
        {loadingService && <DimmerLoader />}
        <FontAwesomeIcon
          style={selected ? null : { display: "none" }}
          icon={faCheckSquare}
          size={"2x"}
          className="services-dashboard-grid-item-checkbox"
        />
        <div className="services-dashboard-icon-container">
          <img src={commonService.image} className="service-dashboard-icons" />
        </div>
        <div className="services-dashboard-grid-item-title">
          <p className="services-name">
            {this.props.t(commonService.translationKey)}
            <br />
            <p className="services-price">from $32</p>
          </p>
        </div>
      </a>
    )
  }

  onClick = ({ event, props }) => this.handleSelectServicesGridItem(props)

  updateCart = (events) => {
    const { vehicleId } = this.props
    const { loadingServiceDefinitionId } = this.state
    const serviceDefinitionId = events[0] && events[0].service_definition_id
    if (loadingServiceDefinitionId) return

    this.setState({ loadingServiceDefinitionId: serviceDefinitionId }, () => {
      this.props.updateCart(events, vehicleId, null, true, {
        callback: () => this.setState({ loadingServiceDefinitionId: null })
      })
    })
  }

  handleSelectServicesGridItem = (commonService) => {
    const { matchingService, position } = commonService

    if (commonService.isGroup) {
      this.setState({
        multiSelectServiceModalOpen: true,
        multiSelectService: commonService
      })
      return
    }

    if (matchingService) {
      this.handleTogglingService(matchingService, position)
    }
  }

  handleTogglingService = (matchingService, position) => {
    const { activeVehicle } = this.props
    const alreadySelected = this.props.addedServices.find(
      (addedService) => addedService.id === matchingService.id
    )
    if (alreadySelected) {
      // Remove the service
      this.updateCart([{ event: "removed", service_definition_id: matchingService.id }])
      return
    }

    let event = {
      event: "added",
      service_definition_id: matchingService.id
    }

    if (position !== undefined) {
      event.position = position
      event.service_option_id = matchingService.service_options[0].service_definition_id
    }
    if (matchingService.reference_id === OIL_CHANGE_REFERENCE_ID) {
      const selectedPositionOrder = findOilPositionIndex(event.position)
      const recommendedPositionOrder = findOilPositionIndex(this.props.activeVehicle.oil_type_name)

      if (selectedPositionOrder < recommendedPositionOrder) {
        this.setState({ warningModalEvent: event, warningModalOpen: true })
      } else {
        this.updateCart([event])
      }
    } else {
      this.updateCart([event])
    }
  }
  render() {
    return <div className="dashBoard2_o__hero__commonServices">{this.renderServicesGrid()}</div>
  }
}

export function mapStateToProps(state) {
  let user = state.user || {}
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
  let addedServices = cart ? cart.order_services : []
  let activeVehicle = user.vehicles && user.vehicles.filter((v) => v.id === user.activeVehicleId)[0]
  let modalNotifications = state.modalNotifications

  return {
    user,
    authentication_token: user.authentication_token,
    vehicleId: user.activeVehicleId,
    activeVehicle,
    addedServices,
    cart: cart || {},
    disableShopChoice: cart && cart.disable_shop_choice,
    modalNotifications
  }
}

const CommonServiceCards = connect(mapStateToProps, { updateCart })(
  withTranslation(["selectCommonMaintenance", "common"])(CommonServiceCard)
)

export default CommonServiceCards
