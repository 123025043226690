import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactSVG from "react-svg"
import { Button, Image } from "semantic-ui-react"
import MagicLinkError from "../../../images/magic-link/magic-link-warning.svg"
import MagicIcon from "../../../images/icons/magic_icon.svg"
import { OPTIONS } from "../../constants/common"

class ErrorRedirectComponent extends Component {
  render() {
    const { redirectionType, redirectError, contentComponent, t } = this.props
    return (
      <div className="magic-link-redirect-container error">
        <div className="magic-link-type-description">
          <Image src={MagicLinkError} />
          <div className="magic-link-header-text error">{redirectError}</div>
          <div className="magic-link-text error">
            {redirectionType === OPTIONS.EMAIL ? t("enterEmailLabel") : t("enterNumberLabel")}
          </div>
        </div>
        {contentComponent && contentComponent}
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, {})(withTranslation("signIn")(ErrorRedirectComponent))
