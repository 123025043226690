import React from "react"

const RewardTagFilter = ({ tag, selectedTag, setSelectedTag }) => {
  return (
    <div
      className={`reward-tag-filter ${
        tag === selectedTag ? "reward-selected-tag-color" : "reward-tag-filter-color"
      }`}
      onClick={() => {
        setSelectedTag(tag)
      }}
    >
      {tag}
    </div>
  )
}

export default RewardTagFilter
