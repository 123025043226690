import i18n from "../../shared_component/utils/i18n"

export const RNS_SHOW_NOTIFICATION = "RNS_SHOW_NOTIFICATION"
export const RNS_HIDE_NOTIFICATION = "RNS_HIDE_NOTIFICATION"
export const RNS_REMOVE_ALL_NOTIFICATIONS = "RNS_REMOVE_ALL_NOTIFICATIONS"

export function show(options = {}, level = "success") {
  return {
    type: RNS_SHOW_NOTIFICATION,
    position: "tr",
    autoDismiss: 5,
    action: { label: "Dismiss" },
    ...options,
    uid: options.uid || Date.now(),
    level
  }
}

export function success(options) {
  return show({ title: i18n.t("common:notificationSuccessTitle"), ...options }, "success")
}

export function info(options) {
  return show(options, "info")
}

export function warning(options) {
  return show(options, "warning")
}

export function error(options) {
  return show({ title: i18n.t("common:notificationErrorTitle"), ...options }, "error")
}

export function hide(uid) {
  return {
    type: RNS_HIDE_NOTIFICATION,
    uid
  }
}

export function removeAll() {
  return { type: RNS_REMOVE_ALL_NOTIFICATIONS }
}
