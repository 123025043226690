import React from "react"
import ShopSearch from "./Shop/ShopSearch"
import DateTimePicker from "./DateTimePicker"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { ACTIVE_VIEWS } from "../../../shared_component/constants/checkout"

const ScheduleIndex = (props) => {
  const { cart, t, dateTimeSelected, showMap, showMapFlag } = props

  return (
    <div className="schedule-index-container">
      <div className="schedule-container">
        {cart && cart.shop && !showMap && !showMapFlag ? (
          <DateTimePicker shop={cart.shop} dateTimeSelected={dateTimeSelected} />
        ) : (
          <ShopSearch activeView={ACTIVE_VIEWS.LIST} />
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let showMapFlag = (state.flag && state.flag.showMapFlag) || false
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    showMapFlag
  }
}
export default connect(mapStateToProps)(withTranslation("schedule")(ScheduleIndex))
