import React from "react"
import { Image } from "semantic-ui-react"

const ShopDetailConfirmScreen = ({ shopImage, appointmentTime, shopName, shopAddress }) => {
  return (
    <div className="shop-container">
      <div className="shop-img">
        <Image src={shopImage} className="shop-logo" />
      </div>
      <div className="shop-details">
        <p className="shop-name">{shopName}</p>
        <p className="shop-info">{shopAddress}</p>
        <p className="shop-info">{appointmentTime}</p>
      </div>
    </div>
  )
}

export default ShopDetailConfirmScreen
