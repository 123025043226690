import { SET_EVENT_NAMES } from "../actions/segment"

export default function segment(state = {}, action = {}) {
  switch (action.type) {
    case SET_EVENT_NAMES:
      return { ...state, eventNames: action.payload }
    default:
      return state
  }
}
