import React from "react"
import ReactSVG from "react-svg"
import { isCarPerksSignUp } from "./../../helpers/carPerksHelpers"
import store from "../../../shared_component/utils/configureStore"
import { logoutUser } from "./../../actions/user"
import { HOSTED_IMAGE_BASE_URL } from "./../../constants/paths"
import SignUpAppBanner from "./SignUpAppBanner"
import { CLEARCOVER, GASBUDDY } from "../../constants/affiliations"
import CompanyLogoHeader from "./component/CompanyLogoHeader"
import CarAdviseLogoWhite from "../../../images/CarAdvise-LogoWhite.svg"

const SignUpContainer = (props) => {
  const isCarPerks = isCarPerksSignUp()
  const params = new URLSearchParams(`?${window.location.href.split("?")[1]}`)
  const affiliation = params.get("affiliation")
  const bannerHide = [GASBUDDY, CLEARCOVER].includes(affiliation)
  const step = params.get("step")

  React.useEffect(() => {
    if (isCarPerks && step === "1") {
      store.dispatch(logoutUser())
    }
  }, [])

  return (
    <div>
      <div className="responsive-small-signup">
        <div>
          <div className="signup-container">
            <CompanyLogoHeader />

            {props.children}
          </div>
        </div>
      </div>

      <div className="responsive-large-signup">
        <div>
          <div className="signup-container">
            {affiliation ? (
              <BrandedSignupLeft affiliation={affiliation} />
            ) : (
              <div className="signup-left">
                <ReactSVG
                  src={`/images/session-info-image.svg`}
                  style={{
                    position: "relative",
                    height: "100vh",
                    left: "50%",
                    width: "50vw",
                    transform: "translateX(-50%)"
                  }}
                />
              </div>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

const BrandedSignupLeft = ({ affiliation }) => {
  const fallbackImage = (
    <img
      style={{ maxWidth: "50%", margin: "auto" }}
      src={`${HOSTED_IMAGE_BASE_URL}${affiliation}_logo.png`}
      alt=""
    />
  )
  return (
    <div className="signup-left branded">
      <div>
        <ReactSVG
          fallback={() => fallbackImage}
          src={`${HOSTED_IMAGE_BASE_URL}${affiliation}_logo-light.svg`}
        />
      </div>
      <div>
        <ReactSVG svgClassName="ca-logo" src={CarAdviseLogoWhite} />
      </div>
    </div>
  )
}

export default SignUpContainer
