export const BASIC = "Basic"
export const PREMIUM = "Premium"
export const ELITE = "Elite"
export const PLAN_HIERARCHY = [BASIC, PREMIUM, ELITE]

export function currentlyAtMaxVehicles(user) {
  if (!user.uses_membership || user.membership == null || user.vehicles == null) {
    return false
  }

  return user.vehicles.length >= user.membership.max_num_vehicles
}

export function usesMembership(user) {
  return user && !user.generic && user.uses_membership && user.membership
}

export function nextMembershipUpgrades(user, availableMembershipPlans) {
  if (!usesMembership(user) || [{}, null, undefined].includes(user.membership)) return []

  const currentPlanName = user.membership && user.membership.plan_name
  const currentPlanIndex = PLAN_HIERARCHY.indexOf(currentPlanName)
  const upgradePlanNames = [...PLAN_HIERARCHY].filter((_, i) => currentPlanIndex < i)

  return withPreviousTier(availableMembershipPlans || []).filter((memPlan) =>
    upgradePlanNames.includes(memPlan.name)
  )
}

export function maxPercentDiscountDiff(membership, nextUpgrades) {
  if (!membership || !nextUpgrades) return null

  const currentDiscount = membership.percent_discount || 0
  const greatestNextDiscount =
    Math.max(...nextUpgrades.map((u) => parseFloat(u.percent_discount))) || 0

  return greatestNextDiscount - currentDiscount
}

function withPreviousTier(plans) {
  const sortedPlans = sortedByHierarchy(plans || [])

  return sortedPlans.map((memPlan, index) => {
    let previous = {}
    if (index > 0) previous = sortedPlans[index - 1]

    return { ...memPlan, previousTier: previous }
  })
}

function sortedByHierarchy(plans) {
  if (!plans) return []

  return PLAN_HIERARCHY.map((name) => plans.find((p) => p.name === name)).filter((p) => p != null)
}

export function hasNextMembershipUpgrade(user, availableMembershipPlans) {
  return usesMembership(user) && nextMembershipUpgrades(user, availableMembershipPlans).length > 0
}

export function plansSortedByNameArray(plans, nameArray = ["elite", "premium", "basic"]) {
  const sortedPlans = plans.sort(function (a, b) {
    return nameArray.indexOf(a.name.toLowerCase()) - nameArray.indexOf(b.name.toLowerCase())
  })

  return sortedPlans
}

export function planFor(user, availableMembershipPlans) {
  if (!usesMembership(user)) return {}

  return withPreviousTier(availableMembershipPlans).find(
    (p) => p.name === user.membership.plan_name
  )
}

export function hasPaidMembership(user) {
  if (!usesMembership(user)) return false

  const { membership } = user
  return !!(membership.price && parseFloat(membership.price) > 0)
}

export function isBasic(plan) {
  return plan && plan.name === BASIC
}

export function isPremium(plan) {
  return plan && plan.name === PREMIUM
}

export function isElite(plan) {
  return plan && plan.name === ELITE
}

export function includesRoadside(plan) {
  return plan && plan.includes_roadside
}
