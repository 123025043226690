import React from "react"
import { Form, Image, Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import IconWhite from "../../images/IconWhite.png"
import { withTranslation } from "react-i18next"

function ClearScreen({ t }) {
  return (
    <>
      <div className="confirm-section">
        <div className="confirmDetails">
          <div className="step-title">
            <Link to="/top-tires">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <h3>
              {t("Step_I")}: {t("Confirm_your_tires")}
            </h3>
          </div>
          <div className="circleStyle">
            <Image src={IconWhite} className="circle-img" />
          </div>
          <div className="clearTxt">{t("You_have_no_tires")}</div>
          <div className="form-parent form-margin">
            <Form className="no-tires">
              <p>{t("Step_I")}</p>
              <h3>{t("Confirm_your_tires")}</h3>
              <hr />
              <div id="parent">
                <div className="col">{t("Subtotal")}</div>
                <div className="col">$0.00</div>
              </div>
              <div id="parent">
                <div className="col">{t("TOTAL")}</div>
                <div className="col amount-red">$0.00</div>
              </div>
              <div className="btn-parent">
                <Button className="shop-btn">
                  {t("Next")}: {t("Choose_installer")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation("clearScreen")(ClearScreen)
