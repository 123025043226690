import React from "react"
import ReactSVG from "react-svg"

const ButtonWithIcon = ({ logo, btnLabel, onClick }) => {
  return (
    <button className="btn-body" onClick={onClick}>
      <ReactSVG src={logo} />
      <p className="btn-text">{btnLabel}</p>
    </button>
  )
}

export default ButtonWithIcon
