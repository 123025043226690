import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown, faCheck } from "@fortawesome/free-solid-svg-icons"
import { getStyles } from "../../../v1/components/Config/StylesheetInjector"
import SearchServicesPriceRange from "./SearchServicesPriceRange"
import { useTranslation } from "react-i18next"
import AddCartButtons from "./AddCartButtons"
import ReactSVG from "react-svg"
import store from "../../../shared_component/utils/configureStore"
import { updateCart } from "../../../v1/actions/user"
import ServicePositionSelector from "./ServicePositionSelector"
import MiniLoader from "../../../v1/components/MiniLoader"
import ServiceNoPriceExplanation from "./ServiceNoPriceExplanation"
import { Image } from "semantic-ui-react"
import CarAdviseLogo from "../../../images/CarAdvise-Logo.svg"
import TireLogo from "../../../images/new_tires_logo.svg"
import { routerPaths } from "../../../v1/constants/paths"
import { REFERENCE_ID } from "../../../shared_component/constants/common"
import { connect } from "react-redux"
import { saveTireSizeDetails } from "../../../v1/actions/tires"
import TireSearch from "./TireSearch"
import TireAtShopLogo from "../../../images/tire-at-shop.svg"
import PriceTagLogo from "../../../images/price-tag.svg"
import { serviceSelectedEvents } from "../../../shared_component/utils/googleAnalyticsHelpers"
import RemoveServiceFromCart from "./RemoveServiceFromCart"

const styles = getStyles()

function ServiceListItem({
  serviceDefinition,
  selected,
  closeItem,
  shop,
  hasActiveOrder,
  activeVehicle,
  isPresentInCart,
  os,
  handleRedirect,
  saveTireSizeDetails,
  isCart,
  order
}) {
  const sd = serviceDefinition
  const [position, setPosition] = useState(isPresentInCart ? os.position : sd.position)
  const [isLoading, setLoading] = useState(false)
  const [isTireInCart, setIsTireInCart] = useState(false)
  const canPosition =
    serviceDefinition.service_options && serviceDefinition.service_options.length > 0
  const { t } = useTranslation("browseServices")
  const remove = async () => {
    setLoading(true)
    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id
    }
    await store.dispatch(
      updateCart(
        [
          {
            event: "removed",
            service_definition_id: serviceDefinition.id
          }
        ],
        null,
        true,
        undefined,
        opts
      )
    )
    if (sd && sd.position) {
      setPosition(sd.position)
    }
    setLoading(false)
    setIsTireInCart(false)
  }

  const addTireToCart = async (serviceDefinition) => {
    setIsTireInCart(true)

    let opts = {
      successCallback: async () => {
        await serviceSelectedEvents({ serviceDefinition, position, activeVehicle })
      }
    }

    await store.dispatch(
      updateCart(
        [
          {
            event: "added",
            position: position,
            service_definition_id: serviceDefinition.id
          }
        ],
        null,
        undefined,
        true,
        opts
      )
    )
    const events = new CustomEvent("cartSidePanelOpen", { detail: true })
    document.dispatchEvent(events)
    setIsTireInCart(false)
  }

  const handleClick = () => {
    if (
      [routerPaths.topTires, routerPaths.tireSize].some((path) =>
        window.location.href.includes(path)
      )
    ) {
      return
    }
    saveTireSizeDetails()
    handleRedirect(routerPaths.tireSize)
  }

  return (
    <div className="search-service-container">
      {sd.reference_id === REFERENCE_ID.NEW_TIRES ? (
        <>
          <div className="new-tire-container">
            <TireSearch
              handleClick={() => addTireToCart(sd)}
              serviceHeader={
                <>
                  {t("buyNew")} <span>{t("tires")}</span> {t("atShop")}
                </>
              }
              className={!isPresentInCart ? "custom-container" : ""}
              service={t("buyTireAtShop")}
              tireLogo={TireAtShopLogo}
              serviceSubLine={t("bookNowBuyLater")}
              tireNote={t("priceAtShop")}
              tireNoteLogo={PriceTagLogo}
              isTireInCart={isTireInCart}
              setIsTireInCart={setIsTireInCart}
              isCartService={true}
              remove={remove}
              isPresentInCart={isPresentInCart}
              t={t}
            />
          </div>
          {!isPresentInCart && (
            <div className="new-tire-container">
              <TireSearch
                handleClick={handleClick}
                serviceHeader={
                  <>
                    {t("new")} <span>{t("tiresFromCarAdvise")}</span>
                  </>
                }
                brandLogo={CarAdviseLogo}
                service={t("buyNewTireLabel")}
                tireLogo={TireLogo}
                serviceSubLine={t("getDeal")}
                t={t}
              />
            </div>
          )}
        </>
      ) : (
        <div className="search-service-header-section">
          <div className="service-container">
            <FontAwesomeIcon
              className="caret-icon"
              icon={selected ? faCaretUp : faCaretDown}
              onClick={closeItem}
            />
            <div className="service-name">{sd.name}</div>
          </div>
          <div className="price-range-section">
            {isPresentInCart ? (
              <RemoveServiceFromCart isLoading={isLoading} handleRemove={remove} t={t} />
            ) : (
              <SearchServicesPriceRange
                highPriceEstimate={sd.high_price_estimate}
                lowPriceEstimate={sd.low_price_estimate}
                shop={shop}
                activeVehicle={activeVehicle}
              />
            )}
          </div>
        </div>
      )}
      <>
        {selected && sd.reference_id !== REFERENCE_ID.NEW_TIRES && (
          <div className="search-services-expanded">
            <ServiceNoPriceExplanation priceEstimate={sd.na_price_estimate} />
            {canPosition && (
              <ServicePositionSelector
                serviceDefinition={sd}
                setPosition={setPosition}
                position={position}
                shop={shop}
                os={os}
                activeVehicle={activeVehicle}
                isPresentInCart={isPresentInCart}
              />
            )}

            <AddCartButtons
              serviceDefinition={sd}
              position={position}
              shop={shop}
              os={os}
              isPresentInCart={isPresentInCart}
              activeVehicle={activeVehicle}
              isCart={isCart}
              order={order}
            />
          </div>
        )}
      </>
    </div>
  )
}

export default connect(null, {
  saveTireSizeDetails
})(ServiceListItem)
