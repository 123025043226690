import Notifications from "../components/Notifications"
import { getJSON, postJSON } from "../../shared_component/utils/fetch"

export const LOAD_PAYMENT_INFO = "LOAD_PAYMENT_INFO"
export const LOAD_ORDER_ID = "LOAD_ORDER_ID"
export const SET_AFFIRM_DEFAULT = "SET_AFFIRM_DEFAULT"
export const SET_CARADVISE_CASH = "SET_CARADVISE_CASH"
const AVAILABLE_CREDIT_URL = "/api/v5/rewards/available_credit"
const GIFT_REWARD_URL = "/api/v5/rewards"

export const setPaymentInfo = (paymentInfo) => {
  return {
    type: LOAD_PAYMENT_INFO,
    paymentInfo
  }
}
export const setOrderId = (orderId) => {
  return {
    type: LOAD_ORDER_ID,
    orderId
  }
}

export const setAffirmDefaultValue = (default_value) => {
  return {
    type: SET_AFFIRM_DEFAULT,
    default_value
  }
}

export function giftCaradviseCash(recipientEmail, giftAmount) {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user

    const response = await postJSON(
      GIFT_REWARD_URL,
      { email: recipientEmail, gift_amount: giftAmount },
      { Authorization: authentication_token }
    )

    if (response.error) {
      dispatch(Notifications.error({ message: response.error }))
    } else {
      dispatch(Notifications.success({ message: response.result.message }))
    }
  }
}

function setCaradviseCash(cash) {
  return { type: SET_CARADVISE_CASH, payload: cash }
}

export function getCaradviseCash() {
  return async function (dispatch, getState) {
    const user = getState().user || {}
    const { authentication_token } = user
    const response = await getJSON(
      AVAILABLE_CREDIT_URL,
      {},
      { Authorization: authentication_token }
    )

    if (response && response.result) {
      dispatch(setCaradviseCash(response.result.available_credit))
    }
  }
}
