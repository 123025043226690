import React from "react"
import EmptyCalenderLogo from "../../../images/empty_calendar.svg"
import { Image } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { datePickerLocale } from "../../../v1/helpers/translationHelpers"
import moment from "moment"
import { DATE_TIME_FORMAT } from "../../../v1/constants/application"
import { isEbayCart } from "../../../v1/helpers/affiliationHelpers"
import { parseInt } from "lodash"
import { getNewTiresServiceFromServices } from "../../../v1/helpers/orderServiceHelpers"
import { getJSON } from "../../../shared_component/utils/fetch"
import ItemSelector from "../../../v1/components/ItemSelector"
import { TimeShimmer } from "./TimeShimmer"
import { findNextAvailableDate, isDayDisabled } from "../../../shared_component/utils/dateHelpers"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const resetState = {
  date: null,
  timeSlots: null,
  time: null,
  dateTimesHash: {},
  loading: false,
  loadingMessage: "",
  slotLoading: false
}
class DateTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...resetState
    }
  }

  componentDidMount() {
    const { cart } = this.props
    let date =
      cart && cart.appointment_datetime
        ? moment(cart.appointment_datetime, moment.defaultFormat).utc().toDate()
        : moment(new Date(), moment.defaultFormat).add(1, "day").utc().toDate()

    if (cart && !cart.appointment_datetime) {
      date = findNextAvailableDate(date)
    }
    this.onDateChange(date)
  }

  componentDidUpdate(prevProps) {
    const { cart } = this.props
    let date =
      cart && cart.appointment_datetime
        ? moment(cart.appointment_datetime, moment.defaultFormat).utc().toDate()
        : moment(new Date(), moment.defaultFormat).add(1, "day").utc().toDate()

    if (this.props.shop && prevProps.cart.shop) {
      if (this.props.shop.id !== prevProps.cart.shop.id) {
        this.onDateChange(date)
        this.resetState()
      }
    }
  }

  resetState = () => {
    this.setState({
      ...resetState
    })
  }

  onDateChange = async (date) => {
    if (this.props.dateTimeSelected) {
      await this.props.dateTimeSelected(null)
    }
    if (!moment(date).isSame(this.state.date, "day")) {
      await this.setState({ date: date, slotLoading: true })
      let time_data = await this.fetchTimeSlots(date)

      await this.setTimeSlots(date, time_data)
      await this.setState({ slotLoading: false })
    }
  }

  onSelect = async (value) => {
    const { t } = this.props
    await this.setState({ time: value })
    await this.setLoadingState(t("updatingAppointmentTimeLoadingMessage"))
    if (this.props.dateTimeSelected) {
      await this.props.dateTimeSelected(value)
    }
    await this.clearLoadingState()
  }

  fetchTimeSlots = async (date) => {
    const { t } = this.props
    await this.setLoadingState(t("RetrievingShopTimeLoadingMessage"))
    //Check the has first
    const dateTimesKey = moment(date).format(DATE_TIME_FORMAT.YYYY_MM_DD)
    let data = this.state.dateTimesHash[dateTimesKey] || (await this.fetchTimeSlotsFromServer(date))

    await this.clearLoadingState()

    return data && data.times ? data.times : null
  }

  fetchTimeSlotsFromServer = async (date) => {
    let { user } = this.props
    const date_string = moment(date).format(DATE_TIME_FORMAT.YYYYMMDD)

    const authentication_token = this.props.user.authentication_token

    const orderLikeObject = this.fetchOrderLikeObject(user)
    const shopId =
      orderLikeObject && orderLikeObject.shop ? orderLikeObject.shop.id : orderLikeObject.shop_id

    if (shopId === null || shopId === undefined) {
      return
    }

    let orderLikeIdParam

    if (orderLikeObject.status !== undefined) orderLikeIdParam = `&order_id=${orderLikeObject.id}`
    else orderLikeIdParam = `&cart_id=${orderLikeObject.id}`

    let response = await getJSON(
      `/api/v4/shops/${shopId}/schedule?date=${date_string}&days=1${orderLikeIdParam}`,
      {},
      { Authorization: authentication_token }
    )

    let data = response.result && response.result[0] ? response.result[0] : null
    const dateTimesKey = moment(date).format(DATE_TIME_FORMAT.YYYY_MM_DD)
    let dateTimesHash = { ...this.state.dateTimesHash }
    dateTimesHash[dateTimesKey] = data
    await this.setState({ dateTimesHash: dateTimesHash })

    return data
  }

  setTimeSlots = async (date, times) => {
    const { t } = this.props
    const orderLikeObject = this.fetchOrderLikeObject()
    const walkInsOnly = (
      (orderLikeObject && orderLikeObject.shop && orderLikeObject.shop.company) ||
      {}
    ).walk_ins_only
    const walkIn = {
      formatted_time: t("shopAppointmentPicker:walkInText"),
      time: moment(date).set({ hour: "3", minute: "00" })
    }

    if (walkInsOnly) {
      await this.setState({ timeSlots: [walkIn], time: null })
    } else if (times) {
      let timesData = times.map((tm) => {
        let hour_min = tm.split(":")
        let hour = parseInt(hour_min[0])
        let min = parseInt(hour_min[1])
        const dt = moment(date).set({ hour: hour, minute: min })
        if (this.props.i18n.language === "en") {
          let ampm = DATE_TIME_FORMAT.AM
          if (hour === 12) {
            ampm = DATE_TIME_FORMAT.PM
          } else if (hour > 12) {
            hour = hour - 12
            ampm = DATE_TIME_FORMAT.PM
          } else if (hour === 0) {
            hour = 12
          }
          return { formatted_time: `${hour}:${hour_min[1]} ${ampm}`, time: dt }
        } else {
          return { formatted_time: `${hour}:${hour_min[1]}`, time: dt }
        }
      })

      if (this.enableWalkIn(date)) timesData.unshift(walkIn)

      await this.setState({ timeSlots: timesData, time: null })
    } else {
      const timeSlots = this.enableWalkIn(date) ? [walkIn] : null
      await this.setState({ timeSlots: timeSlots, time: null })
    }
  }

  render() {
    const { cart, t, dateTimeSelected } = this.props
    const { slotLoading } = this.state
    const date_str = this.state.date ? moment(this.state.date).format(DATE_TIME_FORMAT.LLLL) : ""
    const emptyMessage =
      this.state.date && !this.state.loading
        ? `${t("shopAppointmentPicker:noTimeAvailableText")} ${date_str}`
        : this.state.loading
        ? t("shopAppointmentPicker:loadingMessage")
        : t("shopAppointmentPicker:selectDateFirstLbl")

    let minDate = cart && cart.valid_at ? moment(cart.valid_at) : moment()
    if (minDate < moment()) minDate = moment()
    // const selectedDate = cart && cart.appointment_datetime && moment(cart.appointment_datetime, moment.defaultFormat).toDate()
    return (
      <div className="shop-slect-date-time-container">
        <div className="date-time-header">
          <p className="select-header">{t("selectDateTimeLabel")}</p>
          {cart && !cart.shop && <p className="select-shop-label">{t("noShopSelectLabel")}</p>}
        </div>
        {cart && !cart.shop ? (
          <div className="no-shop-select-image-container">
            <Image src={EmptyCalenderLogo} />
          </div>
        ) : (
          <>
            {cart && cart.order_services.length > 0 ? (
              <>
                <div className="no-shop-select-image-container">
                  <DatePicker
                    inline
                    selected={this.state.date}
                    onChange={this.onDateChange}
                    minDate={minDate._d}
                    locale={datePickerLocale()}
                    filterDate={isDayDisabled}
                  />
                </div>
                <div className="select-time-container">
                  <div className="select-time-header">
                    {t("shopAppointmentPicker:selectTimeLbl")}
                  </div>
                  <div className="time-slots">
                    {!slotLoading ? (
                      <ItemSelector
                        items={this.state.timeSlots}
                        labelField="formatted_time"
                        valueField="time"
                        emptyMessage={emptyMessage}
                        value={this.state.time}
                        onSelect={this.onSelect}
                        dateTimeSelected={dateTimeSelected}
                        cart={cart}
                      />
                    ) : (
                      <TimeShimmer count={7} />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div>{t("common:noAddedServiceText")}</div>
            )}
          </>
        )}
      </div>
    )
  }

  //helpers
  fetchOrderLikeObject() {
    let { user, orderLikeObject, cart } = this.props
    return (
      orderLikeObject ||
      cart ||
      (user &&
        user.active_carts &&
        user.active_carts.find((c) => c.vehicle_id === user.activeVehicleId))
    )
  }

  async clearLoadingState() {
    this.setState({ loading: false, loadingMessage: "" })
  }

  enableWalkIn = (date) => {
    const { user } = this.props
    const momentDate = moment(date)
    var isSunday = date.getDay(0)
    const cart = this.fetchOrderLikeObject()
    const isEbay = isEbayCart(user)
    // check for scheduling blackout dates (NOTE: month is zero-indexed)
    const isBlackoutDate = [
      { month: 11, day: 25 } // Christmas
    ].find((bod) => bod.month === momentDate.month() && bod.day === momentDate.date())

    return (
      !this.props.disableWalkIns &&
      isSunday &&
      !isEbay &&
      !isBlackoutDate &&
      !this.hasNewTiresWithTireConnect(cart)
    )
  }

  hasNewTiresWithTireConnect(cart) {
    return (
      cart.shop &&
      cart.shop.supports_tire_selection &&
      !!getNewTiresServiceFromServices(cart.order_services)
    )
  }

  async setLoadingState(message) {
    this.setState({ loading: true, loadingMessage: message })
  }
}

function mapStateToProps(state, props) {
  let user = state.user
  let shops = state.shop.shops || []
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  let addedServices = cart ? cart.order_services : []
  let modalNotifications = state.modalNotifications
  const promotionData = state.promotionData

  return {
    addedServices,
    anyOrderServiceLacksPrice: cart && cart.any_order_service_lacks_price,
    promoCode: promotionData.promoCode,
    user,
    cart: cart || {},
    shops,
    modalNotifications,
    activeVehicle,
    isCart
  }
}

export default connect(mapStateToProps)(
  withTranslation(["checkout", "shopAppointmentPicker"])(DateTimePicker)
)
