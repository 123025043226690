import React, { useState } from "react"
import SavingsAccordion from "./SavingsAccordionSection"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { Button, Popup } from "semantic-ui-react"
import { navigationPaths, routeChange, routerPaths } from "../../../v1/constants/paths"
import { withRouter } from "react-router-dom"
import { constants } from "caradvise_shared_components"
import ReactSVG from "react-svg"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../v1/constants/application"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { getActiveVehicle, getVehicleName } from "../../../v1/helpers/vehicleHelpers"
import { getCurrentUser } from "../../../v1/helpers/userHelper"
import { getServiceNameForMap } from "../../../v1/helpers/serviceHelper"
import ModalDialog from "../../../v1/components/ModalDialog"
import { WALK_IN_ACTIVE } from "../../../shared_component/constants/common"
const {
  tooltips: { TAX_AND_FEES, TAX_AND_FEES_FR }
} = constants
const {
  orders: { INITIATED_BY_USER, ACTIVE, WALK_IN }
} = constants

const SidePanelFooter = (props) => {
  const {
    cart,
    t,
    shop,
    sidePanelClose,
    user,
    isCart,
    isDefaultOpen = false,
    showButton = true,
    isServiceComponentScrolled,
    handleFooterToggle,
    isActiveOrder
  } = props

  const totalPriceText = (order) => {
    if (order.any_order_service_lacks_price) {
      return props.t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(order.estimated_total_pre_tax - order.transaction_fee)
    }
  }

  // Check if any of the conditions are satisfied
  const shouldShowFooter =
    shop &&
    !shop.cdk_dealer &&
    (shop || (cart.total_savings && cart.total_savings > 0)) &&
    cart &&
    cart.order_services &&
    cart.order_services.length > 0
  const isCartHasServices = cart && cart.order_services && cart.order_services.length > 0
  let activeVehicle
  if (user)
    activeVehicle = user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
  const isMDHDVehicle =
    (activeVehicle &&
      activeVehicle.length > 0 &&
      activeVehicle[0].vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) ||
    false

  const isContinueButtonDisabled =
    cart && cart.order_services && cart.order_services.length > 0 ? false : true

  const includesStatuses = [ACTIVE, WALK_IN, INITIATED_BY_USER, WALK_IN_ACTIVE]
  const showModifyAppointment =
    isActiveOrder && cart && includesStatuses.includes(isActiveOrder.status) && !cart.is_tire_order
  const isTireOrder = cart && cart.is_tire_order
  return (
    <>
      {shouldShowFooter ? (
        <div className="side-panel-footer-section">
          <div className="side-panel-footer-header-section">
            <div className="headings-label">{t("subTotalLabel")}</div>
            <div className="headings-label">{totalPriceText(cart)}</div>
          </div>
          {cart.transaction_fee > 0 && (
            <div className="side-panel-footer-header-sub-section">
              <div className="label-text">
                {t("taxAndFeesLabel")}
                <ModalDialog
                  linkText={<ReactSVG src="images/info_icon_vector.svg" />}
                  className="primary-button-color"
                >
                  {user && user.language.includes("fr") ? TAX_AND_FEES_FR : TAX_AND_FEES}
                </ModalDialog>
              </div>
              <div>
                {numberFormatToCurrency(
                  cart.estimated_total - cart.estimated_total_pre_tax + cart.transaction_fee
                )}
              </div>
            </div>
          )}
          {shop !== null && cart ? (
            <div className="side-panel-footer-savings-section">
              <SavingsAccordion
                user={user}
                t={t}
                cart={cart}
                shop={shop}
                isDefaultOpen={isDefaultOpen}
                isServiceComponentScrolled={isServiceComponentScrolled}
                handleFooterToggle={handleFooterToggle}
              />
            </div>
          ) : (
            cart.total_savings > 0 && (
              <div className="side-panel-footer-savings-section">
                <SavingsAccordion
                  user={user}
                  t={t}
                  cart={cart}
                  shop={shop}
                  isDefaultOpen={isDefaultOpen}
                  isServiceComponentScrolled={isServiceComponentScrolled}
                  handleFooterToggle={handleFooterToggle}
                />
              </div>
            )
          )}
          {showButton && (
            <div
              className="bottom-continue-button-container"
              style={shop !== null && shop.cdk_dealer ? { position: "fixed", bottom: "30px" } : {}}
            >
              <Button
                fluid
                content={
                  showModifyAppointment
                    ? props.t("reviewOrder:modifyAppointmentLbl")
                    : isTireOrder
                    ? props.t("justForYouCards:showDetailsLabel")
                    : props.buttonText
                    ? props.buttonText
                    : props.t("common:continueLabel")
                }
                className="bottom-continue-button"
                onClick={() => {
                  sidePanelClose()
                  routeChange(
                    props.history,
                    isTireOrder ? navigationPaths.review(cart.id) : routerPaths.checkoutSchedule
                  )
                  trackEvent("cart-created", {
                    vehicleName: getVehicleName(getActiveVehicle()),
                    orderServices:
                      cart &&
                      cart.order_services &&
                      cart.order_services.length > 0 &&
                      cart.order_services.map(getServiceNameForMap),
                    email: getCurrentUser().email,
                    eventSourceName: "Checkout_2.0"
                  })
                  trackEvent("checkout-clicked", {
                    eventSourceName: "Checkout_2.0"
                  })
                }}
                disabled={isContinueButtonDisabled}
              />
            </div>
          )}
        </div>
      ) : isCartHasServices && shop && !shop.cdk_dealer && !isMDHDVehicle ? (
        <div className="side-panel-footer-section">
          <div className="side-panel-footer-header-section">
            <div className="headings-label">{t("subTotalLabel")}</div>
            <div className="headings-label">{totalPriceText(cart)}</div>
          </div>
          {!cart.any_order_service_lacks_price && cart.transaction_fee > 0 && (
            <div className="side-panel-footer-header-sub-section">
              <div className="label-text">
                {t("taxAndFeesLabel")}
                <ModalDialog
                  linkText={<ReactSVG src="images/info_icon_vector.svg" />}
                  className="primary-button-color"
                >
                  {user && user.language.includes("fr") ? TAX_AND_FEES_FR : TAX_AND_FEES}
                </ModalDialog>
              </div>
              <div>
                {numberFormatToCurrency(
                  cart.estimated_total - cart.estimated_total_pre_tax + cart.transaction_fee
                )}
              </div>
            </div>
          )}
          {showButton && (
            <div
              className="bottom-continue-button-container"
              style={shop && shop.cdk_dealer ? { position: "fixed", bottom: "30px" } : {}}
            >
              <Button
                fluid
                content={
                  showModifyAppointment
                    ? props.t("reviewOrder:modifyAppointmentLbl")
                    : isTireOrder
                    ? props.t("justForYouCards:showDetailsLabel")
                    : props.buttonText
                    ? props.buttonText
                    : props.t("common:continueLabel")
                }
                className="bottom-continue-button"
                onClick={() => {
                  sidePanelClose()
                  routeChange(
                    props.history,
                    isTireOrder ? navigationPaths.review(cart.id) : routerPaths.checkoutSchedule
                  )
                  trackEvent("cart-created", {
                    vehicleName: getVehicleName(getActiveVehicle()),
                    orderServices:
                      cart &&
                      cart.order_services &&
                      cart.order_services.length > 0 &&
                      cart.order_services.map(getServiceNameForMap),
                    email: getCurrentUser().email,
                    eventSourceName: "Checkout_2.0"
                  })
                  trackEvent("checkout-clicked", {
                    eventSourceName: "Checkout_2.0"
                  })
                }}
                disabled={isContinueButtonDisabled}
              />
            </div>
          )}
        </div>
      ) : (
        showButton && (
          <div className="side-panel-footer-section" style={{ borderTop: "none" }}>
            <div
              className="bottom-continue-button-container"
              style={shop && shop.cdk_dealer ? { position: "fixed", bottom: "30px" } : {}}
            >
              <Button
                fluid
                content={
                  showModifyAppointment
                    ? props.t("reviewOrder:modifyAppointmentLbl")
                    : isTireOrder
                    ? props.t("justForYouCards:showDetailsLabel")
                    : props.buttonText
                    ? props.buttonText
                    : props.t("common:continueLabel")
                }
                className="continue-button"
                onClick={() => {
                  sidePanelClose()
                  routeChange(
                    props.history,
                    isTireOrder ? navigationPaths.review(cart.id) : routerPaths.checkoutSchedule
                  )
                  trackEvent("cart-created", {
                    vehicleName: getVehicleName(getActiveVehicle()),
                    orderServices:
                      cart &&
                      cart.order_services &&
                      cart.order_services.length > 0 &&
                      cart.order_services.map(getServiceNameForMap),
                    email: getCurrentUser().email,
                    eventSourceName: "Checkout_2.0"
                  })
                  trackEvent("checkout-clicked", {
                    eventSourceName: "Checkout_2.0"
                  })
                }}
                disabled={isContinueButtonDisabled}
              />
            </div>
          </div>
        )
      )}
    </>
  )
}

export default withRouter(SidePanelFooter)
