import React, { useState } from "react"
import RewardsPlaceholderImage from "../../../images/rewards/rewards_placeholder.svg"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"
import HowRewardsWorks from "./HowRewardsWorks"

const RewardsPlaceholder = ({ t }) => {
  const [openHowUnlockMilesDialog, setHowUnlockMilesDialog] = useState(false)

  return (
    <div className="rewards_container">
      <ReactSVG src={RewardsPlaceholderImage} />
      <p className="placeholder_placeholder">{t("noRewardsOpportunity")}</p>
      <p className="rewards_link" onClick={() => setHowUnlockMilesDialog(true)}>
        {t("learnHowToEarnMiles")}
      </p>
      <HowRewardsWorks
        setOpenDialog={setHowUnlockMilesDialog}
        openDialog={openHowUnlockMilesDialog}
      />
    </div>
  )
}

export default withTranslation("rewards")(RewardsPlaceholder)
