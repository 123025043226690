import store from "./configureStore"
import { removeUser } from "../../v1/actions/user"
import "whatwg-fetch"

export async function getJSON(url, params = {}, headers = {}) {
  try {
    params = Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      })
      .join("&")

    if (params) url += `?${params}`

    let response = await fetch(url, {
      method: "GET",
      headers: Object.assign({ Accept: "application/json" }, headers)
    })

    if (response.ok) {
      let result = await response.json()
      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) {
        await store.dispatch(removeUser())
        return { error: result.errors || result.error }
      } else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}, url: ${response.url}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}

export async function postJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "POST",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        headers
      ),
      body: JSON.stringify(data)
    })

    if (response.ok) {
      let result = await response.json()
      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else
        return { error: result.errors || result.error || result.message, status: response.status }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}
export async function getFile(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "GET",
      params: {
        vehicle_id: data.vehicle_id,
        auth_token: headers.Authorization
      }
    })

    if (response.ok) {
      let result = await response.json()

      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.error(error)
    return { error: error.message }
  }
}

export async function postFile(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "POST",
      body: data
    })
    if (response.ok) {
      let result = await response.json()
      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}

export async function putFile(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "PUT",
      body: data
    })

    if (response.ok) {
      let result = await response.json()

      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}

export async function putJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "PUT",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        headers
      ),
      body: JSON.stringify(data)
    })

    if (response.ok) {
      let result = await response.json().catch(() => null)
      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}

export async function deleteJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "DELETE",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        headers
      ),
      body: JSON.stringify(data)
    })

    if (response.ok) {
      return { result: true }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    console.log(error)
    return { error: error.message }
  }
}

export async function patchJSON(url, data = {}, headers = {}) {
  try {
    let response = await fetch(url, {
      method: "PATCH",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        headers
      ),
      body: JSON.stringify(data)
    })

    if (response.ok) {
      let result = await response.json()
      return { result }
    } else if (response.status >= 400 && response.status < 500) {
      let result = await response.json()
      if (response.status === 401) await store.dispatch(removeUser())
      else return { error: result.errors || result.error }
    } else {
      throw new Error(`Error with status ${response.status}`)
    }
  } catch (error) {
    return { error: error.message }
  }
}
