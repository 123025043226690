import React, { Component } from "react"
import { connect } from "react-redux"
import store from "../../shared_component/utils/configureStore"
import { withRouter } from "react-router"
import Notifications from "./Notifications"

import {
  getPaymentMethods,
  setPaymentDefault,
  deletePaymentMethod,
  getPaymentCards,
  createPaymentMethod,
  getBraintreePaymentToken
} from "../actions/paymentMethods"

import { updatePaymentType } from "../actions/orders"
import { setAffirmDefaultValue } from "../actions/payment"

import Button from "./Button"
import PaymentMethod from "./PaymentMethods/PaymentMethod"
import LoadingComponent from "../components/LoadingComponent"
import { createMembership, clearSelectedMembershipUpgrade } from "../actions/membership"
import { setupSplitFlags } from "../components/Config/SplitClient"
import MembershipClauseAgreementSection from "../components/Membership/MembershipClauseAgreementSection"
import Affirm from "./Affirm/Affirm"
import { withTranslation } from "react-i18next"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { storeUser } from "../actions/user"
import CreditCardForm from "./CreditCardForm"
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons"
import { setupPaypalBraintree } from "../../shared_component/utils/setupBraintree"
import i18n from "i18next"
import { GAEvent, GAEventCategories } from "../tracking/GAEvent"
import { PAYPAL } from "../constants/tires"
import { PAYMENT_METHODS } from "../constants/application"

class PaymentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadedFirstTime: false,
      loading: false,
      loadingMessage: "",
      showSavePaymentButton: false,
      openCardModal: false,
      showNewPaymentMethods: false
    }
  }

  fulfilledClauseAgreement = () => {
    if (!this.props.showMembershipClauses) return true

    const upgrade = this.props.selectedMembershipUpgrade
    if (upgrade && upgrade.acceptedTermsAndConditions && upgrade.acceptedPassengerVehicleClause)
      return true
    else return false
  }

  async attemptCreateMembership(payload) {
    const response = await this.props.createMembership({ ...payload, callback: this.afterRequest })
    if (response().result) {
      await identifyEvent({ traits: { selectedPlan: response().result.plan_name } })
      trackEvent("plan-type-chosen", {
        previousPlan: null,
        selectedPlan: response().result.plan_name,
        duringOnboarding: "Yes"
      })
    }
  }

  async componentDidUpdate() {
    await this.loadPaymentData()
  }

  async componentDidMount() {
    const { paymentMethods, isPaymentLoad = false } = this.props
    setupSplitFlags.bind(this)()
    const isAddPaymentLoad = (!paymentMethods || paymentMethods.length === 0) && isPaymentLoad
    if (this.props.autoload) await this.addNewPaymentMethod()
    if (isAddPaymentLoad) {
      await this.addNewPaymentMethod()
    }
    this.loadPaymentData()
    this.props.getPaymentCards(this.props.user)
  }

  async loadPaymentData() {
    const { t } = this.props
    if (
      this.props.user.authentication_token &&
      !this.state.loadedFirstTime &&
      (!this.props.paymentMethods || this.props.paymentMethods.length == 0)
    ) {
      await this.setState({
        loading: true,
        loadingMessage: t("fetchPaymentMethodLoadingMessage"),
        loadedFirstTime: true
      })
      await this.props.getPaymentMethods(this.props.user)
      await this.clearLoadingState()
    }
  }

  setLoadingState = async (message) => {
    this.setState({ loading: true, loadingMessage: message })
  }

  clearLoadingState = async () => {
    this.setState({ loading: false, loadingMessage: "" })
  }

  updatePaymentType = async () => {
    const response = await this.props.updatePaymentType(this.props.order, "braintree", "")
  }

  handlePaymentItemClick = async (evt) => {
    this.props.setAffirmDefaultValue(false)

    // this.setState({ isAffirmSelectedDefault: false })
    // if (this.props.updateAffirmSelected != null) {
    //   this.props.updateAffirmSelected(false)
    // }

    if (this.props.order) {
      this.updatePaymentType()
    }
    const { t } = this.props
    if (!evt.value.default) {
      await this.setLoadingState(t("updatingDefaultPaymentMethodLoadingMessage"))
      await this.props.setPaymentDefault(this.props.user, evt.value.token)
      await this.clearLoadingState()
    }
  }

  handlePaymentDelete = async (evt) => {
    const { t } = this.props
    await this.setLoadingState(t("removingPaymentMethodLoadingMessage"))
    await this.props.deletePaymentMethod(this.props.user, evt.value.token)
    await this.clearLoadingState()
  }

  afterRequest = (status, data) => {
    this.props.history.push("/dashboard")
    const { t } = this.props
    if (status === "success") {
      store.dispatch(
        Notifications.success({
          title: t("successTitle"),
          message: `${this.props.altSuccessMessage}` || `${t("paymentMethodAddedSuccess")}`,
          position: "tr",
          autoDismiss: 6,
          action: {
            label: "Dismiss"
          }
        })
      )
      store.dispatch(clearSelectedMembershipUpgrade())
    } else {
      store.dispatch(
        Notifications.error({
          title: t("errorTitle"),
          message: `${data.message || t("paymentMethodAddedFailure")}`,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }

  subscribe = async () => {
    if (this.props.withMembershipUpgrade) {
      const plan = this.props.selectedMembershipUpgrade
      this.attemptCreateMembership({
        membership_plan_id: plan.id,
        accepted_passenger_vehicle_clause: plan.acceptedPassengerVehicleClause,
        accepted_terms_and_conditions: plan.acceptedTermsAndConditions
      })
    }
  }

  addNewPaymentMethod = async (e) => {
    if (e) e.preventDefault()
    this.setState({ showNewPaymentMethods: true })
    const paypalCheckoutInstance = await setupPaypalBraintree()

    paypalCheckoutInstance
      .loadPayPalSDK({
        vault: true,
        intent: "tokenize",
        "disable-funding": "card"
      })
      .then(() => {
        window.paypal
          .Buttons({
            style: {
              disableMaxWidth: true,
              align: "center",
              height: 40
            },
            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                flow: "vault",
                intent: "tokenize"
              })
            },
            onApprove: (data) => {
              return paypalCheckoutInstance.tokenizePayment(data, async (err, payload) => {
                this.setState({ disabled: true })
                await this.props.createPaymentMethod(
                  this.props.user,
                  payload.nonce,
                  PAYMENT_METHODS.PAYPAL
                )
                this.setState({ disabled: false })
              })
            },
            onError: function (err) {
              console.error("PayPal error", err)
            }
          })
          .render("#paypal-button")
      })
    trackEvent("click-add-payment-method")
    GAEvent(GAEventCategories.MY_ACCOUNT, "add-payment", "Add Payment Method")
  }

  handleAffirmSelect = async (evt) => {
    this.props.setAffirmDefaultValue(true)
    if (evt) evt.preventDefault()
  }

  openAddCard = () => {
    this.setState({ openCardModal: true })
  }

  handleClose = () => {
    this.setState({ openCardModal: false })
  }

  render() {
    let { paymentMethods, allowedFinalRemove, cart, user, paymentInfo } = this.props || []
    const { hasPaidMembership, selectedMembershipUpgrade, centeredSavePayment, t } = this.props
    let buttonStyle = { height: "20px", gap: "10px" }
    if (!centeredSavePayment) buttonStyle["float"] = "left"
    const currentPage = this.props.location.pathname
    let { order } = this.props || []
    let isAffirmSelectedDefault = false
    if (paymentInfo.affirmDefault && cart && !cart.any_order_service_lacks_price) {
      this.props.cart.paymentType = "affirm"
    } else if (cart) {
      this.props.cart.paymentType = "braintree"
    }
    const approval_url_match = window.location.hash === "#/approvals"

    if (order) {
      isAffirmSelectedDefault = order.payment_type === "affirm"
    }
    isAffirmSelectedDefault = paymentInfo.affirmDefault || isAffirmSelectedDefault ? true : false

    return (
      <>
        <div style={{ width: "100%" }}>
          {paymentMethods && paymentMethods.length > 0 && (
            <div className="paymentMethodInfoList">
              {paymentMethods.map((paymentMethod) => {
                return (
                  <PaymentMethod
                    key={paymentMethod.token}
                    onDelete={this.handlePaymentDelete}
                    onSetDefault={this.handlePaymentItemClick}
                    paymentMethod={paymentMethod}
                    allowedFinalRemove={allowedFinalRemove}
                    hasPaidMembership={hasPaidMembership}
                    isAffirmSelectedDefault={isAffirmSelectedDefault}
                  />
                )
              })}
            </div>
          )}
          {this.state.disabled && <LoadingComponent />}

          {this.props.autoload ||
            (!this.state.showNewPaymentMethods &&
              (this.props.hasCC ? (
                <div style={{ paddingBottom: "10px" }}>
                  <a
                    href="#"
                    className="orange-link gtm-addnewcard"
                    style={{ float: "right" }}
                    onClick={this.addNewPaymentMethod}
                  >
                    {t("addPaymentMethodText")}
                  </a>
                </div>
              ) : (
                <Button
                  text={t("addPaymentMethodText")}
                  disabled={this.state.disabled}
                  colorStyle="orange"
                  onClick={this.addNewPaymentMethod}
                  linkTo="/addPaymentMethod"
                />
              )))}
          {this.state.showNewPaymentMethods && (
            <>
              <div style={{ paddingBottom: "10px" }}>
                <Button
                  iconLeft={faCreditCard}
                  text="Credit/Debit Card"
                  onClick={this.openAddCard}
                  trackingClassName="add-new-card"
                />
              </div>
              <div
                id="paypal-button"
                className="paypal-btn-loading"
                style={{ maxWidth: "260px", margin: "auto", height: "40px" }}
              ></div>
            </>
          )}
          <br />
          {this.props.showMembershipClauses && (
            <MembershipClauseAgreementSection
              selectedMembershipUpgrade={selectedMembershipUpgrade}
            />
          )}
          {this.props.autoload && (
            <div style={centeredSavePayment ? {} : { display: "flex", marginBottom: "20px" }}>
              <Button
                style={buttonStyle}
                text={this.props.altSavePaymentText || t("savePaymentMethodBtn")}
                disabled={this.state.disabled || !this.fulfilledClauseAgreement()}
                colorStyle="orange"
                onClick={this.subscribe}
              />
            </div>
          )}
          {this.state.openCardModal && <CreditCardForm handleClose={this.handleClose} />}
          <br />
          {((cart !== undefined && !cart.opted_for_prepayment) || approval_url_match) && (
            <Affirm
              cart={cart}
              user={user}
              order={order}
              currentPage={currentPage}
              isAffirmSelectedDefault={isAffirmSelectedDefault}
              handleAffirmSelect={this.handleAffirmSelect}
              t={t}
            />
          )}
          {/* <BraintreeLogo /> */}
        </div>
      </>
    )
  }
} // PaymentList

function mapStateToProps(state) {
  const { membershipData } = state
  let user = state.user || {}
  let allOrders = user.active_orders ? user.active_orders : []
  let paymentInfo = state.paymentInfo || {}
  let orderCount

  if (user.total_shop_orders) {
    orderCount = user.total_shop_orders
  } else if (user.shop_orders) {
    allOrders = allOrders.concat(user.shop_orders)
    orderCount = allOrders.length
  }

  let paymentMethods = state.paymentMethods || {}
  const hasPaidMembership = user.membership && user.membership.price && user.membership.price > 0
  return {
    user,
    paymentInfo,
    allowedFinalRemove:
      (orderCount == 0 && !hasPaidMembership) ||
      (paymentMethods.braintreePaymentMethods && paymentMethods.braintreePaymentMethods.length > 1),
    hasCC: user.has_cc_info_saved,
    hasPaidMembership: hasPaidMembership,
    paymentMethods: state.paymentMethods.braintreePaymentMethods,
    selectedMembershipUpgrade: membershipData.selectedMembershipUpgrade || {}
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getPaymentMethods,
    setPaymentDefault,
    deletePaymentMethod,
    createMembership,
    setAffirmDefaultValue,
    updatePaymentType,
    getPaymentCards,
    createPaymentMethod,
    getBraintreePaymentToken
  })(withTranslation("paymentList")(PaymentList))
)
