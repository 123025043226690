import React, { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import ServiceCard from "./ServiceCard"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import { ShimmerServiceCard } from "./ShimmerServiceCard"
import { updateCart } from "../../../v1/actions/user"
import { getPopularServices } from "../../../v1/actions/services"
import { serviceSelectedEvents } from "../../../shared_component/utils/googleAnalyticsHelpers"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const PopularServices = (props) => {
  const { activeVehicle, cart, getPopularServices, isCheckBox, count } = props
  const [popularServices, setPopularServices] = useState([])
  const [loadingStates, setLoadingStates] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [cartShop, setCartShop] = useState(props.cart.shop)
  const { t } = useTranslation("cartPanel")

  const fetchPopularServices = async () => {
    setLoading(true)

    const services =
      (await getPopularServices({ shopId: (cart && cart.shop && cart.shop.id) || null })) || null
    if (services !== null) {
      setPopularServices(services)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchInitialLoadingStates = async () => {
      try {
        const initialLoadingStates =
          popularServices &&
          popularServices.reduce((acc, service) => {
            acc[service.id || service.service_id || service.service_definition_id] = false
            return acc
          }, {})
        setLoadingStates(initialLoadingStates)
      } catch (error) {
        console.error(error)
      }
    }

    fetchInitialLoadingStates()
  }, [popularServices])

  useEffect(() => {
    fetchPopularServices()
  }, [])

  useEffect(() => {
    if (cartShop !== props.cart.shop) {
      setCartShop(props.cart.shop)
      fetchPopularServices()
    }
  }, [props.cart.shop])

  const addService = async (index, os, event) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [index]: true
    }))
    const { activeVehicle, isCart, order } = props
    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id,
      successCallback: async () => {
        await serviceSelectedEvents({ serviceDefinition: os, position: os.position, activeVehicle })
      }
    }
    try {
      await props.updateCart(
        [
          {
            service_definition_id: os.service_id || os.id || os.service_definition_id,
            event: event,
            prepaid: os.prepaid,
            position: os.position
          }
        ],
        props.cart.vehicle_id,
        null,
        undefined,
        opts
      )
      // Add Segment Event
    } catch (error) {
      console.log(error)
      return { error: error.message }
    } finally {
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [index]: false
      }))
    }
  }

  const filteredpopularServices =
    popularServices &&
    popularServices.filter(
      (rec) =>
        cart &&
        !cart.order_services.some((item) => item.id === rec.id || rec.id === item.service_id)
    )

  const dashboardPopularServices = filteredpopularServices.slice(0, count)
  return (
    <>
      {count && isLoading && <ShimmerServiceCard count={count} />}
      {count &&
        !isLoading &&
        dashboardPopularServices &&
        dashboardPopularServices.map((os, index) => {
          return (
            <ServiceCard
              key={os.id || os.service_id || os.service_definition_id}
              isCheckbox={!isCheckBox ? false : true}
              index={os.id || os.service_id || os.service_definition_id}
              service={os}
              addService={addService}
              loadingStates={loadingStates[os.id || os.service_id || os.service_definition_id]}
              order={props.cart}
              vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
            />
          )
        })}
      {!count && isLoading && <ShimmerServiceCard count={15} />}
      {!count &&
        !isLoading &&
        filteredpopularServices &&
        filteredpopularServices.map((os, index) => {
          return (
            <ServiceCard
              key={os.id || os.service_id || os.service_definition_id}
              isCheckbox={!isCheckBox ? false : true}
              index={os.id || os.service_id || os.service_definition_id}
              service={os}
              addService={addService}
              loadingStates={loadingStates[os.id || os.service_id || os.service_definition_id]}
              order={props.cart}
              vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
            />
          )
        })}
    </>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    order
  }
}

export default connect(mapStateToProps, { getPopularServices, updateCart })(PopularServices)
