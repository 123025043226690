import React from "react"
import BookingRetail from "../../../images/booking_retail.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { CONTACT_NUMBER } from "../../constants/tires"
import { ShimmerThumbnail } from "react-shimmer-effects"

const Contact = (props) => {
  const { t, isLoading } = props
  return isLoading ? (
    <ShimmerThumbnail className="shimmer-custom-mergin shimmer-custom" height={140} rounded />
  ) : (
    <div className="booking-contact">
      <div className="retail-price-idea">
        <Image src={BookingRetail} />
        <p>{t("betterThanRetailPrice")}</p>
      </div>
      <p className="contact-query">{t("contactLabel")}</p>
      <p className="contact">{CONTACT_NUMBER}</p>
    </div>
  )
}

export default withTranslation("bookingContact")(Contact)
