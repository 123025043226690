import React, { useEffect, useRef, useState } from "react"
import { Button, Image } from "semantic-ui-react"
import HomeLogo from "../../images/dashboard/header-home-logo.svg"
import DownArrow from "../../images/dashboard/header-down-arrow.svg"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getUpdatedCart } from "../../v1/helpers/orderHelpers"
import { OPEN_EXPANDED_MAP_VIEW } from "../../v1/constants/shops"
import { EVENT_LISTENERS } from "../../v1/constants/application"
import ShopSearch from "./Schedule/Shop/ShopSearch"
import { ACTIVE_VIEWS } from "../../shared_component/constants/checkout"
const ShopInformation = ({ t, shop }) => {
  const [isOpen, setIsOpen] = useState(false)
  const modalRef = useRef(null)

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const openExpandedMapView = async () => {
    const data = {
      show: true,
      hideCart: true
    }
    const events = new CustomEvent(OPEN_EXPANDED_MAP_VIEW, { detail: data })
    document.dispatchEvent(events)
    setIsOpen(!isOpen)
  }
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener(EVENT_LISTENERS.MOUSEDOWN, handleClickOutside)
    return () => {
      document.removeEventListener(EVENT_LISTENERS.MOUSEDOWN, handleClickOutside)
    }
  }, [])

  return (
    <React.Fragment>
      <div ref={modalRef} className="header-shop-section">
        <div onClick={toggleMenu} className="header-shop-info-container">
          <Image src={HomeLogo} />
          <span className="shop-information-text">
            {shop && shop.name ? (
              <span>
                {shop.name}
                {", "}
                {shop.address_line1} {shop.city}, {shop.state} {shop.zip}
              </span>
            ) : (
              t("noShopSelectedLabel")
            )}
          </span>
          <Image src={DownArrow} />
        </div>
        <div className={`shop-information-modal ${isOpen ? "active" : "inactive"}`}>
          <span className="modal-shop-name">
            {shop && shop.name ? (
              <div className="modal-shop-info-content">
                <span className="modal-shop-name">{shop.name}</span>
                <span className="modal-shop-address">
                  <span>{shop.address_line1}</span>
                  <span>
                    {" "}
                    {shop.city} {shop.state} {shop.zip}
                  </span>
                </span>
              </div>
            ) : (
              t("noShopSelectedLabel")
            )}
          </span>
          <Button className="modal-shop-change-btn" onClick={openExpandedMapView}>
            {shop && shop.name ? t("changeShopBtn") : t("findAShopBtn")}
          </Button>
        </div>
        {!/dashboard|schedule/.test(window.location.href) && (
          <div className="dashboard-search-shop-container">
            <div className="dashboard-search-favorite-shop-section">
              <ShopSearch activeView={ACTIVE_VIEWS.MAP} count={2} showShopTiles={false} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state, props) {
  const { user } = state
  const activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let cart
  let isCart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  return {
    user,
    shop: (cart && cart.shop) || {}
  }
}
export default withRouter(connect(mapStateToProps)(ShopInformation))
