import React, { useState } from "react"
import FilterUpLogo from "../../../images/filter-up.svg"
import FilterDownLogo from "../../../images/filter-down.svg"
import ReactSVG from "react-svg"
import { Checkbox } from "semantic-ui-react"
import { transformString } from "../../../shared_component/utils/TireFilterHelpers"
import { get, isEmpty } from "lodash"
import { TIRE_FILTER_CHECKBOX } from "../../constants/tires"
import { isMobile } from "react-device-detect"

const TireFilterSection = ({
  tireSelectedFilters,
  filterName,
  filterData,
  handleSelectedFilter
}) => {
  const [collapsed, setCollapsed] = useState(!isMobile)

  const handleCollapsed = () => {
    setCollapsed((prev) => !prev)
  }

  const isChecked = (filterName, option) => {
    return get(tireSelectedFilters, `${filterName}.${TIRE_FILTER_CHECKBOX}`, []).includes(option)
  }

  return (
    <div className="filter__section">
      <div onClick={handleCollapsed} className="filter__section-header">
        <p className="filter__label">{transformString(filterName)}</p>
        <ReactSVG className="filter__logo" src={collapsed ? FilterUpLogo : FilterDownLogo} />
      </div>
      {collapsed && (
        <div className="filter__options-container">
          <div className="filter__options">
            {!isEmpty(filterData) &&
              filterData.map((option) => (
                <Checkbox
                  onChange={(event, data) =>
                    handleSelectedFilter(data, filterName, TIRE_FILTER_CHECKBOX)
                  }
                  className="filter__label"
                  label={option}
                  checked={isChecked(filterName, option)}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default TireFilterSection
