// TODO: attach these to companies in the db (and move images to s3)
export const S3_PATH = "https://s3.amazonaws.com/assets.caradvise.com/"
const INDEPENDENT = `${S3_PATH}company_logos/logo-independent-shop.png`
const PAID_INDEPENDENT = `${S3_PATH}company_logos/logo-paid-ai-independent-shop.png`

export function logoForShop(shop) {
  if (shop == null || Object.keys(shop).length === 0) return

  const { independent, featured, company_id, logo_url } = shop
  return independent ? (featured ? PAID_INDEPENDENT : INDEPENDENT) : logo_url
}
