import React from "react"
import wordlessLogoSVG from "../../images/CA_CheckIcon.svg"
import ReactSVG from "react-svg"

const MinimalSidebar = ({}) => (
  <div className="selectMembershipSidebar">
    <div>
      <ReactSVG src={"./images/CA_CheckIcon.svg"} svgStyle={{ width: 50, height: 50 }} />
    </div>
  </div>
)

export default MinimalSidebar
