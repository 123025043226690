import React from "react"
import { Image } from "semantic-ui-react"
import TireConditionLogo from "../../../images/tire_track_logo.svg"
import TireCondition from "./TireCondition"

const TireTreadDepthStatus = ({ tireStatus, className }) => {
  return (
    <div className={`tire-grid-container ${className}`}>
      {tireStatus &&
        tireStatus.length > 0 &&
        tireStatus.map((tire, index) => (
          <TireCondition key={index} status={tire.status} depth={tire.depth} />
        ))}
      <div className="tire-image">
        <Image src={TireConditionLogo} />
      </div>
    </div>
  )
}

export default TireTreadDepthStatus
