import { getJSON, putJSON, deleteJSON, postJSON } from "../../shared_component/utils/fetch"
import { setUserError, setUserData } from "./user"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { PAYMENT_METHODS } from "../constants/application"

const LOAD_PAYMENT_METHODS = "LOAD_PAYMENT_INFO"
const LOAD_PAYMENT_METHODS_URL = "/api/v4/payment_methods"
const CREATE_PAYMENT_METHODS_URL = "/api/v5/payment_methods"
export const GET_TOKEN_URL = LOAD_PAYMENT_METHODS_URL + "/get_token"
export const BRAINTREE_PAYMENT_CARDS_URL = `${process.env.REACT_APP_INTERNAL_API_URL}/api/v1/braintree_payments`
const GET_PAYMENT_TOKEN_URL = BRAINTREE_PAYMENT_CARDS_URL + "/new"
const TIRE_ORDER_CREATION_URL = `${process.env.REACT_APP_INTERNAL_API_URL}/api/ecosystem/v1/tires/tire_connect_orders`

function setPaymentMethods(braintreePaymentMethods, affirm = true) {
  braintreePaymentMethods = braintreePaymentMethods.map(function (obj) {
    obj["isdefault"] = obj["default"]
    delete obj["default"]
    return obj
  })
  return { type: LOAD_PAYMENT_METHODS, braintreePaymentMethods }
}

export function getPaymentMethods(loggedInUser) {
  return async function (dispatch) {
    let user = loggedInUser || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: LOAD_PAYMENT_METHODS_URL,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }
      let url = LOAD_PAYMENT_METHODS_URL

      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        // Update the cache on our end
        if (response.result.length === 0) {
          // TODO: update has_cc logic
          user.has_cc_info_saved = false
          dispatch(setUserData(user))
        } else {
          user.has_cc_info_saved = true
          dispatch(setUserData(user))
        }

        dispatch(setPaymentMethods(response.result))
      } else {
        dispatch(setPaymentMethods([]))

        dispatch(setUserError(response.error))
      }
      return () => response
    }
  }
}

export function setPaymentDefault(loggedInUser, token) {
  return async function (dispatch) {
    let user = loggedInUser || {}
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: `${LOAD_PAYMENT_METHODS_URL}/${token}`,
        headers: {
          Authorization: authentication_token
        },
        body: {
          default: true
        }
      }

      const response = await putJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(setPaymentMethods(response.result))
      } else {
        dispatch(setPaymentMethods([]))
        dispatch(setUserError(response.error))
      }

      return () => response
    }
  }
}

export function deletePaymentMethod(loggedInUser, token) {
  return async function (dispatch) {
    let user = loggedInUser || {}
    let { authentication_token } = user

    if (authentication_token) {
      const request = {
        url: `${LOAD_PAYMENT_METHODS_URL}/${token}`,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }

      const response = await deleteJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(getPaymentMethods(loggedInUser))
      } else {
        dispatch(setPaymentMethods([]))
        dispatch(setUserError(response.error))
      }

      return () => response
    }
  }
}

export function clearPaymentMethods() {
  return async function (dispatch) {
    dispatch(setPaymentMethods([]))
  }
}

export function createPaymentMethod(user, nonce, type) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: CREATE_PAYMENT_METHODS_URL,
        headers: {
          Authorization: authentication_token
        },
        body: {
          payment_method_nonce: nonce,
          default: false
        }
      }

      const response = await postJSON(request.url, request.body, request.headers)

      if (response.result) {
        if (response.result.length === 0) {
          user.has_cc_info_saved = false
          dispatch(setUserData(user))
          trackEvent("payment-method-added", {
            paymentMode: type === PAYMENT_METHODS.CREDIT_CARD ? "Credit Card" : "Paypal",
            source: window.location.hash
          })
        } else {
          user.has_cc_info_saved = true
          dispatch(setUserData(user))
        }

        dispatch(setPaymentMethods(response.result))
      } else {
        dispatch(setPaymentMethods([]))

        dispatch(setUserError(response.error))
      }
      return response
    }
  }
}

export function getPaymentCards(user) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: BRAINTREE_PAYMENT_CARDS_URL,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }

      const response = await getJSON(request.url, request.body, request.headers)

      if (response.result) {
        if (response.result.length === 0) {
          user.has_cc_info_saved = false
          dispatch(setUserData(user))
        } else {
          user.has_cc_info_saved = true
          dispatch(setUserData(user))
        }

        dispatch(setPaymentMethods(response.result))
      } else {
        dispatch(setPaymentMethods([]))

        dispatch(setUserError(response.error))
      }
      return response
    }
  }
}

export function getBraintreePaymentToken(user) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(GET_PAYMENT_TOKEN_URL, {}, request.headers)
      return response
    }
  }
}

export function removePaymentCard(user, token) {
  return async function (dispatch) {
    let { authentication_token } = user

    if (authentication_token) {
      const request = {
        url: `${BRAINTREE_PAYMENT_CARDS_URL}/${token}`,
        headers: {
          Authorization: authentication_token
        },
        body: {}
      }

      const response = await deleteJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(getPaymentMethods(user))
      } else {
        dispatch(setPaymentMethods([]))
        dispatch(setUserError(response.error))
      }

      return response
    }
  }
}

export function setDefaultPaymentCard(user, token) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: `${BRAINTREE_PAYMENT_CARDS_URL}/${token}`,
        headers: {
          Authorization: authentication_token
        },
        body: {
          default: true
        }
      }

      const response = await putJSON(request.url, request.body, request.headers)

      if (response.result) {
        dispatch(setPaymentMethods(response.result))
      } else {
        dispatch(setPaymentMethods([]))
        dispatch(setUserError(response.error))
      }

      return response
    }
  }
}

export function makeBraintreePayment(user, data) {
  return async function (dispatch) {
    let { authentication_token } = user
    if (authentication_token) {
      const request = {
        url: TIRE_ORDER_CREATION_URL,
        headers: {
          Authorization: authentication_token
        },
        body: {
          shop_id: data.shop_id,
          vehicle_id: data.vehicle_id,
          token: data.token,
          amount: data.amount,
          braintree_customer_id: data.braintree_customer_id,
          appointment_datetime: data.appointment_datetime,
          walk_in_time_range: data.walk_in_time_range,
          payment_type: data.payment_type,
          tax: data.tax,
          tires: data.tires,
          note: data.note,
          transaction_fee: data.transaction_fee,
          supplier: data.supplier,
          tire_image: data.tire_image,
          tire_brand: data.tire_brand,
          tire_details: data.tire_details
        }
      }

      const response = await postJSON(request.url, request.body, request.headers)
      return response
    }
  }
}
