import React from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"

export function NoChargeBanner() {
  const { t } = useTranslation("common")
  return (
    <div className="no-charge-banner-container">
      <div className="text-conatiner">
        <p className="primary-text">{t("noChargePrimaryText")}</p>
        <p className="meta-text">{t("noChargeMetaText")}</p>
      </div>
      <div className="caradvise-container">
        <ReactSVG src="images/caradvise_shield.svg" svgStyle={{ width: "54px", height: "50px" }} />
      </div>
    </div>
  )
}
