import React, { useEffect, useState } from "react"
import GoBackLogo from "../../../images/rewards/back.svg"
import ReactSVG from "react-svg"
import RewardUsedTile from "./RewardUsedTile"
import RewardServices from "./RewardServices"
import { withRouter } from "react-router-dom"
import { routerPaths } from "../../../v1/constants/paths"
import { withTranslation } from "react-i18next"
import { getEarnedMiles } from "../../../v1/actions/rewards"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { connect } from "react-redux"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const RewardsHistoryPage = ({
  history,
  getEarnedMiles,
  t,
  earnedMiles,
  burnedMiles,
  pendingMiles
}) => {
  const [isApiInProgress, setIsApisInProgress] = useState(false)
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const handleGoBack = () => {
    history.goBack()
  }

  if (isRewardsFeature !== undefined && !isRewardsFeature) {
    history.push({
      pathname: routerPaths.dashboard
    })
  }

  const getEarnAndBurnMiles = async () => {
    setIsApisInProgress(true)
    try {
      const rewardsRes = await getEarnedMiles()

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("rewards:errorTitle"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    } catch (error) {
      console.log(t("errorInMilesEarnedCard"), error)
    } finally {
      setIsApisInProgress(false)
    }
  }

  useEffect(() => {
    getEarnAndBurnMiles()
  }, [])

  return (
    <div className="reward-history-container">
      <div className="reward-header-box">
        <ReactSVG className="reward-history-logo" onClick={handleGoBack} src={GoBackLogo} />
        <p className="reward-history-header">{t("rewardsHistory")}</p>
      </div>

      <div className="reward-history-tile">
        {isApiInProgress ? (
          [...new Array(3)].map(() => <div className="earn_burn_shimmer"></div>)
        ) : (
          <>
            <RewardUsedTile
              title={t("totalEarned")}
              value={`${earnedMiles ? numberFormater(earnedMiles) : 0} ${t("miles")}`}
            />
            <RewardUsedTile
              title={t("totalUsed")}
              value={`${burnedMiles ? numberFormater(burnedMiles) : 0} ${t("miles")}`}
            />
            <RewardUsedTile
              title={t("totalPending")}
              value={`${pendingMiles ? numberFormater(pendingMiles) : 0} ${t("miles")}`}
            />
          </>
        )}
      </div>

      <RewardServices />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    earnedMiles: state.rewards && state.rewards.earnedMile,
    burnedMiles: state.rewards && state.rewards.burnedMiles,
    pendingMiles: state.rewards && state.rewards.pendingMiles
  }
}

export default connect(mapStateToProps, { getEarnedMiles })(
  withRouter(withTranslation("rewards")(RewardsHistoryPage))
)
