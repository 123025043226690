import React, { useEffect, useRef } from "react"
import { Button, Image } from "semantic-ui-react"
import ReactSVG from "react-svg"
import ServiceRow from "./CartServiceRow"
import MaintenanceScheduleServices from "./MaintenanceScheduleServicesSection"
import { Link } from "react-router-dom"
import { navigationPaths, routerPaths } from "../../../v1/constants/paths"
import NoAddedService from "../Services/NoAddedServices"
import DefaultShopLogo from "../../../images/default_shop_logo.svg"
import { DEFAULT_IMG_URL } from "../../../v1/constants/confirmDetails"
import { fetchVehicleName } from "../../../v1/helpers/vehicleHelpers"
import EarnMilesPerOrder from "../Rewards/EarnMilesPerOrder"
import { SCROLL } from "../../../shared_component/constants/rewards"
import { OPEN_EXPANDED_MAP_VIEW } from "../../../v1/constants/shops"

const ServiceSection = ({
  cart,
  cartQuantity,
  t,
  updateCart,
  user,
  closeSidebar,
  shop,
  isCart = true,
  activeVehicle,
  isFirstOrder,
  close,
  onScrollChange,
  isFooterExpanded,
  showModifyAppointment,
  isMiniCart
}) => {
  const scrollableRef = useRef(null)

  const handleScroll = () => {
    if (scrollableRef.current) {
      const isScrolled = scrollableRef.current.scrollTop > 0
      if (isScrolled && isFooterExpanded) {
        onScrollChange(scrollableRef.current.scrollTop)
      }
    }
  }

  const openExpandedMapView = async () => {
    const data = {
      show: true,
      hideCart: false
    }
    const events = new CustomEvent(OPEN_EXPANDED_MAP_VIEW, { detail: data })
    document.dispatchEvent(events)
    await close()
  }

  useEffect(() => {
    if (isFooterExpanded) {
      scrollableRef.current && scrollableRef.current.addEventListener(SCROLL, handleScroll)
    }
    return () => {
      scrollableRef.current && scrollableRef.current.removeEventListener(SCROLL, handleScroll)
    }
  }, [isFooterExpanded])

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const shouldShowFooter =
    (shop && !shop.cdk_dealer && (shop || (cart.total_savings && cart.total_savings > 0))) || false
  return (
    <div className="side-panel-service-section-parent">
      {cart && cart.order_services.length > 0 ? (
        <div className="side-panel_services-section" ref={scrollableRef}>
          <div className="side-panel_services_container">
            <div className="side-panel-services-shop-section">
              <div className="shop-name-section">
                <div className="shop-label-header">{t("shopLabel")}</div>
                {shop && (
                  <div onClick={openExpandedMapView}>
                    {!showModifyAppointment && (
                      <span className="change_link-button">{t("schedule:changeLabel")}</span>
                    )}
                  </div>
                )}
              </div>
              {shop ? (
                <div className="shop-details-section">
                  <div className="shop-name-section">
                    <div className="shop-logo-section">
                      <div className="shop_name">{shop.name}</div>
                    </div>
                  </div>
                  <div className="selected-shop-address">
                    {shop.address_line1}, {shop.city}, {shop.state} {shop.zip}
                  </div>
                </div>
              ) : (
                <div className="no-shop-selected-text">{t("noShopSelectedLbl")}</div>
              )}
            </div>
            <div className="cart-horizontal-divider"></div>
            {activeVehicle && (
              <div className="side-panel-services-active-vehicle-section">
                <div className="active-vehicle-label-header">{t("activeVehicleLabel")}</div>
                <div className="active-vehicle-details">
                  <div>{fetchVehicleName(activeVehicle)}</div>
                </div>
              </div>
            )}
            <div className="cart-horizontal-divider"></div>
            <div className="side-panel_services-header">
              <p className="secondary-font headings-label">{`${t(
                "servicesLabel"
              )} (${cartQuantity})`}</p>
              {(isCart || showModifyAppointment || (cart && !cart.is_tire_order)) && (
                <Link
                  to={{ pathname: navigationPaths.addServices(), state: 1 }}
                  onClick={() => closeSidebar()}
                  className="responsive-button"
                >
                  <Button basic>
                    <span className="secondary-font mobile-text">{t("addServicesLabel")}</span>
                    <ReactSVG src="images/add_icon_vector.svg" />
                    <span className="secondary-font text">{t("addMoreServicesLabel")}</span>
                  </Button>
                </Link>
              )}
            </div>
            {!shop && (
              <div className="side-panel-services-label">
                <ReactSVG src="images/info_icon_vector.svg" />
                <span>{t("pricesShownLabel")}</span>
              </div>
            )}
            {cart &&
              cart.order_services.map((os, index) => (
                <ServiceRow
                  index={index}
                  key={os.id}
                  os={os}
                  pe={os.price_estimate}
                  order={cart}
                  updateCart={updateCart}
                  user={user}
                  t={t}
                  isCart={isCart}
                  isMiniCart={isMiniCart}
                />
              ))}
            {isCart && (
              <MaintenanceScheduleServices
                vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
                isMiniCart={isMiniCart}
              />
            )}
          </div>
          {isFirstOrder && isRewardsFeature && (
            <div className="earn-miles-container-cart">
              <EarnMilesPerOrder closeSidebar={close} />
            </div>
          )}
        </div>
      ) : (
        <div className="side-panel_services-section side-panel_service_empty_cart">
          <NoAddedService closeSidebar={closeSidebar} />
          <MaintenanceScheduleServices
            vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
            isMiniCart={isMiniCart}
          />
        </div>
      )}
    </div>
  )
}

export default ServiceSection
