import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Image } from "semantic-ui-react"
import MagicLinkSuccess from "../../../images/magic-link/magic-link-lock-success.svg"
import { formatPhoneNumber, formatSentence } from "../../utils/stringHelpers"
import { KEYWORDS, OPTIONS } from "../../constants/common"
import Timer from "../../elements/Timer"
import { sendMagicLink } from "../../../v1/actions/user"
import MiniLoader from "../../../v1/components/MiniLoader"

class SuccessRedirectComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timerKey: 0, // Initialize a key for the Timer component
      isResending: false
    }
  }

  handleResend = async () => {
    const { redirectionType, redirectionValue, sendMagicLink, t } = this.props
    let data = {
      value: redirectionValue,
      type: redirectionType
    }
    this.setState({ isResending: true })
    const response = await sendMagicLink({ data })
    if (response.result && !response.result.error) {
      this.setState((prevState) => ({
        timerKey: prevState.timerKey + 1
      }))
    }
    this.setState({ isResending: false })
  }
  // openMailApp = (event) => {
  //   event.preventDefault();
  //   const url = 'message://';
  //   window.location.href = url;
  // };
  render() {
    const { redirectionType, redirectionValue, isLoading, t } = this.props
    const { timerKey, isResending } = this.state
    const informationLabel = `${t(
      redirectionType === OPTIONS.EMAIL ? "emailInfoLink" : "phoneInfoLabel",
      {
        value:
          redirectionType !== OPTIONS.EMAIL
            ? `+1 ${formatPhoneNumber(redirectionValue)}`
            : redirectionValue.toLowerCase()
      }
    )}`
    return (
      <div className="magic-link-redirect-container">
        <div className="magic-link-type-description">
          <Image src={MagicLinkSuccess} />
          <div className="magic-link-header-text">
            {redirectionType === OPTIONS.EMAIL ? t("emailInboxLink") : t("phoneInboxLabel")}
          </div>
          <div className="magic-link-text">{formatSentence(informationLabel, 700)}</div>
          <div className="magic-link-text-description">
            {formatSentence(t("phoneInboxLinkText"), 500)}
          </div>
        </div>
        <div className="redirect-button-container">
          {/* Commented for Future use */}
          {/* {redirectionType === OPTIONS.EMAIL && (
            <a href="#" onClick={(e) => this.openMailApp(e)} className="magic-link-button">
              {t("openEmailAppLabel")}
            </a>
          )} */}
          {isResending ? (
            <MiniLoader inverted={false} />
          ) : (
            <Timer
              key={timerKey}
              startTime={30}
              endTime={0}
              direction={KEYWORDS.DESC}
              render={({ resendTime, isTimerFinished }) => (
                <div className="timer-span">
                  <span
                    onClick={isTimerFinished ? () => this.handleResend() : undefined}
                    className={`button-link-orange${isTimerFinished ? "" : " disabled"}`}
                  >
                    {t("sendAgainLabel")}
                  </span>
                  {!isTimerFinished && (
                    <span className="timer-text">
                      {` ${t("inLabel")} ${resendTime} ${t("secondsLabel")}`}
                    </span>
                  )}
                </div>
              )}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  return {
    user,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading
  }
}

export default connect(mapStateToProps, { sendMagicLink })(
  withTranslation("signIn")(SuccessRedirectComponent)
)
