import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../v1/constants/application"

export function ServicePriceRangeSpan({ high, low }) {
  return (
    <span>
      {numberFormatToCurrency(low)}&nbsp;-&nbsp;{numberFormatToCurrency(high)}
    </span>
  )
}

export default function SearchServicesPriceRange({
  highPriceEstimate,
  lowPriceEstimate,
  shop,
  activeVehicle
}) {
  const { t } = useTranslation("priceRange")
  let str = <span className="price-range-text">{t("seeEstimatesLabel")}</span>
  if (shop && shop.cdk_dealer) {
    str = t("common:seeDealerPriceLbl")
  } else if (activeVehicle && activeVehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
    str = t("orderServicePriceRow:pricedAtShop")
  } else if (
    highPriceEstimate &&
    lowPriceEstimate &&
    highPriceEstimate.shop_price_estimate != lowPriceEstimate.shop_price_estimate
  )
    str = (
      <ServicePriceRangeSpan
        high={highPriceEstimate.shop_price_estimate}
        low={lowPriceEstimate.shop_price_estimate}
      />
    )
  else if (highPriceEstimate) {
    str = <span>{numberFormatToCurrency(highPriceEstimate.shop_price_estimate)}</span>
  }

  return (
    <Fragment>
      <strong>{str}</strong>
    </Fragment>
  )
}
