import { gaJSON, trackFirebaseAnalytics } from "./googleAnalyticsHelpers"

const SESSION_TIMEOUT = 30 * 60 * 1000 // 30 minutes
const LAST_SESSION_START = "last_session_start"

export const startNewSession = (params = {}) => {
  const now = Date.now()
  localStorage.setItem(LAST_SESSION_START, now.toString())
  trackFirebaseAnalytics({
    event: gaJSON.session_start,
    params: params
  })
}

export const shouldStartNewSession = () => {
  const lastSessionStart = parseInt(localStorage.getItem(LAST_SESSION_START), 10)
  const now = Date.now()
  return isNaN(lastSessionStart) || now - lastSessionStart > SESSION_TIMEOUT
}

export const clearSession = () => {
  localStorage.removeItem([LAST_SESSION_START, "splitUserID"])
}
