import React, { useMemo, useState } from "react"
import { CSS_PERCENTAGE } from "../constants/rewards"
import { useTranslation } from "react-i18next"
import { isFinite } from "lodash"

const RangeSlider = ({
  selectedMiles,
  slideValue,
  handleSliderValue,
  minMiles,
  maxMiles,
  step = 1
}) => {
  const [sliderValue, setSliderValue] = useState(selectedMiles)

  const { t } = useTranslation("rewards")

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value)
    handleSliderValue(event.target.value)
  }

  const calculatePercentage = (value) => {
    const valuePercentage = ((value - minMiles) / (maxMiles - minMiles)) * 100
    return isNaN(valuePercentage) || !isFinite(valuePercentage) ? 89 : valuePercentage
  }

  const calculateTooltipPosition = () => {
    const percentage = calculatePercentage(sliderValue)

    if (percentage <= 26) {
      return percentage + 48 + "%"
    } else if (percentage <= 90) {
      return percentage + 60 + "%"
    } else {
      return percentage + 50 + "%"
    }
  }

  const trackStyle = useMemo(() => {
    return {
      [CSS_PERCENTAGE]: `${calculatePercentage(sliderValue)}%`
    }
  }, [sliderValue])

  const thumbTooltip = useMemo(() => {
    return {
      [CSS_PERCENTAGE]: calculateTooltipPosition()
    }
  }, [sliderValue])

  return (
    <div className="custom-slider-container">
      <div className="tooltip">
        <p className="slider-value" style={thumbTooltip}>
          {sliderValue} {t("miles")} = ${slideValue}
        </p>
      </div>
      <input
        type="range"
        min={sliderValue === minMiles && sliderValue === maxMiles ? 0 : minMiles}
        max={maxMiles}
        step={step}
        value={sliderValue}
        onChange={
          sliderValue === minMiles && sliderValue === maxMiles ? () => {} : handleSliderChange
        }
        className="custom-range-slider"
        style={trackStyle}
      />
    </div>
  )
}

export default RangeSlider
