export const FIRESTONE = "firestone"
export const HIBDON_TIRES_PLUS = "hibdon_tires_plus"
export const MICHEL_TIRES_PLUS = "michel_tires_plus"
export const TIRES_PLUS = "tires_plus"
export const WHEEL_WORKS = "wheel_works"
export const RATING_TAG = "rating"
export const MAP_1 = "MAP_1.0"
export const MAP_2 = "MAP_2.0"
export const MARKER = "Marker"
export const SHOPLIST = "ShopList"
export const OPEN_EXPANDED_MAP_VIEW = "openExpandedMapView"
