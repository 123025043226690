import React from "react"

import { ShimmerCategoryItem } from "react-shimmer-effects"

export const ShopListShimmer = ({ count = 3 }) => {
  return Array(count)
    .fill(0)
    .map((_, i) => (
      <div className="shopListViewContainer">
        <div key={i} className="shopItem">
          <ShimmerCategoryItem />
        </div>
      </div>
    ))
}

export default ShopListShimmer
