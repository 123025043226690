import React from "react"
import RemoveShopX from "./RemoveShopX"
import { Link } from "react-router-dom"
import { isEbayCart } from "../../helpers/affiliationHelpers"
import { capitalizeEachWord } from "../../../shared_component/utils/stringHelpers"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import { useTranslation } from "react-i18next"

export default function ShopInformation({ orderLikeObject, shopLikeObject, user }) {
  if (!shopLikeObject) return null

  const isEbay = isEbayCart(user) && orderLikeObject.shop
  const shopIsRemovable = !orderLikeObject.status && !isEbay
  const { t } = useTranslation("shopInformation")
  return (
    <div>
      <p className="secondary-font">
        <small>
          <strong>{t("shopLocationLbl")}</strong>
        </small>
        {shopIsRemovable && <RemoveShopX />}
      </p>
      <hr />
      <p>
        <span>{capitalizeEachWord(shopLikeObject.name)}</span>
        <br />
        <span>{capitalizeEachWord(shopLikeObject.address_line1)}</span>
        <br />
        <span>
          {capitalizeEachWord(shopLikeObject.city)}, {shopLikeObject.state}, {shopLikeObject.zip}
        </span>
      </p>
      {shopIsRemovable && (
        <p className="cart-change-shop-cta secondary-font">
          <Link
            onClick={() => {
              // trackEvent(EVENT_NAME.changeShopClicked, {
              //   ...getAdditionalProps({ shouldIncludeShopAddress: false }),
              //   existingShopAddress: getShopAddress(shopLikeObject),
              //   existingShopCity: shopLikeObject.city,
              //   // "existing country": shopLikeObject.country, not getting 'country' in shop response
              //   vehicleName: getVehicleName(getActiveVehicle())
              // })
              GAEvent(GAEventCategories.CART, "change-shop-cart", "Change Shop")
            }}
            to="/selectShop"
          >
            {t("changeShopLbl")}
          </Link>
        </p>
      )}
    </div>
  )
}
