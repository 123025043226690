import { Item } from "semantic-ui-react"
import {
  AVAILABLE_GLOVEBOX_UPLOADED,
  AVAILABLE_GLOVEBOX_UPDATED,
  AVAILABLE_GLOVEBOX_LOADED,
  AVAILABLE_GLOVEBOX_DELETED,
  LOAD_ALL_STATES,
  ALL_VEHICLE_GLOVEBOX_DATA
} from "../actions/glovebox"

export default function glovebox(state = {}, action = {}) {
  switch (action.type) {
    case ALL_VEHICLE_GLOVEBOX_DATA:
      return {
        ...state,
        allVehicleGloveboxData: action.payload
      }
      break
    case AVAILABLE_GLOVEBOX_UPLOADED:
      return {
        ...state,
        availableGlovebox: [...state.availableGlovebox, action.payload.glovebox_items]
      }
      break
    case AVAILABLE_GLOVEBOX_UPDATED:
      return {
        ...state,
        availableGlovebox: action.payload.glovebox_items
      }
      break
    case AVAILABLE_GLOVEBOX_LOADED:
      return {
        ...state,
        availableGlovebox: action.payload.glovebox_items || []
      }
      break
    case AVAILABLE_GLOVEBOX_DELETED:
      const availableGlovebox = state.availableGlovebox.filter((ag) => {
        return ag.id !== action.payload.id
      })
      return {
        ...state,
        availableGlovebox: availableGlovebox
      }

      break
    case LOAD_ALL_STATES:
      return {
        ...state,
        states: action.payload
      }

    default:
      return { ...state, error: null }
  }
}
