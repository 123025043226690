import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { updateInfo } from "../../actions/user"
import { Button, Form } from "semantic-ui-react"
import {
  EMAIL_REGEX,
  ZIP_CODE_REGEX,
  POSTAL_CODE_REGEX,
  COUNTRY_CODE
} from "../../../shared_component/utils/stringHelpers"
import { withTranslation } from "react-i18next"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import zipcodes from "zipcodes"
import Notifications from "../Notifications"
import store from "../../../shared_component/utils/configureStore"
import { getSubdomain } from "../Config/StylesheetInjector"
import _ from "lodash"

class UpdateDetailsModal extends PureComponent {
  constructor(props) {
    super(props)

    const { firstName, lastName, cellPhone, email, zip, language } = props
    this.state = {
      isSubmitting: false,
      fields: {
        firstName: {
          name: "First Name",
          value: firstName,
          invalid: false,
          validators: ["_isPresent"]
        },
        lastName: {
          name: "Last Name",
          value: lastName,
          invalid: false,
          validators: ["_isPresent"]
        },
        cellPhone: {
          name: "Mobile Phone",
          value: cellPhone,
          invalid: false,
          validators: ["_isPresent", "_isValidCellPhone"]
        },
        email: {
          name: "Email",
          value: email,
          invalid: false,
          validators: ["_isPresent", "_isEmailValid"]
        },
        zip: {
          name: "Zip Code",
          value: zip,
          invalid: false,
          validators: ["_isPresent", "_isZipCodeValid"]
        },
        language: { name: "Language", value: language, invalid: false, validators: ["_isPresent"] },
        newPassword: {
          name: "New Password",
          value: null,
          invalid: false,
          validators: ["_isPresent"]
        },
        confirmNewPassword: {
          name: "Confirm New Password",
          value: null,
          invalid: false,
          validators: ["_isPasswordMatched"]
        }
      },
      open: props.open
    }
  }

  closeModal = () => {
    this.setState({ open: false })
    this.props.handleModal && this.props.handleModal()
  }

  _onFieldChange = (key, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: this._setAndValidateField(key, value)
      }
    })
  }

  _onNumberChange = (key, value) => {
    const numericRegex = /^\d*$/

    const isOnlyNumeric = numericRegex.test(value)

    if (isOnlyNumeric) {
      this.setState({
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      })
    }
  }

  _setAndValidateField = (key, value) => {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _isPresent = (value) => {
    return !!value
  }

  _isEmailValid(value) {
    return EMAIL_REGEX.test(value)
  }

  _isZipCodeValid(value) {
    if (_.includes(getSubdomain(), "-ca")) {
      return POSTAL_CODE_REGEX.test(value)
    } else return ZIP_CODE_REGEX.test(value)
  }

  _validateFields = (callback) => {
    if (
      !this._validateEmail() ||
      !this._validatePasswordsMatch() ||
      !this._validateZipCode() ||
      !this._validateCellPhone()
    )
      return
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ fields }, () => {
      if (firstInvalidKey) this.showError(`${t("errorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`)
      else if (callback) callback()
    })
  }

  showError = (error) => {
    const { t } = this.props
    store.dispatch(
      Notifications.error({
        title: t("errorTitle"),
        message: error,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  showAlert = (message) => {
    const { t } = this.props
    store.dispatch(
      Notifications.success({
        title: t("successTitle"),
        message: message,
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Dismiss"
        }
      })
    )
  }

  _validateEmail = () => {
    const { fields } = this.state
    const email = fields.email.value
    const { t } = this.props

    if (!this._isEmailValid(email)) {
      this.showError(t("invalidEmailMessage"))
      return false
    }
    return true
  }

  _validateZipCode = () => {
    const { fields } = this.state
    const zipCode = fields.zip.value
    const { t } = this.props

    if (!this._isZipCodeValid(zipCode)) {
      this.showError(t("invalidZipCodeMessage"))
      return false
    }
    return true
  }

  _validatePasswordsMatch = () => {
    const { fields } = this.state
    const newPassword = fields.newPassword.value
    const confirmNewPassword = fields.confirmNewPassword.value
    const { t } = this.props

    if (newPassword !== confirmNewPassword) {
      this.showError(t("passwordNotMatchedMessage"))
      return false
    }

    return true
  }

  _isPasswordMatched = (value) => {
    return value === this.state.fields.newPassword.value
  }

  _isValidCellPhone = (value) => {
    return value && value.length == 10 ? true : false
  }

  _validateCellPhone = () => {
    const { fields } = this.state
    const { t } = this.props
    const cellPhone = fields.cellPhone.value
    if (!this._isValidCellPhone(cellPhone)) {
      this.showError(t("invalidCellPhoneMessage"))
      return false
    }
    return true
  }

  _isValidZip = (value) => {
    if (value.length !== 5) {
      return false
    } else {
      const result = zipcodes.lookup(value)
      return result !== undefined
    }
  }

  saveDetails = () => {
    const { t } = this.props
    const { fields } = this.state
    const { firstName, lastName, email, cellPhone, zip, newPassword, language } = fields
    this._validateFields(async () => {
      const response = await this.props.updateInfo({
        firstName: firstName.value,
        lastName: lastName.value,
        cellPhone: cellPhone.value,
        zip: zip.value,
        email: email.value,
        language: language.value,
        password: newPassword.value,
        displayErrorNotification: true,
        is_password_changed_from_prompt: true
      })
      if (response === true) {
        await identifyEvent({
          traits: {
            zip: fields.zip.value,
            phone: COUNTRY_CODE.concat(fields.cellPhone.value),
            firstNameWithSpace: fields.firstName.value,
            lastNameWithSpace: fields.lastName.value,
            email: fields.email.value
          }
        })
        trackEvent("profile-updated", {
          firstNameWithSpace: fields.firstName.value,
          lastNameWithSpace: fields.lastName.value,
          phone: COUNTRY_CODE.concat(fields.cellPhone.value),
          email: fields.email.value,
          zip: fields.zip.value
        })
        this.showAlert(t("infoUpdatedAlertMessage"))
        this.closeModal()
      }
    })
  }

  render() {
    const { t, isGenericUser } = this.props
    const { isSubmitting, open, fields } = this.state
    const {
      firstName,
      lastName,
      email,
      cellPhone,
      zip,
      newPassword,
      confirmNewPassword,
      language
    } = fields

    return (
      <Modal
        isOpen={open}
        onAfterOpen={this.afterOpenModal}
        // onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        className="modal-dialog"
      >
        <div className="modal-header-close">
          <a className="modal-header-close-button" onClick={() => this.closeModal()}>
            <FontAwesomeIcon className="ca-gray" icon={faTimes} size="2x" />
          </a>
        </div>
        <div className="modal-title">{t("updateInfoLbl")}</div>
        <div className="modal-dialog-content">
          <div style={{ textAlign: "left", marginBottom: "15px" }}>{t("updateInfoText")}</div>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                value={firstName.value}
                onChange={(value) => this._onFieldChange("firstName", value.target.value)}
                fluid
                label={t("firstNameLbl")}
              />
              <Form.Input
                value={lastName.value}
                onChange={(value) => this._onFieldChange("lastName", value.target.value)}
                fluid
                label={t("lastNameLbl")}
              />
            </Form.Group>
            <Form.Input
              value={email.value}
              onChange={(value) => this._onFieldChange("email", value.target.value)}
              fluid
              label={t("emailLbl")}
              readOnly={!isGenericUser}
              disabled={!isGenericUser}
            />
            <Form.Group widths="equal" style={{ alignItems: "flex-end" }}>
              <Form.Input
                value={cellPhone.value}
                onChange={(value) =>
                  this._onNumberChange("cellPhone", value.target.value.replace(/[^0-9]/g, ""))
                }
                fluid
                label={t("mobilePhoneLbl")}
                // maxLength={10}
              />
              <Form.Input
                value={zip.value}
                onChange={(value) => this._onFieldChange("zip", value.target.value)}
                fluid
                label={t("zipCodeLbl")}
              />
            </Form.Group>
            <Form.Group widths="equal" style={{ alignItems: "flex-end" }}>
              <Form.Input
                type="password"
                onChange={(value) => this._onFieldChange("newPassword", value.target.value)}
                fluid
                label={t("newPasswordPlaceholder")}
              />
              <Form.Input
                type="password"
                onChange={(value) => this._onFieldChange("confirmNewPassword", value.target.value)}
                fluid
                label={t("newConfirmPasswordPlaceholder")}
              />
            </Form.Group>
          </Form>
        </div>
        <div
          className="modal-dialog-buttons"
          style={this.props.centerButtons ? { justifyContent: "center" } : {}}
        >
          <Button
            disabled={
              isSubmitting ||
              !(
                firstName &&
                lastName &&
                cellPhone &&
                email &&
                zip &&
                newPassword &&
                confirmNewPassword
              )
            }
            content={t("saveText")}
            onClick={() => this.saveDetails()}
            style={{ backgroundColor: "#e95832", color: "white" }}
          />
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  let user = state.user || {}
  let vehicle = undefined
  let activeVehicleIndex = -1
  if (user.activeVehicleId) {
    activeVehicleIndex = user.vehicles.findIndex((v) => v.id === user.activeVehicleId)
  }
  vehicle = (user.vehicles && user.vehicles[activeVehicleIndex]) || {}
  return {
    loading: user.loading,
    isLoggedOut: !user.authentication_token,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    cellPhone: user.cellPhone || "",
    error: user.error,
    vehicleNumber: vehicle.vehicleNumber,
    zip: user.zip || "",
    language: user.language || "en",
    email: user.generic ? "" : user.email,
    isGenericUser: user.generic
  }
}

export default connect(mapStateToProps, { updateInfo })(
  withTranslation("settings")(UpdateDetailsModal)
)
