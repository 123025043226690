import React, { useRef } from "react"

import { connect } from "react-redux"

import Geosuggest from "react-geosuggest"
import { withTranslation } from "react-i18next"

import ArrowRight from "../../../images/shops/arrow-right.svg"
import ArrowLeft from "../../../images/shops/arrow-left.svg"
import EmptyShopLogo from "../../../images/shops/empty_shop_logo.svg"
import MyLocationImg from "../../../../../images/map/location.png"
import SponsoredShopLogo from "../../../../../images/dashboard/check-icon.svg"

import MapGL, { Marker, Popup, NavigationControl } from "react-map-gl"
import { Divider, Icon, Image } from "semantic-ui-react"
import ShopItemComponent from "./ShopItemComponent"
import ShopMapItem from "../../Map/components/ShopMapItem"
import MapControlContainer from "../../Map/components/MapControlContainer"
import { ProgressHUD, ProgressHUDMap } from "../../components/ProgressHUD"
import { suggestGeoSuggestClick } from "../../../helpers/geoLocationHelper"
import FeaturedLabel from "./FeaturedLabel"

const ICON_WIDTH = 60
const ICON_HEIGHT = 60
const SCROLL_OFFSET = 180

const navStyle = {
  position: "absolute",
  top: 100,
  right: 0,
  padding: "10px"
}

const MapWidgetExpanded = (props) => {
  const {
    shops,
    onClickMarker,
    selectedShop,
    t,
    user,
    updateViewport,
    viewPort,
    setShopSelected,

    shopSelected,
    setShowMapExpanded,
    showMapExpanded,
    onSelectShopClick,
    onSuggestSelect,
    hideFilters,
    position,
    setPosition,
    searchLocation = undefined
  } = props
  const height = Math.max(window.innerHeight * 0.65, 300)

  const scrollRef = useRef(null)

  const [vP, setVP] = React.useState(viewPort)

  const updateVP = (vp) => {
    setVP(vp)
  }
  const recenterMap = (lat, lng) => {
    if (lat) {
      const view = {
        latitude: lat - 0.1,
        longitude: lng,
        width: "-webkit-fill-available",
        height: `${height}px`,
        zoom: 9,
        borderRadius: "8px"
      }
      setVP(view)
    } else {
      setVP(viewPort)
    }
  }

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset
  }

  const onMarkerHover = (shop) => {
    recenterMap(shop.latitude, shop.longitude)
    setShopSelected(shop)
  }
  const onMarkerHoverRemove = () => {
    setShopSelected()
  }

  const onClosePopup = () => {
    setShopSelected(undefined)
  }

  React.useEffect(() => {
    if (shops && shops.length > 0) {
      setVP({
        ...viewPort,
        width: "100%",
        latitude: shops[0].latitude,
        longitude: shops[0].longitude
      })
    } else if (searchLocation) {
      setVP({
        ...viewPort,
        width: "100%",
        latitude: searchLocation.latitude,
        longitude: searchLocation.longitude
      })
    }
  }, [shops])

  return (
    <MapGL
      {...vP}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={updateVP}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      attributionControl={false}
      style={{ borderRadius: "20px", display: "flex" }}
    >
      <React.Fragment>
        {searchLocation && searchLocation.latitude && searchLocation.longitude && (
          <Marker latitude={searchLocation.latitude} longitude={searchLocation.longitude}>
            <Image
              style={{ maxWidth: "30px" }}
              src={MyLocationImg}
              className="current-position-image"
            />
          </Marker>
        )}
        {shops &&
          shops.length > 0 &&
          shops.map((shop, index) => {
            const { latitude, longitude } = shop

            const isSelectedShop = shopSelected && shop.id === shopSelected.id
            const selectedShopMarkerBG = shop.shop_featured
              ? "sponsored-shop-selected-mapMarkerBG"
              : "mapMarkerSelectedBG"
            return (
              <React.Fragment key={`marker-${index}-fragment`}>
                <Marker
                  key={`marker-${index}`}
                  latitude={latitude}
                  longitude={longitude}
                  offsetLeft={-(ICON_WIDTH / 2)}
                  offsetTop={-ICON_HEIGHT}
                  className={`${isSelectedShop ? "onTop" : ""}`}
                >
                  <div className="map-marker-bg-image">
                    <Image
                      onClick={() => onMarkerHover(shop)}
                      src={
                        shop.shop_featured
                          ? SponsoredShopLogo
                          : shop.logo_url
                          ? shop.logo_url
                          : EmptyShopLogo
                      }
                      className={` 
                        marker-shop-logo markerLogoStyling
                        ${isSelectedShop ? selectedShopMarkerBG : "mapMarkerBG"}
                        ${shop.shop_featured ? "sponsored-shop-mapMarkerBG" : ""}
                      `}
                    />
                  </div>
                </Marker>

                {isSelectedShop && (
                  <Popup
                    className="onTop"
                    latitude={shopSelected.latitude}
                    longitude={shopSelected.longitude}
                    closeOnClick={false}
                    closeButton={false}
                    anchor="top"
                    onClose={() => onClosePopup()}
                  >
                    <FeaturedLabel shop={shopSelected} />
                    <ShopItemComponent
                      shop={shopSelected}
                      onClose={() => onClosePopup()}
                      isPopUpComponent={true}
                      showMapExpanded={true}
                      onSelectShopClick={onSelectShopClick}
                    />
                  </Popup>
                )}
              </React.Fragment>
            )
          })}
      </React.Fragment>
      <div className="geo_container">
        <Geosuggest
          onSuggestSelect={(suggest) => suggestGeoSuggestClick(suggest, onSuggestSelect)}
          placeholder="Search on Map"
          types={["geocode"]}
          country={user.country}
          ignoreTab={true}
          className="geosuggestContainerExpanded"
          suggestsClassName="search-suggests-list"
          suggestItemClassName="search-suggest-item"
          inputClassName={`searchFldShop smaller-width`}
          suggestItemActiveClassName="search-suggest-item-active"
          suggestsHiddenClassName="search-suggests-list-hidden"
          fixtures={[{ label: t("use-current-location"), custom: true }]}
        />
      </div>

      <div className="nav" style={navStyle}>
        <MapControlContainer
          recenterClick={() => recenterMap()}
          expandCompressClick={() => {
            hideFilters()
            setShowMapExpanded(!showMapExpanded)
          }}
          isExpanded={showMapExpanded}
          currentPositionClick={(pos) => setPosition(pos)}
        />
        {!showMapExpanded && <NavigationControl onViewportChange={updateViewport} />}
        <NavigationControl onViewportChange={updateVP} />
      </div>
      {props.loading && (
        <div
          style={{
            position: "relative",
            display: "flex",
            flex: 1,
            height: "80%",
            margin: "0rem auto",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {props.loading && <ProgressHUDMap />}
        </div>
      )}
      {shops && shops.length > 0 && !showMapExpanded && (
        <div className="shopListHorizontalContainer">
          <div className="iconContainer" onClick={() => scroll(-SCROLL_OFFSET)}>
            <Image src={ArrowLeft} height="20px" width="20px" />
          </div>
          <div className="shopListContainer" ref={scrollRef}>
            {shops.map((shop, index) => (
              <ShopMapItem
                isSelected={selectedShop && shop.id == selectedShop.id}
                key={index}
                shop={shop}
                setShopSelected={setShopSelected}
                onSelectShopClick={onSelectShopClick}
              />
            ))}
          </div>
          <div className="iconContainer" onClick={() => scroll(SCROLL_OFFSET)}>
            <Image src={ArrowRight} height="20px" width="20px" />
          </div>
        </div>
      )}
    </MapGL>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(MapWidgetExpanded))
