import React, { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import ServiceComponent from "./ServiceComponent"
import ServiceCard from "../Services/ServiceCard"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fetchOEMCurrentIntevals } from "../../../v1/actions/maintenanceSchedules"
import { fetchCarAdviseRecemendedServices } from "../../../v1/CarAdvise2/actions/maintenance"
import { ShimmerServiceCard } from "../Services/ShimmerServiceCard"
import { updateCart } from "../../../v1/actions/user"
import { ShimmerServiceComponent } from "../Services/ShimmerServiceComponent"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { formatText } from "../../../v1/helpers/stringHelpers"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

const MaintenanceScheduleServices = (props) => {
  const [maintenanceSchedulesServices, setMaintenanceScheduledServices] = useState([])
  const [recommendedSevices, setRecommendedSevices] = useState([])
  const [carAdviseServices, setCarAdviseServices] = useState([])
  const [loadingStates, setLoadingStates] = useState({})
  const [isLoading, setLoading] = useState(false)
  const { activeVehicle, cart, fetchOEMCurrentIntevals, fetchCarAdviseRecemendedServices } = props

  const getMaintenanceScheduledServices = async () => {
    setLoading(true)

    const current_interval =
      (activeVehicle && (await fetchOEMCurrentIntevals({ vehicle: activeVehicle }))) || null

    let interval = null
    let services = []

    if (activeVehicle) {
      interval = activeVehicle.miles
    }

    const oemServices =
      (current_interval &&
        current_interval.length > 0 &&
        current_interval[0].services.filter((service) => !service.is_completed)) ||
      []
    setRecommendedSevices(oemServices)
    const data = {
      vehicleId: props.activeVehicle && props.activeVehicle.id,
      interval: interval,
      interval_id:
        current_interval && current_interval.length > 0 && current_interval[0].interval_id
    }

    if (interval !== null) {
      const carAdviseRecommendedResponse = await fetchCarAdviseRecemendedServices(data)

      if (
        carAdviseRecommendedResponse &&
        carAdviseRecommendedResponse.result &&
        !carAdviseRecommendedResponse.result.errors
      ) {
        services =
          (carAdviseRecommendedResponse.result.length > 0 &&
            carAdviseRecommendedResponse.result.map((element) => {
              let isCompleted = false
              try {
                isCompleted = element.is_completed
              } catch (e) {}
              return element
            })) ||
          []
        setCarAdviseServices(services)
      }
    }
    const maintenanceScedulesServices = [...oemServices, ...services].filter((s) => !s.is_completed)
    setMaintenanceScheduledServices(maintenanceScedulesServices)
    setLoading(false)
  }

  useEffect(() => {
    const fetchInitialLoadingStates = async () => {
      try {
        const initialLoadingStates =
          maintenanceSchedulesServices &&
          maintenanceSchedulesServices.reduce((acc, service) => {
            acc[service.id || service.service_id || service.service_definition_id] = false
            return acc
          }, {})
        setLoadingStates(initialLoadingStates)
      } catch (error) {
        console.error(error)
      }
    }

    fetchInitialLoadingStates()
  }, [maintenanceSchedulesServices])

  useEffect(() => {
    getMaintenanceScheduledServices()
  }, [activeVehicle])
  const { t } = useTranslation("cartPanel")
  const addService = async (index, os, event) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [index]: true
    }))
    const { activeVehicle, isCart, order } = props
    let opts = {
      isOrder: !isCart && order && !order.is_tire_order,
      orderId: order && order.id,
      successCallback: async () => {
        trackFirebaseAnalytics({
          event: gaJSON.service_selected,
          params: {
            [gaJSON.service_name]: os.service_name,
            [gaJSON.service_type]: !os.position
              ? os.service_name
              : `${os.service_name} - ${os.position}`,
            [gaJSON.from_where]: window.location.hash,
            [gaJSON.vehicle_name]: activeVehicle
              ? `${activeVehicle.year || ""} ${activeVehicle.make.toUpperCase() || ""} ${
                  activeVehicle.model.toUpperCase() || ""
                }`
              : null
          }
        })
      }
    }
    try {
      await props.updateCart(
        [
          {
            service_definition_id: os.service_id || os.id || os.service_definition_id,
            event: event,
            prepaid: os.prepaid,
            position: os.position
          }
        ],
        props.cart.vehicle_id,
        undefined,
        undefined,
        opts
      )
      // Add Segment Event
    } catch (error) {
      console.log(error)
      return { error: error.message }
    } finally {
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [index]: false
      }))
    }
  }
  const filteredMaintenanceSchedulesServices =
    maintenanceSchedulesServices &&
    maintenanceSchedulesServices.filter(
      (rec) => cart && !cart.order_services.some((item) => item.id === rec.service_id)
    )
  const filteredRecommendedServices =
    recommendedSevices &&
    recommendedSevices.filter(
      (rec) => cart && !cart.order_services.some((item) => item.id === rec.service_id)
    )
  const filteredCarAdviseServices =
    carAdviseServices &&
    carAdviseServices.filter(
      (rec) => cart && !cart.order_services.some((item) => item.id === rec.service_id)
    )
  const nextMaintenanceMiles =
    (props.activeVehicle &&
      props.activeVehicle.current_interval_config &&
      props.activeVehicle.current_interval_config.display_value_abbr) ||
    "--"
  return (
    <>
      {!props.isFilter ? (
        <>
          {isLoading && (
            <div className="cart-summary-recommended-services-container">
              <div className="side-panel-recommended-service-parent">
                <ShimmerServiceCard count={2} />
              </div>
            </div>
          )}
          {!isLoading &&
          filteredMaintenanceSchedulesServices &&
          filteredMaintenanceSchedulesServices.length > 0 ? (
            <>
              {props.isCartSummary ? (
                <div className="cart-summary-recommended-services-container">
                  <div
                    className="side-panel_recommended-services-header"
                    style={{ top: `${cart && cart.order_services.length > 0 ? "50px" : "0px"}` }}
                  >
                    <p className="secondary-font headings-label">{t("youMightAlsoNeedLabel")}</p>
                    <div className="down-button">
                      <Icon name="angle double down" className="pulse-button" />
                    </div>
                  </div>
                  <div className="side-panel-recommended-service-parent">
                    {filteredMaintenanceSchedulesServices.map((os, index) => (
                      <ServiceComponent
                        key={index}
                        index={index}
                        service={os}
                        loadingStates={loadingStates}
                        addService={addService}
                        t={t}
                        isMostPopular={true}
                        activeVehicle={props.activeVehicle}
                        vehicleTypeId={props.activeVehicle && props.activeVehicle.vehicle_type_id}
                        isMiniCart={props.isMiniCart}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div className="side-panel_recommended-services-header">
                    <div className="secondary-font headings-label">
                      {t("manufacturerRecommendedLabel")}
                    </div>
                    {nextMaintenanceMiles && (
                      <div className="headings-metadata">
                        {formatText(
                          `${t("maintencanceScheduleText", {
                            miles: nextMaintenanceMiles
                          })}`,
                          "headings-metadata-label"
                        )}
                      </div>
                    )}
                    <div className="down-button">
                      <Icon name="angle double down" className="pulse-button" />
                    </div>
                  </div>
                  <div className="side-panel-recommended-service-parent">
                    {filteredMaintenanceSchedulesServices.map((os, index) => (
                      <ServiceComponent
                        key={os.id || os.service_id || os.service_definition_id}
                        isCheckbox={false}
                        index={os.id || os.service_id || os.service_definition_id}
                        service={os}
                        addService={addService}
                        loadingStates={
                          loadingStates[os.id || os.service_id || os.service_definition_id]
                        }
                        order={props.cart}
                        vehicleTypeId={props.activeVehicle && props.activeVehicle.vehicle_type_id}
                        isMostPopular={true}
                        isMiniCart={props.isMiniCart}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : null}
        </>
      ) : (
        <>
          {isLoading && <ShimmerServiceCard count={15} />}
          {!isLoading &&
            filteredRecommendedServices &&
            filteredRecommendedServices.map((os, index) => {
              return (
                <ServiceCard
                  key={os.id || os.service_id || os.service_definition_id}
                  isCheckbox={true}
                  index={os.id || os.service_id || os.service_definition_id}
                  service={os}
                  addService={addService}
                  loadingStates={loadingStates[os.id || os.service_id || os.service_definition_id]}
                  order={props.cart}
                  vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
                />
              )
            })}
          {!isLoading && filteredCarAdviseServices.length > 0 && (
            <div className="caradvise-recommended-label">{t("youMightAlsoNeedLabel")}</div>
          )}
          {!isLoading &&
            filteredCarAdviseServices &&
            filteredCarAdviseServices.map((os, index) => {
              return (
                <ServiceCard
                  key={os.id || os.service_id || os.service_definition_id}
                  isCheckbox={true}
                  index={os.id || os.service_id || os.service_definition_id}
                  service={os}
                  addService={addService}
                  loadingStates={loadingStates[os.id || os.service_id || os.service_definition_id]}
                  order={props.cart}
                  vehicleTypeId={activeVehicle.vehicle_type_id}
                />
              )
            })}
        </>
      )}
    </>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle,
    order
  }
}

export default connect(mapStateToProps, {
  fetchOEMCurrentIntevals,
  fetchCarAdviseRecemendedServices,
  updateCart
})(MaintenanceScheduleServices)
