import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import { Button, Image } from "semantic-ui-react"
import { getServiceNameForMap } from "../../../v1/helpers/serviceHelper"
import { formattedPrice, shopPrice } from "../Cart/CartServiceRow"
import DefaultServiceLogo from "../../../images/default_wrench_icon.svg"
import { connect } from "react-redux"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import MiniLoader from "../../../v1/components/MiniLoader"
import ModalDialog from "../../../v1/components/ModalDialog"
import { isEmpty } from "lodash"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../v1/constants/application"
import ServicePositionModal from "../Services/ServicePositionsModal"
import CornerRibbon from "../../../images/recommended-label.svg"

const events = new CustomEvent("cartSidePanelOpen", { detail: true })

const ServiceComponent = ({
  service,
  order = {},
  isCheckbox = false,
  isAdded = false,
  user,
  addService,
  index,
  activeVehicle,
  vehicleTypeId,
  isMostPopular,
  isMiniCart = false
}) => {
  const [isActive, setIsActive] = useState(false)
  const [showPositionModal, setShowPositionModal] = useState(false)
  const [selected, setSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isImageLoaded, setImageLoaded] = useState(true)

  const handleCardClick = () => {
    setIsActive(!isActive)
  }

  const { t } = useTranslation("common")
  // const { price_estimate } = service

  const closeModal = () => {
    setSelected(false)
    setShowPositionModal(false)
  }

  const addServiceWithPosition = async (index, service, position = undefined) => {
    closeModal()
    setIsLoading(true)
    const newOs = { ...service }
    if (position !== undefined && position !== "") {
      newOs.position = position
      newOs.service_option_id = service.service_id
    }
    await addService(index, newOs, "added")
    setIsLoading(false)
    document.dispatchEvent(events)
  }

  const addToCart = async (index, os, event) => {
    if (
      (os.positions && os.positions.length > 1) ||
      (os.service_options && os.service_options[0].positions.length > 1) ||
      (os.position && os.position.length > 1)
    ) {
      setSelected(true)
      setShowPositionModal(true)
    } else {
      setSelected(true)
      // Add a delay before setting isLoading to true
      await new Promise((resolve) => setTimeout(resolve, 500))
      // Adjust the delay time (in milliseconds) as needed
      setIsLoading(true)
      await addService(index, os, event)
      setSelected(false)
      setIsLoading(false)
      document.dispatchEvent(events)
    }
  }

  return (
    <div
      className={`recommended-service-row-container ${isActive ? "active" : ""}`}
      onClick={() => handleCardClick()}
    >
      <Image
        src={isImageLoaded && service && service.logo ? service.logo : DefaultServiceLogo}
        onError={() => setImageLoaded(false)}
        className="service-image-section"
      />
      <div className="service-info-section">
        <div className="service-name-header-section">
          <div className="service-name-info-section">
            {isAdded
              ? getServiceNameForMap(service)
              : (service && service.name) || (service && service.service_name)}
          </div>
          <div className="service-price-info-section">
            <ReactSVG src="images/CA_CheckIcon.svg" />
            <div className="service-price-info">
              {!isAdded && (!service.price_estimate || !service.high_price_estimate) ? (
                order.shop && order.shop.cdk_dealer ? (
                  <p className="price-text">{t("seeDealerPriceLbl")}</p>
                ) : vehicleTypeId && vehicleTypeId === MD_HD_VEHICLE_TYPE_ID ? (
                  <p className="price-text">{t("mdHdpricedAtShopLbl")}</p>
                ) : service.high_price_estimate &&
                  service.low_price_estimate &&
                  service.high_price_estimate.shop_price_estimate !=
                    service.low_price_estimate.shop_price_estimate ? (
                  <p className="price-range">
                    {" "}
                    {`${t("fromLabel")} ${numberFormatToCurrency(
                      service.low_price_estimate.shop_price_estimate
                    )} - ${numberFormatToCurrency(
                      service.high_price_estimate.shop_price_estimate
                    )}`}
                  </p>
                ) : service.high_price_estimate ? (
                  `${numberFormatToCurrency(service.high_price_estimate.shop_price_estimate)}`
                ) : service.price_range_from && service.price_range_to ? (
                  <p className="price-range">
                    {" "}
                    {`${t("fromLabel")} ${numberFormatToCurrency(
                      service.price_range_from
                    )} - ${numberFormatToCurrency(service.price_range_to)}`}
                  </p>
                ) : isEmpty(activeVehicle) ? (
                  <p className="price-text">{t("addTheVehicleLabel")}</p>
                ) : isEmpty(order.shop) ? (
                  <p className="price-text">{t("selectStoreLabel")}</p>
                ) : (
                  <p className="price-text">{t("orderServicePriceRow:discountPriceAtShop")}</p>
                )
              ) : null}
              {isAdded &&
                formattedPrice(
                  shopPrice(service.price_estimate, service),
                  t,
                  user,
                  order.shop,
                  service
                )}
            </div>
          </div>
        </div>
        {!isAdded ? (
          isCheckbox ? (
            !isLoading ? (
              <input
                id={service.name || service.service_name}
                type="checkbox"
                onChange={() => addToCart(index, service, "added")}
                checked={selected}
              />
            ) : (
              <div className={`ui active inline loader tiny`}></div>
            )
          ) : (
            <Button
              // content={isLoading ? <MiniLoader /> : t("addToCartLabel")}
              color="orange"
              className="service-card_add-cart-button"
              onClick={() => addToCart(index, service, "added")}
            >
              {isLoading ? (
                <MiniLoader />
              ) : (
                <>
                  {isMiniCart ? t("addServiceLabel") : ""}
                  <ReactSVG
                    src={"images/cart_icon_white.svg"}
                    svgStyle={{ width: "20px", height: "20px" }}
                  />
                  {!isMiniCart ? t("addToCartLabel") : ""}
                </>
              )}
            </Button>
          )
        ) : null}
      </div>
      {showPositionModal && (
        <ServicePositionModal
          closeModal={closeModal}
          isOpenModel={showPositionModal}
          onSave={addServiceWithPosition}
          serviceOptions={
            service.positions ||
            (service.service_options && service.service_options[0].positions) ||
            service.position
          }
          index={index}
          service={service}
          serviceName={service.name || service.service_name}
          activeVehicle={activeVehicle}
        />
      )}
      {isMostPopular && (
        <div className="corner-label-icon">
          <ReactSVG src={CornerRibbon} />
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.filter((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(ServiceComponent)
