import React, { PureComponent } from "react"
import AppstoreIcon from "../../images/icons/appstore-icon.svg"
import PlaystoreIcon from "../../images/icons/google-play-icon.svg"
import ReactSVG from "react-svg"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
export class AppBannerSignIn extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { banner, visible } = this.props

    const appImageStyle = { width: "100%", height: "100%" }
    if (visible) {
      return (
        <div style={{ color: "#fff" }}>
          <ReactSVG
            src={`/images/CarAdvise-LogoWhite.svg`}
            svgClassName="ca-logo"
            // style={{
            //   position: "relative",
            //   left: "50%",
            //   width: "60%",
            //   transform: "translateX(-50%)"
            // }}
          />
          {!window.location.href.includes("instacart-ca") && (
            <>
              <p
                className="banner-heading"
                style={{ textAlign: "center", fontSize: "22px", marginTop: "2em" }}
              >
                Download the CarAdvise
                <br />
                mobile app for an even greater experience!
              </p>
              <div>
                <div style={{ display: "inline-flex", marginLeft: "18%" }}>
                  <a
                    href="https://apps.apple.com/us/app/caradvise/id1118606915"
                    style={{ width: "34%", marginRight: "2%" }}
                    onClick={() =>
                      trackEvent("download-app-clicked", {
                        appleOrAndroid: "Apple",
                        source: "Sign In",
                        beforeOrAfterSignup: "Before Sign Up"
                      })
                    }
                  >
                    <img style={appImageStyle} src={AppstoreIcon} />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.caradvise.caradvise"
                    style={{ width: "40%" }}
                    onClick={() =>
                      trackEvent("download-app-clicked", {
                        appleOrAndroid: "Android",
                        source: "Sign In",
                        beforeOrAfterSignup: "Before Sign Up"
                      })
                    }
                  >
                    <img style={appImageStyle} src={PlaystoreIcon} />
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      )
    }
  }
}

AppBannerSignIn.defaultProps = {
  visible: true
}

export default AppBannerSignIn
