import React, { useState } from "react"

import { connect } from "react-redux"

import MapComponent from "./MapComponent2"

import { withTranslation } from "react-i18next"

import MapHeaderComponent from "../../Map/components/MapHeaderComponent"
import DisplayMessage from "../../../../components/ShopSearch/DisplayMessage"
import { getActiveVehicle } from "../../../../helpers/vehicleHelpers"

const ShopList2 = (props) => {
  const {
    ratingFiltersApplied,
    typeFiltersApplied,
    clearAllFilters,
    addorRemoveFilterItem,
    shops,
    sortingType,
    updateSortingType,
    onSuggestSelect,
    zip,
    handleApplyFilterClick,
    activeView,
    isCheckout = false,
    isShopSelected = false,
    count,
    showShopTiles,
    searchLocation = undefined,
    selectedShopResponse,
    handleSideCartPanel
  } = props

  const [selectedShop, setSelectedShop] = useState(undefined)
  const [view, setViewType] = useState(undefined)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isSortOpen, setIsSortOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(activeView || "map")
  const [showMapExpanded, setShowMapExpanded] = useState(true)
  const { user, t } = props

  const onPreSelect = (shop) => {
    setSelectedShop(shop)
  }

  const hideFilters = () => {
    setIsFilterOpen(false)
    setIsSortOpen(false)
  }
  const show = (flag) => {
    setShowMapExpanded(flag)
  }

  let vehicleTypeId
  if (user) {
    const activeVehicle = getActiveVehicle()
    vehicleTypeId = (activeVehicle && activeVehicle.vehicle_type_id) || null
  }

  return (
    <>
      {!showMapExpanded || window.innerWidth > 912 ? (
        <MapHeaderComponent
          activeTab={activeTab}
          setActiveTab={(val) => {
            hideFilters()
            setActiveTab(val)
          }}
          ratingFiltersApplied={ratingFiltersApplied}
          typeFiltersApplied={typeFiltersApplied}
          addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
          clearAllFilters={clearAllFilters}
          setSortingType={(value) => updateSortingType(value)}
          sortingType={sortingType}
          zip={shops && shops.length > 0 ? shops[0].zip : zip}
          setIsSortOpen={() => {
            setIsSortOpen(!isSortOpen)
            setIsFilterOpen(false)
            setSelectedShop(undefined)
          }}
          setIsFilterOpen={() => {
            setIsFilterOpen(!isFilterOpen)
            setIsSortOpen(false)
            setSelectedShop(undefined)
          }}
          isSortOpen={isSortOpen}
          isFilterOpen={isFilterOpen}
          isCheckout={isCheckout}
        />
      ) : null}

      <DisplayMessage message={t("common:mdHdShopsLabel")} vehicleTypeId={vehicleTypeId} />

      <MapComponent
        activeTab={activeTab}
        shops={shops}
        show={show}
        loading={props.loading}
        shopLoading={props.shopLoading}
        onClickMarker={onPreSelect}
        selectedShop={selectedShop}
        setShopSelected={(shop) => {
          hideFilters()
          setSelectedShop(shop)
        }}
        setViewType={(view) => setViewType(view)}
        view={view}
        onSuggestSelect={onSuggestSelect}
        handleShopSelect={props.handleShopSelect ? props.handleShopSelect : () => {}}
        setSortingType={(value) => updateSortingType(value)}
        sortingType={sortingType}
        ratingFiltersApplied={ratingFiltersApplied}
        typeFiltersApplied={typeFiltersApplied}
        addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
        clearAllFilters={clearAllFilters}
        zip={zip}
        hideFilters={hideFilters}
        setIsSortOpen={() => {
          setIsSortOpen(!isSortOpen)
          setIsFilterOpen(false)
          setSelectedShop(undefined)
        }}
        setIsFilterOpen={() => {
          setIsFilterOpen(!isFilterOpen)
          setIsSortOpen(false)
          setSelectedShop(undefined)
        }}
        isSortOpen={isSortOpen}
        isFilterOpen={isFilterOpen}
        handleApplyFilterClick={handleApplyFilterClick}
        isCheckout={isCheckout}
        isShopSelected={isShopSelected}
        count={count}
        showShopTiles={showShopTiles}
        searchLocation={searchLocation}
        selectedShopResponse={selectedShopResponse}
        handleSideCartPanel={handleSideCartPanel}
      />
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user }
}

export default connect(mapStateToProps)(withTranslation(["dashboardShopList", "common"])(ShopList2))
