import React, { useEffect, useState } from "react"
import TirePayment from "../../../v1/components/TirePayment"
import { useTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { BRAINTREE } from "../../../v1/constants/tires"

function PaymentIndex(props) {
  const [paymentCard, setPaymentCard] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState()
  const { cart, showBanner, showNoChargeBanner } = props

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    //commented for future support
    // showBanner("payment")
  }, [])

  useEffect(() => {
    if (paymentMethod || paymentCard) {
      props.paymentMethodSelected(paymentMethod, paymentCard)
    }
  }, [paymentMethod, paymentCard])

  return (
    <div>
      <TirePayment
        setPaymentCard={setPaymentCard}
        setPaymentMethod={setPaymentMethod}
        bookingDetails={cart && cart}
        totalAmountDue={cart && cart.estimated_total}
        shopDetails={cart && cart.shop}
        paymentMethod={paymentMethod}
        showNoChargeBanner={showNoChargeBanner}
      />
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(withTranslation("schedule")(PaymentIndex))
