import React, { useState } from "react"
import { Button, Image } from "semantic-ui-react"
import ProviderBenifits from "./ProviderBenifits"
import { useTranslation } from "react-i18next"
import RatingLogo from "../../images/ratings.svg"
import ReactSVG from "react-svg"
import InsuranceReview from "./InsuranceReview"
import { AUTO_GLASS } from "../../shared_component/constants/autoglass"

const InsuranceProviders = ({
  image,
  benefits,
  providerName,
  onClick,
  isButtonDisabled,
  dynamicFooterUI,
  isApiInProgress,
  provider,
  isReviewsAvailable,
  tagline,
  poweredBy,
  module
}) => {
  const { t } = useTranslation("chooseCoverage")

  const [openReviewDialog, setReviewDialog] = useState(false)

  const handleReviewDialog = () => {
    setReviewDialog((prev) => !prev)
  }

  return (
    <div className={`insurance_card ${module !== AUTO_GLASS && "height__auto"}`}>
      <div className={module === AUTO_GLASS ? "autoglass_container" : "insurance_container"}>
        <div className="insurance_image">
          <Image className="logo-image zebra" src={image} />
        </div>
        <div className={module === AUTO_GLASS ? "name__height" : ""}>
          <p className="provider_name">{providerName}</p>
          {poweredBy && <p className="poweredBy">{poweredBy}</p>}
        </div>
        {tagline && <p className="provider_tagline">{tagline}</p>}
        <div>
          <p className="benifit_title">{t("benefits")}</p>
          <div>
            {benefits.map((benifit) => (
              <ProviderBenifits key={benefits} benifit={benifit} />
            ))}
          </div>
        </div>
      </div>
      <div className="card__footer">
        <Button
          loading={isApiInProgress}
          disabled={isButtonDisabled}
          onClick={() => onClick(provider)}
          className={`insurance_button ${module === AUTO_GLASS ? "autoGlass__margin" : ""}`}
        >
          {provider.btnText || t("Get_a_quote")}
        </Button>
        {isReviewsAvailable && (
          <div className="insurance_rating" onClick={handleReviewDialog}>
            <ReactSVG src={RatingLogo} />
            <p>{t("reviewLabel")}</p>
          </div>
        )}
        {dynamicFooterUI && dynamicFooterUI}
      </div>
      {openReviewDialog && (
        <InsuranceReview handleDialog={handleReviewDialog} openDialog={openReviewDialog} />
      )}
    </div>
  )
}

export default InsuranceProviders
