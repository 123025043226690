import React from "react"
import { useTranslation } from "react-i18next"
import ReactSVG from "react-svg"
import { withRouter } from "react-router-dom"
import { Button } from "semantic-ui-react"
import { routeChange, routerPaths } from "../../../v1/constants/paths"

const NoAddedServices = (props) => {
  const { t } = useTranslation("common")
  return (
    <div className="no-added-service-container">
      <ReactSVG src="images/wrench_and_screwdriver.svg" />
      <div className="text">
        <span className="added-service-text">{t("noAddedServiceText")}</span>
        <span className="added-service-savings-text">{t("noAddedServiceSavingsText")}</span>
      </div>
      <div className="add-button-container">
        <Button
          fluid
          content={t("common:addServicesLabel")}
          className="add-button-button"
          onClick={() => {
            props.closeSidebar()
            routeChange(props.history, routerPaths.addServices)
          }}
        />
      </div>
    </div>
  )
}

export default withRouter(NoAddedServices)
