export function isSigningUp() {
  return window.location.href
    .toLowerCase()
    .match(/(signup|signin|sign_up|no_vehicle|select_membership)/)
}

export function isPartnerOfferScreen() {
  const routes = [
    "/offers",
    "/tire-size",
    "/top-tires",
    "/tire-details",
    "/confirm-tire",
    "/confirm-installation",
    "/confirm-installation-date",
    "/booking",
    "/ConfirmDetails",
    "/WarrantyPlan",
    "/plan-details",
    "/insurance-car-mileage",
    "/choose-coverage",
    "/offer/auto-glass",
    "/redirect"
  ]

  const result = routes.some((route) => window.location.href.includes(route))
  return result
}
