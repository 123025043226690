import React, { useState, useRef, useLayoutEffect } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import OrderSummaryIndex from "../OrderSummary/OrderSummaryIndex"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import ReactSVG from "react-svg"
import { connect } from "react-redux"

function ReviewOrderMobile(props) {
  const { setCustomerComments, customerComments, isCartUpdating } = props
  const [isExpanded, setIsExpanded] = useState(props.isExpanded)
  const accordionRef = useRef(null)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  const totalPriceText = (cart) => {
    if (cart.any_order_service_lacks_price) {
      return props.t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(cart.estimated_total)
    }
  }

  useLayoutEffect(() => {
    if (isExpanded && accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [isExpanded])

  return (
    <div
      className={`review-order-accordion-section ${isExpanded ? "expanded" : ""}`}
      ref={accordionRef}
    >
      <div className="review-order-header" onClick={toggleAccordion}>
        <div className="review-order-label">
          <span>{props.t("bookingSummaryLabel")}</span>
        </div>
        <div className="review-order-amount-section">
          <div className="review-order-amount">
            <div>{totalPriceText(props.cart)}</div>
          </div>
          <ReactSVG src={isExpanded ? "images/up_arrow.svg" : "images/down_arrow.svg"} />
        </div>
      </div>
      {isExpanded && (
        <OrderSummaryIndex
          handleShopChange={props.handleShopChange}
          setCustomerComments={setCustomerComments}
          customerComments={customerComments}
          isExpanded={props.isExpanded}
          isNoteExpanded={props.isNoteExpanded}
          handlePaymentChange={props.handlePaymentChange}
          isCartUpdating={isCartUpdating}
          paymentMethodSelected={props.paymentMethodSelected}
          paymentType={props.paymentType}
          paymentDetails={props.paymentDetails}
          isLastStep={props.isLastStep}
        />
      )}
    </div>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation("bookingDetails")(ReviewOrderMobile))
)
