import {
  LOAD_PAYMENT_INFO,
  LOAD_ORDER_ID,
  SET_CARADVISE_CASH,
  SET_AFFIRM_DEFAULT
} from "../actions/payment"

const initialState = {}

export default function paymentInfo(state = initialState, action) {
  switch (action.type) {
    case LOAD_PAYMENT_INFO:
      return Object.assign({}, state, { paymentInfo: action.paymentInfo })

    case LOAD_ORDER_ID:
      return Object.assign({}, state, { orderId: action.orderId })

    case SET_CARADVISE_CASH:
      return Object.assign({}, state, { caradviseCash: action.payload })

    case SET_AFFIRM_DEFAULT:
      return Object.assign({}, state, { affirmDefault: action.default_value })

    default:
      return state
  }
}
