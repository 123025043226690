import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Button, Image } from "semantic-ui-react"
import { routeChange, routerPaths } from "../../../v1/constants/paths"
import RewardDefaultLogo from "../../../images/rewards/reward_default_logo.png"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import UpdateDetailsModal from "../../../v1/components/Modal/UpdateDetailsModal"
import { REWARD_REGISTER } from "../../../shared_component/constants/rewards"
import {
  gaJSON,
  trackFirebaseAnalytics
} from "../../../shared_component/utils/googleAnalyticsHelpers"
import { identifyEvent, trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const HowEarnMilesCards = ({ earnMiles, history, t, user, isActionButtonVisible = true }) => {
  const [openUserModal, setOpenUserModal] = useState(false)

  const handleModalOpen = () => {
    setOpenUserModal((prev) => !prev)
  }

  return (
    <div className="miles-card">
      <div className="miles-card-container">
        <div>
          <p className="miles-digit">
            {numberFormater(earnMiles.miles)} {t("miles")}
          </p>
          <div className="rewards-logo-container">
            <Image
              src={earnMiles.image || RewardDefaultLogo}
              className="reward-image"
              atl={earnMiles.name}
            />
          </div>
          <p className="miles-action">{earnMiles.name}</p>
          <p className="miles-action-detail">{earnMiles.description}</p>
        </div>
        {isActionButtonVisible && earnMiles.button_text && earnMiles.button_url && (
          <Button
            onClick={() => {
              identifyEvent({
                traits: {
                  rewardsName: earnMiles.name
                },
                userId: user.id
              })
              trackEvent("earn-rewards-click", {
                rewardsName: earnMiles.name
              })
              trackFirebaseAnalytics({
                event: gaJSON.earn_view,
                params: {
                  earn_event_name: earnMiles.name
                }
              })
              earnMiles.button_text !== REWARD_REGISTER
                ? routeChange(history, earnMiles.button_url)
                : user.generic && setOpenUserModal(true)
            }}
            content={earnMiles.button_text}
            size="large"
            disabled={user.generic && earnMiles.button_text !== REWARD_REGISTER}
            className="miles-card-btn"
          />
        )}
      </div>
      {openUserModal && <UpdateDetailsModal handleModal={handleModalOpen} open={openUserModal} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default withRouter(
  connect(mapStateToProps, null)(withTranslation("rewards")(HowEarnMilesCards))
)
