import { SplitFactory } from "@splitsoftware/splitio"
import { generateGUID } from "../../../shared_component/utils/guidHelper"
const scriptHash = {
  test: "fonpupc5481glo807cs1tbsf4it0i77a8uc8",
  development: "i7vvu3k2llcms5s8kmpegsb31r398qraugdk",
  staging: "9hbc9u47thoc7adnvgaqac783iu8o0re9821",
  production: "tq0jj954k8scdrvq8r8eupphtr4igfcgo8fj"
}

export const ON_BOARDING_WELCOME_CARDS_FEATURE = "Onboarding_Welcome_Cards_Retail_Web_App"
export const ON_BOARDING_NO_VEHICLE_SCREEN = "Onboarding_No_Vehicle_Screen_Retail_App"
export const MEMBERSHIP_CARD_FEATURE = "Membership_Card_Feature_Retail_Web"
export const HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION =
  "Hide_Subscription_Based_On_User_Affiliation"
export const ON = "on"
export const OFF = "off"
export const CARADVISE_2_DASHBOARD = "CarAdvise_2_Dashboard"
export const GLOVEBOX_FEATURE = "Glovebox_Feature_Retail_Web_App"
export const PARTNER_OFFERS_2 = "PARTNER_OFFERS_2"
export const MAINTENANCE_SCHEDULES = "Maintenance_Schedules"
export const CARADVISE_2_PROFILE = "CarAdvise_2_Profile"
export const CREATE_ACCOUNT_AFTER_SSO_LOGIN = "Create_Account_After_SSO_Login"
export const CARADVISE_2_MAP = "CarAdvise_2_Map"
export const FULL_STORY_FLAG = "Full_Story"
export const RETAIL_RECAPTCHA_FLAG = "RETAIL_RECAPTCHA_FLAG"
export const MINI_ONBOARDING_MR = "Maintenance_Schedules_Mini_Onboarding"
export const CARADVISE_2_NAVIGATION = "CarAdvise_2"
export const V1 = "v1"
export const ACCOUNT_UPGRADE_FLAG = "Show_Account_Upgrade_Modal"
export const MAGIC_LINK_FEATURE = "Magic_Link_Retail"
export const REWARDS = "Rewards"

export async function setupSplitFlags(opts = {}) {
  // Bug in split client resolves too early on second call so setState wont work yet
  // since component not yet mounted. get around this with some hackery that TODO is
  // removed once we're done experimenting.
  const shopsRetailSavings = "shops-retail-savings"
  if (window.splitClientFlagCache && Object.keys(window.splitClientFlagCache).length > 0) {
    const rewardsFlag = await SplitClient.getTreatmentsWithConfig([REWARDS], this.props.user, opts)
    const isRewardsFeature = rewardsFlag ? !!rewardsFlag[REWARDS].treatment.match(/on/) : true

    this.setState({
      ...this.state,
      ...window.splitClientFlagCache,
      isRewardsFeature: isRewardsFeature
    })
    window.splitClientFlagCache = {
      ...window.splitClientFlagCache,
      isRewardsFeature: isRewardsFeature
    }
    if (opts.callback != null) {
      opts.callback({ ...window.splitClientFlagCache, isRewardsFeature: isRewardsFeature })
    }
  } else {
    let { user } = this.props
    let result = await SplitClient.getTreatmentsWithConfig(
      [
        "fixed-price",
        "transaction-fee",
        "seeking-quote",
        "pre-pay-consumer",
        shopsRetailSavings,
        ON_BOARDING_WELCOME_CARDS_FEATURE,
        MEMBERSHIP_CARD_FEATURE,
        HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION,
        ON_BOARDING_NO_VEHICLE_SCREEN,
        CARADVISE_2_DASHBOARD,
        GLOVEBOX_FEATURE,
        PARTNER_OFFERS_2,
        MAINTENANCE_SCHEDULES,
        CARADVISE_2_PROFILE,
        CREATE_ACCOUNT_AFTER_SSO_LOGIN,
        CARADVISE_2_MAP,
        FULL_STORY_FLAG,
        CARADVISE_2_NAVIGATION,
        RETAIL_RECAPTCHA_FLAG,
        MINI_ONBOARDING_MR,
        ACCOUNT_UPGRADE_FLAG,
        MAGIC_LINK_FEATURE,
        REWARDS
      ],
      user,
      opts
    )
    let cachedFlags = {
      isFixedPrice: result ? !!result["fixed-price"].treatment.match(/bump/) : true,
      payAheadTreatment: result ? !!result["pre-pay-consumer"].treatment.match(/on/) : true,
      isOnBoardingWelcomeCardsFlag: result && result[ON_BOARDING_WELCOME_CARDS_FEATURE].treatment,
      isMembershipCardFeatureFlag: result && result[MEMBERSHIP_CARD_FEATURE].treatment,
      isSubscriptionFeatureFlag:
        result && result[HIDE_SUBSCRIPTION_BASED_ON_USER_AFFILIATION].treatment,
      isOnBoardingNoVehicleFlag: result && result[ON_BOARDING_NO_VEHICLE_SCREEN].treatment,
      showNewDashboard: result ? result[CARADVISE_2_DASHBOARD].treatment : false,
      isGloveBoxFeatureFlag: result && result[GLOVEBOX_FEATURE].treatment,
      isPartnerScreenFlag: result && result[PARTNER_OFFERS_2].treatment,
      isMaintenanceScheduleFlag: result && result[MAINTENANCE_SCHEDULES].treatment,
      showNewProfile: result && result[CARADVISE_2_PROFILE].treatment,
      isSSOLogin: result && result[CREATE_ACCOUNT_AFTER_SSO_LOGIN].treatment,
      showNewMapScreen: result && result[CARADVISE_2_MAP].treatment,
      isFullStoryFlagEnabled: result ? !!result[FULL_STORY_FLAG].treatment.match(/on/) : true,
      isCarAdviseNavigation: result && result[CARADVISE_2_NAVIGATION].treatment,
      isReCaptchaflagEnabled: result ? !!result[RETAIL_RECAPTCHA_FLAG].treatment.match(/on/) : true,
      isMiniOnboardingMRFlag: result && result[MINI_ONBOARDING_MR].treatment,
      isAccountUpgrade: result ? !!result[ACCOUNT_UPGRADE_FLAG].treatment.match(/on/) : true,
      isMagicLinkFeature: result ? !!result[MAGIC_LINK_FEATURE].treatment.match(/on/) : true,
      isRewardsFeature: result ? !!result[REWARDS].treatment.match(/on/) : true
    }
    this.setState(cachedFlags)
    if (opts.callback != null) opts.callback(cachedFlags)
    window.splitClientFlagCache = cachedFlags
  }
}

export async function getSplitClient({ customer }) {
  let env = "development"
  if (window.location.hostname.split(".")[1] === "development") env = "staging"
  else if (window.location.hostname.split(".")[1] === "staging") env = "test"
  else if (!window.location.hostname.match(/localhost/)) env = "production"
  let key = scriptHash[env]
  let userId = generateGUID()
  let factory = SplitFactory({
    core: {
      authorizationKey: key,
      key: userId
    }
  })

  return new Promise((resolve, reject) => {
    if (!window.splitIOClient) window.splitIOClient = factory.client()

    if (!window.splitSDKReady)
      window.splitIOClient.on(window.splitIOClient.Event.SDK_READY, () => {
        resolve(window.splitIOClient)
        window.splitSDKReady = true
      })
    else resolve(window.splitIOClient)
  })
}

export default class SplitClient {
  static async funcCaller(treatments, user, opts, func) {
    if (!user) return
    if (!window.splitClientFlagCache) window.splitClientFlagCache = {}

    let splitClient = await getSplitClient({ customer: user })

    let result = splitClient[func](treatments, {
      id: user.id,
      email: user.email,
      affiliation_id: user.affiliation_id,
      affiliation_name: user.affiliation && user.affiliation.name,
      membership_tier: user.membership && user.membership.plan_name,
      ...opts
    })
    return result
  }

  static async getTreatmentsWithConfig(treatments, user, opts) {
    return this.funcCaller(treatments, user, opts, "getTreatmentsWithConfig")
  }

  static async getTreatments(treatments, user, opts) {
    return this.funcCaller(treatments, user, opts, "getTreatments")
  }

  static async getTreatment(treatment, user, opts) {
    return await this.getTreatments([treatment], user, opts)
  }
}
