import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import { ShimmerCategoryItem } from "react-shimmer-effects"
import { Card, CardHeader, Image } from "semantic-ui-react"
import DefaultShop from "../../images/DefaultShop.png"
import RatingIcon from "../../images/Rating_Icon.png"
import store from "../../shared_component/utils/configureStore"
import { isCarAdviseOff, isDayDisabled } from "../../shared_component/utils/dateHelpers"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { getTimeSlotsForShop, getTireBookingDetails, saveInstallationDate } from "../actions/tires"
import Notifications from "../components/Notifications"
import { DATE_TIME_FORMAT } from "../constants/application"
import { routerPaths } from "../constants/paths"
import { WALK_IN, ZERO_VALUE } from "../constants/tires"
import { datePickerLocale } from "../helpers/translationHelpers"
import ItemSelector from "./ItemSelector"
import ShimmerSquar from "./ShimmerSquar"

function ConfirmInstallationDate({
  t,
  user,
  selectedShop,
  getTireBookingDetails,
  tireArray,
  saveInstallationDate,
  history,
  getTimeSlotsForShop
}) {
  const [partnerFlag, setPartnerFlag] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [bookingDate, setBookingDate] = useState()
  const [selectedBookingDate, setSelectedBookingDate] = useState()
  const [showTime, setShowTime] = useState(false)
  const [selectedTime, setSelectedTime] = useState()
  const [timeSlots, setTimeSlots] = useState([])
  const [isTimeLoading, setIsTimeLoading] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [walkInSlots, setWalkInSlots] = useState()
  const [showWalkInData, setShowWalkInData] = useState(false)

  useEffect(() => {
    getFeatureFlagResult()
    getBookingDetails()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const handleDateChange = async (selectedDate) => {
    setIsBtnDisabled(true)
    setIsTimeLoading(true)
    setSelectedTime()
    setShowTime(true)
    setSelectedBookingDate(selectedDate)
    const data = {
      shopId: selectedShop.id,
      date: moment(selectedDate).format(DATE_TIME_FORMAT.YYYYMMDD)
    }
    const response = await getTimeSlotsForShop(data)
    if (response && response.result && response.result[0]) {
      let timesData = []
      response.result[0].times &&
        response.result[0].times.length > 0 &&
        response.result[0].times.map((tm) => {
          const [hour, min] = tm.split(":").map((s) => parseInt(s, 10))
          const dt = moment(selectedDate).hour(hour).minute(min)
          const ampm = hour >= 12 ? t("PM") : t("AM")
          const formatted_time = `${
            hour === 0 || hour === 12 ? 12 : hour % 12
          }:${min.toString().padStart(2, "0")} ${ampm}`
          timesData.push({ formatted_time: formatted_time, time: dt })
        })
      setTimeSlots(timesData)
      let walkInData
      if (response.result[0].walk_in_time_ranges.length > 0) {
        walkInData = response.result[0].walk_in_time_ranges.map((wi) => {
          return { formatted_time: wi.display_walkin_range, time: wi.id }
        })
      }
      setWalkInSlots(walkInData)
    } else {
      setTimeSlots([])
    }
    setIsTimeLoading(false)
  }

  useEffect(() => {
    if (selectedTime === WALK_IN) {
      setShowWalkInData(true)
    }
  }, [selectedTime])

  const handleTimeChange = (value) => {
    setSelectedTime(value)
    const result = {
      isWalkInSelected: showWalkInData,
      walk_in_slot: showWalkInData ? walkInSlots.filter((slot) => slot.time === value) : "",
      date: selectedBookingDate,
      dateTime: !showWalkInData ? value : ""
    }
    saveInstallationDate(result)
    setIsBtnDisabled(value !== WALK_IN ? false : true)
  }

  const getBookingDetails = async () => {
    let tiresList = []
    if (tireArray && tireArray.length > 0 && selectedShop) {
      tireArray.map((tire) => {
        tiresList.push({
          tire_id: tire.tireId,
          quantity: tire.quantity
        })
      })
    }
    const data = {
      tires: tiresList,
      shop_id: selectedShop.id,
      supplier: tireArray && tireArray.length > 0 ? tireArray[0].tireDetails.supplier : ""
    }
    const response = await getTireBookingDetails(data)
    if (response && response.result) {
      setIsLoading(false)
      var date = new Date(response.result.tires_preview.items[0].delivery_date_time.date)
      date.setDate(date.getDate() + 2)
      if (isCarAdviseOff(date)) {
        date.setDate(date.getDate() + 1)
      }
      setBookingDate(date)
      setSelectedBookingDate(date)
      handleDateChange(date)
    } else {
      store.dispatch(
        Notifications.error({
          title: t("Error"),
          message: response.error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      history.push(routerPaths.confirmInstallation)
    }
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <div className="confirm-installation-date-sec">
          <div className="confirm-installation-date">
            <div className="confirm-heading">
              <Link to={routerPaths.confirmInstallation}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h3>{t("Back")}</h3>
            </div>
            <div className="tires">
              <div className="installation-date-time">
                <Card className="confirm-card">
                  <CardHeader>
                    <div className="tire-name-parent">
                      <div className="step">{t("STEP_3")}</div>
                      <div className="confirm-title">{t("Confirm_installation_date")}</div>
                    </div>
                  </CardHeader>
                  <div className="date-time-parent">
                    <div className="date-picker-parent">
                      {isLoading ? (
                        <ShimmerSquar containerClass="h-[25px]" height={250} className="w-2" />
                      ) : (
                        <DatePicker
                          inline
                          selected={selectedBookingDate}
                          onChange={handleDateChange}
                          minDate={bookingDate}
                          locale={datePickerLocale()}
                          openToDate={bookingDate}
                          filterDate={isDayDisabled}
                        />
                      )}
                    </div>
                    <div className="scrollable-in-mobile">
                      {showTime ? (
                        <>
                          <h3 className="time-heading">{t("Select_time")}</h3>
                          {isTimeLoading ? (
                            <div className="time-loader">
                              <ShimmerSquar
                                containerClass="h-[25px]"
                                height={30}
                                className="w-2 time-shimmer-effect"
                              />
                              <ShimmerSquar
                                containerClass="h-[25px]"
                                height={30}
                                className="w-2 time-shimmer-effect"
                              />
                              <ShimmerSquar
                                containerClass="h-[25px]"
                                height={30}
                                className="w-2 time-shimmer-effect"
                              />
                            </div>
                          ) : (
                            <ItemSelector
                              items={showWalkInData ? walkInSlots : timeSlots}
                              labelField="formatted_time"
                              valueField="time"
                              emptyMessage={t("No_time_slot_available")}
                              value={selectedTime}
                              onSelect={handleTimeChange}
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="date-time-details">
                    {isLoading ? (
                      <ShimmerSquar containerClass="h-[25px]" height={25} className="w-2" />
                    ) : (
                      <div className="set-tires-parent">
                        <div className="set-tire">{t("Date")}:</div>
                        <div className="value">
                          {selectedBookingDate
                            ? moment(selectedBookingDate).format(DATE_TIME_FORMAT.LL)
                            : "-"}
                        </div>
                      </div>
                    )}
                    {isLoading ? (
                      <ShimmerSquar containerClass="h-[25px]" height={25} className="w-2" />
                    ) : (
                      <div className="set-tires-parent">
                        <div className="set-tire">{t("Time")}:</div>
                        <div className="value">
                          {selectedTime && selectedTime !== WALK_IN
                            ? showWalkInData
                              ? walkInSlots.filter((slot) => slot.time === selectedTime)[0]
                                  .formatted_time
                              : moment(selectedTime).format(DATE_TIME_FORMAT.HH_MM)
                            : "-"}
                        </div>
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <ShimmerSquar containerClass="h-[25px]" height={55} className="w-2" />
                  ) : (
                    <div className="btn-parent">
                      <Link
                        to={isBtnDisabled ? "#" : routerPaths.booking}
                        className={`next-btn ${isBtnDisabled ? "disable-btn" : "active-btn"}`}
                      >
                        {t("Next")}: {t("Confirmation")}
                      </Link>
                    </div>
                  )}
                </Card>
              </div>
              {selectedShop && selectedShop.id ? (
                <div className="shop-card-parent">
                  <Card className="shop-card">
                    {isLoading ? (
                      <ShimmerCategoryItem
                        hasImage
                        imageType="thumbnail"
                        imageWidth={100}
                        imageHeight={100}
                        text
                        cta
                      />
                    ) : (
                      <CardHeader>
                        <div className="img-parent">
                          <Image
                            src={selectedShop.logo_url ? selectedShop.logo_url : DefaultShop}
                          />
                        </div>
                        <div className="tire-name-parent">
                          <div className="tire-name">{selectedShop.shop_name}</div>
                          <div className="tire-details">
                            {selectedShop.address_line1 +
                              ", " +
                              selectedShop.city +
                              ", " +
                              selectedShop.state +
                              " " +
                              selectedShop.zip}
                          </div>
                          {selectedShop.average_rating &&
                          selectedShop.average_rating !== ZERO_VALUE ? (
                            <div className="shop-rating">
                              <img src={RatingIcon} />
                              {selectedShop.average_rating}
                            </div>
                          ) : null}
                        </div>
                      </CardHeader>
                    )}
                  </Card>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let selectedShop = state.saveBuyTireDetails.selectedShop
  let tireArray = state.saveBuyTireDetails.tireArray

  return {
    user: user,
    selectedShop: selectedShop,
    tireArray: tireArray
  }
}

export default connect(mapStateToProps, {
  getTireBookingDetails,
  saveInstallationDate,
  getTimeSlotsForShop
})(withTranslation("confirmInstallationDate")(ConfirmInstallationDate))
