import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Icon, Image } from "semantic-ui-react"
const MOUSEDOWN = "mousedown"
const CustomDropdown = ({
  options,
  includeBlank,
  defaultOption,
  isVehicleDropdown,
  onOptionChange,
  disabled,
  customeClass,
  imageClass
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { t } = useTranslation("vehicleDetails")
  const dropdownRef = useRef(null)

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    if (dropdownOpen) {
      document.addEventListener(MOUSEDOWN, handleOutsideClick)
    }

    return () => {
      document.removeEventListener(MOUSEDOWN, handleOutsideClick)
    }
  }, [dropdownOpen])

  const handleOptionClick = (option) => {
    if (selectedOption.key !== option.key) {
      setSelectedOption(option)
      onOptionChange && onOptionChange(option)
    }
    setDropdownOpen(false)
  }

  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownOpen(!dropdownOpen)
    }
  }
  return (
    <div
      className={`custom-dropdown ${disabled ? "disabled" : ""} ${customeClass}`}
      ref={dropdownRef}
    >
      <div className={`dropdown-header ${dropdownOpen ? "open" : ""}`} onClick={toggleDropdown}>
        {selectedOption && (
          <div className="active-vehicle-details">
            <span className="placeholder-text-value">{selectedOption.text}</span>
            <Image src={selectedOption.image} />
          </div>
          // ) : (
          //   <>
          //     <span>{defaultOptionText}</span>
          //   </>
        )}
        <Icon name={dropdownOpen ? "angle up" : "angle down"} size="large" />
      </div>
      {!disabled && dropdownOpen && (
        <div className={isVehicleDropdown ? "vehicle-options" : "options"}>
          {includeBlank && <div className="info-text">{t("vehicleDropdownText")}</div>}
          {options &&
            options.map((option, index) => {
              return (
                <div
                  key={option.key}
                  className={isVehicleDropdown ? "vehicle-option" : "option"}
                  onClick={() => handleOptionClick(option)}
                >
                  <Image className={option.isDefaultImage ? imageClass : ""} src={option.image} />
                  {isVehicleDropdown ? (
                    <div className="vehicle-details">
                      <span className="vehicle-name">
                        {option.value && option.value.make && option.value.model
                          ? option.value.make + " " + option.value.model
                          : t("unknownLabel")}
                      </span>
                      {option.value && <span className="vehicle-year">{option.value.year}</span>}
                    </div>
                  ) : (
                    <div className="tire-sizes-list">
                      <div className="tire-rim-sizes">
                        <span>{option.text && option.text}</span>
                        <input
                          type="radio"
                          id={`size-${option.text}`}
                          value={option}
                          checked={selectedOption.key === option.key}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown
