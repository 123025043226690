import React from "react"
import ReactDOM from "react-dom"
import StylesheetInjector from "./v1/components/Config/StylesheetInjector"
import SentryInjector from "./v1/components/Config/SentryInjector"
import App from "./App"
;(async () => {
  // SentryInjector()
  // StylesheetInjector()

  ReactDOM.render(<App />, document.getElementById("root"))
})()
