import "whatwg-fetch"
import braintree from "braintree-web"
import i18n from "./i18n"

export const setupPaypalBraintree = async () => {
  return new Promise((resolve, reject) => {
    braintree.client.create(
      {
        authorization: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error("Error creating client:", clientErr)
          return
        }
        braintree.paypalCheckout.create(
          {
            client: clientInstance,
            locale: i18n.language.match("fr") ? "fr_FR" : "en"
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            resolve(paypalCheckoutInstance)
          }
        )
      }
    )
  })
}
