import React, { useEffect, useState } from "react"
import { Form, Image, Input } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { Link, Redirect } from "react-router-dom"
import { DEFAULT_IMG_URL } from "../constants/carMileage"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateVehicleData } from "../actions/vehicles"
import { getUserDetails, updateUserDetails } from "../actions/user"
import { getPartnerFeatureFlag } from "../../shared_component/utils/partnerFeatureFlag"
import { routerPaths } from "../constants/paths"
import ShimmerSquar from "./ShimmerSquar"

const CarMileage = ({ user, t, updateVehicleData, getUserDetails, updateUserDetails }) => {
  const [userData, setUserData] = useState()
  const [mileage, setMileage] = useState()
  const [errorMileage, setErrorMileage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(t("Mileage_cant_be_empty"))
  const [partnerFlag, setPartnerFlag] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getFeatureFlagResult()
  }, [])

  const getFeatureFlagResult = async () => {
    const result = await getPartnerFeatureFlag(user)
    setPartnerFlag(result)
  }

  const _getUserDetails = async () => {
    let response = await getUserDetails()
    setIsLoading(false)
    setUserData(response && response.result)
    setMileage(response && response.result.active_vehicle.miles)
  }

  useEffect(() => {
    _getUserDetails()
  }, [])

  const handleMileage = (e) => {
    if (e.target.value === 0 || e.target.value === "") {
      setErrorMileage(true)
      setErrorMessage(t("Mileage_cant_be_empty"))
    } else {
      setMileage(e.target.value)
      setErrorMileage(false)
      setErrorMessage("")
    }
  }

  const handleNextBtnClick = async (e) => {
    const data = {
      vehicle_id: userData.active_vehicle.id,
      zip: userData.zip,
      mileage: mileage
    }
    const responseData = await updateUserDetails(data)

    if (responseData && responseData.result.active_vehicle) {
      if (userData.active_vehicle.miles != mileage) {
        updateVehicleData({
          vin: responseData.result.active_vehicle.vin,
          vehicleId: responseData.result.active_vehicle.id,
          miles: responseData.result.active_vehicle.miles,
          milesPerMonth: responseData.result.active_vehicle.miles_per_month,
          licensePlateNumber: responseData.result.active_vehicle.license_plate_number,
          licensePlateState: responseData.result.active_vehicle.license_plate_state
        })
      }
    }
  }

  return (
    <>
      {partnerFlag === false ? (
        <Redirect to={routerPaths.dashboard} />
      ) : (
        <div className="car-mileage-section">
          <div className="car-mileage">
            <div className="mileage-title">
              <Link to={routerPaths.offers}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h3>
                {t("Step1")} {t("CarMileage")}
              </h3>
            </div>
            <div className="mileage-row">
              <div className="form-parent">
                <Form>
                  <p>{t("STEP1")}</p>
                  <h3>{t("CarMileage")}</h3>
                  <div className="comment">{t("Unknow_exact_mileage")}</div>
                  {isLoading ? (
                    <>
                      <ShimmerSquar containerClass="h-[25px]" height={20} className="w-2" />
                      <ShimmerSquar containerClass="h-[25px]" height={35} className="w-2" />
                    </>
                  ) : (
                    <>
                      <label>
                        {t("Mileage")} * <span>{t("only_numbers_no_symbols")}</span>
                      </label>
                      <Input
                        type="number"
                        placeholder="Ex. 23567"
                        onChange={handleMileage}
                        defaultValue={userData && userData.active_vehicle.miles}
                        onKeyUp={handleMileage}
                      />
                      {errorMileage ? <p className="require-text">{errorMessage}</p> : null}
                    </>
                  )}
                  <div className={`btn-parent ${!isLoading ? "btn-margin" : ""}`}>
                    {isLoading ? (
                      <ShimmerSquar
                        containerClass="h-[25px]"
                        height={50}
                        className="w-2"
                        width={200}
                      />
                    ) : (
                      <Link
                        to={{
                          pathname: routerPaths.chooseCoverage,
                          state: {
                            mileage: mileage && mileage
                          }
                        }}
                      >
                        <button
                          className="next-btn"
                          disabled={errorMileage ? true : false}
                          onClick={handleNextBtnClick}
                        >
                          {t("Next_Coverage_plan")}
                        </button>
                      </Link>
                    )}
                  </div>
                </Form>
                <div className="image-parent">
                  <span className="img-strip">
                    <Image
                      className="hide-bg"
                      src={
                        userData && userData.active_vehicle.images[0]
                          ? userData.active_vehicle.images[0].url
                          : DEFAULT_IMG_URL
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  let user = state.user

  return {
    user: user
  }
}

export default connect(mapStateToProps, { updateVehicleData, getUserDetails, updateUserDetails })(
  withTranslation("carMileage")(CarMileage)
)
