import React from "react"

export const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatParagraph = (text, fontWeight = 900, textDecoration = "none") => {
  const parts = text.split("*") // Split text using '*' as a marker

  // Create an array of elements with appropriate styling
  const elements = parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span> // Non-bold text
    ) : (
      <span style={{ fontWeight: fontWeight, textDecoration: textDecoration }} key={index}>
        {part}
      </span> // Bold text
    )
  })

  return elements
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }
  return null
}

export const formatText = (text, className) => {
  const parts = text.split("*") // Split text using '*' as a marker

  // Create an array of elements with appropriate styling
  const elements = parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span>
    ) : (
      <span className={className} key={index}>
        {part}
      </span>
    )
  })

  return elements
}
