import { getJSON, postJSON } from "../../shared_component/utils/fetch"
import { setUserError, setLoading, setNotLoading, setUserData } from "./user"
import i18n from "../../shared_component/utils/i18n"
import { trackEvent } from "../../shared_component/utils/segmentAnalytics"
import { getServiceNameForMap } from "../helpers/serviceHelper"
import { numberFormatToCurrency } from "../../shared_component/utils/currency"
import { formatDate } from "../helpers/segmentHelper"

const DELETE_SERVICE = "DELETE_SERVICE"
const RECOMMENDED_SERVICES_URL = "/api/v4/vehicles/?/vehicle_services"
export const LOAD_SELECTED_SERVICE = "LOAD_SELECTED_SERVICE"
export const CLEAR_SELECTED = "CLEAR_SELECTED"
export const RECOMMENDED_SERVICE_DATA = "RECOMMENDED_SERVICE_DATA"
export const RECOMMENDED_SERVICES_LOADING = "RECOMMENDED_SERVICES_LOADING"
export const RECOMMENDED_SERVICES_NOT_LOADING = "RECOMMENDED_SERVICES_NOT_LOADING"
export const PREPOPULATED_SERVICES_REQUESTED = "PREPOPULATED_SERVICES_REQUESTED"
export const POPULAR_SERVICES_V5 = "/api/v5/services/popular"

function setRecommendedServicesLoading() {
  return { type: RECOMMENDED_SERVICES_LOADING }
}

function setRecommendedServicesNotLoading() {
  return { type: RECOMMENDED_SERVICES_NOT_LOADING }
}

export const selectService = (selectedService) => {
  return {
    type: LOAD_SELECTED_SERVICE,
    selectedService
  }
}

export const deleteService = (deleted_key) => {
  return {
    type: DELETE_SERVICE,
    deleted_key
  }
}

export const clearSelectedServices = () => {
  return {
    type: CLEAR_SELECTED
  }
}

export const LOAD_USER_STATES = "LOAD_USER_STATES"
export function loadUserStates(data) {
  return { type: LOAD_USER_STATES, payload: data }
}

function setRecommendedServiceData(recommendedServices) {
  return { type: RECOMMENDED_SERVICE_DATA, recommendedServices }
}

export function getRecommendedServices() {
  return async function (dispatch, getState) {
    await dispatch(setRecommendedServicesLoading())
    let user = getState().user || {}
    let { authentication_token, activeVehicleId } = user
    if (activeVehicleId && authentication_token) {
      let url = `${RECOMMENDED_SERVICES_URL.replace("?", activeVehicleId)}`

      let response = await getJSON(url, {}, { Authorization: authentication_token })

      if (response.result) {
        dispatch(setRecommendedServiceData(response.result))
      } else {
        dispatch(setUserError(response.error))
      }

      return () => response
    }

    await dispatch(setRecommendedServicesNotLoading())
  }
}

export function addManualMaintenance(services, vehicleId) {
  return async function (dispatch, getState) {
    await dispatch(setLoading(i18n.t("common:savingServicesLoadingMessage")))
    let user = getState().user || {}
    let { authentication_token } = user
    if (vehicleId && authentication_token) {
      let url = `${RECOMMENDED_SERVICES_URL.replace("?", vehicleId)}`

      let response = await postJSON(
        url,
        { vehicle_services: services },
        { Authorization: authentication_token }
      )

      if (response.result) {
        let vehicle = user.vehicles.filter((v) => v.id == vehicleId)[0]
        if (response.result.length > 0) {
          trackEvent("previous-maintenance-services-added", {
            numberOfServices: response.result.length,
            dateOfCompletion: response.result.map(
              (service) => formatDate(service.date_of_completion) || null
            ),
            miles: response.result.map((service) => service.miles || null),
            servicesName: response.result.map(getServiceNameForMap),
            cost: response.result.map(
              (service) => (service.price && numberFormatToCurrency(service.price)) || null
            )
          })
        }
        if (vehicle.vehicle_services) {
          vehicle.vehicle_services = vehicle.vehicle_services.concat(response.result)
          dispatch(setUserData(user))
        } else {
          dispatch(setNotLoading())
        }
      } else {
        dispatch(setUserError(response.error))
      }

      return () => !!response.result
    }
  }
}

export function prepopulatedServicesRequested(data) {
  return { type: PREPOPULATED_SERVICES_REQUESTED, payload: data }
}

export function getPopularServices(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token },
        body: {}
      }

      if (user.activeVehicleId && payload.shopId) {
        request.body.vehicle_id = user.activeVehicleId
        request.body.shop_id = payload.shopId
      }
      let response = await getJSON(POPULAR_SERVICES_V5, request.body, request.headers)
      if (response.result) {
        return response.result
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
    }
  }
}

export function getServiceCategories(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/v1/services/first_level`,
        { vehicle_id: user.activeVehicleId },
        request.headers
      )
      if (response.result) {
        return response.result.first_level
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
    }
  }
}

export function getSecondLevelCategories(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/v1/services/second_level`,
        { ...payload, vehicle_id: user.activeVehicleId },
        request.headers
      )
      if (response.result) {
        return response.result.second_level
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
    }
  }
}

export function getCategoryServices(payload) {
  return async function (dispatch, getState) {
    let user = getState().user || {}
    let { authentication_token } = user
    if (authentication_token) {
      let request = {
        headers: { Authorization: authentication_token }
      }

      let response = await getJSON(
        `${process.env.REACT_APP_INTERNAL_API_URL}/api/v1/services/services_by_category`,
        { ...payload, vehicle_id: user.activeVehicleId },
        request.headers
      )
      if (response.result) {
        return response.result.services
      } else {
        payload.callback && payload.callback("failure", { message: response.error })
      }
      dispatch(setNotLoading())
    }
  }
}
