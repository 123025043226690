import React, { PureComponent } from "react"
import MembershipBenefitsList from "./MembershipBenefitsList"
import Confetti from "react-confetti"
import { withTranslation } from "react-i18next"

class MembershipCongratsContent extends PureComponent {
  render() {
    const { plan, showUpgradeBenefits, t } = this.props

    return (
      <div>
        <div className="confettiContainer">
          <Confetti numberOfPieces={140} opacity={0.8} />
        </div>

        {showUpgradeBenefits && (
          <React.Fragment>
            <div>
              <span>{t("congratsMessage", { planName: plan.name })}</span>
              <br />
              <span>{t("youNowGetLbl")}</span>
            </div>
            <MembershipBenefitsList plan={plan} />
          </React.Fragment>
        )}
        {!showUpgradeBenefits && (
          <div style={{ padding: "30px 0" }}>
            <span>{t("descriptionText1", { planName: plan.name })}</span>
            <span>{` ${t("descriptionText2")}`}</span>
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation("membershipCongratsContent")(MembershipCongratsContent)
