import React, { useState } from "react"
import { Image } from "semantic-ui-react"
import ReactSVG from "react-svg"
import DefaultServiceImageLogo from "../../../images/default_wrench_icon.svg"
import { getServiceNameForMap } from "../../../v1/helpers/serviceHelper"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import MiniLoader from "../../../v1/components/MiniLoader"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../v1/constants/application"
import { textifiedPrice } from "../../../v1/helpers/shopHelpers"
import { TIRE_SERVICES } from "../../../v1/constants/tires"
import { isEmpty } from "lodash"
import store from "../../../shared_component/utils/configureStore"
import { setServiceRemovingFlag } from "../../../v1/actions/rewards"
import Notifications from "../../../v1/components/Notifications"

export function shopPrice(pe, os) {
  // if (this.shouldHidePrice()) return BLANK_PRICE_TEXT
  const { shop_price_estimate } = pe || {}
  const { override_total } = os || {}
  // const extraQuartsPrice = this.additionalQuartsPrice()
  let servicePrice

  if (override_total != undefined) {
    // check null or undefined
    servicePrice = Number(override_total)
  } else if (shop_price_estimate != undefined) {
    // check null or undefined
    servicePrice = Number(shop_price_estimate)
  } else {
    servicePrice = shop_price_estimate
  }

  return servicePrice
}

export function formattedPrice(price, t, user, shop, os, isTireOrder) {
  const isTireService = TIRE_SERVICES.includes(os.name)
  let activeVehicle
  if (user)
    activeVehicle = user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
  if (isEmpty(activeVehicle)) {
    return t("common:addTheVehicleLabel")
  } else if (isTireOrder) {
    return t("orderServicePriceRow:includeTirePriceLabel")
  } else if (shop && shop.cdk_dealer) {
    return t("orderServicePriceRow:seeDealerPriceLbl")
  } else if (Math.sign(price) == 1) {
    return textifiedPrice({ price: price, t: t, prepaid: os.prepaid })
  } else {
    if (shop && shop.is_cdk_dealer) {
      return t("orderServicePriceRow:seeDealerPriceLbl")
    } else if (
      activeVehicle &&
      activeVehicle.length > 0 &&
      activeVehicle[0].vehicle_type_id === MD_HD_VEHICLE_TYPE_ID
    ) {
      return t("orderServicePriceRow:pricedAtShop")
    } else if (isTireService && (price === undefined || price === 0)) {
      return t("orderServicePriceRow:Free")
    } else return t("orderServicePriceRow:discountPriceAtShop")
  }
}
export function isPriceNotPresent(price, t, user, shop, os, isTireOrder) {
  const isTireService = TIRE_SERVICES.includes(os.name)
  let activeVehicle
  if (price) return
  if (user)
    activeVehicle = user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
  if (isEmpty(activeVehicle)) {
    return t("common:addTheVehicleLabel")
  } else if (isTireOrder) {
    return t("orderServicePriceRow:includeTirePriceLabel")
  } else if (shop && shop.cdk_dealer) {
    return t("orderServicePriceRow:seeDealerPriceLbl")
  } else {
    if (shop && shop.is_cdk_dealer) {
      return t("orderServicePriceRow:seeDealerPriceLbl")
    } else if (
      activeVehicle &&
      activeVehicle.length > 0 &&
      activeVehicle[0].vehicle_type_id === MD_HD_VEHICLE_TYPE_ID
    ) {
      return t("orderServicePriceRow:pricedAtShop")
    } else if (isTireService && (price === undefined || price === 0)) {
      return t("orderServicePriceRow:Free")
    } else return t("orderServicePriceRow:discountPriceAtShop")
  }
}
export function cartFormattedPrice(price, t, user, os) {
  let activeVehicle
  if (user)
    activeVehicle = user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
  if (!isEmpty(activeVehicle) && Math.sign(price) == 1) {
    return textifiedPrice({ price: price, t: t, prepaid: os && os.prepaid })
  } else {
    return null
  }
}

const RetailPrice = (props) => {
  const { pe, os, order, t, user } = props
  const activeVehicle =
    user.vehicles && user.vehicles.filter((item) => item.id == user.activeVehicleId)
  if (order.shop && order.shop.cdk_dealer) {
    return null
  } else if (
    activeVehicle &&
    activeVehicle.length > 0 &&
    activeVehicle[0].vehicle_type_id === MD_HD_VEHICLE_TYPE_ID
  ) {
    return null
  } else if (
    activeVehicle &&
    activeVehicle.length > 0 &&
    activeVehicle[0].vehicle_type_id !== MD_HD_VEHICLE_TYPE_ID &&
    order.shop &&
    !order.shop.cdk_dealer &&
    pe &&
    Math.sign(pe.retail_price_estimate) === 1
  ) {
    return (
      <div className="service-retail-price">
        {textifiedPrice({ price: pe.retail_price_estimate, t: t, prepaid: os.prepaid })}
      </div>
    )
  } else if (!order.shop && pe && Math.sign(pe.retail_price_estimate) === 1) {
    return (
      <div className="service-retail-price">
        {textifiedPrice({ price: pe.retail_price_estimate, t: t, prepaid: os.prepaid })}
      </div>
    )
  } else return null
}

const ServiceRow = ({
  index,
  order,
  os,
  pe,
  updateCart,
  user,
  t,
  isCart = true,
  isMiniCart = false
}) => {
  const [loadingStates, setLoadingStates] = useState(order.order_services.map(() => false) || false)
  const [isImageLoaded, setImageLoaded] = useState(true)
  const activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  const orderData =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  const activeOrder =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const isOrderExists =
    user.active_orders && user.active_orders.length && user.active_orders.length > 0
      ? user.active_orders.find((order) => {
          if (+order.vehicle_id === +user.activeVehicleId) {
            return order
          }
        })
      : {}

  const removeService = async (index, event) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates]
      newStates[index] = true
      return newStates
    })
    try {
      let opts = {
        isOrder: !isCart && order && !order.is_tire_order,
        orderId: orderData && orderData.id,
        redeem_miles: "0"
      }
      const response = await updateCart(
        [
          {
            service_definition_id: os.service_id || os.id || pe.service_definition_id,
            event: event,
            prepaid: os.prepaid,
            position: os.position
          }
        ],
        order.vehicle_id,
        null,
        undefined,
        opts
      )
      store.dispatch(
        setServiceRemovingFlag({
          flag: true
        })
      )
      !!+order.redeem_discount &&
        isRewardsFeature &&
        isEmpty(isOrderExists) &&
        store.dispatch(
          Notifications.warning({
            title: t("rewards:milesRemove"),
            position: "tr",
            autoDismiss: 4,
            action: {
              label: "Dismiss"
            }
          })
        )
    } catch (error) {
      console.error("Error removing service:", error)
    }
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates]
      newStates[index] = false
      return newStates
    })
  }

  const shop = order.shop || {}

  return (
    <div className="service-row-container">
      <Image
        src={isImageLoaded && os && os.logo ? os.logo : DefaultServiceImageLogo}
        onError={() => setImageLoaded(false)}
      />
      <div className="service-details">
        <div className="service-name-header">
          <span className="secondary-font service-name">{getServiceNameForMap(os)}</span>
          {!isMiniCart && (
            <>
              {loadingStates[index] ? (
                <div className={`ui active inline loader tiny`}></div>
              ) : (
                (isCart || activeOrder) && (
                  <ReactSVG
                    src="images/delete_icon_vector.svg"
                    onClick={() => removeService(index, "removed")}
                    className="icon-cursor-pointer"
                  />
                )
              )}
            </>
          )}
        </div>
        <div className="service-price-details-section">
          <div className="service-price-details">
            {/* <RetailPrice pe={pe} t={t} os={os} order={order} user={user} /> */}
            {!isMiniCart && (
              <b className="secondary-font service-shop-price">
                {formattedPrice(shopPrice(pe, os), t, user, order.shop, os)}
              </b>
            )}
            {isMiniCart && !shopPrice(pe, os) && (
              <div className="cart-price-section">
                <ReactSVG
                  src={"images/CA_CheckIcon.svg"}
                  svgStyle={{ width: "18px", height: "18px" }}
                />
                <b className="secondary-font service-shop-price">
                  {isPriceNotPresent(shopPrice(pe, os), t, user, order.shop, os)}
                </b>
              </div>
            )}
          </div>
        </div>
        {isMiniCart && (
          <>
            {isMiniCart && loadingStates[index] ? (
              <div className={`ui active inline loader tiny`}></div>
            ) : (
              (isCart || activeOrder) && (
                <div
                  onClick={() => removeService(index, "removed")}
                  className="icon-cursor-pointer"
                >
                  {t("orderServicePriceRow:removeLabel")}
                </div>
              )
            )}
          </>
        )}

        {/* Commented this section for future enhancement */}
        {/* {pe && (
          <div className="service-price-savings-section">
            <div className="service-price-savings">
              <div>💰</div>
              <div>{numberFormatToCurrency(pe.retail_price_estimate - pe.shop_price_estimate)}</div>
            </div>
            <div className="service-price-excellent-tag">
              <div className="">👌</div>
              <div className="">{`Excellent Price `}</div>
              <ReactSVG src="images/info_icon_vector.svg" />
            </div>
          </div>
        )} */}
      </div>
      {isMiniCart && shopPrice(pe, os) && (
        <div className="cart-price-section">
          <ReactSVG src={"images/CA_CheckIcon.svg"} svgStyle={{ width: "18px", height: "18px" }} />
          <b className="secondary-font service-shop-price">
            {cartFormattedPrice(shopPrice(pe, os), t, user, order.shop, os)}
          </b>
        </div>
      )}
    </div>
  )
}

export default ServiceRow
