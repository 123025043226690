import React from "react"
import { connect } from "react-redux"
import { me } from "../actions/user"
import { getMembershipPlans } from "../actions/membership"
import { globalMomentLocale, setLanguage } from "../helpers/translationHelpers"
import { identifyEvent } from "../../shared_component/utils/segmentAnalytics"
import { getVehicleProps } from "../helpers/segmentHelper"
import {
  getActiveVehicle,
  getTransformedMaintenanceScheduleResponse
} from "../helpers/vehicleHelpers"
import { has } from "lodash"
import store from "../../shared_component/utils/configureStore"
import { getMaintenanceSchedules } from "../actions/maintenanceSchedules"
import { getEventNames } from "../actions/segment"
import { COUNTRY_CODE } from "../../shared_component/utils/stringHelpers"

// This class fetches initial data for the app, and individual components then fetch whatever
// additional data they need.
class InitialDataLoader extends React.Component {
  async componentDidMount() {
    const { user, me, getMembershipPlans, isLoggedIn } = this.props
    store.dispatch(getEventNames())
    if (!isLoggedIn) return
    setLanguage(user.language, user.country)
    const response = await me()
    getMembershipPlans()
    if (response && response().result) {
      const user = response().result
      const activeVehicle = user && user.vehicles && user.vehicles.length > 0 && user.vehicles[0]
      const activeVehicleFromStore = getActiveVehicle()
      const vehicle = activeVehicleFromStore || activeVehicle || {}
      if (
        has(activeVehicleFromStore, "maintenanceSchedules[0]") ||
        has(activeVehicle, "maintenanceSchedules[0]")
      ) {
        vehicle.maintenanceSchedules =
          activeVehicleFromStore.maintenanceSchedules || activeVehicle.maintenanceSchedules || []
      } else {
        const maintenanceScheduleResponse = await store.dispatch(getMaintenanceSchedules(vehicle))
        vehicle.maintenanceSchedules = getTransformedMaintenanceScheduleResponse(
          maintenanceScheduleResponse
        )
      }
      identifyEvent({
        traits: {
          affiliation: user.affiliation_name,
          firstNameWithSpace: user.firstName,
          lastNameWithSpace: user.lastName,
          zip: user.zip,
          email: user.email,
          phone: COUNTRY_CODE.concat(user.cellPhone),
          ...getVehicleProps(vehicle)
        },
        userId: user.id,
        replaceUndefinedOrNullValues: false
      })
    }
  }

  render = () => {
    globalMomentLocale(this.props.user.language, this.props.user.country)
    return null
  }
}

const mapStateToProps = (state) => {
  const user = state.user || {}

  return {
    user: user,
    hasMembershipPlans: state.membershipData.availableMembershipPlans.length > 0,
    isLoggedIn: !!user.authentication_token
  }
}

export default connect(mapStateToProps, { me, getMembershipPlans })(InitialDataLoader)
