import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { getEnvironment } from "../../../shared_component/utils/urlUtils"

function UberLandingRedirectPage({ isLoggedIn }) {
  let env = getEnvironment()
  let slug = "uber"

  if (env === "development") {
    slug = "development/uber"
  }

  if (!isLoggedIn) {
    return (window.location.href = `https://www.caradvise.com/${slug}`)
  } else {
    return <Redirect to="/" />
  }
}

function mapStateToProps(state) {
  let user = state.user || {}

  return { isLoggedIn: !!user.authentication_token }
}

export default connect(mapStateToProps, {})(UberLandingRedirectPage)
