import React, { Component, Fragment } from "react"
import { Message, Responsive, Divider } from "semantic-ui-react"

class DismissibleBlock extends Component {
  handleDismiss = () => {
    this.props.afterDismiss()
  }

  render() {
    return (
      <Fragment>
        <Responsive minWidth={540}>
          <Message
            style={{ width: "360px", height: "100px" }}
            className="dismissible-block"
            onDismiss={this.handleDismiss}
            content={this.props.content}
          />
        </Responsive>
        <Responsive maxWidth={539}>
          <Divider hidden />
          <Message
            style={{ width: "260px", height: "100px" }}
            className="dismissible-block"
            onDismiss={this.handleDismiss}
            content={this.props.content}
          />
        </Responsive>
      </Fragment>
    )
  }
}

export default DismissibleBlock
