import React from "react"
import ReactSVG from "react-svg"

import { getStyles } from "../Config/StylesheetInjector"

const styles = getStyles()

const SignUpContainerMobile = ({ children }) => (
  <div>
    <div className="responsive-small-signup">
      <div>
        <div className="signup-container">
          <div className="signup-left">
            <div className="mobile-signup-image-container">
              <img src={"images/CarAdvise-LogoWhite.svg"} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>

    <div className="responsive-large-signup">{children}</div>
  </div>
)

export default SignUpContainerMobile
