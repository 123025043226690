import React, { Component } from "react"
import { connect } from "react-redux"
import { addNewVehicleByVin } from "../actions/vehicles"
import { setLoading, setNotLoading } from "../actions/user"
import spinnerCheckmark from "../../images/spinner_pulsing_checkmark.gif"
import MiniLoader from "../components/MiniLoader"
import Button from "../components/Button"
import { Redirect } from "react-router"
import Notifications from "../components/Notifications"
import store from "../../shared_component/utils/configureStore"
import { withTranslation } from "react-i18next"
import { usesMembership } from "../helpers/membershipHelpers"
import { routerPaths } from "../constants/paths"

class Vin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      fields: Object.assign({
        vin: { value: "", invalid: false, validators: ["_isPresent"] },
        miles: { value: "", invalid: false, validators: ["_isPresent", "_isPositive"] }
      })
    }
    this._onClickNext = this._onClickNext.bind(this)
    this._validateFields = this._validateFields.bind(this)
    this._handleInput = this._handleInput.bind(this)
  }

  componentDidUpdate() {
    const { error, t } = this.props

    if (error) {
      store.dispatch(
        Notifications.error({
          title: t("errorPopupTitle"),
          message: error,
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
  }

  _handleInput(e) {
    this._onFieldChange(e.target.name, e.target.value)
  }

  render() {
    const { isLoading, user, t } = this.props
    if (!usesMembership(user) && user.vehicles.length > 0) {
      return <Redirect to={routerPaths.dashboard} />
    }
    if (this.state.gotoNext && this.props.isSignUp) {
      return <Redirect to="/sign_up/3" />
    } else if (this.state.gotoNext && this.props.isMissingVehicle) {
      return <Redirect to="/dashboard" />
    } else if (this.state.gotoNext) {
      return <Redirect to="/managevehicles" />
    }

    return (
      <div className="container" style={{ width: "100%" }}>
        <div className="contentCenter">
          <p className="introText">{t("header")}</p>
          <div>
            <input
              data-qa="registration-vin-input-field"
              refs="vin"
              name="vin"
              className="inputFld"
              placeholder={t("vinFieldPlaceholder")}
              value={this.state.fields.vin.value}
              onChange={this._handleInput}
            />
          </div>
          <div>
            <input
              data-qa="registration-miles-input-field"
              refs="miles"
              type="number"
              name="miles"
              className="inputFld"
              placeholder={t("milesFieldPlaceholder")}
              value={this.state.fields.miles.value}
              onChange={this._handleInput}
            />
          </div>
          <br />

          <Button
            disabled={isLoading}
            text={isLoading ? <MiniLoader /> : t("nextBtn")}
            colorStyle="orange"
            onClick={this._onClickNext}
          />
        </div>
      </div>
    )
  }

  _isPresent(value) {
    return !!value
  }

  _isPositive(value) {
    return parseInt(value) > 0
  }

  _onFieldChange(key, value, onSelect) {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [key]: this._setAndValidateField(key, value)
        }
      },
      onSelect
    )
  }

  _validateFields(callback) {
    let fields = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.fields).forEach((key) => {
      let field = this.state.fields[key]
      fields[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })

    this.setState({ fields }, () => {
      if (!firstInvalidKey && callback) callback()
      else {
        store.dispatch(
          Notifications.error({
            title: t("errorPopupTitle"),
            message: `${t("errorPopupMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    })
  }

  _setAndValidateField(key, value) {
    let field = this.state.fields[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _onClickNext() {
    this._validateFields(() => {
      this._verifyVIN()
    })
  }

  async _verifyVIN(callback) {
    this.props.setLoading(this.props.t("maintenanceHistoryLoadingMessage"))
    let data = {
      vin: this.state.fields.vin.value,
      miles: this.state.fields.miles.value
    }
    let success = await this.props.addNewVehicleByVin(data.vin, data.miles, true)
    this.props.setNotLoading()
    if (success) {
      this.setState({ gotoNext: true })
    }
  }
}
function mapStateToProps(state) {
  let user = state.user || {}
  let origin = state.origin.origin
  return {
    origin,
    isLoggedIn: !!user.authentication_token,
    isLoading: !!user.loading,
    selectedVehicle: state.selectedVehicle,
    error: user.error,
    user: user
  }
}

export default connect(mapStateToProps, { addNewVehicleByVin, setLoading, setNotLoading })(
  withTranslation("vin")(Vin)
)
