import React, { Component } from "react"
import { withTranslation } from "react-i18next"

class OauthSignin extends Component {
  render() {
    const path = "/api/v4/customers/auth/uber"
    const { t } = this.props
    return (
      <div className="container">
        <a href={path}>{t("signinWithUberLbl")}</a>
      </div>
    )
  }
}

export default withTranslation("common")(OauthSignin)
