import React, { useState } from "react"
import { Image } from "semantic-ui-react"

export function ImageWithFallback({ defaultSrc, src, fallback }) {
  const [showDefault, setShowDefault] = useState(true)

  const handleLoad = () => {
    setShowDefault(false)
  }

  return (
    <>
      {showDefault && <Image src={defaultSrc} />}

      <Image
        src={src}
        style={{ display: showDefault ? "none" : "block", borderRadius: "8px" }}
        onLoad={handleLoad}
      />
    </>
  )
}
