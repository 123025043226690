export const HIDE_CHROME = "HIDE_CHROME"
export const SHOW_CHROME = "SHOW_CHROME"

export function hideChrome() {
  return {
    type: HIDE_CHROME
  }
}

export function showChrome() {
  return {
    type: SHOW_CHROME
  }
}
