import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../../v1/actions/user"
import { fetchCarAdviseRecemendedServices } from "../../../v1/CarAdvise2/actions/maintenance"
import AddServices from "./AddServices"
import ServicesIndex from "./ServicesIndex"

class AddServicesIndex extends Component {
  render() {
    return (
      <div className="add-services-index-container">
        <ServicesIndex />
        <AddServices />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}
export default connect(mapStateToProps, { updateCart, fetchCarAdviseRecemendedServices })(
  withTranslation("cartPanel")(AddServicesIndex)
)
