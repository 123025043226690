import React, { useEffect, useState } from "react"
import SpeedMeterLogo from "../../../images/rewards/speedmeter.svg"
import LockLogo from "../../../images/rewards/lock.svg"
import UnlockMilesInfoLogo from "../../../images/rewards/unlock-info-confirmed.svg"
import ReactSVG from "react-svg"
import UnlockMilesSteps from "./UnlockMilesSteps"
import { withTranslation } from "react-i18next"
import MilesCalendarLogo from "../../../images/rewards/miles-calendar.svg"
import MilesCheckLogo from "../../../images/rewards/miles-check.svg"
import MilesVerifiedLogo from "../../../images/rewards/miles-verified.svg"
import AccountLockLogo from "../../../images/rewards/create-account-lock.svg"
import ButtonWithIcon from "../../../shared_component/components/Button/ButtonWithIcon"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { getMilesToUnlock } from "../../../v1/actions/rewards"
import { connect } from "react-redux"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const UnlockMilesConfirmed = ({ t, getMilesToUnlock, user }) => {
  const UNLOCK_MILES = [
    {
      id: 1,
      stepLogo: MilesCalendarLogo,
      stepInfo: t("bookAppointment")
    },
    {
      id: 2,
      stepLogo: MilesVerifiedLogo,
      stepInfo: t("storeWillLogMileage")
    },
    {
      id: 3,
      stepLogo: MilesCheckLogo,
      stepInfo: (
        <>
          {t("unlockMiles")} <span className="unlock_miles__forYou">{t("unlockMilesForYou")}</span>
        </>
      )
    }
  ]

  const [unlockMiles, setUnlockMiles] = useState({})

  const milesToUnlock = async () => {
    try {
      const unlockMiles = await getMilesToUnlock()

      if (unlockMiles.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${unlockMiles.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      } else {
        setUnlockMiles(unlockMiles.result.lite_vehicle)
      }
    } catch (error) {
      console.log(t("errorInmilesToUnlock"), error)
    }
  }

  useEffect(() => {
    milesToUnlock()
  }, [])

  return (
    <div className="unlock-miles-confirmed-container">
      <div className="unlock-miles-box">
        <div className="mile-logo-box">
          <ReactSVG src={SpeedMeterLogo} />
          <ReactSVG src={LockLogo} className="miles-lock-logo" />
        </div>
        <p className="unlock-estimate-miles">
          {t("unlock")} {unlockMiles ? numberFormater(unlockMiles.points_to_unlock) : 0}{" "}
          {t("miles")}
        </p>
      </div>

      <div className="miles-note">
        <ReactSVG src={UnlockMilesInfoLogo} />
        <p className="miles-note-line">{t("dontForgetToSaveMiles")}</p>
      </div>

      <p className="verify-current-mileage">{t("verifyMileage")}</p>

      {UNLOCK_MILES.map((steps, index) => (
        <UnlockMilesSteps
          key={steps.id}
          steps={steps}
          isLastChild={UNLOCK_MILES.length - 1 === index}
        />
      ))}

      {user && user.generic && (
        <ButtonWithIcon
          logo={AccountLockLogo}
          btnLabel={t("earnMilesPerOrder:createAccountAndEarn")}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { getMilesToUnlock })(
  withTranslation("howToUnlockMiles")(UnlockMilesConfirmed)
)
