import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import Contact from "./BookingConfirmation/Contact"
import BookingDetails from "./BookingConfirmation/BookingDetails"
import { Link, withRouter } from "react-router-dom"
import { routerPaths } from "../constants/paths"
import { useState } from "react"
import store from "../../shared_component/utils/configureStore"
import { getTireShopOrderDetails } from "../actions/bookingConfirmation"
import Notifications from "./Notifications"

const BookingConfirmation = ({ t, location, history, match, user, getTireShopOrderDetails }) => {
  const [orderDetails, setOrderDetails] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const shopOrderId = match.params.id

  const handleOrderDetails = async () => {
    try {
      setIsLoading(true)
      const res = await getTireShopOrderDetails(user, shopOrderId)
      if (res.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${res.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
      setOrderDetails(res.result.tire_shop_order)
    } catch (error) {
      console.log(t("fetchError"), error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleOrderDetails()
  }, [shopOrderId])

  return (
    <div className="booking-confirmation">
      <p className="back-label">
        <Link to={routerPaths.dashboard}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
        <h3>{t("bookingConfirmation")}</h3>
      </p>
      <div className="booking-confirmation-container">
        <div className="box">
          <BookingDetails isLoading={isLoading} installDetails={orderDetails} />
        </div>
        <div className="box">
          <Contact isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user }
}

export default withRouter(
  connect(mapStateToProps, { getTireShopOrderDetails })(
    withTranslation("bookingDetails")(BookingConfirmation)
  )
)
