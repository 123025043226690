import React, { Component, useEffect, useState } from "react"
import { getJSON } from "../../../../../shared_component/utils/fetch"
import { connect } from "react-redux"
import { logoForShop } from "../../../../../shared_component/utils/shopLogos"
import { Image } from "semantic-ui-react"
import MapComponent from "./MapComponent"

import { withTranslation } from "react-i18next"
import { getShopAddress, withPricing } from "../../../../helpers/shopHelpers"
import ShopListItems from "./ShopListItems"
import { BLACK } from "../../../constant/color"
import PopUpComponent from "./PopUpComponent"
import IconLocation from "../../../images/caradvise2/IconLocation.png"
import { useGeolocated } from "react-geolocated"
import Geocode from "react-geocode"
import { updateActiveZip } from "../../../actions/serviceSearch"
import { DEFAULT_ZIP, KEY } from "../../../constants/serviceSearch"
const FIND_SHOPS_URL = "/api/v4/shops/select_shops"
const ICON_WIDTH = 18
const NO_SHOP = "NO_SHOP"
const ICON_HEIGHT = 32

const ShopList = (props) => {
  const [shops, setShops] = useState([])
  const [selectedShop, setSelectedShop] = useState({})
  const [activeTab, setActiveTab] = useState("list")
  const [zipCode, setZipCode] = React.useState("")
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    // userDecisionTimeout: 5000,
    watchPosition: true
  })
  const { user, t, activeZip } = props
  const [searchLocationResult, setSearchLocationResult] = useState({})

  React.useEffect(() => {
    if (!isGeolocationAvailable || !isGeolocationEnabled) {
      updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
      setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
    } else if (coords) {
      // Get the current position
      //from position to zip
      let address
      if (coords.latitude && coords.latitude) {
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            address =
              response.results[0].address_components[
                response.results[0].address_components.length - 1
              ].long_name
            updateActiveZip(address)

            setZipCode(address)
          },
          (error) => {
            updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
            setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
          }
        )
      } else {
        updateActiveZip(user && user.zip ? user.zip : DEFAULT_ZIP)
        setZipCode(user && user.zip ? user.zip : DEFAULT_ZIP)
      }
    } else {
      setZipCode("-")
    }
  }, [
    coords,
    coords && coords.latitude,
    coords && coords.longitude,
    isGeolocationAvailable,
    isGeolocationEnabled
  ])

  const fetchShops = async ({ searchLocation = undefined, zip = 60615 }) => {
    let params = {}
    // params.zip = this.props.activeZip ? this.props.activeZip : this.props.currentZip
    if (searchLocation != undefined) {
      params.latitude = searchLocation.latitude
      params.longitude = searchLocation.longitude
    } else {
      params.zip = activeZip ? activeZip : zip
    }
    //when location permission off  working fine
    //when loc permission on working fine
    //when edited paramzip this.props.activeZip pertains previous value even after refresh,so above condition is becoming wrong

    const response = await getJSON(FIND_SHOPS_URL, params, {
      Authorization: user.authentication_token
    })
    try {
      if (response.result && !response.result.errors) {
        const shops = response.result || []
        setShops(shops)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onSearchLocationSelect = async (suggest) => {
    if (suggest) {
      const searchResult = {
        name: suggest.label,
        latitude: suggest.location.lat,
        longitude: suggest.location.lng
      }

      await fetchShops({ searchLocation: searchResult })
    }
  }

  const onPreSelect = (shop) => {
    setSelectedShop(shop)
  }

  useEffect(() => {
    fetchShops({ searchLocation: undefined })
  }, [])

  return (
    <div className="dashboard_shop_list_container">
      <div className="dashboard_shop_list_header">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p>{t("shopNearYou")}</p>
          <div className="Location" style={{ marginTop: "8%" }}>
            <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <img src={IconLocation} alt="Icon Location" />
            </div>
            <div>{activeZip ? activeZip : zipCode}</div>
            <PopUpComponent />
          </div>
        </div>
        <div className="dashboard_shop_list_icon">
          <div
            className="dashboard_shop_list_icon1"
            style={{
              background: activeTab === "list" ? BLACK : null
            }}
          >
            <Image
              src={require("../../../images/caradvise2/hamburger.svg")}
              alt="icon"
              onClick={() => setActiveTab("list")}
            />
          </div>
          <div
            className="dashboard_shop_list_icon2"
            style={{
              background: activeTab === "map" ? BLACK : null
            }}
          >
            <Image
              className="dashboard_shop_list_icon2_img"
              src={require("../../../images/caradvise2/map_view.svg")}
              alt="icons"
              onClick={() => setActiveTab("map")}
            />
          </div>
        </div>
      </div>
      {activeTab === "list" ? (
        shops &&
        shops.length > 0 &&
        shops.map((x, index) => {
          const src = logoForShop(x)
          let logo = src !== undefined ? <img style={{ width: "40px" }} src={src} /> : null
          return index < 2 ? (
            <div className="dashboard_shops_cards">
              <div className="dashboard_shops_cards_header">
                <Image
                  src={require("../../../images/map/shopHeroImage.png")}
                  alt="stars"
                  width={"100%"}
                />
              </div>
              <div className="dashboard_shops_cards_footer">
                <div
                  style={{
                    display: "flex",
                    flex: 3,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textAlign: "start"
                  }}
                >
                  <div className="dashboard_shops_logo">{logo}</div>
                  <div>
                    <p className="dashboard_shops_name">{x.name}</p>
                    <br className="space" />
                    <p className="dashboard_shops_address">
                      {x.address_line1} {x.city}, {x.zip}
                    </p>
                  </div>
                </div>
                <div className="dashboard_shops_ratings">
                  {/* <img src={require("../../images/stars-4.png")} alt="stars" width={"100px"} /> */}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )
        })
      ) : (
        <div style={{ textAlign: "initial" }}>
          <MapComponent
            shops={shops}
            onClickMarker={onPreSelect}
            selectedShop={selectedShop}
            onSearchLocationSelect={onSearchLocationSelect}
          />
        </div>
      )}
      {activeTab === "list" && (
        <div
          className="dashboard_see_all_label"
          style={{
            textAlign: "end",
            width: "100%",
            color: BLACK,

            cursor: "pointer"
          }}
        >
          <p>{t("seeMore")}</p>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  let user = state.user
  return { user: user, activeZip: state.zipSearch.activeZip }
}

export default connect(mapStateToProps)(withTranslation("dashboardShopList")(ShopList))
