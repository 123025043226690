import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { updateCart } from "../../../v1/actions/user"
import ReactSVG from "react-svg"
import DefaultServiceImageLogo from "../../../images/default_wrench_icon.svg"
import ServiceCard from "./ServiceCard"
import { getUpdatedCart } from "../../../v1/helpers/orderHelpers"

class CartServices extends Component {
  state = {
    caradviseRecommendedServices: []
  }

  render() {
    const { cart, t, activeVehicle } = this.props
    if (cart && cart.order_services.length === 0) {
      return null
    }
    return (
      <div className="cart-services-container">
        <div className="cart-services-header-parent">
          <div className="cart-services_header">
            <div className="cart-services_header-name">{t("servicesAddedLabel")}</div>
          </div>
        </div>
        <div className="cart-services-content-parent">
          {cart.order_services.map((os, index) => {
            return (
              <ServiceCard
                order={cart}
                key={index}
                isAdded={true}
                isCheckbox={true}
                service={os}
                imageSrc={DefaultServiceImageLogo}
                vehicleTypeId={activeVehicle && activeVehicle.vehicle_type_id}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  const order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let cart
  let isCart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}
export default connect(mapStateToProps, { updateCart })(withTranslation("addService")(CartServices))
