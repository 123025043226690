import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { RegionDropdown } from "react-country-region-selector"
import {
  addNewVehicleByPlate,
  addNewVehicleByVin,
  updateVin,
  updateVinByPlateNumber
} from "../../../v1/actions/vehicles"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { me, setLoading, setNotLoading, updateCart } from "../../../v1/actions/user"
import { countryForDropdown } from "../../../v1/helpers/translationHelpers"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import ModalDialog from "../../../v1/components/ModalDialog"
import { Form, Input, Button } from "semantic-ui-react"
import {
  ProvinceNamesAndAbbreviations,
  StateNamesAndAbbreviations
} from "../../../v1/constants/states"
import { getSubdomain } from "../../../v1/components/Config/StylesheetInjector"
import _ from "lodash"

class VehicleInfoUpdatePanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      vin: "",
      licensePlateNumber: "",
      licensePlateState: "",
      isSubmitting: false
    }

    this.infoDialog = {}
    this.handleValidation = this.handleValidation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  async handleValidation() {
    let valid = true
    const { t } = this.props
    if (!this.state.vin && !this.state.licensePlateNumber && !this.state.licensePlateState) {
      valid = false
      store.dispatch(
        Notifications.error({
          title: t("vehicleInfoPanel:errorTitle"),
          message: t("vehicleInfoPanel:vinAndLicensePlateRequiredMessage"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    if (
      !this.state.vin &&
      ((this.state.licensePlateNumber && !this.state.licensePlateState) ||
        (!this.state.licensePlateNumber && this.state.licensePlateState))
    ) {
      valid = false
      store.dispatch(
        Notifications.error({
          title: t("vehicleInfoPanel:errorTitle"),
          message: t("vehicleInfoPanel:licensePlateAndStateRequiredMessage"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }
    if (this.state.vin && this.state.vin.length !== 17) {
      valid = false
      store.dispatch(
        Notifications.error({
          title: t("vehicleInfoPanel:errorTitle"),
          message: t("vehicleInfoPanel:vinInvalidMessage"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
    }

    return valid
  }

  async handleSubmit(event) {
    const { t, setCartUpdating = {}, activeVehicleId } = this.props
    this.setState({ isSubmitting: true })
    let success
    const { vin, licensePlateNumber, licensePlateState } = this.state

    if (vin && vin.length === 17) {
      if (this.props.hasVehicle) {
        success = await this.props.updateVin(vin)
        if (success) {
          trackEvent("vehicle-information-added", {
            licensePlateNumber: licensePlateNumber,
            licensePlateState: licensePlateState,
            vin: vin
          })
        }
      } else {
        setCartUpdating && setCartUpdating(true)
        success = await this.props.addNewVehicleByVin(vin)
        if (success) {
          await this.props.updateCart()
          await this.props.me()
          trackEvent("vehicle-information-added", {
            licensePlateNumber: licensePlateNumber,
            licensePlateState: licensePlateState,
            vin: vin
          })
        }
        setCartUpdating && setCartUpdating(false)
      }
    } else if (vin && vin.length !== 17) {
      store.dispatch(
        Notifications.error({
          title: t("vehicleInfoPanel:errorTitle"),
          message: t("vehicleInfoPanel:validationErrorAtSubmitMessage"),
          position: "tr",
          autoDismiss: 5,
          action: {
            label: "Dismiss"
          }
        })
      )
      success = false
    }

    if (!vin && licensePlateNumber && licensePlateState) {
      if (this.props.hasVehicle) {
        success = await this.props.updateVinByPlateNumber(licensePlateNumber, licensePlateState)
        if (success) {
          trackEvent("vehicle-information-added", {
            licensePlateNumber: licensePlateNumber,
            licensePlateState: licensePlateState,
            vin: vin
          })
          this.setState({ vin: "", licensePlateNumber: "", licensePlateState: "" })
        }
      } else {
        setCartUpdating && setCartUpdating(true)
        success = await this.props.addNewVehicleByPlate(licensePlateNumber, licensePlateState)
        if (success) {
          await this.props.updateCart()
          await this.props.me()
          trackEvent("vehicle-information-added", {
            licensePlateNumber: licensePlateNumber,
            licensePlateState: licensePlateState,
            vin: vin
          })
        } else {
          store.dispatch(
            Notifications.error({
              title: t("vehicleInfoPanel:errorTitle"),
              message: t("common:invalidLicensePlateMsg"),
              position: "tr",
              autoDismiss: 5,
              action: {
                label: "Dismiss"
              }
            })
          )
        }
        setCartUpdating && setCartUpdating(false)
      }
    }
    this.setState({ isSubmitting: false })
  }

  handleChange = async (event) => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  async handleStateChange(state) {
    this.setState({ licensePlateState: state })
  }

  async openModal(event) {
    event.preventDefault()
    this.infoDialog.openModal()
  }

  render() {
    const { t, userStates } = this.props
    const { vin, licensePlateNumber, licensePlateState, isSubmitting } = this.state
    return (
      <div className="vehicle-information">
        <h2 className="heading">{t("vehicleInforLabel")}</h2>
        <div className="information-inputs">
          <div className="input-parent">
            <label className="no-vehicle-label">{t("plateNumberLbl")}</label>
            <Input
              name="licensePlateNumber"
              size="small"
              placeholder={t("plateNumberPlaceholder")}
              onKeyPress={(event) => {
                if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              value={licensePlateNumber}
              onChange={this.handleChange}
            />
          </div>
          <div className="input-parent">
            <label>{t("stateLbl")}</label>
            <Form.Dropdown
              fluid
              placeholder={t("statePlaceholder")}
              search
              selection
              onChange={(e, data) => this.handleStateChange(data.value)}
              options={_.map(userStates, (item) => ({
                key: item.id,
                text: item.name,
                value: item.label
              }))}
            />
          </div>
        </div>
        <div className="or-label">
          <div
            style={{ textAlign: "center", padding: "0 20px 0 15px" }}
            className="primary-button-color tertiary-font"
          >
            {t("vehicleInfoPanel:orLbl")}
          </div>
        </div>
        <div className="information-inputs">
          <div className="input-parent">
            <label className="no-vehicle-label">{t("vinNumberPlaceholder")}</label>
            <Input
              data-qa="registration-vin-input-field"
              refs="vin"
              name="vin"
              size="small"
              placeholder={t("vinPlaceholder")}
              onKeyPress={(event) => {
                if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              onChange={this.handleChange}
              value={vin}
            />
          </div>
          <ModalDialog
            linkText={t("vehicleInfoPanel:whyDoWeNeedThisText")}
            className="primary-button-color informative-label"
            style={{ textDecoration: "underline" }}
          >
            {t("vehicleInfoPanel:vinDesc")}
          </ModalDialog>
        </div>
        <Button
          className={`create-account-btn ${
            !(vin || (licensePlateState && licensePlateNumber)) ? "success" : "success"
          }`}
          disabled={!(vin || (licensePlateState && licensePlateNumber))}
          onClick={this.handleSubmit}
          loading={isSubmitting}
        >
          {t("vehicleInfoPanel:saveText")}
        </Button>
      </div>
    )
  }
}

VehicleInfoUpdatePanel.propTypes = {
  cartId: PropTypes.number
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart = {}
  if (props.cartId)
    cart =
      (user.active_carts && user.active_carts.filter((c) => c.id === Number(props.cartId))[0]) || {}
  else
    cart =
      (user.active_carts && user.active_carts.find((c) => c.vehicle_id === user.activeVehicleId)) ||
      {}

  let hasVehicle = !!cart.vehicle_id
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id === cart.vehicle_id)
  let activeVehicleId = user.activeVehicleId
  return {
    hasVehicle: hasVehicle,
    activeVehicle: activeVehicle || {},
    country: user.country,
    activeVehicleId,
    userStates: (_.includes(getSubdomain(), "-ca")
      ? ProvinceNamesAndAbbreviations
      : StateNamesAndAbbreviations
    ).map((s) => ({
      id: s.abbreviation,
      label: s.abbreviation,
      name: s.name
    }))
  }
}

export default connect(mapStateToProps, {
  updateVin,
  updateVinByPlateNumber,
  addNewVehicleByVin,
  addNewVehicleByPlate,
  setLoading,
  setNotLoading,
  updateCart,
  me
})(withTranslation("vehicleDetails")(VehicleInfoUpdatePanel))
