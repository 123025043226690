import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { ShimmerServiceCard } from "./ShimmerServiceCard"
import { updateCart } from "../../../v1/actions/user"
import { getServiceCategories } from "../../../v1/actions/services"
import CategoryCard from "./CategoryCard"
import SecondLevelCategories from "./SecondLevelCategories"

const FirstLevelCategories = (props) => {
  const [firstLevelCategories, setFirstLevelcategories] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const { getServiceCategories, cart } = props

  const fetchAvailableCategories = async () => {
    setLoading(true)

    const serviceCategories = (await getServiceCategories()) || null

    if (serviceCategories !== null) {
      setFirstLevelcategories(serviceCategories)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAvailableCategories()
  }, [])

  const handleCategoryCardClick = (category) => {
    props.categorySelect()
    setSelectedCategory(category) // Set the selected category in the state
  }

  if (selectedCategory) {
    return (
      <SecondLevelCategories
        firstLevelCategory={selectedCategory}
        handleCategoryCardClick={handleCategoryCardClick}
        categorySelect={props.categorySelect}
      />
    )
  }

  return (
    <>
      {isLoading && <ShimmerServiceCard count={15} />}
      {!isLoading &&
        firstLevelCategories &&
        firstLevelCategories
          .slice()
          .sort()
          .map((os, index) => {
            return (
              <CategoryCard
                key={index}
                isCheckbox={true}
                index={index}
                service={os}
                handleCategoryCardClick={handleCategoryCardClick}
              />
            )
          })}
    </>
  )
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let cart
  let isCart
  let currentActiveOrder =
    user.active_orders && user.active_orders.find((o) => o.vehicle_id == user.activeVehicleId)
  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)

  if (currentActiveOrder) {
    cart = currentActiveOrder
    isCart = false
  } else {
    isCart = true

    if (props.cartId) {
      cart = user.active_carts && user.active_carts.filter((c) => c.id == Number(props.cartId))[0]
    } else {
      cart =
        user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)
    }
  }

  if (!cart || !cart.order_services) {
    cart = { estimated_total: 0, order_services: [] }
  }

  return {
    isCart: isCart,
    cart: cart,
    user: user,
    activeVehicle
  }
}

export default connect(mapStateToProps, { getServiceCategories, updateCart })(FirstLevelCategories)
