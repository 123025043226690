export function replaceSubstring(inputString, search, replaceWith) {
  const regex = new RegExp(search, "g")
  const resultString = inputString.replace(regex, replaceWith)

  return resultString
}

export function numberFormater(number, countryFlag = "en-IN") {
  return new Intl.NumberFormat(countryFlag).format(number)
}
