import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "../../../images/icon-check-circle.svg"
import { Image } from "semantic-ui-react"
import CloseIcon from "../../../images/close-icon.svg"
import CalendarDropdown from "../../../v1/components/Calendar/CalendarDropdown"
import AddToCalendar from "react-add-to-calendar"
import { DATE_TIME_FORMAT } from "../../../v1/constants/application"
import moment from "moment"
import { HOUR, HOURS } from "../../../v1/constants/tires"
import EstimateEarnMiles from "./EstimateEarnMiles"

export const BookingCompleted = ({ cart, user, onClose, estimateEarnMiles }) => {
  const { t } = useTranslation("reviewOrder")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [event, setEvent] = useState()

  const isRewardsFeature =
    window.splitClientFlagCache && window.splitClientFlagCache.isRewardsFeature

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth)
    return () => {
      window.removeEventListener("resize", updateWindowWidth)
    }
  }, [])

  useEffect(() => {
    buildEvent()
    if (windowWidth <= 500) {
      document.body.classList.add("hidden-overflow")
      return () => {
        document.body.classList.remove("hidden-overflow")
      }
    }
  }, [])

  const buildEvent = () => {
    const shop = cart.shop
    const start = moment(cart.appointment_datetime)
    const end = start.clone().add(1, HOUR)
    const duration = end.diff(start, HOURS)
    let eventFormed = {
      title: t("bookingDetails:serviceAtText", { shopName: shop.name }),
      startDatetime: start.format(DATE_TIME_FORMAT.YYYYMMDDTHHmmss),
      endDatetime: end.format(DATE_TIME_FORMAT.YYYYMMDDTHHmmss),
      timezone: shop.timezone,
      duration: `${duration}`,
      description: `${shop.name} ${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`,
      location: `${shop.address_line1} ${shop.city}, ${shop.state} ${shop.zip}`
    }
    setEvent(eventFormed)
  }

  const bookingEmailLabel = `${t("contentBookingCompletedLabel", {
    customer_email: user.email
  })}`

  return (
    <div className="booking-completed-container">
      <div className="booking-completed-icon-section">
        <Image src={CheckCircleIcon} />
      </div>
      <div className="booking-completed-close-icon">
        <Image src={CloseIcon} onClick={() => onClose()} />
      </div>
      <div className="booking-completed-content-section">
        <div className="booking-completed-header-section">
          {t("completedBookingLabel")}
          <div className="close-icon">
            <Image src={CloseIcon} onClick={() => onClose()} />
          </div>
        </div>
        <div className="booking-completed-content">{bookingEmailLabel}</div>
      </div>
      <div className="booking-completed-calender-section">
        <AddToCalendar event={event && event} />
      </div>
      {estimateEarnMiles && user && user.total_shop_orders === 1 && isRewardsFeature && (
        <EstimateEarnMiles estimateEarnMiles={estimateEarnMiles} />
      )}
    </div>
  )
}

export const BookingCompletedModal = ({ cart, user, onClose, estimateEarnMiles }) => {
  const { t } = useTranslation("reviewOrder")
  return (
    <div className="booking-completed-index-container">
      <div
        className="modal fade"
        id="modal"
        tabindex="-1"
        aria-labelledby="modal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <BookingCompleted
            cart={cart}
            user={user}
            onClose={onClose}
            estimateEarnMiles={estimateEarnMiles}
          />
        </div>
      </div>
    </div>
  )
}

export const BookingCompletedNotificationContainer = ({
  cart,
  user,
  onClose,
  estimateEarnMiles
}) => {
  const { t } = useTranslation("reviewOrder")
  return (
    <div className="booking-completed-index-container">
      <div className="">
        <div className="modal-right-coner">
          <BookingCompleted
            cart={cart}
            estimateEarnMiles={estimateEarnMiles}
            user={user}
            onClose={onClose}
          />
        </div>
      </div>
    </div>
  )
}
