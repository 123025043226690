import React from "react"
import Tier1 from "../../../images/tier1.svg"
import Tier2 from "../../../images/tier2.svg"
import { Image } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { TIRE_CONNECT_INSTALLATION, TIRE_CONNECT_NEW_TIRES } from "../../constants/tires"

const BookedService = ({ t, installDetails, isLoading }) => {
  const filteredData =
    installDetails &&
    installDetails.order_services &&
    installDetails.order_services.filter(
      (service) =>
        service.name === TIRE_CONNECT_NEW_TIRES || service.name === TIRE_CONNECT_INSTALLATION
    )

  return (
    <div className="booked-services">
      <p className="label">
        {t("services")} ({filteredData && filteredData.length})
      </p>
      <div className="services">
        {filteredData &&
          filteredData.length > 0 &&
          filteredData.map((service) => (
            <>
              <Image
                key={service.id}
                className="imageClass"
                src={service.name === TIRE_CONNECT_NEW_TIRES ? Tier2 : Tier1}
              />
              <div>
                <p className="servicename">{service.name}</p>
                <p className="discount">{service.total}</p>
              </div>
            </>
          ))}
      </div>
    </div>
  )
}

export default withTranslation("bookingDetails")(BookedService)
