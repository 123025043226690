import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ServiceSection from "../CartServiceSection"
import { updateCart } from "../../../../v1/actions/user"
import ReactSVG from "react-svg"
import { numberFormatToCurrency } from "../../../../shared_component/utils/currency"
import ServiceRow from "../CartServiceRow"
import { Link } from "react-router-dom"
import { routeChange, routerPaths } from "../../../../v1/constants/paths"
import { Button } from "semantic-ui-react"
import MaintenanceScheduleServicesSection from "../MaintenanceScheduleServicesSection"
import NoAddedServices from "../../Services/NoAddedServices"
import CartSidePanelFooter from "../CartSidePanelFooter"
import { getUpdatedCart } from "../../../../v1/helpers/orderHelpers"

export const CartServices = ({ cartQuantity, cart, updateCart, user, t, isCart }) => {
  return (
    <div className="cart-summary_services">
      <div className="cart-summary_services-header">
        <p className="secondary-font">{`${t("servicesLabel")} (${cartQuantity})`}</p>
        <Link to={routerPaths.addServices} className="responsive-button">
          <Button basic>
            <span className="secondary-font mobile-text">{t("addServicesLabel")}</span>
            <ReactSVG src="images/add_icon_vector.svg" />
            <span className="secondary-font text">{t("addMoreServicesLabel")}</span>
          </Button>
        </Link>
      </div>
      <div className="added-cart-services">
        {cart &&
          cart.order_services.map((os, index) => (
            <ServiceRow
              index={index}
              key={os.id}
              os={os}
              pe={os.price_estimate}
              order={cart}
              updateCart={updateCart}
              user={user}
              t={t}
              isCart={isCart}
            />
          ))}
      </div>
    </div>
  )
}
class CartSummary extends Component {
  constructor(props) {
    super(props)
    const user = props.user || {}
    const { activeVehicleId, active_carts } = user
    this.state = {
      activeCart: active_carts && active_carts.find((c) => c.vehicle_id == activeVehicleId),
      approvalStatus: null,
      activeVehicleId: props.user.activeVehicleId
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
  }

  totalPriceText = (order) => {
    if (order.any_order_service_lacks_price) {
      return this.props.t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(order.estimated_total)
    }
  }

  subTotalPriceText = (order) => {
    if (order.any_order_service_lacks_price) {
      return this.props.t("common:notApplicableLbl")
    } else {
      return numberFormatToCurrency(order.estimated_total_pre_tax - order.transaction_fee)
    }
  }

  render() {
    const { cart, t, user, updateCart, isCart } = this.props
    const cartQuantity = cart && cart.order_services && cart.order_services.length
    return (
      <div className="cart-summary-container">
        <div
          className="back-to-shopping-link"
          onClick={() => {
            routeChange(this.props.history, routerPaths.dashboard)
          }}
        >
          <ReactSVG src="images/go_back_icon.svg" style={{ cursor: "pointer" }} />
          <span style={{ cursor: "pointer" }}>{t("goShoppingLabel")}</span>
        </div>
        <div className="cart-summary-services-container">
          {cart && cart.order_services.length > 0 ? (
            <>
              <div className="cart-summary-services-header">
                <p className="secondary-font">{t("myCartLabel")}</p>
                <p className="secondary-font">{this.totalPriceText(cart)}</p>
              </div>
              <CartServices
                cartQuantity={cartQuantity}
                user={user}
                cart={cart}
                t={t}
                updateCart={updateCart}
                isCart={isCart}
              />
              <div className="cart-summary-footer">
                <div className="cart-summary-footer-text">
                  <div>{t("subTotalLabel")}</div>
                  <div>{this.subTotalPriceText(cart)}</div>
                </div>
              </div>
            </>
          ) : (
            <NoAddedServices closeSidebar={() => {}} />
          )}
        </div>
        <MaintenanceScheduleServicesSection isCartSummary={true} />
        <div className="cart-summary-button-section-web">
          <div className="cart-summary-buttons">
            <Button
              fluid
              content={t("goCheckoutLabel")}
              className="continue-button"
              onClick={() => {
                routeChange(this.props.history, routerPaths.checkoutSchedule)
              }}
            />
          </div>
        </div>
        <div className="cart-summary-button-section">
          <div className="cart-summary-buttons">
            <CartSidePanelFooter
              user={user}
              cart={cart}
              isCart={isCart}
              t={t}
              shop={cart.shop}
              sidePanelClose={() => {}}
              buttonText={t("goCheckoutLabel")}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let user = state.user || {}
  let activeVehicle = (user.vehicles || []).find((v) => v.id === user.activeVehicleId) || {}
  let order =
    activeVehicle &&
    activeVehicle.id &&
    (user.active_orders || []).find((o) => o.vehicle_id === activeVehicle.id)
  let isCart
  let cart
  let updatedCartData = getUpdatedCart(user, order, props)
  if (updatedCartData) {
    cart = updatedCartData.cart
    isCart = updatedCartData.isCart
  }
  return {
    user,
    activeVehicle,
    order,
    cart: cart,
    isCart: isCart,
    isLoggedIn: !!user.authentication_token,
    caradviseCash: state.paymentInfo.caradviseCash
  }
}

export default connect(mapStateToProps, { updateCart })(withTranslation("cartPanel")(CartSummary))
