import { combineReducers } from "redux"
import user from "./user"
import selectedVehicle from "./vehicles"
import serviceReducer from "./services"
import addedServiceReducer from "./addedservice"
import shopReducer from "./shop"
import appointmentReducer from "./appointment"
import approvedReducer from "./approvals"
import groupReducer from "./unapprovals"
import billingReducer from "./billing"
import paymentReducer from "./payment"
import maintenanceSchedulesReducer from "./maintenanceSchedules"
import ordersReducer from "./orders"
import originReducer from "./origin"
import paymentMethods from "./paymentMethods"
import promotion from "./promotion"
import notifications from "./notifications"
import membershipReducer from "./membership"
import framework from "./framework"
import modalNotifications from "./modalNotifications"
import glovebox from "./glovebox"
import leftnav from "./leftNav"
import segment from "./segment"
import saveDetails from "./confirmDetails"
import saveBuyTireDetails from "./tires"
import rewards from "./rewards"

import zipSearch from "../CarAdvise2/reducers/serviceSearch"
import shopListReducer from "./checkout"
const appReducer = combineReducers({
  user,
  selectedVehicle,
  service: serviceReducer,
  addedService: addedServiceReducer,
  shop: shopReducer,
  flag: shopListReducer,
  selectedTime: appointmentReducer,
  approved: approvedReducer,
  grouped: groupReducer,
  billingData: billingReducer,
  maintenanceSchedules: maintenanceSchedulesReducer,
  membershipData: membershipReducer,
  promotionData: promotion,
  gloveboxData: glovebox,
  leftnav: leftnav,
  paymentInfo: paymentReducer,
  orders: ordersReducer,
  origin: originReducer,
  paymentMethods,
  notifications,
  framework,
  modalNotifications,
  segment,
  zipSearch,
  saveDetails,
  saveBuyTireDetails,
  rewards
})

// const initialState = appReducer({}, {})

export default function rootReducer(state = {}, action) {
  if (action.type === "LOGOUT_USER") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    const { segment } = state
    state = { segment: segment }
    return appReducer(state, action)
  } else return appReducer(state, action)
}
