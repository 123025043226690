import React from "react"
import Button from "../Button"
import { WalkInWarningModal } from "../../registration/schedule"
import { GAEvent, GAEventCategories } from "../../tracking/GAEvent"
import { useTranslation } from "react-i18next"
import { trackEvent } from "../../../shared_component/utils/segmentAnalytics"
import { withRouter } from "react-router-dom"
import { getActiveVehicle, getVehicleName } from "../../helpers/vehicleHelpers"
import { getServiceNameForMap } from "../../helpers/serviceHelper"
import { getCurrentUser } from "../../helpers/userHelper"

function CartButtons({
  shopLikeObject,
  orderLikeObject,
  path,
  confirmEnabled = true,
  onComplete,
  history
}) {
  const { t } = useTranslation(["cartButtons", "walkInWarningModal"])
  let buttons = []
  if (orderLikeObject.status == undefined) {
    if (
      !orderLikeObject.order_services ||
      (orderLikeObject.order_services.length == 0 && path != "#/selectCommonMaintenance")
    )
      buttons.push(
        <Button
          key="btn-common-maint"
          text={t("chooseServicesText")}
          onClick={() => {
            // trackEvent(EVENT_NAME.chooseOrAddChange, {
            //   ...getAdditionalProps(),
            //   source: location.pathname,
            //   buttonName: t("chooseServicesText")
            // })
            GAEvent(GAEventCategories.DASHBOARD, "cart-chooseservices", "Choose Services — Cart")
          }}
          trackingClassName="gtm-cart-chooseservices"
          colorStyle="orange"
          linkTo="/selectCommonMaintenance"
          disabled={!confirmEnabled}
        />
      )

    if (!orderLikeObject.order_services || orderLikeObject.order_services.length == 0)
      return buttons

    if (!shopLikeObject && !orderLikeObject.is_seeking_quote && path != "#/selectShop")
      buttons.push(
        <Button
          onClick={async () => {
            GAEvent(GAEventCategories.CART, "browse-shops", "Browse Shops And Prices")
            await trackEvent("click-add-service", {
              vehicleName: getVehicleName(getActiveVehicle()),
              orderServices:
                orderLikeObject &&
                orderLikeObject.order_services &&
                orderLikeObject.order_services.length > 0 &&
                orderLikeObject.order_services.map(getServiceNameForMap),
              email: getCurrentUser().email
            })
          }}
          key="btn-choose-shop"
          text={t("browseShopAndPricesText")}
          trackingClassName="gtm-cart-chooseshop"
          colorStyle="orange"
          linkTo="/selectShop"
          disabled={!confirmEnabled}
          className="secondary-font"
        />
      )

    if (
      shopLikeObject &&
      !orderLikeObject.appointment_datetime &&
      !orderLikeObject.is_walk_in &&
      path != "#/schedule" &&
      !orderLikeObject.is_seeking_quote
    )
      buttons.push(
        <Button
          onClick={() => GAEvent(GAEventCategories.CART, "choose-time", "Choose Time")}
          key="btn-choose-time"
          text={t("chooseTimeText")}
          trackingClassName="gtm-cart-choosetime"
          colorStyle="orange"
          linkTo={"/schedule"}
          disabled={!confirmEnabled}
          className="secondary-font"
        />
      )

    if (
      (orderLikeObject.is_seeking_quote ||
        (shopLikeObject && orderLikeObject.appointment_datetime && !orderLikeObject.is_walk_in)) &&
      path != `#/carts/${orderLikeObject.id}/confirm`
    )
      buttons.push(
        <Button
          onClick={async () => {
            GAEvent(GAEventCategories.CART, "checkout", "Checkout")
            await trackEvent("review-appointment-clicked")
            history.push(`/carts/${orderLikeObject.id}/confirm`)
          }}
          key="btn-checkout"
          text={t("checkoutText")}
          trackingClassName="gtm-cart-checkout"
          colorStyle="orange"
          // linkTo={`/carts/${orderLikeObject.id}/confirm`}
          disabled={!confirmEnabled}
        />
      )

    if (
      shopLikeObject &&
      !orderLikeObject.is_seeking_quote &&
      orderLikeObject.is_walk_in &&
      orderLikeObject.appointment_datetime &&
      path != `#/carts/${orderLikeObject.id}/confirm`
    )
      buttons.push(<WalkInWarningModal cartId={orderLikeObject.id} t={t} />)

    if (path == `#/carts/${orderLikeObject.id}/confirm` && buttons.length === 0)
      buttons.push(
        <Button
          onClick={() => GAEvent(GAEventCategories.CART, "confirm-order", "Confirm Order")}
          key="btn-confirm-order"
          text={t("confirmOrderText")}
          trackingClassName="gtm-cart-confirmorder"
          colorStyle="orange"
          disabled={!confirmEnabled}
          onClick={onComplete}
        />
      )
  }

  return <div className="cart-buttons">{buttons}</div>
}

export default withRouter(CartButtons)
