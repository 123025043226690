import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Popup, Accordion, Icon, Checkbox, Divider, Grid } from "semantic-ui-react"
import { MD_HD_VEHICLE_TYPE_ID } from "../../../../constants/application"

import RadioOutline from "../../../../../images/radio_outline.svg"
import RadioSelected from "../../../../../images/radio_selected.svg"

const FilterMobileView = (props) => {
  const {
    setIsFilterOpen,
    clearAllFilters,
    ratingFiltersApplied,
    typeFiltersApplied,
    addorRemoveFilterItem,
    handleApplyFilterClick
  } = props

  const [rating, setRating] = React.useState(ratingFiltersApplied)
  const [bookingType, setBookingType] = React.useState(typeFiltersApplied)

  const handleFilterIconClick = () => {
    setIsFilterOpen && setIsFilterOpen(false)
  }

  const handleClearAllFilter = () => {
    setBookingType([])
    setRating([])
  }

  const handleApplyClick = () => {
    handleFilterIconClick()
    if (rating.length == 0 && bookingType.length == 0) {
      handleApplyFilterClick && handleApplyFilterClick(rating, bookingType, true)
    } else {
      handleApplyFilterClick && handleApplyFilterClick(rating, bookingType)
    }
  }

  return (
    <div className="mobileViewFilter">
      <div className="container">
        <FilterOptions
          onClearAllClick={handleClearAllFilter}
          setRating={setRating}
          setBookingType={setBookingType}
          addorRemoveFilterItem={(value, type) => addorRemoveFilterItem(value, type)}
          ratingFiltersApplied={rating}
          typeFiltersApplied={bookingType}
          handleFilterIconClick={handleFilterIconClick}
        />
        <div style={{ height: "20%" }}></div>

        <div className="buttonStyle" onClick={handleApplyClick}>
          Apply Filter
        </div>
      </div>
    </div>
  )
}

const FilterOptions = (props) => {
  const [isStarFilterOpen, setIsStarFilterOpen] = useState(true)
  const [isTpeOfServiceFilterOpen, setIsTpeOfServiceFilterOpen] = useState(true)

  return (
    <div className="subContainer">
      <div className="titleStyle">
        <Icon
          name="close black"
          style={{ padding: "8px" }}
          onClick={() => props.handleFilterIconClick()}
        />
        <span className="filterTitle">Filter by:</span>
        <span className="filterClearText" onClick={props.onClearAllClick}>
          Clear Filters
        </span>
      </div>

      <div className="filterContainer">
        <div>
          <Grid.Row onClick={() => setIsTpeOfServiceFilterOpen(!isTpeOfServiceFilterOpen)}>
            <span className="filterOptionsHeader">Type</span>

            <div style={{ width: "20px" }} />
            <Icon name={isTpeOfServiceFilterOpen ? "chevron up" : "chevron down"} />
          </Grid.Row>
          {isTpeOfServiceFilterOpen && (
            <TypeOfService
              filterSelected={props.typeFiltersApplied}
              setBookingType={props.setBookingType}
              addorRemoveFilterItem={props.addorRemoveFilterItem}
            />
          )}
        </div>

        <Divider className="dividerStyles" />

        <div style={{ marginTop: "5%" }}>
          <Grid.Row
            onClick={() => {
              setIsStarFilterOpen(!isStarFilterOpen)
            }}
          >
            <span className="filterOptionsHeader">Star Rating</span>
            <div style={{ width: "20px" }} />
            <Icon name={isStarFilterOpen ? "chevron up" : "chevron down"} />
          </Grid.Row>
          {isStarFilterOpen && (
            <StarRating
              filterSelected={props.ratingFiltersApplied}
              setRating={props.setRating}
              addorRemoveFilterItem={props.addorRemoveFilterItem}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const FilterItem = (props) => {
  const { isSelected, onClick, label, value } = props
  return (
    <div style={{ marginTop: "7px", marginLeft: "5px" }}>
      <div className="filterComponent">
        <Checkbox value={value} checked={isSelected} onChange={() => onClick(value)} />
        <span className="checkBoxLabel">{label}</span>
      </div>
    </div>
  )
}

const StarRating = (props) => {
  const { filterSelected, addorRemoveFilterItem, setRating } = props
  const addorRemoveItem = (value) => {
    const selectedType = filterSelected.slice()
    const index = selectedType.findIndex((i) => i == value)
    if (index > -1) {
      selectedType.splice(0, selectedType.length)
    } else {
      selectedType.splice(0, selectedType.length)
      if (value == 2) {
        selectedType.push(2, 3, 4, 5)
      } else if (value == 3) {
        selectedType.push(3, 4, 5)
      } else if (value == 4) {
        selectedType.push(4, 5)
      } else if (value == 5) {
        selectedType.push(5)
      }
    }
    setRating(selectedType)
  }

  return (
    <Grid.Column stretched>
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 5}
        onClick={(value) => addorRemoveItem(value)}
        label="5 Stars"
        value={5}
      />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 4}
        onClick={(value) => addorRemoveItem(value)}
        label="4+ Stars"
        value={4}
      />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 3}
        onClick={(value) => addorRemoveItem(value)}
        label="3+ Stars"
        value={3}
      />
      <FilterItem
        isSelected={filterSelected && filterSelected[0] == 2}
        onClick={(value) => addorRemoveItem(value)}
        label="2+ Stars"
        value={2}
      />
    </Grid.Column>
  )
}

const TypeOfService = (props) => {
  const { filterSelected, addorRemoveFilterItem, setBookingType } = props

  const addorRemoveItem = (value) => {
    const selectedType = filterSelected.slice()
    // addorRemoveFilterItem(value, "type")
    const index = selectedType.findIndex((i) => i == value)
    if (index > -1) {
      selectedType.splice(index, 1)
    } else {
      selectedType.push(value)
    }
    setBookingType(selectedType)
  }

  return (
    <Grid.Column stretched style={{ marginBottom: "5%" }}>
      <FilterItem
        isSelected={filterSelected && filterSelected.includes("walk_in")}
        onClick={(value) => addorRemoveItem(value)}
        label="Walk in"
        value={"walk_in"}
      />
      <FilterItem
        isSelected={filterSelected && filterSelected.includes("appointment")}
        onClick={(value) => addorRemoveItem(value)}
        label="Appointment"
        value={"appointment"}
      />
    </Grid.Column>
  )
}

function mapStateToProps(state) {
  let user = state.user
  let cart =
    user.active_carts && user.active_carts.find((c) => c.vehicle_id == user.activeVehicleId)

  let activeVehicle = user.vehicles && user.vehicles.find((v) => v.id == user.activeVehicleId)
  let vehicleTypeId = activeVehicle.vehicleTypeId

  return {
    user: user,
    cart,
    vehicleTypeId
  }
}

export default connect(mapStateToProps)(withTranslation("dashboardMapComponent")(FilterMobileView))
