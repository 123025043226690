import React, { Component } from "react"
import { withRouter } from "react-router"
import store from "../../shared_component/utils/configureStore"
import { Card, Grid, Icon, Image } from "semantic-ui-react"
import Button from "./../components/Button"
import { updateSelectedMembershipUpgrade } from "../actions/membership"
import { isPremium, isElite, isBasic } from "../helpers/membershipHelpers"
import { elements } from "caradvise_shared_components"
import { PERSONAL_INFO_SECTION } from "../accountMobile"
import RoadsideTooltip from "../components/Membership/RoadsideTooltip"
import { withTranslation } from "react-i18next"
import { numberFormatToCurrency } from "../../shared_component/utils/currency"
import { routerPaths } from "../constants/paths"
import { isDasherDirect } from "../helpers/affiliationHelpers"
import { isCarPerks } from "../helpers/carPerksHelpers"
import { identifyEvent, trackEvent } from "../../shared_component/utils/segmentAnalytics"
import checkPremium from "../../images/check-circle-premium.svg"
import checkCircle from "../../images/check-circle-1.png"
const { BaseModalDialog } = elements

class MembershipCard extends Component {
  state = { modalOpen: false }

  featuredBenefit = (str) => {
    return <>{str}</>
  }

  formatPrice = (price, cadence) => {
    const { t } = this.props
    return price > 0 ? `${numberFormatToCurrency(price)}/ ${cadence}` : t("freeLbl")
  }

  alreadyHasPlan = () => {
    const { plan, activeMembership } = this.props
    return activeMembership && activeMembership.plan_name === plan.name
  }

  handleClick = async () => {
    const { plan, activeMembership } = this.props

    if (isBasic(plan)) {
      await identifyEvent({ traits: { selectedPlan: plan.name } })
      trackEvent("plan-type-chosen", {
        duringOnboarding: this.props.isUserInOnboardingFlow ? "Yes" : "No",
        selectedPlan: plan.name,
        previousPlan: (activeMembership && activeMembership.plan) || null
      })
    }
    if (this.alreadyHasPlan()) return

    if (isPremium(plan)) {
      store.dispatch(updateSelectedMembershipUpgrade(plan))
    }
    if (isElite(plan)) {
      this.setState({ modalOpen: true })
    }
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  planConfig = (plan) => {
    const { t, user } = this.props
    // NOTE: remove this once trial expires
    const dasherDirectTrialOffer =
      "Congrats! As a DasherDirect elite driver, you’ve earned 6 months FREE for our Premium Membership which gives you an additional 10% off already discounted pricing."
    const premiumBenefits = [
      t("premiumPlanDescription2"),
      `${plan.max_num_vehicles} ${t("vehiclesPerAccountLbl")}`
    ]
    if (isDasherDirect() && plan.is_free_trial) {
      premiumBenefits.unshift(
        this.featuredBenefit(dasherDirectTrialOffer),
        t("planDiscountDecription", { discount: parseInt(plan.percent_discount) })
      )
    } else {
      premiumBenefits.unshift(
        this.featuredBenefit(
          t("planDiscountDecription", { discount: parseInt(plan.percent_discount) })
        )
      )
    }

    const grayButtonColors = ["#9e9992", "#b0aaa3"]
    const showGrayBasic = isDasherDirect() || isCarPerks(user)
    const showGrayElite = isDasherDirect()
    const planConfigByTier = {
      basic: {
        featureBenefits: [
          t("basicPlanDescription1"),
          t("basicPlanDescription2"),
          `${plan.max_num_vehicles} ${t("vehiclesPerAccountLbl-basic-new")}`
        ],
        headerMainColor: "#002D5E",
        buttonColors: showGrayBasic ? grayButtonColors : ["#002D5E", "#004B80"],
        linkTo: routerPaths.dashboard,
        price: this.formatPrice(plan.price, t("monthLbl-new")),
        name: plan.name
      },
      premium: {
        featureBenefits: premiumBenefits,
        headerMainColor: "#F8991D",
        buttonColors: ["#F8991D", "#FFC55E"],
        linkTo: this.alreadyHasPlan() ? routerPaths.dashboard : routerPaths.signUp3,
        price: this.formatPrice(plan.price, t("monthLbl-new")),
        name: plan.name
      },
      elite: {
        featureBenefits: [
          this.featuredBenefit(
            <div>
              {t("elitePlanDescription1")}
              {/* <RoadsideTooltip numEvents={plan.max_num_yearly_roadside_events} /> */}
            </div>
          ),
          this.featuredBenefit(
            t("planDiscountDecription", { discount: parseInt(plan.percent_discount) })
          ),
          t("elitePlanDescription2"),
          `${plan.max_num_vehicles} ${t("vehiclesPerAccountLbl")}`
        ],
        headerMainColor: "#0390FF",
        buttonColors: showGrayElite ? grayButtonColors : ["#0390FF", "#00c9ff"],
        price: this.formatPrice(plan.price, t("monthLbl-new")),
        annualPrice: `  (${this.formatPrice(plan.price * 12, t("yearLbl-new"))})`,
        name: plan.name,
        stipulation: t("elitePlanStipulation")
      }
    }
    return planConfigByTier[plan.name.toLowerCase()]
  }

  render() {
    const { plan, hideButton, history, t, location } = this.props
    const { modalOpen } = this.state
    if (!plan) return null

    const {
      name,
      featureBenefits,
      price,
      annualPrice,
      headerMainColor,
      buttonColors,
      linkTo
    } = this.planConfig(plan)
    const planBenefitlistItems = featureBenefits.map((benefit) => (
      <li
        className="planBenefitListItem-new"
        style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#67768E" }}
      >
        <div
          className={
            name == t("premium")
              ? "planBenefitListItemImgDivPremium-new"
              : "planBenefitListItemImgDiv-new"
          }
        >
          <img
            src={name == t("premium") ? checkPremium : checkCircle}
            className="planBenefitListItemImg-new"
            style={{ zIndex: "1" }}
          ></img>
        </div>
        {benefit}
      </li>
    ))
    const displayPrice = annualPrice ? `${price} ${annualPrice}` : price
    const isFreeTrial = plan.is_free_trial

    // NOTE the use case of this card is always in an <li>. it is purposely opiononated.
    return (
      <li
        className={
          name == t("premium")
            ? "membership-card-container-Premium-new"
            : "membership-card-container-new"
        }
      >
        <Card>
          <Card.Content>
            <Card.Description style={{ paddingTop: "5px" }}>
              {annualPrice ? (
                <div className="annual-membership-card-header-new">{t("annualMembershipLbl")}</div>
              ) : name == t("premium") ? (
                <div className="annual-membership-card-header-new" style={{ width: "121px" }}>
                  {t("mostPopularLbl")}
                </div>
              ) : (
                <div style={{ marginTop: "23px" }}>{"\u00A0"}</div>
              )}
            </Card.Description>

            <Card.Meta>
              <div className="caradvise-electric-blue">
                {isFreeTrial ? t("membershipUpgradeCard:freeTrial") : "\u00A0"}
              </div>
            </Card.Meta>

            <Card.Meta className="annual-membership-card-meta-new">
              <span
                className="annual-membership-card-price-new"
                style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#2F2F2F" }}
              >
                {displayPrice.slice(0, 5).toUpperCase()}
              </span>
              <span
                className="annual-membership-card-price-new-2"
                style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#2F2F2F" }}
              >
                {displayPrice.slice(5, 12)}
              </span>
              <span
                className="annual-membership-card-price-new-3"
                style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#2F2F2F" }}
              >
                {displayPrice.slice(12, displayPrice.length)}
              </span>
            </Card.Meta>

            <Card.Header>
              <div
                className="membership-card-plan-name-new"
                style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#2F2F2F" }}
              >
                {name}
              </div>
            </Card.Header>
            <Card.Description>
              <div
                className="mebership-card-plan-title-new"
                style={name == t("premium") ? { color: "#FFFFFF" } : { color: "#67768E" }}
              >
                {name == t("elite")
                  ? t("eliteTagLineLbl")
                  : name == "Premium"
                  ? t("premiumTagLineLbl")
                  : t("basicTaglineLbl")}
              </div>
            </Card.Description>

            <Card.Description className="planDescription-new">
              <div
                className="planBenefitListItemContainer-new"
                style={
                  name == t("premium")
                    ? { color: "#FFFFFF", height: "180px" }
                    : name == t("elite")
                    ? { color: "#67768E" }
                    : { color: "#67768E", height: "190px" }
                }
              >
                <ul style={{ listStyleType: "none", marginLeft: "-22px" }}>
                  {planBenefitlistItems}
                </ul>
              </div>
            </Card.Description>

            <Card.Description>
              {!hideButton && (
                <Button
                  onClick={this.handleClick}
                  linkTo={linkTo && `${linkTo}${location.search}`}
                  colorStyle={name == t("premium") ? "white" : "orange"}
                  className="classic-ca-button"
                  text={
                    <span
                      style={name == t("premium") ? { color: "#2F2F2F" } : { color: "#FFFFFF" }}
                    >
                      {t("choosePlan")}
                    </span>
                  }
                />
              )}
            </Card.Description>
          </Card.Content>
        </Card>
        <BaseModalDialog
          headerTitle={t("baseModalHeader")}
          isOpen={modalOpen}
          toggleModal={this.toggleModal}
        >
          <p>{t("baseModalDescription")}</p>
          <Grid centered columns={8}>
            <Grid.Row>
              <Button
                style={{ margin: "0.3em", height: "0.9em" }}
                colorStyle="orange"
                styleText={{ color: "white", fontSize: "0.8em" }}
                text={t("takemeToMyAccountText")}
                onClick={() => {
                  trackEvent("take-me-to-my-account")
                  history.push({
                    pathname: routerPaths.myAccount,
                    state: { section: PERSONAL_INFO_SECTION }
                  })
                }}
              />
              <Button
                style={{ margin: "0.3em", height: "0.9em" }}
                colorStyle="grey"
                styleText={{ color: "white", fontSize: "0.8em" }}
                text={t("cancelText")}
                onClick={this.toggleModal}
              />
            </Grid.Row>
          </Grid>
        </BaseModalDialog>
      </li>
    )
  }
}

export default withRouter(
  withTranslation(["membershipCard", "membershipUpgradeCard"])(MembershipCard)
)
