import {
  AVAILABLE_PROMOTIONS_LOADED,
  USED_PROMOTIONS_LOADED,
  CUSTOMERS_PROMOTION_CREATED,
  CUSTOMERS_PROMOTION_APPLIED,
  PROMOTION_PARAM_AVAILABLE,
  CLEAR_PROMOTION_PARAM
} from "../actions/promotion"

export default function promotion(state = {}, action = {}) {
  switch (action.type) {
    case AVAILABLE_PROMOTIONS_LOADED:
      return {
        ...state,
        availablePromotions: action.payload || []
      }
    case USED_PROMOTIONS_LOADED:
      return {
        ...state,
        usedPromotions: action.payload || []
      }
    case CUSTOMERS_PROMOTION_CREATED:
      let availablePromotions = state.availablePromotions || []
      availablePromotions.push(action.payload.promotion)

      return {
        ...state,
        availablePromotions: availablePromotions
      }

    case CUSTOMERS_PROMOTION_APPLIED:
      return {
        ...state,
        appliedPromotion: action.payload.promotion
      }
    case PROMOTION_PARAM_AVAILABLE:
      return {
        ...state,
        promoCode: action.payload.promoCode
      }
    case CLEAR_PROMOTION_PARAM:
      return { ...state, promoCode: null }
    default:
      return { ...state, error: null }
  }
}
