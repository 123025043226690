const LOAD_ADDED_SERVICE = "LOAD_ADDED_SERVICE"
const DELETE_SERVICE = "DELETE_SERVICE"
const CLEAR_ADDED = "CLEAR_ADDED"

export default function addedServiceReducer(state = {}, action) {
  const nextState = Object.assign({}, state)
  switch (action.type) {
    case LOAD_ADDED_SERVICE:
      //if this reducer is triggered by the fair price coming back, we only want to update the price of services that are already in the state
      if (action.bool) {
        if (nextState[action.id]) {
          nextState[action.id] = action.addedService
        } else {
          break
        }
      }

      nextState[action.id || action.addedService.id] = action.addedService
      break

    case DELETE_SERVICE:
      delete nextState[action.deleted_key]
      break

    case CLEAR_ADDED:
      return Object.assign({}, state, { addedService: {} })

    default:
      return state
  }
  return nextState
}
