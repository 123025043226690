import React, { useState } from "react"
import CompleteServiceLogo from "../../../images/rewards/complete-service.svg"
import RewardsServiceCard from "./RewardsServiceCard"
import RewardTagFilter from "./RewardTagFilter"
import PendingLogo from "../../../images/rewards/pendingMiles.svg"
import ReactSVG from "react-svg"
import { getRewards } from "../../../v1/actions/rewards"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { useEffect } from "react"
import { connect } from "react-redux"
import { ALL, EARN, PENDING, TAGS } from "../../../shared_component/constants/rewards"
import RewardsPlaceholder from "../Rewards/RewardsPlaceholder"
import { withTranslation } from "react-i18next"

const RewardServices = ({ t, getRewards, userRewards }) => {
  const [selectedTag, setSelectedTag] = useState(ALL)
  const [isLoading, setIsLoading] = useState(false)

  const getRewardsData = async () => {
    setIsLoading(true)
    try {
      let rewardsRes = {}

      if (selectedTag === ALL) {
        rewardsRes = await getRewards()
      } else {
        rewardsRes = await getRewards({ rewardType: EARN })
      }

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("errorTitle"),
            message: `${rewardsRes.error}`,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    } catch (error) {
      console.log(t("errorInRewarSerices"), error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRewardsData()
  }, [selectedTag])

  const rewardsPlaceholderUI = userRewards && userRewards.length && userRewards.length > 0

  return (
    <>
      <div className="reward-service-filters">
        {TAGS.map((tag) => (
          <RewardTagFilter
            key={tag.id}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            tag={tag.tagLabel}
          />
        ))}
      </div>

      {/* this have to ask Ankur */}
      {/* <p className="reward-service-time">August 22, 2023</p> */}

      {selectedTag === PENDING && (
        <div className="reward-pending-miles">
          <ReactSVG src={PendingLogo} />
          <p className="reward-pending-note">{t("pendingMilesNote")}</p>
        </div>
      )}
      <div
        className={
          rewardsPlaceholderUI || isLoading ? "reward-service-container" : "no_services_container"
        }
      >
        {isLoading ? (
          [...new Array(6)].map(() => <div className="miles_shimmer"></div>)
        ) : rewardsPlaceholderUI ? (
          userRewards.map((service) => <RewardsServiceCard key={service.id} service={service} />)
        ) : (
          <RewardsPlaceholder />
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userRewards:
      state.rewards && state.rewards.userRewards && state.rewards.userRewards.length > 0
        ? state.rewards.userRewards
        : []
  }
}

export default connect(mapStateToProps, { getRewards })(withTranslation("rewards")(RewardServices))
