import React, { Component } from "react"
import ActionCable from "actioncable"
import { getApiUrl } from "../../shared_component/utils/urlUtils"

function withWebSocketConnection(WrappedComponent, channelName) {
  return class extends Component {
    constructor(props) {
      super(props)

      if (!window.App) window.App = {}

      this.wrappedComponentInstance = null
    }

    componentDidMount() {
      const { user } = this.props

      const protocol = process.env.NODE_ENV === "development" ? "ws" : "wss"
      const url = `${protocol}://${getApiUrl()}/cable?user_id=${user.id}&authentication_token=${
        user.authentication_token
      }`
      if (window.App && !window.App.cable) {
        window.App.cable = ActionCable.createConsumer(url)

        window.App.cable.subscriptions.create(channelName, {
          connected: () => {},

          received: (data) => {
            if (this.wrappedComponentInstance && this.wrappedComponentInstance.onDataReceived) {
              this.wrappedComponentInstance.onDataReceived(data)
            }
          }
        })
      }
    }

    proc(wrappedComponentInstance) {
      // NOTE: For a wrapped component to do stuff with data received via websocket,
      // the wrapped component MUST implement an instance method called onDataReceived.

      if (wrappedComponentInstance && wrappedComponentInstance.onDataReceived) {
        this.wrappedComponentInstance = wrappedComponentInstance
      } else if (wrappedComponentInstance && !wrappedComponentInstance.onDataReceived) {
        throw "WrappedComponent must implement method onDataReceived"
      }
    }

    render() {
      return <WrappedComponent {...this.props} ref={this.proc.bind(this)} />
    }
  }
}

export default withWebSocketConnection
