import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import MapGL, { Marker } from "react-map-gl"

const ICON_WIDTH = 18
const ICON_HEIGHT = 32

function MapThumbnail({ longitude, latitude, zoom, width, height, mapStyle, shopName = null }) {
  const [viewport, setViewport] = useState({
    longitude,
    latitude,
    zoom,
    width,
    height
  })
  useEffect(() => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      longitude,
      latitude,
      zoom,
      width,
      height
    }))
  }, [longitude, latitude, zoom, width, height])

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport)
  }

  return (
    <MapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={handleViewportChange}
      attributionControl={false}
      mapStyle={mapStyle}
      style={{ borderRadius: "6px" }}
    >
      <Marker
        latitude={latitude}
        longitude={longitude}
        offsetLeft={-(ICON_WIDTH / 2)}
        offsetTop={-ICON_HEIGHT}
        style={{ display: "inline-flex", alignItems: "center", flexDirection: "row", gap: "2px" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          fixedWidth
          style={{
            color: "#B83614",
            cursor: "pointer",
            width: `${ICON_WIDTH}px`,
            height: `${ICON_HEIGHT}px`
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#B83614",
            width: "100%",
            fontSize: "10px",
            fontWeight: "700",
            left: "-18px",
            position: "relative"
          }}
        >
          {shopName && shopName}
        </div>
      </Marker>
    </MapGL>
  )
}

export default MapThumbnail
