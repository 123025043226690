import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { getBookFirstMaintenanceMiles, getEarnedMiles } from "../../../v1/actions/rewards"
import { connect } from "react-redux"
import store from "../../../shared_component/utils/configureStore"
import Notifications from "../../../v1/components/Notifications"
import { isCookieExpired, removeCookie } from "../../../shared_component/utils/cookies"
import { DEFAULT_NUMBER_LOCAL, NEW_USER } from "../../../shared_component/constants/rewards"
import { numberFormater } from "../../../v1/helpers/cuvrdHelpers"

const TotalMiles = ({ t, getEarnedMiles, getBookFirstMaintenanceMiles, earnedMiles }) => {
  const handleEarnedRewards = async () => {
    try {
      const rewardsRes = await getEarnedMiles()

      if (rewardsRes.error) {
        store.dispatch(
          Notifications.error({
            title: t("rewards:errorTitle"),
            message: rewardsRes.error,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      } else {
        const isNewUserCookieExpired = isCookieExpired(NEW_USER)
        if (!isNewUserCookieExpired) {
          store.dispatch(
            Notifications.success({
              message: (
                <div className="earn_miles_toaster">
                  {t("milesEarnCard:youEarned")}{" "}
                  <span>
                    {numberFormater(rewardsRes.result.earned_miles, DEFAULT_NUMBER_LOCAL)}
                  </span>{" "}
                  {t("milesEarnCard:rewards")} {t("milesEarnCard:forCreatingAccount")}
                </div>
              ),
              position: "tr",
              autoDismiss: 5,
              action: {
                label: "Dismiss"
              }
            })
          )
          removeCookie(NEW_USER)
        }
      }
    } catch (error) {
      console.log(t("rewards:errorInTotalMiles"), error)
    }
  }

  const getFirstBookMaintenanceMiles = async () => {
    try {
      const rewardMiles = await getBookFirstMaintenanceMiles({ rewardsId: 1 })

      if (rewardMiles.error) {
        store.dispatch(
          Notifications.error({
            title: t("rewards:errorTitle"),
            message: rewardMiles.error,
            position: "tr",
            autoDismiss: 5,
            action: {
              label: "Dismiss"
            }
          })
        )
      }
    } catch (error) {
      console.log(t("errorIngetFirstBookMaintenanceMiles"), error)
    }
  }

  useEffect(() => {
    handleEarnedRewards()
    getFirstBookMaintenanceMiles()
  }, [])

  return (
    <div className="total-miles-container">
      <p className="miles-header">{t("totalEarned")}</p>
      <p className="miles-value">{earnedMiles && earnedMiles.toString()}</p>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    earnedMiles: state.rewards.earnedMile
  }
}

export default connect(mapStateToProps, { getEarnedMiles, getBookFirstMaintenanceMiles })(
  withTranslation("common")(TotalMiles)
)
