import store from "./configureStore"
import { mapKeys, omitBy, isNil } from "lodash"
import {
  getAdditionalPartnerOfferProps,
  getAdditionalProps,
  getDeviceProp,
  getEventNameByKey,
  removeGenericEmail
} from "../../v1/helpers/segmentHelper"
import { segmentProps } from "../../v1/constants/segmentProperties"
import { COUNTRY_CODE } from "./stringHelpers"

// event names
export const EVENT_NAME = {
  dashboardLand: "Dashboard Land",
  personalInformationUpdated: "Personal Information Updated",
  upgradePlanTypeClicked: "Upgrade Plan Type Clicked After Request Appointment",
  requestAppointmentClicked: "Request Appointment Clicked",
  promotionalCodeApplied: "Click Apply Promo Code Button",
  previousMaintenanceServicesAdded: "Previous Maintenance Services Added",
  newVehicleAdded: "New Vehicle Added",
  signedUp: "Signed Up",
  loggedIn: "Logged In",
  downloadAppClicked: "Download App Clicked",
  myAccountClicked: "My Account Clicked",
  maintenanceScheduleClicked: "Maintenance Schedule Clicked",
  membershipCardClicked: "Membership Card Clicked",
  gloveBoxClicked: "Glovebox Clicked",
  garageClicked: "Garage Clicked",
  maintenanceHistoryClicked: "Maintenance History Clicked",
  addPaymentMethodClicked: "Click Add Payment Method",
  browseServiceClicked: "Browse Services Clicked",
  browseShopsClicked: "Browse Shops Clicked",
  diagnoseAProblemClicked: "Diagnose A Problem Clicked",
  goToGarageClicked: "Go To Garage Clicked",
  serviceSearchUsingSearchBar: "Service Search Using Search Bar",
  addToCalendarClicked: "Add To Calendar Clicked",
  clickCancelOrder: "Click Cancel Order",
  orderCancelled: "Order Cancelled",
  keepMyAppointmentClicked: "Keep My Appointment Clicked",
  chooseOrAddChange: "Choose Or Add/Change Services Clicked",
  reviewAppointmentClicked: "Review Appointment Clicked",
  recommendedServiceAdded: "Recommended Service Added",
  changeShopClicked: "Change Shop Clicked",
  changeDateTimeClicked: "Change Date/Time Clicked",
  editVehicleClicked: "Edit Vehicle Clicked",
  editedVehicleClicked: "Edited Vehicle Details",
  appointmentRequested: "Appointment Requested",
  allRecommendedServicesAdded: "All Recommended Services Added",
  contactInformationAdded: "Contact Information Added",
  vehicleInformationAdded: "Vehicle Information Added",
  addPreviousMaintenanceClicked: "Add Previous Maintenance Clicked",
  applyPromotionalCodeClicked: "Apply Promotional Code Clicked",
  updateReminderClicked: "Update Reminder Clicked",
  changeReminderDateClicked: "Change Reminder Date Clicked",
  shopChosenForServicing: "Shop Chosen For Servicing",
  takeMeToMyAcount: "Take Me To My Account Clicked On Elite Plan",
  serviceSelected: "Service Selected",
  signupClicked: "Sign Up Started",
  signinClicked: "Click Login Button",
  uberLoginClicked: "Uber Login/Signin Clicked",
  addAllServicesFromMaintainenanceSchedule: "Add All Services From Maintenance Schedule",
  vehicleAddedDuringOnboarding: "Vehicle Added During Onboarding",
  paymentMethodSaved: "Payment Method Added",
  addVehicleClicked: "Add Vehicle Clicked",
  planTypeChosen: "Plan Type Chosen",
  signedUpWithPremiumPlan: "Signed Up With Premium Plan",
  approvalsClicked: "Approvals Clicked",
  welcomeScreen: "Welcome Screen"
}

export const trackEvent = async (
  eventNameKey,
  props = {},
  isPartnerOffer,
  shouldMapProperties = false,
  shouldAddCartProperties = true
) => {
  // device information gets added with every track event
  props = {
    ...props,
    ...getDeviceProp()
  }

  // additional props gets added with every track event
  if (shouldAddCartProperties) {
    props = {
      ...getAdditionalProps(),
      ...props
    }
  }

  if (isPartnerOffer) {
    props = {
      ...getAdditionalPartnerOfferProps(),
      ...props
    }
  }

  // source gets added with every track event
  props.source = window.location.hash

  if (props) {
    props = JSON.parse(JSON.stringify(props, (_, v) => (v === undefined ? null : v)))
  }

  // dont send email prop when user is generic
  props = removeGenericEmail(props)

  // mapping props key to constant file
  if (props && !shouldMapProperties) props = mapKeys(props, (v, k) => segmentProps[k])

  if (props && shouldMapProperties)
    props = mapKeys(props, (v, k) => {
      if (isNaN(k)) {
        return segmentProps[k]
      } else {
        return [k]
      }
    })

  if (props) props = omitBy(props, isNil)

  // fetching event name which is stored in redux state by its key
  const eventName = getEventNameByKey(eventNameKey)
  if (eventName) {
    const response = await window.analytics.track(eventName, props)
    return response
  }
  return null
}

export const identifyEvent = async ({
  traits = {},
  userId,
  onSuccess = () => {},
  replaceUndefinedOrNullValues = true
}) => {
  const { user } = store.getState()
  let userIdFromReduxState = user ? user.id : null
  // device information gets added with every track event
  traits = {
    ...traits,
    ...getDeviceProp()
  }

  if (traits) {
    if (user.email) traits.email = user.email
    if (user.cellPhone) traits.phone = COUNTRY_CODE.concat(user.cellPhone)
    if (user.firstName && user.lastName) traits.name = (user.firstName + " " + user.lastName).trim()
    if (user.firstName) traits.firstNameWithSpace = user.firstName.trim()
    if (user.lastName) traits.lastNameWithSpace = user.lastName.trim()
    if (replaceUndefinedOrNullValues) {
      traits = JSON.parse(
        JSON.stringify(traits, (_, v) => (v === undefined || v === null ? "" : v))
      )
    }
  }

  // dont send email prop when user is generic
  traits = removeGenericEmail(traits)

  // mapping props key to constant file
  traits = mapKeys(traits, (v, k) => segmentProps[k])

  const response = await window.analytics.identify(
    userId || userIdFromReduxState,
    traits,
    null,
    onSuccess
  )
  return response
}
