import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"

const ShimmerSquar = ({ height, containerClass, width, rounded, ...rest }) => {
  return (
    <div className={containerClass}>
      <ShimmerThumbnail height={height} width={width} rounded={rounded} {...rest} />
    </div>
  )
}
export default ShimmerSquar
