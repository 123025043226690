import {
  MAINTENANCE_SCHEDULES_LOADING,
  MAINTENANCE_SCHEDULES_NOT_LOADING,
  MAINTENANCE_INDICATORS_LOADING,
  MAINTENANCE_INDICATORS_NOT_LOADING,
  OEM_MAINTENANCE_SCHEDULES_LOADING,
  OEM_MAINTENANCE_SCHEDULES_NOT_LOADING,
  CA_MAINTENANCE_SCHEDULES_LOADING,
  CA_MAINTENANCE_SCHEDULES_NOT_LOADING
} from "../actions/maintenanceSchedules"
import { CARADVISE_RECOMMENDED_SERVICES } from "../CarAdvise2/actions/maintenance"

const initialState = {
  schedulesLoading: false,
  indicatorsLoading: false,
  oemSchedulesLoading: false,
  caSchedulesLoading: false
}

export default function maintenanceSchedulesReducer(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_SCHEDULES_LOADING:
      return { ...state, schedulesLoading: true }
      break
    case MAINTENANCE_SCHEDULES_NOT_LOADING:
      return { ...state, schedulesLoading: false }
      break
    case MAINTENANCE_INDICATORS_LOADING:
      return { ...state, indicatorsLoading: true }
      break
    case MAINTENANCE_INDICATORS_NOT_LOADING:
      return { ...state, indicatorsLoading: false }
      break
    case OEM_MAINTENANCE_SCHEDULES_LOADING:
      return { ...state, oemSchedulesLoading: true }
      break
    case OEM_MAINTENANCE_SCHEDULES_NOT_LOADING:
      return { ...state, oemSchedulesLoading: false }
      break
    case CA_MAINTENANCE_SCHEDULES_LOADING:
      return { ...state, caSchedulesLoading: true }
      break
    case CA_MAINTENANCE_SCHEDULES_NOT_LOADING:
      return { ...state, caSchedulesLoading: false }
      break

    case CARADVISE_RECOMMENDED_SERVICES:
      return {
        ...state,
        carAdviseRecommended: action.payload
      }
    default:
      return state
  }
}
