import React from "react"
import { numberFormatToCurrency } from "../../../shared_component/utils/currency"
import { helpers } from "caradvise_shared_components"
import { noPriceLabel } from "../../helpers/shopHelpers"
import { useTranslation } from "react-i18next"

export default function EstimatedPrice({ orderLikeObject = {}, shop = {} }) {
  if (orderLikeObject.any_order_service_lacks_price) {
    const { t } = useTranslation("common")
    return (
      <a
        id="launch-chat"
        href="#/"
        onClick={(e) => e.preventDefault()}
        style={{ color: "white", textDecoration: "underline", fontSize: "32px" }}
      >
        {noPriceLabel(shop.company || orderLikeObject.company, t)}
      </a>
    )
  } else {
    return (
      <p className="cart-price">
        <strong>{numberFormatToCurrency(orderLikeObject.estimated_total)}</strong>
      </p>
    )
  }
}
