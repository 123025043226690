import React, { forwardRef, Component } from "react"
import PropTypes from "prop-types"
import StepperHeader from "./StepperHeader"
import StepperFooter from "./StepperFooter"
import { routeChange, routerPaths } from "../../../v1/constants/paths"

class Stepper extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      currentTabIndex: props.currentStep || 0
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (this.props.forwardedRef) {
      this.props.forwardedRef.current = () => this.navigateToStepHandler(0)
    }
    if (this.props.paymentRef) {
      this.props.paymentRef.current = () => this.navigateToStepHandler(1)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  navigateToStepHandler = (index) => {
    const { history } = this.props
    const routes = [
      routerPaths.checkoutSchedule,
      routerPaths.checkoutPayment,
      routerPaths.checkoutReview
    ]

    routeChange(history, routes[index] || routerPaths.checkoutReview)
  }

  handleNextStep = () => {
    const { history } = this.props
    const currentPath = window.location.hash.split("#")[1]
    if (this._isMounted) {
      if (currentPath === routerPaths.checkoutSchedule) {
        routeChange(history, routerPaths.checkoutPayment)
      }
      this.setState((prevState) => ({
        currentTabIndex: Math.min(
          prevState.updatedStepperContent + 1,
          this.props.updatedStepperContent.length - 1
        )
      }))
    }
  }

  handlePreviousStep = () => {
    this.setState((prevState) => ({
      currentTabIndex: Math.max(prevState.currentTabIndex - 1, 0)
    }))
  }

  handleStepperSubmit = () => {
    this.props.submitStepper()
  }

  render() {
    const {
      isInline,
      stepperContent,
      t,
      history,
      isSubmitting,
      isLastStep,
      updatedStepperContent,
      pageContent
    } = this.props

    const { currentTabIndex } = this.state
    const isPrevBtn = currentTabIndex !== 0
    return (
      <div className="stepper-wrapper">
        <div className="wizard-header">
          <StepperHeader
            t={t}
            stepperContent={stepperContent}
            navigateToStepHandler={this.navigateToStepHandler}
            isInline={isInline}
            currentTabIndex={currentTabIndex}
            history={history}
            pageContent={pageContent}
          />
        </div>
        <div className="stepper-body">
          {updatedStepperContent.map((el, i) => (
            <React.Fragment key={i}>{i === currentTabIndex && el.content()}</React.Fragment>
          ))}
        </div>
        <StepperFooter
          isPrevBtn={isPrevBtn}
          handlePreviousStep={this.handlePreviousStep}
          isLastStep={isLastStep}
          handleNextStep={this.handleNextStep}
          handleStepperSubmit={this.handleStepperSubmit}
          stepperContent={updatedStepperContent}
          currentTabIndex={currentTabIndex}
          t={t}
          isSubmitting={isSubmitting}
        />
      </div>
    )
  }
}

Stepper.propTypes = {
  stepperContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.func.isRequired,
      clicked: PropTypes.func,
      isWarning: PropTypes.bool,
      isError: PropTypes.bool,
      isComplete: PropTypes.bool,
      isLoading: PropTypes.bool
    })
  ),
  submitStepper: PropTypes.func.isRequired,
  isInline: PropTypes.bool,
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  currentStep: PropTypes.number
}

const StepperComponent = forwardRef((props, ref) => {
  const { paymentRef, ...otherProps } = props

  return <Stepper {...otherProps} forwardedRef={ref} paymentRef={paymentRef} />
})

export default StepperComponent
