import React, { PureComponent } from "react"
import { List } from "semantic-ui-react"
import {
  BASIC,
  PREMIUM,
  ELITE,
  isBasic,
  includesRoadside
} from "../../../v1/helpers/membershipHelpers"
import RoadsideTooltip from "../../../v1/components/Membership/RoadsideTooltip"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation, Trans } from "react-i18next"

class MembershipBenefitsList extends PureComponent {
  benefits = () => {
    const { plan, showAll, isSelected, t } = this.props
    if (!plan) return []

    const previousPlan = plan.previousTier || {}
    const previousTierName = previousPlan.name
    const everythingIn = previousTierName ? (
      <strong>{t("everythingInText", { previousTierName: previousTierName })}</strong>
    ) : null

    const planDiscount = plan.percent_discount || 0
    const previousDiscount = previousPlan.percent_discount || 0
    const discountDiff = planDiscount - previousDiscount
    const discount =
      discountDiff <= 0 ? null : (
        <span>
          <u>{t("discountBenefitText", { discountDiff: parseInt(discountDiff) })}</u>
          {` ${t("discountLbl")}`}
        </span>
      )

    const vehicles = t("vehiclesBenefitText", { vehiclesCount: plan.max_num_vehicles })
    const advice = t("adviceText")
    const coupons = t("couponsText")
    // Don't display the tooltip if the plan isn't selected (only the icon), since selecting the
    // plan shifts the position of the card in the UI which throws off the tooltip position
    const roadside = (
      <span>
        {t("roadsideAssistanceText")}
        {isSelected && <RoadsideTooltip numEvents={plan.max_num_yearly_roadside_events} />}
        {!isSelected && (
          <span style={{ paddingLeft: "5px" }}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </span>
        )}
      </span>
    )

    if (!isBasic(plan) && showAll) {
      let list = [everythingIn, discount, vehicles, advice, coupons]
      if (includesRoadside(plan)) list.splice(1, 0, roadside)

      return list
    }

    let config =
      {
        [BASIC]: [vehicles, advice, coupons],
        [PREMIUM]: [everythingIn, discount, vehicles],
        [ELITE]: [everythingIn, roadside, discount, vehicles]
      }[plan.name] || []

    return config.filter((attr) => attr != null)
  }

  render() {
    const { plan } = this.props

    return (
      <List>
        {this.benefits().map((benefit, i) => {
          return (
            <List.Item
              key={`MembershipBenefitsList-${plan.name}-${i}`}
              className="upgrade-featured-benefit"
            >
              <List.Icon name="checkmark" className="upgrade-modal-checkmark" />
              {benefit}
            </List.Item>
          )
        })}
      </List>
    )
  }
}

export default withTranslation("membershipBenefitsList")(MembershipBenefitsList)
